import Mock from './mock'

import './db/auth'
import './db/projects'
import './db/participants'
import './db/WorkOrders'
import './db/notification'
import './db/states'

Mock.onAny().passThrough()
