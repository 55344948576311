import AxiosInstance from "./AxiosInstance";
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const getAll = async () => {
    const responseData = await AxiosInstance.get(`/api/state/all`).then(response => response);
    await delay(500);
    return responseData.data;
}

const getById = async (StateId) => {
    const responseData = await AxiosInstance.get('/api/state', { data: StateId  }).then(response => response);
    await delay(500);
    return responseData.data;
}

export const StatesService = {
    getAll,
    getById,
};