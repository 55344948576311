const { default: Mock } = require("fake-db/mock")

const projectDB = {
    projects: [
        {
            id: 1,
            index: 1,
            projectName: "Nova High School",
            projectAccNo: "00629933-018",
            streetAddress: "3600 College Avenue",
            aptSuite: "Ste 101",
            city: "Davie",
            state: "2",
            zipCode: 33314,
            country: "Broward",
            projectRole: "Supplier",
            projectType: "Private Commercial",
            workingFor: "General Contractor",
            contractAmount: "$45,000.00",
            balanceRemaining: "$15,000.00",
            firstDayJob: "10-07-2019",
            lastDayJob: "10-28-2018",
            companyName: "Marcus",
            companyDivisionName: "Southern Region"
        }, 
        {
            id: 2,
            index: 2,
            projectName: "Sherlock Museum",
            projectAccNo: "00629933-018",
            streetAddress: "3600 College Avenue",
            aptSuite: "Ste 101",
            city: "Davie",
            state: "2",
            zipCode: 33314,
            country: "Broward",
            projectRole: "Supplier",
            projectType: "Private Commercial",
            workingFor: "General Contractor",
            contractAmount: "$45,000.00",
            balanceRemaining: "$15,000.00",
            firstDayJob: "10-07-2019",
            lastDayJob: "10-28-2018",
            companyName: "Marcus",
            companyDivisionName: "Southern Region"
        },
        {
            id: 3,
            index: 3,
            projectName: "Agengers Tower",
            projectAccNo: "00629933-018",
            streetAddress: "3600 College Avenue",
            aptSuite: "Ste 101",
            city: "Davie",
            state: "2",
            zipCode: 33314,
            country: "Broward",
            projectRole: "Supplier",
            projectType: "Private Commercial",
            workingFor: "General Contractor",
            contractAmount: "$45,000.00",
            balanceRemaining: "$15,000.00",
            firstDayJob: "10-07-2019",
            lastDayJob: "10-28-2018",
            companyName: "Marcus",
            companyDivisionName: "Southern Region"
        }
    ],
}

Mock.onGet('/api/project/all').reply((config) => {
    return [200, projectDB.projects]
})

Mock.onGet('/api/project').reply((config) => {
    const id = config.data
    const response = projectDB.projects.find((project) => project.id == id)
    return [200, response]
})

Mock.onPost('/api/project/delete').reply((config) => {
    let project = JSON.parse(config.data)
    let index = { i: 0 }
    projectDB.projects.forEach((element) => {
        if (element.id === project.id) {
            return [200, projectDB.projects.splice(index.i, 1)]
        }
        index.i++
    })
    return [200, projectDB.projects]
})

Mock.onPost('/api/project/update').reply((config) => {
    let project = JSON.parse(config.data)
    let index = { i: 0 }
    projectDB.projects.forEach((element) => {
        if (element.id === project.id) {
            projectDB.projects[index.i] = project
            return [200, projectDB.projects]
        }
        index.i++
    })
    return [200, projectDB.projects]
})

Mock.onPost('/api/project/add').reply((config) => {
    let project = JSON.parse(config.data)
    projectDB.projects.push(project)
    return [200, projectDB.projects]
})
