import { GET_STATES_REQUEST } from "redux/actions/StateActions";
import { GET_STATES_FAIL } from "redux/actions/StateActions";
import { GET_STATES_SUCCESS } from "redux/actions/StateActions";

import { GET_STATE_REQUEST } from "redux/actions/StateActions";
import { GET_STATE_SUCCESS } from "redux/actions/StateActions";
import { GET_STATE_FAIL } from "redux/actions/StateActions";

const initialStates = {
    states: [],
    state: {},
    isLoading: true,
    error: ''
}

const StatesReducer =  (state = initialStates, action) => {
    switch (action.type) {
        case GET_STATES_REQUEST: 
            return {
                ...state,
            }
        case GET_STATES_SUCCESS: 
            return {
                ...state,
                isLoading: false,
                error: '',
                states: action.data,
            }
        case GET_STATES_FAIL: 
            return {
                ...state,
                isLoading: false,
                error: action.data,
                states: []
            }
        case GET_STATE_REQUEST: 
            return {
                ...state,
                isLoading: true
            }
        case GET_STATE_SUCCESS: 
            return {
                ...state,
                isLoading: false,
                error: '',
                state: action.data,
            }
        case GET_STATE_FAIL: 
            return {
                ...state,
                isLoading: false,
                error: action.data,
                state: []
            }
        default: 
            return state;
    }
}

export default StatesReducer
