import axios from 'axios';

const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    timeout: 2000,
    headers: {
        'content-type': 'application/json',
    }
});

AxiosInstance.interceptors.response.use(
    (response) => response,
    (error) =>
        Promise.reject(
            (error.response && error.response.data) || 'Something wentsss wrong!'
        )
)

export default AxiosInstance
