
import { GET_WORK_ORDERS_REQUEST } from "redux/actions/WorkOrderActions";
import { GET_WORK_ORDERS_FAIL } from "redux/actions/WorkOrderActions";
import { GET_WORK_ORDERS_SUCCESS } from "redux/actions/WorkOrderActions";

import { GET_WORK_ORDER_REQUEST } from "redux/actions/WorkOrderActions";
import { GET_WORK_ORDER_SUCCESS } from "redux/actions/WorkOrderActions";
import { GET_WORK_ORDER_FAIL } from "redux/actions/WorkOrderActions";

import { UPDATE_WORK_ORDER_REQUEST } from "redux/actions/WorkOrderActions";
import { UPDATE_WORK_ORDER_SUCCESS } from "redux/actions/WorkOrderActions";
import { UPDATE_WORK_ORDER_FAIL } from "redux/actions/WorkOrderActions";

const initialStates = {
    workorders: [],
    workorder: {},
    isLoading: true,
    error: ''
}

const WorkOrdersReducer =  (state = initialStates, action) => {
    switch (action.type) {
        case GET_WORK_ORDERS_REQUEST: 
            return {
                ...state,
            }
        case GET_WORK_ORDERS_SUCCESS: 
            return {
                ...state,
                isLoading: false,
                error: '',
                workorders: action.data,
            }
        case GET_WORK_ORDERS_FAIL: 
            return {
                ...state,
                isLoading: false,
                error: action.data,
                workorders: []
            }
        case GET_WORK_ORDER_REQUEST: 
            return {
                ...state,
                isLoading: true
            }
        case GET_WORK_ORDER_SUCCESS: 
            return {
                ...state,
                isLoading: false,
                error: '',
                workorder: action.data,
            }
        case GET_WORK_ORDER_FAIL: 
            return {
                ...state,
                isLoading: false,
                error: action.data,
                workorder: []
            }
        case UPDATE_WORK_ORDER_REQUEST: 
            return {
                ...state,
                isLoading: true
            }
        case UPDATE_WORK_ORDER_SUCCESS:
            return {
                ...state,
                workorder: action.data,
                isLoading: false,
                error: ''
            }
        case UPDATE_WORK_ORDER_FAIL: 
            return {
                ...state,
                isLoading: true,
                error: action.data,
            }    
        default: 
            return state;
    }
}

export default WorkOrdersReducer
