import {
    Grid,
    Typography,
} from '@mui/material';

// Sidebar Footer

const Footer = () => {

    return (
          <Grid container display={"flex"} justifyContent={"center"} alignItems={"end"}>
              <Typography  sx={{ opacity:.6,  marginTop:'calc(5% + 70px)', color: "#ffffff" }} >
                © 2022 Copyright SunRay Notice
              </Typography>
          </Grid>
    );
};

export default Footer;