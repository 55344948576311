import { IconUser } from '@tabler/icons';

const icons = { IconUser };

// Profile Menu Items
const profile = {
    id: 'profile',
    type: 'group',
    children: [
        {
            id: 'profile',
            title: 'PROFILE',
            type: 'item',
            url: '/profile',
            icon: icons.IconUser,
        }
    ]
};

export default profile;

