import React, { useEffect, useState } from "react";
import { ExpandCircleDownOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/styles";

// Styles
const StyledBox = styled(Box)(({ theme }) => ({
    position: 'fixed',
    bottom: '0rem',
    right: '.2rem',
    animation: 'fadeIn 700ms ease-in-out 1s both',
    cursor: 'pointer',
    display:'inline'
}))

// Scroll To Top
const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Show button when page is scorlled upto given distance
    const toggleVisibility = () => {
        if (window.pageYOffset > 300)
            setIsVisible(true);
        else
            setIsVisible(false);
    };

    // Set the top cordinate to 0 make scrolling smooth
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <StyledBox>
            {isVisible && (
                <Box onClick={scrollToTop} >
                    <ExpandCircleDownOutlined sx={{ transform: "rotate(-180deg)", fontSize: '32px' }}  />
                </Box>
            )}
        </StyledBox>
    );
}

export default ScrollToTop;
