// API 
import { ProjectsService } from 'services/ProjectsService'

// Project Action Types

export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST'
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS'
export const GET_PROJECTS_FAIL = 'GET_PROJECTS_FAIL'

export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST'
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS'
export const GET_PROJECT_FAIL = 'GET_PROJECT_FAIL'

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST'
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS'
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL'

// Get All Projects Details

export const getProjectsRequest = () => {
    return {
        type: GET_PROJECTS_REQUEST
    }
}

export const getProjectsSuccess = (projects) => {
    return {
        type: GET_PROJECTS_SUCCESS,
        data: projects
    }
}

export const getProjectsFail = (error) => {
    return {
        type: GET_PROJECTS_FAIL,
        data: error
    }
}

export const loadProjects = ()  => (dispatch) => {
    dispatch(getProjectsRequest())
    ProjectsService.getAll().then((response) => {
        dispatch(getProjectsSuccess(response))
    }).catch(err => {
        dispatch(getProjectsFail(err))
    })
}

// Get Project Details

export const getProjectRequest = () => {
    return {
        type: GET_PROJECT_REQUEST
    }
}

export const getProjectSuccess = (projects) => {
    return {
        type: GET_PROJECT_SUCCESS,
        data: projects
    }
}

export const getProjectFail = (error) => {
    return {
        type: GET_PROJECT_FAIL,
        data: error
    }
}

export const loadProject = (id)  => (dispatch) => {
    dispatch(getProjectRequest())
    ProjectsService.getById(`${id}`).then((response) => {
        dispatch(getProjectSuccess(response))
    }).catch(err => {
        dispatch(getProjectFail(err))
    })
}

// Update Project Details

export const updateProjectRequest = (project) => {
    return {
        type: UPDATE_PROJECT_REQUEST,
        data: project
    }
}

export const updateProjectSuccess = (data) => {
    return {
        type: UPDATE_PROJECT_SUCCESS,
        data: data
    }
}

export const updateProjectFail = (error) => {
    return {
        type: UPDATE_PROJECT_FAIL,
        data: error
    }
}

export const updateProject = (projectID,data) => {
    return function (dispatch) {
        dispatch(updateProjectRequest(data))
        ProjectsService.update(projectID,data).then((response) => {
            dispatch(updateProjectSuccess(response))
        }).catch(err => {
            dispatch(updateProjectFail(err))
        })
    }
}
