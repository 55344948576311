const { default: Mock } = require("fake-db/mock")

const participantDB = {
    participants: [
        {
            id: 1,
            projectRole: "Supplier (You)",
            companyName: "Winsupply Inc",
            contactName: "Winsupply",
            phoneNumber: "+1 (256) 759 455",
            faxNumber: "256-343-534",
            email: "marcus@construction.com",
            streetAddress: "3600 College Avenue",
            aptSuite: "Ste 101",
            zipCode: 33314,
            city: "Davie",
            state: "Florida",
            country: "Broward"
        }
    ],
}

Mock.onGet('/api/participant/all').reply((config) => {
    return [200, participantDB.participants]
})

Mock.onGet('/api/participant').reply((config) => {
    const id = config.data
    const response = participantDB.participants.find((participant) => participant.id == id)
    return [200, response]
})

Mock.onPost('/api/participant/delete').reply((config) => {
    let participant = JSON.parse(config.data)
    let index = { i: 0 }
    participantDB.participants.forEach((element) => {
        if (element.id === participant.id) {
            return [200, participantDB.participants.splice(index.i, 1)]
        }
        index.i++
    })
    return [200, participantDB.participants]
})

Mock.onPost('/api/participant/update').reply((config) => {
    let participant = JSON.parse(config.data)
    let index = { i: 0 }
    participantDB.participants.forEach((element) => {
        if (element.id === participant.id) {
            participantDB.participants[index.i] = participant
            return [200, participantDB.participants]
        }
        index.i++
    })
    return [200, participantDB.participants]
})

Mock.onPost('/api/participant/add').reply((config) => {
    let participant = JSON.parse(config.data)
    participantDB.participants.push(participant)
    return [200, participantDB.participants]
})
