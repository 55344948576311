import { useState, useRef, useEffect } from 'react';

// Material UI
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';

// Project Imports
import MainCard from 'components/cards/MainCard';
import Transitions from 'components/extended/Transitions';
import User1 from 'assets/images/users/user-round.svg';

// Icons
import { ExpandCircleDownOutlined, PowerSettingsNewOutlined } from '@mui/icons-material';
import useAuth from 'hooks/useAuth';

// Profile Section
const ProfileSection = () => {
    const theme = useTheme();
    
    const [open, setOpen] = useState(false);
    const { logout, user } = useAuth();

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
  

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    borderColor: '#ffffff',
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: '#ffffff',
                        backgroundColor: 'transparent',
                        color:'#ffffff !important',
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '&[aria-controls="menu-list-grow"], &:active': {
                        boxShadow: 'none',
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={User1}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label=''
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            />
            <Typography 
                component="span" 
                variant="h4" 
                sx={{ 
                    fontWeight: 400, 
                    cursor: 'pointer' 
                }} 
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true" 
                onClick={handleToggle}
            >
                {user?.name}
            </Typography>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    borderColor: '#ffffff',
                    color:'#ffffff !important',
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: '#ffffff',
                        backgroundColor: 'transparent',
                        color:'#ffffff !important'
                    },
                    '&[aria-controls="menu-list-grow"], &:active': {
                        boxShadow: 'none'
                    },
                }}
                icon={
                    <ExpandCircleDownOutlined />
                }
                label=''
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2,minWidth: 300 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Grid container justify="space-between">
                                                    <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                        {user.name}                                              
                                                    </Typography>
                                                </Grid>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400,cursor: "pointer" }}>
                                                   <PowerSettingsNewOutlined size="1.3rem" color='error'  onClick={logout} />
                                                </Typography>
                                            </Stack>
                                            <Typography variant="subtitle2">{user.role}</Typography>
                                        </Stack> 
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;