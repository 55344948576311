import { useLayoutEffect } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';

// Routes
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';

// Routing Render
export default function ThemeRoutes() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return useRoutes([PrivateRoutes, PublicRoutes], "/");
}
