import React, { useEffect, useState } from "react";
import {
    Skeleton,
    Box,
    Grid,
    Input,
    Typography,
    ClickAwayListener
} from "@mui/material";
import { IconSearch } from "@tabler/icons";
import { Close } from "@mui/icons-material";
import { styled } from '@mui/styles';
import { shouldForwardProp } from '@mui/system';
import { useDispatch, useSelector } from "react-redux";
import { loadWorkOrders } from "redux/actions/WorkOrderActions";
import { Link } from "react-router-dom";

const SearchBarContainer = styled(Grid, { shouldForwardProp })(({ theme }) => ({
    flexDirection: 'column',
    width: '34em',
    height: '3em',
    backgroundColor: '#fff',
    borderRadius: '4px',
    border: '1px solid #ccc',
    // boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
    marginLeft: '16px',
    cursor: 'pointer'
}));

const SearchInputContainer = styled(Grid, { shouldForwardProp })(({ theme }) => ({
    width: '100%',
    minHeight: '3em',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '5px 10px'
}));

const SearchInput = styled(Input, { shouldForwardProp })(({ theme }) => ({
    width: '100%',
    outline: 'none',
    border: 'none',
    fontSize: '14px',
    color: 'inherit',
    fontWeight: '500',
    borderRadius: '4px',
    backgroundColor: '#fff',
    '&:focus': {
        outline: 'none',
        '&::placeholder': {
            opacity: '0'
        }
    },
    '&::placeholder': {
        color: '#ccc',
        transition: ' all 250ms ease-in-out'
    }
}));

const SearchIcon = styled('span', { shouldForwardProp })(({ theme }) => ({
    color: '#bebebe',
    fontSize: '27px',
    verticalAlign: 'middle',
}));

const CloseIcon = styled('span', { shouldForwardProp })(({ theme }) => ({
    color: '#bebebe',
    fontSize: '27px',
    verticalAlign: 'middle',
    cursor: 'pointer',
    '&:hover': {
        color: '#dfdfdf'
    }
}));

const ProjectContainer = styled('div', { shouldForwardProp })(({ theme }) => ({
    width: '100%',
    borderBottom: '2px solid #d8d8d852',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '6px 8px',
}));

const ProjectContainerLink = styled(Link, { shouldForwardProp })(({ theme }) => ({
    width: '100%',
    borderBottom: '2px solid #d8d8d852',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '6px 8px',
    textDecoration: 'none',
    color: '#000',
    '&:hover':{
        background: 'rgb(0, 132, 161)',
        color: '#fff'
    }
}));

const ProjectName = styled(Typography, { shouldForwardProp })(({ theme }) => ({
    fontSize: '16px',
    marginLeft: '10px',
    marginBottom: '5px',
}));

const SearchContent = styled('div', { shouldForwardProp })(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    background: '#fff',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px',
}));

const LoadingWrapper = styled('div', { shouldForwardProp })(({ theme }) => ({
    width: '100%',
    display: 'flex',
    height: '80px',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
}));

const LoadingWrapperContent = styled('div', { shouldForwardProp })(({ theme }) => ({
    width: '100%',
    display: 'flex',
    maxHeight: '250px',
    alignItems: 'center',
    flexDirection: 'column',
    overflowY: 'auto',
}));

const WarningMessage = styled('span', { shouldForwardProp })(({ theme }) => ({
    color: '#a1a1a1',
    fontSize: '14px',
    display: 'flex',
    alignSelf: 'center',
    justifySelf: 'center',
}));

// Search Section
const SearchSection = (props) => {

    const [searchQuery, setSearchQuery] = useState("");
    const [isExpanded, setExpanded] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [projectsList, setProjectsList] = useState([]);
    const [noProjects, setNoProjects] = useState(false);

    const { apiWorkOrders } = useSelector(
        state => ({
            apiWorkOrders: state.workorders.workorders,
            isWorkOrdersLoading: state.workorders.isLoading,
            error: state.workorders.error,
        })
    )

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadWorkOrders());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = (event) => {
        setExpanded(false);
        setSearchQuery("");
    };

    const changeHandler = (e) => {
        e.preventDefault();
        setSearchQuery(e.target.value);
        if (e.target.value.length > 1) {
            setExpanded(true);
            setLoading(true);
            setNoProjects(false);
            if (apiWorkOrders && apiWorkOrders.length === 0) {
                setNoProjects(true);
                setProjectsList([]);
                setLoading(false);
            } else {
                const items = apiWorkOrders && apiWorkOrders.filter(x => x.JobName.toString().toLowerCase().includes(e.target.value.toLowerCase()) || x.JobNumber.toString().toLowerCase().includes(e.target.value))
                if (items && items.length === 0) {
                    setNoProjects(true);
                    setProjectsList([]);
                    setLoading(false);
                } else {
                    setTimeout(() => {
                        setNoProjects(false);
                        setProjectsList(items)
                        setLoading(false);
                    }, 100);
                }
            }
        } else {
            setExpanded(false)
        }
    };

    const expandContainer = (name) => {
        setExpanded(false);
        setSearchQuery(name);
    };

    return (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <>
                <ClickAwayListener onClickAway={handleClose}>
                    <SearchBarContainer>
                        <SearchInputContainer>
                            <SearchInput
                                placeholder="Project #, W/O #, Project Name, Job Address, Job #"
                                value={searchQuery}
                                onChange={changeHandler}
                                disableUnderline
                            />
                            {isExpanded || searchQuery !=='' ? (
                                <CloseIcon>
                                    <Close onClick={() => expandContainer('')} />
                                </CloseIcon>
                            ) : (
                                <SearchIcon >
                                    <IconSearch stroke={1.5} size="1.9rem" color="grey" />
                                </SearchIcon>
                            )
                            }
                        </SearchInputContainer>
                        {isExpanded && (
                            <SearchContent>
                                {isLoading && (
                                    <>
                                        {[...Array(3)].map((el, index) => (
                                            <ProjectContainer key={index}>
                                                <ProjectName><Skeleton width="50%" /></ProjectName>
                                                <ProjectName><Skeleton width="75%" /></ProjectName>
                                                <ProjectName><Skeleton width="100%" /></ProjectName>
                                            </ProjectContainer>
                                        ))}
                                    </>
                                )}
                                {!isLoading && noProjects && (
                                    <LoadingWrapper>
                                        <WarningMessage>No Projects found!</WarningMessage>
                                    </LoadingWrapper>
                                )}
                                {!isLoading && projectsList.length > 0 && (
                                    <LoadingWrapperContent>
                                        {projectsList.map(item => (
                                            <ProjectContainerLink  
                                                onClick={() => expandContainer(item.JobName)}
                                                to={{
                                                    pathname: `/customer/project/${item.WorkOrder}`,
                                                    state: { items: item }
                                                }} 
                                                key={item.WorkOrder}
                                            >
                                                <ProjectName>{item.JobNumber}</ProjectName>
                                                <ProjectName>{item.JobName}</ProjectName>
                                                <ProjectName>{item.JobFullAddress}</ProjectName>
                                            </ProjectContainerLink>
                                        ))}
                                    </LoadingWrapperContent>
                                )}
                            </SearchContent>
                        )}
                    </SearchBarContainer>
                </ClickAwayListener>
            </>
        </Box>
    );
}

export default SearchSection;
