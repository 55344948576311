import { GlobalStyles } from "@mui/material";
import React from "react";

const CustomScrollbar = (props) => {
    return (
        <GlobalStyles
            styles={{
                h1: { color: 'grey' },
                '*::-webkit-scrollbar': {
                    width: '0.7em',
                    cursor: 'pointer',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgb(0, 132, 161)',
                    outline: '0px solid slategrey',
                    borderRadius: 10,
                    cursor: 'pointer'
                },
                '*::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: 'rgb(0, 132, 161)',
                    outline: '0px solid slategrey',
                    cursor: 'pointer'
                },
            }}
        />
    );
};

export default CustomScrollbar;