import axios from "axios";
import AxiosInstance from "./AxiosInstance";

const getAll = async () => {
    const responseData = await axios.get(`/api/participant/all`).then(response => response);
    return responseData.data;
}

const create = async (ParticipantData) => {
    const responseData = await AxiosInstance.post(`/api/participant/add`, ParticipantData).then(response => response);
    return responseData.data;
}

const update = async (Participant) => {
    const responseData = await AxiosInstance.post(`/api/participant/update`, Participant).then(response => response);
    return responseData.data;
}

const _delete = async (Participant) => {
    const responseData = await AxiosInstance.post(`/api/participant/delete`, Participant).then(response => response);
    return responseData.data;
}

const getById = async (ParticipantId) => {
    const responseData = await AxiosInstance.get(`/api/participant`, ParticipantId).then(response => response);
    return responseData.data;
}

const getByProjectId = async (ProjectId) => {
    const responseData = await AxiosInstance.get(`/api/participant`, { params: { projectId: ProjectId } }).then(response => response);
    return responseData.data;
};

export const ParticipantsService = {
    getAll,
    getById,
    getByProjectId,
    create,
    update,
    delete: _delete
};