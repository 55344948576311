import dashboard from './dashboard';
import SearchRequests from './SearchRequests';
import profile from './profile';
import charts from './charts';

// Menu Items

const MenuItems = {
    items: [dashboard,SearchRequests,charts,profile]
};

export default MenuItems;