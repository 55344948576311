import React from 'react';

// Material UI
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Chip,
    Grid,
    Typography
} from '@mui/material';

// Project Imports
import User1 from 'assets/images/users/user-round.svg';

// Icons
import { IconPoint } from '@tabler/icons';
import { PowerSettingsNewOutlined } from '@mui/icons-material';
import useAuth from 'hooks/useAuth';
import { styled } from '@mui/styles';

// Styles
const MainGrid = styled(Grid)(({ theme }) => ({
    display: 'flex', 
    padding: '32px 16px',
    mx: 'auto'
}))

const StyledChip = styled(Chip)(({ theme }) => ({
    backgroundColor: 'transparent',
    '&[aria-controls="menu-list-grow"], &:hover': {
        backgroundColor: 'transparent',
        color: '#ffffff !important',
        '& svg': {
            stroke: theme.palette.primary.light
        }
    }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    cursor: 'pointer',
    textTransform: 'uppercase',
    display: 'flex',
    color: '#70C0B3',
    fontSize: '12px'
}));

// Sidebar Header
const Header = () => {

    const theme = useTheme();
    const { logout, user } = useAuth();

    return (
        <MainGrid alignItems="center" justifyContent="space-between" mb={1}>
            <Grid container>
                <StyledChip
                    icon={
                        <Avatar
                            src={User1}
                            sx={{
                                ...theme.typography.mediumAvatar,
                                cursor: 'pointer'
                            }}
                            color="inherit"
                        />
                    }
                />
                <Grid item>
                    <Typography
                        component="span"
                        variant="h4"
                        color={theme.palette.grey[50]}
                        sx={{
                            fontWeight: 500,
                            cursor: 'pointer'
                        }}
                    >
                        {user.name}
                    </Typography>
                    <StyledTypography
                        component="span"
                        variant="caption"
                    >
                        <IconPoint size="1.3rem" fill="#70C0B3" strokeWidth="5" width="1.2rem" height="1.2rem" />  Online
                    </StyledTypography>
                    </Grid>
            </Grid>
            <Grid item>
                <PowerSettingsNewOutlined size="1.3rem" color='error' sx={{ cursor: 'pointer' }} onClick={logout} />
            </Grid>
        </MainGrid>
    );
};

export default Header;
