// Fake DB
import './fake-db'

// Redux
import { useSelector } from 'react-redux';

// Material UI
import { ThemeProvider } from '@mui/material/styles';
import { 
    CssBaseline, 
    StyledEngineProvider 
} from '@mui/material';

// Routing
import Routes from 'routes';

// Default Theme
import themes from 'themes';

// Project Imports
import { AuthProvider } from 'contexts/JWTAuthContext';

// Custom Imports
import CustomScrollbar from 'components/scrollbars/CustomScrollbar';
import ScrollToTop from 'components/extended/ScrollToTop';

// APP

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CustomScrollbar />
                <CssBaseline />
                <AuthProvider>
                    <Routes />
                </AuthProvider>
                <ScrollToTop />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
