import { IconDeviceAnalytics } from '@tabler/icons';

const icons = { IconDeviceAnalytics };

// Dashboard Menu Items 
const dashboard = {
    id: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'collapse',
            icon: icons.IconDeviceAnalytics,
            url: '/',
            target: false,
            children: [
                {
                    id: 'default1',
                    title: 'Project Request',
                    type: 'item',
                    url: '/project-request',
                    target: false
                },
                {
                    id: 'default2',
                    title: 'Document Request',
                    type: 'item',
                    url: '/document-request',
                    target: false
                },
                {
                    id: 'default3',
                    title: 'Upload Requests',
                    type: 'item',
                    url: '/upload-requests',
                    target: false
                }
            ]
        }
    ]
};

export default dashboard;