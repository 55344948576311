const { default: Mock } = require("fake-db/mock")

const workOrdersDB = {
    WorkOrders: [
        {
          "WorkOrder": 4219558,
          "ParentWorkOrder": "",
          "JobName": "Creation Museum",
          "JobNumber": 132758,
          "FirstdayontheJob": "01-26-2022",
          "LastdayontheJob": "04-26-2022",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 Bulletsburg Church Rd",
          "JobAddress2": "",
          "JobFullAddress": "2800 Bulletsburg Church Rd",
          "JobCounty": "Boone",
          "JobState": "Kentucky",
          "CustomerCompanyName": "De George Ceilings",
          "RequestType": "Preliminary Notice (Not required)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "N/A",
          "BranchNumber": 7347,
          "SubmitDate": "",
          "ClientonJob": "De George Ceilings"
        },
        {
          "WorkOrder": 4218230,
          "ParentWorkOrder": "",
          "JobName": "Department of Veterans Affairs  Project #539-300",
          "JobNumber": "7347-132722-2",
          "FirstdayontheJob": "01-24-2022",
          "LastdayontheJob": "01-24-2022",
          "EstimatedLastdayontheJob": "01-24-2022",
          "JobAddress1": "3200 Vine street",
          "JobAddress2": "6th Floor",
          "JobFullAddress": "3200 Vine street 6th Floor",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "Veterans Contracting Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$6,770.86",
          "BalanceRemaining": "$6,770.86",
          "BranchNumber": 7347,
          "SubmitDate": "",
          "ClientonJob": "Veterans Contracting Inc"
        },
        {
          "WorkOrder": 4218229,
          "ParentWorkOrder": "",
          "JobName": "CONTECH",
          "JobNumber": "7347-102659-15",
          "FirstdayontheJob": "01-28-2022",
          "LastdayontheJob": "01-28-2022",
          "EstimatedLastdayontheJob": "01-28-2022",
          "JobAddress1": "9100 CENTRE POINTE DR",
          "JobAddress2": "4TH FLOOR",
          "JobFullAddress": "9100 CENTRE POINTE DR 4TH FLOOR",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "True Cut Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Processing",
          "ContractAmount": "$48,263.34",
          "BalanceRemaining": "$48,263.34",
          "BranchNumber": 7347,
          "SubmitDate": "02-11-2022",
          "ClientonJob": "True Cut Construction LLC"
        },
        {
          "WorkOrder": 4218228,
          "ParentWorkOrder": "",
          "JobName": "FIRST FINANCIAL BANK",
          "JobNumber": "7347-102659-17",
          "FirstdayontheJob": "02-03-2022",
          "LastdayontheJob": "02-03-2022",
          "EstimatedLastdayontheJob": "02-03-2022",
          "JobAddress1": "9660 MASON MONTGOMERY",
          "JobAddress2": "",
          "JobFullAddress": "9660 MASON MONTGOMERY",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "True Cut Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Request",
          "ContractAmount": "$12,947.42",
          "BalanceRemaining": "$12,947.42",
          "BranchNumber": 7347,
          "SubmitDate": "02-11-2022",
          "ClientonJob": "True Cut Construction LLC"
        },
        {
          "WorkOrder": 4218227,
          "ParentWorkOrder": "",
          "JobName": "GROW OHIO 2 STORY",
          "JobNumber": "7272-132741-7",
          "FirstdayontheJob": "02-02-2022",
          "LastdayontheJob": "02-02-2022",
          "EstimatedLastdayontheJob": "02-02-2022",
          "JobAddress1": "6400 MAYSVILLE PIKE",
          "JobAddress2": "FORKLIFT UNLOAD",
          "JobFullAddress": "6400 MAYSVILLE PIKE FORKLIFT UNLOAD",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Paul Construction Co Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Request",
          "ContractAmount": "$24,940.00",
          "BalanceRemaining": "$24,940.00",
          "BranchNumber": 7272,
          "SubmitDate": "02-11-2022",
          "ClientonJob": "Paul Construction Co Inc"
        },
        {
          "WorkOrder": 4218226,
          "ParentWorkOrder": "",
          "JobName": "Anthem - East Walnut Hills",
          "JobNumber": "7347-100959-19",
          "FirstdayontheJob": "02-02-2022",
          "LastdayontheJob": "02-02-2022",
          "EstimatedLastdayontheJob": "02-02-2022",
          "JobAddress1": "1351 William Howard Taft Road",
          "JobAddress2": "VANCE 812-599-3746",
          "JobFullAddress": "1351 William Howard Taft Road VANCE 812-599-3746",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Morningside Group Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$49,468.80",
          "BalanceRemaining": "$49,468.80",
          "BranchNumber": 7347,
          "SubmitDate": "",
          "ClientonJob": "Morningside Group Inc"
        },
        {
          "WorkOrder": 4218225,
          "ParentWorkOrder": "",
          "JobName": "Exor",
          "JobNumber": "7270-102652-20",
          "FirstdayontheJob": "02-02-2022",
          "LastdayontheJob": "02-02-2022",
          "EstimatedLastdayontheJob": "02-02-2022",
          "JobAddress1": "3680 Symmes Rd",
          "JobAddress2": "",
          "JobFullAddress": "3680 Symmes Rd",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Kiesland Development Srvcs LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Request",
          "ContractAmount": "$7,707.01",
          "BalanceRemaining": "$7,707.01",
          "BranchNumber": 7270,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "Kiesland Development Srvcs LLC"
        },
        {
          "WorkOrder": 4218224,
          "ParentWorkOrder": "",
          "JobName": "Advanced Auto Ontario",
          "JobNumber": "7302-137373-22",
          "FirstdayontheJob": "02-07-2022",
          "LastdayontheJob": "02-07-2022",
          "EstimatedLastdayontheJob": "02-07-2022",
          "JobAddress1": "490 N Lexington-Springmill Rd",
          "JobAddress2": "",
          "JobFullAddress": "490 N Lexington-Springmill Rd",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Request",
          "ContractAmount": "$5,197.81",
          "BalanceRemaining": "$5,197.81",
          "BranchNumber": 7302,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4218223,
          "ParentWorkOrder": "",
          "JobName": "GPT Enterprise Inc",
          "JobNumber": "7270-134638-17",
          "FirstdayontheJob": "01-26-2022",
          "LastdayontheJob": "01-26-2022",
          "EstimatedLastdayontheJob": "01-26-2022",
          "JobAddress1": "11655 Solzman Rd",
          "JobAddress2": "",
          "JobFullAddress": "11655 Solzman Rd",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "GPT Enterprise Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Under Review",
          "ContractAmount": "$12,640.68",
          "BalanceRemaining": "$12,640.68",
          "BranchNumber": 7270,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "GPT Enterprise Inc"
        },
        {
          "WorkOrder": 4218222,
          "ParentWorkOrder": "",
          "JobName": "Ferguson Hilliard",
          "JobNumber": "7272-140525-2",
          "FirstdayontheJob": "02-02-2022",
          "LastdayontheJob": "02-02-2022",
          "EstimatedLastdayontheJob": "02-02-2022",
          "JobAddress1": "4363 Lyman Drive",
          "JobAddress2": "",
          "JobFullAddress": "4363 Lyman Drive",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Giustini Enterprises LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Request",
          "ContractAmount": "$5,473.73",
          "BalanceRemaining": "$5,473.73",
          "BranchNumber": 7272,
          "SubmitDate": "02-11-2022",
          "ClientonJob": "Giustini Enterprises LLC"
        },
        {
          "WorkOrder": 4218221,
          "ParentWorkOrder": "",
          "JobName": "Caroline Harrison Building",
          "JobNumber": "7347-101415-8",
          "FirstdayontheJob": "02-07-2022",
          "LastdayontheJob": "02-07-2022",
          "EstimatedLastdayontheJob": "02-07-2022",
          "JobAddress1": "131 W High Street",
          "JobAddress2": "1st Floor",
          "JobFullAddress": "131 W High Street 1st Floor",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Donald Borg Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Request",
          "ContractAmount": "$14,019.37",
          "BalanceRemaining": "$14,019.37",
          "BranchNumber": 7347,
          "SubmitDate": "02-11-2022",
          "ClientonJob": "Donald Borg Construction"
        },
        {
          "WorkOrder": 4218220,
          "ParentWorkOrder": "",
          "JobName": "FIVE RIVERS MEDICAL CENTER",
          "JobNumber": "7348-132775-4",
          "FirstdayontheJob": "02-07-2022",
          "LastdayontheJob": "02-07-2022",
          "EstimatedLastdayontheJob": "02-07-2022",
          "JobAddress1": "271 MIAMI CHAPEL RD",
          "JobAddress2": "GC: SKANSKA",
          "JobFullAddress": "271 MIAMI CHAPEL RD GC: SKANSKA",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "AKA Construction Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Request",
          "ContractAmount": "$10,737.40",
          "BalanceRemaining": "$10,737.40",
          "BranchNumber": 7348,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "AKA Construction Inc"
        },
        {
          "WorkOrder": 4218070,
          "ParentWorkOrder": 4211743,
          "JobName": "Aldi Inc E Jackson Plaza #111",
          "JobNumber": "7312-101312-96",
          "FirstdayontheJob": "01-25-2022",
          "LastdayontheJob": "01-25-2022",
          "EstimatedLastdayontheJob": "01-25-2022",
          "JobAddress1": "3021 E Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "3021 E Michigan Ave",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Advance Constr & Design Svs",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$109,093.04",
          "BalanceRemaining": "$109,093.04",
          "BranchNumber": 7312,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "Advance Constr & Design Svs"
        },
        {
          "WorkOrder": 4218058,
          "ParentWorkOrder": 4211742,
          "JobName": "Sema Garage/Dyno/PM/Storage",
          "JobNumber": "7304-103058-45",
          "FirstdayontheJob": "01-28-2022",
          "LastdayontheJob": "01-28-2022",
          "EstimatedLastdayontheJob": "01-28-2022",
          "JobAddress1": "14655 Jib St",
          "JobAddress2": "21131-350",
          "JobFullAddress": "14655 Jib St 21131-350",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Siwek Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$63,400.64",
          "BalanceRemaining": "$63,400.64",
          "BranchNumber": 7304,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "Siwek Construction Co"
        },
        {
          "WorkOrder": 4218051,
          "ParentWorkOrder": 4211738,
          "JobName": "Encore",
          "JobNumber": "7304-104019-60",
          "FirstdayontheJob": "01-27-2022",
          "LastdayontheJob": "01-27-2022",
          "EstimatedLastdayontheJob": "01-27-2022",
          "JobAddress1": "1985 West Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "1985 West Big Beaver Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,930.42",
          "BalanceRemaining": "$9,930.42",
          "BranchNumber": 7304,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4218050,
          "ParentWorkOrder": 4211737,
          "JobName": "RTT  MEDICAL",
          "JobNumber": "7308-101361-25",
          "FirstdayontheJob": "01-27-2022",
          "LastdayontheJob": "01-27-2022",
          "EstimatedLastdayontheJob": "01-27-2022",
          "JobAddress1": "3509 3 Mile Rd NW",
          "JobAddress2": "Ste 3",
          "JobFullAddress": "3509 3 Mile Rd NW Ste 3",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Angstrom Technology Ltd",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,781.30",
          "BalanceRemaining": "$7,781.30",
          "BranchNumber": 7308,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "Angstrom Technology Ltd"
        },
        {
          "WorkOrder": 4218049,
          "ParentWorkOrder": 4211733,
          "JobName": "Poblano’s",
          "JobNumber": "7304-133913-9",
          "FirstdayontheJob": "01-28-2022",
          "LastdayontheJob": "01-28-2022",
          "EstimatedLastdayontheJob": "01-28-2022",
          "JobAddress1": "6200 Baron Dr",
          "JobAddress2": "",
          "JobFullAddress": "6200 Baron Dr",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lynch Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,331.06",
          "BalanceRemaining": "$5,331.06",
          "BranchNumber": 7304,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "Lynch Construction LLC"
        },
        {
          "WorkOrder": 4218048,
          "ParentWorkOrder": 4209671,
          "JobName": "Byers Porsche",
          "JobNumber": "7272-132739-24",
          "FirstdayontheJob": "01-18-2022",
          "LastdayontheJob": "01-18-2022",
          "EstimatedLastdayontheJob": "01-18-2022",
          "JobAddress1": "407 N Hamilton Rd",
          "JobAddress2": "",
          "JobFullAddress": "407 N Hamilton Rd",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$6,060.72",
          "BalanceRemaining": "$6,060.72",
          "BranchNumber": 7272,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4218047,
          "ParentWorkOrder": 4209669,
          "JobName": "OTTERBEIN HOUSE",
          "JobNumber": "7347-102659-14",
          "FirstdayontheJob": "01-19-2022",
          "LastdayontheJob": "01-19-2022",
          "EstimatedLastdayontheJob": "01-19-2022",
          "JobAddress1": "201 Marge Schott Way",
          "JobAddress2": "5 UNITS",
          "JobFullAddress": "201 Marge Schott Way 5 UNITS",
          "JobCounty": "Warren",
          "JobState": "Ohio",
          "CustomerCompanyName": "True Cut Construction LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,478.51",
          "BalanceRemaining": "$5,478.51",
          "BranchNumber": 7347,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "True Cut Construction LLC"
        },
        {
          "WorkOrder": 4218046,
          "ParentWorkOrder": 4209668,
          "JobName": "One Medical Dublin",
          "JobNumber": "7348-132711-9",
          "FirstdayontheJob": "01-19-2022",
          "LastdayontheJob": "01-19-2022",
          "EstimatedLastdayontheJob": "01-19-2022",
          "JobAddress1": "6515 Longshore Loop #1a",
          "JobAddress2": "",
          "JobFullAddress": "6515 Longshore Loop #1a",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "Integrity Interiors US LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$21,274.31",
          "BalanceRemaining": "$21,274.31",
          "BranchNumber": 7348,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "Integrity Interiors US LLC"
        },
        {
          "WorkOrder": 4218045,
          "ParentWorkOrder": 4209667,
          "JobName": "Taco Bell / Genoa",
          "JobNumber": "7302-100963-124",
          "FirstdayontheJob": "01-18-2022",
          "LastdayontheJob": "01-18-2022",
          "EstimatedLastdayontheJob": "01-18-2022",
          "JobAddress1": "22251 OH-51 W",
          "JobAddress2": "",
          "JobFullAddress": "22251 OH-51 W",
          "JobCounty": "Ottawa",
          "JobState": "Ohio",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,814.44",
          "BalanceRemaining": "$10,814.44",
          "BranchNumber": 7302,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4218043,
          "ParentWorkOrder": 4209666,
          "JobName": "Msa",
          "JobNumber": "7270-134638-15",
          "FirstdayontheJob": "01-19-2022",
          "LastdayontheJob": "01-19-2022",
          "EstimatedLastdayontheJob": "01-19-2022",
          "JobAddress1": "316 W 4th St",
          "JobAddress2": "",
          "JobFullAddress": "316 W 4th St",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "GPT Enterprise Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$17,276.60",
          "BalanceRemaining": "$17,276.60",
          "BranchNumber": 7270,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "GPT Enterprise Inc"
        },
        {
          "WorkOrder": 4218042,
          "ParentWorkOrder": 4209005,
          "JobName": "Oakland Uni Off Camp Reaserch",
          "JobNumber": "7310-101038-55",
          "FirstdayontheJob": "01-24-2022",
          "LastdayontheJob": "01-24-2022",
          "EstimatedLastdayontheJob": "01-24-2022",
          "JobAddress1": "2871 Research Dr",
          "JobAddress2": "",
          "JobFullAddress": "2871 Research Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$66,707.84",
          "BalanceRemaining": "$66,707.84",
          "BranchNumber": 7310,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4218040,
          "ParentWorkOrder": 4208994,
          "JobName": "Springfield Oaks",
          "JobNumber": "7313-104476-9",
          "FirstdayontheJob": "01-24-2022",
          "LastdayontheJob": "01-24-2022",
          "EstimatedLastdayontheJob": "01-24-2022",
          "JobAddress1": "9741 Dixie Hwy",
          "JobAddress2": "",
          "JobFullAddress": "9741 Dixie Hwy",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,492.86",
          "BalanceRemaining": "$7,492.86",
          "BranchNumber": 7313,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "M & V Construction"
        },
        {
          "WorkOrder": 4218039,
          "ParentWorkOrder": 4208992,
          "JobName": "I Heart Mac&Cheese Saginaw",
          "JobNumber": "7313-104138-7",
          "FirstdayontheJob": "01-20-2022",
          "LastdayontheJob": "01-20-2022",
          "EstimatedLastdayontheJob": "01-20-2022",
          "JobAddress1": "4510 State Street",
          "JobAddress2": "",
          "JobFullAddress": "4510 State Street",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Wolgast Corporation",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,012.73",
          "BalanceRemaining": "$6,012.73",
          "BranchNumber": 7313,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "Wolgast Corporation"
        },
        {
          "WorkOrder": 4218038,
          "ParentWorkOrder": 4208991,
          "JobName": "Pizza Hut",
          "JobNumber": "7310-104761-37",
          "FirstdayontheJob": "01-19-2022",
          "LastdayontheJob": "01-19-2022",
          "EstimatedLastdayontheJob": "01-19-2022",
          "JobAddress1": "1241 Fort St",
          "JobAddress2": "Lock Box # 1234",
          "JobFullAddress": "1241 Fort St Lock Box # 1234",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Token Acoustical Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,754.22",
          "BalanceRemaining": "$5,754.22",
          "BranchNumber": 7310,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "Token Acoustical Inc"
        },
        {
          "WorkOrder": 4218036,
          "ParentWorkOrder": 4206998,
          "JobName": "Mobility Works",
          "JobNumber": "7302-101113-29",
          "FirstdayontheJob": "01-17-2022",
          "LastdayontheJob": "01-17-2022",
          "EstimatedLastdayontheJob": "01-17-2022",
          "JobAddress1": "9675 South Compass Drive",
          "JobAddress2": "",
          "JobFullAddress": "9675 South Compass Drive",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$27,951.79",
          "BalanceRemaining": "$27,951.79",
          "BranchNumber": 7302,
          "SubmitDate": "02-10-2022",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4217879,
          "ParentWorkOrder": "",
          "JobName": "Sema Garage",
          "JobNumber": "7312-103058-45",
          "FirstdayontheJob": "02-07-2022",
          "LastdayontheJob": "02-07-2022",
          "EstimatedLastdayontheJob": "02-07-2022",
          "JobAddress1": "14655 Jib St",
          "JobAddress2": "ADAS Rooms 1&2",
          "JobFullAddress": "14655 Jib St ADAS Rooms 1&2",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Siwek Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Request",
          "ContractAmount": "$77,776.09",
          "BalanceRemaining": "$77,776.09",
          "BranchNumber": "Not specified",
          "SubmitDate": "02-09-2022",
          "ClientonJob": "Siwek Construction Co"
        },
        {
          "WorkOrder": 4217878,
          "ParentWorkOrder": "",
          "JobName": "Marlette Job N-S Wing",
          "JobNumber": "7304-105171-67",
          "FirstdayontheJob": "02-07-2022",
          "LastdayontheJob": "02-07-2022",
          "EstimatedLastdayontheJob": "02-07-2022",
          "JobAddress1": 1,
          "JobAddress2": "",
          "JobFullAddress": 1,
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Nates Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Request",
          "ContractAmount": "$19,119.13",
          "BalanceRemaining": "$19,119.13",
          "BranchNumber": "Not specified",
          "SubmitDate": "02-09-2022",
          "ClientonJob": "Nates Drywall"
        },
        {
          "WorkOrder": 4217877,
          "ParentWorkOrder": "",
          "JobName": "T&B Construction",
          "JobNumber": "7422-102638-8",
          "FirstdayontheJob": "01-21-2022",
          "LastdayontheJob": "01-21-2022",
          "EstimatedLastdayontheJob": "01-21-2022",
          "JobAddress1": "2310 Krafft Road",
          "JobAddress2": "Building 1",
          "JobFullAddress": "2310 Krafft Road Building 1",
          "JobCounty": "Saint Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "T&B Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$14,601.48",
          "BalanceRemaining": "$14,601.48",
          "BranchNumber": "Not specified",
          "SubmitDate": "02-09-2022",
          "ClientonJob": "T&B Construction"
        },
        {
          "WorkOrder": 4217876,
          "ParentWorkOrder": "",
          "JobName": "Njp Mercy Health",
          "JobNumber": "7308-104212-4",
          "FirstdayontheJob": "02-04-2022",
          "LastdayontheJob": "02-04-2022",
          "EstimatedLastdayontheJob": "02-04-2022",
          "JobAddress1": "200 Jefferson Ave SE",
          "JobAddress2": "",
          "JobFullAddress": "200 Jefferson Ave SE",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Ken Ferris Construction C",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Request",
          "ContractAmount": "$13,380.05",
          "BalanceRemaining": "$13,380.05",
          "BranchNumber": "Not specified",
          "SubmitDate": "02-09-2022",
          "ClientonJob": "Ken Ferris Construction C"
        },
        {
          "WorkOrder": 4217875,
          "ParentWorkOrder": "",
          "JobName": "Doxtator - Dearborn Heights",
          "JobNumber": "7310-153719-5",
          "FirstdayontheJob": "02-08-2022",
          "LastdayontheJob": "02-08-2022",
          "EstimatedLastdayontheJob": "02-08-2022",
          "JobAddress1": "27150 Doxtator",
          "JobAddress2": "",
          "JobFullAddress": "27150 Doxtator",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trademark Carpentry Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Request",
          "ContractAmount": "$10,927.90",
          "BalanceRemaining": "$10,927.90",
          "BranchNumber": "Not specified",
          "SubmitDate": "02-09-2022",
          "ClientonJob": "Trademark Carpentry Services"
        },
        {
          "WorkOrder": 4212369,
          "ParentWorkOrder": 4185405,
          "JobName": "Marine City Contracting",
          "JobNumber": "7313-136494-16",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "1145 S Main Street",
          "JobAddress2": "",
          "JobFullAddress": "1145 S Main Street",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Marine City Contracting Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,110.07",
          "BranchNumber": 7313,
          "SubmitDate": "02-04-2022",
          "ClientonJob": "Marine City Contracting Inc"
        },
        {
          "WorkOrder": 4212364,
          "ParentWorkOrder": 4200026,
          "JobName": "Dollar General",
          "JobNumber": "7304-103135-4",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "17080 Lincoln Rd",
          "JobAddress2": "",
          "JobFullAddress": "17080 Lincoln Rd",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Quality Design Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,316.42",
          "BranchNumber": 7304,
          "SubmitDate": "02-04-2022",
          "ClientonJob": "Quality Design Inc"
        },
        {
          "WorkOrder": 4212340,
          "ParentWorkOrder": 4190966,
          "JobName": "Aerial Assets",
          "JobNumber": "7313-100963-127",
          "FirstdayontheJob": "11-30-2021",
          "LastdayontheJob": "11-30-2021",
          "EstimatedLastdayontheJob": "11-30-2021",
          "JobAddress1": "8170 N Lilley Rd",
          "JobAddress2": "",
          "JobFullAddress": "8170 N Lilley Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$37,527.02",
          "BranchNumber": 7313,
          "SubmitDate": "02-04-2022",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4212255,
          "ParentWorkOrder": "",
          "JobName": "Worthington Hills",
          "JobNumber": "7272-106673-10",
          "FirstdayontheJob": "01-27-2022",
          "LastdayontheJob": "01-27-2022",
          "EstimatedLastdayontheJob": "01-27-2022",
          "JobAddress1": "920 Clubview Blvd S",
          "JobAddress2": "",
          "JobFullAddress": "920 Clubview Blvd S",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "William J Shaffer",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$6,567.52",
          "BalanceRemaining": "$6,567.52",
          "BranchNumber": 7272,
          "SubmitDate": "02-03-2022",
          "ClientonJob": "William J Shaffer"
        },
        {
          "WorkOrder": 4212254,
          "ParentWorkOrder": "",
          "JobName": "Arrowhead Cleaners",
          "JobNumber": "7302-104950-11",
          "FirstdayontheJob": "01-31-2022",
          "LastdayontheJob": "01-31-2022",
          "EstimatedLastdayontheJob": "01-31-2022",
          "JobAddress1": "471 W. Dussel Dr",
          "JobAddress2": "",
          "JobFullAddress": "471 W. Dussel Dr",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "Van Tassel Constr Corp",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Processing",
          "ContractAmount": "$5,116.07",
          "BalanceRemaining": "$5,116.07",
          "BranchNumber": 7302,
          "SubmitDate": "02-03-2022",
          "ClientonJob": "Van Tassel Constr Corp"
        },
        {
          "WorkOrder": 4212253,
          "ParentWorkOrder": "",
          "JobName": "EVEREVE",
          "JobNumber": "7347-133616-16",
          "FirstdayontheJob": "01-31-2022",
          "LastdayontheJob": "01-31-2022",
          "EstimatedLastdayontheJob": "01-31-2022",
          "JobAddress1": "787 MONTGOMERY RD",
          "JobAddress2": "SPACE 8B",
          "JobFullAddress": "787 MONTGOMERY RD SPACE 8B",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Specialty Interiors Of Oh Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Processing",
          "ContractAmount": "$12,741.69",
          "BalanceRemaining": "$12,741.69",
          "BranchNumber": 7347,
          "SubmitDate": "02-03-2022",
          "ClientonJob": "Specialty Interiors Of Oh Inc"
        },
        {
          "WorkOrder": 4212252,
          "ParentWorkOrder": "",
          "JobName": "Cmc Properties",
          "JobNumber": "7348-132721-5",
          "FirstdayontheJob": "01-31-2022",
          "LastdayontheJob": "01-31-2022",
          "EstimatedLastdayontheJob": "01-31-2022",
          "JobAddress1": "511 N Broadway ST",
          "JobAddress2": "",
          "JobFullAddress": "511 N Broadway ST",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Rafic Nakouzi",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Under Review",
          "ContractAmount": "$61,676.10",
          "BalanceRemaining": "$61,676.10",
          "BranchNumber": 7348,
          "SubmitDate": "02-03-2022",
          "ClientonJob": "Rafic Nakouzi"
        },
        {
          "WorkOrder": 4212251,
          "ParentWorkOrder": "",
          "JobName": "Core 5",
          "JobNumber": "7272-100133-78",
          "FirstdayontheJob": "01-31-2022",
          "LastdayontheJob": "01-31-2022",
          "EstimatedLastdayontheJob": "01-31-2022",
          "JobAddress1": "1225 Southgate Parkway",
          "JobAddress2": "",
          "JobFullAddress": "1225 Southgate Parkway",
          "JobCounty": "Licking",
          "JobState": "Ohio",
          "CustomerCompanyName": "Pepper Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Under Review",
          "ContractAmount": "$8,189.90",
          "BalanceRemaining": "$8,189.90",
          "BranchNumber": 7272,
          "SubmitDate": "02-03-2022",
          "ClientonJob": "Pepper Construction"
        },
        {
          "WorkOrder": 4212250,
          "ParentWorkOrder": "",
          "JobName": "W.P.A.F.B.",
          "JobNumber": "7348-100492-7",
          "FirstdayontheJob": "01-27-2022",
          "LastdayontheJob": "01-27-2022",
          "EstimatedLastdayontheJob": "01-27-2022",
          "JobAddress1": "2560 LOOP RD",
          "JobAddress2": "AREA B",
          "JobFullAddress": "2560 LOOP RD AREA B",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Messer Construction Co",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Processing",
          "ContractAmount": "$6,086.41",
          "BalanceRemaining": "$6,086.41",
          "BranchNumber": 7348,
          "SubmitDate": "02-03-2022",
          "ClientonJob": "Messer Construction Co"
        },
        {
          "WorkOrder": 4212249,
          "ParentWorkOrder": "",
          "JobName": "Rock box",
          "JobNumber": "7270-102652-15",
          "FirstdayontheJob": "01-31-2022",
          "LastdayontheJob": "01-31-2022",
          "EstimatedLastdayontheJob": "01-31-2022",
          "JobAddress1": "8207 Highland pointe dr",
          "JobAddress2": "",
          "JobFullAddress": "8207 Highland pointe dr",
          "JobCounty": "Butler",
          "JobState": "Ohio",
          "CustomerCompanyName": "Kiesland Development Srvcs LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Under Review",
          "ContractAmount": "$10,100.87",
          "BalanceRemaining": "$10,100.87",
          "BranchNumber": 7270,
          "SubmitDate": "02-03-2022",
          "ClientonJob": "Kiesland Development Srvcs LLC"
        },
        {
          "WorkOrder": 4212248,
          "ParentWorkOrder": "",
          "JobName": "KROGER TI",
          "JobNumber": "7348-132748-19",
          "FirstdayontheJob": "01-26-2022",
          "LastdayontheJob": "01-26-2022",
          "EstimatedLastdayontheJob": "01-26-2022",
          "JobAddress1": "6732 SHOOK RD",
          "JobAddress2": "",
          "JobFullAddress": "6732 SHOOK RD",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Combs Interior Specialties Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Processing",
          "ContractAmount": "$16,417.46",
          "BalanceRemaining": "$16,417.46",
          "BranchNumber": 7348,
          "SubmitDate": "02-03-2022",
          "ClientonJob": "Combs Interior Specialties Inc"
        },
        {
          "WorkOrder": 4212247,
          "ParentWorkOrder": "",
          "JobName": "Tom Paulsaenl",
          "JobNumber": "7270-137502-6",
          "FirstdayontheJob": "01-26-2022",
          "LastdayontheJob": "01-26-2022",
          "EstimatedLastdayontheJob": "01-26-2022",
          "JobAddress1": "700 Columbia ave",
          "JobAddress2": "",
          "JobFullAddress": "700 Columbia ave",
          "JobCounty": "Butler",
          "JobState": "Ohio",
          "CustomerCompanyName": "C R Meyer Company",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Under Review",
          "ContractAmount": "$10,119.98",
          "BalanceRemaining": "$10,119.98",
          "BranchNumber": 7270,
          "SubmitDate": "02-03-2022",
          "ClientonJob": "C R Meyer Company"
        },
        {
          "WorkOrder": 4212246,
          "ParentWorkOrder": "",
          "JobName": "Merchant Street",
          "JobNumber": "7348-136541-2",
          "FirstdayontheJob": "01-31-2022",
          "LastdayontheJob": "01-31-2022",
          "EstimatedLastdayontheJob": "01-31-2022",
          "JobAddress1": "134/144 Merchant Street",
          "JobAddress2": "1ST FLOOR",
          "JobFullAddress": "134/144 Merchant Street 1ST FLOOR",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "Burgert Paint & Drywall",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Under Review",
          "ContractAmount": "$9,523.34",
          "BalanceRemaining": "$9,523.34",
          "BranchNumber": 7348,
          "SubmitDate": "02-03-2022",
          "ClientonJob": "Burgert Paint & Drywall"
        },
        {
          "WorkOrder": 4211743,
          "ParentWorkOrder": "",
          "JobName": "Aldi Inc E Jackson Plaza #111",
          "JobNumber": "7312-101312-96",
          "FirstdayontheJob": "01-25-2022",
          "LastdayontheJob": "01-25-2022",
          "EstimatedLastdayontheJob": "01-25-2022",
          "JobAddress1": "3021 E Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "3021 E Michigan Ave",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Advance Constr & Design Svs",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$109,093.04",
          "BalanceRemaining": "$109,093.04",
          "BranchNumber": 7312,
          "SubmitDate": "02-02-2022",
          "ClientonJob": "Advance Constr & Design Svs"
        },
        {
          "WorkOrder": 4211742,
          "ParentWorkOrder": "",
          "JobName": "Sema Garage/Dyno/PM/Storage",
          "JobNumber": "7304-103058-45",
          "FirstdayontheJob": "01-28-2022",
          "LastdayontheJob": "01-28-2022",
          "EstimatedLastdayontheJob": "01-28-2022",
          "JobAddress1": "14655 Jib St",
          "JobAddress2": "21131-350",
          "JobFullAddress": "14655 Jib St 21131-350",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Siwek Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$63,400.64",
          "BalanceRemaining": "$63,400.64",
          "BranchNumber": 7304,
          "SubmitDate": "02-02-2022",
          "ClientonJob": "Siwek Construction Co"
        },
        {
          "WorkOrder": 4211741,
          "ParentWorkOrder": "",
          "JobName": "Burlington Coat Factory",
          "JobNumber": "7313-136494-17",
          "FirstdayontheJob": "01-28-2022",
          "LastdayontheJob": "01-28-2022",
          "EstimatedLastdayontheJob": "01-28-2022",
          "JobAddress1": "2169 S. Telegraph Rd.",
          "JobAddress2": "",
          "JobFullAddress": "2169 S. Telegraph Rd.",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Marine City Contracting Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Processing",
          "ContractAmount": "$25,956.95",
          "BalanceRemaining": "$25,956.95",
          "BranchNumber": 7313,
          "SubmitDate": "02-02-2022",
          "ClientonJob": "Marine City Contracting Inc"
        },
        {
          "WorkOrder": 4211740,
          "ParentWorkOrder": "",
          "JobName": "Affordable Dentures",
          "JobNumber": "7422-129211-77",
          "FirstdayontheJob": "01-31-2022",
          "LastdayontheJob": "01-31-2022",
          "EstimatedLastdayontheJob": "01-31-2022",
          "JobAddress1": "50640 Waterside drive",
          "JobAddress2": "",
          "JobFullAddress": "50640 Waterside drive",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lawson Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Processing",
          "ContractAmount": "$18,366.15",
          "BalanceRemaining": "$18,366.15",
          "BranchNumber": 7422,
          "SubmitDate": "02-02-2022",
          "ClientonJob": "Lawson Contracting"
        },
        {
          "WorkOrder": 4211739,
          "ParentWorkOrder": "",
          "JobName": "SPRINGHILL CAMP",
          "JobNumber": "7308-104225-18",
          "FirstdayontheJob": "01-31-2022",
          "LastdayontheJob": "01-31-2022",
          "EstimatedLastdayontheJob": "01-31-2022",
          "JobAddress1": "7717 95TH AVE",
          "JobAddress2": "",
          "JobFullAddress": "7717 95TH AVE",
          "JobCounty": "OSCEOLA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Mid Michigan Ceiling & Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$11,163.24",
          "BalanceRemaining": "$11,163.24",
          "BranchNumber": 7308,
          "SubmitDate": "02-02-2022",
          "ClientonJob": "Mid Michigan Ceiling & Drywall Inc"
        },
        {
          "WorkOrder": 4211738,
          "ParentWorkOrder": "",
          "JobName": "Encore",
          "JobNumber": "7304-104019-60",
          "FirstdayontheJob": "01-27-2022",
          "LastdayontheJob": "01-27-2022",
          "EstimatedLastdayontheJob": "01-27-2022",
          "JobAddress1": "1985 West Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "1985 West Big Beaver Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,930.42",
          "BalanceRemaining": "$9,930.42",
          "BranchNumber": 7304,
          "SubmitDate": "02-02-2022",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4211737,
          "ParentWorkOrder": "",
          "JobName": "RTT  MEDICAL",
          "JobNumber": "7308-101361-25",
          "FirstdayontheJob": "01-27-2022",
          "LastdayontheJob": "01-27-2022",
          "EstimatedLastdayontheJob": "01-27-2022",
          "JobAddress1": "3509 3 Mile Rd NW",
          "JobAddress2": "Ste 3",
          "JobFullAddress": "3509 3 Mile Rd NW Ste 3",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Angstrom Technology Ltd",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,781.30",
          "BalanceRemaining": "$7,781.30",
          "BranchNumber": 7308,
          "SubmitDate": "02-02-2022",
          "ClientonJob": "Angstrom Technology Ltd"
        },
        {
          "WorkOrder": 4211735,
          "ParentWorkOrder": "",
          "JobName": "STARBUCKS",
          "JobNumber": "7308-102688-24",
          "FirstdayontheJob": "01-31-2022",
          "LastdayontheJob": "01-31-2022",
          "EstimatedLastdayontheJob": "01-31-2022",
          "JobAddress1": "6700 BROADMOOR AVE SE",
          "JobAddress2": "",
          "JobFullAddress": "6700 BROADMOOR AVE SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Keystone Const & Consult LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Processing",
          "ContractAmount": "$7,541.59",
          "BalanceRemaining": "$7,541.59",
          "BranchNumber": 7308,
          "SubmitDate": "02-02-2022",
          "ClientonJob": "Keystone Const & Consult LLC"
        },
        {
          "WorkOrder": 4211734,
          "ParentWorkOrder": "",
          "JobName": "Commercial - 1st Floor",
          "JobNumber": "7310-137397-19",
          "FirstdayontheJob": "01-31-2022",
          "LastdayontheJob": "01-31-2022",
          "EstimatedLastdayontheJob": "01-31-2022",
          "JobAddress1": "260 S Liley Rd",
          "JobAddress2": "Lock box 1963",
          "JobFullAddress": "260 S Liley Rd Lock box 1963",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trademark Drywall LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Processing",
          "ContractAmount": "$6,510.37",
          "BalanceRemaining": "$6,510.37",
          "BranchNumber": 7310,
          "SubmitDate": "02-02-2022",
          "ClientonJob": "Trademark Drywall LLC"
        },
        {
          "WorkOrder": 4211733,
          "ParentWorkOrder": "",
          "JobName": "Poblano’s",
          "JobNumber": "7304-133913-9",
          "FirstdayontheJob": "01-28-2022",
          "LastdayontheJob": "01-28-2022",
          "EstimatedLastdayontheJob": "01-28-2022",
          "JobAddress1": "6200 Baron Dr",
          "JobAddress2": "",
          "JobFullAddress": "6200 Baron Dr",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lynch Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,331.06",
          "BalanceRemaining": "$5,331.06",
          "BranchNumber": 7304,
          "SubmitDate": "02-02-2022",
          "ClientonJob": "Lynch Construction LLC"
        },
        {
          "WorkOrder": 4210848,
          "ParentWorkOrder": 4202375,
          "JobName": "Hudson BLK TWR General Trades",
          "JobNumber": "7313-101349-56",
          "FirstdayontheJob": "01-03-2022",
          "LastdayontheJob": "01-03-2022",
          "EstimatedLastdayontheJob": "01-03-2022",
          "JobAddress1": "1208 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "1208 Woodward Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Barton Malow Company",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,831.93",
          "BalanceRemaining": "$9,831.93",
          "BranchNumber": 7313,
          "SubmitDate": "02-01-2022",
          "ClientonJob": "Barton Malow Company"
        },
        {
          "WorkOrder": 4210806,
          "ParentWorkOrder": 4204992,
          "JobName": "TOWNE PLACE SUITES",
          "JobNumber": "7400-100946-3",
          "FirstdayontheJob": "01-07-2022",
          "LastdayontheJob": "01-07-2022",
          "EstimatedLastdayontheJob": "01-07-2022",
          "JobAddress1": "1298 W HIGH AVE",
          "JobAddress2": "TOWMOTOR UNLOAD",
          "JobFullAddress": "1298 W HIGH AVE TOWMOTOR UNLOAD",
          "JobCounty": "Tuscarawas",
          "JobState": "Ohio",
          "CustomerCompanyName": "Sunrise Hospitality Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,193.09",
          "BalanceRemaining": "$6,193.09",
          "BranchNumber": 7400,
          "SubmitDate": "02-01-2022",
          "ClientonJob": "Sunrise Hospitality Inc"
        },
        {
          "WorkOrder": 4210645,
          "ParentWorkOrder": "",
          "JobName": "Brad & Donna Randolph",
          "JobNumber": 132758,
          "FirstdayontheJob": "01-14-2022",
          "LastdayontheJob": "01-24-2022",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2188 Deer Run Dr",
          "JobAddress2": "",
          "JobFullAddress": "2188 Deer Run Dr",
          "JobCounty": "FRANKLIN",
          "JobState": "Indiana",
          "CustomerCompanyName": "DeGeorge Ceilings",
          "RequestType": "Pre-Lien Notice to Owner of Mechanic's Lien Rights (Pre-existing Residential)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "N/A",
          "BranchNumber": 7347,
          "SubmitDate": "01-31-2022",
          "ClientonJob": "DeGeorge Ceilings"
        },
        {
          "WorkOrder": 4209672,
          "ParentWorkOrder": "",
          "JobName": "Jeff Ruby",
          "JobNumber": "7272-106673-9",
          "FirstdayontheJob": "01-24-2022",
          "LastdayontheJob": "01-24-2022",
          "EstimatedLastdayontheJob": "01-24-2022",
          "JobAddress1": "89 E Nationwide Blvd",
          "JobAddress2": "",
          "JobFullAddress": "89 E Nationwide Blvd",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "William J Shaffer",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$6,307.97",
          "BalanceRemaining": "$6,307.97",
          "BranchNumber": 7272,
          "SubmitDate": "01-27-2022",
          "ClientonJob": "William J Shaffer"
        },
        {
          "WorkOrder": 4209671,
          "ParentWorkOrder": "",
          "JobName": "Byers Porsche",
          "JobNumber": "7272-132739-24",
          "FirstdayontheJob": "01-18-2022",
          "LastdayontheJob": "01-18-2022",
          "EstimatedLastdayontheJob": "01-18-2022",
          "JobAddress1": "407 N Hamilton Rd",
          "JobAddress2": "",
          "JobFullAddress": "407 N Hamilton Rd",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$6,060.72",
          "BalanceRemaining": "$6,060.72",
          "BranchNumber": 7272,
          "SubmitDate": "01-28-2022",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4209670,
          "ParentWorkOrder": "",
          "JobName": "FURTHER FINANCIAL",
          "JobNumber": "7347-102659-12",
          "FirstdayontheJob": "01-24-2022",
          "LastdayontheJob": "01-24-2022",
          "EstimatedLastdayontheJob": "01-24-2022",
          "JobAddress1": "6184 CENTRE LOOP DR",
          "JobAddress2": "",
          "JobFullAddress": "6184 CENTRE LOOP DR",
          "JobCounty": "Butler",
          "JobState": "Ohio",
          "CustomerCompanyName": "True Cut Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$28,173.24",
          "BalanceRemaining": "$28,173.24",
          "BranchNumber": 7347,
          "SubmitDate": "01-27-2022",
          "ClientonJob": "True Cut Construction LLC"
        },
        {
          "WorkOrder": 4209669,
          "ParentWorkOrder": "",
          "JobName": "OTTERBEIN HOUSE",
          "JobNumber": "7347-102659-14",
          "FirstdayontheJob": "01-19-2022",
          "LastdayontheJob": "01-19-2022",
          "EstimatedLastdayontheJob": "01-19-2022",
          "JobAddress1": "201 Marge Schott Way",
          "JobAddress2": "5 UNITS",
          "JobFullAddress": "201 Marge Schott Way 5 UNITS",
          "JobCounty": "Warren",
          "JobState": "Ohio",
          "CustomerCompanyName": "True Cut Construction LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,478.51",
          "BalanceRemaining": "$5,478.51",
          "BranchNumber": 7347,
          "SubmitDate": "01-27-2022",
          "ClientonJob": "True Cut Construction LLC"
        },
        {
          "WorkOrder": 4209668,
          "ParentWorkOrder": "",
          "JobName": "One Medical Dublin",
          "JobNumber": "7348-132711-9",
          "FirstdayontheJob": "01-19-2022",
          "LastdayontheJob": "01-19-2022",
          "EstimatedLastdayontheJob": "01-19-2022",
          "JobAddress1": "6515 Longshore Loop #1a",
          "JobAddress2": "",
          "JobFullAddress": "6515 Longshore Loop #1a",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "Integrity Interiors US LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$21,274.31",
          "BalanceRemaining": "$21,274.31",
          "BranchNumber": 7348,
          "SubmitDate": "01-27-2022",
          "ClientonJob": "Integrity Interiors US LLC"
        },
        {
          "WorkOrder": 4209667,
          "ParentWorkOrder": "",
          "JobName": "Taco Bell / Genoa",
          "JobNumber": "7302-100963-124",
          "FirstdayontheJob": "01-18-2022",
          "LastdayontheJob": "01-18-2022",
          "EstimatedLastdayontheJob": "01-18-2022",
          "JobAddress1": "22251 OH-51 W",
          "JobAddress2": "",
          "JobFullAddress": "22251 OH-51 W",
          "JobCounty": "Ottawa",
          "JobState": "Ohio",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,814.44",
          "BalanceRemaining": "$10,814.44",
          "BranchNumber": 7302,
          "SubmitDate": "01-27-2022",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4209666,
          "ParentWorkOrder": "",
          "JobName": "Msa",
          "JobNumber": "7270-134638-15",
          "FirstdayontheJob": "01-19-2022",
          "LastdayontheJob": "01-19-2022",
          "EstimatedLastdayontheJob": "01-19-2022",
          "JobAddress1": "316 W 4th St",
          "JobAddress2": "",
          "JobFullAddress": "316 W 4th St",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "GPT Enterprise Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$17,276.60",
          "BalanceRemaining": "$17,276.60",
          "BranchNumber": 7270,
          "SubmitDate": "01-27-2022",
          "ClientonJob": "GPT Enterprise Inc"
        },
        {
          "WorkOrder": 4209005,
          "ParentWorkOrder": "",
          "JobName": "Oakland Uni Off Camp Reaserch",
          "JobNumber": "7310-101038-55",
          "FirstdayontheJob": "01-24-2022",
          "LastdayontheJob": "01-24-2022",
          "EstimatedLastdayontheJob": "01-24-2022",
          "JobAddress1": "2871 Research Dr",
          "JobAddress2": "",
          "JobFullAddress": "2871 Research Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$66,707.84",
          "BalanceRemaining": "$66,707.84",
          "BranchNumber": 7310,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4209004,
          "ParentWorkOrder": "",
          "JobName": "Evolution Grow",
          "JobNumber": "7308-104198-36",
          "FirstdayontheJob": "01-21-2022",
          "LastdayontheJob": "01-21-2022",
          "EstimatedLastdayontheJob": "01-21-2022",
          "JobAddress1": "1060 River Rd W",
          "JobAddress2": "",
          "JobFullAddress": "1060 River Rd W",
          "JobCounty": "CALHOUN",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$32,844.96",
          "BalanceRemaining": "$32,844.96",
          "BranchNumber": 7308,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4209003,
          "ParentWorkOrder": "",
          "JobName": "Dte Western Wayne Service Cent",
          "JobNumber": "7310-104648-16",
          "FirstdayontheJob": "01-21-2022",
          "LastdayontheJob": "01-21-2022",
          "EstimatedLastdayontheJob": "01-21-2022",
          "JobAddress1": "8001 Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "8001 Haggerty Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Brinker Team Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,222.09",
          "BalanceRemaining": "$30,222.09",
          "BranchNumber": 7310,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "Brinker Team Construction"
        },
        {
          "WorkOrder": 4209001,
          "ParentWorkOrder": "",
          "JobName": "Sema Garage",
          "JobNumber": "7304-103058-45",
          "FirstdayontheJob": "01-24-2022",
          "LastdayontheJob": "01-24-2022",
          "EstimatedLastdayontheJob": "01-24-2022",
          "JobAddress1": "14655 Jib St",
          "JobAddress2": "Office Area",
          "JobFullAddress": "14655 Jib St Office Area",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Siwek Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,674.27",
          "BalanceRemaining": "$15,674.27",
          "BranchNumber": 7304,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "Siwek Construction Co"
        },
        {
          "WorkOrder": 4209000,
          "ParentWorkOrder": "",
          "JobName": "GOLDSTEIN HOME",
          "JobNumber": "7308-104328-77",
          "FirstdayontheJob": "01-12-2022",
          "LastdayontheJob": "01-12-2022",
          "EstimatedLastdayontheJob": "01-12-2022",
          "JobAddress1": "52015 LAKE PARK DR",
          "JobAddress2": "",
          "JobFullAddress": "52015 LAKE PARK DR",
          "JobCounty": "Berrien",
          "JobState": "Michigan",
          "CustomerCompanyName": "Intext Concepts Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,617.72",
          "BalanceRemaining": "$9,617.72",
          "BranchNumber": 7308,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "Intext Concepts Inc"
        },
        {
          "WorkOrder": 4208999,
          "ParentWorkOrder": "",
          "JobName": "MATTAWAN CONSOLIDATED SCHOOLS",
          "JobNumber": "7308-104969-15",
          "FirstdayontheJob": "01-21-2022",
          "LastdayontheJob": "01-21-2022",
          "EstimatedLastdayontheJob": "01-21-2022",
          "JobAddress1": "56720 MURRY ST",
          "JobAddress2": "",
          "JobFullAddress": "56720 MURRY ST",
          "JobCounty": "VAN BUREN",
          "JobState": "Michigan",
          "CustomerCompanyName": "Premier 1 Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,013.04",
          "BalanceRemaining": "$9,013.04",
          "BranchNumber": 7308,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "Premier 1 Interiors LLC"
        },
        {
          "WorkOrder": 4208997,
          "ParentWorkOrder": "",
          "JobName": "GOODWILL",
          "JobNumber": "7308-105032-20",
          "FirstdayontheJob": "01-24-2022",
          "LastdayontheJob": "01-24-2022",
          "EstimatedLastdayontheJob": "01-24-2022",
          "JobAddress1": "1024 WASHINGTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "1024 WASHINGTON AVE",
          "JobCounty": "ALLEGAN",
          "JobState": "Michigan",
          "CustomerCompanyName": "Odanah Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,828.85",
          "BalanceRemaining": "$7,828.85",
          "BranchNumber": 7308,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "Odanah Construction"
        },
        {
          "WorkOrder": 4208994,
          "ParentWorkOrder": "",
          "JobName": "Springfield Oaks",
          "JobNumber": "7313-104476-9",
          "FirstdayontheJob": "01-24-2022",
          "LastdayontheJob": "01-24-2022",
          "EstimatedLastdayontheJob": "01-24-2022",
          "JobAddress1": "9741 Dixie Hwy",
          "JobAddress2": "",
          "JobFullAddress": "9741 Dixie Hwy",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,492.86",
          "BalanceRemaining": "$7,492.86",
          "BranchNumber": 7313,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "M & V Construction"
        },
        {
          "WorkOrder": 4208993,
          "ParentWorkOrder": "",
          "JobName": "Perum",
          "JobNumber": "7310-104587-9",
          "FirstdayontheJob": "01-24-2022",
          "LastdayontheJob": "01-24-2022",
          "EstimatedLastdayontheJob": "01-24-2022",
          "JobAddress1": "29101 Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "29101 Haggerty Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Danco Contracting Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,169.56",
          "BalanceRemaining": "$6,169.56",
          "BranchNumber": 7310,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "Danco Contracting Inc"
        },
        {
          "WorkOrder": 4208992,
          "ParentWorkOrder": "",
          "JobName": "I Heart Mac&Cheese Saginaw",
          "JobNumber": "7313-104138-7",
          "FirstdayontheJob": "01-20-2022",
          "LastdayontheJob": "01-20-2022",
          "EstimatedLastdayontheJob": "01-20-2022",
          "JobAddress1": "4510 State Street",
          "JobAddress2": "",
          "JobFullAddress": "4510 State Street",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Wolgast Corporation",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,012.73",
          "BalanceRemaining": "$6,012.73",
          "BranchNumber": 7313,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "Wolgast Corporation"
        },
        {
          "WorkOrder": 4208991,
          "ParentWorkOrder": "",
          "JobName": "Pizza Hut",
          "JobNumber": "7310-104761-37",
          "FirstdayontheJob": "01-19-2022",
          "LastdayontheJob": "01-19-2022",
          "EstimatedLastdayontheJob": "01-19-2022",
          "JobAddress1": "1241 Fort St",
          "JobAddress2": "Lock Box # 1234",
          "JobFullAddress": "1241 Fort St Lock Box # 1234",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Token Acoustical Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,754.22",
          "BalanceRemaining": "$5,754.22",
          "BranchNumber": 7310,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "Token Acoustical Inc"
        },
        {
          "WorkOrder": 4208990,
          "ParentWorkOrder": "",
          "JobName": "GSA DOS-Patrick McNamara Bldg.",
          "JobNumber": "7313-104691-16",
          "FirstdayontheJob": "01-18-2022",
          "LastdayontheJob": "01-18-2022",
          "EstimatedLastdayontheJob": "01-18-2022",
          "JobAddress1": "477 Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "477 Michigan Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Bluestone Const Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,255.03",
          "BalanceRemaining": "$5,255.03",
          "BranchNumber": 7313,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "Bluestone Const Group"
        },
        {
          "WorkOrder": 4208989,
          "ParentWorkOrder": "",
          "JobName": "Silver Shores Waterfront",
          "JobNumber": "7310-104761-38",
          "FirstdayontheJob": "01-21-2022",
          "LastdayontheJob": "01-21-2022",
          "EstimatedLastdayontheJob": "01-21-2022",
          "JobAddress1": "1 James Desana Dr",
          "JobAddress2": "",
          "JobFullAddress": "1 James Desana Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Token Acoustical Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,237.83",
          "BalanceRemaining": "$5,237.83",
          "BranchNumber": 7310,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "Token Acoustical Inc"
        },
        {
          "WorkOrder": 4208896,
          "ParentWorkOrder": 4181318,
          "JobName": "ELIZA JENNINGS",
          "JobNumber": "7400-102686-8",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "26376 JOHN ROAD",
          "JobAddress2": "2ND FLOOR",
          "JobFullAddress": "26376 JOHN ROAD 2ND FLOOR",
          "JobCounty": "CUYAHOGA",
          "JobState": "Ohio",
          "CustomerCompanyName": "Trinity Drywall & Paintin LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Draft",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,559.55",
          "BranchNumber": 7400,
          "SubmitDate": "",
          "ClientonJob": "Trinity Drywall & Paintin LLC"
        },
        {
          "WorkOrder": 4208870,
          "ParentWorkOrder": 4181318,
          "JobName": "ELIZA JENNINGS",
          "JobNumber": "7400-102686-8",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "26376 JOHN ROAD",
          "JobAddress2": "2ND FLOOR",
          "JobFullAddress": "26376 JOHN ROAD 2ND FLOOR",
          "JobCounty": "CUYAHOGA",
          "JobState": "Ohio",
          "CustomerCompanyName": "Trinity Drywall & Paintin LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,559.55",
          "BranchNumber": 7400,
          "SubmitDate": "01-26-2022",
          "ClientonJob": "Trinity Drywall & Paintin LLC"
        },
        {
          "WorkOrder": 4207106,
          "ParentWorkOrder": 4196739,
          "JobName": "University of Detroit",
          "JobNumber": "7310-104410-7",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "4001 W McNichols Rd",
          "JobAddress2": "",
          "JobFullAddress": "4001 W McNichols Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Shaffer Incorporated",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,516.24",
          "BranchNumber": 7310,
          "SubmitDate": "01-20-2022",
          "ClientonJob": "Shaffer Incorporated"
        },
        {
          "WorkOrder": 4207001,
          "ParentWorkOrder": 4186629,
          "JobName": "Eye Glass World",
          "JobNumber": "7308-102942-15",
          "FirstdayontheJob": "11-16-2021",
          "LastdayontheJob": "11-16-2021",
          "EstimatedLastdayontheJob": "11-16-2021",
          "JobAddress1": "3303 Alpine Ave NW",
          "JobAddress2": "",
          "JobFullAddress": "3303 Alpine Ave NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Pure Renovation Concepts Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,878.91",
          "BranchNumber": 7308,
          "SubmitDate": "01-20-2022",
          "ClientonJob": "Pure Renovation Concepts Inc"
        },
        {
          "WorkOrder": 4207000,
          "ParentWorkOrder": "",
          "JobName": "NW P1-4",
          "JobNumber": "7272-106673-7",
          "FirstdayontheJob": "01-14-2022",
          "LastdayontheJob": "01-14-2022",
          "EstimatedLastdayontheJob": "01-14-2022",
          "JobAddress1": "1 Nationwide Plaza",
          "JobAddress2": "",
          "JobFullAddress": "1 Nationwide Plaza",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "William J Shaffer",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$6,579.93",
          "BalanceRemaining": "$6,579.93",
          "BranchNumber": 7272,
          "SubmitDate": "01-20-2022",
          "ClientonJob": "William J Shaffer"
        },
        {
          "WorkOrder": 4206999,
          "ParentWorkOrder": "",
          "JobName": "STOROPACK",
          "JobNumber": "7347-102659-13",
          "FirstdayontheJob": "01-17-2022",
          "LastdayontheJob": "01-17-2022",
          "EstimatedLastdayontheJob": "01-17-2022",
          "JobAddress1": "4600 BRATE DR SUITE 100",
          "JobAddress2": "BUILDING 7",
          "JobFullAddress": "4600 BRATE DR SUITE 100 BUILDING 7",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "True Cut Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$51,055.77",
          "BalanceRemaining": "$51,055.77",
          "BranchNumber": 7347,
          "SubmitDate": "01-20-2022",
          "ClientonJob": "True Cut Construction LLC"
        },
        {
          "WorkOrder": 4206998,
          "ParentWorkOrder": "",
          "JobName": "Mobility Works",
          "JobNumber": "7302-101113-29",
          "FirstdayontheJob": "01-17-2022",
          "LastdayontheJob": "01-17-2022",
          "EstimatedLastdayontheJob": "01-17-2022",
          "JobAddress1": "9675 South Compass Drive",
          "JobAddress2": "",
          "JobFullAddress": "9675 South Compass Drive",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$27,951.79",
          "BalanceRemaining": "$27,951.79",
          "BranchNumber": 7302,
          "SubmitDate": "01-20-2022",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4206997,
          "ParentWorkOrder": "",
          "JobName": "Metcor",
          "JobNumber": "7347-134638-12",
          "FirstdayontheJob": "01-17-2022",
          "LastdayontheJob": "01-17-2022",
          "EstimatedLastdayontheJob": "01-17-2022",
          "JobAddress1": "2859 Boudinot Ave",
          "JobAddress2": "",
          "JobFullAddress": "2859 Boudinot Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "GPT Enterprise Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$12,227.40",
          "BalanceRemaining": "$12,227.40",
          "BranchNumber": 7347,
          "SubmitDate": "01-20-2022",
          "ClientonJob": "GPT Enterprise Inc"
        },
        {
          "WorkOrder": 4206996,
          "ParentWorkOrder": "",
          "JobName": "Pep Boys Auto Parts & Service",
          "JobNumber": "7272-116328-6",
          "FirstdayontheJob": "01-13-2022",
          "LastdayontheJob": "01-13-2022",
          "EstimatedLastdayontheJob": "01-13-2022",
          "JobAddress1": "1321 Morse Rd",
          "JobAddress2": "",
          "JobFullAddress": "1321 Morse Rd",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Excel Construction Svcs",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,650.10",
          "BalanceRemaining": "$5,650.10",
          "BranchNumber": 7272,
          "SubmitDate": "01-20-2022",
          "ClientonJob": "Excel Construction Svcs"
        },
        {
          "WorkOrder": 4206845,
          "ParentWorkOrder": "",
          "JobName": "Dte Western Wayne Service Cent",
          "JobNumber": "7310-104648-16",
          "FirstdayontheJob": "01-14-2022",
          "LastdayontheJob": "01-14-2022",
          "EstimatedLastdayontheJob": "01-14-2022",
          "JobAddress1": "8001 Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "8001 Haggerty Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Brinker Team Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$24,140.39",
          "BalanceRemaining": "$24,140.39",
          "BranchNumber": 7310,
          "SubmitDate": "01-19-2022",
          "ClientonJob": "Brinker Team Construction"
        },
        {
          "WorkOrder": 4206844,
          "ParentWorkOrder": "",
          "JobName": "Clio Elementary School",
          "JobNumber": "7313-104368-27",
          "FirstdayontheJob": "01-17-2022",
          "LastdayontheJob": "01-17-2022",
          "EstimatedLastdayontheJob": "01-17-2022",
          "JobAddress1": "10271 North Clio Rd",
          "JobAddress2": "",
          "JobFullAddress": "10271 North Clio Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elkhorn Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$13,312.68",
          "BalanceRemaining": "$13,312.68",
          "BranchNumber": 7313,
          "SubmitDate": "01-19-2022",
          "ClientonJob": "Elkhorn Construction"
        },
        {
          "WorkOrder": 4206843,
          "ParentWorkOrder": "",
          "JobName": "Affordable Dentures",
          "JobNumber": "7310-129211-79",
          "FirstdayontheJob": "01-17-2022",
          "LastdayontheJob": "01-17-2022",
          "EstimatedLastdayontheJob": "01-17-2022",
          "JobAddress1": "28535 Schoolcraft rd",
          "JobAddress2": "",
          "JobFullAddress": "28535 Schoolcraft rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lawson Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,673.05",
          "BalanceRemaining": "$8,673.05",
          "BranchNumber": 7310,
          "SubmitDate": "01-19-2022",
          "ClientonJob": "Lawson Contracting"
        },
        {
          "WorkOrder": 4206842,
          "ParentWorkOrder": "",
          "JobName": "Rebath",
          "JobNumber": "7313-137388-19",
          "FirstdayontheJob": "01-14-2022",
          "LastdayontheJob": "01-14-2022",
          "EstimatedLastdayontheJob": "01-14-2022",
          "JobAddress1": "465 Stephenson Highway",
          "JobAddress2": "",
          "JobFullAddress": "465 Stephenson Highway",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Patriot Team Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,439.49",
          "BalanceRemaining": "$7,439.49",
          "BranchNumber": 7313,
          "SubmitDate": "01-19-2022",
          "ClientonJob": "Patriot Team Inc"
        },
        {
          "WorkOrder": 4206841,
          "ParentWorkOrder": "",
          "JobName": "South Hill School # 3227",
          "JobNumber": "7313-102953-47",
          "FirstdayontheJob": "01-17-2022",
          "LastdayontheJob": "01-17-2022",
          "EstimatedLastdayontheJob": "01-17-2022",
          "JobAddress1": "4200 W Quarton Rd",
          "JobAddress2": "",
          "JobFullAddress": "4200 W Quarton Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Contracting",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,828.10",
          "BalanceRemaining": "$5,828.10",
          "BranchNumber": 7313,
          "SubmitDate": "01-19-2022",
          "ClientonJob": "Commercial Contracting"
        },
        {
          "WorkOrder": 4206768,
          "ParentWorkOrder": 4200024,
          "JobName": "CADILLAC RETAIL DEV",
          "JobNumber": "7308-104549-16",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "9075 EAST 34 RD",
          "JobAddress2": "",
          "JobFullAddress": "9075 EAST 34 RD",
          "JobCounty": "WEXFORD",
          "JobState": "Michigan",
          "CustomerCompanyName": "Superior Designs Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,586.53",
          "BranchNumber": 7308,
          "SubmitDate": "01-19-2022",
          "ClientonJob": "Superior Designs Inc"
        },
        {
          "WorkOrder": 4205571,
          "ParentWorkOrder": 4196748,
          "JobName": "Tapper’s Fine Jewelry",
          "JobNumber": "7310-101038-50",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "20800 Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "20800 Haggerty Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$19,611.19",
          "BranchNumber": 7310,
          "SubmitDate": "01-16-2022",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4204993,
          "ParentWorkOrder": "",
          "JobName": "Circle K Pickerington",
          "JobNumber": "7272-132739-26",
          "FirstdayontheJob": "01-10-2022",
          "LastdayontheJob": "01-10-2022",
          "EstimatedLastdayontheJob": "01-10-2022",
          "JobAddress1": "29 Hill South Road",
          "JobAddress2": "",
          "JobFullAddress": "29 Hill South Road",
          "JobCounty": "FAIRFIELD",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$22,312.53",
          "BalanceRemaining": "$22,312.53",
          "BranchNumber": 7272,
          "SubmitDate": "01-13-2022",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4204992,
          "ParentWorkOrder": "",
          "JobName": "TOWNE PLACE SUITES",
          "JobNumber": "7400-100946-3",
          "FirstdayontheJob": "01-07-2022",
          "LastdayontheJob": "01-07-2022",
          "EstimatedLastdayontheJob": "01-07-2022",
          "JobAddress1": "1298 W HIGH AVE",
          "JobAddress2": "TOWMOTOR UNLOAD",
          "JobFullAddress": "1298 W HIGH AVE TOWMOTOR UNLOAD",
          "JobCounty": "Tuscarawas",
          "JobState": "Ohio",
          "CustomerCompanyName": "Sunrise Hospitality Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$6,193.09",
          "BalanceRemaining": "$6,193.09",
          "BranchNumber": 7400,
          "SubmitDate": "01-13-2022",
          "ClientonJob": "Sunrise Hospitality Inc"
        },
        {
          "WorkOrder": 4204991,
          "ParentWorkOrder": "",
          "JobName": "OHIO CATERPILLER",
          "JobNumber": "7348-132746-4",
          "FirstdayontheJob": "01-07-2022",
          "LastdayontheJob": "01-07-2022",
          "EstimatedLastdayontheJob": "01-07-2022",
          "JobAddress1": "1281 BURKNER DR",
          "JobAddress2": "",
          "JobFullAddress": "1281 BURKNER DR",
          "JobCounty": "MIAMI",
          "JobState": "Ohio",
          "CustomerCompanyName": "Kyros Development",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,315.96",
          "BalanceRemaining": "$5,315.96",
          "BranchNumber": 7348,
          "SubmitDate": "01-13-2022",
          "ClientonJob": "Kyros Development"
        },
        {
          "WorkOrder": 4204990,
          "ParentWorkOrder": "",
          "JobName": "Portsmouth Apartments",
          "JobNumber": "7272-101113-33",
          "FirstdayontheJob": "01-05-2022",
          "LastdayontheJob": "01-05-2022",
          "EstimatedLastdayontheJob": "01-05-2022",
          "JobAddress1": "813 Campbell Ave",
          "JobAddress2": "",
          "JobFullAddress": "813 Campbell Ave",
          "JobCounty": "SCIOTO",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$12,534.85",
          "BalanceRemaining": "$12,534.85",
          "BranchNumber": 7272,
          "SubmitDate": "01-17-2022",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4204989,
          "ParentWorkOrder": "",
          "JobName": "Classic Living Home",
          "JobNumber": "7347-132879-4",
          "FirstdayontheJob": "01-06-2022",
          "LastdayontheJob": "01-06-2022",
          "EstimatedLastdayontheJob": "01-06-2022",
          "JobAddress1": "10 Old Orchard",
          "JobAddress2": "Joe 513-616-5506",
          "JobFullAddress": "10 Old Orchard Joe 513-616-5506",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Duke Drywall",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$5,368.61",
          "BalanceRemaining": "$5,368.61",
          "BranchNumber": 7347,
          "SubmitDate": "",
          "ClientonJob": "Duke Drywall"
        },
        {
          "WorkOrder": 4204588,
          "ParentWorkOrder": "",
          "JobName": "CLEARCHOICE DENTAL IMPLANT CEN",
          "JobNumber": "7308-153258-2",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "770 KENMORE AVE SE",
          "JobAddress2": "SUITE 200",
          "JobFullAddress": "770 KENMORE AVE SE SUITE 200",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "TNW Properties LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$16,138.50",
          "BalanceRemaining": "$16,138.50",
          "BranchNumber": 7308,
          "SubmitDate": "01-12-2022",
          "ClientonJob": "TNW Properties LLC"
        },
        {
          "WorkOrder": 4204587,
          "ParentWorkOrder": "",
          "JobName": "Dte Western Wayne Service Cent",
          "JobNumber": "7310-104648-16",
          "FirstdayontheJob": "01-10-2022",
          "LastdayontheJob": "01-10-2022",
          "EstimatedLastdayontheJob": "01-10-2022",
          "JobAddress1": "8001 Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "8001 Haggerty Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Brinker Team Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,356.75",
          "BalanceRemaining": "$15,356.75",
          "BranchNumber": 7310,
          "SubmitDate": "01-12-2022",
          "ClientonJob": "Brinker Team Construction"
        },
        {
          "WorkOrder": 4204586,
          "ParentWorkOrder": "",
          "JobName": "Nof Gordons Burton",
          "JobNumber": "7304-104137-24",
          "FirstdayontheJob": "01-10-2022",
          "LastdayontheJob": "01-10-2022",
          "EstimatedLastdayontheJob": "01-10-2022",
          "JobAddress1": "527 N Woodbridge St.",
          "JobAddress2": "",
          "JobFullAddress": "527 N Woodbridge St.",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Wobig Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$8,218.51",
          "BalanceRemaining": "$8,218.51",
          "BranchNumber": 7304,
          "SubmitDate": "",
          "ClientonJob": "Wobig Construction"
        },
        {
          "WorkOrder": 4204585,
          "ParentWorkOrder": "",
          "JobName": "Nof Elder Ford",
          "JobNumber": "7310-134589-24",
          "FirstdayontheJob": "01-10-2022",
          "LastdayontheJob": "01-10-2022",
          "EstimatedLastdayontheJob": "01-10-2022",
          "JobAddress1": "12425 30 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "12425 30 Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "Acoustech Ceiling Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,531.23",
          "BalanceRemaining": "$6,531.23",
          "BranchNumber": 7310,
          "SubmitDate": "01-12-2022",
          "ClientonJob": "Acoustech Ceiling Inc"
        },
        {
          "WorkOrder": 4204584,
          "ParentWorkOrder": "",
          "JobName": "Sign Flight Supp Hanger 408",
          "JobNumber": "7310-101038-52",
          "FirstdayontheJob": "01-05-2022",
          "LastdayontheJob": "01-05-2022",
          "EstimatedLastdayontheJob": "01-05-2022",
          "JobAddress1": "29900 Northline Rd",
          "JobAddress2": "",
          "JobFullAddress": "29900 Northline Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,486.85",
          "BalanceRemaining": "$6,486.85",
          "BranchNumber": 7310,
          "SubmitDate": "01-12-2022",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4204583,
          "ParentWorkOrder": "",
          "JobName": "Chelsea",
          "JobNumber": "7312-104800-2",
          "FirstdayontheJob": "01-06-2022",
          "LastdayontheJob": "01-06-2022",
          "EstimatedLastdayontheJob": "01-06-2022",
          "JobAddress1": "805 E Morrell St",
          "JobAddress2": "",
          "JobFullAddress": "805 E Morrell St",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "W A Foote Memorial Hosp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,982.69",
          "BalanceRemaining": "$5,982.69",
          "BranchNumber": 7312,
          "SubmitDate": "01-12-2022",
          "ClientonJob": "W A Foote Memorial Hosp"
        },
        {
          "WorkOrder": 4204582,
          "ParentWorkOrder": "",
          "JobName": "LaBelle Development",
          "JobNumber": "7422-104492-33",
          "FirstdayontheJob": "01-05-2022",
          "LastdayontheJob": "01-05-2022",
          "EstimatedLastdayontheJob": "01-05-2022",
          "JobAddress1": "47914 Harbor Drive",
          "JobAddress2": "",
          "JobFullAddress": "47914 Harbor Drive",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DGS Drywall LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,306.06",
          "BalanceRemaining": "$5,306.06",
          "BranchNumber": 7422,
          "SubmitDate": "01-12-2022",
          "ClientonJob": "DGS Drywall LLC"
        },
        {
          "WorkOrder": 4202792,
          "ParentWorkOrder": 4196635,
          "JobName": "Brinker Team Construction",
          "JobNumber": "7310-104648-14",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "2047 Woodward Ave/25 W Elizabeth Street",
          "JobAddress2": "",
          "JobFullAddress": "2047 Woodward Ave/25 W Elizabeth Street",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Brinker Team Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,824.62",
          "BranchNumber": 7310,
          "SubmitDate": "01-07-2022",
          "ClientonJob": "Brinker Team Construction"
        },
        {
          "WorkOrder": 4202481,
          "ParentWorkOrder": "",
          "JobName": "Laibe Electric",
          "JobNumber": "7302-104942-7",
          "FirstdayontheJob": "01-05-2022",
          "LastdayontheJob": "01-05-2022",
          "EstimatedLastdayontheJob": "01-05-2022",
          "JobAddress1": "26440 Southpoint Rd",
          "JobAddress2": "",
          "JobFullAddress": "26440 Southpoint Rd",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "The Spieker Company",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$6,931.49",
          "BalanceRemaining": "$6,931.49",
          "BranchNumber": 7302,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "The Spieker Company"
        },
        {
          "WorkOrder": 4202480,
          "ParentWorkOrder": "",
          "JobName": "Anthem - East Walnut Hills",
          "JobNumber": "7347-100959-19",
          "FirstdayontheJob": "01-04-2022",
          "LastdayontheJob": "01-04-2022",
          "EstimatedLastdayontheJob": "01-04-2022",
          "JobAddress1": "1351 William Howard Taft Rd",
          "JobAddress2": "B2-6-ST",
          "JobFullAddress": "1351 William Howard Taft Rd B2-6-ST",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Morningside Group Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$46,689.35",
          "BalanceRemaining": "$46,689.35",
          "BranchNumber": 7347,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "Morningside Group Inc"
        },
        {
          "WorkOrder": 4202479,
          "ParentWorkOrder": "",
          "JobName": "CULVERS",
          "JobNumber": "7348-107422-2",
          "FirstdayontheJob": "12-29-2021",
          "LastdayontheJob": "12-29-2021",
          "EstimatedLastdayontheJob": "12-29-2021",
          "JobAddress1": "6425 MILLER LN",
          "JobAddress2": "",
          "JobFullAddress": "6425 MILLER LN",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "Avail Interiors",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,633.04",
          "BalanceRemaining": "$15,633.04",
          "BranchNumber": 7348,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "Avail Interiors"
        },
        {
          "WorkOrder": 4202380,
          "ParentWorkOrder": "",
          "JobName": "Covid Base",
          "JobNumber": "7304-104137-23",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "527 N Washington",
          "JobAddress2": "",
          "JobFullAddress": "527 N Washington",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Wobig Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$70,703.27",
          "BalanceRemaining": "$70,703.27",
          "BranchNumber": 7304,
          "SubmitDate": "",
          "ClientonJob": "Wobig Construction"
        },
        {
          "WorkOrder": 4202379,
          "ParentWorkOrder": "",
          "JobName": "Imlay City High Auditorium",
          "JobNumber": "7313-104368-24",
          "FirstdayontheJob": "12-29-2021",
          "LastdayontheJob": "12-29-2021",
          "EstimatedLastdayontheJob": "12-29-2021",
          "JobAddress1": "1001 Norlin Dr",
          "JobAddress2": "",
          "JobFullAddress": "1001 Norlin Dr",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elkhorn Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$22,234.57",
          "BalanceRemaining": "$22,234.57",
          "BranchNumber": 7313,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "Elkhorn Construction"
        },
        {
          "WorkOrder": 4202378,
          "ParentWorkOrder": "",
          "JobName": "Wework Reno 6th & 7th Fls",
          "JobNumber": "7310-101038-53",
          "FirstdayontheJob": "01-04-2022",
          "LastdayontheJob": "01-04-2022",
          "EstimatedLastdayontheJob": "01-04-2022",
          "JobAddress1": "1001 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "1001 Woodward Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$17,252.56",
          "BalanceRemaining": "$17,252.56",
          "BranchNumber": 7310,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4202377,
          "ParentWorkOrder": "",
          "JobName": "CLEARCHOICE DENTAL IMPLANT CEN",
          "JobNumber": "7308-153258-2",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "770 KENMOOR AVE SE",
          "JobAddress2": "SUITE 200",
          "JobFullAddress": "770 KENMOOR AVE SE SUITE 200",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "TNN Properties",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$16,138.50",
          "BalanceRemaining": "$16,138.50",
          "BranchNumber": 7308,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "TNN Properties"
        },
        {
          "WorkOrder": 4202376,
          "ParentWorkOrder": "",
          "JobName": "Blossom",
          "JobNumber": "7310-105002-41",
          "FirstdayontheJob": "01-05-2022",
          "LastdayontheJob": "01-05-2022",
          "EstimatedLastdayontheJob": "01-05-2022",
          "JobAddress1": "8175 Movie Dr",
          "JobAddress2": "",
          "JobFullAddress": "8175 Movie Dr",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "New Hudson Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,993.95",
          "BalanceRemaining": "$9,993.95",
          "BranchNumber": 7310,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "New Hudson Construction"
        },
        {
          "WorkOrder": 4202375,
          "ParentWorkOrder": "",
          "JobName": "Hudson BLK TWR General Trades",
          "JobNumber": "7313-101349-56",
          "FirstdayontheJob": "01-03-2022",
          "LastdayontheJob": "01-03-2022",
          "EstimatedLastdayontheJob": "01-03-2022",
          "JobAddress1": "1208 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "1208 Woodward Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Barton Malow Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,831.93",
          "BalanceRemaining": "$9,831.93",
          "BranchNumber": 7313,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "Barton Malow Company"
        },
        {
          "WorkOrder": 4202374,
          "ParentWorkOrder": "",
          "JobName": "Detroit Employment Solutions",
          "JobNumber": "7313-104619-5",
          "FirstdayontheJob": "12-29-2021",
          "LastdayontheJob": "12-29-2021",
          "EstimatedLastdayontheJob": "12-29-2021",
          "JobAddress1": "18017 E Warren Ave",
          "JobAddress2": "",
          "JobFullAddress": "18017 E Warren Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Inter Office Const Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,382.93",
          "BalanceRemaining": "$9,382.93",
          "BranchNumber": 7313,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "Inter Office Const Inc"
        },
        {
          "WorkOrder": 4202372,
          "ParentWorkOrder": "",
          "JobName": "Carriage Place",
          "JobNumber": "7310-104009-52",
          "FirstdayontheJob": "12-17-2021",
          "LastdayontheJob": "12-17-2021",
          "EstimatedLastdayontheJob": "12-17-2021",
          "JobAddress1": "255 Carriage Cir Dr",
          "JobAddress2": "",
          "JobFullAddress": "255 Carriage Cir Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Professional Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,147.43",
          "BalanceRemaining": "$9,147.43",
          "BranchNumber": 7310,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "Professional Drywall"
        },
        {
          "WorkOrder": 4202370,
          "ParentWorkOrder": "",
          "JobName": "Dte Western Wayne Service Cent",
          "JobNumber": "7310-104648-16",
          "FirstdayontheJob": "12-27-2021",
          "LastdayontheJob": "12-27-2021",
          "EstimatedLastdayontheJob": "12-27-2021",
          "JobAddress1": "8001 Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "8001 Haggerty Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Brinker Team Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,842.52",
          "BalanceRemaining": "$8,842.52",
          "BranchNumber": 7310,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "Brinker Team Construction"
        },
        {
          "WorkOrder": 4202369,
          "ParentWorkOrder": "",
          "JobName": "East China Schools District",
          "JobNumber": "7422-104627-4",
          "FirstdayontheJob": "01-03-2022",
          "LastdayontheJob": "01-03-2022",
          "EstimatedLastdayontheJob": "01-03-2022",
          "JobAddress1": "1585 Meisner Rd",
          "JobAddress2": "",
          "JobFullAddress": "1585 Meisner Rd",
          "JobCounty": "Saint Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "R E Leggette Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,898.56",
          "BalanceRemaining": "$6,898.56",
          "BranchNumber": 7422,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "R E Leggette Co"
        },
        {
          "WorkOrder": 4202368,
          "ParentWorkOrder": "",
          "JobName": "Intex",
          "JobNumber": "7310-104587-7",
          "FirstdayontheJob": "12-29-2021",
          "LastdayontheJob": "12-29-2021",
          "EstimatedLastdayontheJob": "12-29-2021",
          "JobAddress1": "39001 Schoolcraft Rd",
          "JobAddress2": "",
          "JobFullAddress": "39001 Schoolcraft Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Danco Contracting Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,584.61",
          "BalanceRemaining": "$6,584.61",
          "BranchNumber": 7310,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "Danco Contracting Inc"
        },
        {
          "WorkOrder": 4202367,
          "ParentWorkOrder": "",
          "JobName": "Nof Dispatch Office",
          "JobNumber": "7310-104761-24",
          "FirstdayontheJob": "01-03-2022",
          "LastdayontheJob": "01-03-2022",
          "EstimatedLastdayontheJob": "01-03-2022",
          "JobAddress1": "7047 W Warren Rd",
          "JobAddress2": "",
          "JobFullAddress": "7047 W Warren Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Token Acoustical Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,538.26",
          "BalanceRemaining": "$5,538.26",
          "BranchNumber": 7310,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "Token Acoustical Inc"
        },
        {
          "WorkOrder": 4202366,
          "ParentWorkOrder": "",
          "JobName": "EL RANCHO RESTURANT",
          "JobNumber": "7308-105085-10",
          "FirstdayontheJob": "12-29-2021",
          "LastdayontheJob": "12-29-2021",
          "EstimatedLastdayontheJob": "12-29-2021",
          "JobAddress1": "4743 W US HIGHWAY 10 STE 1",
          "JobAddress2": "",
          "JobFullAddress": "4743 W US HIGHWAY 10 STE 1",
          "JobCounty": "MASON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Enriquez Constr Co LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,382.75",
          "BalanceRemaining": "$5,382.75",
          "BranchNumber": 7308,
          "SubmitDate": "01-06-2022",
          "ClientonJob": "Enriquez Constr Co LLC"
        },
        {
          "WorkOrder": 4200027,
          "ParentWorkOrder": "",
          "JobName": "CLEARCHOICE DENTAL IMPLANT CEN",
          "JobNumber": "7308-153258-2",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "770 Kenmoor Ave SE",
          "JobAddress2": "SUITE 200",
          "JobFullAddress": "770 Kenmoor Ave SE SUITE 200",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "TNN Properties",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,138.50",
          "BranchNumber": 7308,
          "SubmitDate": "12-29-2021",
          "ClientonJob": "TNN Properties"
        },
        {
          "WorkOrder": 4200026,
          "ParentWorkOrder": "",
          "JobName": "Dollar General",
          "JobNumber": "7304-103135-4",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "17080 Lincoln Rd",
          "JobAddress2": "",
          "JobFullAddress": "17080 Lincoln Rd",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Quality Design Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,316.42",
          "BranchNumber": 7304,
          "SubmitDate": "12-29-2021",
          "ClientonJob": "Quality Design Inc"
        },
        {
          "WorkOrder": 4200025,
          "ParentWorkOrder": "",
          "JobName": "Dte Western Wayne Service Cent",
          "JobNumber": "7310-104648-16",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "8001 Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "8001 Haggerty Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Brinker Team Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,842.52",
          "BranchNumber": 7310,
          "SubmitDate": "12-29-2021",
          "ClientonJob": "Brinker Team Construction"
        },
        {
          "WorkOrder": 4200024,
          "ParentWorkOrder": "",
          "JobName": "CADILLAC RETAIL DEV",
          "JobNumber": "7308-104549-16",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "9075 EAST 34 RD",
          "JobAddress2": "",
          "JobFullAddress": "9075 EAST 34 RD",
          "JobCounty": "WEXFORD",
          "JobState": "Michigan",
          "CustomerCompanyName": "Superior Designs Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,586.53",
          "BranchNumber": 7308,
          "SubmitDate": "12-29-2021",
          "ClientonJob": "Superior Designs Inc"
        },
        {
          "WorkOrder": 4200023,
          "ParentWorkOrder": "",
          "JobName": "Songbird",
          "JobNumber": "7313-104546-66",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "Songbird Lane Lot C",
          "JobAddress2": "",
          "JobFullAddress": "Songbird Lane Lot C",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,088.25",
          "BranchNumber": 7313,
          "SubmitDate": "",
          "ClientonJob": "SC Construction"
        },
        {
          "WorkOrder": 4200022,
          "ParentWorkOrder": "",
          "JobName": "Blossom",
          "JobNumber": "7310-105002-41",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "8175 Movie Dr",
          "JobAddress2": "",
          "JobFullAddress": "8175 Movie Dr",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "New Hudson Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,150.96",
          "BranchNumber": 7310,
          "SubmitDate": "12-29-2021",
          "ClientonJob": "New Hudson Construction"
        },
        {
          "WorkOrder": 4200021,
          "ParentWorkOrder": "",
          "JobName": "Wrigley Center",
          "JobNumber": "7422-104843-24",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "318 Grand River Avenue",
          "JobAddress2": "",
          "JobFullAddress": "318 Grand River Avenue",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,659.76",
          "BranchNumber": 7422,
          "SubmitDate": "12-29-2021",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4199888,
          "ParentWorkOrder": "",
          "JobName": "MERCY LOVELAND",
          "JobNumber": "7347-102659-11",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "10562 LOVELAND-MADEIRA RD",
          "JobAddress2": "",
          "JobFullAddress": "10562 LOVELAND-MADEIRA RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "True Cut Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,538.35",
          "BranchNumber": 7347,
          "SubmitDate": "12-29-2021",
          "ClientonJob": "True Cut Construction LLC"
        },
        {
          "WorkOrder": 4199887,
          "ParentWorkOrder": "",
          "JobName": "Kingsdale Senior Living",
          "JobNumber": "7272-101113-30",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "3240 Tremont Rd",
          "JobAddress2": "",
          "JobFullAddress": "3240 Tremont Rd",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$68,691.09",
          "BranchNumber": 7272,
          "SubmitDate": "12-29-2021",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4199886,
          "ParentWorkOrder": "",
          "JobName": "Seven Hills Middle School Reno",
          "JobNumber": "7347-132770-4",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "5400 Redbank Rd",
          "JobAddress2": "",
          "JobFullAddress": "5400 Redbank Rd",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "HGC Construction Co.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,693.50",
          "BranchNumber": 7347,
          "SubmitDate": "12-29-2021",
          "ClientonJob": "HGC Construction Co."
        },
        {
          "WorkOrder": 4199885,
          "ParentWorkOrder": "",
          "JobName": "HARBOR FREIGHT",
          "JobNumber": "7400-134575-9",
          "FirstdayontheJob": "12-28-2021",
          "LastdayontheJob": "12-28-2021",
          "EstimatedLastdayontheJob": "12-28-2021",
          "JobAddress1": "1143 FRONT AVE",
          "JobAddress2": "1ST FLOOR",
          "JobFullAddress": "1143 FRONT AVE 1ST FLOOR",
          "JobCounty": "Tuscarawas",
          "JobState": "Ohio",
          "CustomerCompanyName": "Construction Concepts",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,656.25",
          "BranchNumber": 7400,
          "SubmitDate": "12-29-2021",
          "ClientonJob": "Construction Concepts"
        },
        {
          "WorkOrder": 4199507,
          "ParentWorkOrder": 4185420,
          "JobName": "Ford Building Six",
          "JobNumber": "7313-101349-50",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "20600 Rotunda Dr",
          "JobAddress2": "",
          "JobFullAddress": "20600 Rotunda Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Barton Malow Company",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$36,126.42",
          "BranchNumber": 7313,
          "SubmitDate": "12-28-2021",
          "ClientonJob": "Barton Malow Company"
        },
        {
          "WorkOrder": 4199316,
          "ParentWorkOrder": 4194904,
          "JobName": "Njp Hmor 1900 S Channel",
          "JobNumber": "7422-104499-9",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "1900 S Channel Dr",
          "JobAddress2": "",
          "JobFullAddress": "1900 S Channel Dr",
          "JobCounty": "Saint Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "Picklehaupt Drywall",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$30,365.27",
          "BranchNumber": 7422,
          "SubmitDate": "12-27-2021",
          "ClientonJob": "Picklehaupt Drywall"
        },
        {
          "WorkOrder": 4198457,
          "ParentWorkOrder": "",
          "JobName": "Holiday Inn",
          "JobNumber": "7308-102293-17",
          "FirstdayontheJob": "12-21-2021",
          "LastdayontheJob": "12-21-2021",
          "EstimatedLastdayontheJob": "12-21-2021",
          "JobAddress1": "1005 Perry Ave",
          "JobAddress2": "",
          "JobFullAddress": "1005 Perry Ave",
          "JobCounty": "MECOSTA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Harbaugh Const",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$20,907.94",
          "BranchNumber": 7308,
          "SubmitDate": "12-22-2021",
          "ClientonJob": "Harbaugh Const"
        },
        {
          "WorkOrder": 4198456,
          "ParentWorkOrder": "",
          "JobName": "Vassar Agriculture",
          "JobNumber": "7304-104565-14",
          "FirstdayontheJob": "12-21-2021",
          "LastdayontheJob": "12-21-2021",
          "EstimatedLastdayontheJob": "12-21-2021",
          "JobAddress1": "600 E Huron Ave",
          "JobAddress2": "",
          "JobFullAddress": "600 E Huron Ave",
          "JobCounty": "TUSCOLA",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construct",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,537.61",
          "BranchNumber": 7304,
          "SubmitDate": "12-22-2021",
          "ClientonJob": "American Family Construct"
        },
        {
          "WorkOrder": 4198455,
          "ParentWorkOrder": "",
          "JobName": "AFFORDABLE DENTURES",
          "JobNumber": "7308-129211-75",
          "FirstdayontheJob": "12-21-2021",
          "LastdayontheJob": "12-21-2021",
          "EstimatedLastdayontheJob": "12-21-2021",
          "JobAddress1": "333C N MARKETPLACE BLVD",
          "JobAddress2": "",
          "JobFullAddress": "333C N MARKETPLACE BLVD",
          "JobCounty": "EATON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lawson Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,670.90",
          "BranchNumber": 7308,
          "SubmitDate": "12-22-2021",
          "ClientonJob": "Lawson Contracting"
        },
        {
          "WorkOrder": 4198454,
          "ParentWorkOrder": "",
          "JobName": "Cookie Crumble Royal Oak",
          "JobNumber": "7313-100963-131",
          "FirstdayontheJob": "12-21-2021",
          "LastdayontheJob": "12-21-2021",
          "EstimatedLastdayontheJob": "12-21-2021",
          "JobAddress1": "30955 Woodward",
          "JobAddress2": "Suite F715",
          "JobFullAddress": "30955 Woodward Suite F715",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,257.26",
          "BranchNumber": 7313,
          "SubmitDate": "12-22-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4198453,
          "ParentWorkOrder": "",
          "JobName": "Hudson's Block Core",
          "JobNumber": "7313-101349-54",
          "FirstdayontheJob": "12-21-2021",
          "LastdayontheJob": "12-21-2021",
          "EstimatedLastdayontheJob": "12-21-2021",
          "JobAddress1": "1208 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "1208 Woodward Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Barton Malow Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,588.38",
          "BranchNumber": 7313,
          "SubmitDate": "12-22-2021",
          "ClientonJob": "Barton Malow Company"
        },
        {
          "WorkOrder": 4198452,
          "ParentWorkOrder": "",
          "JobName": "Subway -- St. Ignas",
          "JobNumber": "7304-137375-4",
          "FirstdayontheJob": "12-21-2021",
          "LastdayontheJob": "12-21-2021",
          "EstimatedLastdayontheJob": "12-21-2021",
          "JobAddress1": "134 US-2",
          "JobAddress2": "",
          "JobFullAddress": "134 US-2",
          "JobCounty": "Mackinac",
          "JobState": "Michigan",
          "CustomerCompanyName": "DCM Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,356.22",
          "BranchNumber": 7304,
          "SubmitDate": "12-22-2021",
          "ClientonJob": "DCM Construction Inc"
        },
        {
          "WorkOrder": 4198451,
          "ParentWorkOrder": "",
          "JobName": "M&M Equities",
          "JobNumber": "7308-104328-72",
          "FirstdayontheJob": "12-21-2021",
          "LastdayontheJob": "12-21-2021",
          "EstimatedLastdayontheJob": "12-21-2021",
          "JobAddress1": "11039 Water Tower Court",
          "JobAddress2": "",
          "JobFullAddress": "11039 Water Tower Court",
          "JobCounty": "Ottawa",
          "JobState": "Michigan",
          "CustomerCompanyName": "Intext Concepts Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,076.34",
          "BranchNumber": 7308,
          "SubmitDate": "12-22-2021",
          "ClientonJob": "Intext Concepts Inc"
        },
        {
          "WorkOrder": 4198259,
          "ParentWorkOrder": "",
          "JobName": "DT Springfield",
          "JobNumber": "7348-106905-23",
          "FirstdayontheJob": "12-20-2021",
          "LastdayontheJob": "12-20-2021",
          "EstimatedLastdayontheJob": "12-20-2021",
          "JobAddress1": "205 E Leffel Lane",
          "JobAddress2": "",
          "JobFullAddress": "205 E Leffel Lane",
          "JobCounty": "CLARK",
          "JobState": "Ohio",
          "CustomerCompanyName": "Viper Wall Systems",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,941.86",
          "BranchNumber": 7348,
          "SubmitDate": "12-21-2021",
          "ClientonJob": "Viper Wall Systems"
        },
        {
          "WorkOrder": 4198258,
          "ParentWorkOrder": "",
          "JobName": "Community Blood Center",
          "JobNumber": "7348-132751-7",
          "FirstdayontheJob": "12-20-2021",
          "LastdayontheJob": "12-20-2021",
          "EstimatedLastdayontheJob": "12-20-2021",
          "JobAddress1": "349 S Main St",
          "JobAddress2": "",
          "JobFullAddress": "349 S Main St",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Ray Isaac Plastering LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,570.13",
          "BranchNumber": 7348,
          "SubmitDate": "12-21-2021",
          "ClientonJob": "Ray Isaac Plastering LLC"
        },
        {
          "WorkOrder": 4198257,
          "ParentWorkOrder": "",
          "JobName": "Lincoln Electric",
          "JobNumber": "7400-105222-4",
          "FirstdayontheJob": "12-20-2021",
          "LastdayontheJob": "12-20-2021",
          "EstimatedLastdayontheJob": "12-20-2021",
          "JobAddress1": "22801 ST CLAIR AVE",
          "JobAddress2": "",
          "JobFullAddress": "22801 ST CLAIR AVE",
          "JobCounty": "CUYAHOGA",
          "JobState": "Ohio",
          "CustomerCompanyName": "National Specialty",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,791.40",
          "BranchNumber": 7400,
          "SubmitDate": "12-22-2021",
          "ClientonJob": "National Specialty"
        },
        {
          "WorkOrder": 4198256,
          "ParentWorkOrder": "",
          "JobName": "Cornerstone Apartments",
          "JobNumber": "7348-150148-3",
          "FirstdayontheJob": "12-20-2021",
          "LastdayontheJob": "12-20-2021",
          "EstimatedLastdayontheJob": "12-20-2021",
          "JobAddress1": "4950 Cornerstone Blvd",
          "JobAddress2": "BLDG #5 2ND FLOOR",
          "JobFullAddress": "4950 Cornerstone Blvd BLDG #5 2ND FLOOR",
          "JobCounty": "Greene",
          "JobState": "Ohio",
          "CustomerCompanyName": "National CB LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$77,754.82",
          "BranchNumber": 7348,
          "SubmitDate": "12-21-2021",
          "ClientonJob": "National CB LLC"
        },
        {
          "WorkOrder": 4198255,
          "ParentWorkOrder": "",
          "JobName": "Gabe's/Turkey Hill",
          "JobNumber": "7302-137373-19",
          "FirstdayontheJob": "12-20-2021",
          "LastdayontheJob": "12-20-2021",
          "EstimatedLastdayontheJob": "12-20-2021",
          "JobAddress1": "27322 Carronade Dr",
          "JobAddress2": "",
          "JobFullAddress": "27322 Carronade Dr",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,320.33",
          "BranchNumber": 7302,
          "SubmitDate": "12-21-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4197407,
          "ParentWorkOrder": 4181046,
          "JobName": "GCT Battery Lab #200326",
          "JobNumber": "7313-101349-51",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "6395 William Knudsen Rd",
          "JobAddress2": "",
          "JobFullAddress": "6395 William Knudsen Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Barton Malow Company",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$31,289.66",
          "BranchNumber": 7313,
          "SubmitDate": "12-19-2021",
          "ClientonJob": "Barton Malow Company"
        },
        {
          "WorkOrder": 4197405,
          "ParentWorkOrder": 4181043,
          "JobName": "MMMH Supply & Distribution",
          "JobNumber": "7304-104189-15",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "6800 Eastman Ave",
          "JobAddress2": "Job# P21411",
          "JobFullAddress": "6800 Eastman Ave Job# P21411",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Three Rivers Corporation",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Draft",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,994.48",
          "BranchNumber": 7304,
          "SubmitDate": "",
          "ClientonJob": "Three Rivers Corporation"
        },
        {
          "WorkOrder": 4197406,
          "ParentWorkOrder": 4181043,
          "JobName": "MMMH Supply & Distribution",
          "JobNumber": "7304-104189-15",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "6800 Eastman Ave",
          "JobAddress2": "Job# P21411",
          "JobFullAddress": "6800 Eastman Ave Job# P21411",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Three Rivers Corporation",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,994.48",
          "BranchNumber": 7304,
          "SubmitDate": "12-19-2021",
          "ClientonJob": "Three Rivers Corporation"
        },
        {
          "WorkOrder": 4197404,
          "ParentWorkOrder": 4189239,
          "JobName": "Pearl Vision",
          "JobNumber": "7313-100963-128",
          "FirstdayontheJob": "11-23-2021",
          "LastdayontheJob": "11-23-2021",
          "EstimatedLastdayontheJob": "11-23-2021",
          "JobAddress1": "26140 Ingersol Dr",
          "JobAddress2": "",
          "JobFullAddress": "26140 Ingersol Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,348.73",
          "BranchNumber": 7313,
          "SubmitDate": "12-19-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4197200,
          "ParentWorkOrder": "",
          "JobName": "LINCOLN JOB",
          "JobNumber": "7400-102686-10",
          "FirstdayontheJob": "12-16-2021",
          "LastdayontheJob": "12-16-2021",
          "EstimatedLastdayontheJob": "12-16-2021",
          "JobAddress1": "2410 SCRANTON ROAD",
          "JobAddress2": "",
          "JobFullAddress": "2410 SCRANTON ROAD",
          "JobCounty": "CUYAHOGA",
          "JobState": "Ohio",
          "CustomerCompanyName": "Trinity Drywall & Paintin LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,451.27",
          "BranchNumber": 7400,
          "SubmitDate": "12-17-2021",
          "ClientonJob": "Trinity Drywall & Paintin LLC"
        },
        {
          "WorkOrder": 4197199,
          "ParentWorkOrder": "",
          "JobName": "ODNR Building H 3rd Floor",
          "JobNumber": "7272-132726-5",
          "FirstdayontheJob": "12-16-2021",
          "LastdayontheJob": "12-16-2021",
          "EstimatedLastdayontheJob": "12-16-2021",
          "JobAddress1": "2045 Morse Rd",
          "JobAddress2": "",
          "JobFullAddress": "2045 Morse Rd",
          "JobCounty": "Vinton",
          "JobState": "Ohio",
          "CustomerCompanyName": "Rockwood Builders",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,700.79",
          "BranchNumber": 7272,
          "SubmitDate": "12-17-2021",
          "ClientonJob": "Rockwood Builders"
        },
        {
          "WorkOrder": 4197198,
          "ParentWorkOrder": "",
          "JobName": "Montgomery Quarter 1FH",
          "JobNumber": "7347-133958-8",
          "FirstdayontheJob": "12-16-2021",
          "LastdayontheJob": "12-16-2021",
          "EstimatedLastdayontheJob": "12-16-2021",
          "JobAddress1": "9280 Montgomery Rd",
          "JobAddress2": "",
          "JobFullAddress": "9280 Montgomery Rd",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "Northside Supply",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$36,246.27",
          "BranchNumber": 7347,
          "SubmitDate": "12-17-2021",
          "ClientonJob": "Northside Supply"
        },
        {
          "WorkOrder": 4197197,
          "ParentWorkOrder": "",
          "JobName": "Lathrop/st Vincent Hosp",
          "JobNumber": "7302-104922-7",
          "FirstdayontheJob": "12-16-2021",
          "LastdayontheJob": "12-16-2021",
          "EstimatedLastdayontheJob": "12-16-2021",
          "JobAddress1": "2213 Cherry St",
          "JobAddress2": "",
          "JobFullAddress": "2213 Cherry St",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lathrop Company",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,521.64",
          "BranchNumber": 7302,
          "SubmitDate": "12-17-2021",
          "ClientonJob": "Lathrop Company"
        },
        {
          "WorkOrder": 4197195,
          "ParentWorkOrder": "",
          "JobName": "Toledo Legal",
          "JobNumber": "7302-101113-31",
          "FirstdayontheJob": "12-16-2021",
          "LastdayontheJob": "12-16-2021",
          "EstimatedLastdayontheJob": "12-16-2021",
          "JobAddress1": "416 N Erie St",
          "JobAddress2": "",
          "JobFullAddress": "416 N Erie St",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,357.42",
          "BranchNumber": 7302,
          "SubmitDate": "12-17-2021",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4197194,
          "ParentWorkOrder": "",
          "JobName": "HARBOR FREIGHT",
          "JobNumber": "7400-134575-8",
          "FirstdayontheJob": "12-16-2021",
          "LastdayontheJob": "12-16-2021",
          "EstimatedLastdayontheJob": "12-16-2021",
          "JobAddress1": "317 BOARDMAN POLAND RD",
          "JobAddress2": "",
          "JobFullAddress": "317 BOARDMAN POLAND RD",
          "JobCounty": "MAHONING",
          "JobState": "Ohio",
          "CustomerCompanyName": "Construction Concepts of Indy LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$35,656.97",
          "BranchNumber": 7400,
          "SubmitDate": "12-20-2021",
          "ClientonJob": "Construction Concepts of Indy LLC"
        },
        {
          "WorkOrder": 4196749,
          "ParentWorkOrder": "",
          "JobName": "Warrior Sports",
          "JobNumber": "7313-101113-27",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "6931 23 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "6931 23 Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$180,168.95",
          "BranchNumber": 7313,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4196748,
          "ParentWorkOrder": "",
          "JobName": "Turner Brooks",
          "JobNumber": "7310-101038-50",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "20800 Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "20800 Haggerty Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$19,611.19",
          "BranchNumber": 7310,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4196747,
          "ParentWorkOrder": "",
          "JobName": "Kroger D-784",
          "JobNumber": "7313-101358-19",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "16919 Kercheval Ave",
          "JobAddress2": "",
          "JobFullAddress": "16919 Kercheval Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC Construction Co Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,665.19",
          "BranchNumber": 7313,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "AC Construction Co Inc"
        },
        {
          "WorkOrder": 4196746,
          "ParentWorkOrder": "",
          "JobName": "Rochefort Hangar",
          "JobNumber": "7304-104063-19",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "1170 N Van Dyke rd",
          "JobAddress2": "",
          "JobFullAddress": "1170 N Van Dyke rd",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Booms Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,958.70",
          "BranchNumber": 7304,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Booms Construction Inc"
        },
        {
          "WorkOrder": 4196745,
          "ParentWorkOrder": "",
          "JobName": "Fit Body",
          "JobNumber": "7308-104198-43",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "1971 E Beltline Ave NE # 110",
          "JobAddress2": "",
          "JobFullAddress": "1971 E Beltline Ave NE # 110",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,448.85",
          "BranchNumber": 7308,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4196744,
          "ParentWorkOrder": "",
          "JobName": "Cookie Crumble Royal Oak",
          "JobNumber": "7313-100963-131",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "30955 Woodward Ave",
          "JobAddress2": "Suite F715",
          "JobFullAddress": "30955 Woodward Ave Suite F715",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,257.26",
          "BranchNumber": 7313,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4196741,
          "ParentWorkOrder": "",
          "JobName": "Muskegon Hotel",
          "JobNumber": "7308-104848-16",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "1989 E Sternberg Rd",
          "JobAddress2": "",
          "JobFullAddress": "1989 E Sternberg Rd",
          "JobCounty": "MUSKEGON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Rudys Plastering And Drywall Finish",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,151.26",
          "BranchNumber": 7308,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Rudys Plastering And Drywall Finish"
        },
        {
          "WorkOrder": 4196739,
          "ParentWorkOrder": "",
          "JobName": "University of Detroit",
          "JobNumber": "7310-104410-7",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "4001 W McNichols Rd",
          "JobAddress2": "",
          "JobFullAddress": "4001 W McNichols Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Shaffer Incorporated",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,516.24",
          "BranchNumber": 7310,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Shaffer Incorporated"
        },
        {
          "WorkOrder": 4196736,
          "ParentWorkOrder": "",
          "JobName": "McDonald's Westland Warren Rd.",
          "JobNumber": "7313-100963-125",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "34900 Warren Rd",
          "JobAddress2": "",
          "JobFullAddress": "34900 Warren Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,758.48",
          "BranchNumber": 7313,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4196735,
          "ParentWorkOrder": "",
          "JobName": "Dte Western Wayne Service Cent",
          "JobNumber": "7310-104648-16",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "8001 Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "8001 Haggerty Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Brinker Team Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,165.01",
          "BranchNumber": 7310,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Brinker Team Construction"
        },
        {
          "WorkOrder": 4196734,
          "ParentWorkOrder": "",
          "JobName": "Eddy Stone",
          "JobNumber": "7313-102953-41",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "110 Sproat St",
          "JobAddress2": "",
          "JobFullAddress": "110 Sproat St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,103.23",
          "BranchNumber": 7313,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Commercial Contracting"
        },
        {
          "WorkOrder": 4196720,
          "ParentWorkOrder": "",
          "JobName": "Wings ETC",
          "JobNumber": "7272-132739-20",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "7200 Hazelton - Etna Rd",
          "JobAddress2": "",
          "JobFullAddress": "7200 Hazelton - Etna Rd",
          "JobCounty": "LICKING",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,699.52",
          "BranchNumber": 7272,
          "SubmitDate": "12-21-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4196718,
          "ParentWorkOrder": "",
          "JobName": "Viper DT Harrison",
          "JobNumber": "7348-106905-18",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "10501 New Haven Rd",
          "JobAddress2": "",
          "JobFullAddress": "10501 New Haven Rd",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "Viper Wall Systems",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,373.36",
          "BranchNumber": 7348,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Viper Wall Systems"
        },
        {
          "WorkOrder": 4196717,
          "ParentWorkOrder": "",
          "JobName": "Dollar Tree Wash CH # 9067",
          "JobNumber": "7348-106905-21",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "2019 Columbus Ave",
          "JobAddress2": "",
          "JobFullAddress": "2019 Columbus Ave",
          "JobCounty": "FAYETTE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Viper Wall Systems",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,844.54",
          "BranchNumber": 7348,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Viper Wall Systems"
        },
        {
          "WorkOrder": 4196716,
          "ParentWorkOrder": "",
          "JobName": "Viper GlenCrossing DT",
          "JobNumber": "7348-106905-19",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "5056 Glencrossing Way",
          "JobAddress2": "",
          "JobFullAddress": "5056 Glencrossing Way",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Viper Wall Systems",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,830.45",
          "BranchNumber": 7348,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Viper Wall Systems"
        },
        {
          "WorkOrder": 4196715,
          "ParentWorkOrder": "",
          "JobName": "41 West",
          "JobNumber": "7400-102686-4",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "4010 LORAIN AVE",
          "JobAddress2": "3RD/4TH FLOOR",
          "JobFullAddress": "4010 LORAIN AVE 3RD/4TH FLOOR",
          "JobCounty": "CUYAHOGA",
          "JobState": "Ohio",
          "CustomerCompanyName": "Trinity Drywall & Painting LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$70,909.67",
          "BranchNumber": 7400,
          "SubmitDate": "12-21-2021",
          "ClientonJob": "Trinity Drywall & Painting LLC"
        },
        {
          "WorkOrder": 4196714,
          "ParentWorkOrder": "",
          "JobName": "Mu Dodds Hall",
          "JobNumber": "7348-151429-2",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "600 S Maple Ave",
          "JobAddress2": "",
          "JobFullAddress": "600 S Maple Ave",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "Summers Consulting Services LL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,889.82",
          "BranchNumber": 7348,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Summers Consulting Services LL"
        },
        {
          "WorkOrder": 4196713,
          "ParentWorkOrder": "",
          "JobName": "Fairfield Inn",
          "JobNumber": "7302-150679-3",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "2000 Tiffin Ave",
          "JobAddress2": "",
          "JobFullAddress": "2000 Tiffin Ave",
          "JobCounty": "Hancock",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lippe Const Services Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,841.55",
          "BranchNumber": 7302,
          "SubmitDate": "12-21-2021",
          "ClientonJob": "Lippe Const Services Inc"
        },
        {
          "WorkOrder": 4196712,
          "ParentWorkOrder": "",
          "JobName": "Nof Toledo Hospital",
          "JobNumber": "7302-104922-2",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "2142 N Cove Blvd",
          "JobAddress2": "Legacy Level 5",
          "JobFullAddress": "2142 N Cove Blvd Legacy Level 5",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lathrop Company",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,832.54",
          "BranchNumber": 7302,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Lathrop Company"
        },
        {
          "WorkOrder": 4196636,
          "ParentWorkOrder": "",
          "JobName": "Baker College",
          "JobNumber": "7310-101038-47",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "420 S LAFAYETTE AVE",
          "JobAddress2": "",
          "JobFullAddress": "420 S LAFAYETTE AVE",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$15,767.33",
          "BranchNumber": 7310,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4196635,
          "ParentWorkOrder": "",
          "JobName": "Brinker Team Construction",
          "JobNumber": "7310-104648-14",
          "FirstdayontheJob": "12-15-2021",
          "LastdayontheJob": "12-15-2021",
          "EstimatedLastdayontheJob": "12-15-2021",
          "JobAddress1": "2047 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "2047 Woodward Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Brinker Team Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,824.62",
          "BranchNumber": 7310,
          "SubmitDate": "12-16-2021",
          "ClientonJob": "Brinker Team Construction"
        },
        {
          "WorkOrder": 4194905,
          "ParentWorkOrder": "",
          "JobName": "First National",
          "JobNumber": "7308-102688-19",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "1354 Baldwin",
          "JobAddress2": "",
          "JobFullAddress": "1354 Baldwin",
          "JobCounty": "Ottawa",
          "JobState": "Michigan",
          "CustomerCompanyName": "Keystone Const & Consult LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$34,952.52",
          "BranchNumber": 7308,
          "SubmitDate": "",
          "ClientonJob": "Keystone Const & Consult LLC"
        },
        {
          "WorkOrder": 4194904,
          "ParentWorkOrder": "",
          "JobName": "Njp Hmor 1900 S Channel",
          "JobNumber": "7422-104499-9",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "1900 S Channel Dr",
          "JobAddress2": "",
          "JobFullAddress": "1900 S Channel Dr",
          "JobCounty": "Saint Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "Picklehaupt Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$30,365.27",
          "BranchNumber": 7422,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "Picklehaupt Drywall"
        },
        {
          "WorkOrder": 4194903,
          "ParentWorkOrder": "",
          "JobName": "Woodland Center",
          "JobNumber": "7313-104549-15",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "66780 VAN DYKE RD # 6688",
          "JobAddress2": "",
          "JobFullAddress": "66780 VAN DYKE RD # 6688",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Superior Designs Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$23,973.86",
          "BranchNumber": 7313,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "Superior Designs Inc"
        },
        {
          "WorkOrder": 4194901,
          "ParentWorkOrder": "",
          "JobName": "First English Lutheran Church",
          "JobNumber": "7422-105170-21",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "800 Vernier Rd",
          "JobAddress2": "",
          "JobFullAddress": "800 Vernier Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Industrial Tech Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$17,774.38",
          "BranchNumber": 7422,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "Industrial Tech Services"
        },
        {
          "WorkOrder": 4194900,
          "ParentWorkOrder": "",
          "JobName": "Culver's Dewitt",
          "JobNumber": "7313-100963-108",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "1030 W Herbison Rd",
          "JobAddress2": "",
          "JobFullAddress": "1030 W Herbison Rd",
          "JobCounty": "Clinton",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$17,102.33",
          "BranchNumber": 7313,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4194899,
          "ParentWorkOrder": "",
          "JobName": "Rochefort Hangar-deliver 2shop",
          "JobNumber": "7304-104063-19",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "1170 N Van Dyke rd",
          "JobAddress2": "",
          "JobFullAddress": "1170 N Van Dyke rd",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Booms Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,566.92",
          "BranchNumber": 7304,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "Booms Construction Inc"
        },
        {
          "WorkOrder": 4194898,
          "ParentWorkOrder": "",
          "JobName": "Njp Chrysler Tech Cntr",
          "JobNumber": "7313-101349-38",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "1000 Chrysler Dr",
          "JobAddress2": "",
          "JobFullAddress": "1000 Chrysler Dr",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Barton Malow Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,369.29",
          "BranchNumber": 7313,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "Barton Malow Company"
        },
        {
          "WorkOrder": 4194896,
          "ParentWorkOrder": "",
          "JobName": "Newago Retail-Biggby Tenant",
          "JobNumber": "7308-104198-45",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "176 West River Valley Dr",
          "JobAddress2": "",
          "JobFullAddress": "176 West River Valley Dr",
          "JobCounty": "Newaygo",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$15,337.22",
          "BranchNumber": 7308,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4194895,
          "ParentWorkOrder": "",
          "JobName": "FERRIS COFFE & NUT",
          "JobNumber": "7308-102688-18",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "839 SEWARD AVE NW",
          "JobAddress2": "",
          "JobFullAddress": "839 SEWARD AVE NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Keystone Const & Consult LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,491.09",
          "BranchNumber": 7308,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "Keystone Const & Consult LLC"
        },
        {
          "WorkOrder": 4194894,
          "ParentWorkOrder": "",
          "JobName": "Mercury Point Commons",
          "JobNumber": "7313-104677-15",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "16844 Schaefer Hwy",
          "JobAddress2": "",
          "JobFullAddress": "16844 Schaefer Hwy",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TLA Interiors Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,222.03",
          "BranchNumber": 7313,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "TLA Interiors Inc"
        },
        {
          "WorkOrder": 4194893,
          "ParentWorkOrder": "",
          "JobName": "Detroit Zoo Membership Trailer",
          "JobNumber": "7422-150680-3",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "8450 W 10 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "8450 W 10 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Midwest Comm Const",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,217.17",
          "BranchNumber": 7422,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "Midwest Comm Const"
        },
        {
          "WorkOrder": 4194892,
          "ParentWorkOrder": "",
          "JobName": "Kroger D-724",
          "JobNumber": "7313-101358-18",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "35000 23 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "35000 23 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC Construction Co Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,127.46",
          "BranchNumber": 7313,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "AC Construction Co Inc"
        },
        {
          "WorkOrder": 4194891,
          "ParentWorkOrder": "",
          "JobName": "Nof 12 Mile Gallery",
          "JobNumber": "7313-104553-8",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "3824 12 Mile Rd",
          "JobAddress2": "# misc",
          "JobFullAddress": "3824 12 Mile Rd # misc",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Motor City Constr & Redmo",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,871.69",
          "BranchNumber": 7313,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "Motor City Constr & Redmo"
        },
        {
          "WorkOrder": 4194890,
          "ParentWorkOrder": "",
          "JobName": "Nof Biggby Coffee",
          "JobNumber": "7304-102934-19",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "6615 Eastman Ave",
          "JobAddress2": "",
          "JobFullAddress": "6615 Eastman Ave",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,223.53",
          "BranchNumber": 7304,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "Great Lakes Bay Construction"
        },
        {
          "WorkOrder": 4194889,
          "ParentWorkOrder": "",
          "JobName": "Njp Williams Inter",
          "JobNumber": "7313-104263-3",
          "FirstdayontheJob": "12-09-2021",
          "LastdayontheJob": "12-09-2021",
          "EstimatedLastdayontheJob": "12-09-2021",
          "JobAddress1": "2020 Centerpoint Pkwy",
          "JobAddress2": "",
          "JobFullAddress": "2020 Centerpoint Pkwy",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "EJ Kaneris Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,037.53",
          "BranchNumber": 7313,
          "SubmitDate": "12-10-2021",
          "ClientonJob": "EJ Kaneris Inc"
        },
        {
          "WorkOrder": 4191167,
          "ParentWorkOrder": "",
          "JobName": "UC West Chester family prat.",
          "JobNumber": "7270-133987-27",
          "FirstdayontheJob": "11-30-2021",
          "LastdayontheJob": "11-30-2021",
          "EstimatedLastdayontheJob": "11-30-2021",
          "JobAddress1": "7798 Discovery Drive",
          "JobAddress2": "Suite A",
          "JobFullAddress": "7798 Discovery Drive Suite A",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "Triversity Construction Group / Triversity Craft Force LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$25,889.27",
          "BranchNumber": 7270,
          "SubmitDate": "12-01-2021",
          "ClientonJob": "Triversity Construction Group / Triversity Craft Force LLC"
        },
        {
          "WorkOrder": 4191166,
          "ParentWorkOrder": "",
          "JobName": "PROJECT BOLT / AMAZON",
          "JobNumber": "7348-100175-477",
          "FirstdayontheJob": "11-30-2021",
          "LastdayontheJob": "11-30-2021",
          "EstimatedLastdayontheJob": "11-30-2021",
          "JobAddress1": "1835 Union Airpark Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1835 Union Airpark Blvd",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "Performance Contracting Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,434.55",
          "BranchNumber": 7348,
          "SubmitDate": "12-01-2021",
          "ClientonJob": "Performance Contracting Inc"
        },
        {
          "WorkOrder": 4191165,
          "ParentWorkOrder": "",
          "JobName": "McDonalds Franklin 28601",
          "JobNumber": "7348-131575-15",
          "FirstdayontheJob": "11-30-2021",
          "LastdayontheJob": "11-30-2021",
          "EstimatedLastdayontheJob": "11-30-2021",
          "JobAddress1": "1425 E 2nd St",
          "JobAddress2": "",
          "JobFullAddress": "1425 E 2nd St",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "LIS Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,529.08",
          "BranchNumber": 7348,
          "SubmitDate": "12-01-2021",
          "ClientonJob": "LIS Construction"
        },
        {
          "WorkOrder": 4191164,
          "ParentWorkOrder": "",
          "JobName": "Firehouse Storage Building 11",
          "JobNumber": "7270-106739-4",
          "FirstdayontheJob": "11-30-2021",
          "LastdayontheJob": "11-30-2021",
          "EstimatedLastdayontheJob": "11-30-2021",
          "JobAddress1": "5763 Princeton Glendale Rd",
          "JobAddress2": "",
          "JobFullAddress": "5763 Princeton Glendale Rd",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "Hutsenpiller Contractors",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$38,454.05",
          "BranchNumber": 7270,
          "SubmitDate": "12-01-2021",
          "ClientonJob": "Hutsenpiller Contractors"
        },
        {
          "WorkOrder": 4190966,
          "ParentWorkOrder": "",
          "JobName": "Aerial Assets",
          "JobNumber": "7313-100963-127",
          "FirstdayontheJob": "11-30-2021",
          "LastdayontheJob": "11-30-2021",
          "EstimatedLastdayontheJob": "11-30-2021",
          "JobAddress1": "8170 N Lilley Rd",
          "JobAddress2": "",
          "JobFullAddress": "8170 N Lilley Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$37,527.02",
          "BranchNumber": 7313,
          "SubmitDate": "12-01-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4190965,
          "ParentWorkOrder": "",
          "JobName": "MSU FRIB",
          "JobNumber": "7310-101038-48",
          "FirstdayontheJob": "11-30-2021",
          "LastdayontheJob": "11-30-2021",
          "EstimatedLastdayontheJob": "11-30-2021",
          "JobAddress1": "640 S Shaw Ln",
          "JobAddress2": "",
          "JobFullAddress": "640 S Shaw Ln",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,151.99",
          "BranchNumber": 7310,
          "SubmitDate": "12-01-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4190964,
          "ParentWorkOrder": "",
          "JobName": "E5 PEOPLE DRIVEN",
          "JobNumber": "7308-137191-20",
          "FirstdayontheJob": "11-30-2021",
          "LastdayontheJob": "11-30-2021",
          "EstimatedLastdayontheJob": "11-30-2021",
          "JobAddress1": "6300 Venture Hills Blvd SW",
          "JobAddress2": "",
          "JobFullAddress": "6300 Venture Hills Blvd SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Triad Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,072.36",
          "BranchNumber": 7308,
          "SubmitDate": "12-01-2021",
          "ClientonJob": "Triad Interiors LLC"
        },
        {
          "WorkOrder": 4190963,
          "ParentWorkOrder": "",
          "JobName": "Taco Bell- Vassar",
          "JobNumber": "7304-104112-5",
          "FirstdayontheJob": "11-30-2021",
          "LastdayontheJob": "11-30-2021",
          "EstimatedLastdayontheJob": "11-30-2021",
          "JobAddress1": "960 W Huron Ave",
          "JobAddress2": "",
          "JobFullAddress": "960 W Huron Ave",
          "JobCounty": "TUSCOLA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Raymond Renovations",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,142.33",
          "BranchNumber": 7304,
          "SubmitDate": "12-01-2021",
          "ClientonJob": "Raymond Renovations"
        },
        {
          "WorkOrder": 4190962,
          "ParentWorkOrder": "",
          "JobName": "Wendy's Lenex",
          "JobNumber": "7310-102087-35",
          "FirstdayontheJob": "11-30-2021",
          "LastdayontheJob": "11-30-2021",
          "EstimatedLastdayontheJob": "11-30-2021",
          "JobAddress1": "36255 26 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "36255 26 Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H Pastor & Sons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,811.05",
          "BranchNumber": 7310,
          "SubmitDate": "12-01-2021",
          "ClientonJob": "George H Pastor & Sons"
        },
        {
          "WorkOrder": 4190212,
          "ParentWorkOrder": "",
          "JobName": "LOWER PRICE HILLS",
          "JobNumber": "7348-132715-15",
          "FirstdayontheJob": "11-28-2021",
          "LastdayontheJob": "11-28-2021",
          "EstimatedLastdayontheJob": "11-28-2021",
          "JobAddress1": "2113 W 8TH STREET",
          "JobAddress2": "",
          "JobFullAddress": "2113 W 8TH STREET",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lemcer Construction LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,816.06",
          "BranchNumber": 7348,
          "SubmitDate": "11-29-2021",
          "ClientonJob": "Lemcer Construction LLC"
        },
        {
          "WorkOrder": 4190211,
          "ParentWorkOrder": "",
          "JobName": "Hoge Steet Apartments",
          "JobNumber": "7347-101849-85",
          "FirstdayontheJob": "11-28-2021",
          "LastdayontheJob": "11-28-2021",
          "EstimatedLastdayontheJob": "11-28-2021",
          "JobAddress1": "426 Hoge St",
          "JobAddress2": "",
          "JobFullAddress": "426 Hoge St",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "Vision Contractors Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,533.66",
          "BranchNumber": 7347,
          "SubmitDate": "11-29-2021",
          "ClientonJob": "Vision Contractors Inc"
        },
        {
          "WorkOrder": 4190210,
          "ParentWorkOrder": "",
          "JobName": "Clifton Place Apartments",
          "JobNumber": "7347-132880-13",
          "FirstdayontheJob": "11-28-2021",
          "LastdayontheJob": "11-28-2021",
          "EstimatedLastdayontheJob": "11-28-2021",
          "JobAddress1": "900 Rue De La Paix",
          "JobAddress2": "",
          "JobFullAddress": "900 Rue De La Paix",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Reyes/tdr Interiors",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$27,630.49",
          "BranchNumber": 7347,
          "SubmitDate": "11-29-2021",
          "ClientonJob": "Reyes/tdr Interiors"
        },
        {
          "WorkOrder": 4190209,
          "ParentWorkOrder": "",
          "JobName": "Dollar Tree",
          "JobNumber": "7302-106905-13",
          "FirstdayontheJob": "11-28-2021",
          "LastdayontheJob": "11-28-2021",
          "EstimatedLastdayontheJob": "11-28-2021",
          "JobAddress1": "22061 OH 51",
          "JobAddress2": "",
          "JobFullAddress": "22061 OH 51",
          "JobCounty": "OTTAWA",
          "JobState": "Ohio",
          "CustomerCompanyName": "Viper Wall Systems",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,591.68",
          "BranchNumber": 7302,
          "SubmitDate": "11-29-2021",
          "ClientonJob": "Viper Wall Systems"
        },
        {
          "WorkOrder": 4190208,
          "ParentWorkOrder": "",
          "JobName": "Nof Brighton Schools",
          "JobNumber": "7302-104942-5",
          "FirstdayontheJob": "11-28-2021",
          "LastdayontheJob": "11-28-2021",
          "EstimatedLastdayontheJob": "11-28-2021",
          "JobAddress1": "7878 Brighton Rd",
          "JobAddress2": "",
          "JobFullAddress": "7878 Brighton Rd",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "The Spieker Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,686.34",
          "BranchNumber": 7302,
          "SubmitDate": "11-29-2021",
          "ClientonJob": "The Spieker Company"
        },
        {
          "WorkOrder": 4190207,
          "ParentWorkOrder": "",
          "JobName": "Sylvania Place",
          "JobNumber": "7302-102638-7",
          "FirstdayontheJob": "11-28-2021",
          "LastdayontheJob": "11-28-2021",
          "EstimatedLastdayontheJob": "11-28-2021",
          "JobAddress1": "7649 Sylvan Towne",
          "JobAddress2": "",
          "JobFullAddress": "7649 Sylvan Towne",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "T&B Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,824.98",
          "BranchNumber": 7302,
          "SubmitDate": "11-29-2021",
          "ClientonJob": "T&B Construction"
        },
        {
          "WorkOrder": 4190206,
          "ParentWorkOrder": "",
          "JobName": "M&M Heating & Cooling",
          "JobNumber": "7302-104937-9",
          "FirstdayontheJob": "11-28-2021",
          "LastdayontheJob": "11-28-2021",
          "EstimatedLastdayontheJob": "11-28-2021",
          "JobAddress1": "1515 Washington St",
          "JobAddress2": "",
          "JobFullAddress": "1515 Washington St",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Rupp Rosebrock Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,989.11",
          "BranchNumber": 7302,
          "SubmitDate": "11-29-2021",
          "ClientonJob": "Rupp Rosebrock Inc"
        },
        {
          "WorkOrder": 4190205,
          "ParentWorkOrder": "",
          "JobName": "Kraft Heinz - Line 5",
          "JobNumber": "7302-104930-2",
          "FirstdayontheJob": "11-28-2021",
          "LastdayontheJob": "11-28-2021",
          "EstimatedLastdayontheJob": "11-28-2021",
          "JobAddress1": "2100 W Birchard Ave",
          "JobAddress2": "",
          "JobFullAddress": "2100 W Birchard Ave",
          "JobCounty": "Sandusky",
          "JobState": "Ohio",
          "CustomerCompanyName": "Mosser Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$14,308.83",
          "BranchNumber": 7302,
          "SubmitDate": "11-29-2021",
          "ClientonJob": "Mosser Construction"
        },
        {
          "WorkOrder": 4190204,
          "ParentWorkOrder": "",
          "JobName": "Gabe's",
          "JobNumber": "7302-137373-19",
          "FirstdayontheJob": "11-28-2021",
          "LastdayontheJob": "11-28-2021",
          "EstimatedLastdayontheJob": "11-28-2021",
          "JobAddress1": "27386 Carronade Dr",
          "JobAddress2": "",
          "JobFullAddress": "27386 Carronade Dr",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,691.32",
          "BranchNumber": 7302,
          "SubmitDate": "11-29-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4190203,
          "ParentWorkOrder": "",
          "JobName": "Pure Romance",
          "JobNumber": "7270-132715-16",
          "FirstdayontheJob": "11-28-2021",
          "LastdayontheJob": "11-28-2021",
          "EstimatedLastdayontheJob": "11-28-2021",
          "JobAddress1": "300 Main St",
          "JobAddress2": "",
          "JobFullAddress": "300 Main St",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lemcer Construction LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$19,263.03",
          "BranchNumber": 7270,
          "SubmitDate": "11-29-2021",
          "ClientonJob": "Lemcer Construction LLC"
        },
        {
          "WorkOrder": 4189372,
          "ParentWorkOrder": 4176297,
          "JobName": "O'Reilly Auto Parts Toledo",
          "JobNumber": "7302-101113-22",
          "FirstdayontheJob": "10-21-2021",
          "LastdayontheJob": "10-21-2021",
          "EstimatedLastdayontheJob": "10-21-2021",
          "JobAddress1": "4627 Suder Ave",
          "JobAddress2": "",
          "JobFullAddress": "4627 Suder Ave",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$60,637.13",
          "BranchNumber": 7302,
          "SubmitDate": "11-24-2021",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4189245,
          "ParentWorkOrder": "",
          "JobName": "Legacy Hill Apartments",
          "JobNumber": "7310-151430-2",
          "FirstdayontheJob": "11-23-2021",
          "LastdayontheJob": "11-23-2021",
          "EstimatedLastdayontheJob": "11-23-2021",
          "JobAddress1": "2940 W Hamlin Rd",
          "JobAddress2": "",
          "JobFullAddress": "2940 W Hamlin Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SNI Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$70,087.73",
          "BranchNumber": 7310,
          "SubmitDate": "11-24-2021",
          "ClientonJob": "SNI Contracting"
        },
        {
          "WorkOrder": 4189244,
          "ParentWorkOrder": "",
          "JobName": "Culver's South Lyon",
          "JobNumber": "7313-100963-110",
          "FirstdayontheJob": "11-23-2021",
          "LastdayontheJob": "11-23-2021",
          "EstimatedLastdayontheJob": "11-23-2021",
          "JobAddress1": "22700 Pontiac Trail",
          "JobAddress2": "",
          "JobFullAddress": "22700 Pontiac Trail",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$32,539.79",
          "BranchNumber": 7313,
          "SubmitDate": "11-24-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4189243,
          "ParentWorkOrder": "",
          "JobName": "MORR Commercial Bldg",
          "JobNumber": "7313-137344-4",
          "FirstdayontheJob": "11-23-2021",
          "LastdayontheJob": "11-23-2021",
          "EstimatedLastdayontheJob": "11-23-2021",
          "JobAddress1": "5445 South Pennsylvania Avenue",
          "JobAddress2": "",
          "JobFullAddress": "5445 South Pennsylvania Avenue",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "M O R R Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,407.07",
          "BranchNumber": 7313,
          "SubmitDate": "11-24-2021",
          "ClientonJob": "M O R R Construction LLC"
        },
        {
          "WorkOrder": 4189242,
          "ParentWorkOrder": "",
          "JobName": "Orange Theory",
          "JobNumber": "7422-150826-2",
          "FirstdayontheJob": "11-23-2021",
          "LastdayontheJob": "11-23-2021",
          "EstimatedLastdayontheJob": "11-23-2021",
          "JobAddress1": "30283 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "30283 Woodward Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Alpha Commercial Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,338.99",
          "BranchNumber": 7422,
          "SubmitDate": "11-24-2021",
          "ClientonJob": "Alpha Commercial Construction"
        },
        {
          "WorkOrder": 4189241,
          "ParentWorkOrder": "",
          "JobName": "Borg Warner",
          "JobNumber": "7313-104393-6",
          "FirstdayontheJob": "11-23-2021",
          "LastdayontheJob": "11-23-2021",
          "EstimatedLastdayontheJob": "11-23-2021",
          "JobAddress1": "1624 Meijer Dr",
          "JobAddress2": "",
          "JobFullAddress": "1624 Meijer Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "National Business Supply",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,355.27",
          "BranchNumber": 7313,
          "SubmitDate": "11-24-2021",
          "ClientonJob": "National Business Supply"
        },
        {
          "WorkOrder": 4189240,
          "ParentWorkOrder": "",
          "JobName": "McDonald's Westland Warren Rd.",
          "JobNumber": "7313-100963-125",
          "FirstdayontheJob": "11-23-2021",
          "LastdayontheJob": "11-23-2021",
          "EstimatedLastdayontheJob": "11-23-2021",
          "JobAddress1": "34900 Warren RD",
          "JobAddress2": "",
          "JobFullAddress": "34900 Warren RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,590.07",
          "BranchNumber": 7313,
          "SubmitDate": "11-24-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4189239,
          "ParentWorkOrder": "",
          "JobName": "Pearl Vision",
          "JobNumber": "7313-100963-128",
          "FirstdayontheJob": "11-23-2021",
          "LastdayontheJob": "11-23-2021",
          "EstimatedLastdayontheJob": "11-23-2021",
          "JobAddress1": "26140 Ingersol Dr",
          "JobAddress2": "",
          "JobFullAddress": "26140 Ingersol Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,348.73",
          "BranchNumber": 7313,
          "SubmitDate": "11-24-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4187510,
          "ParentWorkOrder": "",
          "JobName": "Hoge Street Apartments",
          "JobNumber": "101849-0001",
          "FirstdayontheJob": "11-04-2021",
          "LastdayontheJob": "11-04-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "423 Hoge St",
          "JobAddress2": "",
          "JobFullAddress": "423 Hoge St",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "Vision Contractors Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "N/A",
          "BranchNumber": 7347,
          "SubmitDate": "11-19-2021",
          "ClientonJob": "Vision Contractors Inc"
        },
        {
          "WorkOrder": 4187366,
          "ParentWorkOrder": "",
          "JobName": "Dollar Tree",
          "JobNumber": "7348-106905-14",
          "FirstdayontheJob": "11-18-2021",
          "LastdayontheJob": "11-18-2021",
          "EstimatedLastdayontheJob": "11-18-2021",
          "JobAddress1": "3116 Montgomery Rd",
          "JobAddress2": "",
          "JobFullAddress": "3116 Montgomery Rd",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Viper Wall Systems",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,806.39",
          "BranchNumber": 7348,
          "SubmitDate": "11-19-2021",
          "ClientonJob": "Viper Wall Systems"
        },
        {
          "WorkOrder": 4187365,
          "ParentWorkOrder": "",
          "JobName": "Antwerp Innovation Center",
          "JobNumber": "7302-104926-4",
          "FirstdayontheJob": "11-18-2021",
          "LastdayontheJob": "11-18-2021",
          "EstimatedLastdayontheJob": "11-18-2021",
          "JobAddress1": "303 S HARRMANN RD",
          "JobAddress2": "",
          "JobFullAddress": "303 S HARRMANN RD",
          "JobCounty": "PAULDING",
          "JobState": "Ohio",
          "CustomerCompanyName": "Mel Lanzer Company",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,147.65",
          "BranchNumber": 7302,
          "SubmitDate": "11-19-2021",
          "ClientonJob": "Mel Lanzer Company"
        },
        {
          "WorkOrder": 4187364,
          "ParentWorkOrder": "",
          "JobName": "Senior Living Apartments",
          "JobNumber": "7272-151425-2",
          "FirstdayontheJob": "11-18-2021",
          "LastdayontheJob": "11-18-2021",
          "EstimatedLastdayontheJob": "11-18-2021",
          "JobAddress1": "7250 E Broad St",
          "JobAddress2": "",
          "JobFullAddress": "7250 E Broad St",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Farmer Contracting",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,362.79",
          "BranchNumber": 7272,
          "SubmitDate": "11-19-2021",
          "ClientonJob": "Farmer Contracting"
        },
        {
          "WorkOrder": 4186685,
          "ParentWorkOrder": "",
          "JobName": "MSI Express",
          "JobNumber": "133871-0001",
          "FirstdayontheJob": "11-22-2021",
          "LastdayontheJob": "11-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5730 Saltzgaber",
          "JobAddress2": "",
          "JobFullAddress": "5730 Saltzgaber",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "Columbus Drywall, Inc.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "N/A",
          "BranchNumber": 7272,
          "SubmitDate": "11-17-2021",
          "ClientonJob": "Columbus Drywall, Inc."
        },
        {
          "WorkOrder": 4186633,
          "ParentWorkOrder": "",
          "JobName": "Nof Ypsilanti Library",
          "JobNumber": "7313-104657-43",
          "FirstdayontheJob": "11-16-2021",
          "LastdayontheJob": "11-16-2021",
          "EstimatedLastdayontheJob": "11-16-2021",
          "JobAddress1": "1900 N Harris Rd",
          "JobAddress2": "",
          "JobFullAddress": "1900 N Harris Rd",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$38,433.95",
          "BranchNumber": 7313,
          "SubmitDate": "11-17-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4186632,
          "ParentWorkOrder": "",
          "JobName": "Rapid Detox",
          "JobNumber": "7310-104562-11",
          "FirstdayontheJob": "11-16-2021",
          "LastdayontheJob": "11-16-2021",
          "EstimatedLastdayontheJob": "11-16-2021",
          "JobAddress1": "23800 Orchard Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "23800 Orchard Lake Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,982.03",
          "BranchNumber": 7310,
          "SubmitDate": "11-17-2021",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 4186631,
          "ParentWorkOrder": "",
          "JobName": "JSDS Conservation",
          "JobNumber": "7310-105077-6",
          "FirstdayontheJob": "11-16-2021",
          "LastdayontheJob": "11-16-2021",
          "EstimatedLastdayontheJob": "11-16-2021",
          "JobAddress1": "331 Montcalm St",
          "JobAddress2": "",
          "JobFullAddress": "331 Montcalm St",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "DFB Building Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,346.83",
          "BranchNumber": 7310,
          "SubmitDate": "",
          "ClientonJob": "DFB Building Inc"
        },
        {
          "WorkOrder": 4186630,
          "ParentWorkOrder": "",
          "JobName": "Njp Old Almont School",
          "JobNumber": "7422-104548-14",
          "FirstdayontheJob": "11-16-2021",
          "LastdayontheJob": "11-16-2021",
          "EstimatedLastdayontheJob": "11-16-2021",
          "JobAddress1": "401 Church St",
          "JobAddress2": "",
          "JobFullAddress": "401 Church St",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Cons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,529.32",
          "BranchNumber": 7422,
          "SubmitDate": "11-17-2021",
          "ClientonJob": "Blue Water Drywall & Cons"
        },
        {
          "WorkOrder": 4186629,
          "ParentWorkOrder": "",
          "JobName": "Tractor Supply Co.",
          "JobNumber": "7308-102942-15",
          "FirstdayontheJob": "11-16-2021",
          "LastdayontheJob": "11-16-2021",
          "EstimatedLastdayontheJob": "11-16-2021",
          "JobAddress1": "3303 Alpine Ave NW",
          "JobAddress2": "",
          "JobFullAddress": "3303 Alpine Ave NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Pure Renovation Concepts Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,878.91",
          "BranchNumber": 7308,
          "SubmitDate": "11-17-2021",
          "ClientonJob": "Pure Renovation Concepts Inc"
        },
        {
          "WorkOrder": 4186628,
          "ParentWorkOrder": "",
          "JobName": "Taco Bell Tawas",
          "JobNumber": "7304-100963-126",
          "FirstdayontheJob": "11-16-2021",
          "LastdayontheJob": "11-16-2021",
          "EstimatedLastdayontheJob": "11-16-2021",
          "JobAddress1": "709 E Lake Street",
          "JobAddress2": "",
          "JobFullAddress": "709 E Lake Street",
          "JobCounty": "IOSCO",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,705.26",
          "BranchNumber": 7304,
          "SubmitDate": "11-17-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4186627,
          "ParentWorkOrder": "",
          "JobName": "McDonald's Westland Warren Rd.",
          "JobNumber": "7310-100963-125",
          "FirstdayontheJob": "11-16-2021",
          "LastdayontheJob": "11-16-2021",
          "EstimatedLastdayontheJob": "11-16-2021",
          "JobAddress1": "34900 Warren Rd",
          "JobAddress2": "",
          "JobFullAddress": "34900 Warren Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,371.14",
          "BranchNumber": 7310,
          "SubmitDate": "11-17-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4186626,
          "ParentWorkOrder": "",
          "JobName": "Kroger Holt",
          "JobNumber": "7313-101358-17",
          "FirstdayontheJob": "11-16-2021",
          "LastdayontheJob": "11-16-2021",
          "EstimatedLastdayontheJob": "11-16-2021",
          "JobAddress1": "2495 Cedar St",
          "JobAddress2": "",
          "JobFullAddress": "2495 Cedar St",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC Construction Co Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,246.89",
          "BranchNumber": 7313,
          "SubmitDate": "11-17-2021",
          "ClientonJob": "AC Construction Co Inc"
        },
        {
          "WorkOrder": 4186625,
          "ParentWorkOrder": "",
          "JobName": "Detroit Employment Solutions",
          "JobNumber": "7313-104393-5",
          "FirstdayontheJob": "11-16-2021",
          "LastdayontheJob": "11-16-2021",
          "EstimatedLastdayontheJob": "11-16-2021",
          "JobAddress1": "18017 E Warren Ave",
          "JobAddress2": "",
          "JobFullAddress": "18017 E Warren Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "National Business Supply",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,002.97",
          "BranchNumber": 7313,
          "SubmitDate": "11-17-2021",
          "ClientonJob": "National Business Supply"
        },
        {
          "WorkOrder": 4186621,
          "ParentWorkOrder": 4175995,
          "JobName": "McDonald's #15401",
          "JobNumber": "7313-100963-111",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "15401 E 12 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "15401 E 12 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,275.72",
          "BranchNumber": 7313,
          "SubmitDate": "11-17-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4186134,
          "ParentWorkOrder": 4172790,
          "JobName": "SHEETZ",
          "JobNumber": "7272-132739-16",
          "FirstdayontheJob": "10-10-2021",
          "LastdayontheJob": "10-10-2021",
          "EstimatedLastdayontheJob": "10-10-2021",
          "JobAddress1": "2840 Johnstown Road",
          "JobAddress2": "",
          "JobFullAddress": "2840 Johnstown Road",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,244.83",
          "BranchNumber": 7272,
          "SubmitDate": "11-16-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4186069,
          "ParentWorkOrder": 4174382,
          "JobName": "Cognex",
          "JobNumber": "7313-100963-114",
          "FirstdayontheJob": "10-14-2021",
          "LastdayontheJob": "10-14-2021",
          "EstimatedLastdayontheJob": "10-14-2021",
          "JobAddress1": "660 E 10 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "660 E 10 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$15,082.72",
          "BranchNumber": 7313,
          "SubmitDate": "11-16-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4186061,
          "ParentWorkOrder": "",
          "JobName": "Turkey Hill",
          "JobNumber": "7272-132739-1",
          "FirstdayontheJob": "11-15-2021",
          "LastdayontheJob": "11-15-2021",
          "EstimatedLastdayontheJob": "11-15-2021",
          "JobAddress1": "6233 Avery Rd",
          "JobAddress2": "",
          "JobFullAddress": "6233 Avery Rd",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "N/A",
          "BranchNumber": 7272,
          "SubmitDate": "11-16-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4186060,
          "ParentWorkOrder": "",
          "JobName": "SURGE CENTER",
          "JobNumber": "7272-132739-21",
          "FirstdayontheJob": "11-15-2021",
          "LastdayontheJob": "11-15-2021",
          "EstimatedLastdayontheJob": "11-15-2021",
          "JobAddress1": "705 BUCKLES CT NORTH",
          "JobAddress2": "",
          "JobFullAddress": "705 BUCKLES CT NORTH",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$18,901.60",
          "BranchNumber": 7272,
          "SubmitDate": "11-16-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4186059,
          "ParentWorkOrder": "",
          "JobName": "UC West Chester",
          "JobNumber": "7270-133987-27",
          "FirstdayontheJob": "11-15-2021",
          "LastdayontheJob": "11-15-2021",
          "EstimatedLastdayontheJob": "11-15-2021",
          "JobAddress1": "7798 Diversity Drive",
          "JobAddress2": "Suite A",
          "JobFullAddress": "7798 Diversity Drive Suite A",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "Triversity Craft Force LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$24,483.87",
          "BranchNumber": 7270,
          "SubmitDate": "11-16-2021",
          "ClientonJob": "Triversity Craft Force LLC"
        },
        {
          "WorkOrder": 4186058,
          "ParentWorkOrder": "",
          "JobName": "Mayweather Boxing Fitness",
          "JobNumber": "7270-102652-12",
          "FirstdayontheJob": "11-15-2021",
          "LastdayontheJob": "11-15-2021",
          "EstimatedLastdayontheJob": "11-15-2021",
          "JobAddress1": "3825 EDWARDS RD",
          "JobAddress2": "Suite 105",
          "JobFullAddress": "3825 EDWARDS RD Suite 105",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Kiesland Development Srvcs LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,984.95",
          "BranchNumber": 7270,
          "SubmitDate": "11-16-2021",
          "ClientonJob": "Kiesland Development Srvcs LLC"
        },
        {
          "WorkOrder": 4186057,
          "ParentWorkOrder": "",
          "JobName": "Secor Senior Lofts",
          "JobNumber": "7302-101113-26",
          "FirstdayontheJob": "11-15-2021",
          "LastdayontheJob": "11-15-2021",
          "EstimatedLastdayontheJob": "11-15-2021",
          "JobAddress1": "3839 Secor Rd",
          "JobAddress2": "",
          "JobFullAddress": "3839 Secor Rd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,630.05",
          "BranchNumber": 7302,
          "SubmitDate": "11-16-2021",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4186056,
          "ParentWorkOrder": "",
          "JobName": "Csafe Global",
          "JobNumber": "7347-132779-19",
          "FirstdayontheJob": "11-15-2021",
          "LastdayontheJob": "11-15-2021",
          "EstimatedLastdayontheJob": "11-15-2021",
          "JobAddress1": "675 Gateway Blvd",
          "JobAddress2": "rock 859-991-5766",
          "JobFullAddress": "675 Gateway Blvd rock 859-991-5766",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & A Interior Systems",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$99,264.69",
          "BranchNumber": 7347,
          "SubmitDate": "",
          "ClientonJob": "J & A Interior Systems"
        },
        {
          "WorkOrder": 4186055,
          "ParentWorkOrder": "",
          "JobName": "Rosewood Arts",
          "JobNumber": "7348-132711-10",
          "FirstdayontheJob": "11-15-2021",
          "LastdayontheJob": "11-15-2021",
          "EstimatedLastdayontheJob": "11-15-2021",
          "JobAddress1": "2655 Olson Dr",
          "JobAddress2": "",
          "JobFullAddress": "2655 Olson Dr",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Integrity Interiors US LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,194.11",
          "BranchNumber": 7348,
          "SubmitDate": "11-16-2021",
          "ClientonJob": "Integrity Interiors US LLC"
        },
        {
          "WorkOrder": 4186054,
          "ParentWorkOrder": "",
          "JobName": "Madison on 1st Shell",
          "JobNumber": "7347-133810-39",
          "FirstdayontheJob": "11-15-2021",
          "LastdayontheJob": "11-15-2021",
          "EstimatedLastdayontheJob": "11-15-2021",
          "JobAddress1": "424 E First St",
          "JobAddress2": "",
          "JobFullAddress": "424 E First St",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Benco Construction Servic",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$57,554.06",
          "BranchNumber": 7347,
          "SubmitDate": "11-16-2021",
          "ClientonJob": "Benco Construction Servic"
        },
        {
          "WorkOrder": 4185420,
          "ParentWorkOrder": "",
          "JobName": "Ford Building Six",
          "JobNumber": "7313-101349-50",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "20600 Rotunda Dr",
          "JobAddress2": "",
          "JobFullAddress": "20600 Rotunda Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Barton Malow Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$36,126.42",
          "BranchNumber": 7313,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "Barton Malow Company"
        },
        {
          "WorkOrder": 4185419,
          "ParentWorkOrder": "",
          "JobName": "Warren GM ECC",
          "JobNumber": "7313-133905-9",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "30001 VanDyke Rd",
          "JobAddress2": "",
          "JobFullAddress": "30001 VanDyke Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Ideal Contracting LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$25,334.11",
          "BranchNumber": 7313,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "Ideal Contracting LLC"
        },
        {
          "WorkOrder": 4185418,
          "ParentWorkOrder": "",
          "JobName": "Njp Nu Wool",
          "JobNumber": "7308-104328-51",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "2472 Port Sheldon St",
          "JobAddress2": "",
          "JobFullAddress": "2472 Port Sheldon St",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Intext Concepts Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$21,336.72",
          "BranchNumber": 7308,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "Intext Concepts Inc"
        },
        {
          "WorkOrder": 4185417,
          "ParentWorkOrder": "",
          "JobName": "The DOT Ferndale",
          "JobNumber": "7313-137350-24",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "221 W Troy St",
          "JobAddress2": "",
          "JobFullAddress": "221 W Troy St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D A Contracting LLC",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,300.81",
          "BranchNumber": 7313,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "D A Contracting LLC"
        },
        {
          "WorkOrder": 4185416,
          "ParentWorkOrder": "",
          "JobName": "Starbucks-Lowell",
          "JobNumber": "7308-137191-19",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "11901 Fulton St E",
          "JobAddress2": "",
          "JobFullAddress": "11901 Fulton St E",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Triad Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$15,694.50",
          "BranchNumber": 7308,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "Triad Interiors LLC"
        },
        {
          "WorkOrder": 4185415,
          "ParentWorkOrder": "",
          "JobName": "K-9 Elite # 3136",
          "JobNumber": "7313-102953-46",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "2700 Auburn  Ct",
          "JobAddress2": "",
          "JobFullAddress": "2700 Auburn  Ct",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,795.22",
          "BranchNumber": 7313,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "Commercial Contracting"
        },
        {
          "WorkOrder": 4185414,
          "ParentWorkOrder": "",
          "JobName": "Pace 2nd Floor",
          "JobNumber": "7313-104400-15",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "28100 Cabot Dr",
          "JobAddress2": "",
          "JobFullAddress": "28100 Cabot Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Parallel Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,897.24",
          "BranchNumber": 7313,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "Parallel Construction"
        },
        {
          "WorkOrder": 4185412,
          "ParentWorkOrder": "",
          "JobName": "Chipotle Plymouth",
          "JobNumber": "7310-105016-10",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "40815 Ann Arbor Rd",
          "JobAddress2": "",
          "JobFullAddress": "40815 Ann Arbor Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elite Builders LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,809.67",
          "BranchNumber": 7310,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "Elite Builders LLC"
        },
        {
          "WorkOrder": 4185411,
          "ParentWorkOrder": "",
          "JobName": "McDonalds #21837",
          "JobNumber": "7313-100963-118",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "38418 Ford Rd",
          "JobAddress2": "",
          "JobFullAddress": "38418 Ford Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,572.95",
          "BranchNumber": 7313,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4185410,
          "ParentWorkOrder": "",
          "JobName": "TACO BELL BROWNSTOWN",
          "JobNumber": "7310-102087-34",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "21121 Gibraltar RD",
          "JobAddress2": "",
          "JobFullAddress": "21121 Gibraltar RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H Pastor & Sons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,315.10",
          "BranchNumber": 7310,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "George H Pastor & Sons"
        },
        {
          "WorkOrder": 4185409,
          "ParentWorkOrder": "",
          "JobName": "CULVERS CALEDONIA",
          "JobNumber": "7308-104198-42",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "6697 BROADMOOR AVE SE",
          "JobAddress2": "",
          "JobFullAddress": "6697 BROADMOOR AVE SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,317.89",
          "BranchNumber": 7308,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4185408,
          "ParentWorkOrder": "",
          "JobName": "Nof Kalkaska Project",
          "JobNumber": "7313-100963-85",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "731 S Cedar St",
          "JobAddress2": "",
          "JobFullAddress": "731 S Cedar St",
          "JobCounty": "Kalkaska",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,601.71",
          "BranchNumber": 7313,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4185407,
          "ParentWorkOrder": "",
          "JobName": "Rochester Kroger",
          "JobNumber": "7313-100963-104",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "65 S Livernois Rd",
          "JobAddress2": "",
          "JobFullAddress": "65 S Livernois Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,114.14",
          "BranchNumber": 7313,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4185406,
          "ParentWorkOrder": "",
          "JobName": "Dearborn Heights City Council",
          "JobNumber": "7310-133899-5",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "6045 Fenton St",
          "JobAddress2": "",
          "JobFullAddress": "6045 Fenton St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Travco Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,111.28",
          "BranchNumber": 7310,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "Travco Interiors LLC"
        },
        {
          "WorkOrder": 4185405,
          "ParentWorkOrder": "",
          "JobName": "Marine City Contracting",
          "JobNumber": "7313-136494-16",
          "FirstdayontheJob": "11-11-2021",
          "LastdayontheJob": "11-11-2021",
          "EstimatedLastdayontheJob": "11-11-2021",
          "JobAddress1": "1161 S Main Street",
          "JobAddress2": "",
          "JobFullAddress": "1161 S Main Street",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Marine City Contracting Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,110.07",
          "BranchNumber": 7313,
          "SubmitDate": "11-12-2021",
          "ClientonJob": "Marine City Contracting Inc"
        },
        {
          "WorkOrder": 4185385,
          "ParentWorkOrder": "",
          "JobName": "Azzur Group",
          "JobNumber": 128213,
          "FirstdayontheJob": "02-17-2021",
          "LastdayontheJob": "08-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "60 Blanchard Road",
          "JobAddress2": "",
          "JobFullAddress": "60 Blanchard Road",
          "JobCounty": "Southern Middlesex District",
          "JobState": "Massachusetts",
          "CustomerCompanyName": "K & G Drywall LLC",
          "RequestType": "Notice of Contract - Lien (Subcontractor/ Supplier)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$130,042.30",
          "BalanceRemaining": "$69,227.55",
          "BranchNumber": 7397,
          "SubmitDate": "",
          "ClientonJob": "K & G Drywall LLC"
        },
        {
          "WorkOrder": 4185368,
          "ParentWorkOrder": 4165788,
          "JobName": "Grizzlies Restaurant at the Airport",
          "JobNumber": 132814,
          "FirstdayontheJob": "",
          "LastdayontheJob": "07-30-2021",
          "EstimatedLastdayontheJob": "07-30-2021",
          "JobAddress1": "2491 WINCHESTER RD",
          "JobAddress2": "SPACE 2-CK 115A",
          "JobFullAddress": "2491 WINCHESTER RD SPACE 2-CK 115A",
          "JobCounty": "Shelby",
          "JobState": "Tennessee",
          "CustomerCompanyName": "High Caliber Const. LLC",
          "RequestType": "Satisfaction",
          "CustomerRepresentative": "Olivia Quinones",
          "Status": "Draft",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,157.70",
          "BranchNumber": 7265,
          "SubmitDate": "",
          "ClientonJob": "High Caliber Const. LLC"
        },
        {
          "WorkOrder": 4184313,
          "ParentWorkOrder": 4181034,
          "JobName": "City View Apartments",
          "JobNumber": "7310-101038-44",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "501 S Capitol Ave",
          "JobAddress2": "",
          "JobFullAddress": "501 S Capitol Ave",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,517.07",
          "BranchNumber": 7310,
          "SubmitDate": "11-09-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4181323,
          "ParentWorkOrder": "",
          "JobName": "Nof Fischer Homes Ofc",
          "JobNumber": "7348-135976-3",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "7777 Washington Village Dr",
          "JobAddress2": "Ste 300",
          "JobFullAddress": "7777 Washington Village Dr Ste 300",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "Woodard Development",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,143.41",
          "BranchNumber": 7348,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "Woodard Development"
        },
        {
          "WorkOrder": 4181320,
          "ParentWorkOrder": "",
          "JobName": "ROSS DRESS",
          "JobNumber": "7272-132739-18",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "1881 HILLIARD ROME RD",
          "JobAddress2": "",
          "JobFullAddress": "1881 HILLIARD ROME RD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$59,447.09",
          "BranchNumber": 7272,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4181319,
          "ParentWorkOrder": "",
          "JobName": "Dollar Tree Galbraith",
          "JobNumber": "7348-106905-12",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "1571 W Galbraith Rd",
          "JobAddress2": "",
          "JobFullAddress": "1571 W Galbraith Rd",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Viper Wall Systems",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,844.15",
          "BranchNumber": 7348,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "Viper Wall Systems"
        },
        {
          "WorkOrder": 4181318,
          "ParentWorkOrder": "",
          "JobName": "ELIZA JENNINGS",
          "JobNumber": "7400-102686-8",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "26376 JOHN ROAD",
          "JobAddress2": "2ND FLOOR",
          "JobFullAddress": "26376 JOHN ROAD 2ND FLOOR",
          "JobCounty": "CUYAHOGA",
          "JobState": "Ohio",
          "CustomerCompanyName": "Trinity Drywall & Paintin LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,559.55",
          "BranchNumber": 7400,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "Trinity Drywall & Paintin LLC"
        },
        {
          "WorkOrder": 4181317,
          "ParentWorkOrder": "",
          "JobName": "Lee & Ass Relocation",
          "JobNumber": "7270-132755-51",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "10260 Alliance Rd",
          "JobAddress2": "",
          "JobFullAddress": "10260 Alliance Rd",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "T Square Wall Systems LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,517.43",
          "BranchNumber": 7270,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "T Square Wall Systems LLC"
        },
        {
          "WorkOrder": 4181316,
          "ParentWorkOrder": "",
          "JobName": "Tealtown Apartments",
          "JobNumber": "7270-132712-5",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "4564 Tealtown Rd",
          "JobAddress2": "",
          "JobFullAddress": "4564 Tealtown Rd",
          "JobCounty": "CLERMONT",
          "JobState": "Ohio",
          "CustomerCompanyName": "Newphase Drywall LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,527.27",
          "BranchNumber": 7270,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "Newphase Drywall LLC"
        },
        {
          "WorkOrder": 4181315,
          "ParentWorkOrder": "",
          "JobName": "McDonalds Georgesville 3400",
          "JobNumber": "7348-131575-16",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "800 Georgesville Rd",
          "JobAddress2": "",
          "JobFullAddress": "800 Georgesville Rd",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "LIS Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,093.77",
          "BranchNumber": 7348,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "LIS Construction"
        },
        {
          "WorkOrder": 4181314,
          "ParentWorkOrder": "",
          "JobName": "McDonalds Paxton 14529",
          "JobNumber": "7347-131575-17",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "3880 Paxton Ave",
          "JobAddress2": "",
          "JobFullAddress": "3880 Paxton Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "LIS Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,857.29",
          "BranchNumber": 7347,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "LIS Construction"
        },
        {
          "WorkOrder": 4181313,
          "ParentWorkOrder": "",
          "JobName": "Sun Treatment",
          "JobNumber": "7270-102652-13",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "247 Cic Blvd",
          "JobAddress2": "",
          "JobFullAddress": "247 Cic Blvd",
          "JobCounty": "ADAMS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Kiesland Development Services LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$100,754.42",
          "BranchNumber": 7270,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "Kiesland Development Services LLC"
        },
        {
          "WorkOrder": 4181310,
          "ParentWorkOrder": "",
          "JobName": "Mayweather Boxing Fitness",
          "JobNumber": "7270-102652-12",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "3825 EDWARDS RD",
          "JobAddress2": "Suite 105",
          "JobFullAddress": "3825 EDWARDS RD Suite 105",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Kiesland Development Srvcs LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,984.95",
          "BranchNumber": 7270,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "Kiesland Development Srvcs LLC"
        },
        {
          "WorkOrder": 4181309,
          "ParentWorkOrder": "",
          "JobName": "Superior Credit Union Toledo",
          "JobNumber": "7302-101113-24",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "5642 Airport Highway",
          "JobAddress2": "",
          "JobFullAddress": "5642 Airport Highway",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$26,024.54",
          "BranchNumber": 7302,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4181308,
          "ParentWorkOrder": "",
          "JobName": "Csafe Global",
          "JobNumber": "7347-132779-19",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "675 Gateway Blvd",
          "JobAddress2": "",
          "JobFullAddress": "675 Gateway Blvd",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & A Interior Systems",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$76,598.26",
          "BranchNumber": 7347,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "J & A Interior Systems"
        },
        {
          "WorkOrder": 4181307,
          "ParentWorkOrder": "",
          "JobName": "North point Bldg 6 TI Crocs",
          "JobNumber": "7347-132779-18",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "1805 Union Airpark Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1805 Union Airpark Blvd",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & A Interior Systems",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,029.50",
          "BranchNumber": 7347,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "J & A Interior Systems"
        },
        {
          "WorkOrder": 4181306,
          "ParentWorkOrder": "",
          "JobName": "Ross Store #2364",
          "JobNumber": "7348-132711-11",
          "FirstdayontheJob": "11-02-2021",
          "LastdayontheJob": "11-02-2021",
          "EstimatedLastdayontheJob": "11-02-2021",
          "JobAddress1": "542 Lexington Springmill Rd",
          "JobAddress2": "",
          "JobFullAddress": "542 Lexington Springmill Rd",
          "JobCounty": "RICHLAND",
          "JobState": "Ohio",
          "CustomerCompanyName": "Integrity Interiors US LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$18,977.82",
          "BranchNumber": 7348,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "Integrity Interiors US LLC"
        },
        {
          "WorkOrder": 4181047,
          "ParentWorkOrder": "",
          "JobName": "G M Live",
          "JobNumber": "7310-104443-2",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "35700 Van Dyke Ave",
          "JobAddress2": "",
          "JobFullAddress": "35700 Van Dyke Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Czarnowski Display Service Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$56,356.00",
          "BranchNumber": 7310,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Czarnowski Display Service Inc"
        },
        {
          "WorkOrder": 4181046,
          "ParentWorkOrder": "",
          "JobName": "GCT Battery Lab #200326",
          "JobNumber": "7313-101349-51",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "6395 William Knudsen Rd",
          "JobAddress2": "",
          "JobFullAddress": "6395 William Knudsen Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Barton Malow Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$31,289.66",
          "BranchNumber": 7313,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Barton Malow Company"
        },
        {
          "WorkOrder": 4181045,
          "ParentWorkOrder": "",
          "JobName": "TRI COUNTY ELEM SCHOOL",
          "JobNumber": "7308-104969-14",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "21350 KENDAVILLE RD",
          "JobAddress2": "",
          "JobFullAddress": "21350 KENDAVILLE RD",
          "JobCounty": "MONTCALM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Premier 1 Interiors LLC",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,548.64",
          "BranchNumber": 7308,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Premier 1 Interiors LLC"
        },
        {
          "WorkOrder": 4181044,
          "ParentWorkOrder": "",
          "JobName": "Yanfeng Monroe",
          "JobNumber": "7422-105170-19",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "1833 Frenchtown Center Dr",
          "JobAddress2": "",
          "JobFullAddress": "1833 Frenchtown Center Dr",
          "JobCounty": "MONROE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Industrial Tech Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,236.79",
          "BranchNumber": 7422,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Industrial Tech Services"
        },
        {
          "WorkOrder": 4181043,
          "ParentWorkOrder": "",
          "JobName": "MMMH Supply & Distribution",
          "JobNumber": "7304-104189-15",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "6800 Eastman Ave",
          "JobAddress2": "Job# P21411",
          "JobFullAddress": "6800 Eastman Ave Job# P21411",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Three Rivers Corporation",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,994.48",
          "BranchNumber": 7304,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Three Rivers Corporation"
        },
        {
          "WorkOrder": 4181042,
          "ParentWorkOrder": "",
          "JobName": "Traverse City",
          "JobNumber": "7308-104106-9",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "1829 Carroll Rd",
          "JobAddress2": "",
          "JobFullAddress": "1829 Carroll Rd",
          "JobCounty": "Grand Traverse",
          "JobState": "Michigan",
          "CustomerCompanyName": "Moeller Builders Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,767.42",
          "BranchNumber": 7308,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Moeller Builders Inc"
        },
        {
          "WorkOrder": 4181041,
          "ParentWorkOrder": "",
          "JobName": "Culver's Monroe",
          "JobNumber": "7313-100963-109",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "1901 North Telegraph Rd",
          "JobAddress2": "",
          "JobFullAddress": "1901 North Telegraph Rd",
          "JobCounty": "MONROE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,195.02",
          "BranchNumber": 7313,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4181040,
          "ParentWorkOrder": "",
          "JobName": "Fisher and Phillips",
          "JobNumber": "7310-104649-6",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "300 Park St",
          "JobAddress2": "",
          "JobFullAddress": "300 Park St",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "NVR Finishes Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,220.20",
          "BranchNumber": 7310,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "NVR Finishes Inc"
        },
        {
          "WorkOrder": 4181039,
          "ParentWorkOrder": "",
          "JobName": "Auria Roof Raise",
          "JobNumber": "7313-102839-28",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "2001 Christian B Haas",
          "JobAddress2": "",
          "JobFullAddress": "2001 Christian B Haas",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lee Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$14,236.74",
          "BranchNumber": 7313,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Lee Contracting"
        },
        {
          "WorkOrder": 4181038,
          "ParentWorkOrder": "",
          "JobName": "Dermatology Office",
          "JobNumber": "7304-104106-8",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "3925 Fortune Blvd",
          "JobAddress2": "",
          "JobFullAddress": "3925 Fortune Blvd",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Moeller Builders Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$151.83",
          "BranchNumber": 7304,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Moeller Builders Inc"
        },
        {
          "WorkOrder": 4181037,
          "ParentWorkOrder": "",
          "JobName": "Marlette Job",
          "JobNumber": "7304-105171-67",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "2827 Main Street",
          "JobAddress2": "",
          "JobFullAddress": "2827 Main Street",
          "JobCounty": "SANILAC",
          "JobState": "Michigan",
          "CustomerCompanyName": "Nates Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,393.00",
          "BranchNumber": 7304,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Nates Drywall"
        },
        {
          "WorkOrder": 4181036,
          "ParentWorkOrder": "",
          "JobName": "Park Promenade/SVS Vision",
          "JobNumber": "7313-137350-17",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "22600 ALLEN RD",
          "JobAddress2": "",
          "JobFullAddress": "22600 ALLEN RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D A Contracting LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,459.50",
          "BranchNumber": 7313,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "D A Contracting LLC"
        },
        {
          "WorkOrder": 4181035,
          "ParentWorkOrder": "",
          "JobName": "Popeyes Shelby",
          "JobNumber": "7313-104476-7",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "12285 23 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "12285 23 MILE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,527.57",
          "BranchNumber": 7313,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "M & V Construction"
        },
        {
          "WorkOrder": 4181034,
          "ParentWorkOrder": "",
          "JobName": "City View Apartments",
          "JobNumber": "7310-101038-44",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "501 S Capitol Ave",
          "JobAddress2": "",
          "JobFullAddress": "501 S Capitol Ave",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,517.07",
          "BranchNumber": 7310,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4181033,
          "ParentWorkOrder": "",
          "JobName": "Northline Church",
          "JobNumber": "7313-100963-120",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "22140 CHAMPAIGN ST",
          "JobAddress2": "",
          "JobFullAddress": "22140 CHAMPAIGN ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,412.67",
          "BranchNumber": 7313,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4181032,
          "ParentWorkOrder": "",
          "JobName": "MCDESV",
          "JobNumber": "7312-104179-5",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "3893 Okemos Rd",
          "JobAddress2": "Ste B2",
          "JobFullAddress": "3893 Okemos Rd Ste B2",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Louis J Eyde Family LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,686.07",
          "BranchNumber": 7312,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Louis J Eyde Family LLC"
        },
        {
          "WorkOrder": 4181031,
          "ParentWorkOrder": "",
          "JobName": "Warren Truck",
          "JobNumber": "7313-102953-45",
          "FirstdayontheJob": "11-01-2021",
          "LastdayontheJob": "11-01-2021",
          "EstimatedLastdayontheJob": "11-01-2021",
          "JobAddress1": "21500 Mound Rd",
          "JobAddress2": "",
          "JobFullAddress": "21500 Mound Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,198.05",
          "BranchNumber": 7313,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "Commercial Contracting"
        },
        {
          "WorkOrder": 4180999,
          "ParentWorkOrder": 4130572,
          "JobName": "(NOF)Project Worley",
          "JobNumber": "502-815750-318",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "05-18-2021",
          "JobAddress1": "46555 Magellan Dr",
          "JobAddress2": "",
          "JobFullAddress": "46555 Magellan Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "11-02-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION"
        },
        {
          "WorkOrder": 4180998,
          "ParentWorkOrder": 4169315,
          "JobName": "Kroger Midland",
          "JobNumber": "7313-101358-14",
          "FirstdayontheJob": "09-28-2021",
          "LastdayontheJob": "09-28-2021",
          "EstimatedLastdayontheJob": "09-28-2021",
          "JobAddress1": "2808 Ashman Street",
          "JobAddress2": "",
          "JobFullAddress": "2808 Ashman Street",
          "JobCounty": "Midland",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC Construction Co Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,067.89",
          "BranchNumber": 7313,
          "SubmitDate": "",
          "ClientonJob": "AC Construction Co Inc"
        },
        {
          "WorkOrder": 4180200,
          "ParentWorkOrder": "",
          "JobName": "KNAPKE HOUSE - SIDNEY",
          "JobNumber": "100779-0002",
          "FirstdayontheJob": "10-01-2021",
          "LastdayontheJob": "10-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10360 MILLCREEK RD",
          "JobAddress2": "",
          "JobFullAddress": "10360 MILLCREEK RD",
          "JobCounty": "Shelby",
          "JobState": "Ohio",
          "CustomerCompanyName": "DURANGO DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "N/A",
          "BranchNumber": 348,
          "SubmitDate": "11-03-2021",
          "ClientonJob": "DURANGO DRYWALL LLC"
        },
        {
          "WorkOrder": 4178550,
          "ParentWorkOrder": "",
          "JobName": "THE ANNEX",
          "JobNumber": "103495-2",
          "FirstdayontheJob": "",
          "LastdayontheJob": "08-27-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3601 Midland Ave",
          "JobAddress2": "",
          "JobFullAddress": "3601 Midland Ave",
          "JobCounty": "Shelby",
          "JobState": "Tennessee",
          "CustomerCompanyName": "SM ALAMO SYSTEMS",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Olivia Quinones",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$19,877.01",
          "BranchNumber": 7265,
          "SubmitDate": "10-26-2021",
          "ClientonJob": "SM ALAMO SYSTEMS"
        },
        {
          "WorkOrder": 4178172,
          "ParentWorkOrder": 4169621,
          "JobName": "HCF BG",
          "JobNumber": "7302-133878-9",
          "FirstdayontheJob": "09-29-2021",
          "LastdayontheJob": "09-29-2021",
          "EstimatedLastdayontheJob": "09-29-2021",
          "JobAddress1": "1021 W Poe Road",
          "JobAddress2": "",
          "JobFullAddress": "1021 W Poe Road",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "RCS Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,666.24",
          "BranchNumber": 7302,
          "SubmitDate": "10-25-2021",
          "ClientonJob": "RCS Construction"
        },
        {
          "WorkOrder": 4176303,
          "ParentWorkOrder": "",
          "JobName": "SHEETZ",
          "JobNumber": "7272-132739-19",
          "FirstdayontheJob": "10-21-2021",
          "LastdayontheJob": "10-21-2021",
          "EstimatedLastdayontheJob": "10-21-2021",
          "JobAddress1": "1700-1716 and 1690 Stelzer Rd",
          "JobAddress2": "",
          "JobFullAddress": "1700-1716 and 1690 Stelzer Rd",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$18,103.67",
          "BranchNumber": 7272,
          "SubmitDate": "10-22-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4176302,
          "ParentWorkOrder": "",
          "JobName": "PROJECT BOLTS",
          "JobNumber": "7347-100175-60",
          "FirstdayontheJob": "10-21-2021",
          "LastdayontheJob": "10-21-2021",
          "EstimatedLastdayontheJob": "10-21-2021",
          "JobAddress1": "3512 SPRING GROVE AVE",
          "JobAddress2": "",
          "JobFullAddress": "3512 SPRING GROVE AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Performance Contracting Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$97,133.83",
          "BranchNumber": 7347,
          "SubmitDate": "10-22-2021",
          "ClientonJob": "Performance Contracting Inc"
        },
        {
          "WorkOrder": 4176301,
          "ParentWorkOrder": "",
          "JobName": "Mont Qtr 2C",
          "JobNumber": "7347-133958-10",
          "FirstdayontheJob": "10-21-2021",
          "LastdayontheJob": "10-21-2021",
          "EstimatedLastdayontheJob": "10-21-2021",
          "JobAddress1": "9300 Montgomery Rd",
          "JobAddress2": "",
          "JobFullAddress": "9300 Montgomery Rd",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Northside Supply",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,771.41",
          "BranchNumber": 7347,
          "SubmitDate": "10-22-2021",
          "ClientonJob": "Northside Supply"
        },
        {
          "WorkOrder": 4176300,
          "ParentWorkOrder": "",
          "JobName": "Fickwood Plumbing",
          "JobNumber": "7302-150842-2",
          "FirstdayontheJob": "10-21-2021",
          "LastdayontheJob": "10-21-2021",
          "EstimatedLastdayontheJob": "10-21-2021",
          "JobAddress1": "3754 Herr Road",
          "JobAddress2": "",
          "JobFullAddress": "3754 Herr Road",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Makena Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$25,642.52",
          "BranchNumber": 7302,
          "SubmitDate": "10-22-2021",
          "ClientonJob": "Makena Construction LLC"
        },
        {
          "WorkOrder": 4176299,
          "ParentWorkOrder": "",
          "JobName": "Project Zeta",
          "JobNumber": "7272-133976-8",
          "FirstdayontheJob": "10-21-2021",
          "LastdayontheJob": "10-21-2021",
          "EstimatedLastdayontheJob": "10-21-2021",
          "JobAddress1": "Thornwood Keller Drive",
          "JobAddress2": "",
          "JobFullAddress": "Thornwood Keller Drive",
          "JobCounty": "Licking",
          "JobState": "Ohio",
          "CustomerCompanyName": "Liberty General Contr",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$31,219.14",
          "BranchNumber": 7272,
          "SubmitDate": "10-22-2021",
          "ClientonJob": "Liberty General Contr"
        },
        {
          "WorkOrder": 4176298,
          "ParentWorkOrder": "",
          "JobName": "Mayweather Boxing Fitness",
          "JobNumber": "7270-102652-12",
          "FirstdayontheJob": "10-21-2021",
          "LastdayontheJob": "10-21-2021",
          "EstimatedLastdayontheJob": "10-21-2021",
          "JobAddress1": "3825 Edwards Rd",
          "JobAddress2": "Suite105",
          "JobFullAddress": "3825 Edwards Rd Suite105",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Kiesland Development Srvcs LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,088.64",
          "BranchNumber": 7270,
          "SubmitDate": "10-22-2021",
          "ClientonJob": "Kiesland Development Srvcs LLC"
        },
        {
          "WorkOrder": 4176297,
          "ParentWorkOrder": "",
          "JobName": "O'Reilly Auto Parts Toledo",
          "JobNumber": "7302-101113-22",
          "FirstdayontheJob": "10-21-2021",
          "LastdayontheJob": "10-21-2021",
          "EstimatedLastdayontheJob": "10-21-2021",
          "JobAddress1": "4603 Suder Ave",
          "JobAddress2": "",
          "JobFullAddress": "4603 Suder Ave",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$60,637.13",
          "BranchNumber": 7302,
          "SubmitDate": "10-22-2021",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4176296,
          "ParentWorkOrder": "",
          "JobName": "SCHULTE WELLNESS CENTER",
          "JobNumber": "7348-131687-6",
          "FirstdayontheJob": "10-21-2021",
          "LastdayontheJob": "10-21-2021",
          "EstimatedLastdayontheJob": "10-21-2021",
          "JobAddress1": "1928 DONN DAVIS WAY",
          "JobAddress2": "",
          "JobFullAddress": "1928 DONN DAVIS WAY",
          "JobCounty": "MIAMI",
          "JobState": "Ohio",
          "CustomerCompanyName": "Construction Service Partners",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,004.10",
          "BranchNumber": 7348,
          "SubmitDate": "10-22-2021",
          "ClientonJob": "Construction Service Partners"
        },
        {
          "WorkOrder": 4176003,
          "ParentWorkOrder": "",
          "JobName": "20600 Rotunda Dr.",
          "JobNumber": "7313-101349-49",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "20600 Rotunda Dr",
          "JobAddress2": "",
          "JobFullAddress": "20600 Rotunda Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Barton Malow Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$36,768.49",
          "BranchNumber": 7313,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "Barton Malow Company"
        },
        {
          "WorkOrder": 4176002,
          "ParentWorkOrder": "",
          "JobName": "Hub Stadium 21053",
          "JobNumber": "7310-101038-45",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "44325 W 12 Mile Rd",
          "JobAddress2": "#H-160",
          "JobFullAddress": "44325 W 12 Mile Rd #H-160",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$23,430.41",
          "BranchNumber": 7310,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4176001,
          "ParentWorkOrder": "",
          "JobName": "Transform Automotive",
          "JobNumber": "7313-102839-25",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "52400 Shelby Parkway",
          "JobAddress2": "",
          "JobFullAddress": "52400 Shelby Parkway",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lee Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$23,372.83",
          "BranchNumber": 7313,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "Lee Contracting"
        },
        {
          "WorkOrder": 4176000,
          "ParentWorkOrder": "",
          "JobName": "Hazy Farms",
          "JobNumber": "7310-104795-2",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "2642 Princess St",
          "JobAddress2": "",
          "JobFullAddress": "2642 Princess St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Carpentry Concepts&ceilin",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$21,133.69",
          "BranchNumber": 7310,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "Carpentry Concepts&ceilin"
        },
        {
          "WorkOrder": 4175999,
          "ParentWorkOrder": "",
          "JobName": "Njp W Ottawa Stadium",
          "JobNumber": "7308-104198-25",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "3685 Butternut Dr",
          "JobAddress2": "",
          "JobFullAddress": "3685 Butternut Dr",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$18,000.95",
          "BranchNumber": 7308,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4175998,
          "ParentWorkOrder": "",
          "JobName": "Mad Rag",
          "JobNumber": "7313-137350-23",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "20380 W Eight Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "20380 W Eight Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D A Contracting LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,545.94",
          "BranchNumber": 7313,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "D A Contracting LLC"
        },
        {
          "WorkOrder": 4175997,
          "ParentWorkOrder": "",
          "JobName": "K&R TRUCKING",
          "JobNumber": "7308-104198-34",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "840 INTERCHANGE DR",
          "JobAddress2": "",
          "JobFullAddress": "840 INTERCHANGE DR",
          "JobCounty": "ALLEGAN",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,491.09",
          "BranchNumber": 7308,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4175996,
          "ParentWorkOrder": "",
          "JobName": "LaBelle Development",
          "JobNumber": "7422-104492-30",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "31100 S River Rd",
          "JobAddress2": "",
          "JobFullAddress": "31100 S River Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DGS Drywall LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,817.95",
          "BranchNumber": 7422,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "DGS Drywall LLC"
        },
        {
          "WorkOrder": 4175995,
          "ParentWorkOrder": "",
          "JobName": "McDonald's #15401",
          "JobNumber": "7313-100963-111",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "15401 E 12 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "15401 E 12 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,275.72",
          "BranchNumber": 7313,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4175994,
          "ParentWorkOrder": "",
          "JobName": "Hastings Harvest Point",
          "JobNumber": "7308-104326-27",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "2990 McKeown Rd",
          "JobAddress2": "",
          "JobFullAddress": "2990 McKeown Rd",
          "JobCounty": "BARRY",
          "JobState": "Michigan",
          "CustomerCompanyName": "Halyard",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,639.00",
          "BranchNumber": 7308,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "Halyard"
        },
        {
          "WorkOrder": 4175993,
          "ParentWorkOrder": "",
          "JobName": "Warren White Box",
          "JobNumber": "7313-100963-119",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "6115 E 10 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "6115 E 10 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,470.67",
          "BranchNumber": 7313,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4175992,
          "ParentWorkOrder": "",
          "JobName": "Texas Roadhouse",
          "JobNumber": "7312-100979-9",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "8114 W Saginaw",
          "JobAddress2": "",
          "JobFullAddress": "8114 W Saginaw",
          "JobCounty": "EATON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Monnin Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,153.19",
          "BranchNumber": 7312,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "Monnin Construction LLC"
        },
        {
          "WorkOrder": 4175991,
          "ParentWorkOrder": "",
          "JobName": "Proshine Southgate Auto Wash",
          "JobNumber": "7313-137388-16",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "16610 Fort Street",
          "JobAddress2": "",
          "JobFullAddress": "16610 Fort Street",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Patriot Team Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,580.97",
          "BranchNumber": 7313,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "Patriot Team Inc"
        },
        {
          "WorkOrder": 4175990,
          "ParentWorkOrder": "",
          "JobName": "16525 Industrial St.",
          "JobNumber": "7422-105144-8",
          "FirstdayontheJob": "10-20-2021",
          "LastdayontheJob": "10-20-2021",
          "EstimatedLastdayontheJob": "10-20-2021",
          "JobAddress1": "16525 Industrial St",
          "JobAddress2": "deliver thru front of building",
          "JobFullAddress": "16525 Industrial St deliver thru front of building",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Superior Const & Drywall LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,639.17",
          "BranchNumber": 7422,
          "SubmitDate": "10-21-2021",
          "ClientonJob": "Superior Const & Drywall LLC"
        },
        {
          "WorkOrder": 4175182,
          "ParentWorkOrder": 4169312,
          "JobName": "GREAT LAKES",
          "JobNumber": "7308-104198-38",
          "FirstdayontheJob": "09-28-2021",
          "LastdayontheJob": "09-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3040 IVANREST AVE SW",
          "JobAddress2": "",
          "JobFullAddress": "3040 IVANREST AVE SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,939.16",
          "BranchNumber": 7308,
          "SubmitDate": "10-19-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4174757,
          "ParentWorkOrder": "",
          "JobName": "McDonalds Cherry St Renovation",
          "JobNumber": "7302-104927-21",
          "FirstdayontheJob": "10-17-2021",
          "LastdayontheJob": "10-17-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1520 Cherry St",
          "JobAddress2": "",
          "JobFullAddress": "1520 Cherry St",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Midwest Contracting Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,007.14",
          "BranchNumber": 7302,
          "SubmitDate": "10-18-2021",
          "ClientonJob": "Midwest Contracting Inc"
        },
        {
          "WorkOrder": 4174756,
          "ParentWorkOrder": "",
          "JobName": "O'Reilly Auto Parts Waterville",
          "JobNumber": "7302-101113-23",
          "FirstdayontheJob": "10-17-2021",
          "LastdayontheJob": "10-17-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1060 Pray Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1060 Pray Blvd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,564.54",
          "BranchNumber": 7302,
          "SubmitDate": "10-18-2021",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4174736,
          "ParentWorkOrder": "",
          "JobName": "Slatington Market Place",
          "JobNumber": 516972,
          "FirstdayontheJob": "02-26-2021",
          "LastdayontheJob": "06-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8281 Route 873",
          "JobAddress2": "",
          "JobFullAddress": "8281 Route 873",
          "JobCounty": "Lehigh",
          "JobState": "Pennsylvania",
          "CustomerCompanyName": "Innovative Surfaces LLC",
          "RequestType": "Subcontractor's Intent to Lien",
          "CustomerRepresentative": "Amy Chiodo",
          "Status": "Mailed",
          "ContractAmount": "$31,513.80",
          "BalanceRemaining": "$31,513.80",
          "BranchNumber": ".",
          "SubmitDate": "10-18-2021",
          "ClientonJob": "Innovative Surfaces LLC"
        },
        {
          "WorkOrder": 4174384,
          "ParentWorkOrder": "",
          "JobName": "AMST-Art Mulder Sons Trucking",
          "JobNumber": "7308-104198-39",
          "FirstdayontheJob": "10-14-2021",
          "LastdayontheJob": "10-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1936 Transport Lane",
          "JobAddress2": "",
          "JobFullAddress": "1936 Transport Lane",
          "JobCounty": "Allegan",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$70,206.22",
          "BranchNumber": 7308,
          "SubmitDate": "10-15-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4174383,
          "ParentWorkOrder": "",
          "JobName": "Red Lobster Warren",
          "JobNumber": "7313-104357-16",
          "FirstdayontheJob": "10-14-2021",
          "LastdayontheJob": "10-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25901 Hoover Rd",
          "JobAddress2": "",
          "JobFullAddress": "25901 Hoover Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Int Const",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$18,488.57",
          "BranchNumber": 7313,
          "SubmitDate": "10-15-2021",
          "ClientonJob": "Commercial Int Const"
        },
        {
          "WorkOrder": 4174382,
          "ParentWorkOrder": "",
          "JobName": "Cognex",
          "JobNumber": "7313-100963-114",
          "FirstdayontheJob": "10-14-2021",
          "LastdayontheJob": "10-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "660 E 10 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "660 E 10 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$15,082.72",
          "BranchNumber": 7313,
          "SubmitDate": "10-15-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4174381,
          "ParentWorkOrder": "",
          "JobName": "Allendale Christian",
          "JobNumber": "7308-104328-73",
          "FirstdayontheJob": "10-14-2021",
          "LastdayontheJob": "10-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11050 64th ave",
          "JobAddress2": "",
          "JobFullAddress": "11050 64th ave",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Intext Concepts Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$14,293.46",
          "BranchNumber": 7308,
          "SubmitDate": "10-15-2021",
          "ClientonJob": "Intext Concepts Inc"
        },
        {
          "WorkOrder": 4174380,
          "ParentWorkOrder": "",
          "JobName": "NJP Mercy Health",
          "JobNumber": "7308-104851-33",
          "FirstdayontheJob": "10-14-2021",
          "LastdayontheJob": "10-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 E Sherman Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1500 E Sherman Blvd",
          "JobCounty": "MUSKEGON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Level 5 Commercial Finish",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,938.40",
          "BranchNumber": 7308,
          "SubmitDate": "10-15-2021",
          "ClientonJob": "Level 5 Commercial Finish"
        },
        {
          "WorkOrder": 4174379,
          "ParentWorkOrder": "",
          "JobName": "AMST-Art Mulder Sons Trucking",
          "JobNumber": "7308-104198-39",
          "FirstdayontheJob": "10-14-2021",
          "LastdayontheJob": "10-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Holland",
          "JobAddress2": "",
          "JobFullAddress": "Holland",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,458.10",
          "BranchNumber": 7308,
          "SubmitDate": "",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4174378,
          "ParentWorkOrder": "",
          "JobName": "NOF Voyageur Academy",
          "JobNumber": "7422-105170-18",
          "FirstdayontheJob": "10-14-2021",
          "LastdayontheJob": "10-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4321 Military St",
          "JobAddress2": "",
          "JobFullAddress": "4321 Military St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Industrial Tech Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,102.88",
          "BranchNumber": 7422,
          "SubmitDate": "10-15-2021",
          "ClientonJob": "Industrial Tech Services"
        },
        {
          "WorkOrder": 4174377,
          "ParentWorkOrder": "",
          "JobName": "SC4 Challenger @ Clare E",
          "JobNumber": "7422-104517-6",
          "FirstdayontheJob": "10-14-2021",
          "LastdayontheJob": "10-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "323 Erie St",
          "JobAddress2": "",
          "JobFullAddress": "323 Erie St",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,825.16",
          "BranchNumber": 7422,
          "SubmitDate": "10-15-2021",
          "ClientonJob": "JFS Company Inc"
        },
        {
          "WorkOrder": 4174376,
          "ParentWorkOrder": "",
          "JobName": "Doxtator",
          "JobNumber": "7310-137397-16",
          "FirstdayontheJob": "10-14-2021",
          "LastdayontheJob": "10-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27130 Doxtator St",
          "JobAddress2": "",
          "JobFullAddress": "27130 Doxtator St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trademark Drywall LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,701.49",
          "BranchNumber": 7310,
          "SubmitDate": "10-15-2021",
          "ClientonJob": "Trademark Drywall LLC"
        },
        {
          "WorkOrder": 4173997,
          "ParentWorkOrder": 4155987,
          "JobName": "Njp Irs",
          "JobNumber": "7313-133614-4",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "985 Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "985 Michigan Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Acoustic Ceil & Part",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,013.92",
          "BranchNumber": 7313,
          "SubmitDate": "10-14-2021",
          "ClientonJob": "Acoustic Ceil & Part"
        },
        {
          "WorkOrder": 4173996,
          "ParentWorkOrder": 4169317,
          "JobName": "McLaren Port Huron mob",
          "JobNumber": "7310-101038-43",
          "FirstdayontheJob": "09-28-2021",
          "LastdayontheJob": "09-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1221 Pine Grove Ave",
          "JobAddress2": "",
          "JobFullAddress": "1221 Pine Grove Ave",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,742.89",
          "BranchNumber": 7310,
          "SubmitDate": "10-14-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4173475,
          "ParentWorkOrder": "",
          "JobName": "BIXLER HOUSE",
          "JobNumber": 100779,
          "FirstdayontheJob": "10-01-2021",
          "LastdayontheJob": "10-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "143 E Main St",
          "JobAddress2": "",
          "JobFullAddress": "143 E Main St",
          "JobCounty": "Shelby",
          "JobState": "Ohio",
          "CustomerCompanyName": "DURANGO DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "N/A",
          "BranchNumber": 13,
          "SubmitDate": "10-13-2021",
          "ClientonJob": "DURANGO DRYWALL LLC"
        },
        {
          "WorkOrder": 4172790,
          "ParentWorkOrder": "",
          "JobName": "SHEETZ",
          "JobNumber": "7272-132739-16",
          "FirstdayontheJob": "10-10-2021",
          "LastdayontheJob": "10-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1690 Stelzer Rd",
          "JobAddress2": "",
          "JobFullAddress": "1690 Stelzer Rd",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,244.83",
          "BranchNumber": 7272,
          "SubmitDate": "10-11-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4172789,
          "ParentWorkOrder": "",
          "JobName": "Family Dollar Toledo",
          "JobNumber": "7302-106905-11",
          "FirstdayontheJob": "10-10-2021",
          "LastdayontheJob": "10-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5126 N Summit St",
          "JobAddress2": "",
          "JobFullAddress": "5126 N Summit St",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Viper Wall Systems",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,787.69",
          "BranchNumber": 7302,
          "SubmitDate": "10-11-2021",
          "ClientonJob": "Viper Wall Systems"
        },
        {
          "WorkOrder": 4172788,
          "ParentWorkOrder": "",
          "JobName": "Country Meats",
          "JobNumber": "7270-133987-26",
          "FirstdayontheJob": "10-10-2021",
          "LastdayontheJob": "10-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3539 Reading Rd",
          "JobAddress2": "",
          "JobFullAddress": "3539 Reading Rd",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "Triversity Craft Force LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$18,266.61",
          "BranchNumber": 7270,
          "SubmitDate": "10-11-2021",
          "ClientonJob": "Triversity Craft Force LLC"
        },
        {
          "WorkOrder": 4172786,
          "ParentWorkOrder": "",
          "JobName": "Mueller Roofing",
          "JobNumber": "7270-132755-2",
          "FirstdayontheJob": "10-10-2021",
          "LastdayontheJob": "10-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11865 Hamilon Ave",
          "JobAddress2": "",
          "JobFullAddress": "11865 Hamilon Ave",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "T Square Wall Systems LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,655.87",
          "BranchNumber": 7270,
          "SubmitDate": "10-11-2021",
          "ClientonJob": "T Square Wall Systems LLC"
        },
        {
          "WorkOrder": 4172785,
          "ParentWorkOrder": "",
          "JobName": "DEER CREEK CABINS",
          "JobNumber": "7272-132741-6",
          "FirstdayontheJob": "10-10-2021",
          "LastdayontheJob": "10-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22300 STATE PARK RD",
          "JobAddress2": "CABINS 8-14",
          "JobFullAddress": "22300 STATE PARK RD CABINS 8-14",
          "JobCounty": "MADISON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Paul Construction Co Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,824.74",
          "BranchNumber": 7272,
          "SubmitDate": "10-11-2021",
          "ClientonJob": "Paul Construction Co Inc"
        },
        {
          "WorkOrder": 4172784,
          "ParentWorkOrder": "",
          "JobName": "Nothing Bundt Cakes",
          "JobNumber": "7348-134665-2",
          "FirstdayontheJob": "10-10-2021",
          "LastdayontheJob": "10-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2418 ESQUIRE DR",
          "JobAddress2": "STE 4",
          "JobFullAddress": "2418 ESQUIRE DR STE 4",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Ohio Valley Drywall",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,675.06",
          "BranchNumber": 7348,
          "SubmitDate": "10-11-2021",
          "ClientonJob": "Ohio Valley Drywall"
        },
        {
          "WorkOrder": 4172783,
          "ParentWorkOrder": "",
          "JobName": "Toledo Clinic",
          "JobNumber": "7302-104922-13",
          "FirstdayontheJob": "10-10-2021",
          "LastdayontheJob": "10-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1200 Medical Center Parkway",
          "JobAddress2": "",
          "JobFullAddress": "1200 Medical Center Parkway",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lathrop Company",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,563.90",
          "BranchNumber": 7302,
          "SubmitDate": "10-11-2021",
          "ClientonJob": "Lathrop Company"
        },
        {
          "WorkOrder": 4172782,
          "ParentWorkOrder": "",
          "JobName": "Hempstead Landing",
          "JobNumber": "7348-103743-2",
          "FirstdayontheJob": "10-10-2021",
          "LastdayontheJob": "10-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4024 Hempstead Station Drive",
          "JobAddress2": "",
          "JobFullAddress": "4024 Hempstead Station Drive",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "J&E Drywall",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,465.66",
          "BranchNumber": 7348,
          "SubmitDate": "10-11-2021",
          "ClientonJob": "J&E Drywall"
        },
        {
          "WorkOrder": 4172781,
          "ParentWorkOrder": "",
          "JobName": "New Life Chapel",
          "JobNumber": "7347-133810-30",
          "FirstdayontheJob": "10-10-2021",
          "LastdayontheJob": "10-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8655 Cincinnati Dayton Rd",
          "JobAddress2": "",
          "JobFullAddress": "8655 Cincinnati Dayton Rd",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "Benco Construction Servic",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,702.82",
          "BranchNumber": 7347,
          "SubmitDate": "10-11-2021",
          "ClientonJob": "Benco Construction Servic"
        },
        {
          "WorkOrder": 4172084,
          "ParentWorkOrder": "",
          "JobName": "Industry Detroit",
          "JobNumber": "7310-103579-2",
          "FirstdayontheJob": "10-07-2021",
          "LastdayontheJob": "10-07-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "950 Selden st",
          "JobAddress2": "",
          "JobFullAddress": "950 Selden st",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Beehive Stud Rockers LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$112,867.23",
          "BranchNumber": 7310,
          "SubmitDate": "10-08-2021",
          "ClientonJob": "Beehive Stud Rockers LLC"
        },
        {
          "WorkOrder": 4172083,
          "ParentWorkOrder": "",
          "JobName": "Mini Storage",
          "JobNumber": "7308-104546-64",
          "FirstdayontheJob": "10-07-2021",
          "LastdayontheJob": "10-07-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11135 64th Ave",
          "JobAddress2": "",
          "JobFullAddress": "11135 64th Ave",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,722.74",
          "BranchNumber": 7308,
          "SubmitDate": "10-08-2021",
          "ClientonJob": "SC Construction"
        },
        {
          "WorkOrder": 4172082,
          "ParentWorkOrder": "",
          "JobName": "Massage Luxe",
          "JobNumber": "7313-104703-10",
          "FirstdayontheJob": "10-07-2021",
          "LastdayontheJob": "10-07-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2125 S Telegraph Rd",
          "JobAddress2": "",
          "JobFullAddress": "2125 S Telegraph Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D&S Contractors Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,671.19",
          "BranchNumber": 7313,
          "SubmitDate": "10-08-2021",
          "ClientonJob": "D&S Contractors Inc"
        },
        {
          "WorkOrder": 4172081,
          "ParentWorkOrder": "",
          "JobName": "McDonald's #11236",
          "JobNumber": "7313-100963-112",
          "FirstdayontheJob": "10-07-2021",
          "LastdayontheJob": "10-07-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 Mack Ave",
          "JobAddress2": "",
          "JobFullAddress": "1000 Mack Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,458.27",
          "BranchNumber": 7313,
          "SubmitDate": "10-08-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4172080,
          "ParentWorkOrder": "",
          "JobName": "Ainsworth Building",
          "JobNumber": "7422-104499-12",
          "FirstdayontheJob": "10-07-2021",
          "LastdayontheJob": "10-07-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Woodiside Rd  Lot 18",
          "JobAddress2": "",
          "JobFullAddress": "Woodiside Rd  Lot 18",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Picklehaupt Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,314.65",
          "BranchNumber": 7422,
          "SubmitDate": "",
          "ClientonJob": "Picklehaupt Drywall"
        },
        {
          "WorkOrder": 4172079,
          "ParentWorkOrder": "",
          "JobName": "Crumble Cookie Livoina",
          "JobNumber": "7310-100963-113",
          "FirstdayontheJob": "10-07-2021",
          "LastdayontheJob": "10-07-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11019 Middlebelt rd",
          "JobAddress2": "",
          "JobFullAddress": "11019 Middlebelt rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,522.49",
          "BranchNumber": 7310,
          "SubmitDate": "10-08-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4171569,
          "ParentWorkOrder": 4136565,
          "JobName": "(NOF)20-5139 OU Wilson",
          "JobNumber": "502-920940-97",
          "FirstdayontheJob": "06-08-2021",
          "LastdayontheJob": "06-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "371 WILSON BLVD.",
          "JobAddress2": "",
          "JobFullAddress": "371 WILSON BLVD.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$330,000.00",
          "BalanceRemaining": "$3,747.47",
          "BranchNumber": 8,
          "SubmitDate": "10-06-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4170916,
          "ParentWorkOrder": 4151519,
          "JobName": "Lake Fenton Point Facade",
          "JobNumber": "7422-104019-54",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1545 N LEROY ST",
          "JobAddress2": "",
          "JobFullAddress": "1545 N LEROY ST",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,109.66",
          "BranchNumber": 7422,
          "SubmitDate": "10-05-2021",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4170913,
          "ParentWorkOrder": 4163658,
          "JobName": "Bath and Body Works",
          "JobNumber": "7304-104069-9",
          "FirstdayontheJob": "09-08-2021",
          "LastdayontheJob": "09-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9070 Highland Rd",
          "JobAddress2": "",
          "JobFullAddress": "9070 Highland Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Complete Acoustical",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,092.35",
          "BranchNumber": 7304,
          "SubmitDate": "10-05-2021",
          "ClientonJob": "Complete Acoustical"
        },
        {
          "WorkOrder": 4169622,
          "ParentWorkOrder": "",
          "JobName": "Community Living",
          "JobNumber": "7347-132722-2",
          "FirstdayontheJob": "09-29-2021",
          "LastdayontheJob": "09-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3200 vine street",
          "JobAddress2": "",
          "JobFullAddress": "3200 vine street",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Veterans Contracting Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,931.97",
          "BranchNumber": 7347,
          "SubmitDate": "09-30-2021",
          "ClientonJob": "Veterans Contracting Inc"
        },
        {
          "WorkOrder": 4169621,
          "ParentWorkOrder": "",
          "JobName": "HCF BG",
          "JobNumber": "7302-133878-9",
          "FirstdayontheJob": "09-29-2021",
          "LastdayontheJob": "09-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1021 W Poe Road",
          "JobAddress2": "",
          "JobFullAddress": "1021 W Poe Road",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "RCS Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,666.24",
          "BranchNumber": 7302,
          "SubmitDate": "09-30-2021",
          "ClientonJob": "RCS Construction"
        },
        {
          "WorkOrder": 4169620,
          "ParentWorkOrder": "",
          "JobName": "BrandSafway Solutions",
          "JobNumber": "7348-150178-2",
          "FirstdayontheJob": "09-29-2021",
          "LastdayontheJob": "09-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1978 Jackson Rd",
          "JobAddress2": "",
          "JobFullAddress": "1978 Jackson Rd",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "Norrenbrock Company",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$22,046.19",
          "BranchNumber": 7348,
          "SubmitDate": "09-30-2021",
          "ClientonJob": "Norrenbrock Company"
        },
        {
          "WorkOrder": 4169618,
          "ParentWorkOrder": "",
          "JobName": "McDonalds Reyonoldsburg 7888",
          "JobNumber": "7272-131575-11",
          "FirstdayontheJob": "09-29-2021",
          "LastdayontheJob": "09-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6350 Tussing Rd",
          "JobAddress2": "",
          "JobFullAddress": "6350 Tussing Rd",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "LIS Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,459.03",
          "BranchNumber": 7272,
          "SubmitDate": "09-30-2021",
          "ClientonJob": "LIS Construction"
        },
        {
          "WorkOrder": 4169319,
          "ParentWorkOrder": "",
          "JobName": "305 Properties",
          "JobNumber": "7313-102839-24",
          "FirstdayontheJob": "09-28-2021",
          "LastdayontheJob": "09-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3050 Crandall Parkway",
          "JobAddress2": "",
          "JobFullAddress": "3050 Crandall Parkway",
          "JobCounty": "VAN BUREN",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lee Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$80,009.85",
          "BranchNumber": 7313,
          "SubmitDate": "09-29-2021",
          "ClientonJob": "Lee Contracting"
        },
        {
          "WorkOrder": 4169318,
          "ParentWorkOrder": "",
          "JobName": "U Of M University Towers",
          "JobNumber": "7310-101038-42",
          "FirstdayontheJob": "09-28-2021",
          "LastdayontheJob": "09-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "324 S Saginaw St",
          "JobAddress2": "",
          "JobFullAddress": "324 S Saginaw St",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,078.66",
          "BranchNumber": 7310,
          "SubmitDate": "09-29-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4169317,
          "ParentWorkOrder": "",
          "JobName": "McLaren Port Huron mob",
          "JobNumber": "7310-101038-43",
          "FirstdayontheJob": "09-28-2021",
          "LastdayontheJob": "09-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1221 Pine Grove Ave",
          "JobAddress2": "",
          "JobFullAddress": "1221 Pine Grove Ave",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,742.89",
          "BranchNumber": 7310,
          "SubmitDate": "09-29-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4169316,
          "ParentWorkOrder": "",
          "JobName": "Mini Storage",
          "JobNumber": "7308-104546-64",
          "FirstdayontheJob": "09-28-2021",
          "LastdayontheJob": "09-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11135 W 64th Ave",
          "JobAddress2": "",
          "JobFullAddress": "11135 W 64th Ave",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,722.74",
          "BranchNumber": 7308,
          "SubmitDate": "09-29-2021",
          "ClientonJob": "SC Construction"
        },
        {
          "WorkOrder": 4169315,
          "ParentWorkOrder": "",
          "JobName": "Kroger Midland",
          "JobNumber": "7313-101358-14",
          "FirstdayontheJob": "09-28-2021",
          "LastdayontheJob": "09-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2808 Ashman Street",
          "JobAddress2": "",
          "JobFullAddress": "2808 Ashman Street",
          "JobCounty": "Midland",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC Construction Co Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,067.89",
          "BranchNumber": 7313,
          "SubmitDate": "09-29-2021",
          "ClientonJob": "AC Construction Co Inc"
        },
        {
          "WorkOrder": 4169313,
          "ParentWorkOrder": "",
          "JobName": "HASTINGS AVE JOB",
          "JobNumber": "7308-104198-40",
          "FirstdayontheJob": "09-28-2021",
          "LastdayontheJob": "09-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "667 HASTINGS AVE",
          "JobAddress2": "",
          "JobFullAddress": "667 HASTINGS AVE",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,983.28",
          "BranchNumber": 7308,
          "SubmitDate": "09-29-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4169312,
          "ParentWorkOrder": "",
          "JobName": "GREAT LAKES",
          "JobNumber": "7308-104198-38",
          "FirstdayontheJob": "09-28-2021",
          "LastdayontheJob": "09-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3040 IVANREST AVE SW",
          "JobAddress2": "",
          "JobFullAddress": "3040 IVANREST AVE SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,939.16",
          "BranchNumber": 7308,
          "SubmitDate": "09-29-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4169311,
          "ParentWorkOrder": "",
          "JobName": "Zeeland Wrhse/Tornado Shltr",
          "JobNumber": "7304-104110-9",
          "FirstdayontheJob": "09-28-2021",
          "LastdayontheJob": "09-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "425 N Fairview",
          "JobAddress2": "Use North Gate",
          "JobFullAddress": "425 N Fairview Use North Gate",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Pumford Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,293.77",
          "BranchNumber": 7304,
          "SubmitDate": "09-29-2021",
          "ClientonJob": "Pumford Construction Inc"
        },
        {
          "WorkOrder": 4168840,
          "ParentWorkOrder": 4163922,
          "JobName": "Riverside Electric",
          "JobNumber": "7270-102652-11",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "680B Redna Terrace",
          "JobAddress2": "",
          "JobFullAddress": "680B Redna Terrace",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Kiesland Development Srvcs LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,770.03",
          "BranchNumber": 7270,
          "SubmitDate": "09-28-2021",
          "ClientonJob": "Kiesland Development Srvcs LLC"
        },
        {
          "WorkOrder": 4168248,
          "ParentWorkOrder": "",
          "JobName": "SAFStor - Mentor",
          "JobNumber": 133708,
          "FirstdayontheJob": "06-14-2021",
          "LastdayontheJob": "06-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1980 Mentor Ave",
          "JobAddress2": "",
          "JobFullAddress": "1980 Mentor Ave",
          "JobCounty": "Lake",
          "JobState": "Ohio",
          "CustomerCompanyName": "Space City Construction, Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "N/A",
          "BranchNumber": 1,
          "SubmitDate": "09-27-2021",
          "ClientonJob": "Space City Construction, Inc"
        },
        {
          "WorkOrder": 4167492,
          "ParentWorkOrder": "",
          "JobName": "Avril Lofts",
          "JobNumber": "7270-132715-13",
          "FirstdayontheJob": "09-22-2021",
          "LastdayontheJob": "09-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31 E Court St",
          "JobAddress2": "",
          "JobFullAddress": "31 E Court St",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lemcer Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$18,155.32",
          "BranchNumber": 7270,
          "SubmitDate": "09-23-2021",
          "ClientonJob": "Lemcer Construction LLC"
        },
        {
          "WorkOrder": 4167491,
          "ParentWorkOrder": "",
          "JobName": "Njp Bethel Church",
          "JobNumber": "7302-104314-3",
          "FirstdayontheJob": "09-22-2021",
          "LastdayontheJob": "09-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2920 S State Route 590",
          "JobAddress2": "",
          "JobFullAddress": "2920 S State Route 590",
          "JobCounty": "Ottawa",
          "JobState": "Ohio",
          "CustomerCompanyName": "KDH Const LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,615.86",
          "BranchNumber": 7302,
          "SubmitDate": "09-23-2021",
          "ClientonJob": "KDH Const LLC"
        },
        {
          "WorkOrder": 4167490,
          "ParentWorkOrder": "",
          "JobName": "Taco Bell",
          "JobNumber": "7302-100963-105",
          "FirstdayontheJob": "09-22-2021",
          "LastdayontheJob": "09-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "137 East Main Street",
          "JobAddress2": "",
          "JobFullAddress": "137 East Main Street",
          "JobCounty": "LOGAN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,832.76",
          "BranchNumber": 7302,
          "SubmitDate": "09-23-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4167364,
          "ParentWorkOrder": "",
          "JobName": "Publix",
          "JobNumber": "103365/2",
          "FirstdayontheJob": "",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1221 South Water Ave",
          "JobAddress2": "",
          "JobFullAddress": "1221 South Water Ave",
          "JobCounty": "SUMNER",
          "JobState": "Tennessee",
          "CustomerCompanyName": "Stover & Sons Contractors",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$60,146.72",
          "BranchNumber": 7342,
          "SubmitDate": "09-23-2021",
          "ClientonJob": "Stover & Sons Contractors"
        },
        {
          "WorkOrder": 4167339,
          "ParentWorkOrder": 4165057,
          "JobName": "FKC Chippewa Hills",
          "JobNumber": "7304-104138-3",
          "FirstdayontheJob": "09-14-2021",
          "LastdayontheJob": "09-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1523 S Mission",
          "JobAddress2": "",
          "JobFullAddress": "1523 S Mission",
          "JobCounty": "ISABELLA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Wolgast Corporation",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,283.22",
          "BranchNumber": 7304,
          "SubmitDate": "09-23-2021",
          "ClientonJob": "Wolgast Corporation"
        },
        {
          "WorkOrder": 4167142,
          "ParentWorkOrder": "",
          "JobName": "Prestige Commons",
          "JobNumber": "7313-104357-14",
          "FirstdayontheJob": "09-21-2021",
          "LastdayontheJob": "09-21-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33503 23 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "33503 23 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Int Const",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$124,614.10",
          "BranchNumber": 7313,
          "SubmitDate": "09-22-2021",
          "ClientonJob": "Commercial Int Const"
        },
        {
          "WorkOrder": 4167141,
          "ParentWorkOrder": "",
          "JobName": "Rochester Hills Surgery Center",
          "JobNumber": "7313-104450-32",
          "FirstdayontheJob": "09-21-2021",
          "LastdayontheJob": "09-21-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1401 E South Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1401 E South Blvd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Hudson Interiors",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$100,109.31",
          "BranchNumber": 7313,
          "SubmitDate": "09-22-2021",
          "ClientonJob": "Hudson Interiors"
        },
        {
          "WorkOrder": 4167140,
          "ParentWorkOrder": "",
          "JobName": "Pine Rest",
          "JobNumber": "7308-104326-26",
          "FirstdayontheJob": "09-21-2021",
          "LastdayontheJob": "09-21-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6500 Byron Center Ave SW",
          "JobAddress2": "",
          "JobFullAddress": "6500 Byron Center Ave SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Halyard",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$28,825.24",
          "BranchNumber": 7308,
          "SubmitDate": "09-22-2021",
          "ClientonJob": "Halyard"
        },
        {
          "WorkOrder": 4167139,
          "ParentWorkOrder": "",
          "JobName": "Yankee Air Museum",
          "JobNumber": "7313-104257-4",
          "FirstdayontheJob": "09-21-2021",
          "LastdayontheJob": "09-21-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "48075 Tyler rd",
          "JobAddress2": "",
          "JobFullAddress": "48075 Tyler rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Castle Commercial Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$27,135.32",
          "BranchNumber": 7313,
          "SubmitDate": "09-22-2021",
          "ClientonJob": "Castle Commercial Carp"
        },
        {
          "WorkOrder": 4167138,
          "ParentWorkOrder": "",
          "JobName": "Tribar Manufacturing LLC",
          "JobNumber": "7422-105170-16",
          "FirstdayontheJob": "09-21-2021",
          "LastdayontheJob": "09-21-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2211 Grand Commerce Dr",
          "JobAddress2": "",
          "JobFullAddress": "2211 Grand Commerce Dr",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Industrial Tech Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$22,039.93",
          "BranchNumber": 7422,
          "SubmitDate": "09-22-2021",
          "ClientonJob": "Industrial Tech Services"
        },
        {
          "WorkOrder": 4167137,
          "ParentWorkOrder": "",
          "JobName": "Birmingham Ice Arena",
          "JobNumber": "7310-137388-14",
          "FirstdayontheJob": "09-21-2021",
          "LastdayontheJob": "09-21-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2300 E Lincoln St",
          "JobAddress2": "",
          "JobFullAddress": "2300 E Lincoln St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Patriot Team Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,321.87",
          "BranchNumber": 7310,
          "SubmitDate": "09-22-2021",
          "ClientonJob": "Patriot Team Inc"
        },
        {
          "WorkOrder": 4166377,
          "ParentWorkOrder": 4165537,
          "JobName": "Springfield Farm Credit Svcs.",
          "JobNumber": "7348-133810-34",
          "FirstdayontheJob": "09-15-2021",
          "LastdayontheJob": "09-15-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2241 Troy Rd",
          "JobAddress2": "",
          "JobFullAddress": "2241 Troy Rd",
          "JobCounty": "CLARK",
          "JobState": "Ohio",
          "CustomerCompanyName": "Benco Construction Service",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,014.63",
          "BranchNumber": 7348,
          "SubmitDate": "09-20-2021",
          "ClientonJob": "Benco Construction Service"
        },
        {
          "WorkOrder": 4166300,
          "ParentWorkOrder": 4165537,
          "JobName": "Springfield Farm Credit Svcs.",
          "JobNumber": "7348-133810-34",
          "FirstdayontheJob": "09-15-2021",
          "LastdayontheJob": "09-15-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2241 Troy Rd",
          "JobAddress2": "",
          "JobFullAddress": "2241 Troy Rd",
          "JobCounty": "CLARK",
          "JobState": "Ohio",
          "CustomerCompanyName": "Benco Construction Service",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,014.63",
          "BranchNumber": 7348,
          "SubmitDate": "09-20-2021",
          "ClientonJob": "Benco Construction Service"
        },
        {
          "WorkOrder": 4165788,
          "ParentWorkOrder": "",
          "JobName": "Grizzlies Restaurant at the Airport",
          "JobNumber": 132814,
          "FirstdayontheJob": "",
          "LastdayontheJob": "07-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2491 WINCHESTER RD",
          "JobAddress2": "SPACE 2-CK 115A",
          "JobFullAddress": "2491 WINCHESTER RD SPACE 2-CK 115A",
          "JobCounty": "Shelby",
          "JobState": "Tennessee",
          "CustomerCompanyName": "High Caliber Const. LLC",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Olivia Quinones",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,157.70",
          "BranchNumber": 7265,
          "SubmitDate": "09-17-2021",
          "ClientonJob": "High Caliber Const. LLC"
        },
        {
          "WorkOrder": 4165541,
          "ParentWorkOrder": "",
          "JobName": "Vietnamese Restuarant",
          "JobNumber": "7348-132755-46",
          "FirstdayontheJob": "09-15-2021",
          "LastdayontheJob": "09-15-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5466 Dixie Highway",
          "JobAddress2": "",
          "JobFullAddress": "5466 Dixie Highway",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "T Square Wall Systems LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,534.27",
          "BranchNumber": 7348,
          "SubmitDate": "09-16-2021",
          "ClientonJob": "T Square Wall Systems LLC"
        },
        {
          "WorkOrder": 4165540,
          "ParentWorkOrder": "",
          "JobName": "Monrtgomery Qluarter 1 FH",
          "JobNumber": "7347-133958-9",
          "FirstdayontheJob": "09-15-2021",
          "LastdayontheJob": "09-15-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "240A HARMON AVE",
          "JobAddress2": "",
          "JobFullAddress": "240A HARMON AVE",
          "JobCounty": "Warren",
          "JobState": "Ohio",
          "CustomerCompanyName": "Northside Supply",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$58,249.56",
          "BranchNumber": 7347,
          "SubmitDate": "09-16-2021",
          "ClientonJob": "Northside Supply"
        },
        {
          "WorkOrder": 4165539,
          "ParentWorkOrder": "",
          "JobName": "Anthem - East Walnut Hills",
          "JobNumber": "7347-100959-19",
          "FirstdayontheJob": "09-15-2021",
          "LastdayontheJob": "09-15-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1351 William Howard Taft Road",
          "JobAddress2": "B2-3 PR",
          "JobFullAddress": "1351 William Howard Taft Road B2-3 PR",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Morningside Group Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$24,891.05",
          "BranchNumber": 7347,
          "SubmitDate": "09-16-2021",
          "ClientonJob": "Morningside Group Inc"
        },
        {
          "WorkOrder": 4165538,
          "ParentWorkOrder": "",
          "JobName": "Colleg Hill Station",
          "JobNumber": "7347-100959-20",
          "FirstdayontheJob": "09-15-2021",
          "LastdayontheJob": "09-15-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6211/6200 Hamilton Ave",
          "JobAddress2": "",
          "JobFullAddress": "6211/6200 Hamilton Ave",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "Morningside Group Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$20,337.48",
          "BranchNumber": 7347,
          "SubmitDate": "09-16-2021",
          "ClientonJob": "Morningside Group Inc"
        },
        {
          "WorkOrder": 4165537,
          "ParentWorkOrder": "",
          "JobName": "Springfield Farm Credit Svcs.",
          "JobNumber": "7348-133810-34",
          "FirstdayontheJob": "09-15-2021",
          "LastdayontheJob": "09-15-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2241 Troy Rd",
          "JobAddress2": "",
          "JobFullAddress": "2241 Troy Rd",
          "JobCounty": "CLARK",
          "JobState": "Ohio",
          "CustomerCompanyName": "Benco Construction Service",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,014.63",
          "BranchNumber": 7348,
          "SubmitDate": "09-16-2021",
          "ClientonJob": "Benco Construction Service"
        },
        {
          "WorkOrder": 4165433,
          "ParentWorkOrder": "",
          "JobName": "SANITAS",
          "JobNumber": "132814-6",
          "FirstdayontheJob": "",
          "LastdayontheJob": "06-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1200 E Raines Rd",
          "JobAddress2": "",
          "JobFullAddress": "1200 E Raines Rd",
          "JobCounty": "Shelby",
          "JobState": "Tennessee",
          "CustomerCompanyName": "High Caliber Const LLC",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Olivia Quinones",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$48,014.65",
          "BranchNumber": 7265,
          "SubmitDate": "09-16-2021",
          "ClientonJob": "High Caliber Const LLC"
        },
        {
          "WorkOrder": 4165062,
          "ParentWorkOrder": "",
          "JobName": "Njp Whole Foods Market",
          "JobNumber": "7308-104198-27",
          "FirstdayontheJob": "09-14-2021",
          "LastdayontheJob": "09-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2891 Radcliff Ave SE",
          "JobAddress2": "",
          "JobFullAddress": "2891 Radcliff Ave SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$53,586.56",
          "BranchNumber": 7308,
          "SubmitDate": "09-15-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4165061,
          "ParentWorkOrder": "",
          "JobName": "Nof 21 5137 Wsu Gateway",
          "JobNumber": "7313-104657-33",
          "FirstdayontheJob": "09-14-2021",
          "LastdayontheJob": "09-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4743 Cass Ave",
          "JobAddress2": "",
          "JobFullAddress": "4743 Cass Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$31,877.61",
          "BranchNumber": 7313,
          "SubmitDate": "09-15-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4165060,
          "ParentWorkOrder": "",
          "JobName": "Njp Apex Grand Haven",
          "JobNumber": "7308-104198-28",
          "FirstdayontheJob": "09-14-2021",
          "LastdayontheJob": "09-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "APEX - GRAND HAVEN",
          "JobAddress2": "",
          "JobFullAddress": "APEX - GRAND HAVEN",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,503.99",
          "BranchNumber": 7308,
          "SubmitDate": "",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4165059,
          "ParentWorkOrder": "",
          "JobName": "WSU Gateway Theater",
          "JobNumber": "7313-104657-50",
          "FirstdayontheJob": "09-14-2021",
          "LastdayontheJob": "09-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4715 Cass Ave",
          "JobAddress2": "",
          "JobFullAddress": "4715 Cass Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,096.35",
          "BranchNumber": 7313,
          "SubmitDate": "09-15-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4165058,
          "ParentWorkOrder": "",
          "JobName": "Beaumont Hospital NEXCORE",
          "JobNumber": "7310-137349-4",
          "FirstdayontheJob": "09-14-2021",
          "LastdayontheJob": "09-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "39000 7 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "39000 7 Mile Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Custom Architectural",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,923.71",
          "BranchNumber": 7310,
          "SubmitDate": "09-15-2021",
          "ClientonJob": "Custom Architectural"
        },
        {
          "WorkOrder": 4165057,
          "ParentWorkOrder": "",
          "JobName": "FKC Chippewa Hills",
          "JobNumber": "7304-104138-3",
          "FirstdayontheJob": "09-14-2021",
          "LastdayontheJob": "09-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1523 S Mission",
          "JobAddress2": "",
          "JobFullAddress": "1523 S Mission",
          "JobCounty": "ISABELLA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Wolgast Corporation",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,283.22",
          "BranchNumber": 7304,
          "SubmitDate": "09-15-2021",
          "ClientonJob": "Wolgast Corporation"
        },
        {
          "WorkOrder": 4165056,
          "ParentWorkOrder": "",
          "JobName": "Njp Leos Coney Island",
          "JobNumber": "7304-104290-7",
          "FirstdayontheJob": "09-14-2021",
          "LastdayontheJob": "09-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5212 Bay Rd",
          "JobAddress2": "",
          "JobFullAddress": "5212 Bay Rd",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Metro General Contractor Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,946.94",
          "BranchNumber": 7304,
          "SubmitDate": "09-15-2021",
          "ClientonJob": "Metro General Contractor Inc"
        },
        {
          "WorkOrder": 4165055,
          "ParentWorkOrder": "",
          "JobName": "Frankenmuth Uptown Ste 1",
          "JobNumber": "7304-104092-9",
          "FirstdayontheJob": "09-14-2021",
          "LastdayontheJob": "09-14-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "975 N Main St",
          "JobAddress2": "07-21003",
          "JobFullAddress": "975 N Main St 07-21003",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Serenus Johnson",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,688.27",
          "BranchNumber": 7304,
          "SubmitDate": "09-15-2021",
          "ClientonJob": "Serenus Johnson"
        },
        {
          "WorkOrder": 4164763,
          "ParentWorkOrder": 4139378,
          "JobName": "Njp Beacon Hill",
          "JobNumber": "7308-104848-13",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1919 SE BOSTON ST",
          "JobAddress2": "",
          "JobFullAddress": "1919 SE BOSTON ST",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Rudys Plastering And Drywall Finish",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$19,821.39",
          "BranchNumber": 7308,
          "SubmitDate": "09-14-2021",
          "ClientonJob": "Rudys Plastering And Drywall Finish"
        },
        {
          "WorkOrder": 4164358,
          "ParentWorkOrder": 4159703,
          "JobName": "Avalon  Hilltop Apartments",
          "JobNumber": "7313-104675-5",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7651 Dan Hoey Rd",
          "JobAddress2": "",
          "JobFullAddress": "7651 Dan Hoey Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Stony Creek Serv",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$15,806.73",
          "BranchNumber": 7313,
          "SubmitDate": "09-13-2021",
          "ClientonJob": "Stony Creek Serv"
        },
        {
          "WorkOrder": 4163929,
          "ParentWorkOrder": "",
          "JobName": "Manhattan Project",
          "JobNumber": "7348-135976-2",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "601 E 3rd St",
          "JobAddress2": "",
          "JobFullAddress": "601 E 3rd St",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Woodard Development",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,265.11",
          "BranchNumber": 7348,
          "SubmitDate": "09-10-2021",
          "ClientonJob": "Woodard Development"
        },
        {
          "WorkOrder": 4163928,
          "ParentWorkOrder": "",
          "JobName": "AMAZON",
          "JobNumber": "7272-101849-61",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6322 COLLINS DR",
          "JobAddress2": "Ste B",
          "JobFullAddress": "6322 COLLINS DR Ste B",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Vision Contractors Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$48,581.94",
          "BranchNumber": 7272,
          "SubmitDate": "09-10-2021",
          "ClientonJob": "Vision Contractors Inc"
        },
        {
          "WorkOrder": 4163927,
          "ParentWorkOrder": "",
          "JobName": "Kroger #511",
          "JobNumber": "7302-101545-6",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4925 Jackman Rd",
          "JobAddress2": "",
          "JobFullAddress": "4925 Jackman Rd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "The Dotson Company Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$18,238.60",
          "BranchNumber": 7302,
          "SubmitDate": "09-10-2021",
          "ClientonJob": "The Dotson Company Inc"
        },
        {
          "WorkOrder": 4163926,
          "ParentWorkOrder": "",
          "JobName": "Beacon Ortho",
          "JobNumber": "7270-132755-31",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9100 Centre Pointe Dr",
          "JobAddress2": "",
          "JobFullAddress": "9100 Centre Pointe Dr",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "T Square Wall Systems LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,964.27",
          "BranchNumber": 7270,
          "SubmitDate": "09-10-2021",
          "ClientonJob": "T Square Wall Systems LLC"
        },
        {
          "WorkOrder": 4163925,
          "ParentWorkOrder": "",
          "JobName": "Howard Hanna",
          "JobNumber": "7302-133867-5",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1677 Lance Pointe Rd",
          "JobAddress2": "",
          "JobFullAddress": "1677 Lance Pointe Rd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Sunforest Building Company",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,245.44",
          "BranchNumber": 7302,
          "SubmitDate": "09-10-2021",
          "ClientonJob": "Sunforest Building Company"
        },
        {
          "WorkOrder": 4163924,
          "ParentWorkOrder": "",
          "JobName": "Cornerstone Apartments",
          "JobNumber": "7348-150148-2",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4950 Cornerstone Blvd",
          "JobAddress2": "4th floor",
          "JobFullAddress": "4950 Cornerstone Blvd 4th floor",
          "JobCounty": "Greene",
          "JobState": "Ohio",
          "CustomerCompanyName": "National CB LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,433.25",
          "BranchNumber": 7348,
          "SubmitDate": "09-10-2021",
          "ClientonJob": "National CB LLC"
        },
        {
          "WorkOrder": 4163923,
          "ParentWorkOrder": "",
          "JobName": "Sun Treatment",
          "JobNumber": "7270-102652-10",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1421 Parker Ct",
          "JobAddress2": "",
          "JobFullAddress": "1421 Parker Ct",
          "JobCounty": "Clark",
          "JobState": "Ohio",
          "CustomerCompanyName": "Kiesland Development Srvcs LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,559.35",
          "BranchNumber": 7270,
          "SubmitDate": "09-10-2021",
          "ClientonJob": "Kiesland Development Srvcs LLC"
        },
        {
          "WorkOrder": 4163922,
          "ParentWorkOrder": "",
          "JobName": "Riverside Electric",
          "JobNumber": "7270-102652-11",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "680B Redna Terrace",
          "JobAddress2": "",
          "JobFullAddress": "680B Redna Terrace",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Kiesland Development Srvcs LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,770.03",
          "BranchNumber": 7270,
          "SubmitDate": "09-10-2021",
          "ClientonJob": "Kiesland Development Srvcs LLC"
        },
        {
          "WorkOrder": 4163921,
          "ParentWorkOrder": "",
          "JobName": "1400 N. Summit St.",
          "JobNumber": "7302-101113-20",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1400 N Summit St",
          "JobAddress2": "1st floor delivery",
          "JobFullAddress": "1400 N Summit St 1st floor delivery",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,195.27",
          "BranchNumber": 7302,
          "SubmitDate": "09-10-2021",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4163920,
          "ParentWorkOrder": "",
          "JobName": "Walgreens",
          "JobNumber": "7302-137373-16",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10003 Fremont Pike",
          "JobAddress2": "",
          "JobFullAddress": "10003 Fremont Pike",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,039.65",
          "BranchNumber": 7302,
          "SubmitDate": "09-10-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4163919,
          "ParentWorkOrder": "",
          "JobName": "Obersons Nursery",
          "JobNumber": "7270-132867-2",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2362 Hamilton leves Rd.",
          "JobAddress2": "",
          "JobFullAddress": "2362 Hamilton leves Rd.",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "David Brunemann Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,963.19",
          "BranchNumber": 7270,
          "SubmitDate": "",
          "ClientonJob": "David Brunemann Construction"
        },
        {
          "WorkOrder": 4163918,
          "ParentWorkOrder": "",
          "JobName": "NOF Dayton Va TCUICU",
          "JobNumber": "7348-132748-2",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4100 W 3rd St",
          "JobAddress2": "",
          "JobFullAddress": "4100 W 3rd St",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "Combs Interior Specialties Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,021.51",
          "BranchNumber": 7348,
          "SubmitDate": "09-10-2021",
          "ClientonJob": "Combs Interior Specialties Inc"
        },
        {
          "WorkOrder": 4163917,
          "ParentWorkOrder": "",
          "JobName": "Sunrise",
          "JobNumber": "7302-104884-25",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3000 West Bucyrus St",
          "JobAddress2": "",
          "JobFullAddress": "3000 West Bucyrus St",
          "JobCounty": "CRAWFORD",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$22,065.83",
          "BranchNumber": 7302,
          "SubmitDate": "09-10-2021",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 4163916,
          "ParentWorkOrder": "",
          "JobName": "Mercer Landmark",
          "JobNumber": "7302-104884-26",
          "FirstdayontheJob": "09-09-2021",
          "LastdayontheJob": "09-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14170 State Route 613",
          "JobAddress2": "",
          "JobFullAddress": "14170 State Route 613",
          "JobCounty": "PAULDING",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$21,971.85",
          "BranchNumber": 7302,
          "SubmitDate": "09-10-2021",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 4163663,
          "ParentWorkOrder": "",
          "JobName": "Njp Whole Foods Market",
          "JobNumber": "7308-104198-27",
          "FirstdayontheJob": "09-08-2021",
          "LastdayontheJob": "09-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2891 RADCLIFF AVE SE",
          "JobAddress2": "",
          "JobFullAddress": "2891 RADCLIFF AVE SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$53,721.71",
          "BranchNumber": 7308,
          "SubmitDate": "09-09-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4163662,
          "ParentWorkOrder": "",
          "JobName": "Njp Apex Grand Haven",
          "JobNumber": "7308-104198-28",
          "FirstdayontheJob": "09-08-2021",
          "LastdayontheJob": "09-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "224-226 Washington Avenue",
          "JobAddress2": "",
          "JobFullAddress": "224-226 Washington Avenue",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,503.99",
          "BranchNumber": 7308,
          "SubmitDate": "09-09-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4163661,
          "ParentWorkOrder": "",
          "JobName": "Beaumont Hospital NEXCORE",
          "JobNumber": "7310-137349-4",
          "FirstdayontheJob": "09-08-2021",
          "LastdayontheJob": "09-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "39000 7 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "39000 7 Mile Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Custom Architectural",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,923.71",
          "BranchNumber": 7310,
          "SubmitDate": "09-09-2021",
          "ClientonJob": "Custom Architectural"
        },
        {
          "WorkOrder": 4163660,
          "ParentWorkOrder": "",
          "JobName": "Taco Bell Bad Axe",
          "JobNumber": "7313-100963-106",
          "FirstdayontheJob": "09-08-2021",
          "LastdayontheJob": "09-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "852 N VanDyke Rd",
          "JobAddress2": "",
          "JobFullAddress": "852 N VanDyke Rd",
          "JobCounty": "Huron",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,918.73",
          "BranchNumber": 7313,
          "SubmitDate": "09-09-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4163659,
          "ParentWorkOrder": "",
          "JobName": "Harper",
          "JobNumber": "7313-104837-3",
          "FirstdayontheJob": "09-08-2021",
          "LastdayontheJob": "09-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3990 John R ST",
          "JobAddress2": "",
          "JobFullAddress": "3990 John R ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Detroit Medical Center",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,291.40",
          "BranchNumber": 7313,
          "SubmitDate": "09-09-2021",
          "ClientonJob": "Detroit Medical Center"
        },
        {
          "WorkOrder": 4163658,
          "ParentWorkOrder": "",
          "JobName": "Bath and Body Works",
          "JobNumber": "7304-104069-9",
          "FirstdayontheJob": "09-08-2021",
          "LastdayontheJob": "09-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9070 Highland Rd",
          "JobAddress2": "",
          "JobFullAddress": "9070 Highland Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Complete Acoustical",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,092.35",
          "BranchNumber": 7304,
          "SubmitDate": "09-09-2021",
          "ClientonJob": "Complete Acoustical"
        },
        {
          "WorkOrder": 4163656,
          "ParentWorkOrder": "",
          "JobName": "Rigaku",
          "JobNumber": "7313-104400-12",
          "FirstdayontheJob": "09-08-2021",
          "LastdayontheJob": "09-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1900 Taylor Rd",
          "JobAddress2": "",
          "JobFullAddress": "1900 Taylor Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Parallel Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,727.43",
          "BranchNumber": 7313,
          "SubmitDate": "09-09-2021",
          "ClientonJob": "Parallel Construction"
        },
        {
          "WorkOrder": 4163655,
          "ParentWorkOrder": "",
          "JobName": "Lenscrafters",
          "JobNumber": "7313-104357-13",
          "FirstdayontheJob": "09-08-2021",
          "LastdayontheJob": "09-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19329 Mack Ave",
          "JobAddress2": "",
          "JobFullAddress": "19329 Mack Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Int Const",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,827.68",
          "BranchNumber": 7313,
          "SubmitDate": "09-09-2021",
          "ClientonJob": "Commercial Int Const"
        },
        {
          "WorkOrder": 4163654,
          "ParentWorkOrder": "",
          "JobName": "Vassar Job",
          "JobNumber": "7304-104276-9",
          "FirstdayontheJob": "09-08-2021",
          "LastdayontheJob": "09-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50 Enterprise Drive",
          "JobAddress2": "",
          "JobFullAddress": "50 Enterprise Drive",
          "JobCounty": "TUSCOLA",
          "JobState": "Michigan",
          "CustomerCompanyName": "The Drywall Guys Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,070.52",
          "BranchNumber": 7304,
          "SubmitDate": "09-09-2021",
          "ClientonJob": "The Drywall Guys Inc"
        },
        {
          "WorkOrder": 4163274,
          "ParentWorkOrder": 4159685,
          "JobName": "Whetstone High School Ceiling",
          "JobNumber": "7272-132736-8",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4405 Scenic Dr",
          "JobAddress2": "",
          "JobFullAddress": "4405 Scenic Dr",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Procon Professional Const",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,752.82",
          "BranchNumber": 7272,
          "SubmitDate": "09-08-2021",
          "ClientonJob": "Procon Professional Const"
        },
        {
          "WorkOrder": 4163212,
          "ParentWorkOrder": 4154382,
          "JobName": "Mosaic at Levis Commons",
          "JobNumber": "7302-103182-4",
          "FirstdayontheJob": "08-08-2021",
          "LastdayontheJob": "08-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 Hollister LN",
          "JobAddress2": "",
          "JobFullAddress": "1000 Hollister LN",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "Carter Painting Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$18,703.77",
          "BranchNumber": 7302,
          "SubmitDate": "09-08-2021",
          "ClientonJob": "Carter Painting Inc"
        },
        {
          "WorkOrder": 4161615,
          "ParentWorkOrder": "",
          "JobName": "Beacon Hill",
          "JobNumber": "7313-104217-8",
          "FirstdayontheJob": "09-01-2021",
          "LastdayontheJob": "09-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1845 Boston St SE",
          "JobAddress2": "",
          "JobFullAddress": "1845 Boston St SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Sobie Company Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$19,596.32",
          "BranchNumber": 7313,
          "SubmitDate": "09-02-2021",
          "ClientonJob": "Sobie Company Inc"
        },
        {
          "WorkOrder": 4161614,
          "ParentWorkOrder": "",
          "JobName": "Njp Continental",
          "JobNumber": "7313-102839-10",
          "FirstdayontheJob": "09-01-2021",
          "LastdayontheJob": "09-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Continental Dr",
          "JobAddress2": "",
          "JobFullAddress": "1 Continental Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lee Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,653.25",
          "BranchNumber": 7313,
          "SubmitDate": "09-02-2021",
          "ClientonJob": "Lee Contracting"
        },
        {
          "WorkOrder": 4161613,
          "ParentWorkOrder": "",
          "JobName": "New Life Church",
          "JobNumber": "7313-100963-103",
          "FirstdayontheJob": "09-01-2021",
          "LastdayontheJob": "09-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7235 Torrey Rd",
          "JobAddress2": "",
          "JobFullAddress": "7235 Torrey Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,532.18",
          "BranchNumber": 7313,
          "SubmitDate": "09-02-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4161611,
          "ParentWorkOrder": "",
          "JobName": "Pure Roots- Harrison Twsp.",
          "JobNumber": "7313-102839-23",
          "FirstdayontheJob": "09-01-2021",
          "LastdayontheJob": "09-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25371 Joy Blvd",
          "JobAddress2": "",
          "JobFullAddress": "25371 Joy Blvd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lee Contracting",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,738.80",
          "BranchNumber": 7313,
          "SubmitDate": "09-02-2021",
          "ClientonJob": "Lee Contracting"
        },
        {
          "WorkOrder": 4161609,
          "ParentWorkOrder": "",
          "JobName": "Woods",
          "JobNumber": "7313-104546-62",
          "FirstdayontheJob": "09-01-2021",
          "LastdayontheJob": "09-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "68050 Hillside Ln",
          "JobAddress2": "",
          "JobFullAddress": "68050 Hillside Ln",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,483.61",
          "BranchNumber": 7313,
          "SubmitDate": "09-02-2021",
          "ClientonJob": "SC Construction"
        },
        {
          "WorkOrder": 4161608,
          "ParentWorkOrder": "",
          "JobName": "Monroe Byler",
          "JobNumber": "7304-104269-7",
          "FirstdayontheJob": "09-01-2021",
          "LastdayontheJob": "09-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5906 Hadley rd",
          "JobAddress2": "",
          "JobFullAddress": "5906 Hadley rd",
          "JobCounty": "SANILAC",
          "JobState": "Michigan",
          "CustomerCompanyName": "D&D Quality Roofing",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,151.62",
          "BranchNumber": 7304,
          "SubmitDate": "09-02-2021",
          "ClientonJob": "D&D Quality Roofing"
        },
        {
          "WorkOrder": 4161607,
          "ParentWorkOrder": "",
          "JobName": "Sun Communities",
          "JobNumber": "7313-137383-30",
          "FirstdayontheJob": "09-01-2021",
          "LastdayontheJob": "09-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27335 W 11 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "27335 W 11 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Distinctive Grading & Design LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,059.63",
          "BranchNumber": 7313,
          "SubmitDate": "09-02-2021",
          "ClientonJob": "Distinctive Grading & Design LLC"
        },
        {
          "WorkOrder": 4161603,
          "ParentWorkOrder": "",
          "JobName": "AHS Etna LLC",
          "JobNumber": "7272-132740-8",
          "FirstdayontheJob": "09-01-2021",
          "LastdayontheJob": "09-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "70 Global Parkway",
          "JobAddress2": "",
          "JobFullAddress": "70 Global Parkway",
          "JobCounty": "LICKING",
          "JobState": "Ohio",
          "CustomerCompanyName": "J Yankle Company Ltd",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$105,965.31",
          "BranchNumber": 7272,
          "SubmitDate": "09-02-2021",
          "ClientonJob": "J Yankle Company Ltd"
        },
        {
          "WorkOrder": 4161602,
          "ParentWorkOrder": "",
          "JobName": "Mercy Health Kenwood",
          "JobNumber": "7347-102659-8",
          "FirstdayontheJob": "09-01-2021",
          "LastdayontheJob": "09-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4700 E Galbraith Rd",
          "JobAddress2": "",
          "JobFullAddress": "4700 E Galbraith Rd",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "True Cut Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,348.81",
          "BranchNumber": 7347,
          "SubmitDate": "09-02-2021",
          "ClientonJob": "True Cut Construction LLC"
        },
        {
          "WorkOrder": 4161601,
          "ParentWorkOrder": "",
          "JobName": "Pieology",
          "JobNumber": "7302-137373-13",
          "FirstdayontheJob": "09-01-2021",
          "LastdayontheJob": "09-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1123 W Trenton Avenue",
          "JobAddress2": "",
          "JobFullAddress": "1123 W Trenton Avenue",
          "JobCounty": "HANCOCK",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,546.98",
          "BranchNumber": 7302,
          "SubmitDate": "09-02-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4161599,
          "ParentWorkOrder": "",
          "JobName": "Otterbein Additions",
          "JobNumber": "7348-133878-7",
          "FirstdayontheJob": "09-01-2021",
          "LastdayontheJob": "09-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9320 Avalon Cir",
          "JobAddress2": "",
          "JobFullAddress": "9320 Avalon Cir",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "RCS Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,355.72",
          "BranchNumber": 7348,
          "SubmitDate": "09-02-2021",
          "ClientonJob": "RCS Construction"
        },
        {
          "WorkOrder": 4161313,
          "ParentWorkOrder": 4143497,
          "JobName": "Nof Berkley Anderson Ms",
          "JobNumber": "7313-104657-45",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3205 Catalpa Dr",
          "JobAddress2": "",
          "JobFullAddress": "3205 Catalpa Dr",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,562.08",
          "BranchNumber": 7313,
          "SubmitDate": "09-01-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4161312,
          "ParentWorkOrder": 4141164,
          "JobName": "Nof 21 5166 Tarppan M S",
          "JobNumber": "7313-104657-42",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2251 E Stadium Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2251 E Stadium Blvd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,588.67",
          "BranchNumber": 7313,
          "SubmitDate": "09-01-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4160548,
          "ParentWorkOrder": 4159905,
          "JobName": "3 TIGERS",
          "JobNumber": "7272-132739-15",
          "FirstdayontheJob": "08-19-2021",
          "LastdayontheJob": "08-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "140 N PROSPECT ST",
          "JobAddress2": "",
          "JobFullAddress": "140 N PROSPECT ST",
          "JobCounty": "LICKING",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$27,471.03",
          "BranchNumber": 7272,
          "SubmitDate": "08-30-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4160528,
          "ParentWorkOrder": 4145192,
          "JobName": "10034 Chris Morgan",
          "JobNumber": "7422-104151-86",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10034 Ludlow Ave",
          "JobAddress2": "",
          "JobFullAddress": "10034 Ludlow Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Savyer Drywall LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,150.95",
          "BranchNumber": 7422,
          "SubmitDate": "08-30-2021",
          "ClientonJob": "Savyer Drywall LLC"
        },
        {
          "WorkOrder": 4160504,
          "ParentWorkOrder": 4149381,
          "JobName": "Wacker",
          "JobNumber": "7310-137349-3",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4950 S State St",
          "JobAddress2": "",
          "JobFullAddress": "4950 S State St",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Custom Architectural Sheet Metal Specialists",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$47,339.60",
          "BranchNumber": 7310,
          "SubmitDate": "08-30-2021",
          "ClientonJob": "Custom Architectural Sheet Metal Specialists"
        },
        {
          "WorkOrder": 4159905,
          "ParentWorkOrder": 4157582,
          "JobName": "3 TIGERS",
          "JobNumber": "7272-132739-15",
          "FirstdayontheJob": "08-19-2021",
          "LastdayontheJob": "08-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "140 N PROSPECT ST",
          "JobAddress2": "",
          "JobFullAddress": "140 N PROSPECT ST",
          "JobCounty": "LICKING",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$27,471.03",
          "BranchNumber": 7272,
          "SubmitDate": "08-27-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4159706,
          "ParentWorkOrder": "",
          "JobName": "Nof Rair Kalamazoo",
          "JobNumber": "7313-100963-86",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "104 N Drake Rd",
          "JobAddress2": "",
          "JobFullAddress": "104 N Drake Rd",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$56,899.99",
          "BranchNumber": 7313,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4159705,
          "ParentWorkOrder": "",
          "JobName": "Njp Fc2021 Project",
          "JobNumber": "7308-101361-18",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3509 3 Mile Road NW",
          "JobAddress2": "Suite 3",
          "JobFullAddress": "3509 3 Mile Road NW Suite 3",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Angstrom Technology Ltd",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$39,901.20",
          "BranchNumber": 7308,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Angstrom Technology Ltd"
        },
        {
          "WorkOrder": 4159704,
          "ParentWorkOrder": "",
          "JobName": "Urban Outfitters",
          "JobNumber": "7313-100963-101",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2950 Towne Center Blvd",
          "JobAddress2": "Suite 66A",
          "JobFullAddress": "2950 Towne Center Blvd Suite 66A",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$30,689.64",
          "BranchNumber": 7313,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4159703,
          "ParentWorkOrder": "",
          "JobName": "Avalon  Hilltop Apartments",
          "JobNumber": "7313-104675-5",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7651 Dan Hoey Rd",
          "JobAddress2": "",
          "JobFullAddress": "7651 Dan Hoey Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Stony Creek Serv",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$15,806.73",
          "BranchNumber": 7313,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Stony Creek Serv"
        },
        {
          "WorkOrder": 4159702,
          "ParentWorkOrder": "",
          "JobName": "Njp Calvary Baptist",
          "JobNumber": "7308-104241-2",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "815 W Dresden St",
          "JobAddress2": "",
          "JobFullAddress": "815 W Dresden St",
          "JobCounty": "Kalkaska",
          "JobState": "Michigan",
          "CustomerCompanyName": "Surfaces Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,139.03",
          "BranchNumber": 7308,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Surfaces Inc"
        },
        {
          "WorkOrder": 4159701,
          "ParentWorkOrder": "",
          "JobName": "GAGE PROVISIONING",
          "JobNumber": "7308-102688-14",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3825 STADIUM DR",
          "JobAddress2": "",
          "JobFullAddress": "3825 STADIUM DR",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "Keystone Const & Consult LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,249.66",
          "BranchNumber": 7308,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Keystone Const & Consult LLC"
        },
        {
          "WorkOrder": 4159700,
          "ParentWorkOrder": "",
          "JobName": "SKYMINT",
          "JobNumber": "7308-104198-35",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2345 E Apple Ave",
          "JobAddress2": "",
          "JobFullAddress": "2345 E Apple Ave",
          "JobCounty": "Muskegon",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,934.96",
          "BranchNumber": 7308,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4159699,
          "ParentWorkOrder": "",
          "JobName": "St. Marys Book Store",
          "JobNumber": "7313-104657-51",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3535 Indian Trail",
          "JobAddress2": "",
          "JobFullAddress": "3535 Indian Trail",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,155.28",
          "BranchNumber": 7313,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4159698,
          "ParentWorkOrder": "",
          "JobName": "Town Square Plaza",
          "JobNumber": "7310-104761-33",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26751 Ford Rd",
          "JobAddress2": "",
          "JobFullAddress": "26751 Ford Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Token Acoustical Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,800.88",
          "BranchNumber": 7310,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Token Acoustical Inc"
        },
        {
          "WorkOrder": 4159697,
          "ParentWorkOrder": "",
          "JobName": "Njp American Surgery Grp",
          "JobNumber": "7313-104546-50",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "329 Enterprise Ct",
          "JobAddress2": "",
          "JobFullAddress": "329 Enterprise Ct",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,659.41",
          "BranchNumber": 7313,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "SC Construction"
        },
        {
          "WorkOrder": 4159696,
          "ParentWorkOrder": "",
          "JobName": "STERLING INDUSTRIES",
          "JobNumber": "7308-101361-23",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6825 BEATRICE DR",
          "JobAddress2": "SUITE C",
          "JobFullAddress": "6825 BEATRICE DR SUITE C",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "Angstrom Technology Ltd",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,153.92",
          "BranchNumber": 7308,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Angstrom Technology Ltd"
        },
        {
          "WorkOrder": 4159695,
          "ParentWorkOrder": "",
          "JobName": "Njp Mww Morgan Woods W",
          "JobNumber": "7308-101533-81",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Aebmww",
          "JobAddress2": "",
          "JobFullAddress": "Aebmww",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Allen Edwin Home Builders LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,128.54",
          "BranchNumber": 7308,
          "SubmitDate": "",
          "ClientonJob": "Allen Edwin Home Builders LLC"
        },
        {
          "WorkOrder": 4159694,
          "ParentWorkOrder": "",
          "JobName": "Edelman Financial",
          "JobNumber": "7308-104328-66",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5510 Cascade rd SE",
          "JobAddress2": "",
          "JobFullAddress": "5510 Cascade rd SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Intext Concepts Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,094.11",
          "BranchNumber": 7308,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Intext Concepts Inc"
        },
        {
          "WorkOrder": 4159693,
          "ParentWorkOrder": "",
          "JobName": "Njp Clio High School",
          "JobNumber": "7304-104137-14",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Mustang Dr",
          "JobAddress2": "",
          "JobFullAddress": "1 Mustang Dr",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Wobig Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,889.25",
          "BranchNumber": 7304,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Wobig Construction"
        },
        {
          "WorkOrder": 4159692,
          "ParentWorkOrder": "",
          "JobName": "McDonalds St. Clair",
          "JobNumber": "7313-100963-98",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1155 S Carney Dr",
          "JobAddress2": "",
          "JobFullAddress": "1155 S Carney Dr",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,694.23",
          "BranchNumber": 7313,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4159691,
          "ParentWorkOrder": "",
          "JobName": "LIFE STREAM CHURCH",
          "JobNumber": "7308-104328-65",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6561 LAKE MICHIGAN DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "6561 LAKE MICHIGAN DRIVE",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Intext Concepts Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,603.63",
          "BranchNumber": 7308,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Intext Concepts Inc"
        },
        {
          "WorkOrder": 4159690,
          "ParentWorkOrder": "",
          "JobName": "Geneysis Care",
          "JobNumber": "7313-100963-100",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4550 Investment Dr",
          "JobAddress2": "Suite B 111",
          "JobFullAddress": "4550 Investment Dr Suite B 111",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,560.27",
          "BranchNumber": 7313,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4159689,
          "ParentWorkOrder": "",
          "JobName": "Taco Bell Standish",
          "JobNumber": "7313-100963-99",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "506 S Main St",
          "JobAddress2": "",
          "JobFullAddress": "506 S Main St",
          "JobCounty": "ARENAC",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,275.52",
          "BranchNumber": 7313,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4159688,
          "ParentWorkOrder": "",
          "JobName": "Evident Church",
          "JobNumber": "7313-104400-14",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "52101 Gratiot Avenue",
          "JobAddress2": "",
          "JobFullAddress": "52101 Gratiot Avenue",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Parallel Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,107.29",
          "BranchNumber": 7313,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Parallel Construction"
        },
        {
          "WorkOrder": 4159687,
          "ParentWorkOrder": "",
          "JobName": "Athleta",
          "JobNumber": "7302-137373-14",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2150 Levis Commons Blvd",
          "JobAddress2": "Suite 1140",
          "JobFullAddress": "2150 Levis Commons Blvd Suite 1140",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$14,757.53",
          "BranchNumber": 7302,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4159686,
          "ParentWorkOrder": "",
          "JobName": "Petsmart",
          "JobNumber": "7270-133866-7",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6068 Glenway Ave",
          "JobAddress2": "",
          "JobFullAddress": "6068 Glenway Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Kerricook Const Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,614.69",
          "BranchNumber": 7270,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Kerricook Const Inc"
        },
        {
          "WorkOrder": 4159685,
          "ParentWorkOrder": "",
          "JobName": "Whetstone High School Ceiling",
          "JobNumber": "7272-132736-8",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4405 Scenic Dr",
          "JobAddress2": "",
          "JobFullAddress": "4405 Scenic Dr",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Procon Professional Const",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,752.82",
          "BranchNumber": 7272,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Procon Professional Const"
        },
        {
          "WorkOrder": 4159684,
          "ParentWorkOrder": "",
          "JobName": "Dunkin Donuts",
          "JobNumber": "7302-137373-15",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "637 West McPherson Highway",
          "JobAddress2": "",
          "JobFullAddress": "637 West McPherson Highway",
          "JobCounty": "SANDUSKY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,677.59",
          "BranchNumber": 7302,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4159683,
          "ParentWorkOrder": "",
          "JobName": "Nof L C Corrections",
          "JobNumber": "7302-104927-20",
          "FirstdayontheJob": "08-25-2021",
          "LastdayontheJob": "08-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2001 E Central Ave",
          "JobAddress2": "",
          "JobFullAddress": "2001 E Central Ave",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Midwest Contracting Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,603.79",
          "BranchNumber": 7302,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "Midwest Contracting Inc"
        },
        {
          "WorkOrder": 4159287,
          "ParentWorkOrder": 4151515,
          "JobName": "North East Corner of Building",
          "JobNumber": "7312-105089-4",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 S JACKSON ST",
          "JobAddress2": "",
          "JobFullAddress": "100 S JACKSON ST",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "River City Interiors",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,102.89",
          "BranchNumber": 7312,
          "SubmitDate": "08-26-2021",
          "ClientonJob": "River City Interiors"
        },
        {
          "WorkOrder": 4159286,
          "ParentWorkOrder": "",
          "JobName": "GERMAIN",
          "JobNumber": "7272-132739-14",
          "FirstdayontheJob": "08-24-2021",
          "LastdayontheJob": "08-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1525 Georgesville Rd",
          "JobAddress2": "",
          "JobFullAddress": "1525 Georgesville Rd",
          "JobCounty": "570-207046",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$26,056.94",
          "BranchNumber": 7272,
          "SubmitDate": "08-25-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4159285,
          "ParentWorkOrder": "",
          "JobName": "Nof Hampton Manor Of Mad",
          "JobNumber": "7302-105203-2",
          "FirstdayontheJob": "08-24-2021",
          "LastdayontheJob": "08-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1491 E US Highway 223",
          "JobAddress2": "",
          "JobFullAddress": "1491 E US Highway 223",
          "JobCounty": "LENAWEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Innovating Drywall System",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,314.49",
          "BranchNumber": 7302,
          "SubmitDate": "08-25-2021",
          "ClientonJob": "Innovating Drywall System"
        },
        {
          "WorkOrder": 4157582,
          "ParentWorkOrder": "",
          "JobName": "3 TIGERS",
          "JobNumber": "7272-132739-15",
          "FirstdayontheJob": "08-19-2021",
          "LastdayontheJob": "08-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "135 N PROSPECT ST",
          "JobAddress2": "",
          "JobFullAddress": "135 N PROSPECT ST",
          "JobCounty": "LICKING",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$27,471.03",
          "BranchNumber": 7272,
          "SubmitDate": "08-20-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4157581,
          "ParentWorkOrder": "",
          "JobName": "Dollar General",
          "JobNumber": "7302-106905-4",
          "FirstdayontheJob": "08-19-2021",
          "LastdayontheJob": "08-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3358 Glendale Ave",
          "JobAddress2": "",
          "JobFullAddress": "3358 Glendale Ave",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Viper Wall Systems",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,008.76",
          "BranchNumber": 7302,
          "SubmitDate": "08-20-2021",
          "ClientonJob": "Viper Wall Systems"
        },
        {
          "WorkOrder": 4157580,
          "ParentWorkOrder": "",
          "JobName": "Project Scarlet- CMH7",
          "JobNumber": "7272-102828-59",
          "FirstdayontheJob": "08-19-2021",
          "LastdayontheJob": "08-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1245 Beech Rd SW",
          "JobAddress2": "",
          "JobFullAddress": "1245 Beech Rd SW",
          "JobCounty": "LICKING",
          "JobState": "Ohio",
          "CustomerCompanyName": "United Contractor Service",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$116,277.67",
          "BranchNumber": 7272,
          "SubmitDate": "08-20-2021",
          "ClientonJob": "United Contractor Service"
        },
        {
          "WorkOrder": 4157579,
          "ParentWorkOrder": "",
          "JobName": "twin valley behavior ctr",
          "JobNumber": "7272-106672-2",
          "FirstdayontheJob": "08-19-2021",
          "LastdayontheJob": "08-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2200 w broad st",
          "JobAddress2": "",
          "JobFullAddress": "2200 w broad st",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "R W Setterlin Building Co",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$7,232.89",
          "BalanceRemaining": "$7,232.89",
          "BranchNumber": 7272,
          "SubmitDate": "08-20-2021",
          "ClientonJob": "R W Setterlin Building Co"
        },
        {
          "WorkOrder": 4157578,
          "ParentWorkOrder": "",
          "JobName": "Monroe Provisioning Center",
          "JobNumber": "7302-104643-26",
          "FirstdayontheJob": "08-19-2021",
          "LastdayontheJob": "08-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14500 Laplaisance Rd",
          "JobAddress2": "Lockbox #2367  Unit on right",
          "JobFullAddress": "14500 Laplaisance Rd Lockbox #2367  Unit on right",
          "JobCounty": "Monroe",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$15,171.98",
          "BranchNumber": 7302,
          "SubmitDate": "08-20-2021",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 4157316,
          "ParentWorkOrder": "",
          "JobName": "Munn Ice Arena- Phase 2",
          "JobNumber": "7310-101038-41",
          "FirstdayontheJob": "08-18-2021",
          "LastdayontheJob": "08-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "509 Birch Rd",
          "JobAddress2": "",
          "JobFullAddress": "509 Birch Rd",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$31,154.27",
          "BranchNumber": 7310,
          "SubmitDate": "08-19-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4157315,
          "ParentWorkOrder": "",
          "JobName": "Njp Taco Bell Kitch Frp",
          "JobNumber": "7310-104700-2",
          "FirstdayontheJob": "08-18-2021",
          "LastdayontheJob": "08-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1525 E Alexis Rd",
          "JobAddress2": "",
          "JobFullAddress": "1525 E Alexis Rd",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "Clear Image Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,668.14",
          "BranchNumber": 7310,
          "SubmitDate": "08-19-2021",
          "ClientonJob": "Clear Image Construction"
        },
        {
          "WorkOrder": 4157314,
          "ParentWorkOrder": "",
          "JobName": "Nof Caro Psychiartic Hos",
          "JobNumber": "7313-102953-35",
          "FirstdayontheJob": "08-18-2021",
          "LastdayontheJob": "08-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2000 Chambers Rd",
          "JobAddress2": "",
          "JobFullAddress": "2000 Chambers Rd",
          "JobCounty": "TUSCOLA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Contracting",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$95,036.01",
          "BranchNumber": 7313,
          "SubmitDate": "08-19-2021",
          "ClientonJob": "Commercial Contracting"
        },
        {
          "WorkOrder": 4157313,
          "ParentWorkOrder": "",
          "JobName": "Mt.Clemens Library",
          "JobNumber": "7313-104450-30",
          "FirstdayontheJob": "08-18-2021",
          "LastdayontheJob": "08-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "150 Cass Ave",
          "JobAddress2": "",
          "JobFullAddress": "150 Cass Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Hudson Interiors",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$37,761.17",
          "BranchNumber": 7313,
          "SubmitDate": "08-19-2021",
          "ClientonJob": "Hudson Interiors"
        },
        {
          "WorkOrder": 4157257,
          "ParentWorkOrder": 4134568,
          "JobName": "(NOF)20-5151 Orchard Hill",
          "JobNumber": "502-920940-104",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41900 Quince Dr",
          "JobAddress2": "",
          "JobFullAddress": "41900 Quince Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4156534,
          "ParentWorkOrder": 4147906,
          "JobName": "GERMAIN VOLKSWAGEN",
          "JobNumber": "7272-132739-12",
          "FirstdayontheJob": "07-02-2021",
          "LastdayontheJob": "07-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1495 AUTO MALL DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "1495 AUTO MALL DRIVE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,234.93",
          "BranchNumber": 7272,
          "SubmitDate": "08-16-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4156043,
          "ParentWorkOrder": 4147924,
          "JobName": "Shoot Point Blank",
          "JobNumber": "7310-104562-8",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21420 Hall Rd",
          "JobAddress2": "",
          "JobFullAddress": "21420 Hall Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,355.90",
          "BranchNumber": 7310,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 4156008,
          "ParentWorkOrder": "",
          "JobName": "Nof Awls Burdo Building",
          "JobNumber": "7348-108870-1",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5967 Finzel Rd",
          "JobAddress2": "",
          "JobFullAddress": "5967 Finzel Rd",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "Midwest Contracting Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,560.05",
          "BranchNumber": 7302,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "Midwest Contracting Inc"
        },
        {
          "WorkOrder": 4156007,
          "ParentWorkOrder": "",
          "JobName": "McDonalds Hamilton",
          "JobNumber": "7348-100492-6",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6840 Hamilton Ave",
          "JobAddress2": "",
          "JobFullAddress": "6840 Hamilton Ave",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "LIS Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,770.49",
          "BranchNumber": 7348,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "LIS Construction"
        },
        {
          "WorkOrder": 4156005,
          "ParentWorkOrder": "",
          "JobName": "Perrysburg Animal Hospita",
          "JobNumber": "7302-104922-9",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9310 Bass Pro Blvd",
          "JobAddress2": "",
          "JobFullAddress": "9310 Bass Pro Blvd",
          "JobCounty": "Wood",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$20,664.68",
          "BranchNumber": 7302,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4156004,
          "ParentWorkOrder": "",
          "JobName": "Njp Placon Room",
          "JobNumber": "7308-101361-22",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3509 3 Mile Rd NW",
          "JobAddress2": "Ste 3",
          "JobFullAddress": "3509 3 Mile Rd NW Ste 3",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Angstrom Technology Ltd",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$26,252.64",
          "BranchNumber": 7308,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "Angstrom Technology Ltd"
        },
        {
          "WorkOrder": 4156003,
          "ParentWorkOrder": "",
          "JobName": "Crunch Fitness",
          "JobNumber": "7422-104267-9",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14255 Eureka road",
          "JobAddress2": "",
          "JobFullAddress": "14255 Eureka road",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Custom Build",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$24,547.20",
          "BranchNumber": 7422,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "Great Lakes Custom Build"
        },
        {
          "WorkOrder": 4156001,
          "ParentWorkOrder": "",
          "JobName": "Bontaz",
          "JobNumber": "7313-104450-29",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1099 Chicago Rd",
          "JobAddress2": "",
          "JobFullAddress": "1099 Chicago Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Hudson Interiors",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$21,038.51",
          "BranchNumber": 7313,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "Hudson Interiors"
        },
        {
          "WorkOrder": 4156000,
          "ParentWorkOrder": "",
          "JobName": "Swetech Medical Building",
          "JobNumber": "7422-137143-8",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "43600 Garfield rd",
          "JobAddress2": "",
          "JobFullAddress": "43600 Garfield rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Priority Constructors Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,662.76",
          "BranchNumber": 7422,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "Priority Constructors Inc"
        },
        {
          "WorkOrder": 4155999,
          "ParentWorkOrder": "",
          "JobName": "MMH Midland Hospital",
          "JobNumber": "7304-104189-14",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4000 Wellness Dr",
          "JobAddress2": "",
          "JobFullAddress": "4000 Wellness Dr",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Three Rivers Corporation",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,053.26",
          "BranchNumber": 7304,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "Three Rivers Corporation"
        },
        {
          "WorkOrder": 4155998,
          "ParentWorkOrder": "",
          "JobName": "515 Chief Financial Rochester",
          "JobNumber": "7313-104399-13",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "515 N Main St / 515 S Rochester Rd",
          "JobAddress2": "",
          "JobFullAddress": "515 N Main St / 515 S Rochester Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "G J Perelli Co",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,926.07",
          "BranchNumber": 7313,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "G J Perelli Co"
        },
        {
          "WorkOrder": 4155997,
          "ParentWorkOrder": "",
          "JobName": "Indi Suites",
          "JobNumber": "7308-104198-33",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4585 Canal Ave SW",
          "JobAddress2": "",
          "JobFullAddress": "4585 Canal Ave SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,130.65",
          "BranchNumber": 7308,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4155996,
          "ParentWorkOrder": "",
          "JobName": "Weston Elementary",
          "JobNumber": "7313-104368-23",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "275 Weston Street",
          "JobAddress2": "",
          "JobFullAddress": "275 Weston Street",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elkhorn Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,768.36",
          "BranchNumber": 7313,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "Elkhorn Construction"
        },
        {
          "WorkOrder": 4155994,
          "ParentWorkOrder": "",
          "JobName": "Military Place",
          "JobNumber": "7310-104562-9",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22370 Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "22370 Michigan Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,142.46",
          "BranchNumber": 7310,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 4155993,
          "ParentWorkOrder": "",
          "JobName": "Macomb Library",
          "JobNumber": "7313-132707-27",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "54100 Broughton Road",
          "JobAddress2": "",
          "JobFullAddress": "54100 Broughton Road",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "Pontiac Ceiling & Part",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,962.11",
          "BranchNumber": 7313,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "Pontiac Ceiling & Part"
        },
        {
          "WorkOrder": 4155992,
          "ParentWorkOrder": "",
          "JobName": "Njp Barricades Job 1007",
          "JobNumber": "7310-104590-2",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "GC Somerset Collections",
          "JobAddress2": "",
          "JobFullAddress": "GC Somerset Collections",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH Construction Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,997.09",
          "BranchNumber": 7310,
          "SubmitDate": "",
          "ClientonJob": "DH Construction Company"
        },
        {
          "WorkOrder": 4155991,
          "ParentWorkOrder": "",
          "JobName": "NOVA CARE",
          "JobNumber": "7308-104851-39",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "120 S Dexter St",
          "JobAddress2": "",
          "JobFullAddress": "120 S Dexter St",
          "JobCounty": "Ionia",
          "JobState": "Michigan",
          "CustomerCompanyName": "Level 5 Commercial Finish",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,794.90",
          "BranchNumber": 7308,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "Level 5 Commercial Finish"
        },
        {
          "WorkOrder": 4155990,
          "ParentWorkOrder": "",
          "JobName": "Brouwers Agency",
          "JobNumber": "7308-104225-13",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1925 Breton Ave",
          "JobAddress2": "",
          "JobFullAddress": "1925 Breton Ave",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "Mid Michigan Ceiling & Drywall",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,569.09",
          "BranchNumber": 7308,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "Mid Michigan Ceiling & Drywall"
        },
        {
          "WorkOrder": 4155989,
          "ParentWorkOrder": "",
          "JobName": "St Joseph Mercy Hospital",
          "JobNumber": "7313-104489-2",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5337 McAuley Dr",
          "JobAddress2": "",
          "JobFullAddress": "5337 McAuley Dr",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trinity Health Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,212.52",
          "BranchNumber": 7313,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "Trinity Health Services"
        },
        {
          "WorkOrder": 4155987,
          "ParentWorkOrder": "",
          "JobName": "Njp Irs",
          "JobNumber": "7313-133614-4",
          "FirstdayontheJob": "08-12-2021",
          "LastdayontheJob": "08-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "985 Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "985 Michigan Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Acoustic Ceil & Part",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,013.92",
          "BranchNumber": 7313,
          "SubmitDate": "08-13-2021",
          "ClientonJob": "Acoustic Ceil & Part"
        },
        {
          "WorkOrder": 4155033,
          "ParentWorkOrder": 4145193,
          "JobName": "Nof Jersey Mikes Davison",
          "JobNumber": "7313-104450-25",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1053 N Irish Rd",
          "JobAddress2": "",
          "JobFullAddress": "1053 N Irish Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Hudson Interiors",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,163.12",
          "BranchNumber": 7313,
          "SubmitDate": "08-10-2021",
          "ClientonJob": "Hudson Interiors"
        },
        {
          "WorkOrder": 4154898,
          "ParentWorkOrder": 4145476,
          "JobName": "Midwest Shooting Center",
          "JobNumber": "7302-103589-2",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7638 SYLVANIA AVE",
          "JobAddress2": "",
          "JobFullAddress": "7638 SYLVANIA AVE",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Gehring & Burtchin Cont Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$762.20",
          "BranchNumber": 7302,
          "SubmitDate": "08-10-2021",
          "ClientonJob": "Gehring & Burtchin Cont Inc"
        },
        {
          "WorkOrder": 4154409,
          "ParentWorkOrder": 4149392,
          "JobName": "Springdale Commerce",
          "JobNumber": "7270-132755-13",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1145 Springdale Commerce",
          "JobAddress2": "",
          "JobFullAddress": "1145 Springdale Commerce",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T Square Wall Systems LLC",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,755.20",
          "BranchNumber": 7270,
          "SubmitDate": "08-09-2021",
          "ClientonJob": "T Square Wall Systems LLC"
        },
        {
          "WorkOrder": 4154386,
          "ParentWorkOrder": "",
          "JobName": "Hampton Inn Harrison",
          "JobNumber": "7348-133667-5",
          "FirstdayontheJob": "08-08-2021",
          "LastdayontheJob": "08-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6364 Harrison Ave",
          "JobAddress2": "",
          "JobFullAddress": "6364 Harrison Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Schnippel Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,578.87",
          "BranchNumber": 7348,
          "SubmitDate": "08-09-2021",
          "ClientonJob": "Schnippel Construction"
        },
        {
          "WorkOrder": 4154385,
          "ParentWorkOrder": "",
          "JobName": "Renegrade Warehouse",
          "JobNumber": "7348-133884-2",
          "FirstdayontheJob": "08-08-2021",
          "LastdayontheJob": "08-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10841 Wood Rd",
          "JobAddress2": "",
          "JobFullAddress": "10841 Wood Rd",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "Fayette Drywall",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,416.12",
          "BranchNumber": 7348,
          "SubmitDate": "08-09-2021",
          "ClientonJob": "Fayette Drywall"
        },
        {
          "WorkOrder": 4154384,
          "ParentWorkOrder": "",
          "JobName": "Mercy Health 5 Mile",
          "JobNumber": "7347-102659-7",
          "FirstdayontheJob": "08-08-2021",
          "LastdayontheJob": "08-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7575 Five Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "7575 Five Mile Rd",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "True Cut Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,657.96",
          "BranchNumber": 7347,
          "SubmitDate": "08-09-2021",
          "ClientonJob": "True Cut Construction LLC"
        },
        {
          "WorkOrder": 4154383,
          "ParentWorkOrder": "",
          "JobName": "The Red Corner",
          "JobNumber": "7347-100959-16",
          "FirstdayontheJob": "08-08-2021",
          "LastdayontheJob": "08-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3500 Madison Park Ave",
          "JobAddress2": "",
          "JobFullAddress": "3500 Madison Park Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Morningside Group Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$23,219.55",
          "BranchNumber": 7347,
          "SubmitDate": "08-09-2021",
          "ClientonJob": "Morningside Group Inc"
        },
        {
          "WorkOrder": 4154382,
          "ParentWorkOrder": "",
          "JobName": "Mosaic at Levis Commons",
          "JobNumber": "7302-103182-4",
          "FirstdayontheJob": "08-08-2021",
          "LastdayontheJob": "08-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 Hollister LN",
          "JobAddress2": "",
          "JobFullAddress": "1000 Hollister LN",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "Carter Painting Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$18,703.77",
          "BranchNumber": 7302,
          "SubmitDate": "08-09-2021",
          "ClientonJob": "Carter Painting Inc"
        },
        {
          "WorkOrder": 4154381,
          "ParentWorkOrder": "",
          "JobName": "Otoole Construction Inc",
          "JobNumber": "7272-103887-2",
          "FirstdayontheJob": "08-08-2021",
          "LastdayontheJob": "08-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4531 industrial center",
          "JobAddress2": "",
          "JobFullAddress": "4531 industrial center",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Otoole Construction Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,407.89",
          "BranchNumber": 7272,
          "SubmitDate": "",
          "ClientonJob": "Otoole Construction Inc"
        },
        {
          "WorkOrder": 4154130,
          "ParentWorkOrder": 4151524,
          "JobName": "Orchard Hills Schools",
          "JobNumber": "7310-104657-48",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41900 Quince Dr",
          "JobAddress2": "",
          "JobFullAddress": "41900 Quince Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Contracting Services, Inc.",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,816.73",
          "BranchNumber": 7310,
          "SubmitDate": "08-06-2021",
          "ClientonJob": "Clark Contracting Services, Inc."
        },
        {
          "WorkOrder": 4154102,
          "ParentWorkOrder": 4143501,
          "JobName": "Njp Fairfield Inn",
          "JobNumber": "7313-104019-30",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3900 S Rochester Rd",
          "JobAddress2": "",
          "JobFullAddress": "3900 S Rochester Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,917.44",
          "BranchNumber": 7313,
          "SubmitDate": "08-06-2021",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4154097,
          "ParentWorkOrder": 4141140,
          "JobName": "Njp Home2 Stes Troy",
          "JobNumber": "7313-104019-19",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1035 Wilshire Dr",
          "JobAddress2": "",
          "JobFullAddress": "1035 Wilshire Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$127.20",
          "BranchNumber": 7313,
          "SubmitDate": "08-06-2021",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4154087,
          "ParentWorkOrder": 4139401,
          "JobName": "Nof Kalamazoo Redwood",
          "JobNumber": "7308-105131-8",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6711 W Q Ave",
          "JobAddress2": "",
          "JobFullAddress": "6711 W Q Ave",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "Shingleton Contractors Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$96,292.84",
          "BranchNumber": 7308,
          "SubmitDate": "08-06-2021",
          "ClientonJob": "Shingleton Contractors Inc"
        },
        {
          "WorkOrder": 4153744,
          "ParentWorkOrder": "",
          "JobName": "Nof Endura Coatings",
          "JobNumber": "7313-104450-14",
          "FirstdayontheJob": "08-04-2021",
          "LastdayontheJob": "08-04-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "42250 Yearego Dr",
          "JobAddress2": "",
          "JobFullAddress": "42250 Yearego Dr",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "Hudson Interiors",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,258.56",
          "BranchNumber": 7313,
          "SubmitDate": "08-05-2021",
          "ClientonJob": "Hudson Interiors"
        },
        {
          "WorkOrder": 4153743,
          "ParentWorkOrder": "",
          "JobName": "Caniff Academy",
          "JobNumber": "7310-104761-32",
          "FirstdayontheJob": "08-04-2021",
          "LastdayontheJob": "08-04-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2650 Caniff St",
          "JobAddress2": "",
          "JobFullAddress": "2650 Caniff St",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Token Acoustical Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,783.47",
          "BranchNumber": 7310,
          "SubmitDate": "08-05-2021",
          "ClientonJob": "Token Acoustical Inc"
        },
        {
          "WorkOrder": 4153742,
          "ParentWorkOrder": "",
          "JobName": "Kelly Rd. Facility",
          "JobNumber": "7422-104444-7",
          "FirstdayontheJob": "08-04-2021",
          "LastdayontheJob": "08-04-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21811 Kelly Rd",
          "JobAddress2": "",
          "JobFullAddress": "21811 Kelly Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dalloo Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,363.92",
          "BranchNumber": 7422,
          "SubmitDate": "08-05-2021",
          "ClientonJob": "Dalloo Construction Inc"
        },
        {
          "WorkOrder": 4153741,
          "ParentWorkOrder": "",
          "JobName": "BENS HOPE",
          "JobNumber": "7308-103529-2",
          "FirstdayontheJob": "08-04-2021",
          "LastdayontheJob": "08-04-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15468 RILEY ST",
          "JobAddress2": "",
          "JobFullAddress": "15468 RILEY ST",
          "JobCounty": "Ottawa",
          "JobState": "Michigan",
          "CustomerCompanyName": "CL Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,725.31",
          "BranchNumber": 7308,
          "SubmitDate": "08-05-2021",
          "ClientonJob": "CL Construction"
        },
        {
          "WorkOrder": 4152303,
          "ParentWorkOrder": "",
          "JobName": "Ortho Cincy",
          "JobNumber": "7270-106739-2",
          "FirstdayontheJob": "08-01-2021",
          "LastdayontheJob": "08-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8734 UNION CENTRE BLVD",
          "JobAddress2": "",
          "JobFullAddress": "8734 UNION CENTRE BLVD",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "Hutsenpiller Contractors",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,566.56",
          "BranchNumber": 7270,
          "SubmitDate": "08-02-2021",
          "ClientonJob": "Hutsenpiller Contractors"
        },
        {
          "WorkOrder": 4152302,
          "ParentWorkOrder": "",
          "JobName": "Hern Residence",
          "JobNumber": "7347-107104-2",
          "FirstdayontheJob": "08-01-2021",
          "LastdayontheJob": "08-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5640 Oakvista Dr",
          "JobAddress2": "",
          "JobFullAddress": "5640 Oakvista Dr",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Andeck Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,896.56",
          "BranchNumber": 7347,
          "SubmitDate": "08-02-2021",
          "ClientonJob": "Andeck Inc"
        },
        {
          "WorkOrder": 4152301,
          "ParentWorkOrder": "",
          "JobName": "Hampton Inn",
          "JobNumber": "7272-103779-2",
          "FirstdayontheJob": "08-01-2021",
          "LastdayontheJob": "08-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3920 Tuller Rd",
          "JobAddress2": "",
          "JobFullAddress": "3920 Tuller Rd",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "HKK  Enterprise Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,521.72",
          "BranchNumber": 7272,
          "SubmitDate": "08-02-2021",
          "ClientonJob": "HKK  Enterprise Inc"
        },
        {
          "WorkOrder": 4152300,
          "ParentWorkOrder": "",
          "JobName": "Superior Buildout",
          "JobNumber": "7302-133910-3",
          "FirstdayontheJob": "08-01-2021",
          "LastdayontheJob": "08-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2755 Harding Highway",
          "JobAddress2": "",
          "JobFullAddress": "2755 Harding Highway",
          "JobCounty": "ALLEN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Miller Contracting Group",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,411.25",
          "BranchNumber": 7302,
          "SubmitDate": "08-02-2021",
          "ClientonJob": "Miller Contracting Group"
        },
        {
          "WorkOrder": 4152299,
          "ParentWorkOrder": "",
          "JobName": "Kroger N-818",
          "JobNumber": "7272-132744-8",
          "FirstdayontheJob": "08-01-2021",
          "LastdayontheJob": "08-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2090 Crown Plaza Drive",
          "JobAddress2": "",
          "JobFullAddress": "2090 Crown Plaza Drive",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "R&W Fixturing Co Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,425.05",
          "BranchNumber": 7272,
          "SubmitDate": "08-02-2021",
          "ClientonJob": "R&W Fixturing Co Inc"
        },
        {
          "WorkOrder": 4151526,
          "ParentWorkOrder": "",
          "JobName": "Go to Transport",
          "JobNumber": "7313-137383-28",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9900 Harrison Rd",
          "JobAddress2": "Suite 100",
          "JobFullAddress": "9900 Harrison Rd Suite 100",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Distinctive Grading & Design LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$14,811.26",
          "BranchNumber": 7313,
          "SubmitDate": "07-30-2021",
          "ClientonJob": "Distinctive Grading & Design LLC"
        },
        {
          "WorkOrder": 4151525,
          "ParentWorkOrder": "",
          "JobName": "Culvers-South Haven",
          "JobNumber": "7308-104549-13",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "72219 CR-388",
          "JobAddress2": "",
          "JobFullAddress": "72219 CR-388",
          "JobCounty": "Van Buren",
          "JobState": "Michigan",
          "CustomerCompanyName": "Superior Designs Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,006.28",
          "BranchNumber": 7308,
          "SubmitDate": "07-30-2021",
          "ClientonJob": "Superior Designs Inc"
        },
        {
          "WorkOrder": 4151524,
          "ParentWorkOrder": "",
          "JobName": "Orchard Hills Schools",
          "JobNumber": "7310-104657-48",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41900 Quince Dr",
          "JobAddress2": "",
          "JobFullAddress": "41900 Quince Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,816.73",
          "BranchNumber": 7310,
          "SubmitDate": "07-30-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4151523,
          "ParentWorkOrder": "",
          "JobName": "Njp Placon Elkhart",
          "JobNumber": "7308-101361-20",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3509 3 Mile Rd NW",
          "JobAddress2": "Ste 3",
          "JobFullAddress": "3509 3 Mile Rd NW Ste 3",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Angstrom Technology Ltd",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,430.61",
          "BranchNumber": 7308,
          "SubmitDate": "",
          "ClientonJob": "Angstrom Technology Ltd"
        },
        {
          "WorkOrder": 4151522,
          "ParentWorkOrder": "",
          "JobName": "Njp Gisd Davis Cntr",
          "JobNumber": "7304-104137-11",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2413 W Maple Ave",
          "JobAddress2": "",
          "JobFullAddress": "2413 W Maple Ave",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Wobig Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,264.59",
          "BranchNumber": 7304,
          "SubmitDate": "07-30-2021",
          "ClientonJob": "Wobig Construction"
        },
        {
          "WorkOrder": 4151521,
          "ParentWorkOrder": "",
          "JobName": "Total Wine Novi",
          "JobNumber": "7313-136494-15",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21071 Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "21071 Haggerty Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Marine City Contracting Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,386.52",
          "BranchNumber": 7313,
          "SubmitDate": "07-30-2021",
          "ClientonJob": "Marine City Contracting Inc"
        },
        {
          "WorkOrder": 4151520,
          "ParentWorkOrder": "",
          "JobName": "8 MILE FACILITY",
          "JobNumber": "7422-104444-6",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1921 E 8 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "1921 E 8 MILE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dalloo Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$15,297.08",
          "BranchNumber": 7422,
          "SubmitDate": "07-30-2021",
          "ClientonJob": "Dalloo Construction Inc"
        },
        {
          "WorkOrder": 4151519,
          "ParentWorkOrder": "",
          "JobName": "Lake Fenton Point Facade",
          "JobNumber": "7422-104019-54",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1545 N LEROY ST",
          "JobAddress2": "",
          "JobFullAddress": "1545 N LEROY ST",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,109.66",
          "BranchNumber": 7422,
          "SubmitDate": "07-30-2021",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4151518,
          "ParentWorkOrder": "",
          "JobName": "Kroger Flushing",
          "JobNumber": "7313-101358-12",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1542 E Pierson Rd",
          "JobAddress2": "",
          "JobFullAddress": "1542 E Pierson Rd",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC Construction Co Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,404.01",
          "BranchNumber": 7313,
          "SubmitDate": "07-30-2021",
          "ClientonJob": "AC Construction Co Inc"
        },
        {
          "WorkOrder": 4151517,
          "ParentWorkOrder": "",
          "JobName": "Nof 278 McC Sd Renov",
          "JobNumber": "7313-104368-15",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14500 E 12 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "14500 E 12 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elkhorn Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,595.38",
          "BranchNumber": 7313,
          "SubmitDate": "07-30-2021",
          "ClientonJob": "Elkhorn Construction"
        },
        {
          "WorkOrder": 4151516,
          "ParentWorkOrder": "",
          "JobName": "Clelsea Plaza",
          "JobNumber": "7312-104816-8",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1101 S MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "1101 S MAIN ST",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "R W Mercer",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$27,223.13",
          "BranchNumber": 7312,
          "SubmitDate": "07-30-2021",
          "ClientonJob": "R W Mercer"
        },
        {
          "WorkOrder": 4151515,
          "ParentWorkOrder": "",
          "JobName": "North East Corner of Building",
          "JobNumber": "7312-105089-4",
          "FirstdayontheJob": "07-29-2021",
          "LastdayontheJob": "07-29-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 S JACKSON ST",
          "JobAddress2": "",
          "JobFullAddress": "100 S JACKSON ST",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "River City Interiors",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,102.89",
          "BranchNumber": 7312,
          "SubmitDate": "07-30-2021",
          "ClientonJob": "River City Interiors"
        },
        {
          "WorkOrder": 4151434,
          "ParentWorkOrder": 4134562,
          "JobName": "(NOF)ELGA Credit Union",
          "JobNumber": "502-813200-156",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6051 Grand Pointe Blvd.",
          "JobAddress2": "",
          "JobFullAddress": "6051 Grand Pointe Blvd.",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$175,000.00",
          "BalanceRemaining": "$66,965.29",
          "BranchNumber": 8,
          "SubmitDate": "07-29-2021",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4151428,
          "ParentWorkOrder": 4132558,
          "JobName": "(NOF)GM Tech Center",
          "JobNumber": "502-1606296-14",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30400 Van Dyke Ave",
          "JobAddress2": "",
          "JobFullAddress": "30400 Van Dyke Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Regis Construction, LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "07-29-2021",
          "ClientonJob": "Regis Construction, LLC"
        },
        {
          "WorkOrder": 4151394,
          "ParentWorkOrder": 4132569,
          "JobName": "(NOF)MJC 87 FOX CREEK",
          "JobNumber": "502-164470-23",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17721 WOODSTOCK CT",
          "JobAddress2": "",
          "JobFullAddress": "17721 WOODSTOCK CT",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,129.60",
          "BranchNumber": 11,
          "SubmitDate": "07-29-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4151382,
          "ParentWorkOrder": 4132570,
          "JobName": "(NOF)MJC-FOX CREEK #86",
          "JobNumber": "502-164470-24",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17697 WOODSTOCK CT",
          "JobAddress2": "",
          "JobFullAddress": "17697 WOODSTOCK CT",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,806.00",
          "BalanceRemaining": "$3,516.78",
          "BranchNumber": 11,
          "SubmitDate": "07-29-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4151357,
          "ParentWorkOrder": 4139397,
          "JobName": "Nof Livonia Med Cntr",
          "JobNumber": "7312-104842-9",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29370 Plymouth Rd",
          "JobAddress2": "",
          "JobFullAddress": "29370 Plymouth Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Rajala & Sons Finishes",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$20,830.89",
          "BranchNumber": 7312,
          "SubmitDate": "07-29-2021",
          "ClientonJob": "Rajala & Sons Finishes"
        },
        {
          "WorkOrder": 4151347,
          "ParentWorkOrder": 4139432,
          "JobName": "Nof Market Express",
          "JobNumber": "7308-137191-9",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4773 Lake Michigan Dr",
          "JobAddress2": "",
          "JobFullAddress": "4773 Lake Michigan Dr",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Triad Interiors LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$28,777.80",
          "BranchNumber": 7308,
          "SubmitDate": "07-29-2021",
          "ClientonJob": "Triad Interiors LLC"
        },
        {
          "WorkOrder": 4151338,
          "ParentWorkOrder": 4134120,
          "JobName": "Curtis Row",
          "JobNumber": "7270-133987-25",
          "FirstdayontheJob": "06-01-2021",
          "LastdayontheJob": "06-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "921 Curtis St",
          "JobAddress2": "",
          "JobFullAddress": "921 Curtis St",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Triversity Construction Company, LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,059.46",
          "BranchNumber": 7270,
          "SubmitDate": "07-29-2021",
          "ClientonJob": "Triversity Construction Company, LLC"
        },
        {
          "WorkOrder": 4151321,
          "ParentWorkOrder": 4141262,
          "JobName": "TIRE DISCOUNTERS",
          "JobNumber": "7272-132739-10",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2214 HILLIARD ROME RD",
          "JobAddress2": "",
          "JobFullAddress": "2214 HILLIARD ROME RD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$168.34",
          "BranchNumber": 7272,
          "SubmitDate": "07-29-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4151294,
          "ParentWorkOrder": 4136857,
          "JobName": "Heartland Buildout Specialists",
          "JobNumber": "7270-103441-2",
          "FirstdayontheJob": "06-09-2021",
          "LastdayontheJob": "06-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1011 Baker Dr.",
          "JobAddress2": "",
          "JobFullAddress": "1011 Baker Dr.",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Heartland Buildout Specialists",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,009.50",
          "BranchNumber": 7270,
          "SubmitDate": "",
          "ClientonJob": "Heartland Buildout Specialists"
        },
        {
          "WorkOrder": 4150246,
          "ParentWorkOrder": 4145479,
          "JobName": "Nof Fairfield Inn",
          "JobNumber": "7302-133667-4",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5410 Milan Rd",
          "JobAddress2": "",
          "JobFullAddress": "5410 Milan Rd",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Schnippel Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$71,873.00",
          "BranchNumber": 7302,
          "SubmitDate": "07-26-2021",
          "ClientonJob": "Schnippel Construction"
        },
        {
          "WorkOrder": 4150245,
          "ParentWorkOrder": 4139406,
          "JobName": "Njp Xolo Tequila Bar",
          "JobNumber": "7304-105217-9",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "111 S Leroy St",
          "JobAddress2": "Ste 4LOX",
          "JobFullAddress": "111 S Leroy St Ste 4LOX",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Case Construction Company",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,863.25",
          "BranchNumber": 7304,
          "SubmitDate": "07-26-2021",
          "ClientonJob": "Case Construction Company"
        },
        {
          "WorkOrder": 4150024,
          "ParentWorkOrder": 4146478,
          "JobName": "Carbon Health",
          "JobNumber": "7272-132739-9",
          "FirstdayontheJob": "07-02-2021",
          "LastdayontheJob":"07-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1603 W Lane Ave",
          "JobAddress2": "",
          "JobFullAddress": "1603 W Lane Ave",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "N/A",
          "BranchNumber": 7272,
          "SubmitDate": "07-26-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4149392,
          "ParentWorkOrder": "",
          "JobName": "Springdale Commerce",
          "JobNumber": "7270-132755-13",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1145 Springdale Commerce",
          "JobAddress2": "",
          "JobFullAddress": "1145 Springdale Commerce",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T Square Wall Systems LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,755.20",
          "BranchNumber": 7270,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "T Square Wall Systems LLC"
        },
        {
          "WorkOrder": 4149391,
          "ParentWorkOrder": "",
          "JobName": "Kroger N-808",
          "JobNumber": "7272-132744-9",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1428 Marion Waldo Rd.",
          "JobAddress2": "",
          "JobFullAddress": "1428 Marion Waldo Rd.",
          "JobCounty": "MARION",
          "JobState": "Ohio",
          "CustomerCompanyName": "R&W Fixturing Co Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$14,074.80",
          "BranchNumber": 7272,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "R&W Fixturing Co Inc"
        },
        {
          "WorkOrder": 4149389,
          "ParentWorkOrder": "",
          "JobName": "Montgomery Quarter 2BC",
          "JobNumber": "7347-133958-8",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9280 Montgomery Rd",
          "JobAddress2": "",
          "JobFullAddress": "9280 Montgomery Rd",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Northside Supply",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$70,281.77",
          "BranchNumber": 7347,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Northside Supply"
        },
        {
          "WorkOrder": 4149388,
          "ParentWorkOrder": "",
          "JobName": "Kroger Store Cincinnati 344",
          "JobNumber": "7270-133989-4",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6725 Dick Fynn Blvd",
          "JobAddress2": "",
          "JobFullAddress": "6725 Dick Fynn Blvd",
          "JobCounty": "CLERMONT",
          "JobState": "Ohio",
          "CustomerCompanyName": "Bigner Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,646.89",
          "BranchNumber": 7270,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Bigner Construction"
        },
        {
          "WorkOrder": 4149387,
          "ParentWorkOrder": "",
          "JobName": "Nof Bp Warehouse",
          "JobNumber": "7302-104887-2",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4001 Cedar Point Rd",
          "JobAddress2": "",
          "JobFullAddress": "4001 Cedar Point Rd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "A A Boos & Sons Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,129.09",
          "BranchNumber": 7302,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "A A Boos & Sons Inc"
        },
        {
          "WorkOrder": 4149386,
          "ParentWorkOrder": "",
          "JobName": "GFS",
          "JobNumber": "7312-101312-75",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "PO Box 209",
          "JobAddress2": "",
          "JobFullAddress": "PO Box 209",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Advance Constr & Design Svs",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,347.50",
          "BranchNumber": 7312,
          "SubmitDate": "",
          "ClientonJob": "Advance Constr & Design Svs"
        },
        {
          "WorkOrder": 4149385,
          "ParentWorkOrder": "",
          "JobName": "Lee Contracting- Pure Roots",
          "JobNumber": "7313-102839-19",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5805 Beckley Rd",
          "JobAddress2": "",
          "JobFullAddress": "5805 Beckley Rd",
          "JobCounty": "Calhoun",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lee Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,478.80",
          "BranchNumber": 7313,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Lee Contracting"
        },
        {
          "WorkOrder": 4149384,
          "ParentWorkOrder": "",
          "JobName": "Nof Commercial Authority",
          "JobNumber": "7310-104761-27",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "580 S MANSFIELD ST",
          "JobAddress2": "",
          "JobFullAddress": "580 S MANSFIELD ST",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Token Acoustical Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,780.33",
          "BranchNumber": 7310,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Token Acoustical Inc"
        },
        {
          "WorkOrder": 4149383,
          "ParentWorkOrder": "",
          "JobName": "Aptiv",
          "JobNumber": "7313-104393-4",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5725 INNOVATION DR",
          "JobAddress2": "",
          "JobFullAddress": "5725 INNOVATION DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "National Business Supply",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,360.67",
          "BranchNumber": 7313,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "National Business Supply"
        },
        {
          "WorkOrder": 4149382,
          "ParentWorkOrder": "",
          "JobName": "Burlington",
          "JobNumber": "7308-133456-19",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5022 W Main St",
          "JobAddress2": "",
          "JobFullAddress": "5022 W Main St",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "East Coast Interiors Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$70,085.96",
          "BranchNumber": 7308,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "East Coast Interiors Inc"
        },
        {
          "WorkOrder": 4149381,
          "ParentWorkOrder": "",
          "JobName": "Wacker",
          "JobNumber": "7310-137349-3",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4950 S State St",
          "JobAddress2": "",
          "JobFullAddress": "4950 S State St",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Custom Architectural Sheet Metal Specialists",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$47,339.60",
          "BranchNumber": 7310,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Custom Architectural Sheet Metal Specialists"
        },
        {
          "WorkOrder": 4149380,
          "ParentWorkOrder": "",
          "JobName": "MCEDSV",
          "JobNumber": "7312-104179-6",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3893 Okemos Rd",
          "JobAddress2": "",
          "JobFullAddress": "3893 Okemos Rd",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Louis J Eyde Family LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,096.80",
          "BranchNumber": 7312,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Louis J Eyde Family LLC"
        },
        {
          "WorkOrder": 4149379,
          "ParentWorkOrder": "",
          "JobName": "Njp Meijer 208",
          "JobNumber": "7304-103058-29",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3710 Dix Highway",
          "JobAddress2": "",
          "JobFullAddress": "3710 Dix Highway",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Siwek Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,592.91",
          "BranchNumber": 7304,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Siwek Construction Co"
        },
        {
          "WorkOrder": 4149378,
          "ParentWorkOrder": "",
          "JobName": "River Oaks Apt. Bldg 27",
          "JobNumber": "7313-104675-4",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3630 Galloway Ct.",
          "JobAddress2": "East & West Side of Bldg 27",
          "JobFullAddress": "3630 Galloway Ct. East & West Side of Bldg 27",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Stony Creek Serv",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,787.48",
          "BranchNumber": 7313,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Stony Creek Serv"
        },
        {
          "WorkOrder": 4149377,
          "ParentWorkOrder": "",
          "JobName": "Richardson Elementary School",
          "JobNumber": "7304-104092-8",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3630 E. River Rd.",
          "JobAddress2": "",
          "JobFullAddress": "3630 E. River Rd.",
          "JobCounty": "IOSCO",
          "JobState": "Michigan",
          "CustomerCompanyName": "Serenus Johnson",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,501.09",
          "BranchNumber": 7304,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Serenus Johnson"
        },
        {
          "WorkOrder": 4149376,
          "ParentWorkOrder": "",
          "JobName": "Hungy Howies",
          "JobNumber": "7312-104335-10",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3415 E. Saginaw",
          "JobAddress2": "Lock Box 3080",
          "JobFullAddress": "3415 E. Saginaw Lock Box 3080",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "A4H Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,487.86",
          "BranchNumber": 7312,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "A4H Construction LLC"
        },
        {
          "WorkOrder": 4149375,
          "ParentWorkOrder": "",
          "JobName": "Nof Warren Consolidated",
          "JobNumber": "7310-101038-35",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31300 Anita Dr",
          "JobAddress2": "",
          "JobFullAddress": "31300 Anita Dr",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$33,598.27",
          "BranchNumber": 7310,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4149374,
          "ParentWorkOrder": "",
          "JobName": "First Baptist Church",
          "JobNumber": "7304-104335-11",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2400 King Road",
          "JobAddress2": "",
          "JobFullAddress": "2400 King Road",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "A4H Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,086.04",
          "BranchNumber": 7304,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "A4H Construction LLC"
        },
        {
          "WorkOrder": 4149372,
          "ParentWorkOrder": "",
          "JobName": "Multi Tenant",
          "JobNumber": "7308-104225-11",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2151 w columbia ave",
          "JobAddress2": "",
          "JobFullAddress": "2151 w columbia ave",
          "JobCounty": "Calhoun",
          "JobState": "Michigan",
          "CustomerCompanyName": "Mid Michigan Ceiling & Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,254.66",
          "BranchNumber": 7308,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Mid Michigan Ceiling & Drywall"
        },
        {
          "WorkOrder": 4149371,
          "ParentWorkOrder": "",
          "JobName": "Nof Ford Ddl W Simulator",
          "JobNumber": "7313-133905-7",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20600 Oakwood Blvd",
          "JobAddress2": "",
          "JobFullAddress": "20600 Oakwood Blvd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Ideal Contracting LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,458.28",
          "BranchNumber": 7313,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Ideal Contracting LLC"
        },
        {
          "WorkOrder": 4149370,
          "ParentWorkOrder": "",
          "JobName": "Kamps",
          "JobNumber": "7310-104761-31",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19111 Glendale St",
          "JobAddress2": "",
          "JobFullAddress": "19111 Glendale St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Token Acoustical Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,306.83",
          "BranchNumber": 7310,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Token Acoustical Inc"
        },
        {
          "WorkOrder": 4149369,
          "ParentWorkOrder": "",
          "JobName": "Uptown Square Apt.",
          "JobNumber": "7313-138225-2",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1300 Livernois Rd.",
          "JobAddress2": "Builging 8",
          "JobFullAddress": "1300 Livernois Rd. Builging 8",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Millenium Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,320.42",
          "BranchNumber": 7313,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Millenium Construction"
        },
        {
          "WorkOrder": 4149368,
          "ParentWorkOrder": "",
          "JobName": "Nof Bulk Buy",
          "JobNumber": "7422-104019-17",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 North Opdyke Rd Stec",
          "JobAddress2": "",
          "JobFullAddress": "1000 North Opdyke Rd Stec",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$56,236.31",
          "BranchNumber": 7422,
          "SubmitDate": "",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4149367,
          "ParentWorkOrder": "",
          "JobName": "Njp Ocm Central 20049",
          "JobNumber": "7310-101038-33",
          "FirstdayontheJob": "07-22-2021",
          "LastdayontheJob": "07-22-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "1 Woodward Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,910.89",
          "BranchNumber": 7310,
          "SubmitDate": "07-23-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4149180,
          "ParentWorkOrder": 4130586,
          "JobName": "(NOF) BOOK TOWER",
          "JobNumber": "502-1677788-2",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1265 WASHINGTON BLVD",
          "JobAddress2": "",
          "JobFullAddress": "1265 WASHINGTON BLVD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "WOLVERINE STONE COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$442.85",
          "BranchNumber": 9,
          "SubmitDate": "07-22-2021",
          "ClientonJob": "WOLVERINE STONE COMPANY"
        },
        {
          "WorkOrder": 4149179,
          "ParentWorkOrder": 4136856,
          "JobName": "(NOF) Dundee Place #3",
          "JobNumber": "502-1084306-7",
          "FirstdayontheJob": "06-09-2021",
          "LastdayontheJob": "06-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "140 Helle Blvd.",
          "JobAddress2": "",
          "JobFullAddress": "140 Helle Blvd.",
          "JobCounty": "Monroe",
          "JobState": "Michigan",
          "CustomerCompanyName": "T&B CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$2,098.36",
          "BranchNumber": 13,
          "SubmitDate": "07-22-2021",
          "ClientonJob": "T&B CONSTRUCTION"
        },
        {
          "WorkOrder": 4149175,
          "ParentWorkOrder": 4134574,
          "JobName": "(NOF)Grocer Major G",
          "JobNumber": "502-934634-23",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "662 E Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "662 E Big Beaver Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONSTRUCTION GROUP",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$7,504.80",
          "BranchNumber": 9,
          "SubmitDate": "07-22-2021",
          "ClientonJob": "BLUESTONE CONSTRUCTION GROUP"
        },
        {
          "WorkOrder": 4149172,
          "ParentWorkOrder": 4132566,
          "JobName": "(NOF)Ciennea of Hartford",
          "JobNumber": "502-1639418-39",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6700 W Outer Dr",
          "JobAddress2": "",
          "JobFullAddress": "6700 W Outer Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$1,640.24",
          "BranchNumber": 8,
          "SubmitDate": "07-22-2021",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4149131,
          "ParentWorkOrder": 4130591,
          "JobName": "(NOF)UNIT 51 WOODBRIDGE",
          "JobNumber": "502-847401-188",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1385 LINCOLN STREET",
          "JobAddress2": "",
          "JobFullAddress": "1385 LINCOLN STREET",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$3,129.68",
          "BranchNumber": 11,
          "SubmitDate": "07-22-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4149124,
          "ParentWorkOrder": 4130569,
          "JobName": "(NOF)SAGINAW VA",
          "JobNumber": "502-12540-34",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2830 MCCARTY RD.",
          "JobAddress2": "",
          "JobFullAddress": "2830 MCCARTY RD.",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "LUPE DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,469.19",
          "BranchNumber": 1,
          "SubmitDate": "07-22-2021",
          "ClientonJob": "LUPE DRYWALL"
        },
        {
          "WorkOrder": 4149074,
          "ParentWorkOrder": 4121972,
          "JobName": "(NOF) MSGCU",
          "JobNumber": "502-913820-597",
          "FirstdayontheJob": "04-20-2021",
          "LastdayontheJob": "04-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1941 S Telegraph Rd",
          "JobAddress2": "",
          "JobFullAddress": "1941 S Telegraph Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,000.00",
          "BalanceRemaining": "$20,126.06",
          "BranchNumber": 9,
          "SubmitDate": "07-22-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4149047,
          "ParentWorkOrder": 4128612,
          "JobName": "(NOF) Dollar General",
          "JobNumber": "502-16564-8",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8068 N Clio Rd",
          "JobAddress2": "",
          "JobFullAddress": "8068 N Clio Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARINE CITY CONTRACTING INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$5,577.89",
          "BranchNumber": 8,
          "SubmitDate": "07-22-2021",
          "ClientonJob": "MARINE CITY CONTRACTING INC"
        },
        {
          "WorkOrder": 4149041,
          "ParentWorkOrder": 4114923,
          "JobName": "(NOF)Evolution",
          "JobNumber": "502-815750-312",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 Town Center",
          "JobAddress2": "",
          "JobFullAddress": "1000 Town Center",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "07-22-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC."
        },
        {
          "WorkOrder": 4149023,
          "ParentWorkOrder": 4128613,
          "JobName": "(NOF)FERNDALE UPPER ELEM.",
          "JobNumber": "502-164068-4",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24220 ROSEWOOD ST",
          "JobAddress2": "",
          "JobFullAddress": "24220 ROSEWOOD ST",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PGC DEVELOPMENT, LLC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$1,037.99",
          "BranchNumber": 8,
          "SubmitDate": "07-22-2021",
          "ClientonJob": "PGC DEVELOPMENT, LLC."
        },
        {
          "WorkOrder": 4148374,
          "ParentWorkOrder": 4141258,
          "JobName": "Ehove Building A",
          "JobNumber": "7302-104913-4",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "316 Mason Road W",
          "JobAddress2": "",
          "JobFullAddress": "316 Mason Road W",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & B Acoustical",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "-$1,253.19",
          "BranchNumber": 7302,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "J & B Acoustical"
        },
        {
          "WorkOrder": 4148366,
          "ParentWorkOrder": 4132559,
          "JobName": "(NOF) United Shore Financ",
          "JobNumber": "502-814500-86",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "585 SOUTH BLVD E",
          "JobAddress2": "",
          "JobFullAddress": "585 SOUTH BLVD E",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,920.92",
          "BranchNumber": 8,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "COMMERCIAL INT CONSTRUCTION"
        },
        {
          "WorkOrder": 4148278,
          "ParentWorkOrder": 4143506,
          "JobName": "FBM Lansing",
          "JobNumber": "7312-100166-12",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5430 Enterprise Dr",
          "JobAddress2": "",
          "JobFullAddress": "5430 Enterprise Dr",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Foundation Building Materials",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,471.92",
          "BranchNumber": 7312,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Foundation Building Materials"
        },
        {
          "WorkOrder": 4148277,
          "ParentWorkOrder": 4143505,
          "JobName": "FBM Grand Rapids",
          "JobNumber": "7304-100166-25",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5203 Division Ave S",
          "JobAddress2": "",
          "JobFullAddress": "5203 Division Ave S",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Foundation Building Materials",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,414.05",
          "BranchNumber": 7304,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Foundation Building Materials"
        },
        {
          "WorkOrder": 4147942,
          "ParentWorkOrder": "",
          "JobName": "Njp Mps Admin Bldg",
          "JobNumber": "7304-104137-17",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "600 E Carpenter St",
          "JobAddress2": "",
          "JobFullAddress": "600 E Carpenter St",
          "JobCounty": "Midland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Wobig Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$71.60",
          "BranchNumber": 7304,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Wobig Construction"
        },
        {
          "WorkOrder": 4147941,
          "ParentWorkOrder": "",
          "JobName": "Njp Chick Fil A",
          "JobNumber": "7308-104851-16",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5607 Harvey St",
          "JobAddress2": "",
          "JobFullAddress": "5607 Harvey St",
          "JobCounty": "MUSKEGON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Level 5 Commercial Finish",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$997.67",
          "BranchNumber": 7308,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Level 5 Commercial Finish"
        },
        {
          "WorkOrder": 4147940,
          "ParentWorkOrder": "",
          "JobName": "Nof Boldt Company",
          "JobNumber": "7310-104761-23",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "51740 GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "51740 GRAND RIVER AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Token Acoustical Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,583.43",
          "BranchNumber": 7310,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Token Acoustical Inc"
        },
        {
          "WorkOrder": 4147939,
          "ParentWorkOrder": "",
          "JobName": "Nof Trailer Tech",
          "JobNumber": "7310-134589-3",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "48282 Frank St",
          "JobAddress2": "",
          "JobFullAddress": "48282 Frank St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Acoustech Ceiling Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,104.48",
          "BranchNumber": 7310,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Acoustech Ceiling Inc"
        },
        {
          "WorkOrder": 4147938,
          "ParentWorkOrder": "",
          "JobName": "Hutzel HOspital REc.",
          "JobNumber": "7313-104837-2",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4707 St. Antoine",
          "JobAddress2": "po#2021013835896-0-HA",
          "JobFullAddress": "4707 St. Antoine po#2021013835896-0-HA",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Detroit Medical Center",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$482.00",
          "BranchNumber": 7313,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Detroit Medical Center"
        },
        {
          "WorkOrder": 4147937,
          "ParentWorkOrder": "",
          "JobName": "Ionia Middle School",
          "JobNumber": "7308-105113-25",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "438 Union st",
          "JobAddress2": "",
          "JobFullAddress": "438 Union st",
          "JobCounty": "IONIA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Phoenix Interiors LLC",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,233.64",
          "BranchNumber": 7308,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Phoenix Interiors LLC"
        },
        {
          "WorkOrder": 4147936,
          "ParentWorkOrder": "",
          "JobName": "Njp Moran Chrysler Dodge",
          "JobNumber": "7422-104447-4",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4080 24th Ave",
          "JobAddress2": "",
          "JobFullAddress": "4080 24th Ave",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "DU Aull Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,368.65",
          "BranchNumber": 7422,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "DU Aull Construction"
        },
        {
          "WorkOrder": 4147935,
          "ParentWorkOrder": "",
          "JobName": "Nof Stillwater Harvest",
          "JobNumber": "7422-105016-5",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10260 Harvest Park Ste A",
          "JobAddress2": "",
          "JobFullAddress": "10260 Harvest Park Ste A",
          "JobCounty": "Eaton",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elite Builders LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,437.70",
          "BranchNumber": 7422,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Elite Builders LLC"
        },
        {
          "WorkOrder": 4147934,
          "ParentWorkOrder": "",
          "JobName": "ARMANOS CANCER INSTITUTE",
          "JobNumber": "7310-134461-16",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3990 JOHN R ST",
          "JobAddress2": "",
          "JobFullAddress": "3990 JOHN R ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Interstate Restoration",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,499.54",
          "BranchNumber": 7310,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Interstate Restoration"
        },
        {
          "WorkOrder": 4147933,
          "ParentWorkOrder": "",
          "JobName": "Speedway Livonia",
          "JobNumber": "7310-135996-3",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "37416 7 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "37416 7 MILE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Whitaker Acoustics",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,243.47",
          "BranchNumber": 7310,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Whitaker Acoustics"
        },
        {
          "WorkOrder": 4147932,
          "ParentWorkOrder": "",
          "JobName": "OCTAFARMA",
          "JobNumber": "7310-102667-6",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35363 FORD RD",
          "JobAddress2": "",
          "JobFullAddress": "35363 FORD RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Sterling Millwork Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,687.54",
          "BranchNumber": 7310,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Sterling Millwork Inc"
        },
        {
          "WorkOrder": 4147931,
          "ParentWorkOrder": "",
          "JobName": "Njp Astral",
          "JobNumber": "7308-101361-21",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3509 3 Mile Rd NW",
          "JobAddress2": "Ste 3",
          "JobFullAddress": "3509 3 Mile Rd NW Ste 3",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Angstrom Technology Ltd",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$29,726.26",
          "BranchNumber": 7308,
          "SubmitDate": "",
          "ClientonJob": "Angstrom Technology Ltd"
        },
        {
          "WorkOrder": 4147930,
          "ParentWorkOrder": "",
          "JobName": "Premier Martial Arts",
          "JobNumber": "7313-104450-28",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30955 Woodward Ave.",
          "JobAddress2": "Suite 220",
          "JobFullAddress": "30955 Woodward Ave. Suite 220",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Hudson Interiors",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,717.65",
          "BranchNumber": 7313,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Hudson Interiors"
        },
        {
          "WorkOrder": 4147929,
          "ParentWorkOrder": "",
          "JobName": "Eaton",
          "JobNumber": "7312-104816-9",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2425 W MICHIGAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "2425 W MICHIGAN AVE",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "R W Mercer",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,233.15",
          "BranchNumber": 7312,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "R W Mercer"
        },
        {
          "WorkOrder": 4147928,
          "ParentWorkOrder": "",
          "JobName": "Edda Marie Court",
          "JobNumber": "7304-104052-19",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "238 Edda Marie Court",
          "JobAddress2": "",
          "JobFullAddress": "238 Edda Marie Court",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "A S D Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$4,395.24",
          "BranchNumber": 7304,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "A S D Drywall"
        },
        {
          "WorkOrder": 4147927,
          "ParentWorkOrder": "",
          "JobName": "Eaton Community Bank",
          "JobNumber": "7312-104842-15",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "236 S COCHRAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "236 S COCHRAN AVE",
          "JobCounty": "EATON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Rajala & Sons Finishes",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,085.37",
          "BranchNumber": 7312,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Rajala & Sons Finishes"
        },
        {
          "WorkOrder": 4147926,
          "ParentWorkOrder": "",
          "JobName": "Nof 20 5151 Village Oaks",
          "JobNumber": "7310-104657-40",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23333 Willowbrook",
          "JobAddress2": "",
          "JobFullAddress": "23333 Willowbrook",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,074.24",
          "BranchNumber": 7310,
          "SubmitDate": "",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4147925,
          "ParentWorkOrder": "",
          "JobName": "TACO BELL SPRINKLE ROAD",
          "JobNumber": "7308-104225-10",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2229 S SPRINKLE ROAD",
          "JobAddress2": "WOLVERINE BUILDING GROUP",
          "JobFullAddress": "2229 S SPRINKLE ROAD WOLVERINE BUILDING GROUP",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "Mid Michigan Ceiling & Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$4,320.96",
          "BranchNumber": 7308,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Mid Michigan Ceiling & Drywall"
        },
        {
          "WorkOrder": 4147924,
          "ParentWorkOrder": "",
          "JobName": "Shoot Point Blank",
          "JobNumber": "7310-104562-8",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21420 Hall Rd",
          "JobAddress2": "",
          "JobFullAddress": "21420 Hall Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,355.90",
          "BranchNumber": 7310,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 4147923,
          "ParentWorkOrder": "",
          "JobName": "Nof Groves Maint Bldg",
          "JobNumber": "7313-104368-19",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20500 W 13 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "20500 W 13 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elkhorn Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$729.28",
          "BranchNumber": 7313,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Elkhorn Construction"
        },
        {
          "WorkOrder": 4147922,
          "ParentWorkOrder": "",
          "JobName": "LaDuke Construction",
          "JobNumber": "7422-104499-10",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1776 Lakeshore Rd",
          "JobAddress2": "",
          "JobFullAddress": "1776 Lakeshore Rd",
          "JobCounty": "SANILAC",
          "JobState": "Michigan",
          "CustomerCompanyName": "Picklehaupt Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,426.32",
          "BranchNumber": 7422,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "Picklehaupt Drywall"
        },
        {
          "WorkOrder": 4147921,
          "ParentWorkOrder": "",
          "JobName": "Culvers Muskegon",
          "JobNumber": "7308-104198-32",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1776 Independence Dr",
          "JobAddress2": "",
          "JobFullAddress": "1776 Independence Dr",
          "JobCounty": "MUSKEGON",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,754.90",
          "BranchNumber": 7308,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4147920,
          "ParentWorkOrder": "",
          "JobName": "Kroger Flushing",
          "JobNumber": "7313-101358-12",
          "FirstdayontheJob": "07-19-2021",
          "LastdayontheJob": "07-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1542 E Pierson Rd",
          "JobAddress2": "",
          "JobFullAddress": "1542 E Pierson Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC Construction Co Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,401.82",
          "BranchNumber": 7313,
          "SubmitDate": "07-20-2021",
          "ClientonJob": "AC Construction Co Inc"
        },
        {
          "WorkOrder": 4147906,
          "ParentWorkOrder": 4143515,
          "JobName": "GERMAIN VOLKSWAGEN",
          "JobNumber": "7272-132739-12",
          "FirstdayontheJob": "07-02-2021",
          "LastdayontheJob": "07-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1495 AUTO MALL DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "1495 AUTO MALL DRIVE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,234.93",
          "BranchNumber": 7272,
          "SubmitDate": "07-19-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4147905,
          "ParentWorkOrder": 4135544,
          "JobName": "(NOF) Dunkin Donuts",
          "JobNumber": "502-1304186-75",
          "FirstdayontheJob": "06-06-2021",
          "LastdayontheJob": "06-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2251 West State Street",
          "JobAddress2": "",
          "JobFullAddress": "2251 West State Street",
          "JobCounty": "SANDUSKY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,659.57",
          "BranchNumber": 13,
          "SubmitDate": "07-19-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4147904,
          "ParentWorkOrder": 4135545,
          "JobName": "(NOF) FAIRFIELD INN",
          "JobNumber": "502-1308136-5",
          "FirstdayontheJob": "06-06-2021",
          "LastdayontheJob": "06-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5410 MILAN RD",
          "JobAddress2": "",
          "JobFullAddress": "5410 MILAN RD",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Schnippel Construction, Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$125,000.00",
          "BalanceRemaining": "$42,810.97",
          "BranchNumber": 13,
          "SubmitDate": "07-19-2021",
          "ClientonJob": "Schnippel Construction, Inc"
        },
        {
          "WorkOrder": 4147850,
          "ParentWorkOrder": 4141139,
          "JobName": "Nof Bulk Buy Warehouse",
          "JobNumber": "7422-104019-17",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 North Opdyke Rd Ste C",
          "JobAddress2": "",
          "JobFullAddress": "1000 North Opdyke Rd Ste C",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,566.50",
          "BranchNumber": 7422,
          "SubmitDate": "07-19-2021",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4147782,
          "ParentWorkOrder": 4134582,
          "JobName": "(NOF)CLEAN JUICE",
          "JobNumber": "502-1551310-13",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "641 E GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "641 E GRAND RIVER AVE",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELITE BUILDERS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$7,335.34",
          "BranchNumber": 11,
          "SubmitDate": "07-19-2021",
          "ClientonJob": "ELITE BUILDERS LLC"
        },
        {
          "WorkOrder": 4147532,
          "ParentWorkOrder": "",
          "JobName": "Age of Counseling",
          "JobNumber": "7270-132759-4",
          "FirstdayontheJob": "07-16-2021",
          "LastdayontheJob": "07-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4601 Malsbary Rd",
          "JobAddress2": "",
          "JobFullAddress": "4601 Malsbary Rd",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Reliable Finish LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,226.06",
          "BranchNumber": 7270,
          "SubmitDate": "07-17-2021",
          "ClientonJob": "Reliable Finish LLC"
        },
        {
          "WorkOrder": 4147531,
          "ParentWorkOrder": "",
          "JobName": "Nof Stadium View Apts",
          "JobNumber": "7302-104918-3",
          "FirstdayontheJob": "07-16-2021",
          "LastdayontheJob": "07-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1700 Juniper Dr",
          "JobAddress2": "",
          "JobFullAddress": "1700 Juniper Dr",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "Jet Drywall",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,487.35",
          "BranchNumber": 7302,
          "SubmitDate": "07-17-2021",
          "ClientonJob": "Jet Drywall"
        },
        {
          "WorkOrder": 4147530,
          "ParentWorkOrder": "",
          "JobName": "Life Connection",
          "JobNumber": "7348-132748-15",
          "FirstdayontheJob": "07-16-2021",
          "LastdayontheJob": "07-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 Donation Circle",
          "JobAddress2": "",
          "JobFullAddress": "2800 Donation Circle",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Combs Interior Specialties Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$489.23",
          "BranchNumber": 7348,
          "SubmitDate": "07-17-2021",
          "ClientonJob": "Combs Interior Specialties Inc"
        },
        {
          "WorkOrder": 4146478,
          "ParentWorkOrder": "",
          "JobName": "Carbon Health",
          "JobNumber": "7272-132739-9",
          "FirstdayontheJob": "07-02-2021",
          "LastdayontheJob": "07-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1603 W Lane Ave",
          "JobAddress2": "",
          "JobFullAddress": "1603 W Lane Ave",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "N/A",
          "BranchNumber": 7272,
          "SubmitDate": "07-13-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4145480,
          "ParentWorkOrder": "",
          "JobName": "CALVARY BAPTIST CHURCH",
          "JobNumber": "7348-107430-3",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14 DORSEY AKERS DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "14 DORSEY AKERS DRIVE",
          "JobCounty": "PREBLE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Western Ohio Drywall Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,315.90",
          "BranchNumber": 7348,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Western Ohio Drywall Inc"
        },
        {
          "WorkOrder": 4145479,
          "ParentWorkOrder": "",
          "JobName": "Nof Fairfield Inn",
          "JobNumber": "7302-133667-4",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5410 Milan Rd",
          "JobAddress2": "",
          "JobFullAddress": "5410 Milan Rd",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Schnippel Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$71,873.00",
          "BranchNumber": 7302,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Schnippel Construction"
        },
        {
          "WorkOrder": 4145478,
          "ParentWorkOrder": "",
          "JobName": "The Approach",
          "JobNumber": "7347-100959-13",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10220 Gateway Pl",
          "JobAddress2": "",
          "JobFullAddress": "10220 Gateway Pl",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Morningside Group Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$27,938.49",
          "BranchNumber": 7347,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Morningside Group Inc"
        },
        {
          "WorkOrder": 4145477,
          "ParentWorkOrder": "",
          "JobName": "J & A Drywall Inc",
          "JobNumber": "7348-131617-2",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5875 Dixie Highway",
          "JobAddress2": "Panera Bread #4929",
          "JobFullAddress": "5875 Dixie Highway Panera Bread #4929",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & A Drywall Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,482.17",
          "BranchNumber": 7348,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "J & A Drywall Inc"
        },
        {
          "WorkOrder": 4145476,
          "ParentWorkOrder": "",
          "JobName": "Midwest Shooting Center",
          "JobNumber": "7302-103589-2",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7638 SYLVANIA AVE",
          "JobAddress2": "",
          "JobFullAddress": "7638 SYLVANIA AVE",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Gehring & Burtchin Cont Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$762.20",
          "BranchNumber": 7302,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Gehring & Burtchin Cont Inc"
        },
        {
          "WorkOrder": 4145475,
          "ParentWorkOrder": "",
          "JobName": "Mercy Mission House",
          "JobNumber": "7348-132774-9",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "950 Childrens Home Rd",
          "JobAddress2": "",
          "JobFullAddress": "950 Childrens Home Rd",
          "JobCounty": "SHELBY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Gane Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,359.05",
          "BranchNumber": 7348,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Gane Construction"
        },
        {
          "WorkOrder": 4145474,
          "ParentWorkOrder": "",
          "JobName": "MEIJERS STORE # 181  JOB 21-25",
          "JobNumber": "7272-100934-7",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 POLARIS PKWY",
          "JobAddress2": "",
          "JobFullAddress": "100 POLARIS PKWY",
          "JobCounty": "DELAWARE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Big Sky Services Company LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,128.28",
          "BranchNumber": 7272,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Big Sky Services Company LLC"
        },
        {
          "WorkOrder": 4145212,
          "ParentWorkOrder": "",
          "JobName": "Sunoco Gas Station",
          "JobNumber": "7304-137117-13",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1607 S Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "1607 S Michigan Ave",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lupe Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$188.69",
          "BranchNumber": 7304,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Lupe Drywall"
        },
        {
          "WorkOrder": 4145211,
          "ParentWorkOrder": "",
          "JobName": "Nof Richland Elementary",
          "JobNumber": "7308-104969-7",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9476 M 89",
          "JobAddress2": "",
          "JobFullAddress": "9476 M 89",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "Premier 1 Interiors LLC",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$12,859.00",
          "BranchNumber": 7308,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Premier 1 Interiors LLC"
        },
        {
          "WorkOrder": 4145210,
          "ParentWorkOrder": "",
          "JobName": "Dominos Pizza Clarkston",
          "JobNumber": "7304-102934-17",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7081 Dixie Highway",
          "JobAddress2": "Job#21056",
          "JobFullAddress": "7081 Dixie Highway Job#21056",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Constr",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,201.14",
          "BranchNumber": 7304,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Great Lakes Bay Constr"
        },
        {
          "WorkOrder": 4145209,
          "ParentWorkOrder": "",
          "JobName": "Mid-Michigan Medical/Clare Hos",
          "JobNumber": "7304-104189-13",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "703 N. McEwan St",
          "JobAddress2": "",
          "JobFullAddress": "703 N. McEwan St",
          "JobCounty": "CLARE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Three Rivers Corporation",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$832.18",
          "BranchNumber": 7304,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Three Rivers Corporation"
        },
        {
          "WorkOrder": 4145208,
          "ParentWorkOrder": "",
          "JobName": "Fairfeild Inn & Suites",
          "JobNumber": "7308-104546-54",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "620 Center Dr NW",
          "JobAddress2": "",
          "JobFullAddress": "620 Center Dr NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$525.36",
          "BranchNumber": 7308,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "SC Construction"
        },
        {
          "WorkOrder": 4145207,
          "ParentWorkOrder": "",
          "JobName": "Njp Aarons Rental",
          "JobNumber": "7304-103184-4",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5570 Bay Rd",
          "JobAddress2": "",
          "JobFullAddress": "5570 Bay Rd",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Advanced Systems Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$557.79",
          "BranchNumber": 7304,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Advanced Systems Inc"
        },
        {
          "WorkOrder": 4145206,
          "ParentWorkOrder": "",
          "JobName": "Blu Pearl",
          "JobNumber": "7308-104228-8",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4925 Michigan ave",
          "JobAddress2": "",
          "JobFullAddress": "4925 Michigan ave",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Newco Design Build",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$82.60",
          "BranchNumber": 7308,
          "SubmitDate": "",
          "ClientonJob": "Newco Design Build"
        },
        {
          "WorkOrder": 4145205,
          "ParentWorkOrder": "",
          "JobName": "Town Center Villas",
          "JobNumber": "7422-104019-52",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45620 Harvard Dr",
          "JobAddress2": "",
          "JobFullAddress": "45620 Harvard Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$21,518.00",
          "BranchNumber": 7422,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4145204,
          "ParentWorkOrder": "",
          "JobName": "Nof Ash *",
          "JobNumber": "7313-137383-11",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4065 Gold Ridge Dr E",
          "JobAddress2": "",
          "JobFullAddress": "4065 Gold Ridge Dr E",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Distinctive Grading & Design LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,845.30",
          "BranchNumber": 7313,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Distinctive Grading & Design LLC"
        },
        {
          "WorkOrder": 4145203,
          "ParentWorkOrder": "",
          "JobName": "Nof Meijer 27",
          "JobNumber": "7312-103058-42",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3825 Carpenter Rd",
          "JobAddress2": "",
          "JobFullAddress": "3825 Carpenter Rd",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Siwek Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,590.95",
          "BranchNumber": 7312,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Siwek Construction Co"
        },
        {
          "WorkOrder": 4145202,
          "ParentWorkOrder": "",
          "JobName": "Meijer #208",
          "JobNumber": "7304-103058-44",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3710 Dix Highway",
          "JobAddress2": "",
          "JobFullAddress": "3710 Dix Highway",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Siwek Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,859.15",
          "BranchNumber": 7304,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Siwek Construction Co"
        },
        {
          "WorkOrder": 4145201,
          "ParentWorkOrder": "",
          "JobName": "Njp Grace Church",
          "JobNumber": "7308-104328-54",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3500 New Holland St",
          "JobAddress2": "",
          "JobFullAddress": "3500 New Holland St",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Intext Concepts Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,666.98",
          "BranchNumber": 7308,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Intext Concepts Inc"
        },
        {
          "WorkOrder": 4145200,
          "ParentWorkOrder": "",
          "JobName": "CHIC FIL A",
          "JobNumber": "7308-104851-36",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "320 WILSON AVE NW",
          "JobAddress2": "",
          "JobFullAddress": "320 WILSON AVE NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Level 5 Commercial Finish",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$228.35",
          "BranchNumber": 7308,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Level 5 Commercial Finish"
        },
        {
          "WorkOrder": 4145199,
          "ParentWorkOrder": "",
          "JobName": "West Pharma",
          "JobNumber": "7308-105113-24",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3116 N Wilson Ct NW",
          "JobAddress2": "",
          "JobFullAddress": "3116 N Wilson Ct NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Phoenix Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,353.80",
          "BranchNumber": 7308,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Phoenix Interiors LLC"
        },
        {
          "WorkOrder": 4145198,
          "ParentWorkOrder": "",
          "JobName": "Nof Ferndale Early Chld",
          "JobNumber": "7313-137156-5",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2920 Burdette St",
          "JobAddress2": "",
          "JobFullAddress": "2920 Burdette St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PGC Development",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,637.54",
          "BranchNumber": 7313,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "PGC Development"
        },
        {
          "WorkOrder": 4145197,
          "ParentWorkOrder": "",
          "JobName": "Nof Rair Lowell",
          "JobNumber": "7308-100963-54",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2264 W Main St",
          "JobAddress2": "",
          "JobFullAddress": "2264 W Main St",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,573.16",
          "BranchNumber": 7308,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4145196,
          "ParentWorkOrder": "",
          "JobName": "Gleaners",
          "JobNumber": "7313-104677-11",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21405 trolly industrial dr",
          "JobAddress2": "",
          "JobFullAddress": "21405 trolly industrial dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TLA Interiors Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7.21",
          "BranchNumber": 7313,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "TLA Interiors Inc"
        },
        {
          "WorkOrder": 4145195,
          "ParentWorkOrder": "",
          "JobName": "Flex Tech",
          "JobNumber": "7310-104761-28",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17901 Woodland Dr",
          "JobAddress2": "Ste 1000",
          "JobFullAddress": "17901 Woodland Dr Ste 1000",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Token Acoustical Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,425.51",
          "BranchNumber": 7310,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Token Acoustical Inc"
        },
        {
          "WorkOrder": 4145194,
          "ParentWorkOrder": "",
          "JobName": "Krogers",
          "JobNumber": "7313-101358-10",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1542 E Pierson Road",
          "JobAddress2": "",
          "JobFullAddress": "1542 E Pierson Road",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC Construction Co Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,191.65",
          "BranchNumber": 7313,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "AC Construction Co Inc"
        },
        {
          "WorkOrder": 4145193,
          "ParentWorkOrder": "",
          "JobName": "Nof Jersey Mikes Davison",
          "JobNumber": "7313-104450-25",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1053 N Irish Rd",
          "JobAddress2": "",
          "JobFullAddress": "1053 N Irish Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Hudson Interiors",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,163.12",
          "BranchNumber": 7313,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Hudson Interiors"
        },
        {
          "WorkOrder": 4145192,
          "ParentWorkOrder": "",
          "JobName": "10034 Chris Morgan",
          "JobNumber": "7422-104151-86",
          "FirstdayontheJob": "07-08-2021",
          "LastdayontheJob": "07-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10034 Ludlow Ave",
          "JobAddress2": "",
          "JobFullAddress": "10034 Ludlow Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Savyer Drywall LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,150.95",
          "BranchNumber": 7422,
          "SubmitDate": "07-09-2021",
          "ClientonJob": "Savyer Drywall LLC"
        },
        {
          "WorkOrder": 4145182,
          "ParentWorkOrder": 4134563,
          "JobName": "(NOF)Szott Dealership",
          "JobNumber": "502-817800-56",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "57495 W Lyon Center Dr.",
          "JobAddress2": "",
          "JobFullAddress": "57495 W Lyon Center Dr.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "07-08-2021",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4143515,
          "ParentWorkOrder": "",
          "JobName": "GERMAIN VOLKSWAGEN",
          "JobNumber": "7272-132739-12",
          "FirstdayontheJob": "07-02-2021",
          "LastdayontheJob": "07-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1495 AUTO MALL DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "1495 AUTO MALL DRIVE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,234.93",
          "BranchNumber": 7272,
          "SubmitDate": "07-03-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4143514,
          "ParentWorkOrder": "",
          "JobName": "FAMILY DOLLAR",
          "JobNumber": "7270-101084-8",
          "FirstdayontheJob": "07-02-2021",
          "LastdayontheJob": "07-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7724 State Route 139",
          "JobAddress2": "",
          "JobFullAddress": "7724 State Route 139",
          "JobCounty": "SCIOTO",
          "JobState": "Ohio",
          "CustomerCompanyName": "C & S Drywall Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$511.79",
          "BranchNumber": 7270,
          "SubmitDate": "07-03-2021",
          "ClientonJob": "C & S Drywall Inc"
        },
        {
          "WorkOrder": 4143513,
          "ParentWorkOrder": "",
          "JobName": "Nof Chilson Urgent Care",
          "JobNumber": "7313-100963-83",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9551 Chilson Cmns",
          "JobAddress2": "",
          "JobFullAddress": "9551 Chilson Cmns",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$23,167.16",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4143512,
          "ParentWorkOrder": "",
          "JobName": "Nof Delta Project",
          "JobNumber": "7312-100963-69",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "775 E Washington Ave",
          "JobAddress2": "",
          "JobFullAddress": "775 E Washington Ave",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$51,051.80",
          "BranchNumber": 7312,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4143511,
          "ParentWorkOrder": "",
          "JobName": "CULVERS SOUTH HAVEN",
          "JobNumber": "7308-100963-90",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "72219 CO RD 388",
          "JobAddress2": "",
          "JobFullAddress": "72219 CO RD 388",
          "JobCounty": "VAN BUREN",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,258.29",
          "BranchNumber": 7308,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4143510,
          "ParentWorkOrder": "",
          "JobName": "Nof Brother Rice",
          "JobNumber": "7313-104360-6",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7101 Lasher Rd",
          "JobAddress2": "",
          "JobFullAddress": "7101 Lasher Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Devange Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,558.32",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Devange Construction"
        },
        {
          "WorkOrder": 4143509,
          "ParentWorkOrder": "",
          "JobName": "Dominos Pizza Clarkston",
          "JobNumber": "7304-102934-17",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7081 Dixie Highway",
          "JobAddress2": "",
          "JobFullAddress": "7081 Dixie Highway",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Constr",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,201.14",
          "BranchNumber": 7304,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Great Lakes Bay Constr"
        },
        {
          "WorkOrder": 4143508,
          "ParentWorkOrder": "",
          "JobName": "Heartland Schools",
          "JobNumber": "7310-134589-31",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "687 Taylor Rd",
          "JobAddress2": "",
          "JobFullAddress": "687 Taylor Rd",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Acoustech Ceiling Inc",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$970.79",
          "BranchNumber": 7310,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Acoustech Ceiling Inc"
        },
        {
          "WorkOrder": 4143507,
          "ParentWorkOrder": "",
          "JobName": "Nof Clean Juice",
          "JobNumber": "7312-105016-8",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "641 E Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "641 E Grand River Ave",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elite Builders LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,687.50",
          "BranchNumber": 7312,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Elite Builders LLC"
        },
        {
          "WorkOrder": 4143506,
          "ParentWorkOrder": "",
          "JobName": "FBM Lansing",
          "JobNumber": "7312-100166-12",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5430 Enterprise Dr",
          "JobAddress2": "",
          "JobFullAddress": "5430 Enterprise Dr",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Foundation Building Materials",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,471.92",
          "BranchNumber": 7312,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Foundation Building Materials"
        },
        {
          "WorkOrder": 4143505,
          "ParentWorkOrder": "",
          "JobName": "FBM Grand Rapids",
          "JobNumber": "7304-100166-25",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5203 Division Ave S",
          "JobAddress2": "",
          "JobFullAddress": "5203 Division Ave S",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Foundation Building Materials",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,414.05",
          "BranchNumber": 7304,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Foundation Building Materials"
        },
        {
          "WorkOrder": 4143504,
          "ParentWorkOrder": "",
          "JobName": "Nof Ally Financial",
          "JobNumber": "7310-101038-37",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "500 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "500 Woodward Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,113.80",
          "BranchNumber": 7310,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4143503,
          "ParentWorkOrder": "",
          "JobName": "Nof Culvers Canton",
          "JobNumber": "7313-100963-71",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45275 Ford Rd",
          "JobAddress2": "",
          "JobFullAddress": "45275 Ford Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$28,064.11",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4143502,
          "ParentWorkOrder": "",
          "JobName": "Nof McDonalds Detroit",
          "JobNumber": "7313-100963-84",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4235 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "4235 Woodward Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,621.97",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4143501,
          "ParentWorkOrder": "",
          "JobName": "Njp Fairfield Inn",
          "JobNumber": "7313-104019-30",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3900 S Rochester Rd",
          "JobAddress2": "",
          "JobFullAddress": "3900 S Rochester Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,917.44",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4143500,
          "ParentWorkOrder": "",
          "JobName": "Meijer #208",
          "JobNumber": "7304-103058-44",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3710 Dix Highway",
          "JobAddress2": "",
          "JobFullAddress": "3710 Dix Highway",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Siwek Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,859.15",
          "BranchNumber": 7304,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Siwek Construction Co"
        },
        {
          "WorkOrder": 4143499,
          "ParentWorkOrder": "",
          "JobName": "Crestant Management",
          "JobNumber": "7310-104761-30",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35211 Ford Rd",
          "JobAddress2": "",
          "JobFullAddress": "35211 Ford Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Token Acoustical Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,932.97",
          "BranchNumber": 7310,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Token Acoustical Inc"
        },
        {
          "WorkOrder": 4143498,
          "ParentWorkOrder": "",
          "JobName": "Njp Fountain Cir Ah",
          "JobNumber": "7313-104019-29",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3505 Auburn Rd",
          "JobAddress2": "",
          "JobFullAddress": "3505 Auburn Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,183.94",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4143497,
          "ParentWorkOrder": "",
          "JobName": "Nof Berkley Anderson Ms",
          "JobNumber": "7313-104657-45",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3205 Catalpa Dr",
          "JobAddress2": "",
          "JobFullAddress": "3205 Catalpa Dr",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,562.08",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4143496,
          "ParentWorkOrder": "",
          "JobName": "Total Wine Store",
          "JobNumber": "7313-136494-14",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3140 Lohr Rd",
          "JobAddress2": "",
          "JobFullAddress": "3140 Lohr Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Marine City Contracting Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,427.51",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Marine City Contracting Inc"
        },
        {
          "WorkOrder": 4143495,
          "ParentWorkOrder": "",
          "JobName": "Njp Bwca Bw Comm Action",
          "JobNumber": "7422-104338-2",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "302 MICHIGAN ST",
          "JobAddress2": "",
          "JobFullAddress": "302 MICHIGAN ST",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Quality Handyman Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,026.88",
          "BranchNumber": 7422,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Quality Handyman Services"
        },
        {
          "WorkOrder": 4143494,
          "ParentWorkOrder": "",
          "JobName": "Manheim",
          "JobNumber": "7310-104761-29",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29500 Gateway DR",
          "JobAddress2": "",
          "JobFullAddress": "29500 Gateway DR",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Token Acoustical Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,429.46",
          "BranchNumber": 7310,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Token Acoustical Inc"
        },
        {
          "WorkOrder": 4143493,
          "ParentWorkOrder": "",
          "JobName": "Avondale High School",
          "JobNumber": "7310-101038-38",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 Waujegan St",
          "JobAddress2": "",
          "JobFullAddress": "2800 Waujegan St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,722.68",
          "BranchNumber": 7310,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4143492,
          "ParentWorkOrder": "",
          "JobName": "Roland Frey",
          "JobNumber": "7313-137383-26",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 S State St",
          "JobAddress2": "",
          "JobFullAddress": "2800 S State St",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Distinctive Grading & Design LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,074.32",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Distinctive Grading & Design LLC"
        },
        {
          "WorkOrder": 4143491,
          "ParentWorkOrder": "",
          "JobName": "OAKLAND CHISTIAN",
          "JobNumber": "7313-104420-3",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3075 Shimmons Rd",
          "JobAddress2": "",
          "JobFullAddress": "3075 Shimmons Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Usztan Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$349.36",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Usztan Construction"
        },
        {
          "WorkOrder": 4143490,
          "ParentWorkOrder": "",
          "JobName": "ITW DRAWFORM",
          "JobNumber": "7308-104198-31",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2505 VAN OMMEN DR",
          "JobAddress2": "",
          "JobFullAddress": "2505 VAN OMMEN DR",
          "JobCounty": "Ottawa",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$20,401.67",
          "BranchNumber": 7308,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4143489,
          "ParentWorkOrder": "",
          "JobName": "Nof Target Ann Arbor",
          "JobNumber": "7313-102275-11",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "233 S State St",
          "JobAddress2": "",
          "JobFullAddress": "233 S State St",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Woods Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,536.36",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Woods Construction Inc"
        },
        {
          "WorkOrder": 4143488,
          "ParentWorkOrder": "",
          "JobName": "FBM Addison IL",
          "JobNumber": "7304-100166-7",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2301 W Windsor Ct",
          "JobAddress2": "Unit B",
          "JobFullAddress": "2301 W Windsor Ct Unit B",
          "JobCounty": "",
          "JobState": "Illinois",
          "CustomerCompanyName": "Foundation Building Materials",
          "RequestType": "Subcontractor's Notice of Intention to File Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$979.95",
          "BranchNumber": 7304,
          "SubmitDate": "",
          "ClientonJob": "Foundation Building Materials"
        },
        {
          "WorkOrder": 4143487,
          "ParentWorkOrder": "",
          "JobName": "Nof Gerstacker",
          "JobNumber": "7310-102953-36",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2200 Bonisteel Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2200 Bonisteel Blvd",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Contracting",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,002.60",
          "BranchNumber": 7310,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Commercial Contracting"
        },
        {
          "WorkOrder": 4143486,
          "ParentWorkOrder": "",
          "JobName": "Cookie Crumble Novi",
          "JobNumber": "7313-100963-88",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20620 Ingersol Dr",
          "JobAddress2": "",
          "JobFullAddress": "20620 Ingersol Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commcl Constr Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,537.52",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Independence Commcl Constr Inc"
        },
        {
          "WorkOrder": 4143485,
          "ParentWorkOrder": "",
          "JobName": "Nof Schoolcraft College",
          "JobNumber": "7310-101038-24",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18600 Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "18600 Haggerty Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$59,314.22",
          "BranchNumber": 7310,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4143484,
          "ParentWorkOrder": "",
          "JobName": "Flex Tech",
          "JobNumber": "7310-104761-28",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17901 Woodland Dr",
          "JobAddress2": "Ste 1000",
          "JobFullAddress": "17901 Woodland Dr Ste 1000",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Token Acoustical Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,425.51",
          "BranchNumber": 7310,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Token Acoustical Inc"
        },
        {
          "WorkOrder": 4143483,
          "ParentWorkOrder": "",
          "JobName": "Multi Drywall & Partition LLC",
          "JobNumber": "7313-104643-20",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1624  Kilburn Rd N",
          "JobAddress2": "",
          "JobFullAddress": "1624  Kilburn Rd N",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$450.73",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 4143482,
          "ParentWorkOrder": "",
          "JobName": "Harrison Road",
          "JobNumber": "7312-101138-12",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10725 Harrison Road",
          "JobAddress2": "",
          "JobFullAddress": "10725 Harrison Road",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Brindle Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$173.77",
          "BranchNumber": 7312,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Brindle Construction"
        },
        {
          "WorkOrder": 4143481,
          "ParentWorkOrder": "",
          "JobName": "Hamilton Vet Clinic",
          "JobNumber": "7313-105217-10",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1537 N. Main St.",
          "JobAddress2": "",
          "JobFullAddress": "1537 N. Main St.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Case Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,930.69",
          "BranchNumber": 7313,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Case Construction"
        },
        {
          "WorkOrder": 4143480,
          "ParentWorkOrder": "",
          "JobName": "Avondale Middle School",
          "JobNumber": "7310-101038-39",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1445 W Auburn Rd",
          "JobAddress2": "",
          "JobFullAddress": "1445 W Auburn Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Turner Brooks Inc",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$25,971.20",
          "BranchNumber": 7310,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Turner Brooks Inc"
        },
        {
          "WorkOrder": 4143479,
          "ParentWorkOrder": "",
          "JobName": "starbucks",
          "JobNumber": "7308-137191-15",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "121 Ionia Ave SW",
          "JobAddress2": "",
          "JobFullAddress": "121 Ionia Ave SW",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "Triad Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,284.09",
          "BranchNumber": 7308,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Triad Interiors LLC"
        },
        {
          "WorkOrder": 4143478,
          "ParentWorkOrder": "",
          "JobName": "FireFarm-Monroe",
          "JobNumber": "7422-105144-7",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1100 S Monroe ST",
          "JobAddress2": "",
          "JobFullAddress": "1100 S Monroe ST",
          "JobCounty": "MONROE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Superior Const & Drywall LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,543.46",
          "BranchNumber": 7422,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Superior Const & Drywall LLC"
        },
        {
          "WorkOrder": 4143477,
          "ParentWorkOrder": "",
          "JobName": "Nof Harrison Rd",
          "JobNumber": "7312-101138-11",
          "FirstdayontheJob": "07-01-2021",
          "LastdayontheJob": "07-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10725 Harrison",
          "JobAddress2": "",
          "JobFullAddress": "10725 Harrison",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Brindle Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$49,432.38",
          "BranchNumber": 7312,
          "SubmitDate": "07-02-2021",
          "ClientonJob": "Brindle Construction"
        },
        {
          "WorkOrder": 4142706,
          "ParentWorkOrder": 4128638,
          "JobName": "(NOF) City Flats Phase 2",
          "JobNumber": "502-857051-10",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "405 Water Street, 800 - 802 Military Street",
          "JobAddress2": "",
          "JobFullAddress": "405 Water Street, 800 - 802 Military Street",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARTIN CONSTRUCTION, LLC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$7,605.10",
          "BranchNumber": 11,
          "SubmitDate": "06-30-2021",
          "ClientonJob": "MARTIN CONSTRUCTION, LLC."
        },
        {
          "WorkOrder": 4141262,
          "ParentWorkOrder": "",
          "JobName": "TIRE DISCOUNTERS",
          "JobNumber": "7272-132739-10",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2214 HILLIARD ROME RD",
          "JobAddress2": "",
          "JobFullAddress": "2214 HILLIARD ROME RD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$168.34",
          "BranchNumber": 7272,
          "SubmitDate": "06-28-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4141261,
          "ParentWorkOrder": "",
          "JobName": "Vega Americas",
          "JobNumber": "7270-133987-24",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3500 W State Rte 741",
          "JobAddress2": "",
          "JobFullAddress": "3500 W State Rte 741",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Triversity Craft Force LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,597.42",
          "BranchNumber": 7270,
          "SubmitDate": "06-28-2021",
          "ClientonJob": "Triversity Craft Force LLC"
        },
        {
          "WorkOrder": 4141260,
          "ParentWorkOrder": "",
          "JobName": "Atchenson Place",
          "JobNumber": "7272-133665-13",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1001 Atchenson St",
          "JobAddress2": "",
          "JobFullAddress": "1001 Atchenson St",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Sowers",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,599.12",
          "BranchNumber": 7272,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Sowers"
        },
        {
          "WorkOrder": 4141259,
          "ParentWorkOrder": "",
          "JobName": "Nof Spartan Chemical",
          "JobNumber": "7302-104922-12",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1110 Spartan Dr",
          "JobAddress2": "",
          "JobFullAddress": "1110 Spartan Dr",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lathrop Company",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,400.77",
          "BranchNumber": 7302,
          "SubmitDate": "06-28-2021",
          "ClientonJob": "Lathrop Company"
        },
        {
          "WorkOrder": 4141258,
          "ParentWorkOrder": "",
          "JobName": "Ehove Building A",
          "JobNumber": "7302-104913-4",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "316 Mason Road W",
          "JobAddress2": "",
          "JobFullAddress": "316 Mason Road W",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & B Acoustical",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "-$1,253.19",
          "BranchNumber": 7302,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "J & B Acoustical"
        },
        {
          "WorkOrder": 4141210,
          "ParentWorkOrder": "",
          "JobName": "NEW LOTHRUP SCHOOLS",
          "JobNumber": "7304-104130-17",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9285 EASTON RD",
          "JobAddress2": "",
          "JobFullAddress": "9285 EASTON RD",
          "JobCounty": "SHIAWASSEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Tri City Acoustical",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$433.90",
          "BranchNumber": 7304,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Tri City Acoustical"
        },
        {
          "WorkOrder": 4141208,
          "ParentWorkOrder": "",
          "JobName": "Njp Caledonia Crossings",
          "JobNumber": "7308-105015-3",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9028 Cherry Valley",
          "JobAddress2": "",
          "JobFullAddress": "9028 Cherry Valley",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "CMC Improvements LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$251.28",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "CMC Improvements LLC"
        },
        {
          "WorkOrder": 4141206,
          "ParentWorkOrder": "",
          "JobName": "868 22 MILE RD NW",
          "JobNumber": "7308-137218-41",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "868 22 MILE RD NW",
          "JobAddress2": "",
          "JobFullAddress": "868 22 MILE RD NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "BC Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$535.64",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "BC Drywall"
        },
        {
          "WorkOrder": 4141204,
          "ParentWorkOrder": "",
          "JobName": "Njp Market Pl Square",
          "JobNumber": "7308-104326-14",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7471 River St SE",
          "JobAddress2": "",
          "JobFullAddress": "7471 River St SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Halyard",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$108.12",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Halyard"
        },
        {
          "WorkOrder": 4141202,
          "ParentWorkOrder": "",
          "JobName": "Njp Svsu Remod",
          "JobNumber": "7304-104137-16",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7400 Bay Rd",
          "JobAddress2": "",
          "JobFullAddress": "7400 Bay Rd",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Wobig Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,343.58",
          "BranchNumber": 7304,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Wobig Construction"
        },
        {
          "WorkOrder": 4141200,
          "ParentWorkOrder": "",
          "JobName": "Major G Grocer",
          "JobNumber": "7313-104691-13",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "662 E Big Beaver Road",
          "JobAddress2": "",
          "JobFullAddress": "662 E Big Beaver Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Bluestone Construction Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,075.50",
          "BranchNumber": 7313,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Bluestone Construction Group"
        },
        {
          "WorkOrder": 4141198,
          "ParentWorkOrder": "",
          "JobName": "Jackson School of Arts",
          "JobNumber": "7312-105089-2",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "634 N Mechanic",
          "JobAddress2": "",
          "JobFullAddress": "634 N Mechanic",
          "JobCounty": "JACKON",
          "JobState": "Michigan",
          "CustomerCompanyName": "River City Interiors",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,615.33",
          "BranchNumber": 7312,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "River City Interiors"
        },
        {
          "WorkOrder": 4141194,
          "ParentWorkOrder": "",
          "JobName": "Njr Shahani Residence",
          "JobNumber": "7313-104843-20",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6300 Rue Du Lac",
          "JobAddress2": "",
          "JobFullAddress": "6300 Rue Du Lac",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$130.25",
          "BranchNumber": 7313,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4141192,
          "ParentWorkOrder": "",
          "JobName": "Remax Job",
          "JobNumber": "7304-102934-16",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4900 Eastman Ave.",
          "JobAddress2": "",
          "JobFullAddress": "4900 Eastman Ave.",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$905.45",
          "BranchNumber": 7304,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Great Lakes Bay Construction"
        },
        {
          "WorkOrder": 4141190,
          "ParentWorkOrder": "",
          "JobName": "Njp Iinn F",
          "JobNumber": "7304-105217-4",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4800 S Saginaw St",
          "JobAddress2": "",
          "JobFullAddress": "4800 S Saginaw St",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "Case Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$812.53",
          "BranchNumber": 7304,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Case Construction"
        },
        {
          "WorkOrder": 4141188,
          "ParentWorkOrder": "",
          "JobName": "GGB Sports (Joe Dumars)",
          "JobNumber": "7313-104400-9",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45300 Mound Road",
          "JobAddress2": "",
          "JobFullAddress": "45300 Mound Road",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Parallel Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,870.75",
          "BranchNumber": 7313,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Parallel Construction"
        },
        {
          "WorkOrder": 4141186,
          "ParentWorkOrder": "",
          "JobName": "Nof Brown City High Sch",
          "JobNumber": "7422-104368-22",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4400 2NS ST",
          "JobAddress2": "/4290 SECOND ST",
          "JobFullAddress": "4400 2NS ST /4290 SECOND ST",
          "JobCounty": "SANILAC",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elkhorn Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$161.46",
          "BranchNumber": 7422,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Elkhorn Construction"
        },
        {
          "WorkOrder": 4141185,
          "ParentWorkOrder": "",
          "JobName": "Evangelical Homes",
          "JobNumber": "7313-104476-5",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "440 W Russell St.",
          "JobAddress2": "",
          "JobFullAddress": "440 W Russell St.",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,950.91",
          "BranchNumber": 7313,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "M & V Construction"
        },
        {
          "WorkOrder": 4141184,
          "ParentWorkOrder": "",
          "JobName": "Jackson Ambulance",
          "JobNumber": "7312-104643-18",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "429 Ingham St",
          "JobAddress2": "",
          "JobFullAddress": "429 Ingham St",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$180.88",
          "BranchNumber": 7312,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 4141183,
          "ParentWorkOrder": "",
          "JobName": "Njp Newtonproperties",
          "JobNumber": "7304-104101-3",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4151 Shrestha Dr",
          "JobAddress2": "",
          "JobFullAddress": "4151 Shrestha Dr",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "McIntosh Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$181.10",
          "BranchNumber": 7304,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "McIntosh Construction"
        },
        {
          "WorkOrder": 4141182,
          "ParentWorkOrder": "",
          "JobName": "Njp Midland Hospital",
          "JobNumber": "7304-104189-2",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4000 Wellness Dr",
          "JobAddress2": "",
          "JobFullAddress": "4000 Wellness Dr",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Three Rivers Corporation",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$87.05",
          "BranchNumber": 7304,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Three Rivers Corporation"
        },
        {
          "WorkOrder": 4141181,
          "ParentWorkOrder": "",
          "JobName": "Njp Belle Tire Waterford",
          "JobNumber": "7313-104341-15",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "39 N Telegraph Rd",
          "JobAddress2": "",
          "JobFullAddress": "39 N Telegraph Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Acoustical Systems",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,240.44",
          "BranchNumber": 7313,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Acoustical Systems"
        },
        {
          "WorkOrder": 4141180,
          "ParentWorkOrder": "",
          "JobName": "US farathane corp",
          "JobNumber": "7422-105170-15",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "38000 mound road",
          "JobAddress2": "",
          "JobFullAddress": "38000 mound road",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Industrial Tech Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,965.87",
          "BranchNumber": 7422,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Industrial Tech Services"
        },
        {
          "WorkOrder": 4141179,
          "ParentWorkOrder": "",
          "JobName": "Renaissance Builders",
          "JobNumber": "7422-104151-84",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3611 Charitoo Ridge",
          "JobAddress2": "",
          "JobFullAddress": "3611 Charitoo Ridge",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Savyer Drywall LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$338.35",
          "BranchNumber": 7422,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Savyer Drywall LLC"
        },
        {
          "WorkOrder": 4141178,
          "ParentWorkOrder": "",
          "JobName": "Calvin College",
          "JobNumber": "7308-104851-32",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3201 Burton St SE",
          "JobAddress2": "",
          "JobFullAddress": "3201 Burton St SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Level 5 Commercial Finish",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$107.91",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Level 5 Commercial Finish"
        },
        {
          "WorkOrder": 4141177,
          "ParentWorkOrder": "",
          "JobName": "Njp Hope Endzone",
          "JobNumber": "7308-104198-23",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "313 Fairbanks Ave",
          "JobAddress2": "",
          "JobFullAddress": "313 Fairbanks Ave",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$418.49",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4141176,
          "ParentWorkOrder": "",
          "JobName": "T-Mobile",
          "JobNumber": "7304-136494-13",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3125 Tittawabassee Rd",
          "JobAddress2": "",
          "JobFullAddress": "3125 Tittawabassee Rd",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Marine City Contracting Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$81.16",
          "BranchNumber": 7304,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Marine City Contracting Inc"
        },
        {
          "WorkOrder": 4141175,
          "ParentWorkOrder": "",
          "JobName": "Nof Banfield Rochester",
          "JobNumber": "7313-104357-9",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3117 S Rochester Rd",
          "JobAddress2": "",
          "JobFullAddress": "3117 S Rochester Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Int Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,593.00",
          "BranchNumber": 7313,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Commercial Int Construction"
        },
        {
          "WorkOrder": 4141174,
          "ParentWorkOrder": "",
          "JobName": "Njp Gratiot Medical Ctr",
          "JobNumber": "7304-105051-3",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "300 E Warwick Dr",
          "JobAddress2": "",
          "JobFullAddress": "300 E Warwick Dr",
          "JobCounty": "GRATIOT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Mid Michigan Medical Cntr",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$804.13",
          "BranchNumber": 7304,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Mid Michigan Medical Cntr"
        },
        {
          "WorkOrder": 4141173,
          "ParentWorkOrder": "",
          "JobName": "Njp New House",
          "JobNumber": "7308-137341-7",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2894 Onyx Ct",
          "JobAddress2": "",
          "JobFullAddress": "2894 Onyx Ct",
          "JobCounty": "BARRY",
          "JobState": "Michigan",
          "CustomerCompanyName": "Mallory Corporation",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,093.67",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Mallory Corporation"
        },
        {
          "WorkOrder": 4141172,
          "ParentWorkOrder": "",
          "JobName": "Njp Iha Farmington Peds",
          "JobNumber": "7310-104842-11",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "28300 Orchard Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "28300 Orchard Lake Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Rajala & Sons Finishes",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$178.38",
          "BranchNumber": 7310,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Rajala & Sons Finishes"
        },
        {
          "WorkOrder": 4141171,
          "ParentWorkOrder": "",
          "JobName": "Nof 279 Kettering High",
          "JobNumber": "7313-104368-16",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 Kettering Dr",
          "JobAddress2": "",
          "JobFullAddress": "2800 Kettering Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elkhorn Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$963.43",
          "BranchNumber": 7313,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Elkhorn Construction"
        },
        {
          "WorkOrder": 4141170,
          "ParentWorkOrder": "",
          "JobName": "Bobs Furniture",
          "JobNumber": "7304-136494-4",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "280 Brown Rd",
          "JobAddress2": "",
          "JobFullAddress": "280 Brown Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Marine City Contracting Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,101.96",
          "BranchNumber": 7304,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Marine City Contracting Inc"
        },
        {
          "WorkOrder": 4141169,
          "ParentWorkOrder": "",
          "JobName": "NOF Grand Castle",
          "JobNumber": "7308-105223-2",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2655 Grand Castle Blvd SW",
          "JobAddress2": "",
          "JobFullAddress": "2655 Grand Castle Blvd SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "The Grand Castle LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$181.51",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "The Grand Castle LLC"
        },
        {
          "WorkOrder": 4141168,
          "ParentWorkOrder": "",
          "JobName": "Njp Webasto",
          "JobNumber": "7313-104620-3",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2500 Executive Hills Dr",
          "JobAddress2": "",
          "JobFullAddress": "2500 Executive Hills Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Jasman Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$358.70",
          "BranchNumber": 7313,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Jasman Construction Inc"
        },
        {
          "WorkOrder": 4141167,
          "ParentWorkOrder": "",
          "JobName": "Njp Warehouse",
          "JobNumber": "7308-103005-4",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24644 M 43",
          "JobAddress2": "",
          "JobFullAddress": "24644 M 43",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Spartan Insulation",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,578.34",
          "BranchNumber": 7308,
          "SubmitDate": "",
          "ClientonJob": "Spartan Insulation"
        },
        {
          "WorkOrder": 4141166,
          "ParentWorkOrder": "",
          "JobName": "Nof Lyon Reserves",
          "JobNumber": "7310-104009-38",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24065 Lyon Preserve Trail",
          "JobAddress2": "",
          "JobFullAddress": "24065 Lyon Preserve Trail",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Professional Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,190.26",
          "BranchNumber": 7310,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Professional Drywall"
        },
        {
          "WorkOrder": 4141165,
          "ParentWorkOrder": "",
          "JobName": "Njp Bissell Homecare",
          "JobNumber": "7308-104198-30",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2345 Walker Ave Nw",
          "JobAddress2": "",
          "JobFullAddress": "2345 Walker Ave Nw",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,135.23",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4141164,
          "ParentWorkOrder": "",
          "JobName": "Nof 21 5166 Tarppan M S",
          "JobNumber": "7313-104657-42",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2251 E Stadium Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2251 E Stadium Blvd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,588.67",
          "BranchNumber": 7313,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4141163,
          "ParentWorkOrder": "",
          "JobName": "Njp Hastings Water Tre",
          "JobNumber": "7308-104851-23",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "225 N Cass St",
          "JobAddress2": "",
          "JobFullAddress": "225 N Cass St",
          "JobCounty": "BARRY",
          "JobState": "Michigan",
          "CustomerCompanyName": "Level 5 Commercial Finish",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,738.90",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Level 5 Commercial Finish"
        },
        {
          "WorkOrder": 4141162,
          "ParentWorkOrder": "",
          "JobName": "Njp Sideline Sports Bar",
          "JobNumber": "7304-104870-4",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "219 S Washington St",
          "JobAddress2": "",
          "JobFullAddress": "219 S Washington St",
          "JobCounty": "SHIAWASSEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Canze Construction Co LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,359.88",
          "BranchNumber": 7304,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Canze Construction Co LLC"
        },
        {
          "WorkOrder": 4141161,
          "ParentWorkOrder": "",
          "JobName": "Njp 2135 Durant Se",
          "JobNumber": "7308-104868-30",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2135 Durant Dr SE",
          "JobAddress2": "",
          "JobFullAddress": "2135 Durant Dr SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lake Effect Interior Installations Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$90.06",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Lake Effect Interior Installations Inc"
        },
        {
          "WorkOrder": 4141160,
          "ParentWorkOrder": "",
          "JobName": "Multi Drywall & Partition LLC",
          "JobNumber": "7310-104643-19",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21017 Middlebelt Rd",
          "JobAddress2": "",
          "JobFullAddress": "21017 Middlebelt Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$156.03",
          "BranchNumber": 7310,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 4141159,
          "ParentWorkOrder": "",
          "JobName": "Nof Lalonde Chrysler",
          "JobNumber": "7422-104517-4",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2093 S van Dyke Rd",
          "JobAddress2": "",
          "JobFullAddress": "2093 S van Dyke Rd",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9.96",
          "BranchNumber": 7422,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "JFS Company"
        },
        {
          "WorkOrder": 4141158,
          "ParentWorkOrder": "",
          "JobName": "Njp Hampton Inn Bh",
          "JobNumber": "7312-104843-11",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1921 Pipestone Rd",
          "JobAddress2": "",
          "JobFullAddress": "1921 Pipestone Rd",
          "JobCounty": "Berrien",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$838.94",
          "BranchNumber": 7312,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4141157,
          "ParentWorkOrder": "",
          "JobName": "Nof Bluewater View Condo",
          "JobNumber": "7422-104517-2",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "203 Beers St",
          "JobAddress2": "",
          "JobFullAddress": "203 Beers St",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$257.05",
          "BranchNumber": 7422,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "JFS Company"
        },
        {
          "WorkOrder": 4141156,
          "ParentWorkOrder": "",
          "JobName": "Njp Chen Med",
          "JobNumber": "7422-104444-4",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20001 W Seven Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "20001 W Seven Mile Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dalloo Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$135.04",
          "BranchNumber": 7422,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Dalloo Construction Inc"
        },
        {
          "WorkOrder": 4141155,
          "ParentWorkOrder": "",
          "JobName": "Swiftwall Solutions",
          "JobNumber": "7304-137348-2",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1928 N Stark RD",
          "JobAddress2": "",
          "JobFullAddress": "1928 N Stark RD",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Swiftwall Solutions",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$4,561.13",
          "BranchNumber": 7304,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Swiftwall Solutions"
        },
        {
          "WorkOrder": 4141154,
          "ParentWorkOrder": "",
          "JobName": "Arby's 28th St",
          "JobNumber": "7308-104546-53",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1830 28th St SW",
          "JobAddress2": "",
          "JobFullAddress": "1830 28th St SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,363.50",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "SC Construction"
        },
        {
          "WorkOrder": 4141153,
          "ParentWorkOrder": "",
          "JobName": "MORAN",
          "JobNumber": "7422-104447-2",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15800 Eureka Rd",
          "JobAddress2": "",
          "JobFullAddress": "15800 Eureka Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DU Aull Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$157.73",
          "BranchNumber": 7422,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "DU Aull Construction"
        },
        {
          "WorkOrder": 4141152,
          "ParentWorkOrder": "",
          "JobName": "HMOR-1569 1569 River Rd",
          "JobNumber": "7422-104548-41",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1569 River Rd",
          "JobAddress2": "",
          "JobFullAddress": "1569 River Rd",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,191.50",
          "BranchNumber": 7422,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Blue Water Drywall & Construction"
        },
        {
          "WorkOrder": 4141151,
          "ParentWorkOrder": "",
          "JobName": "GFS",
          "JobNumber": "7312-101312-71",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1507 Boardman Rd",
          "JobAddress2": "",
          "JobFullAddress": "1507 Boardman Rd",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Advance Construction & Design Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$27,935.45",
          "BranchNumber": 7312,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Advance Construction & Design Services"
        },
        {
          "WorkOrder": 4141150,
          "ParentWorkOrder": "",
          "JobName": "Nof Royal Oak High Schoo",
          "JobNumber": "7313-102953-8",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 Lexington Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1500 Lexington Blvd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Contracting",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$318.71",
          "BranchNumber": 7313,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Commercial Contracting"
        },
        {
          "WorkOrder": 4141149,
          "ParentWorkOrder": "",
          "JobName": "Njp Cranhill Ranch",
          "JobNumber": "7308-104328-53",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14444 17 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "14444 17 Mile Rd",
          "JobCounty": "MECOSTA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Intext Concepts Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$585.10",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Intext Concepts Inc"
        },
        {
          "WorkOrder": 4141148,
          "ParentWorkOrder": "",
          "JobName": "Njp Dykstra Hall Hope",
          "JobNumber": "7308-104328-58",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "144 E 9th St",
          "JobAddress2": "",
          "JobFullAddress": "144 E 9th St",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Intext Concepts Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$650.45",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Intext Concepts Inc"
        },
        {
          "WorkOrder": 4141147,
          "ParentWorkOrder": "",
          "JobName": "12681 LOCKWOOD DR",
          "JobNumber": "7308-105112-62",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12681 Lockwood Dr",
          "JobAddress2": "",
          "JobFullAddress": "12681 Lockwood Dr",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Martin Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$210.83",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Martin Drywall Inc"
        },
        {
          "WorkOrder": 4141146,
          "ParentWorkOrder": "",
          "JobName": "11640 N DIVISION AVE",
          "JobNumber": "7308-137129-9",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11640 N DIVISION AVE",
          "JobAddress2": "",
          "JobFullAddress": "11640 N DIVISION AVE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Smittys Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,770.93",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Smittys Drywall"
        },
        {
          "WorkOrder": 4141145,
          "ParentWorkOrder": "",
          "JobName": "Nof 274 Early Child Cnt",
          "JobNumber": "7313-104368-12",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1155 Joslyn Rd",
          "JobAddress2": "",
          "JobFullAddress": "1155 Joslyn Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elkhorn Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$63.77",
          "BranchNumber": 7313,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Elkhorn Construction"
        },
        {
          "WorkOrder": 4141144,
          "ParentWorkOrder": "",
          "JobName": "11336 STEBBINS",
          "JobNumber": "7308-105249-23",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11336 STEBBINS AVE",
          "JobAddress2": "",
          "JobFullAddress": "11336 STEBBINS AVE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Diversified Drywall Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$4,294.44",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Diversified Drywall Services"
        },
        {
          "WorkOrder": 4141143,
          "ParentWorkOrder": "",
          "JobName": "Nof Fox Run Village Inc",
          "JobNumber": "7422-105016-7",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41100 Fox Run Rd",
          "JobAddress2": "",
          "JobFullAddress": "41100 Fox Run Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elite Builders LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,311.63",
          "BranchNumber": 7422,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Elite Builders LLC"
        },
        {
          "WorkOrder": 4141142,
          "ParentWorkOrder": "",
          "JobName": "Halyard 28th St apartments",
          "JobNumber": "7308-104326-2",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1095 Prairie Pkwy",
          "JobAddress2": "",
          "JobFullAddress": "1095 Prairie Pkwy",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Halyard Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,291.84",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Halyard Inc"
        },
        {
          "WorkOrder": 4141141,
          "ParentWorkOrder": "",
          "JobName": "Njp Gvsu",
          "JobNumber": "7308-104328-62",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10915 N Campus Dr",
          "JobAddress2": "",
          "JobFullAddress": "10915 N Campus Dr",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Intext Concepts Inc",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,289.93",
          "BranchNumber": 7308,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Intext Concepts Inc"
        },
        {
          "WorkOrder": 4141140,
          "ParentWorkOrder": "",
          "JobName": "Njp Home2 Stes Troy",
          "JobNumber": "7313-104019-19",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1035 Wilshire Dr",
          "JobAddress2": "",
          "JobFullAddress": "1035 Wilshire Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$127.20",
          "BranchNumber": 7313,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4141139,
          "ParentWorkOrder": "",
          "JobName": "Nof Bulk Buy Warehouse",
          "JobNumber": "7422-104019-17",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 North Opdyke Rd Ste C",
          "JobAddress2": "",
          "JobFullAddress": "1000 North Opdyke Rd Ste C",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,566.50",
          "BranchNumber": 7422,
          "SubmitDate": "06-25-2021",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4141138,
          "ParentWorkOrder": "",
          "JobName": "matthews road",
          "JobNumber": "7312-104791-3",
          "FirstdayontheJob": "06-24-2021",
          "LastdayontheJob": "06-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "123 MATTHEWS RD",
          "JobAddress2": "",
          "JobFullAddress": "123 MATTHEWS RD",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "CD Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,054.87",
          "BranchNumber": 7312,
          "SubmitDate": "",
          "ClientonJob": "CD Drywall"
        },
        {
          "WorkOrder": 4139435,
          "ParentWorkOrder": "",
          "JobName": "Nof Aaa Laurel Park",
          "JobNumber": "7310-104565-5",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "37383 SIX MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "37383 SIX MILE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,303.08",
          "BranchNumber": 7310,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "American Family Construction"
        },
        {
          "WorkOrder": 4139434,
          "ParentWorkOrder": "",
          "JobName": "Njp Arbys Wyoming 44th",
          "JobNumber": "7308-104546-52",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "765 44th St Sw",
          "JobAddress2": "",
          "JobFullAddress": "765 44th St Sw",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,815.60",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "SC Construction"
        },
        {
          "WorkOrder": 4139433,
          "ParentWorkOrder": "",
          "JobName": "Njp High Grade",
          "JobNumber": "7308-104326-19",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "701 Lake Lansing Rd",
          "JobAddress2": "",
          "JobFullAddress": "701 Lake Lansing Rd",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Halyard",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,308.26",
          "BranchNumber": 7308,
          "SubmitDate": "",
          "ClientonJob": "Halyard"
        },
        {
          "WorkOrder": 4139432,
          "ParentWorkOrder": "",
          "JobName": "Nof Market Express",
          "JobNumber": "7308-137191-9",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4773 Lake Michigan Dr",
          "JobAddress2": "",
          "JobFullAddress": "4773 Lake Michigan Dr",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Triad Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$28,777.80",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Triad Interiors LLC"
        },
        {
          "WorkOrder": 4139431,
          "ParentWorkOrder": "",
          "JobName": "POLYTEK WOOD SHOP",
          "JobNumber": "7308-104326-23",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4405 44TH ST SE",
          "JobAddress2": "",
          "JobFullAddress": "4405 44TH ST SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Halyard",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,060.74",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Halyard"
        },
        {
          "WorkOrder": 4139430,
          "ParentWorkOrder": "",
          "JobName": "HAGGERTY",
          "JobNumber": "7310-104733-2",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29064 Clarita St",
          "JobAddress2": "",
          "JobFullAddress": "29064 Clarita St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "L & M Supply Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$874.23",
          "BranchNumber": 7310,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "L & M Supply Co"
        },
        {
          "WorkOrder": 4139429,
          "ParentWorkOrder": "",
          "JobName": "Nof Dearborn Aaa",
          "JobNumber": "7310-104649-3",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15500 Lundy Pkwy",
          "JobAddress2": "",
          "JobFullAddress": "15500 Lundy Pkwy",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NVR Finishes Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,077.72",
          "BranchNumber": 7310,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "NVR Finishes Inc"
        },
        {
          "WorkOrder": 4139428,
          "ParentWorkOrder": "",
          "JobName": "Njp Legacy",
          "JobNumber": "7308-104198-24",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1525 Steele Ave SW",
          "JobAddress2": "",
          "JobFullAddress": "1525 Steele Ave SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR Bouwkamp & Assoc Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$14,238.31",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "JR Bouwkamp & Assoc Inc"
        },
        {
          "WorkOrder": 4139427,
          "ParentWorkOrder": "",
          "JobName": "Nof Behavieral Ctr",
          "JobNumber": "7310-104587-4",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "39465 W 14 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "39465 W 14 MILE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Danco Contracting Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$28,742.64",
          "BranchNumber": 7310,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Danco Contracting Inc"
        },
        {
          "WorkOrder": 4139426,
          "ParentWorkOrder": "",
          "JobName": "Addient",
          "JobNumber": "7310-104619-2",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Todd",
          "JobAddress2": "",
          "JobFullAddress": "Todd",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Inter Office Const Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$521.41",
          "BranchNumber": 7310,
          "SubmitDate": "",
          "ClientonJob": "Inter Office Const Inc"
        },
        {
          "WorkOrder": 4139425,
          "ParentWorkOrder": "",
          "JobName": "dearborn town center",
          "JobNumber": "7310-104619-3",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "PO Box 777",
          "JobAddress2": "",
          "JobFullAddress": "PO Box 777",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Inter Office Const Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$558.41",
          "BranchNumber": 7310,
          "SubmitDate": "",
          "ClientonJob": "Inter Office Const Inc"
        },
        {
          "WorkOrder": 4139424,
          "ParentWorkOrder": "",
          "JobName": "job 4773",
          "JobNumber": "7310-133906-2",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "PO Box 5289",
          "JobAddress2": "",
          "JobFullAddress": "PO Box 5289",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Urbans Partition",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$339.46",
          "BranchNumber": 7310,
          "SubmitDate": "",
          "ClientonJob": "Urbans Partition"
        },
        {
          "WorkOrder": 4139423,
          "ParentWorkOrder": "",
          "JobName": "metro bank",
          "JobNumber": "7310-104024-6",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "PO Box 1544",
          "JobAddress2": "",
          "JobFullAddress": "PO Box 1544",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Integrated Acoustical",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$56.99",
          "BranchNumber": 7310,
          "SubmitDate": "",
          "ClientonJob": "Integrated Acoustical"
        },
        {
          "WorkOrder": 4139422,
          "ParentWorkOrder": "",
          "JobName": "MARWOOD",
          "JobNumber": "7310-104009-39",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "PO Box 1222",
          "JobAddress2": "",
          "JobFullAddress": "PO Box 1222",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Professional Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,416.96",
          "BranchNumber": 7310,
          "SubmitDate": "",
          "ClientonJob": "Professional Drywall"
        },
        {
          "WorkOrder": 4139421,
          "ParentWorkOrder": "",
          "JobName": "Thermo Fisher",
          "JobNumber": "7313-105170-8",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "920 Henry St",
          "JobAddress2": "",
          "JobFullAddress": "920 Henry St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Industrial Tech Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$22,125.08",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Industrial Tech Services"
        },
        {
          "WorkOrder": 4139420,
          "ParentWorkOrder": "",
          "JobName": "Nof Iagd Rochester Hill",
          "JobNumber": "7310-104568-3",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "879 W Auburn Rd",
          "JobAddress2": "",
          "JobFullAddress": "879 W Auburn Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Best Finishers Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,647.62",
          "BranchNumber": 7310,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Best Finishers Inc"
        },
        {
          "WorkOrder": 4139419,
          "ParentWorkOrder": "",
          "JobName": "Detroit Achievement ADAD",
          "JobNumber": "7313-104401-2",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7000 W Outer Dr",
          "JobAddress2": "",
          "JobFullAddress": "7000 W Outer Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PCI Industries Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,923.80",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "PCI Industries Inc"
        },
        {
          "WorkOrder": 4139418,
          "ParentWorkOrder": "",
          "JobName": "695 Kenmoor",
          "JobNumber": "7308-137191-13",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "695 Kenmoor Ave SE",
          "JobAddress2": "",
          "JobFullAddress": "695 Kenmoor Ave SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Triad Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$281.43",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Triad Interiors LLC"
        },
        {
          "WorkOrder": 4139417,
          "ParentWorkOrder": "",
          "JobName": "Thompson Elementary School",
          "JobNumber": "7313-133898-13",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "617 E Clark St",
          "JobAddress2": "",
          "JobFullAddress": "617 E Clark St",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Erickson & lindstrom Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$4,701.68",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Erickson & lindstrom Construction"
        },
        {
          "WorkOrder": 4139416,
          "ParentWorkOrder": "",
          "JobName": "Njp Ostrowski Fit Out",
          "JobNumber": "7308-104326-22",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4500 Cascade Rd SE",
          "JobAddress2": "",
          "JobFullAddress": "4500 Cascade Rd SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Halyard",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,270.26",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Halyard"
        },
        {
          "WorkOrder": 4139415,
          "ParentWorkOrder": "",
          "JobName": "Nof Residents Inn",
          "JobNumber": "7313-104009-10",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44600 Hayes Rd",
          "JobAddress2": "",
          "JobFullAddress": "44600 Hayes Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Professional Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$41,205.22",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Professional Drywall"
        },
        {
          "WorkOrder": 4139414,
          "ParentWorkOrder": "",
          "JobName": "Njp Farwell Elementary",
          "JobNumber": "7304-132706-8",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "399 E Michigan St",
          "JobAddress2": "",
          "JobFullAddress": "399 E Michigan St",
          "JobCounty": "CLARE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Denali Construction Inc",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$18,560.97",
          "BranchNumber": 7304,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Denali Construction Inc"
        },
        {
          "WorkOrder": 4139413,
          "ParentWorkOrder": "",
          "JobName": "Nof Priority One Exp",
          "JobNumber": "7313-137350-18",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "38701 Seven Mile",
          "JobAddress2": "",
          "JobFullAddress": "38701 Seven Mile",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "D A Contracting LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,720.92",
          "BranchNumber": 7313,
          "SubmitDate": "",
          "ClientonJob": "D A Contracting LLC"
        },
        {
          "WorkOrder": 4139412,
          "ParentWorkOrder": "",
          "JobName": "TACO BELL",
          "JobNumber": "7310-102087-28",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34018 Beacon St",
          "JobAddress2": "",
          "JobFullAddress": "34018 Beacon St",
          "JobCounty": "",
          "JobState": "Virginia",
          "CustomerCompanyName": "George H Pastor & Sons",
          "RequestType": "Memorandum for Mechanic's Lien Claimed by Sub Subcontractor",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,046.86",
          "BranchNumber": 7310,
          "SubmitDate": "",
          "ClientonJob": "George H Pastor & Sons"
        },
        {
          "WorkOrder": 4139411,
          "ParentWorkOrder": "",
          "JobName": "BEAUMONT # 5864",
          "JobNumber": "7310-104560-2",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31805 Eight Mile",
          "JobAddress2": "",
          "JobFullAddress": "31805 Eight Mile",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "AIM Systems",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$198.43",
          "BranchNumber": 7310,
          "SubmitDate": "",
          "ClientonJob": "AIM Systems"
        },
        {
          "WorkOrder": 4139410,
          "ParentWorkOrder": "",
          "JobName": "NJP 294 108th",
          "JobNumber": "7308-105112-61",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "294 108th St",
          "JobAddress2": "",
          "JobFullAddress": "294 108th St",
          "JobCounty": "Allegan",
          "JobState": "Michigan",
          "CustomerCompanyName": "Martin Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,083.43",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Martin Drywall Inc"
        },
        {
          "WorkOrder": 4139409,
          "ParentWorkOrder": "",
          "JobName": "Nof Saginaw Va",
          "JobNumber": "7304-137117-10",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2830 McCarty Rd",
          "JobAddress2": "",
          "JobFullAddress": "2830 McCarty Rd",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Lupe Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$875.70",
          "BranchNumber": 7304,
          "SubmitDate": "",
          "ClientonJob": "Lupe Drywall"
        },
        {
          "WorkOrder": 4139408,
          "ParentWorkOrder": "",
          "JobName": "Banquette Center Ypsi",
          "JobNumber": "7313-104489-3",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1275 S. Huron St.",
          "JobAddress2": "",
          "JobFullAddress": "1275 S. Huron St.",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trinity Health Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,799.35",
          "BranchNumber": 7313,
          "SubmitDate": "",
          "ClientonJob": "Trinity Health Services"
        },
        {
          "WorkOrder": 4139407,
          "ParentWorkOrder": "",
          "JobName": "Job- bay Veterans Project",
          "JobNumber": "7304-104092-7",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1215 N. Madison Ave",
          "JobAddress2": "",
          "JobFullAddress": "1215 N. Madison Ave",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "Serenus Johnson",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$582.89",
          "BranchNumber": 7304,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Serenus Johnson"
        },
        {
          "WorkOrder": 4139406,
          "ParentWorkOrder": "",
          "JobName": "Njp Xolo Tequila Bar",
          "JobNumber": "7304-105217-9",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "111 S Leroy St",
          "JobAddress2": "Ste 4LOX",
          "JobFullAddress": "111 S Leroy St Ste 4LOX",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Case Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,863.25",
          "BranchNumber": 7304,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Case Construction"
        },
        {
          "WorkOrder": 4139405,
          "ParentWorkOrder": "",
          "JobName": "Nof Cherrin Residence",
          "JobNumber": "7313-137383-24",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1017 Otter Ave",
          "JobAddress2": "",
          "JobFullAddress": "1017 Otter Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Distinctive Grading & Design LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,699.72",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Distinctive Grading & Design LLC"
        },
        {
          "WorkOrder": 4139404,
          "ParentWorkOrder": "",
          "JobName": "Nof Reserve W Bloomfie",
          "JobNumber": "7310-104009-32",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Toll Brothers",
          "JobAddress2": "",
          "JobFullAddress": "Toll Brothers",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Professional Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$174,934.32",
          "BranchNumber": 7310,
          "SubmitDate": "",
          "ClientonJob": "Professional Drywall"
        },
        {
          "WorkOrder": 4139403,
          "ParentWorkOrder": "",
          "JobName": "Njp Leonard St Studio",
          "JobNumber": "7308-137119-2",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "730 Leonard St NW",
          "JobAddress2": "",
          "JobFullAddress": "730 Leonard St NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Bradco LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,413.08",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Bradco LLC"
        },
        {
          "WorkOrder": 4139402,
          "ParentWorkOrder": "",
          "JobName": "Njp 7121 Valdez Ct",
          "JobNumber": "7308-105112-60",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7121 Valdez Ct SE",
          "JobAddress2": "",
          "JobFullAddress": "7121 Valdez Ct SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Martin Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,412.52",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Martin Drywall Inc"
        },
        {
          "WorkOrder": 4139401,
          "ParentWorkOrder": "",
          "JobName": "Nof Kalamazoo Redwood",
          "JobNumber": "7308-105131-8",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6711 W Q Ave",
          "JobAddress2": "",
          "JobFullAddress": "6711 W Q Ave",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "Shingleton Contractors Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$96,292.84",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Shingleton Contractors Inc"
        },
        {
          "WorkOrder": 4139400,
          "ParentWorkOrder": "",
          "JobName": "Njp Valduga Stes",
          "JobNumber": "7308-104326-20",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6172 Valduga Dr SW",
          "JobAddress2": "",
          "JobFullAddress": "6172 Valduga Dr SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Halyard",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$26,868.77",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Halyard"
        },
        {
          "WorkOrder": 4139399,
          "ParentWorkOrder": "",
          "JobName": "Njp Handy Middle School",
          "JobNumber": "7304-104137-12",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "601 Blend St",
          "JobAddress2": "",
          "JobFullAddress": "601 Blend St",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "Wobig Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,317.13",
          "BranchNumber": 7304,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Wobig Construction"
        },
        {
          "WorkOrder": 4139398,
          "ParentWorkOrder": "",
          "JobName": "Nof Laquinta Hotel",
          "JobNumber": "7308-104843-17",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "52554 Indiana State Route 933",
          "JobAddress2": "",
          "JobFullAddress": "52554 Indiana State Route 933",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,022.48",
          "BranchNumber": 7308,
          "SubmitDate": "",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4139397,
          "ParentWorkOrder": "",
          "JobName": "Nof Livonia Med Cntr",
          "JobNumber": "7312-104842-9",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29370 Plymouth Rd",
          "JobAddress2": "",
          "JobFullAddress": "29370 Plymouth Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Rajala & Sons Finishes",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$20,830.89",
          "BranchNumber": 7312,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Rajala & Sons Finishes"
        },
        {
          "WorkOrder": 4139396,
          "ParentWorkOrder": "",
          "JobName": "Nof Fh Orchard View",
          "JobNumber": "7308-104969-6",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2770 Leffingwell Ave NE",
          "JobAddress2": "",
          "JobFullAddress": "2770 Leffingwell Ave NE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Premier 1 Interiors LLC",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$34,495.36",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Premier 1 Interiors LLC"
        },
        {
          "WorkOrder": 4139395,
          "ParentWorkOrder": "",
          "JobName": "Njp Treadstone",
          "JobNumber": "7308-137191-10",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "214 Fulton St E",
          "JobAddress2": "",
          "JobFullAddress": "214 Fulton St E",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Triad Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,527.71",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Triad Interiors LLC"
        },
        {
          "WorkOrder": 4139394,
          "ParentWorkOrder": "",
          "JobName": "NJP Comfort Insulation",
          "JobNumber": "7308-104213-2",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15 N PARK ST NW",
          "JobAddress2": "",
          "JobFullAddress": "15 N PARK ST NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Grand Eagle Insulation",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,936.99",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Grand Eagle Insulation"
        },
        {
          "WorkOrder": 4139393,
          "ParentWorkOrder": "",
          "JobName": "Nof Jsa 21 0113 04",
          "JobNumber": "7312-104816-7",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "135 W Cortland Ave",
          "JobAddress2": "",
          "JobFullAddress": "135 W Cortland Ave",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "R. W. Mercer Co.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,167.93",
          "BranchNumber": 7312,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "R. W. Mercer Co."
        },
        {
          "WorkOrder": 4139392,
          "ParentWorkOrder": "",
          "JobName": "NOF Marriott Ypsilanti",
          "JobNumber": "7310-102226-8",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1275 S Huron St",
          "JobAddress2": "",
          "JobFullAddress": "1275 S Huron St",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trinity Painting LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$771.47",
          "BranchNumber": 7310,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Trinity Painting LLC"
        },
        {
          "WorkOrder": 4139391,
          "ParentWorkOrder": "",
          "JobName": "Waste Water Treatment Pla",
          "JobNumber": "7310-104152-2",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9300 W Jefferson Ave",
          "JobAddress2": "",
          "JobFullAddress": "9300 W Jefferson Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Water Authority",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$57.97",
          "BranchNumber": 7310,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Great Lakes Water Authority"
        },
        {
          "WorkOrder": 4139390,
          "ParentWorkOrder": "",
          "JobName": "Nof United Shore Financ",
          "JobNumber": "7313-104357-7",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "585 SOUTH BLVD E",
          "JobAddress2": "",
          "JobFullAddress": "585 SOUTH BLVD E",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INTERIOR CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,206.20",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "COMMERCIAL INTERIOR CONSTRUCTION"
        },
        {
          "WorkOrder": 4139389,
          "ParentWorkOrder": "",
          "JobName": "Nof Port Huron Twp Dpw",
          "JobNumber": "7422-137354-4",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4134 W Water St",
          "JobAddress2": "",
          "JobFullAddress": "4134 W Water St",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Martin Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,051.88",
          "BranchNumber": 7422,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Martin Construction"
        },
        {
          "WorkOrder": 4139388,
          "ParentWorkOrder": "",
          "JobName": "Nof Ash",
          "JobNumber": "7313-137383-11",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4065 Gold Ridge Dr E",
          "JobAddress2": "",
          "JobFullAddress": "4065 Gold Ridge Dr E",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Distinctive Grading & Design LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,299.67",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Distinctive Grading & Design LLC"
        },
        {
          "WorkOrder": 4139387,
          "ParentWorkOrder": "",
          "JobName": "Nof Jordan 3250",
          "JobNumber": "7313-104546-47",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3250 Auburn Rd",
          "JobAddress2": "",
          "JobFullAddress": "3250 Auburn Rd",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,315.77",
          "BranchNumber": 7313,
          "SubmitDate": "",
          "ClientonJob": "SC Construction"
        },
        {
          "WorkOrder": 4139386,
          "ParentWorkOrder": "",
          "JobName": "Nof Holiday Inn",
          "JobNumber": "7304-102293-15",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2501 Tittabawassee Rd",
          "JobAddress2": "",
          "JobFullAddress": "2501 Tittabawassee Rd",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Harbaugh Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,449.46",
          "BranchNumber": 7304,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Harbaugh Construction"
        },
        {
          "WorkOrder": 4139385,
          "ParentWorkOrder": "",
          "JobName": "Njp Edge Fitness",
          "JobNumber": "7313-104691-12",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14495 Hall Rd",
          "JobAddress2": "",
          "JobFullAddress": "14495 Hall Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Bluestone Const Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,569.88",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Bluestone Const Group"
        },
        {
          "WorkOrder": 4139384,
          "ParentWorkOrder": "",
          "JobName": "Nof Amazon Panda",
          "JobNumber": "7313-101349-46",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1377 East 8 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "1377 East 8 Mile Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Barton Malow Company",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$45,799.51",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Barton Malow Company"
        },
        {
          "WorkOrder": 4139383,
          "ParentWorkOrder": "",
          "JobName": "Njp Van Buren Senior",
          "JobNumber": "7308-104851-29",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8337 M 140",
          "JobAddress2": "",
          "JobFullAddress": "8337 M 140",
          "JobCounty": "VAN BUREN",
          "JobState": "Michigan",
          "CustomerCompanyName": "Level 5 Commercial Finish",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$102,065.13",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Level 5 Commercial Finish"
        },
        {
          "WorkOrder": 4139382,
          "ParentWorkOrder": "",
          "JobName": "Nof 3131 Zen Apartments",
          "JobNumber": "7313-102953-18",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "777 Wilshire Dr",
          "JobAddress2": "",
          "JobFullAddress": "777 Wilshire Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Contracting",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$70,825.76",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Commercial Contracting"
        },
        {
          "WorkOrder": 4139381,
          "ParentWorkOrder": "",
          "JobName": "Nof Village Club",
          "JobNumber": "7313-102953-24",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "64 N Saginaw St",
          "JobAddress2": "",
          "JobFullAddress": "64 N Saginaw St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$29,958.12",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Commercial Contracting"
        },
        {
          "WorkOrder": 4139380,
          "ParentWorkOrder": "",
          "JobName": "Nof 5775 Enterprise Driv",
          "JobNumber": "7422-105144-6",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5775 Enterprise Dr",
          "JobAddress2": "",
          "JobFullAddress": "5775 Enterprise Dr",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Superior Const & Drywall LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$4,993.95",
          "BranchNumber": 7422,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Superior Const & Drywall LLC"
        },
        {
          "WorkOrder": 4139379,
          "ParentWorkOrder": "",
          "JobName": "Nof Panda Exp Royal Oak",
          "JobNumber": "7422-104495-15",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2250 E 12 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "2250 E 12 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Midwest Metal Const LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$26,633.54",
          "BranchNumber": 7422,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Midwest Metal Const LLC"
        },
        {
          "WorkOrder": 4139378,
          "ParentWorkOrder": "",
          "JobName": "Njp Beacon Hill",
          "JobNumber": "7308-104848-13",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1919 SE BOSTON ST",
          "JobAddress2": "",
          "JobFullAddress": "1919 SE BOSTON ST",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Rudys Plastering And Drywall Finish",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$19,821.39",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Rudys Plastering And Drywall Finish"
        },
        {
          "WorkOrder": 4139377,
          "ParentWorkOrder": "",
          "JobName": "Nof Gun Lake Casino",
          "JobNumber": "7313-104657-35",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1123 129th Ave",
          "JobAddress2": "",
          "JobFullAddress": "1123 129th Ave",
          "JobCounty": "ALLEGAN",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,201.73",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4139376,
          "ParentWorkOrder": "",
          "JobName": "Asad Malik Residence",
          "JobNumber": "7310-102293-10",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "900 E Long Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "900 E Long Lake Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Harbaugh Const",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$4,905.44",
          "BranchNumber": 7310,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Harbaugh Const"
        },
        {
          "WorkOrder": 4139375,
          "ParentWorkOrder": "",
          "JobName": "Nof Foster Construction",
          "JobNumber": "7422-105228-4",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8704 Lakeshore Rd",
          "JobAddress2": "",
          "JobFullAddress": "8704 Lakeshore Rd",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Discount Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,823.60",
          "BranchNumber": 7422,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Discount Drywall"
        },
        {
          "WorkOrder": 4139374,
          "ParentWorkOrder": "",
          "JobName": "Nof Red Cedar Apartments",
          "JobNumber": "7310-104643-15",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "203 S Clippert St",
          "JobAddress2": "",
          "JobFullAddress": "203 S Clippert St",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,181.10",
          "BranchNumber": 7310,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 4139373,
          "ParentWorkOrder": "",
          "JobName": "Njp Hillside Memory Care",
          "JobNumber": "7312-104842-7",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1939 Jackson Ave",
          "JobAddress2": "",
          "JobFullAddress": "1939 Jackson Ave",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Rajala & Sons Finishes",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$24,073.02",
          "BranchNumber": 7312,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Rajala & Sons Finishes"
        },
        {
          "WorkOrder": 4139372,
          "ParentWorkOrder": "",
          "JobName": "Nof Pontiac Hs Roof",
          "JobNumber": "7313-104368-20",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1051 Arlene Ave",
          "JobAddress2": "",
          "JobFullAddress": "1051 Arlene Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elkhorn Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,075.13",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Elkhorn Construction"
        },
        {
          "WorkOrder": 4139371,
          "ParentWorkOrder": "",
          "JobName": "Nof Clarkston Medical",
          "JobNumber": "7313-105080-3",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6770 Dixie Hwy",
          "JobAddress2": "",
          "JobFullAddress": "6770 Dixie Hwy",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Carpenter Brothers",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,999.07",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Carpenter Brothers"
        },
        {
          "WorkOrder": 4139370,
          "ParentWorkOrder": "",
          "JobName": "Nof Ou Fitzgerald Anibal",
          "JobNumber": "7313-104657-36",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "614 Pioneer Dr",
          "JobAddress2": "",
          "JobFullAddress": "614 Pioneer Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,159.46",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4139369,
          "ParentWorkOrder": "",
          "JobName": "Njp Glacier Office Plaza",
          "JobNumber": "7422-137146-4",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "60005 CAMPGROUND RD",
          "JobAddress2": "UNIT D",
          "JobFullAddress": "60005 CAMPGROUND RD UNIT D",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Paradise Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,212.78",
          "BranchNumber": 7422,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Paradise Drywall"
        },
        {
          "WorkOrder": 4139368,
          "ParentWorkOrder": "",
          "JobName": "Nof Georgetown Apts",
          "JobNumber": "7308-105263-4",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5721 W Town Dr",
          "JobAddress2": "",
          "JobFullAddress": "5721 W Town Dr",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "PRD Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$299.10",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "PRD Construction Inc"
        },
        {
          "WorkOrder": 4139367,
          "ParentWorkOrder": "",
          "JobName": "Njp P4a Potterville",
          "JobNumber": "7304-104110-7",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4780 N Royston Rd",
          "JobAddress2": "",
          "JobFullAddress": "4780 N Royston Rd",
          "JobCounty": "EATON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Pumford Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$26,972.31",
          "BranchNumber": 7304,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Pumford Construction Inc"
        },
        {
          "WorkOrder": 4139366,
          "ParentWorkOrder": "",
          "JobName": "Njp Munson Cadillac Hosp",
          "JobNumber": "7304-104189-11",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "400 Hobart St",
          "JobAddress2": "",
          "JobFullAddress": "400 Hobart St",
          "JobCounty": "WEXFORD",
          "JobState": "Michigan",
          "CustomerCompanyName": "Three Rivers Corporation",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$4,426.49",
          "BranchNumber": 7304,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Three Rivers Corporation"
        },
        {
          "WorkOrder": 4139365,
          "ParentWorkOrder": "",
          "JobName": "Nof Chimney",
          "JobNumber": "7313-104546-51",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3063 Franklin Rd",
          "JobAddress2": "",
          "JobFullAddress": "3063 Franklin Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$540.99",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "SC Construction"
        },
        {
          "WorkOrder": 4139364,
          "ParentWorkOrder": "",
          "JobName": "Nof Cornerstone Com",
          "JobNumber": "7313-104357-8",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2955 University Dr",
          "JobAddress2": "",
          "JobFullAddress": "2955 University Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Int Const",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,869.56",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Commercial Int Const"
        },
        {
          "WorkOrder": 4139363,
          "ParentWorkOrder": "",
          "JobName": "Nof Town Ctr Dearborn",
          "JobNumber": "7310-134589-29",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "290 Town Ctr Dr",
          "JobAddress2": "11th Floor",
          "JobFullAddress": "290 Town Ctr Dr 11th Floor",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Acoustech Ceiling Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$561.38",
          "BranchNumber": 7310,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Acoustech Ceiling Inc"
        },
        {
          "WorkOrder": 4139362,
          "ParentWorkOrder": "",
          "JobName": "Nof 3187 Vivarium Uofm",
          "JobNumber": "7313-102953-31",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 Wall St",
          "JobAddress2": "",
          "JobFullAddress": "1000 Wall St",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Contracting Corp.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$21,271.37",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Commercial Contracting Corp."
        },
        {
          "WorkOrder": 4139361,
          "ParentWorkOrder": "",
          "JobName": "Njp Lot 105 Butler Ridge",
          "JobNumber": "7313-104020-23",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3917 Piccadilly Dr",
          "JobAddress2": "",
          "JobFullAddress": "3917 Piccadilly Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elite Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,967.10",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Elite Drywall"
        },
        {
          "WorkOrder": 4139360,
          "ParentWorkOrder": "",
          "JobName": "Nof Aldi White Lake",
          "JobNumber": "7313-104357-10",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9054 Highland Rd",
          "JobAddress2": "",
          "JobFullAddress": "9054 Highland Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Int Const",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$14,348.57",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Commercial Int Const"
        },
        {
          "WorkOrder": 4139359,
          "ParentWorkOrder": "",
          "JobName": "Nof Labelle Development",
          "JobNumber": "7422-104492-21",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7845 Markel Rd",
          "JobAddress2": "",
          "JobFullAddress": "7845 Markel Rd",
          "JobCounty": "Saint Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "DGS Drywall LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$770.17",
          "BranchNumber": 7422,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "DGS Drywall LLC"
        },
        {
          "WorkOrder": 4139358,
          "ParentWorkOrder": "",
          "JobName": "Njp 6000 N Creek Ct",
          "JobNumber": "7313-104020-22",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6000 N Creek Ct",
          "JobAddress2": "",
          "JobFullAddress": "6000 N Creek Ct",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Elite Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,146.65",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Elite Drywall"
        },
        {
          "WorkOrder": 4139357,
          "ParentWorkOrder": "",
          "JobName": "Nof Ann Arbor Rd",
          "JobNumber": "7312-105066-2",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4100 Ann Arbor Rd",
          "JobAddress2": "",
          "JobFullAddress": "4100 Ann Arbor Rd",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Merit Commercial Cont LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,055.72",
          "BranchNumber": 7312,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Merit Commercial Cont LLC"
        },
        {
          "WorkOrder": 4139356,
          "ParentWorkOrder": "",
          "JobName": "Njp Comar",
          "JobNumber": "7308-101361-15",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9595 Utica Ave",
          "JobAddress2": "",
          "JobFullAddress": "9595 Utica Ave",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Angstrom Technology Ltd",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$37,456.80",
          "BranchNumber": 7308,
          "SubmitDate": "",
          "ClientonJob": "Angstrom Technology Ltd"
        },
        {
          "WorkOrder": 4139355,
          "ParentWorkOrder": "",
          "JobName": "Njp Nature Releaf",
          "JobNumber": "7308-104328-57",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "666 Leonard St NW",
          "JobAddress2": "",
          "JobFullAddress": "666 Leonard St NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Intext Concepts Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,472.26",
          "BranchNumber": 7308,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Intext Concepts Inc"
        },
        {
          "WorkOrder": 4139354,
          "ParentWorkOrder": "",
          "JobName": "Nof Stevens Red Maple",
          "JobNumber": "7313-137383-12",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6545 Red Maple Ln",
          "JobAddress2": "",
          "JobFullAddress": "6545 Red Maple Ln",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,380.64",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4139353,
          "ParentWorkOrder": "",
          "JobName": "Njp Clarkston Dental",
          "JobNumber": "7313-105037-2",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "55 S Main St",
          "JobAddress2": "",
          "JobFullAddress": "55 S Main St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Facility Maintenance & Construction Of Michigan",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,579.27",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Facility Maintenance & Construction Of Michigan"
        },
        {
          "WorkOrder": 4139352,
          "ParentWorkOrder": "",
          "JobName": "Nof Ford Cec",
          "JobNumber": "7312-105084-4",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1151 Village Rd",
          "JobAddress2": "",
          "JobFullAddress": "1151 Village Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Regis Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$15,035.63",
          "BranchNumber": 7312,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Regis Construction LLC"
        },
        {
          "WorkOrder": 4139351,
          "ParentWorkOrder": "",
          "JobName": "Nof Contractor Steel",
          "JobNumber": "7310-102587-4",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "48649 Schooner St",
          "JobAddress2": "",
          "JobFullAddress": "48649 Schooner St",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Iris Construction Service",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$17,552.00",
          "BranchNumber": 7310,
          "SubmitDate": "",
          "ClientonJob": "Iris Construction Service"
        },
        {
          "WorkOrder": 4139350,
          "ParentWorkOrder": "",
          "JobName": "Nof USi",
          "JobNumber": "7310-134589-25",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2600 W Big Beaver Rd",
          "JobAddress2": "# 140",
          "JobFullAddress": "2600 W Big Beaver Rd # 140",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Acoustech Ceiling Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,039.08",
          "BranchNumber": 7310,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Acoustech Ceiling Inc"
        },
        {
          "WorkOrder": 4139349,
          "ParentWorkOrder": "",
          "JobName": "Nof Omni Property",
          "JobNumber": "7310-104019-51",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24400 Novi Rd",
          "JobAddress2": "Ste 104",
          "JobFullAddress": "24400 Novi Rd Ste 104",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Modern Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,988.94",
          "BranchNumber": 7310,
          "SubmitDate": "",
          "ClientonJob": "Modern Drywall Inc"
        },
        {
          "WorkOrder": 4139348,
          "ParentWorkOrder": "",
          "JobName": "Nof Webesto",
          "JobNumber": "7310-133905-5",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14200 N Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "14200 N Haggerty Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Ideal Contracting LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$327.26",
          "BranchNumber": 7310,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Ideal Contracting LLC"
        },
        {
          "WorkOrder": 4139347,
          "ParentWorkOrder": "",
          "JobName": "Nof Elga Credit Union",
          "JobNumber": "7313-102953-37",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6051 Grand Pointe Dr",
          "JobAddress2": "",
          "JobFullAddress": "6051 Grand Pointe Dr",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$66,965.29",
          "BranchNumber": 7313,
          "SubmitDate": "",
          "ClientonJob": "Commercial Contracting"
        },
        {
          "WorkOrder": 4139346,
          "ParentWorkOrder": "",
          "JobName": "Nof Eaton Hr",
          "JobNumber": "7312-104792-2",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "300 S East Ave",
          "JobAddress2": "",
          "JobFullAddress": "300 S East Ave",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Collins Design/build",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,001.14",
          "BranchNumber": 7312,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Collins Design/build"
        },
        {
          "WorkOrder": 4139345,
          "ParentWorkOrder": "",
          "JobName": "Nof Canton Medical",
          "JobNumber": "7313-104691-10",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "275 N Canton Center Rd",
          "JobAddress2": "",
          "JobFullAddress": "275 N Canton Center Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Bluestone Const Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$421.09",
          "BranchNumber": 7313,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Bluestone Const Group"
        },
        {
          "WorkOrder": 4139344,
          "ParentWorkOrder": "",
          "JobName": "Board Of Elections",
          "JobNumber": "7270-131588-3",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2300 Wall St",
          "JobAddress2": "Suite A",
          "JobFullAddress": "2300 Wall St Suite A",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Thumbs Up Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,389.98",
          "BranchNumber": 7270,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Thumbs Up Construction"
        },
        {
          "WorkOrder": 4139343,
          "ParentWorkOrder": "",
          "JobName": "R L Carriers",
          "JobNumber": "7348-132753-4",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "600 Gilliam Rd",
          "JobAddress2": "3rd Floor",
          "JobFullAddress": "600 Gilliam Rd 3rd Floor",
          "JobCounty": "CLINTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "R L Carriers",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$15,378.74",
          "BranchNumber": 7348,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "R L Carriers"
        },
        {
          "WorkOrder": 4139342,
          "ParentWorkOrder": "",
          "JobName": "Nof Bank Of America",
          "JobNumber": "7302-105222-3",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2065 E 79th St",
          "JobAddress2": "",
          "JobFullAddress": "2065 E 79th St",
          "JobCounty": "CUYAHOGA",
          "JobState": "Ohio",
          "CustomerCompanyName": "National Specialty Contractors LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$23,421.63",
          "BranchNumber": 7302,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "National Specialty Contractors LLC"
        },
        {
          "WorkOrder": 4139341,
          "ParentWorkOrder": "",
          "JobName": "Njp Clean Laundry",
          "JobNumber": "7302-104927-19",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1703 W Laskey Rd",
          "JobAddress2": "",
          "JobFullAddress": "1703 W Laskey Rd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Midwest Contracting Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,337.14",
          "BranchNumber": 7302,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Midwest Contracting Inc"
        },
        {
          "WorkOrder": 4139340,
          "ParentWorkOrder": "",
          "JobName": "Nof Trailwind Apartments",
          "JobNumber": "7302-104918-2",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "750 Trail Dr",
          "JobAddress2": "",
          "JobFullAddress": "750 Trail Dr",
          "JobCounty": "HENRY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Jet Drywall",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$25,776.68",
          "BranchNumber": 7302,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Jet Drywall"
        },
        {
          "WorkOrder": 4139339,
          "ParentWorkOrder": "",
          "JobName": "Mt. Carmel Urgent Care",
          "JobNumber": "7272-132711-8",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6599 E Broad St",
          "JobAddress2": "",
          "JobFullAddress": "6599 E Broad St",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "Integrity Interiors US LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$293.93",
          "BranchNumber": 7272,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "Integrity Interiors US LLC"
        },
        {
          "WorkOrder": 4139338,
          "ParentWorkOrder": "",
          "JobName": "Sun Treatment",
          "JobNumber": "7270-134638-7",
          "FirstdayontheJob": "06-20-2021",
          "LastdayontheJob": "06-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1421 Parker CT",
          "JobAddress2": "",
          "JobFullAddress": "1421 Parker CT",
          "JobCounty": "CLARK",
          "JobState": "Ohio",
          "CustomerCompanyName": "GPT Enterprise Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$46,664.90",
          "BranchNumber": 7270,
          "SubmitDate": "06-21-2021",
          "ClientonJob": "GPT Enterprise Inc"
        },
        {
          "WorkOrder": 4136862,
          "ParentWorkOrder": "",
          "JobName": "Westland Construction",
          "JobNumber": "7272-132450-4",
          "FirstdayontheJob": "06-09-2021",
          "LastdayontheJob": "06-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3870 Gteway Blvd",
          "JobAddress2": "",
          "JobFullAddress": "3870 Gteway Blvd",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Westland Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "N/A",
          "BranchNumber": 7272,
          "SubmitDate": "06-10-2021",
          "ClientonJob": "Westland Construction"
        },
        {
          "WorkOrder": 4136861,
          "ParentWorkOrder": "",
          "JobName": "HUNTINGTON",
          "JobNumber": "7272-132739-7",
          "FirstdayontheJob": "06-09-2021",
          "LastdayontheJob": "06-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5930 BRITTON PKWY",
          "JobAddress2": "",
          "JobFullAddress": "5930 BRITTON PKWY",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,254.78",
          "BranchNumber": 7272,
          "SubmitDate": "",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4136860,
          "ParentWorkOrder": "",
          "JobName": "ROSS DRESS",
          "JobNumber": "7272-132739-11",
          "FirstdayontheJob": "06-09-2021",
          "LastdayontheJob": "06-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5780 BRITTON PKWY",
          "JobAddress2": "",
          "JobFullAddress": "5780 BRITTON PKWY",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,009.65",
          "BranchNumber": 7272,
          "SubmitDate": "06-10-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4136859,
          "ParentWorkOrder": "",
          "JobName": "McDonalds Delhi Cincinnati",
          "JobNumber": "7348-131575-10",
          "FirstdayontheJob": "06-09-2021",
          "LastdayontheJob": "06-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5010 Delhi Pike",
          "JobAddress2": "",
          "JobFullAddress": "5010 Delhi Pike",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "LIS Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$24,626.45",
          "BranchNumber": 7348,
          "SubmitDate": "06-13-2021",
          "ClientonJob": "LIS Construction"
        },
        {
          "WorkOrder": 4136858,
          "ParentWorkOrder": "",
          "JobName": "aubry",
          "JobNumber": "7272-134623-8",
          "FirstdayontheJob": "06-09-2021",
          "LastdayontheJob": "06-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "PO Box 44009",
          "JobAddress2": "",
          "JobFullAddress": "PO Box 44009",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lake Hill Dev Group Col",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$4,603.25",
          "BranchNumber": 7272,
          "SubmitDate": "",
          "ClientonJob": "Lake Hill Dev Group Col"
        },
        {
          "WorkOrder": 4136857,
          "ParentWorkOrder": "",
          "JobName": "Heartland Buildout Specialists",
          "JobNumber": "7270-103441-2",
          "FirstdayontheJob": "06-09-2021",
          "LastdayontheJob": "06-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1011 Baker Dr.",
          "JobAddress2": "",
          "JobFullAddress": "1011 Baker Dr.",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Heartland Buildout Specialists",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,009.50",
          "BranchNumber": 7270,
          "SubmitDate": "06-13-2021",
          "ClientonJob": "Heartland Buildout Specialists"
        },
        {
          "WorkOrder": 4136856,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dundee Place #3",
          "JobNumber": "502-1084306-7",
          "FirstdayontheJob": "06-09-2021",
          "LastdayontheJob": "06-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "140 Helle Blvd.",
          "JobAddress2": "",
          "JobFullAddress": "140 Helle Blvd.",
          "JobCounty": "Monroe",
          "JobState": "Michigan",
          "CustomerCompanyName": "T&B CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$2,098.36",
          "BranchNumber": 13,
          "SubmitDate": "06-10-2021",
          "ClientonJob": "T&B CONSTRUCTION"
        },
        {
          "WorkOrder": 4136570,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TEXAS ROADHOUSE",
          "JobNumber": "502-821181-6",
          "FirstdayontheJob": "06-08-2021",
          "LastdayontheJob": "06-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2508 Tittabawassee Rd",
          "JobAddress2": "",
          "JobFullAddress": "2508 Tittabawassee Rd",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Monnin Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,103.91",
          "BranchNumber": 11,
          "SubmitDate": "06-09-2021",
          "ClientonJob": "Monnin Construction LLC"
        },
        {
          "WorkOrder": 4136569,
          "ParentWorkOrder": "",
          "JobName": "(NOF) APTIV",
          "JobNumber": "502-1507798-61",
          "FirstdayontheJob": "06-08-2021",
          "LastdayontheJob": "06-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5725 INNOVATION DR",
          "JobAddress2": "",
          "JobFullAddress": "5725 INNOVATION DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$11,058.06",
          "BranchNumber": 9,
          "SubmitDate": "06-09-2021",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4136568,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CANTON COFFEE SHOP",
          "JobNumber": "502-949575-134",
          "FirstdayontheJob": "06-08-2021",
          "LastdayontheJob": "06-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6124 N CANTON CENTER RD",
          "JobAddress2": "",
          "JobFullAddress": "6124 N CANTON CENTER RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$3,696.92",
          "BranchNumber": 9,
          "SubmitDate": "06-09-2021",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4136567,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BANQUETTE CTR YPSI",
          "JobNumber": "502-948945-63",
          "FirstdayontheJob": "06-08-2021",
          "LastdayontheJob": "06-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1275 S Huron St",
          "JobAddress2": "",
          "JobFullAddress": "1275 S Huron St",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trinity Building Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$69,006.00",
          "BalanceRemaining": "$22,206.95",
          "BranchNumber": 9,
          "SubmitDate": "06-09-2021",
          "ClientonJob": "Trinity Building Group"
        },
        {
          "WorkOrder": 4136566,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ARITZIA - SOMERSET",
          "JobNumber": "502-913820-624",
          "FirstdayontheJob": "06-08-2021",
          "LastdayontheJob": "06-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$947.16",
          "BranchNumber": 9,
          "SubmitDate": "06-09-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4136565,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-5139 OU Wilson",
          "JobNumber": "502-920940-97",
          "FirstdayontheJob": "06-08-2021",
          "LastdayontheJob": "06-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "371 WILSON BLVD.",
          "JobAddress2": "",
          "JobFullAddress": "371 WILSON BLVD.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$330,000.00",
          "BalanceRemaining": "$3,747.47",
          "BranchNumber": 8,
          "SubmitDate": "06-09-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4136564,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GKN SINTER",
          "JobNumber": "502-820500-49",
          "FirstdayontheJob": "06-08-2021",
          "LastdayontheJob": "06-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1670 N Opdyke Rd",
          "JobAddress2": "",
          "JobFullAddress": "1670 N Opdyke Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEE CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$20,487.27",
          "BranchNumber": 8,
          "SubmitDate": "06-09-2021",
          "ClientonJob": "LEE CONTRACTING"
        },
        {
          "WorkOrder": 4136563,
          "ParentWorkOrder": "",
          "JobName": "(NOF) PONTIAC HS ROOF",
          "JobNumber": "502-816350-166",
          "FirstdayontheJob": "06-08-2021",
          "LastdayontheJob": "06-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1051 ARLENE STREET",
          "JobAddress2": "",
          "JobFullAddress": "1051 ARLENE STREET",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$7,059.69",
          "BranchNumber": 8,
          "SubmitDate": "06-09-2021",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4136562,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Aldi White Lake",
          "JobNumber": "502-814500-89",
          "FirstdayontheJob": "06-08-2021",
          "LastdayontheJob": "06-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9054 Highland Rd.",
          "JobAddress2": "",
          "JobFullAddress": "9054 Highland Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONST",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "06-09-2021",
          "ClientonJob": "COMMERCIAL INT CONST"
        },
        {
          "WorkOrder": 4136561,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CONTRACTOR STEEL",
          "JobNumber": "502-1602587-2",
          "FirstdayontheJob": "06-08-2021",
          "LastdayontheJob": "06-08-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "48649 SCHOONER DR",
          "JobAddress2": "",
          "JobFullAddress": "48649 SCHOONER DR",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "IRIS CONSTRUCTION SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$17,552.00",
          "BranchNumber": 1,
          "SubmitDate": "06-09-2021",
          "ClientonJob": "IRIS CONSTRUCTION SERVICES"
        },
        {
          "WorkOrder": 4135546,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HAMPTON MANOR OF MAD",
          "JobNumber": "502-1691900-3",
          "FirstdayontheJob": "06-06-2021",
          "LastdayontheJob": "06-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3251 S Adrian Hwy",
          "JobAddress2": "",
          "JobFullAddress": "3251 S Adrian Hwy",
          "JobCounty": "LENAWEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INNOVATING DRYWALL SYSTEM",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$12,095.95",
          "BranchNumber": 13,
          "SubmitDate": "06-07-2021",
          "ClientonJob": "INNOVATING DRYWALL SYSTEM"
        },
        {
          "WorkOrder": 4135545,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FAIRFIELD INN",
          "JobNumber": "502-1308136-5",
          "FirstdayontheJob": "06-06-2021",
          "LastdayontheJob": "06-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5410 MILAN RD",
          "JobAddress2": "PO#20009-11",
          "JobFullAddress": "5410 MILAN RD PO#20009-11",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Schnippel Construction, Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$125,000.00",
          "BalanceRemaining": "$42,810.97",
          "BranchNumber": 13,
          "SubmitDate": "06-07-2021",
          "ClientonJob": "Schnippel Construction, Inc"
        },
        {
          "WorkOrder": 4135544,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dunkin Donuts",
          "JobNumber": "502-1304186-75",
          "FirstdayontheJob": "06-06-2021",
          "LastdayontheJob": "06-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2251 West State Street",
          "JobAddress2": "",
          "JobFullAddress": "2251 West State Street",
          "JobCounty": "SANDUSKY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,659.57",
          "BranchNumber": 13,
          "SubmitDate": "06-07-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4134582,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CLEAN JUICE",
          "JobNumber": "502-1551310-13",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "641 W GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "641 W GRAND RIVER AVE",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELITE BUILDERS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$7,335.34",
          "BranchNumber": 11,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "ELITE BUILDERS LLC"
        },
        {
          "WorkOrder": 4134581,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Fox Run Village Inc",
          "JobNumber": "502-1551310-12",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41100 Fox Run Rd",
          "JobAddress2": "",
          "JobFullAddress": "41100 Fox Run Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELITE BUILDERS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$9,036.84",
          "BranchNumber": 11,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "ELITE BUILDERS LLC"
        },
        {
          "WorkOrder": 4134580,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HMOR-43 Bronx",
          "JobNumber": "502-877250-182",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26545 Bronx Dr Unit 43",
          "JobAddress2": "",
          "JobFullAddress": "26545 Bronx Dr Unit 43",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Consruction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$5,715.86",
          "BranchNumber": 11,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "Blue Water Drywall & Consruction"
        },
        {
          "WorkOrder": 4134579,
          "ParentWorkOrder": "",
          "JobName": "(NOF) OASIS HOMES",
          "JobNumber": "502-869501-126",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20510 31 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "20510 31 MILE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Don Kerby Drywall",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,506.00",
          "BalanceRemaining": "$7,815.95",
          "BranchNumber": 11,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "Don Kerby Drywall"
        },
        {
          "WorkOrder": 4134578,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TIMBERWOOD HOMES",
          "JobNumber": "502-861507-73",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2310 ALGONA CT",
          "JobAddress2": "",
          "JobFullAddress": "2310 ALGONA CT",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "LARRY CLEVELAND",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$12,568.11",
          "BranchNumber": 11,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "LARRY CLEVELAND"
        },
        {
          "WorkOrder": 4134577,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Omni Property",
          "JobNumber": "502-847401-193",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24400 Novi Rd. Suite#104",
          "JobAddress2": "",
          "JobFullAddress": "24400 Novi Rd. Suite#104",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$5,981.97",
          "BranchNumber": 11,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4134576,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Shelby Psych",
          "JobNumber": "502-934634-25",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "46810 Schoenherr Rd",
          "JobAddress2": "",
          "JobFullAddress": "46810 Schoenherr Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONSTRUCTION GROUP",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$5,496.66",
          "BranchNumber": 9,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "BLUESTONE CONSTRUCTION GROUP"
        },
        {
          "WorkOrder": 4134575,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CANTON MEDICAL",
          "JobNumber": "502-934634-24",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "275 N Canton Center Rd",
          "JobAddress2": "",
          "JobFullAddress": "275 N Canton Center Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONST. GROUP",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$421.09",
          "BranchNumber": 9,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "BLUESTONE CONST. GROUP"
        },
        {
          "WorkOrder": 4134574,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Grocer Major G",
          "JobNumber": "502-934634-23",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "662 E Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "662 E Big Beaver Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONSTRUCTION GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$7,504.80",
          "BranchNumber": 9,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "BLUESTONE CONSTRUCTION GROUP"
        },
        {
          "WorkOrder": 4134573,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CHILSON URGENT CARE",
          "JobNumber": "502-916315-226",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9551 CHILSON COMMONS",
          "JobAddress2": "",
          "JobFullAddress": "9551 CHILSON COMMONS",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMMERCIAL CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$11,191.12",
          "BranchNumber": 9,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "INDEPENDENCE COMMERCIAL CONSTRUCTION"
        },
        {
          "WorkOrder": 4134572,
          "ParentWorkOrder": "",
          "JobName": "(NOF)5TH FLOOR PAIN CLINI",
          "JobNumber": "502-913820-625",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3990 JOHN R ST",
          "JobAddress2": "DMC DETROIT",
          "JobFullAddress": "3990 JOHN R ST DMC DETROIT",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4134571,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LAB RENO ST JOSEPH",
          "JobNumber": "502-913820-621",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5301 MCAULEY DR",
          "JobAddress2": "",
          "JobFullAddress": "5301 MCAULEY DR",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$543.06",
          "BranchNumber": 9,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4134570,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NORTHWEST DERMOTOLO",
          "JobNumber": "502-911900-6",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32270 S TELEGRAPH RD",
          "JobAddress2": "",
          "JobFullAddress": "32270 S TELEGRAPH RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRANBETH CONSTRUCTION. INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$4,298.34",
          "BranchNumber": 9,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "BRANBETH CONSTRUCTION. INC"
        },
        {
          "WorkOrder": 4134569,
          "ParentWorkOrder": "",
          "JobName": "(NOF)UWM CONVENIENCE CORR",
          "JobNumber": "502-1639418-40",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "700 SOUTH E BLVD",
          "JobAddress2": "",
          "JobFullAddress": "700 SOUTH E BLVD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$3,927.47",
          "BranchNumber": 8,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4134568,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-5151 Orchard Hill",
          "JobNumber": "502-920940-104",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41900 Quince Dr",
          "JobAddress2": "",
          "JobFullAddress": "41900 Quince Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4134567,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-5151 Village Oaks",
          "JobNumber": "502-920940-103",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23333 Willowbrook Dr.",
          "JobAddress2": "",
          "JobFullAddress": "23333 Willowbrook Dr.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4134566,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Jordan 3250",
          "JobNumber": "502-876650-399",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3250 Auburn Rd.",
          "JobAddress2": "",
          "JobFullAddress": "3250 Auburn Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$6,899.78",
          "BranchNumber": 8,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4134564,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FERNDALE ELECTRIC",
          "JobNumber": "502-843701-225",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31750 SHERMAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "31750 SHERMAN AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$14,751.37",
          "BranchNumber": 8,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 4134563,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Szott Dealership",
          "JobNumber": "502-817800-56",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "57495 W Lyon Center Dr.",
          "JobAddress2": "",
          "JobFullAddress": "57495 W Lyon Center Dr.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4134562,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ELGA Credit Union",
          "JobNumber": "502-813200-156",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6051 Grand Pointe Blvd.",
          "JobAddress2": "",
          "JobFullAddress": "6051 Grand Pointe Blvd.",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$175,000.00",
          "BalanceRemaining": "$66,965.29",
          "BranchNumber": 8,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4134561,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DORSEY SCHOOL",
          "JobNumber": "502-16564-10",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31739 JOHN R RD",
          "JobAddress2": "",
          "JobFullAddress": "31739 JOHN R RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARINE CITY CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$7,899.39",
          "BranchNumber": 8,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "MARINE CITY CONTRACTING"
        },
        {
          "WorkOrder": 4134560,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TGW PHASE 2",
          "JobNumber": "502-1492164-12",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3001 ORCHARD VISTA DR SE",
          "JobAddress2": "",
          "JobFullAddress": "3001 ORCHARD VISTA DR SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRIAD INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$1,991.86",
          "BranchNumber": 5,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "TRIAD INTERIORS LLC"
        },
        {
          "WorkOrder": 4134559,
          "ParentWorkOrder": "",
          "JobName": "(NOF) VIRGINIA TILE",
          "JobNumber": "502-1524600-11",
          "FirstdayontheJob": "06-02-2021",
          "LastdayontheJob": "06-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "900 W MAPLE RD",
          "JobAddress2": "",
          "JobFullAddress": "900 W MAPLE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Sterling Millwork Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$14,028.75",
          "BranchNumber": 1,
          "SubmitDate": "06-03-2021",
          "ClientonJob": "Sterling Millwork Inc"
        },
        {
          "WorkOrder": 4134196,
          "ParentWorkOrder": 4119659,
          "JobName": "Emerson/Experitec Tenant Improvement Project",
          "JobNumber": 8021011689,
          "FirstdayontheJob": "01-01-2021",
          "LastdayontheJob": "02-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11325 Strang Line Rd",
          "JobAddress2": "",
          "JobFullAddress": "11325 Strang Line Rd",
          "JobCounty": "Johnson",
          "JobState": "Kansas",
          "CustomerCompanyName": "Construction Designworks",
          "RequestType": "Satisfaction",
          "CustomerRepresentative": "Pamela Willard",
          "Status": "Recorded",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,955.90",
          "BranchNumber": 7273,
          "SubmitDate": "06-02-2021",
          "ClientonJob": "Construction Designworks"
        },
        {
          "WorkOrder": 4134120,
          "ParentWorkOrder": "",
          "JobName": "Curtis Row",
          "JobNumber": "7270-133987-25",
          "FirstdayontheJob": "06-01-2021",
          "LastdayontheJob": "06-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "921 Curtis St",
          "JobAddress2": "",
          "JobFullAddress": "921 Curtis St",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Triversity Craft Force LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$10,059.46",
          "BranchNumber": 7270,
          "SubmitDate": "06-02-2021",
          "ClientonJob": "Triversity Craft Force LLC"
        },
        {
          "WorkOrder": 4134119,
          "ParentWorkOrder": "",
          "JobName": "Housing Network Of Hamilton",
          "JobNumber": "7270-132715-12",
          "FirstdayontheJob": "06-01-2021",
          "LastdayontheJob": "06-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "242 W Sharon Rd",
          "JobAddress2": "",
          "JobFullAddress": "242 W Sharon Rd",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lemcer Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$13,240.63",
          "BranchNumber": 7270,
          "SubmitDate": "06-02-2021",
          "ClientonJob": "Lemcer Construction LLC"
        },
        {
          "WorkOrder": 4134118,
          "ParentWorkOrder": "",
          "JobName": "Interact For Health",
          "JobNumber": "7270-132711-7",
          "FirstdayontheJob": "06-01-2021",
          "LastdayontheJob": "06-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8230 Montgomery Rd Ste 300",
          "JobAddress2": "",
          "JobFullAddress": "8230 Montgomery Rd Ste 300",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Integrity Interiors US LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$626.11",
          "BranchNumber": 7270,
          "SubmitDate": "06-02-2021",
          "ClientonJob": "Integrity Interiors US LLC"
        },
        {
          "WorkOrder": 4134116,
          "ParentWorkOrder": "",
          "JobName": "Sun Treatment",
          "JobNumber": "7270-134638-7",
          "FirstdayontheJob": "06-01-2021",
          "LastdayontheJob": "06-01-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1421 Parker Ct",
          "JobAddress2": "",
          "JobFullAddress": "1421 Parker Ct",
          "JobCounty": "CLARK",
          "JobState": "Ohio",
          "CustomerCompanyName": "GPT Enterprise Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$46,042.02",
          "BranchNumber": 7270,
          "SubmitDate": "06-02-2021",
          "ClientonJob": "GPT Enterprise Inc"
        },
        {
          "WorkOrder": 4133801,
          "ParentWorkOrder": 4128616,
          "JobName": "(NOF)MCS Ford Train",
          "JobNumber": "502-815250-387",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2001 15th Street",
          "JobAddress2": "",
          "JobFullAddress": "2001 15th Street",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "06-01-2021",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4133334,
          "ParentWorkOrder": "",
          "JobName": "Bank of Amerca",
          "JobNumber": "7272-132739-8",
          "FirstdayontheJob": "05-27-2021",
          "LastdayontheJob": "05-27-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4160 Anson Dr",
          "JobAddress2": "",
          "JobFullAddress": "4160 Anson Dr",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$225.76",
          "BranchNumber": 7272,
          "SubmitDate": "",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4133333,
          "ParentWorkOrder": "",
          "JobName": "CARBON HEALTH",
          "JobNumber": "7272-132739-9",
          "FirstdayontheJob": "05-27-2021",
          "LastdayontheJob": "05-27-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1603 W LANE AVE.",
          "JobAddress2": "",
          "JobFullAddress": "1603 W LANE AVE.",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Wayne Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,823.15",
          "BranchNumber": 7272,
          "SubmitDate": "05-28-2021",
          "ClientonJob": "Wayne Construction"
        },
        {
          "WorkOrder": 4133332,
          "ParentWorkOrder": "",
          "JobName": "Uptown OB1",
          "JobNumber": "7270-133987-23",
          "FirstdayontheJob": "05-27-2021",
          "LastdayontheJob": "05-27-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "620 Lincoln Ave",
          "JobAddress2": "",
          "JobFullAddress": "620 Lincoln Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Triversity Craft Force LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$609.07",
          "BranchNumber": 7270,
          "SubmitDate": "05-28-2021",
          "ClientonJob": "Triversity Craft Force LLC"
        },
        {
          "WorkOrder": 4133331,
          "ParentWorkOrder": "",
          "JobName": "Medpace B 100",
          "JobNumber": "7270-132755-37",
          "FirstdayontheJob": "05-27-2021",
          "LastdayontheJob": "05-27-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5375 Medpace Way",
          "JobAddress2": "",
          "JobFullAddress": "5375 Medpace Way",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T Square Wall Systems LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$17,147.59",
          "BranchNumber": 7270,
          "SubmitDate": "05-28-2021",
          "ClientonJob": "T Square Wall Systems LLC"
        },
        {
          "WorkOrder": 4133330,
          "ParentWorkOrder": "",
          "JobName": "Otterbein Additions",
          "JobNumber": "7348-133878-6",
          "FirstdayontheJob": "05-27-2021",
          "LastdayontheJob": "05-27-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "105 Atrium Dr",
          "JobAddress2": "",
          "JobFullAddress": "105 Atrium Dr",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "RCS Construction",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,609.27",
          "BranchNumber": 7348,
          "SubmitDate": "05-28-2021",
          "ClientonJob": "RCS Construction"
        },
        {
          "WorkOrder": 4133329,
          "ParentWorkOrder": "",
          "JobName": "Kingston National Bank",
          "JobNumber": "7272-132736-7",
          "FirstdayontheJob": "05-27-2021",
          "LastdayontheJob": "05-27-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2 N Main St",
          "JobAddress2": "",
          "JobFullAddress": "2 N Main St",
          "JobCounty": "ROSS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Procon Professional Construction Services Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$163.06",
          "BranchNumber": 7272,
          "SubmitDate": "05-28-2021",
          "ClientonJob": "Procon Professional Construction Services Inc"
        },
        {
          "WorkOrder": 4133328,
          "ParentWorkOrder": "",
          "JobName": "Nof Savana Ridge",
          "JobNumber": "7270-132712-3",
          "FirstdayontheJob": "05-27-2021",
          "LastdayontheJob": "05-27-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1252 Redleaf Dr",
          "JobAddress2": "",
          "JobFullAddress": "1252 Redleaf Dr",
          "JobCounty": "CLERMONT",
          "JobState": "Ohio",
          "CustomerCompanyName": "Newphase Drywall LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$475.87",
          "BranchNumber": 7270,
          "SubmitDate": "05-28-2021",
          "ClientonJob": "Newphase Drywall LLC"
        },
        {
          "WorkOrder": 4133327,
          "ParentWorkOrder": "",
          "JobName": "Cntrville Grace Church",
          "JobNumber": "7348-131575-7",
          "FirstdayontheJob": "05-27-2021",
          "LastdayontheJob": "05-27-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "410 E Social Row Rd",
          "JobAddress2": "",
          "JobFullAddress": "410 E Social Row Rd",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "LIS Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$4,251.41",
          "BranchNumber": 7348,
          "SubmitDate": "05-28-2021",
          "ClientonJob": "LIS Construction"
        },
        {
          "WorkOrder": 4133326,
          "ParentWorkOrder": "",
          "JobName": "WESBANCO",
          "JobNumber": "7348-132715-11",
          "FirstdayontheJob": "05-27-2021",
          "LastdayontheJob": "05-27-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1243 N FAIRFIELD RD",
          "JobAddress2": "",
          "JobFullAddress": "1243 N FAIRFIELD RD",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lemcer Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,703.53",
          "BranchNumber": 7348,
          "SubmitDate": "05-28-2021",
          "ClientonJob": "Lemcer Construction LLC"
        },
        {
          "WorkOrder": 4133325,
          "ParentWorkOrder": "",
          "JobName": "MARICHRON",
          "JobNumber": "7347-132867-4",
          "FirstdayontheJob": "05-27-2021",
          "LastdayontheJob": "05-27-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1011 Baker Rd",
          "JobAddress2": "",
          "JobFullAddress": "1011 Baker Rd",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "David Brunemann Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$837.61",
          "BranchNumber": 7347,
          "SubmitDate": "",
          "ClientonJob": "David Brunemann Construction"
        },
        {
          "WorkOrder": 4133323,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Schutz Container",
          "JobNumber": "502-1304700-28",
          "FirstdayontheJob": "05-27-2021",
          "LastdayontheJob": "05-27-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2105 S Wilkinson Way",
          "JobAddress2": "",
          "JobFullAddress": "2105 S Wilkinson Way",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "LATHROP COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$48,658.31",
          "BranchNumber": 13,
          "SubmitDate": "05-28-2021",
          "ClientonJob": "LATHROP COMPANY"
        },
        {
          "WorkOrder": 4133321,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Neuroscience",
          "JobNumber": "502-1304700-27",
          "FirstdayontheJob": "05-27-2021",
          "LastdayontheJob": "05-27-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2213 Cherry St",
          "JobAddress2": "Job #210274",
          "JobFullAddress": "2213 Cherry St Job #210274",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "LATHROP COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$16,774.56",
          "BranchNumber": 13,
          "SubmitDate": "05-28-2021",
          "ClientonJob": "LATHROP COMPANY"
        },
        {
          "WorkOrder": 4132692,
          "ParentWorkOrder": 4114935,
          "JobName": "(NOF) ASCENSION MACKEY",
          "JobNumber": "502-933550-204",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19251 Mack Ave",
          "JobAddress2": "",
          "JobFullAddress": "19251 Mack Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$871.58",
          "BranchNumber": 9,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4132679,
          "ParentWorkOrder": 4080182,
          "JobName": "(NOF)LIVONIA DPW   20016",
          "JobNumber": "502-933550-186",
          "FirstdayontheJob": "11-18-2020",
          "LastdayontheJob": "11-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12973 FARMINGTON RD",
          "JobAddress2": "",
          "JobFullAddress": "12973 FARMINGTON RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$16,816.91",
          "BranchNumber": 9,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4132573,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DANA HR PLANT WALL",
          "JobNumber": "502-1650650-19",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "28201 Van Dyke Ave",
          "JobAddress2": "",
          "JobFullAddress": "28201 Van Dyke Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDUSTRIAL TECH SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$2,521.20",
          "BranchNumber": 11,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "INDUSTRIAL TECH SERVICES"
        },
        {
          "WorkOrder": 4132572,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Res. @ Chesterfield",
          "JobNumber": "502-847401-192",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45301 Marketplace Blvd",
          "JobAddress2": "",
          "JobFullAddress": "45301 Marketplace Blvd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$7,215.21",
          "BranchNumber": 11,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4132571,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ARCADIA LEGACY 179",
          "JobNumber": "502-164470-25",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23815 RAVENSWOOD RD",
          "JobAddress2": "",
          "JobFullAddress": "23815 RAVENSWOOD RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,806.00",
          "BalanceRemaining": "$3,614.73",
          "BranchNumber": 11,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4132570,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MJC-FOX CREEK #86",
          "JobNumber": "502-164470-24",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17697 WOODSTOCK CT",
          "JobAddress2": "",
          "JobFullAddress": "17697 WOODSTOCK CT",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,806.00",
          "BalanceRemaining": "$3,516.78",
          "BranchNumber": 11,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4132569,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MJC 87 FOX CREEK",
          "JobNumber": "502-164470-23",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17721 WOODSTOCK CT",
          "JobAddress2": "",
          "JobFullAddress": "17721 WOODSTOCK CT",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,129.60",
          "BranchNumber": 11,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4132568,
          "ParentWorkOrder": "",
          "JobName": "(NOF) USI",
          "JobNumber": "502-944100-351",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2600 W BIG BEAVER RD #140",
          "JobAddress2": "",
          "JobFullAddress": "2600 W BIG BEAVER RD #140",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,773.62",
          "BranchNumber": 9,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4132567,
          "ParentWorkOrder": "",
          "JobName": "(NOF) IHS",
          "JobNumber": "502-920000-30",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26555 EVERGREEN RD",
          "JobAddress2": "",
          "JobFullAddress": "26555 EVERGREEN RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NVR FINISHES, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$17,572.53",
          "BranchNumber": 9,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "NVR FINISHES, INC."
        },
        {
          "WorkOrder": 4132566,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Ciennea of Hartford",
          "JobNumber": "502-1639418-39",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6700 W Outer Dr",
          "JobAddress2": "",
          "JobFullAddress": "6700 W Outer Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$1,640.24",
          "BranchNumber": 8,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4132564,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WebberElem LakeOrion",
          "JobNumber": "502-920940-107",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3191 W Clarkstron Rd.",
          "JobAddress2": "",
          "JobFullAddress": "3191 W Clarkstron Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4132563,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Zuzo Orthodontics",
          "JobNumber": "502-823300-85",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "49471 Ann Arbor Rd W",
          "JobAddress2": "",
          "JobFullAddress": "49471 Ann Arbor Rd W",
          "JobCounty": "WANYE",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 4132562,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Allied Printing",
          "JobNumber": "502-815630-248",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22438 Woodward Avenue",
          "JobAddress2": "",
          "JobFullAddress": "22438 Woodward Avenue",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4132561,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1 Ford Place Kitchen",
          "JobNumber": "502-815250-390",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Ford Place",
          "JobAddress2": "Order#P-211014-01",
          "JobFullAddress": "1 Ford Place Order#P-211014-01",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4132560,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Cornerstone Com",
          "JobNumber": "502-814500-87",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2955 University Dr.",
          "JobAddress2": "",
          "JobFullAddress": "2955 University Dr.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$304.33",
          "BranchNumber": 8,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "COMMERCIAL INT CONSTRUCTION"
        },
        {
          "WorkOrder": 4132559,
          "ParentWorkOrder": "",
          "JobName": "(NOF) United Shore Financ",
          "JobNumber": "502-814500-86",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "585 SOUTH E BLVD.",
          "JobAddress2": "",
          "JobFullAddress": "585 SOUTH E BLVD.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,920.92",
          "BranchNumber": 8,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "COMMERCIAL INT CONSTRUCTION"
        },
        {
          "WorkOrder": 4132558,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GM Tech Center",
          "JobNumber": "502-1606296-14",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30400 Van Dyke Ave",
          "JobAddress2": "",
          "JobFullAddress": "30400 Van Dyke Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Regis Construction, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "Regis Construction, LLC"
        },
        {
          "WorkOrder": 4132557,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CWWTP",
          "JobNumber": "502-1060142-46",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8701 Cherry Valley Ave SE",
          "JobAddress2": "",
          "JobFullAddress": "8701 Cherry Valley Ave SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,000.00",
          "BalanceRemaining": "$473.43",
          "BranchNumber": 5,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 4132556,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FARWELL ELEMENTARY",
          "JobNumber": "502-30420-20",
          "FirstdayontheJob": "05-25-2021",
          "LastdayontheJob": "05-25-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "399 E MICHIGAN ST",
          "JobAddress2": "BP4",
          "JobFullAddress": "399 E MICHIGAN ST BP4",
          "JobCounty": "CLAIRE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENALI CONSTRUCTION, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$8,228.89",
          "BranchNumber": 1,
          "SubmitDate": "05-26-2021",
          "ClientonJob": "DENALI CONSTRUCTION, INC."
        },
        {
          "WorkOrder": 4131152,
          "ParentWorkOrder": "",
          "JobName": "McDonald's #738",
          "JobNumber": 131575,
          "FirstdayontheJob": "05-17-2021",
          "LastdayontheJob": "05-17-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "738 Corwin Nixon Blvd",
          "JobAddress2": "",
          "JobFullAddress": "738 Corwin Nixon Blvd",
          "JobCounty": "Warren",
          "JobState": "Ohio",
          "CustomerCompanyName": "LIS CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "N/A",
          "BranchNumber": 7348,
          "SubmitDate": "05-21-2021",
          "ClientonJob": "LIS CONSTRUCTION"
        },
        {
          "WorkOrder": 4130779,
          "ParentWorkOrder": "",
          "JobName": "Ensemble",
          "JobNumber": "7270-133987-22",
          "FirstdayontheJob": "05-19-2021",
          "LastdayontheJob": "05-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11511 Reed Hartman Hwy Ste 330",
          "JobAddress2": "",
          "JobFullAddress": "11511 Reed Hartman Hwy Ste 330",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Triversity Craft Force LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,070.80",
          "BranchNumber": 7270,
          "SubmitDate": "05-20-2021",
          "ClientonJob": "Triversity Craft Force LLC"
        },
        {
          "WorkOrder": 4130778,
          "ParentWorkOrder": "",
          "JobName": "Miami Valley Hospital Mant",
          "JobNumber": "7348-102058-4",
          "FirstdayontheJob": "05-19-2021",
          "LastdayontheJob": "05-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Wyoming St",
          "JobAddress2": "",
          "JobFullAddress": "1 Wyoming St",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Synovos",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,946.71",
          "BranchNumber": 7348,
          "SubmitDate": "05-20-2021",
          "ClientonJob": "Synovos"
        },
        {
          "WorkOrder": 4130777,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Harbor Town Sr Resi",
          "JobNumber": "502-1307559-2",
          "FirstdayontheJob": "05-19-2021",
          "LastdayontheJob": "05-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7228 Lighthouse Way",
          "JobAddress2": "",
          "JobFullAddress": "7228 Lighthouse Way",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "RIDGE STONE BUILDERS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,701.92",
          "BranchNumber": 13,
          "SubmitDate": "05-20-2021",
          "ClientonJob": "RIDGE STONE BUILDERS"
        },
        {
          "WorkOrder": 4130776,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TARGET #0673",
          "JobNumber": "502-134220-14",
          "FirstdayontheJob": "05-19-2021",
          "LastdayontheJob": "05-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1076 JACKSON CROSSING",
          "JobAddress2": "",
          "JobFullAddress": "1076 JACKSON CROSSING",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "HORIZON RETAIL CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,087.19",
          "BranchNumber": 13,
          "SubmitDate": "05-20-2021",
          "ClientonJob": "HORIZON RETAIL CONSTRUCTION"
        },
        {
          "WorkOrder": 4130775,
          "ParentWorkOrder": "",
          "JobName": "McDonalds Cincinnati Glenway",
          "JobNumber": 127581,
          "FirstdayontheJob": "04-20-2021",
          "LastdayontheJob": "04-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6433 Glenway Ave",
          "JobAddress2": "",
          "JobFullAddress": "6433 Glenway Ave",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "LIS CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "N/A",
          "BranchNumber": 348,
          "SubmitDate": "05-20-2021",
          "ClientonJob": "LIS CONSTRUCTION"
        },
        {
          "WorkOrder": 4130614,
          "ParentWorkOrder": 4117612,
          "JobName": "(NOF)OAKSIDE SCHOLAR",
          "JobNumber": "502-916315-213",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "355 SUMMIT DR",
          "JobAddress2": "",
          "JobFullAddress": "355 SUMMIT DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$265,006.00",
          "BalanceRemaining": "$23,018.64",
          "BranchNumber": 9,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONSTRUCTION"
        },
        {
          "WorkOrder": 4130598,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Trop Smooth-Detroit",
          "JobNumber": "502-1551310-9",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "220 W Congress St",
          "JobAddress2": "",
          "JobFullAddress": "220 W Congress St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELITE BUILDERS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$8,257.55",
          "BranchNumber": 11,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "ELITE BUILDERS LLC"
        },
        {
          "WorkOrder": 4130597,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Woodfield Sub-3409",
          "JobNumber": "502-877250-181",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3409 WOODFIELD BLVD",
          "JobAddress2": "",
          "JobFullAddress": "3409 WOODFIELD BLVD",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$5,082.68",
          "BranchNumber": 11,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "Blue Water Drywall & Construction"
        },
        {
          "WorkOrder": 4130596,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DOLLAR GEN-GR LEDGE",
          "JobNumber": "502-864751-240",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "940 N CLINTON ST",
          "JobAddress2": "M100",
          "JobFullAddress": "940 N CLINTON ST M100",
          "JobCounty": "EATON",
          "JobState": "Michigan",
          "CustomerCompanyName": "STILES DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$2,544.80",
          "BranchNumber": 11,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "STILES DRYWALL LLC"
        },
        {
          "WorkOrder": 4130595,
          "ParentWorkOrder": "",
          "JobName": "(NOF)32028 SCHOOL SECTION",
          "JobNumber": "502-847401-191",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32028 SCHOOL SECTION RD",
          "JobAddress2": "",
          "JobFullAddress": "32028 SCHOOL SECTION RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,912.78",
          "BranchNumber": 11,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4130594,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LOT6 B.F. GROVE",
          "JobNumber": "502-847401-190",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "176 TIMBER TRACE LN",
          "JobAddress2": "",
          "JobFullAddress": "176 TIMBER TRACE LN",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$5,279.43",
          "BranchNumber": 11,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4130592,
          "ParentWorkOrder": "",
          "JobName": "(NOF)UNIT 52 WOODBRIDGE",
          "JobNumber": "502-847401-189",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1383 LINCOLN STREET",
          "JobAddress2": "",
          "JobFullAddress": "1383 LINCOLN STREET",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$2,957.48",
          "BranchNumber": 11,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4130591,
          "ParentWorkOrder": "",
          "JobName": "(NOF)UNIT 51 WOODBRIDGE",
          "JobNumber": "502-847401-188",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1385 LINCOLN STREET",
          "JobAddress2": "",
          "JobFullAddress": "1385 LINCOLN STREET",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$3,129.68",
          "BranchNumber": 11,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4130590,
          "ParentWorkOrder": "",
          "JobName": "(NOF)UNIT 50 WOODBRIDGE",
          "JobNumber": "502-847401-187",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1387 LINCOLN STREET",
          "JobAddress2": "",
          "JobFullAddress": "1387 LINCOLN STREET",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$3,129.68",
          "BranchNumber": 11,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4130589,
          "ParentWorkOrder": "",
          "JobName": "(NOF)UNIT 49 WOODBRIDGE",
          "JobNumber": "502-847401-186",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1389 LINCOLN STREET",
          "JobAddress2": "",
          "JobFullAddress": "1389 LINCOLN STREET",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$2,763.26",
          "BranchNumber": 11,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4130588,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 47705 Card Rd",
          "JobNumber": "502-164470-22",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "47705 Card Rd",
          "JobAddress2": "",
          "JobFullAddress": "47705 Card Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$5,323.95",
          "BranchNumber": 11,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4130587,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Acadia-Legacy #178",
          "JobNumber": "502-164470-21",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23423 Legacy Blvd",
          "JobAddress2": "",
          "JobFullAddress": "23423 Legacy Blvd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,506.00",
          "BalanceRemaining": "$3,411.01",
          "BranchNumber": 11,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4130586,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BOOK TOWER",
          "JobNumber": "502-1677788-2",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1265 WASHINGTON BLVD",
          "JobAddress2": "",
          "JobFullAddress": "1265 WASHINGTON BLVD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "WOLVERINE STONE COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$442.85",
          "BranchNumber": 9,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "WOLVERINE STONE COMPANY"
        },
        {
          "WorkOrder": 4130585,
          "ParentWorkOrder": "",
          "JobName": "(NOF) UNIVERSITY TRAILS",
          "JobNumber": "502-1507798-62",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 W UNIVERSITY DR",
          "JobAddress2": "",
          "JobFullAddress": "1000 W UNIVERSITY DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$4,421.50",
          "BranchNumber": 9,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4130584,
          "ParentWorkOrder": "",
          "JobName": "(NOF)VIBE C.U - NOVI",
          "JobNumber": "502-1507798-60",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44777 W 12 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "44777 W 12 MILE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$51,301.95",
          "BranchNumber": 9,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4130583,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TACO BELL DELPHOS",
          "JobNumber": "502-916315-223",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "201 Elida Rd",
          "JobAddress2": "",
          "JobFullAddress": "201 Elida Rd",
          "JobCounty": "ALLEN",
          "JobState": "Ohio",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$1,603.49",
          "BranchNumber": 9,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4130582,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Advanced Auto Parts",
          "JobNumber": "502-916315-2",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21 Mile Rd & Hayes Rd.",
          "JobAddress2": "",
          "JobFullAddress": "21 Mile Rd & Hayes Rd.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,700.00",
          "BalanceRemaining": "$1,349.71",
          "BranchNumber": 9,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4130581,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Laura Residence",
          "JobNumber": "502-1621098-3",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "323 Highland Ave.",
          "JobAddress2": "",
          "JobFullAddress": "323 Highland Ave.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "FACE CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,006.00",
          "BalanceRemaining": "$1,013.58",
          "BranchNumber": 8,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "FACE CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 4130580,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GROESBECK HWY",
          "JobNumber": "502-1611850-3",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25800 GROESBECK HWY",
          "JobAddress2": "",
          "JobFullAddress": "25800 GROESBECK HWY",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "J & J INDUSTRIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,924.64",
          "BranchNumber": 8,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "J & J INDUSTRIAL CONTRACTING"
        },
        {
          "WorkOrder": 4130579,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Patrick Chesterton",
          "JobNumber": "502-1604171-37",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1047 Yorkshire Rd",
          "JobAddress2": "",
          "JobFullAddress": "1047 Yorkshire Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,621.01",
          "BranchNumber": 8,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4130578,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Burger King",
          "JobNumber": "502-876650-396",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2920 Frontage Rd.",
          "JobAddress2": "",
          "JobFullAddress": "2920 Frontage Rd.",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,887.98",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4130577,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Stadium Dr. Elem.",
          "JobNumber": "502-817800-55",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "244 Stadium Drive",
          "JobAddress2": "",
          "JobFullAddress": "244 Stadium Drive",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE COMPANY INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "HURON ACOUSTIC TILE COMPANY INC"
        },
        {
          "WorkOrder": 4130575,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Cranbrook Church",
          "JobNumber": "502-815750-320",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "470 Church Rd",
          "JobAddress2": "",
          "JobFullAddress": "470 Church Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION"
        },
        {
          "WorkOrder": 4130574,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Developers Academy",
          "JobNumber": "502-815750-319",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "600 Woodward Ave",
          "JobAddress2": "(first national bldg)",
          "JobFullAddress": "600 Woodward Ave (first national bldg)",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION"
        },
        {
          "WorkOrder": 4130572,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Project Worley",
          "JobNumber": "502-815750-318",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "46555 Magellan Dr",
          "JobAddress2": "",
          "JobFullAddress": "46555 Magellan Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION"
        },
        {
          "WorkOrder": 4130571,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Gerstacker",
          "JobNumber": "502-813200-155",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2200 Bonisteel Bvd.",
          "JobAddress2": "",
          "JobFullAddress": "2200 Bonisteel Bvd.",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4130570,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SURVIVAL FITNESS",
          "JobNumber": "502-163171-4",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "228 WASHINGTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "228 WASHINGTON AVE",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW ADVENTURE DEVELOPMENT",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$14,283.08",
          "BranchNumber": 1,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "NEW ADVENTURE DEVELOPMENT"
        },
        {
          "WorkOrder": 4130569,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SAGINAW VA",
          "JobNumber": "502-12540-34",
          "FirstdayontheJob": "05-18-2021",
          "LastdayontheJob": "05-18-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2830 MCCARTY RD.",
          "JobAddress2": "",
          "JobFullAddress": "2830 MCCARTY RD.",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "LUPE DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,469.19",
          "BranchNumber": 1,
          "SubmitDate": "05-19-2021",
          "ClientonJob": "LUPE DRYWALL"
        },
        {
          "WorkOrder": 4130100,
          "ParentWorkOrder": "",
          "JobName": "Schutz Container",
          "JobNumber": 1304700,
          "FirstdayontheJob": "05-17-2021",
          "LastdayontheJob": "05-17-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2105 S Wilkinson Way",
          "JobAddress2": "",
          "JobFullAddress": "2105 S Wilkinson Way",
          "JobCounty": "Wood",
          "JobState": "Ohio",
          "CustomerCompanyName": "LATHROP COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "N/A",
          "BranchNumber": 7302,
          "SubmitDate": "05-18-2021",
          "ClientonJob": "LATHROP COMPANY"
        },
        {
          "WorkOrder": 4130029,
          "ParentWorkOrder": 4124148,
          "JobName": "Clark County Municipal",
          "JobNumber": "7347-132758-1",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50 E Columbia St",
          "JobAddress2": "",
          "JobFullAddress": "50 E Columbia St",
          "JobCounty": "Clark",
          "JobState": "Ohio",
          "CustomerCompanyName": "DE George Ceilings",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$4,728.00",
          "BranchNumber": 7347,
          "SubmitDate": "05-18-2021",
          "ClientonJob": "DE George Ceilings"
        },
        {
          "WorkOrder": 4129678,
          "ParentWorkOrder": "",
          "JobName": "COMMERCIAL CONSTRUCTION SOL.",
          "JobNumber": "7348-103344-2",
          "FirstdayontheJob": "05-16-2021",
          "LastdayontheJob": "05-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "615 Lyons Rd.",
          "JobAddress2": "PANERA BREAD #4853",
          "JobFullAddress": "615 Lyons Rd. PANERA BREAD #4853",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Thomas W Arrington LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,371.04",
          "BranchNumber": 7348,
          "SubmitDate": "05-17-2021",
          "ClientonJob": "Thomas W Arrington LLC"
        },
        {
          "WorkOrder": 4129676,
          "ParentWorkOrder": "",
          "JobName": "NULL One Black and Gold Blvd",
          "JobNumber": "7272-129053-4",
          "FirstdayontheJob": "05-16-2021",
          "LastdayontheJob": "05-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "One Black and Gold Blvd",
          "JobAddress2": "",
          "JobFullAddress": "One Black and Gold Blvd",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Marquee Development Group",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,901.80",
          "BranchNumber": 7272,
          "SubmitDate": "05-17-2021",
          "ClientonJob": "Marquee Development Group"
        },
        {
          "WorkOrder": 4129675,
          "ParentWorkOrder": "",
          "JobName": "220 Park West Dr 4091 Neovia",
          "JobNumber": "7272-132740-7",
          "FirstdayontheJob": "05-16-2021",
          "LastdayontheJob": "05-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "220 Park West Dr #4091 Neovia",
          "JobAddress2": "",
          "JobFullAddress": "220 Park West Dr #4091 Neovia",
          "JobCounty": "MADISON",
          "JobState": "Ohio",
          "CustomerCompanyName": "J Yankle Company Ltd",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$5,524.77",
          "BranchNumber": 7272,
          "SubmitDate": "05-17-2021",
          "ClientonJob": "J Yankle Company Ltd"
        },
        {
          "WorkOrder": 4129674,
          "ParentWorkOrder": "",
          "JobName": "J & A Drywall Inc",
          "JobNumber": "7348-131617-2",
          "FirstdayontheJob": "05-16-2021",
          "LastdayontheJob": "05-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5875 Dixie Highway",
          "JobAddress2": "Panera Bread #4929",
          "JobFullAddress": "5875 Dixie Highway Panera Bread #4929",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & A Drywall Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,689.53",
          "BranchNumber": 7348,
          "SubmitDate": "05-17-2021",
          "ClientonJob": "J & A Drywall Inc"
        },
        {
          "WorkOrder": 4129673,
          "ParentWorkOrder": "",
          "JobName": "SHISEIDO",
          "JobNumber": "7272-106586-2",
          "FirstdayontheJob": "05-16-2021",
          "LastdayontheJob": "05-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5271 Centerpoint Pkwy",
          "JobAddress2": "",
          "JobFullAddress": "5271 Centerpoint Pkwy",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Compass Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,532.52",
          "BranchNumber": 7272,
          "SubmitDate": "05-17-2021",
          "ClientonJob": "Compass Construction"
        },
        {
          "WorkOrder": 4128741,
          "ParentWorkOrder": 4114933,
          "JobName": "(NOF)Crumble Cookie St.Ht",
          "JobNumber": "502-916315-211",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14844 Hall Rd",
          "JobAddress2": "",
          "JobFullAddress": "14844 Hall Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4128642,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TMC Childcare",
          "JobNumber": "502-862701-2",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "406 North Street",
          "JobAddress2": "",
          "JobFullAddress": "406 North Street",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "SANCTUM CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$17,270.52",
          "BranchNumber": 11,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "SANCTUM CONTRACTING"
        },
        {
          "WorkOrder": 4128640,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BLUE HORSESHOE INC",
          "JobNumber": "502-861507-72",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7317 DYKE RD",
          "JobAddress2": "",
          "JobFullAddress": "7317 DYKE RD",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "LARRY CLEVELAND",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$7,586.73",
          "BranchNumber": 11,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "LARRY CLEVELAND"
        },
        {
          "WorkOrder": 4128639,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HMOR-8220 DIXIE HWY",
          "JobNumber": "502-861507-70",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8220 DIXIE HWY",
          "JobAddress2": "",
          "JobFullAddress": "8220 DIXIE HWY",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "LARRY CLEVELAND",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$4,765.36",
          "BranchNumber": 11,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "LARRY CLEVELAND"
        },
        {
          "WorkOrder": 4128638,
          "ParentWorkOrder": "",
          "JobName": "(NOF) City Flats Phase 2",
          "JobNumber": "502-857051-10",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "800 Military St",
          "JobAddress2": "",
          "JobFullAddress": "800 Military St",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARTIN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$7,605.10",
          "BranchNumber": 11,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "MARTIN CONSTRUCTION"
        },
        {
          "WorkOrder": 4128637,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Home 2 Suites-Northv",
          "JobNumber": "502-847401-185",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "47450 W Five Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "47450 W Five Mile Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,806.00",
          "BalanceRemaining": "$743.36",
          "BranchNumber": 11,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4128636,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Warehouse/Shop",
          "JobNumber": "502-112811-5",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7760 E Deckerville Rd",
          "JobAddress2": "",
          "JobFullAddress": "7760 E Deckerville Rd",
          "JobCounty": "TUSCOLA",
          "JobState": "Michigan",
          "CustomerCompanyName": "D&D Quality Roofing",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,995.05",
          "BranchNumber": 11,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "D&D Quality Roofing"
        },
        {
          "WorkOrder": 4128635,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BRUSH PARK CITY",
          "JobNumber": "502-1628287-2",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "320 Edmund Pl",
          "JobAddress2": "",
          "JobFullAddress": "320 Edmund Pl",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PRO SHIELD CONTRACTORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$32,831.00",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "PRO SHIELD CONTRACTORS LLC"
        },
        {
          "WorkOrder": 4128634,
          "ParentWorkOrder": "",
          "JobName": "(NOF) KENSINGTON HOMES",
          "JobNumber": "502-1325000-46",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8851 Somerset Ln",
          "JobAddress2": "",
          "JobFullAddress": "8851 Somerset Ln",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,514.32",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4128633,
          "ParentWorkOrder": "",
          "JobName": "(NOF) OMID NESP",
          "JobNumber": "502-941950-13",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11001 E STATE FAIR AVE",
          "JobAddress2": "",
          "JobFullAddress": "11001 E STATE FAIR AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "GREAT LAKES CEILING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$17,497.01",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "GREAT LAKES CEILING"
        },
        {
          "WorkOrder": 4128632,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Athletico",
          "JobNumber": "502-916315-224",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22435 Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "22435 Michigan Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,011.00",
          "BalanceRemaining": "$5,120.58",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4128631,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CREATIVE FOAM",
          "JobNumber": "502-916315-219",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "310 N Alloy Dr",
          "JobAddress2": "",
          "JobFullAddress": "310 N Alloy Dr",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,752.08",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4128630,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TACO BELL BAY CITY",
          "JobNumber": "502-916315-218",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4111 Wilder Rd",
          "JobAddress2": "",
          "JobFullAddress": "4111 Wilder Rd",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$740.30",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4128629,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDONALDS #15872 FH",
          "JobNumber": "502-916315-217",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31325 Orchard Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "31325 Orchard Lake Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,460.44",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4128628,
          "ParentWorkOrder": "",
          "JobName": "(NOF)#1655 ST.ALFRED CHUR",
          "JobNumber": "502-913820-618",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24175 Baske St",
          "JobAddress2": "",
          "JobFullAddress": "24175 Baske St",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$299.24",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4128627,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SHAKE SHACK",
          "JobNumber": "502-913820-615",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "66 N Adams Rd",
          "JobAddress2": "",
          "JobFullAddress": "66 N Adams Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,004.00",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4128626,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1639 FIDELITY INVEST",
          "JobNumber": "502-913820-602",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "500 E Eisenhower Pkwy",
          "JobAddress2": "",
          "JobFullAddress": "500 E Eisenhower Pkwy",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,287.52",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4128625,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MSU RESEARCH CENTER",
          "JobNumber": "502-913400-32",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1400 Rosa Parks Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1400 Rosa Parks Blvd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DANCO CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,660.33",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "DANCO CONTRACTING, INC."
        },
        {
          "WorkOrder": 4128624,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OU WILSON HALL EXPAN",
          "JobNumber": "502-910900-12",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "371 WILSON BLVD",
          "JobAddress2": "",
          "JobFullAddress": "371 WILSON BLVD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANN ARBOR CEILING & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$250,000.00",
          "BalanceRemaining": "$42,441.74",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "ANN ARBOR CEILING & PART"
        },
        {
          "WorkOrder": 4128623,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SHELL GAS ANN ARBOR",
          "JobNumber": "502-910621-51",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2679 Ann Arbor Saline Rd",
          "JobAddress2": "",
          "JobFullAddress": "2679 Ann Arbor Saline Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$13,412.07",
          "BranchNumber": 9,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 4128620,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Xfinity Detroit",
          "JobNumber": "502-1607100-2",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1367 Eight Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "1367 Eight Mile Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "CONSTRUCTION SOLUTIONS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "CONSTRUCTION SOLUTIONS LLC"
        },
        {
          "WorkOrder": 4128619,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Ann Arbor Counseling",
          "JobNumber": "502-856071-89",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8554 Canton Center North RD",
          "JobAddress2": "",
          "JobFullAddress": "8554 Canton Center North RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "M & V CONSTRUCTION"
        },
        {
          "WorkOrder": 4128618,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ADIENT BATHROOMS",
          "JobNumber": "502-815750-317",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "49200 Halyard Dr",
          "JobAddress2": "",
          "JobFullAddress": "49200 Halyard Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCT- ION SPECIALISTS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCT- ION SPECIALISTS, INC."
        },
        {
          "WorkOrder": 4128616,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCS Ford Train",
          "JobNumber": "502-815250-387",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2001 15th Street",
          "JobAddress2": "",
          "JobFullAddress": "2001 15th Street",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4128615,
          "ParentWorkOrder": "",
          "JobName": "(NOF)UofM Sophie's Place",
          "JobNumber": "502-813200-153",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1540 E Hospital Dr",
          "JobAddress2": "",
          "JobFullAddress": "1540 E Hospital Dr",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4128614,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KLA",
          "JobNumber": "502-812350-105",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1200 Woodridge AVE",
          "JobAddress2": "",
          "JobFullAddress": "1200 Woodridge AVE",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4128613,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FERNDALE UPPER ELEM.",
          "JobNumber": "502-164068-4",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24220 ROSEWOOD ST",
          "JobAddress2": "",
          "JobFullAddress": "24220 ROSEWOOD ST",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PGC DEVELOPMENT",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$1,037.99",
          "BranchNumber": 8,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "PGC DEVELOPMENT"
        },
        {
          "WorkOrder": 4128612,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dollar General",
          "JobNumber": "502-16564-8",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8068 N Clio Rd",
          "JobAddress2": "",
          "JobFullAddress": "8068 N Clio Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARINE CITY CONTRACTING INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$5,577.89",
          "BranchNumber": 8,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "MARINE CITY CONTRACTING INC"
        },
        {
          "WorkOrder": 4128611,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Iroquois Industries",
          "JobNumber": "502-16564-7",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25101 Groesbeck Hwy",
          "JobAddress2": "",
          "JobFullAddress": "25101 Groesbeck Hwy",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARINE CITY CONTRACTING INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$4,526.94",
          "BranchNumber": 8,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "MARINE CITY CONTRACTING INC"
        },
        {
          "WorkOrder": 4128610,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Hobby Lobby",
          "JobNumber": "502-1602953-2",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5412 W Saginaw Hwy",
          "JobAddress2": "",
          "JobFullAddress": "5412 W Saginaw Hwy",
          "JobCounty": "EATON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATIONWIDE ACOUSTICS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$23,778.95",
          "BranchNumber": 7,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "NATIONWIDE ACOUSTICS"
        },
        {
          "WorkOrder": 4128609,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HH BARNUM BLDG 3",
          "JobNumber": "502-990400-13",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7891 LOCHLIN DR",
          "JobAddress2": "",
          "JobFullAddress": "7891 LOCHLIN DR",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "RAJALA & SONS FINISHES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,552.36",
          "BranchNumber": 7,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "RAJALA & SONS FINISHES"
        },
        {
          "WorkOrder": 4128608,
          "ParentWorkOrder": "",
          "JobName": "(NOF) VAN BUREN SENIOR",
          "JobNumber": "502-1060142-44",
          "FirstdayontheJob": "05-11-2021",
          "LastdayontheJob": "05-11-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8337 M140",
          "JobAddress2": "",
          "JobFullAddress": "8337 M140",
          "JobCounty": "VAN BUREN",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,000.00",
          "BalanceRemaining": "$84,291.44",
          "BranchNumber": 5,
          "SubmitDate": "05-12-2021",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 4127813,
          "ParentWorkOrder": 4121971,
          "JobName": "(NOF) ALDIS REDFORD",
          "JobNumber": "502-913400-31",
          "FirstdayontheJob": "04-20-2021",
          "LastdayontheJob": "04-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9009 TELEGRAPH RD",
          "JobAddress2": "",
          "JobFullAddress": "9009 TELEGRAPH RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DANCO CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,213.35",
          "BranchNumber": 9,
          "SubmitDate": "05-10-2021",
          "ClientonJob": "DANCO CONTRACTING, INC."
        },
        {
          "WorkOrder": 4127804,
          "ParentWorkOrder": 4117611,
          "JobName": "(NOF)FABLETICS  # 1637",
          "JobNumber": "502-913820-601",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$14,006.00",
          "BalanceRemaining": "$5,371.95",
          "BranchNumber": 9,
          "SubmitDate": "05-10-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4127538,
          "ParentWorkOrder": "",
          "JobName": "LIMA DOLLAR TREE",
          "JobNumber": "7348-106905-0",
          "FirstdayontheJob": "05-09-2021",
          "LastdayontheJob": "05-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2411 SHAWNEE RD",
          "JobAddress2": "",
          "JobFullAddress": "2411 SHAWNEE RD",
          "JobCounty": "ALLEN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Viper Wall Systems",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$11,874.04",
          "BranchNumber": 7348,
          "SubmitDate": "05-10-2021",
          "ClientonJob": "Viper Wall Systems"
        },
        {
          "WorkOrder": 4127533,
          "ParentWorkOrder": "",
          "JobName": "Forum Apartments",
          "JobNumber": "7347-136533-1",
          "FirstdayontheJob": "05-09-2021",
          "LastdayontheJob": "05-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "707 Martin Luther King Dr W",
          "JobAddress2": "",
          "JobFullAddress": "707 Martin Luther King Dr W",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Pro Grade Drywall",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$15,983.29",
          "BranchNumber": 7347,
          "SubmitDate": "05-11-2021",
          "ClientonJob": "Pro Grade Drywall"
        },
        {
          "WorkOrder": 4127527,
          "ParentWorkOrder": "",
          "JobName": "WPAFB",
          "JobNumber": "7348-107421-0",
          "FirstdayontheJob": "05-09-2021",
          "LastdayontheJob": "05-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1864 4TH ST",
          "JobAddress2": "",
          "JobFullAddress": "1864 4TH ST",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Adaptable Office Concepts",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$2,300.71",
          "BranchNumber": 7348,
          "SubmitDate": "05-10-2021",
          "ClientonJob": "Adaptable Office Concepts"
        },
        {
          "WorkOrder": 4127057,
          "ParentWorkOrder": 4124169,
          "JobName": "(NOF)Wood Road Landfill",
          "JobNumber": "502-948950-58",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16800 Wood Road",
          "JobAddress2": "",
          "JobFullAddress": "16800 Wood Road",
          "JobCounty": "CLINTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHIVELY-BOUMA, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$10,467.52",
          "BranchNumber": 7,
          "SubmitDate": "05-07-2021",
          "ClientonJob": "SHIVELY-BOUMA, INC."
        },
        {
          "WorkOrder": 4126697,
          "ParentWorkOrder": "",
          "JobName": "FYDA FREIGHTLINER",
          "JobNumber": "133872-0013",
          "FirstdayontheJob": "05-07-2021",
          "LastdayontheJob": "05-07-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2700 Plain City Georgesville Rd",
          "JobAddress2": "",
          "JobFullAddress": "2700 Plain City Georgesville Rd",
          "JobCounty": "Madison",
          "JobState": "Ohio",
          "CustomerCompanyName": "RL Fry & Associates",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "N/A",
          "BranchNumber": 65,
          "SubmitDate": "05-07-2021",
          "ClientonJob": "RL Fry & Associates"
        },
        {
          "WorkOrder": 4126373,
          "ParentWorkOrder": 4111307,
          "JobName": "(NOF)3181 McLaren Pharmac",
          "JobNumber": "502-813200-148",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50 N Perry St",
          "JobAddress2": "",
          "JobFullAddress": "50 N Perry St",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$864.83",
          "BranchNumber": 8,
          "SubmitDate": "05-05-2021",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4124182,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BELLA HOMES",
          "JobNumber": "502-164470-16",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "56387 SCOTLAND DR",
          "JobAddress2": "",
          "JobFullAddress": "56387 SCOTLAND DR",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$4,057.39",
          "BranchNumber": 11,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4124181,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Arcadia-Legacy Est",
          "JobNumber": "502-164470-15",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "48247 Stapleton Ave",
          "JobAddress2": "",
          "JobFullAddress": "48247 Stapleton Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,506.00",
          "BalanceRemaining": "$3,590.89",
          "BranchNumber": 11,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4124180,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SUFFIELD AVE",
          "JobNumber": "502-111914-15",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1320 SUFFIELD AVE",
          "JobAddress2": "",
          "JobFullAddress": "1320 SUFFIELD AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "GREAT LAKES CUSTOM BUILDER LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$14,006.00",
          "BalanceRemaining": "$12,663.05",
          "BranchNumber": 11,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "GREAT LAKES CUSTOM BUILDER LLC"
        },
        {
          "WorkOrder": 4124179,
          "ParentWorkOrder": "",
          "JobName": "(NOF)JP CYCLES",
          "JobNumber": "502-916315-222",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14440 Pardee Rd",
          "JobAddress2": "",
          "JobFullAddress": "14440 Pardee Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$437.08",
          "BranchNumber": 9,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4124178,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WHISPERING WINDS VAP",
          "JobNumber": "502-916315-221",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20771 GIBRALTAR RD",
          "JobAddress2": "",
          "JobFullAddress": "20771 GIBRALTAR RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$510.60",
          "BranchNumber": 9,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4124177,
          "ParentWorkOrder": "",
          "JobName": "(NOF)THE SHADE STORE",
          "JobNumber": "502-916315-220",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "142 S Old Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "142 S Old Woodward Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,383.01",
          "BranchNumber": 9,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4124176,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Taco Bell Milford",
          "JobNumber": "502-916315-212",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "525 General Motors Rd",
          "JobAddress2": "",
          "JobFullAddress": "525 General Motors Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM CONST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,474.28",
          "BranchNumber": 9,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "INDEPENDENCE COMM CONST"
        },
        {
          "WorkOrder": 4124175,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LITTLE TREE",
          "JobNumber": "502-1621098-2",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8239 MORRISH RD",
          "JobAddress2": "",
          "JobFullAddress": "8239 MORRISH RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "FACE CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,011.00",
          "BalanceRemaining": "$7,224.22",
          "BranchNumber": 8,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "FACE CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 4124174,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ST MARYS OUR LADY",
          "JobNumber": "502-843701-224",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8075 RITTER",
          "JobAddress2": "",
          "JobFullAddress": "8075 RITTER",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$349.72",
          "BranchNumber": 8,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 4124173,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Ridge Wood Elementar",
          "JobNumber": "502-815750-313",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "49775 Six Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "49775 Six Mile Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC."
        },
        {
          "WorkOrder": 4124172,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Priority One Exp",
          "JobNumber": "502-815630-247",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "38701 Seven Mile RD",
          "JobAddress2": "",
          "JobFullAddress": "38701 Seven Mile RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4124171,
          "ParentWorkOrder": "",
          "JobName": "(NOF)#3187 Vivarium-UOFM",
          "JobNumber": "502-813200-152",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 Wall St",
          "JobAddress2": "",
          "JobFullAddress": "1000 Wall St",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4124170,
          "ParentWorkOrder": "",
          "JobName": "(NOF) OU Dodge Hall",
          "JobNumber": "502-813200-151",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "318 Meadow Brook Rd",
          "JobAddress2": "",
          "JobFullAddress": "318 Meadow Brook Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4124169,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Wood Road Landfill",
          "JobNumber": "502-948950-58",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16800 Wood Road",
          "JobAddress2": "",
          "JobFullAddress": "16800 Wood Road",
          "JobCounty": "CLINTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHIVELY-BOUMA, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$10,467.52",
          "BranchNumber": 7,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "SHIVELY-BOUMA, INC."
        },
        {
          "WorkOrder": 4124150,
          "ParentWorkOrder": "",
          "JobName": "Mapfre Stadium",
          "JobNumber": "7272-129053-0",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "One Black and Gold Blvd",
          "JobAddress2": "",
          "JobFullAddress": "One Black and Gold Blvd",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "Marquee Development Group",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,901.80",
          "BranchNumber": 7272,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "Marquee Development Group"
        },
        {
          "WorkOrder": 4124148,
          "ParentWorkOrder": "",
          "JobName": "Clark County Municipal",
          "JobNumber": "7347-132758-1",
          "FirstdayontheJob": "04-28-2021",
          "LastdayontheJob": "04-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50 E Columbia St",
          "JobAddress2": "",
          "JobFullAddress": "50 E Columbia St",
          "JobCounty": "Clark",
          "JobState": "Ohio",
          "CustomerCompanyName": "DE George Ceilings",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$4,728.00",
          "BranchNumber": 7347,
          "SubmitDate": "04-29-2021",
          "ClientonJob": "DE George Ceilings"
        },
        {
          "WorkOrder": 4123633,
          "ParentWorkOrder": 4117610,
          "JobName": "(NOF)Silver Springs Elem.",
          "JobNumber": "502-815750-311",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19801 Silver Springs Dr.",
          "JobAddress2": "",
          "JobFullAddress": "19801 Silver Springs Dr.",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC.",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-27-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC."
        },
        {
          "WorkOrder": 4122362,
          "ParentWorkOrder": 4117609,
          "JobName": "(NOF)RICHMOND HIGH SCHOOL",
          "JobNumber": "502-810150-62",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35320 DIVISION RD",
          "JobAddress2": "",
          "JobFullAddress": "35320 DIVISION RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTIC CEIL & PARTITION CO",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$90,006.00",
          "BalanceRemaining": "$464.34",
          "BranchNumber": 7,
          "SubmitDate": "04-22-2021",
          "ClientonJob": "ACOUSTIC CEIL & PARTITION CO"
        },
        {
          "WorkOrder": 4121975,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Panera Bread",
          "JobNumber": "502-944100-348",
          "FirstdayontheJob": "04-20-2021",
          "LastdayontheJob": "04-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "175 S Livernois Rd",
          "JobAddress2": "",
          "JobFullAddress": "175 S Livernois Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,054.47",
          "BranchNumber": 9,
          "SubmitDate": "04-21-2021",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4121974,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Allied Media Project",
          "JobNumber": "502-934634-22",
          "FirstdayontheJob": "04-20-2021",
          "LastdayontheJob": "04-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4731 Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "4731 Grand River Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONSTRUCTION GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "04-21-2021",
          "ClientonJob": "BLUESTONE CONSTRUCTION GROUP"
        },
        {
          "WorkOrder": 4121973,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Target Ann Arbor",
          "JobNumber": "502-934150-72",
          "FirstdayontheJob": "04-20-2021",
          "LastdayontheJob": "04-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "233 S State St",
          "JobAddress2": "",
          "JobFullAddress": "233 S State St",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "WOODS CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "04-21-2021",
          "ClientonJob": "WOODS CONSTRUCTION INC"
        },
        {
          "WorkOrder": 4121972,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MSGCU",
          "JobNumber": "502-913820-597",
          "FirstdayontheJob": "04-20-2021",
          "LastdayontheJob": "04-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1941 S Telegraph Rd",
          "JobAddress2": "",
          "JobFullAddress": "1941 S Telegraph Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,000.00",
          "BalanceRemaining": "$20,126.06",
          "BranchNumber": 9,
          "SubmitDate": "04-21-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4121971,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ALDIS REDFORD",
          "JobNumber": "502-913400-31",
          "FirstdayontheJob": "04-20-2021",
          "LastdayontheJob": "04-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9009 TELEGRAPH RD",
          "JobAddress2": "",
          "JobFullAddress": "9009 TELEGRAPH RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DANCO CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,213.35",
          "BranchNumber": 9,
          "SubmitDate": "04-21-2021",
          "ClientonJob": "DANCO CONTRACTING, INC."
        },
        {
          "WorkOrder": 4121970,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Family Dollar OakPrk",
          "JobNumber": "502-876752-25",
          "FirstdayontheJob": "04-20-2021",
          "LastdayontheJob": "04-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23001 Coolidge Hwy",
          "JobAddress2": "",
          "JobFullAddress": "23001 Coolidge Hwy",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,062.45",
          "BranchNumber": 8,
          "SubmitDate": "04-21-2021",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 4121969,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ALEXANDER HORNING",
          "JobNumber": "502-16564-6",
          "FirstdayontheJob": "04-20-2021",
          "LastdayontheJob": "04-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20650 STEPHENS ST",
          "JobAddress2": "",
          "JobFullAddress": "20650 STEPHENS ST",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARINE CITY CONTRACTING INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$3,339.05",
          "BranchNumber": 8,
          "SubmitDate": "04-21-2021",
          "ClientonJob": "MARINE CITY CONTRACTING INC"
        },
        {
          "WorkOrder": 4121968,
          "ParentWorkOrder": "",
          "JobName": "(NOF) OLLIES BARGAIN CEN",
          "JobNumber": "502-1600141-2",
          "FirstdayontheJob": "04-20-2021",
          "LastdayontheJob": "04-20-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 N CLINTON ST",
          "JobAddress2": "",
          "JobFullAddress": "1500 N CLINTON ST",
          "JobCounty": "DEFIANCE",
          "JobState": "Ohio",
          "CustomerCompanyName": "DODSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$13,016.86",
          "BranchNumber": 7,
          "SubmitDate": "04-21-2021",
          "ClientonJob": "DODSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4121542,
          "ParentWorkOrder": 4057348,
          "JobName": "(NOF)COMFORT INN-CLARKSTN",
          "JobNumber": "502-1325000-31",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6874 SASHABAW RD",
          "JobAddress2": "",
          "JobFullAddress": "6874 SASHABAW RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$140,006.00",
          "BalanceRemaining": "$14,607.70",
          "BranchNumber": 9,
          "SubmitDate": "04-20-2021",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4121242,
          "ParentWorkOrder": 4117608,
          "JobName": "(NOF)Richmond Middle Schl",
          "JobNumber": "502-810150-61",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35250 Division Rd",
          "JobAddress2": "",
          "JobFullAddress": "35250 Division Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTIC CEIL & PART",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$90,006.00",
          "BalanceRemaining": "$4,098.65",
          "BranchNumber": 7,
          "SubmitDate": "04-19-2021",
          "ClientonJob": "ACOUSTIC CEIL & PART"
        },
        {
          "WorkOrder": 4121236,
          "ParentWorkOrder": 4114922,
          "JobName": "(NOF)HILLSIDE MIDDLE SCHO",
          "JobNumber": "502-815750-307",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "775 N CENTER ST",
          "JobAddress2": "",
          "JobFullAddress": "775 N CENTER ST",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC.",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$36,753.17",
          "BranchNumber": 8,
          "SubmitDate": "04-19-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC."
        },
        {
          "WorkOrder": 4120157,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PANDA EXP ROYAL OAK",
          "JobNumber": "502-858231-59",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2250 EAST TWELVE MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "2250 EAST TWELVE MILE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,458.64",
          "BranchNumber": 11,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 4120156,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MJC-WHITE OAKS #9",
          "JobNumber": "502-839201-144",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7348 MULBERRY DR",
          "JobAddress2": "",
          "JobFullAddress": "7348 MULBERRY DR",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLINTON INTERIORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,006.00",
          "BalanceRemaining": "$1,192.12",
          "BranchNumber": 11,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "CLINTON INTERIORS INC"
        },
        {
          "WorkOrder": 4120155,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LEONE STRATHMORE #5",
          "JobNumber": "502-164470-13",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "56361 SCOTLAND DR",
          "JobAddress2": "",
          "JobFullAddress": "56361 SCOTLAND DR",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$3,984.40",
          "BranchNumber": 11,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4120154,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WEST RIDGE ESTATE",
          "JobNumber": "502-1325000-45",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50009 TAHOE WAY",
          "JobAddress2": "",
          "JobFullAddress": "50009 TAHOE WAY",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$7,394.00",
          "BranchNumber": 9,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4120153,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Delta Project",
          "JobNumber": "502-916315-214",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "775 E Washington St",
          "JobAddress2": "",
          "JobFullAddress": "775 E Washington St",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$265,006.00",
          "BalanceRemaining": "$33,347.59",
          "BranchNumber": 9,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4120152,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Banfield Animal Hosp",
          "JobNumber": "502-916315-210",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3300 Ann Arbor Saline Rd",
          "JobAddress2": "",
          "JobFullAddress": "3300 Ann Arbor Saline Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,011.00",
          "BalanceRemaining": "$10,837.46",
          "BranchNumber": 9,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4120151,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LOURDES REHAB PHAS 2",
          "JobNumber": "502-910050-60",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2300 WATKINS LAKE RD",
          "JobAddress2": "",
          "JobFullAddress": "2300 WATKINS LAKE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,011.00",
          "BalanceRemaining": "$14,799.23",
          "BranchNumber": 9,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 4120150,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Troy Chipotle",
          "JobNumber": "502-1639418-38",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5296 Crooks Rd",
          "JobAddress2": "",
          "JobFullAddress": "5296 Crooks Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$2,552.16",
          "BranchNumber": 8,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4120149,
          "ParentWorkOrder": "",
          "JobName": "(NOF)St.Michael Parish",
          "JobNumber": "502-1639418-37",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11441 Hubbard st",
          "JobAddress2": "",
          "JobFullAddress": "11441 Hubbard st",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,701.43",
          "BranchNumber": 8,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4120148,
          "ParentWorkOrder": "",
          "JobName": "(NOF)5775 ENTERPRISE DRIV",
          "JobNumber": "502-1632825-11",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5775 ENTERPRISE DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "5775 ENTERPRISE DRIVE",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR CONST & DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,011.00",
          "BalanceRemaining": "$5,367.23",
          "BranchNumber": 8,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "SUPERIOR CONST & DRYWALL LLC"
        },
        {
          "WorkOrder": 4120147,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Big Lots",
          "JobNumber": "502-876650-390",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14333 Eureka Rd",
          "JobAddress2": "",
          "JobFullAddress": "14333 Eureka Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4120146,
          "ParentWorkOrder": "",
          "JobName": "(NOF)JESSIE GREWAL RES",
          "JobNumber": "502-876650-388",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6765 MEADOWLAKE RD",
          "JobAddress2": "",
          "JobFullAddress": "6765 MEADOWLAKE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$188.42",
          "BranchNumber": 8,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4120145,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Phase 2 UAW Solidari",
          "JobNumber": "502-812350-104",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8000 E Jefferson Ave",
          "JobAddress2": "",
          "JobFullAddress": "8000 E Jefferson Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4120144,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GYP/STL RICH MID SCL",
          "JobNumber": "502-810150-64",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35250 DIVISION RD",
          "JobAddress2": "",
          "JobFullAddress": "35250 DIVISION RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTIC CEIL & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,225.12",
          "BranchNumber": 7,
          "SubmitDate": "",
          "ClientonJob": "ACOUSTIC CEIL & PART"
        },
        {
          "WorkOrder": 4120143,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GYP/STL Rich High",
          "JobNumber": "502-810150-63",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35320 Division Rd",
          "JobAddress2": "",
          "JobFullAddress": "35320 Division Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTIC CEIL & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$225.34",
          "BranchNumber": 7,
          "SubmitDate": "",
          "ClientonJob": "ACOUSTIC CEIL & PART"
        },
        {
          "WorkOrder": 4119993,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Senior Center",
          "JobNumber": "502-1603235-3",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "450 S Williams Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "450 S Williams Lake Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "A1 PAINTING & DECORATING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,921.46",
          "BranchNumber": 13,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "A1 PAINTING & DECORATING"
        },
        {
          "WorkOrder": 4119992,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CARDINAL QUARRY",
          "JobNumber": "502-1304099-13",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8042 FREMONT PIKE",
          "JobAddress2": "",
          "JobFullAddress": "8042 FREMONT PIKE",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & B ACOUSTICAL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$12,218.35",
          "BranchNumber": 13,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "J & B ACOUSTICAL"
        },
        {
          "WorkOrder": 4119991,
          "ParentWorkOrder": "",
          "JobName": "FONTAINE PLAZA",
          "JobNumber": "65-120090-4",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2053 S MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "2053 S MAIN ST",
          "JobCounty": "LOGAN",
          "JobState": "Ohio",
          "CustomerCompanyName": "MARK RUMPKE SERVICES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,838.30",
          "BranchNumber": 12,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "MARK RUMPKE SERVICES INC"
        },
        {
          "WorkOrder": 4119990,
          "ParentWorkOrder": "",
          "JobName": "AUTOZONE",
          "JobNumber": "65-2136004-5",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4436 GLENESTE WITHAMSVILLE RD",
          "JobAddress2": "",
          "JobFullAddress": "4436 GLENESTE WITHAMSVILLE RD",
          "JobCounty": "CLERMONT",
          "JobState": "Ohio",
          "CustomerCompanyName": "C&S DRYWALL INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,011.00",
          "BalanceRemaining": "$12,817.58",
          "BranchNumber": 9,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "C&S DRYWALL INC."
        },
        {
          "WorkOrder": 4119989,
          "ParentWorkOrder": "",
          "JobName": "(NOF) COTTONWOOD APTS",
          "JobNumber": "65-55219-15",
          "FirstdayontheJob": "04-13-2021",
          "LastdayontheJob": "04-13-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "242 W SHARON RD, 8476 COTTONWOOD DR AKA 8490 COTTONWOOD DR & 3302-3304 WERK RD",
          "JobAddress2": "GRAYBACH",
          "JobFullAddress": "242 W SHARON RD, 8476 COTTONWOOD DR AKA 8490 COTTONWOOD DR & 3302-3304 WERK RD GRAYBACH",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lemcer Construction LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$10,285.78",
          "BranchNumber": 2,
          "SubmitDate": "04-14-2021",
          "ClientonJob": "Lemcer Construction LLC"
        },
        {
          "WorkOrder": 4119659,
          "ParentWorkOrder": "",
          "JobName": "Emerson/Experitec Tenant Improvement Project",
          "JobNumber": 8021011689,
          "FirstdayontheJob": "01-01-2021",
          "LastdayontheJob": "02-28-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11325 Strang Line Rd",
          "JobAddress2": "",
          "JobFullAddress": "11325 Strang Line Rd",
          "JobCounty": "Johnson",
          "JobState": "Kansas",
          "CustomerCompanyName": "Construction Designworks",
          "RequestType": "Lien Statement",
          "CustomerRepresentative": "Pamela Willard",
          "Status": "Recorded",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$9,955.90",
          "BranchNumber": 7273,
          "SubmitDate": "04-13-2021",
          "ClientonJob": "Construction Designworks"
        },
        {
          "WorkOrder": 4117677,
          "ParentWorkOrder": 4111316,
          "JobName": "(NOF)Walgreens Flint",
          "JobNumber": "502-916315-208",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1419 N Dort Hwy",
          "JobAddress2": "",
          "JobFullAddress": "1419 N Dort Hwy",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$25,042.20",
          "BranchNumber": 9,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4117619,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BRIGHTON SCHOOLS",
          "JobNumber": "502-1308300-5",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "850 Spencer Rd",
          "JobAddress2": "",
          "JobFullAddress": "850 Spencer Rd",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "THE SPIEKER COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$389.02",
          "BranchNumber": 13,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "THE SPIEKER COMPANY"
        },
        {
          "WorkOrder": 4117618,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WALMART #2542",
          "JobNumber": "502-160885-2",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1720 N Perry St",
          "JobAddress2": "",
          "JobFullAddress": "1720 N Perry St",
          "JobCounty": "PUTNAM",
          "JobState": "Ohio",
          "CustomerCompanyName": "MARJAM SUPPLY COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$12,374.77",
          "BranchNumber": 13,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "MARJAM SUPPLY COMPANY"
        },
        {
          "WorkOrder": 4117616,
          "ParentWorkOrder": "",
          "JobName": "FIRE WALL",
          "JobNumber": "65-413670-3",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5050 RIGGINS RD",
          "JobAddress2": "",
          "JobFullAddress": "5050 RIGGINS RD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "GEORGE MOBLEY DRYWALL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$7,053.88",
          "BranchNumber": 4,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "GEORGE MOBLEY DRYWALL"
        },
        {
          "WorkOrder": 4117615,
          "ParentWorkOrder": "",
          "JobName": "CHIPOLTE 3824",
          "JobNumber": "65-46034-18",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9430 Fields Ertel Rd",
          "JobAddress2": "",
          "JobFullAddress": "9430 Fields Ertel Rd",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "KP Sullivan Builders, Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,628.55",
          "BranchNumber": 4,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "KP Sullivan Builders, Inc"
        },
        {
          "WorkOrder": 4117613,
          "ParentWorkOrder": "",
          "JobName": "(NOF)McDonald's Richmond",
          "JobNumber": "502-916315-215",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "67600 S Main St",
          "JobAddress2": "",
          "JobFullAddress": "67600 S Main St",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$2,894.78",
          "BranchNumber": 9,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4117612,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OAKSIDE SCHOLAR",
          "JobNumber": "502-916315-213",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "355 SUMMIT DR",
          "JobAddress2": "",
          "JobFullAddress": "355 SUMMIT DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$265,006.00",
          "BalanceRemaining": "$23,018.64",
          "BranchNumber": 9,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONSTRUCTION"
        },
        {
          "WorkOrder": 4117611,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FABLETICS  # 1637",
          "JobNumber": "502-913820-601",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$14,006.00",
          "BalanceRemaining": "$5,371.95",
          "BranchNumber": 9,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4117610,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Silver Springs Elem.",
          "JobNumber": "502-815750-311",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19801 Silver Springs Dr.",
          "JobAddress2": "",
          "JobFullAddress": "19801 Silver Springs Dr.",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC."
        },
        {
          "WorkOrder": 4117609,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RICHMOND HIGH SCHOOL",
          "JobNumber": "502-810150-62",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35320 DIVISION RD",
          "JobAddress2": "",
          "JobFullAddress": "35320 DIVISION RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTIC CEIL & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$90,006.00",
          "BalanceRemaining": "$464.34",
          "BranchNumber": 7,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "ACOUSTIC CEIL & PART"
        },
        {
          "WorkOrder": 4117608,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Richmond Middle Schl",
          "JobNumber": "502-810150-61",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35250 Division Rd",
          "JobAddress2": "",
          "JobFullAddress": "35250 Division Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTIC CEIL & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$90,006.00",
          "BalanceRemaining": "$4,098.65",
          "BranchNumber": 7,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "ACOUSTIC CEIL & PART"
        },
        {
          "WorkOrder": 4117607,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FAIRWAY GLEN SUB",
          "JobNumber": "502-1668468-14",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1669, 1671 Fairway Glens Dr",
          "JobAddress2": "",
          "JobFullAddress": "1669, 1671 Fairway Glens Dr",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "WADE JURNEY HOMES LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "WADE JURNEY HOMES LLC"
        },
        {
          "WorkOrder": 4117606,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MONITOR TWP FIRE",
          "JobNumber": "502-12150-15",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2483 Midland Rd",
          "JobAddress2": "",
          "JobFullAddress": "2483 Midland Rd",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "SERENUS JOHNSON",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$9,020.79",
          "BranchNumber": 1,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "SERENUS JOHNSON"
        },
        {
          "WorkOrder": 4117605,
          "ParentWorkOrder": "",
          "JobName": "(NOF)USA SCHOOLS",
          "JobNumber": "502-10550-50",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7835 N Unionville Rd",
          "JobAddress2": "",
          "JobFullAddress": "7835 N Unionville Rd",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Booms Construction Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,650.28",
          "BranchNumber": 1,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "Booms Construction Inc."
        },
        {
          "WorkOrder": 4117604,
          "ParentWorkOrder": "",
          "JobName": "(NOF) THUMB INDUSTRIES",
          "JobNumber": "502-10550-49",
          "FirstdayontheJob": "04-06-2021",
          "LastdayontheJob": "04-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "721 N VAN DYKE RD",
          "JobAddress2": "",
          "JobFullAddress": "721 N VAN DYKE RD",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Booms Construction Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$10,072.59",
          "BranchNumber": 1,
          "SubmitDate": "04-07-2021",
          "ClientonJob": "Booms Construction Inc."
        },
        {
          "WorkOrder": 4116953,
          "ParentWorkOrder": 4105001,
          "JobName": "(NOF)NTH FARMS",
          "JobNumber": "502-913001-7",
          "FirstdayontheJob": "02-23-2021",
          "LastdayontheJob": "02-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1255 Falahee Rd",
          "JobAddress2": "",
          "JobFullAddress": "1255 Falahee Rd",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Interior Resource Group Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,284.55",
          "BranchNumber": 9,
          "SubmitDate": "04-06-2021",
          "ClientonJob": "Commercial Interior Resource Group Inc"
        },
        {
          "WorkOrder": 4116918,
          "ParentWorkOrder": 4097771,
          "JobName": "(NOF) CANDLEWOOD HOTEL",
          "JobNumber": "502-1677740-40",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15595 HALL RD",
          "JobAddress2": "",
          "JobFullAddress": "15595 HALL RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$9,136.90",
          "BranchNumber": 9,
          "SubmitDate": "04-06-2021",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 4116913,
          "ParentWorkOrder": 4103218,
          "JobName": "(NOF)Auto Zone Midland",
          "JobNumber": "502-916315-203",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1409 Joe Mann Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1409 Joe Mann Blvd",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,012.79",
          "BranchNumber": 9,
          "SubmitDate": "04-06-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4116908,
          "ParentWorkOrder": 4111314,
          "JobName": "(NOF)AUTO ZONE WYOMING",
          "JobNumber": "502-916315-206",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1148 28th St SW",
          "JobAddress2": "",
          "JobFullAddress": "1148 28th St SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,011.00",
          "BalanceRemaining": "$35,588.37",
          "BranchNumber": 9,
          "SubmitDate": "04-06-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4116364,
          "ParentWorkOrder": 4105409,
          "JobName": "(NOF) TIFFIN UNIVERSITY",
          "JobNumber": "502-1301172-58",
          "FirstdayontheJob": "02-24-2021",
          "LastdayontheJob": "02-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "203 MIAMI ST",
          "JobAddress2": "",
          "JobFullAddress": "203 MIAMI ST",
          "JobCounty": "SENECA",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$38,873.71",
          "BranchNumber": 13,
          "SubmitDate": "04-05-2021",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 4115415,
          "ParentWorkOrder": "",
          "JobName": "TROTWOOD HOMES",
          "JobNumber": "65-13105-6",
          "FirstdayontheJob": "03-31-2021",
          "LastdayontheJob": "03-31-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7735 DOWNEY TRL",
          "JobAddress2": "",
          "JobFullAddress": "7735 DOWNEY TRL",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "INTEGRITY INTERIORS US LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$2,175.49",
          "BranchNumber": 13,
          "SubmitDate": "04-01-2021",
          "ClientonJob": "INTEGRITY INTERIORS US LLC"
        },
        {
          "WorkOrder": 4115414,
          "ParentWorkOrder": "",
          "JobName": "PARKWOOD",
          "JobNumber": "65-421030-7",
          "FirstdayontheJob": "03-31-2021",
          "LastdayontheJob": "03-31-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "325 W Parkwood Dr",
          "JobAddress2": "",
          "JobFullAddress": "325 W Parkwood Dr",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "Windsor Construct Svc LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,974.71",
          "BranchNumber": 4,
          "SubmitDate": "04-01-2021",
          "ClientonJob": "Windsor Construct Svc LLC"
        },
        {
          "WorkOrder": 4115413,
          "ParentWorkOrder": "",
          "JobName": "(NOF) THE CHURCH OF JESUS",
          "JobNumber": "65-41411-2",
          "FirstdayontheJob": "03-31-2021",
          "LastdayontheJob": "03-31-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3870 GATEWAY BLVD",
          "JobAddress2": "",
          "JobFullAddress": "3870 GATEWAY BLVD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "WESTLAND CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$480.00",
          "BranchNumber": 4,
          "SubmitDate": "04-01-2021",
          "ClientonJob": "WESTLAND CONSTRUCTION"
        },
        {
          "WorkOrder": 4114965,
          "ParentWorkOrder": 4111321,
          "JobName": "(NOF) Bayside Commons sub",
          "JobNumber": "502-142370-4",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "47452 & 47476 BAYSIDE CIRCLE W",
          "JobAddress2": "",
          "JobFullAddress": "47452 & 47476 BAYSIDE CIRCLE W",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PRIORITY CONSTRUCTORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,761.65",
          "BranchNumber": 11,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "PRIORITY CONSTRUCTORS INC"
        },
        {
          "WorkOrder": 4114948,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LABELLE DEVELOPMENT",
          "JobNumber": "502-858171-135",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7845 MARKLE RD",
          "JobAddress2": "",
          "JobFullAddress": "7845 MARKLE RD",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "DGS DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,372.18",
          "BranchNumber": 11,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "DGS DRYWALL LLC"
        },
        {
          "WorkOrder": 4114945,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1397 LUXINGTON",
          "JobNumber": "502-847401-181",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1397 Luxington Dr",
          "JobAddress2": "",
          "JobFullAddress": "1397 Luxington Dr",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$2,110.92",
          "BranchNumber": 11,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4114943,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1395 LUXINGTON #80",
          "JobNumber": "502-847401-180",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1395 Luxington Dr",
          "JobAddress2": "",
          "JobFullAddress": "1395 Luxington Dr",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$2,028.27",
          "BranchNumber": 11,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4114941,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Hope Academy",
          "JobNumber": "502-164470-12",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12121 Broadstreet Ave",
          "JobAddress2": "",
          "JobFullAddress": "12121 Broadstreet Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,506.00",
          "BalanceRemaining": "$1,838.99",
          "BranchNumber": 11,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4114939,
          "ParentWorkOrder": "",
          "JobName": "(NOF) KELLER WILLIAMS",
          "JobNumber": "502-1605183-4",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6455 TELEGRAPH RD",
          "JobAddress2": "",
          "JobFullAddress": "6455 TELEGRAPH RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DFB BUILDING INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$567.82",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "DFB BUILDING INC"
        },
        {
          "WorkOrder": 4114937,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MONARK GROVE",
          "JobNumber": "502-1507798-59",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7373 Sashabaw Rd",
          "JobAddress2": "",
          "JobFullAddress": "7373 Sashabaw Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$250,000.00",
          "BalanceRemaining": "$403.48",
          "BranchNumber": 9,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4114935,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ASCENSION MACKEY",
          "JobNumber": "502-933550-204",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19251 Mack Ave",
          "JobAddress2": "",
          "JobFullAddress": "19251 Mack Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$871.58",
          "BranchNumber": 9,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4114933,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Crumble Cookie St.Ht",
          "JobNumber": "502-916315-211",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14844 Hall Rd",
          "JobAddress2": "",
          "JobFullAddress": "14844 Hall Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4114931,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CATH LAB FARM.HILLS",
          "JobNumber": "502-913820-612",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "28050 Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "28050 Grand River Ave",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,135.93",
          "BranchNumber": 9,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4114930,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LOUIS VUITTON PERMAN",
          "JobNumber": "502-913820-608",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$7,119.40",
          "BranchNumber": 9,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4114929,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BEHAVIERAL CTR",
          "JobNumber": "502-913400-30",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "39465 W 14 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "39465 W 14 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DANCO CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "DANCO CONTRACTING, INC."
        },
        {
          "WorkOrder": 4114928,
          "ParentWorkOrder": "",
          "JobName": "(NOF) New Baltimore",
          "JobNumber": "502-1604171-33",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35785 Bal Clair St",
          "JobAddress2": "",
          "JobFullAddress": "35785 Bal Clair St",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4114927,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Arthur Cartright",
          "JobNumber": "502-1604171-32",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45 Orchard LN",
          "JobAddress2": "",
          "JobFullAddress": "45 Orchard LN",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$4,123.92",
          "BranchNumber": 8,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4114926,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Natural Remedy",
          "JobNumber": "502-876650-387",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "925 E Drayton Rd",
          "JobAddress2": "",
          "JobFullAddress": "925 E Drayton Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4114925,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Brookside Pool",
          "JobNumber": "502-876650-386",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "369 N Old Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "369 N Old Woodward Ave",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4114924,
          "ParentWorkOrder": "",
          "JobName": "NOF APPCO Control",
          "JobNumber": "502-818550-5",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4224 MARTIN RD",
          "JobAddress2": "",
          "JobFullAddress": "4224 MARTIN RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE MANAGEMENT",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,566.23",
          "BranchNumber": 8,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "INDEPENDENCE MANAGEMENT"
        },
        {
          "WorkOrder": 4114923,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Evolution",
          "JobNumber": "502-815750-312",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 Town Center",
          "JobAddress2": "",
          "JobFullAddress": "1000 Town Center",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC."
        },
        {
          "WorkOrder": 4114922,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HILLSIDE MIDDLE SCHO",
          "JobNumber": "502-815750-307",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "775 N CENTER ST",
          "JobAddress2": "",
          "JobFullAddress": "775 N CENTER ST",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$36,753.17",
          "BranchNumber": 8,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC."
        },
        {
          "WorkOrder": 4114921,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FCA Freud Metering",
          "JobNumber": "502-815250-386",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1207 Terminal St",
          "JobAddress2": "",
          "JobFullAddress": "1207 Terminal St",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$13,372.90",
          "BranchNumber": 8,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4114920,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DTE GO STAIRWELL",
          "JobNumber": "502-813200-150",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Plz",
          "JobAddress2": "",
          "JobFullAddress": "1 Energy Plz",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4114919,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HOLIDAY INN",
          "JobNumber": "502-812692-25",
          "FirstdayontheJob": "03-30-2021",
          "LastdayontheJob": "03-30-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2501 TITTABAWASSE RD",
          "JobAddress2": "",
          "JobFullAddress": "2501 TITTABAWASSE RD",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "HARBAUGH CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$970.84",
          "BranchNumber": 8,
          "SubmitDate": "03-31-2021",
          "ClientonJob": "HARBAUGH CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 4113046,
          "ParentWorkOrder": "",
          "JobName": "MCDONALDS MILFORD",
          "JobNumber": "65-137581-7",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1101 STATE ROUTE 28",
          "JobAddress2": "",
          "JobFullAddress": "1101 STATE ROUTE 28",
          "JobCounty": "Clermont",
          "JobState": "Ohio",
          "CustomerCompanyName": "LIS CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$5,976.31",
          "BranchNumber": 13,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "LIS CONSTRUCTION"
        },
        {
          "WorkOrder": 4113045,
          "ParentWorkOrder": "",
          "JobName": "7 11 EAST COURT ST APART",
          "JobNumber": "65-2134697-21",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "915 917 VINE ST",
          "JobAddress2": "",
          "JobFullAddress": "915 917 VINE ST",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "J.F. BRAKE CONTRACTOR",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$14,397.30",
          "BranchNumber": 12,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "J.F. BRAKE CONTRACTOR"
        },
        {
          "WorkOrder": 4113044,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GENESIS ORTHOPEDIC",
          "JobNumber": "65-433386-9",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2904 BELL ST",
          "JobAddress2": "",
          "JobFullAddress": "2904 BELL ST",
          "JobCounty": "Muskingum",
          "JobState": "Ohio",
          "CustomerCompanyName": "Paul Construction Co Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$35,081.12",
          "BranchNumber": 4,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "Paul Construction Co Inc"
        },
        {
          "WorkOrder": 4113043,
          "ParentWorkOrder": "",
          "JobName": "PILOT TRAVEL CENTER 699",
          "JobNumber": "65-415739-2",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10480 BALTIMORE RD",
          "JobAddress2": "",
          "JobFullAddress": "10480 BALTIMORE RD",
          "JobCounty": "LICKING",
          "JobState": "Ohio",
          "CustomerCompanyName": "MRB GENERAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,631.61",
          "BranchNumber": 4,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "MRB GENERAL CONTRACTING"
        },
        {
          "WorkOrder": 4113042,
          "ParentWorkOrder": "",
          "JobName": "DRESS FOR SUCESS",
          "JobNumber": "65-510300-66",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4623 WESLEY AVE",
          "JobAddress2": "",
          "JobFullAddress": "4623 WESLEY AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$5,487.46",
          "BranchNumber": 2,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4113041,
          "ParentWorkOrder": "",
          "JobName": "THE RIDGE",
          "JobNumber": "65-504193-48",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4562 OHIO 222",
          "JobAddress2": "",
          "JobFullAddress": "4562 OHIO 222",
          "JobCounty": "CLERMONT",
          "JobState": "Ohio",
          "CustomerCompanyName": "GPT ENTERPRISE INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$8,196.08",
          "BranchNumber": 2,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "GPT ENTERPRISE INC"
        },
        {
          "WorkOrder": 4113040,
          "ParentWorkOrder": "",
          "JobName": "WESTPOINT DIST. CENTER",
          "JobNumber": "65-50355-6",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5235 WESTPOINT DR",
          "JobAddress2": "",
          "JobFullAddress": "5235 WESTPOINT DR",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "KERRICOOK CONST. INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$918.28",
          "BranchNumber": 2,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "KERRICOOK CONST. INC"
        },
        {
          "WorkOrder": 4113039,
          "ParentWorkOrder": "",
          "JobName": "NOF MULTI FAMILY PROJECT",
          "JobNumber": "502-864751-238",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "134 UNIVERSITY DR",
          "JobAddress2": "",
          "JobFullAddress": "134 UNIVERSITY DR",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "STILES DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$14,642.08",
          "BranchNumber": 11,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "STILES DRYWALL LLC"
        },
        {
          "WorkOrder": 4113038,
          "ParentWorkOrder": "",
          "JobName": "(NOF) PREMIER ACADEMY",
          "JobNumber": "502-839201-137",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "40724 SEVEN MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "40724 SEVEN MILE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLINTON INTERIORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$12,149.80",
          "BranchNumber": 11,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "CLINTON INTERIORS INC"
        },
        {
          "WorkOrder": 4113037,
          "ParentWorkOrder": "",
          "JobName": "(NOF) VIRGINIA TILE",
          "JobNumber": "502-931850-13",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "900 W Maple Rd",
          "JobAddress2": "",
          "JobFullAddress": "900 W Maple Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "STONY CREEK SERV.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$3,699.18",
          "BranchNumber": 9,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "STONY CREEK SERV."
        },
        {
          "WorkOrder": 4113036,
          "ParentWorkOrder": "",
          "JobName": "(NOF)#1646 PSYCHO BUNNY",
          "JobNumber": "502-913820-611",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "2800 W Big Beaver Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$573.72",
          "BranchNumber": 9,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4113035,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TCCC3",
          "JobNumber": "502-1639418-36",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1430 E 10 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "1430 E 10 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,233.22",
          "BranchNumber": 8,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4113034,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 277 Pierce",
          "JobNumber": "502-1639418-35",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "277 Pierce St",
          "JobAddress2": "",
          "JobFullAddress": "277 Pierce St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$987.07",
          "BranchNumber": 8,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4113033,
          "ParentWorkOrder": "",
          "JobName": "(NOF) JERRY DAGENAIS",
          "JobNumber": "502-1465490-2",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1101 DEERPATH TRAIL",
          "JobAddress2": "",
          "JobFullAddress": "1101 DEERPATH TRAIL",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MID THUMB CONTRACTING GRP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$962.99",
          "BranchNumber": 8,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "MID THUMB CONTRACTING GRP"
        },
        {
          "WorkOrder": 4113032,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCC Dentist",
          "JobNumber": "502-920940-101",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27956 College Park Drive",
          "JobAddress2": "",
          "JobFullAddress": "27956 College Park Drive",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4113031,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Powell ALTS-9th GR",
          "JobNumber": "502-816350-164",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "62100 Jewell Road",
          "JobAddress2": "",
          "JobFullAddress": "62100 Jewell Road",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4113030,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Yeshiva Phase 2 2nd",
          "JobNumber": "502-815750-310",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15751 Lincoln Dr",
          "JobAddress2": "",
          "JobFullAddress": "15751 Lincoln Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCT-ION SPECIALISTS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCT-ION SPECIALISTS, INC."
        },
        {
          "WorkOrder": 4113029,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3172 D-HAM GMP",
          "JobNumber": "502-813200-149",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2500 E Grand Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2500 E Grand Blvd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$5,810.20",
          "BranchNumber": 8,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4113028,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Comerica #55Dearborn",
          "JobNumber": "502-1162526-5",
          "FirstdayontheJob": "03-23-2021",
          "LastdayontheJob": "03-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25851 Joy Rd",
          "JobAddress2": "",
          "JobFullAddress": "25851 Joy Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ALAN JAMES CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "03-24-2021",
          "ClientonJob": "ALAN JAMES CONSTRUCTION"
        },
        {
          "WorkOrder": 4112676,
          "ParentWorkOrder": 4105407,
          "JobName": "NORWOOD VIEW ELEMENTARY",
          "JobNumber": "65-550796-8",
          "FirstdayontheJob": "02-24-2021",
          "LastdayontheJob": "02-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5328 CARTHAGE AVE",
          "JobAddress2": "",
          "JobFullAddress": "5328 CARTHAGE AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "DE GEORGE CEILINGS",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$6,943.24",
          "BranchNumber": 2,
          "SubmitDate": "03-23-2021",
          "ClientonJob": "DE GEORGE CEILINGS"
        },
        {
          "WorkOrder": 4111714,
          "ParentWorkOrder": "",
          "JobName": "THE FORUM",
          "JobNumber": "65-2137106-5",
          "FirstdayontheJob": "03-17-2021",
          "LastdayontheJob": "03-17-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "707 MLK BLVD",
          "JobAddress2": "",
          "JobFullAddress": "707 MLK BLVD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "HAYS & SONS CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,918.07",
          "BranchNumber": 12,
          "SubmitDate": "03-18-2021",
          "ClientonJob": "HAYS & SONS CONSTRUCTION"
        },
        {
          "WorkOrder": 4111713,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Empire Building",
          "JobNumber": "65-49800-3",
          "FirstdayontheJob": "03-17-2021",
          "LastdayontheJob": "03-17-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "150 East Broad St",
          "JobAddress2": "",
          "JobFullAddress": "150 East Broad St",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Horton Construction Team",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$671.85",
          "BranchNumber": 4,
          "SubmitDate": "03-18-2021",
          "ClientonJob": "Horton Construction Team"
        },
        {
          "WorkOrder": 4111712,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CROWN POINTE",
          "JobNumber": "65-48500-3",
          "FirstdayontheJob": "03-17-2021",
          "LastdayontheJob": "03-17-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "96-119 DERRER HEIGHT LANE",
          "JobAddress2": "",
          "JobFullAddress": "96-119 DERRER HEIGHT LANE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "FIRE SEAL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$10,821.39",
          "BranchNumber": 4,
          "SubmitDate": "03-18-2021",
          "ClientonJob": "FIRE SEAL LLC"
        },
        {
          "WorkOrder": 4111711,
          "ParentWorkOrder": "",
          "JobName": "FRANKLINTON PREP HS",
          "JobNumber": "65-42942-6",
          "FirstdayontheJob": "03-17-2021",
          "LastdayontheJob": "03-17-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "89 Avondale Ave",
          "JobAddress2": "",
          "JobFullAddress": "89 Avondale Ave",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "C.A. Orr Construction Company LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$63.14",
          "BranchNumber": 4,
          "SubmitDate": "03-18-2021",
          "ClientonJob": "C.A. Orr Construction Company LLC"
        },
        {
          "WorkOrder": 4111710,
          "ParentWorkOrder": "",
          "JobName": "SYCAMORE SPRINGS",
          "JobNumber": "65-510300-65",
          "FirstdayontheJob": "03-17-2021",
          "LastdayontheJob": "03-17-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2164 E CENTRAL AVE",
          "JobAddress2": "",
          "JobFullAddress": "2164 E CENTRAL AVE",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$5,917.42",
          "BranchNumber": 2,
          "SubmitDate": "03-18-2021",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4111709,
          "ParentWorkOrder": "",
          "JobName": "COURT STREET BAR",
          "JobNumber": "65-504193-49",
          "FirstdayontheJob": "03-17-2021",
          "LastdayontheJob": "03-17-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "56 E COURT ST",
          "JobAddress2": "",
          "JobFullAddress": "56 E COURT ST",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "GPT ENTERPRISE INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,496.85",
          "BranchNumber": 2,
          "SubmitDate": "03-18-2021",
          "ClientonJob": "GPT ENTERPRISE INC"
        },
        {
          "WorkOrder": 4111323,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Roslyn-Gr. Pt. Shore",
          "JobNumber": "502-877250-174",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "60 ROSLYN RD",
          "JobAddress2": "",
          "JobFullAddress": "60 ROSLYN RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUE WATER DRYWALL & CONSTRUCTION, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$6,877.98",
          "BranchNumber": 11,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "BLUE WATER DRYWALL & CONSTRUCTION, LLC"
        },
        {
          "WorkOrder": 4111322,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HMOR-6817 23 Mile",
          "JobNumber": "502-164470-11",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6817 23 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "6817 23 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,006.00",
          "BalanceRemaining": "$1,590.57",
          "BranchNumber": 11,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4111321,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Bayside Commons sub",
          "JobNumber": "502-142370-4",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29235 Cotton Rd",
          "JobAddress2": "",
          "JobFullAddress": "29235 Cotton Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PRIORITY CONSTRUCTORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,761.65",
          "BranchNumber": 11,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "PRIORITY CONSTRUCTORS INC"
        },
        {
          "WorkOrder": 4111320,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MSU FEDERAL CU",
          "JobNumber": "502-933550-195",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3220 University Dr",
          "JobAddress2": "",
          "JobFullAddress": "3220 University Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,011.00",
          "BalanceRemaining": "$11,597.89",
          "BranchNumber": 9,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4111319,
          "ParentWorkOrder": "",
          "JobName": "(NOF) JADALLAH",
          "JobNumber": "502-917690-14",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13375 Haverhill Dr",
          "JobAddress2": "",
          "JobFullAddress": "13375 Haverhill Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Kingdom Construction, Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "Kingdom Construction, Inc"
        },
        {
          "WorkOrder": 4111318,
          "ParentWorkOrder": "",
          "JobName": "(NOF) M.P.S. LIVONIA",
          "JobNumber": "502-916800-33",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19499 Victor Pkwy",
          "JobAddress2": "",
          "JobFullAddress": "19499 Victor Pkwy",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "JASMAN CONSTRUCTION, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$256.31",
          "BranchNumber": 9,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "JASMAN CONSTRUCTION, INC"
        },
        {
          "WorkOrder": 4111317,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TULSON VETERINARY",
          "JobNumber": "502-916315-209",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5733 SAINT JOSEPH AVE",
          "JobAddress2": "",
          "JobFullAddress": "5733 SAINT JOSEPH AVE",
          "JobCounty": "BERRIEN",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$4,746.38",
          "BranchNumber": 9,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4111316,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Walgreens Flint",
          "JobNumber": "502-916315-208",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1419 N Dort Hwy",
          "JobAddress2": "",
          "JobFullAddress": "1419 N Dort Hwy",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$25,042.20",
          "BranchNumber": 9,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4111315,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TOTAL WINE GRAND",
          "JobNumber": "502-916315-207",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4923 28TH ST SE",
          "JobAddress2": "",
          "JobFullAddress": "4923 28TH ST SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$21,865.99",
          "BranchNumber": 9,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4111314,
          "ParentWorkOrder": "",
          "JobName": "(NOF)AUTO ZONE WYOMING",
          "JobNumber": "502-916315-206",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1148 28th St SW",
          "JobAddress2": "",
          "JobFullAddress": "1148 28th St SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,011.00",
          "BalanceRemaining": "$35,588.37",
          "BranchNumber": 9,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4111313,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1647 BATH & BODY",
          "JobNumber": "502-913820-610",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4000 BALDWIN RD",
          "JobAddress2": "",
          "JobFullAddress": "4000 BALDWIN RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$7,512.18",
          "BranchNumber": 9,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4111312,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Psycho Bunny",
          "JobNumber": "502-913820-609",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "2800 W Big Beaver Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4111311,
          "ParentWorkOrder": "",
          "JobName": "(NOF)#1644 GOLDEN GOOSE",
          "JobNumber": "502-913820-607",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "2800 W Big Beaver Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$3,999.84",
          "BranchNumber": 9,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4111310,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PURE SOURCE",
          "JobNumber": "502-151515-3",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23230 Amber Ave",
          "JobAddress2": "",
          "JobFullAddress": "23230 Amber Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "CI ENGINEERING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$17,083.25",
          "BranchNumber": 9,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "CI ENGINEERING"
        },
        {
          "WorkOrder": 4111309,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Clarkston Medical",
          "JobNumber": "502-1605400-5",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6770 Dixie Highway",
          "JobAddress2": "",
          "JobFullAddress": "6770 Dixie Highway",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Carpenter Brothers",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$4,175.81",
          "BranchNumber": 8,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "Carpenter Brothers"
        },
        {
          "WorkOrder": 4111308,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CULVERS OXFORD",
          "JobNumber": "502-816080-4",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "525 N Lapeer Rd",
          "JobAddress2": "",
          "JobFullAddress": "525 N Lapeer Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Rosetta Building Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "Rosetta Building Co"
        },
        {
          "WorkOrder": 4111307,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3181 McLaren Pharmac",
          "JobNumber": "502-813200-148",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50 N Perry St",
          "JobAddress2": "",
          "JobFullAddress": "50 N Perry St",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$864.83",
          "BranchNumber": 8,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4111306,
          "ParentWorkOrder": "",
          "JobName": "(NOF) JSA 21.0113.04",
          "JobNumber": "502-971850-35",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "135 W Cortland St",
          "JobAddress2": "",
          "JobFullAddress": "135 W Cortland St",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "R.W. MERCER",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,412.27",
          "BranchNumber": 7,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "R.W. MERCER"
        },
        {
          "WorkOrder": 4111305,
          "ParentWorkOrder": "",
          "JobName": "(NOF)COMFORT INN WHITEHAL",
          "JobNumber": "502-1629870-3",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2822 N DURHAM RD",
          "JobAddress2": "",
          "JobFullAddress": "2822 N DURHAM RD",
          "JobCounty": "MUSKEGON",
          "JobState": "Michigan",
          "CustomerCompanyName": "PINNACLE HOSPITALITY INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,333.91",
          "BranchNumber": 1,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "PINNACLE HOSPITALITY INC"
        },
        {
          "WorkOrder": 4111304,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CASS CITY ELEMENTARY",
          "JobNumber": "502-10550-48",
          "FirstdayontheJob": "03-16-2021",
          "LastdayontheJob": "03-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4805 Ale St",
          "JobAddress2": "",
          "JobFullAddress": "4805 Ale St",
          "JobCounty": "TUSCOLA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Booms Construction Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$20,380.14",
          "BranchNumber": 1,
          "SubmitDate": "03-17-2021",
          "ClientonJob": "Booms Construction Inc."
        },
        {
          "WorkOrder": 4109294,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DICKS SPORTING GOOD",
          "JobNumber": "502-1304186-74",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4020 MILAN RD",
          "JobAddress2": "",
          "JobFullAddress": "4020 MILAN RD",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,000.44",
          "BranchNumber": 13,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4109293,
          "ParentWorkOrder": "",
          "JobName": "GENERATIONS CONSTRUCTION",
          "JobNumber": "65-502950-16",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "DEVIN CONSTRUCTION CO LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$30,000.00",
          "BalanceRemaining": "$7,125.21",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "DEVIN CONSTRUCTION CO LLC"
        },
        {
          "WorkOrder": 4109292,
          "ParentWorkOrder": "",
          "JobName": "BETHANY VILLAGE",
          "JobNumber": "65-13250-2",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6443 Far Hills Ave",
          "JobAddress2": "",
          "JobFullAddress": "6443 Far Hills Ave",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "FLAWLESS FINISH CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,011.00",
          "BalanceRemaining": "$4,617.00",
          "BranchNumber": 13,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "FLAWLESS FINISH CONSTRUCTION"
        },
        {
          "WorkOrder": 4109291,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OCC AH BLD-C ELKHORN",
          "JobNumber": "502-1670701-2",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2900 FEATHERSTONE RD",
          "JobAddress2": "",
          "JobFullAddress": "2900 FEATHERSTONE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "R & E DEV GROUP LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,000.00",
          "BalanceRemaining": "$165.81",
          "BranchNumber": 11,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "R & E DEV GROUP LLC"
        },
        {
          "WorkOrder": 4109290,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MC CITY HALL",
          "JobNumber": "502-865069-14",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "303 S WATER ST",
          "JobAddress2": "",
          "JobFullAddress": "303 S WATER ST",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Salski Construction LLC",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$18,006.00",
          "BalanceRemaining": "$7,517.22",
          "BranchNumber": 11,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "Salski Construction LLC"
        },
        {
          "WorkOrder": 4109289,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CADILIAC HOUSE",
          "JobNumber": "502-858221-30",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5502 MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "5502 MAIN ST",
          "JobCounty": "SANILAC",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISCOUNT DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$6,984.48",
          "BranchNumber": 11,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "DISCOUNT DRYWALL"
        },
        {
          "WorkOrder": 4109288,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FAIRFAX",
          "JobNumber": "502-111914-14",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1141 FAIRFAX ST",
          "JobAddress2": "",
          "JobFullAddress": "1141 FAIRFAX ST",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "GREAT LAKES CUSTOM BUILDER LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$12,946.79",
          "BranchNumber": 11,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "GREAT LAKES CUSTOM BUILDER LLC"
        },
        {
          "WorkOrder": 4109287,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LOTZ * CANTON",
          "JobNumber": "502-1677740-42",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1460 S Lotz Rd",
          "JobAddress2": "",
          "JobFullAddress": "1460 S Lotz Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$13,006.00",
          "BalanceRemaining": "$11,984.67",
          "BranchNumber": 9,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 4109286,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NEW CENTER PLAZA",
          "JobNumber": "502-1507798-58",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7450 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "7450 Woodward Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4109285,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TRANFIGURATION",
          "JobNumber": "502-1325000-44",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13300 Syracuse St",
          "JobAddress2": "",
          "JobFullAddress": "13300 Syracuse St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4109284,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MM CAREGIVER FACILIT",
          "JobNumber": "502-1632825-10",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32931 Gratiot Ave",
          "JobAddress2": "",
          "JobFullAddress": "32931 Gratiot Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR CONST & DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$9,817.29",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "SUPERIOR CONST & DRYWALL LLC"
        },
        {
          "WorkOrder": 4109283,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GALLERIES PHASE 2",
          "JobNumber": "502-886833-17",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29469 NORTHWESTERN HWY",
          "JobAddress2": "",
          "JobFullAddress": "29469 NORTHWESTERN HWY",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MOTOR CITY CONSTRUCTION & REMODLING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$7,152.90",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "MOTOR CITY CONSTRUCTION & REMODLING"
        },
        {
          "WorkOrder": 4109282,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Lindsay Books Store",
          "JobNumber": "502-817800-53",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "92 Kercheval Ave",
          "JobAddress2": "",
          "JobFullAddress": "92 Kercheval Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4109281,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Adient Phase 1B",
          "JobNumber": "502-815750-308",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "49200 Halyard Dr.",
          "JobAddress2": "",
          "JobFullAddress": "49200 Halyard Dr.",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS INC"
        },
        {
          "WorkOrder": 4109280,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Belle Tire #29",
          "JobNumber": "502-967075-130",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31053 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "31053 Woodward Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 4109279,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HOMEWOOD SUITES",
          "JobNumber": "502-1014005-14",
          "FirstdayontheJob": "03-10-2021",
          "LastdayontheJob": "03-10-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2457 S STATE ST",
          "JobAddress2": "",
          "JobFullAddress": "2457 S STATE ST",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "RUDYS PLASTERING AND DRYWALL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$5,277.03",
          "BranchNumber": 5,
          "SubmitDate": "03-11-2021",
          "ClientonJob": "RUDYS PLASTERING AND DRYWALL FINISH"
        },
        {
          "WorkOrder": 4109088,
          "ParentWorkOrder": 4108003,
          "JobName": "Buzzards Bay",
          "JobNumber": "4109088",
          "FirstdayontheJob": "10-16-2020",
          "LastdayontheJob": "12-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25 Perry Ave",
          "JobAddress2": "",
          "JobFullAddress": "25 Perry Ave",
          "JobCounty": "Barnstable",
          "JobState": "Massachusetts",
          "CustomerCompanyName": "Calamar Construction Management Inc",
          "RequestType": "Statement of Account",
          "CustomerRepresentative": "Amy Chiodo",
          "Status": "Recorded",
          "ContractAmount": "$224,933.06",
          "BalanceRemaining": "$224,933.06",
          "BranchNumber": ".",
          "SubmitDate": "03-10-2021",
          "ClientonJob": "Calamar Construction Management Inc"
        },
        {
          "WorkOrder": 4108440,
          "ParentWorkOrder": 4101579,
          "JobName": "(NOF) JACKSON DIST LIBRAR",
          "JobNumber": "502-919700-143",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "207 N MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "207 N MAIN ST",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,011.00",
          "BalanceRemaining": "$6,729.16",
          "BranchNumber": 9,
          "SubmitDate": "03-08-2021",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 4108436,
          "ParentWorkOrder": 4104996,
          "JobName": "(NOF)STOCKBRIDGE",
          "JobNumber": "502-1610483-45",
          "FirstdayontheJob": "02-23-2021",
          "LastdayontheJob": "02-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "833 S CLINTON RD",
          "JobAddress2": "",
          "JobFullAddress": "833 S CLINTON RD",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARTIN DRYWALL INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,413.33",
          "BranchNumber": 5,
          "SubmitDate": "03-08-2021",
          "ClientonJob": "MARTIN DRYWALL INC"
        },
        {
          "WorkOrder": 4108028,
          "ParentWorkOrder": 4104995,
          "JobName": "(NOF)212 RIVER ST",
          "JobNumber": "502-50435-81",
          "FirstdayontheJob": "02-23-2021",
          "LastdayontheJob": "02-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "212 S RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "212 S RIVER AVE",
          "JobCounty": "Ottawa",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR BOUWKAMP & ASSOC. INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "03-05-2021",
          "ClientonJob": "JR BOUWKAMP & ASSOC. INC"
        },
        {
          "WorkOrder": 4108003,
          "ParentWorkOrder": "",
          "JobName": "Buzzards Bay",
          "JobNumber": "4108003",
          "FirstdayontheJob": "10-16-2020",
          "LastdayontheJob": "12-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25 Perry Ave",
          "JobAddress2": "",
          "JobFullAddress": "25 Perry Ave",
          "JobCounty": "Barnstable",
          "JobState": "Massachusetts",
          "CustomerCompanyName": "Calamar Construction Management Inc",
          "RequestType": "Notice of Contract - Lien (Subcontractor/ Supplier)",
          "CustomerRepresentative": "Amy Chiodo",
          "Status": "Recorded",
          "ContractAmount": "$224,933.06",
          "BalanceRemaining": "$224,933.06",
          "BranchNumber": ".",
          "SubmitDate": "03-05-2021",
          "ClientonJob": "Calamar Construction Management Inc"
        },
        {
          "WorkOrder": 4107215,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NPC",
          "JobNumber": "502-1609034-57",
          "FirstdayontheJob": "03-03-2021",
          "LastdayontheJob": "03-03-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1052 Pray Blvd.",
          "JobAddress2": "",
          "JobFullAddress": "1052 Pray Blvd.",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,113.58",
          "BranchNumber": 13,
          "SubmitDate": "03-04-2021",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4107213,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TH PLASTICS",
          "JobNumber": "502-1301172-52",
          "FirstdayontheJob": "03-03-2021",
          "LastdayontheJob": "03-03-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "680 TYBER RD",
          "JobAddress2": "",
          "JobFullAddress": "680 TYBER RD",
          "JobCounty": "SENECA",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$25,188.06",
          "BranchNumber": 13,
          "SubmitDate": "03-04-2021",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 4107212,
          "ParentWorkOrder": "",
          "JobName": "JOHNSTONE SUPPLY",
          "JobNumber": "65-2134697-20",
          "FirstdayontheJob": "03-03-2021",
          "LastdayontheJob": "03-03-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1212 Dalton Ave",
          "JobAddress2": "",
          "JobFullAddress": "1212 Dalton Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "J.F. BRAKE CONTRACTOR",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$3,668.54",
          "BranchNumber": 12,
          "SubmitDate": "03-04-2021",
          "ClientonJob": "J.F. BRAKE CONTRACTOR"
        },
        {
          "WorkOrder": 4107211,
          "ParentWorkOrder": "",
          "JobName": "915 VINE ST PROJECT",
          "JobNumber": "65-124263-3",
          "FirstdayontheJob": "03-03-2021",
          "LastdayontheJob": "03-03-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "915 VINE ST",
          "JobAddress2": "",
          "JobFullAddress": "915 VINE ST",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "WELLS & DAUGHTERS DRYWALL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$6,403.72",
          "BranchNumber": 12,
          "SubmitDate": "03-04-2021",
          "ClientonJob": "WELLS & DAUGHTERS DRYWALL"
        },
        {
          "WorkOrder": 4107210,
          "ParentWorkOrder": "",
          "JobName": "(NOF) KROGER  A-832",
          "JobNumber": "65-48740-3",
          "FirstdayontheJob": "03-03-2021",
          "LastdayontheJob": "03-03-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "965 N Bechtle Ave",
          "JobAddress2": "",
          "JobFullAddress": "965 N Bechtle Ave",
          "JobCounty": "CLARK",
          "JobState": "Ohio",
          "CustomerCompanyName": "F H Martin Constructors",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$17,446.20",
          "BranchNumber": 4,
          "SubmitDate": "03-04-2021",
          "ClientonJob": "F H Martin Constructors"
        },
        {
          "WorkOrder": 4107209,
          "ParentWorkOrder": "",
          "JobName": "NORWOOD VIEW ELEMENTARY",
          "JobNumber": "65-550796-8",
          "FirstdayontheJob": "03-03-2021",
          "LastdayontheJob": "03-03-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5328 CARTHAGE AVE",
          "JobAddress2": "",
          "JobFullAddress": "5328 CARTHAGE AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "DE GEORGE CEILINGS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$7,557.32",
          "BranchNumber": 2,
          "SubmitDate": "03-04-2021",
          "ClientonJob": "DE GEORGE CEILINGS"
        },
        {
          "WorkOrder": 4107208,
          "ParentWorkOrder": "",
          "JobName": "NOF JEWISH HOSPITAL",
          "JobNumber": "65-509570-8",
          "FirstdayontheJob": "03-03-2021",
          "LastdayontheJob": "03-03-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4777 E GALBRAITH RD",
          "JobAddress2": "",
          "JobFullAddress": "4777 E GALBRAITH RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "SCHUMACHER DUGAN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$48.00",
          "BranchNumber": 2,
          "SubmitDate": "03-04-2021",
          "ClientonJob": "SCHUMACHER DUGAN CONSTRUCTION"
        },
        {
          "WorkOrder": 4107207,
          "ParentWorkOrder": "",
          "JobName": "CINCINNATI POOL",
          "JobNumber": "65-505800-13",
          "FirstdayontheJob": "03-03-2021",
          "LastdayontheJob": "03-03-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10731 READING RD",
          "JobAddress2": "",
          "JobFullAddress": "10731 READING RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "KIESLAND DEVELOPMENT SERVICES LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$8,360.52",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "KIESLAND DEVELOPMENT SERVICES LLC"
        },
        {
          "WorkOrder": 4107098,
          "ParentWorkOrder": 4103232,
          "JobName": "(NOF) CHIPOTLE",
          "JobNumber": "502-1304186-73",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "851 SEAN DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "851 SEAN DRIVE",
          "JobCounty": "SANDUSKY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,011.00",
          "BalanceRemaining": "$7,564.09",
          "BranchNumber": 13,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4107091,
          "ParentWorkOrder": 4089313,
          "JobName": "(NOF) Fremont Retail",
          "JobNumber": "502-1304186-72",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "851 Sean Drive",
          "JobAddress2": "",
          "JobFullAddress": "851 Sean Drive",
          "JobCounty": "SANDUSKY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$13,236.77",
          "BranchNumber": 13,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4107052,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TROPICAL SMOOTHIE AH",
          "JobNumber": "502-1551310-8",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3987 BALDWIN RD",
          "JobAddress2": "",
          "JobFullAddress": "3987 BALDWIN RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELITE BUILDERS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$3,622.72",
          "BranchNumber": 11,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "ELITE BUILDERS LLC"
        },
        {
          "WorkOrder": 4107051,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HMOR-24690 PRATT RD",
          "JobNumber": "502-847401-177",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24690 PRATT RD",
          "JobAddress2": "",
          "JobFullAddress": "24690 PRATT RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$5,940.37",
          "BranchNumber": 11,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4107050,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Brandenburg Park #22",
          "JobNumber": "502-164470-10",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34341 Dante Dr",
          "JobAddress2": "",
          "JobFullAddress": "34341 Dante Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,806.00",
          "BalanceRemaining": "$4,364.04",
          "BranchNumber": 11,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4107049,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LEAF HOME STOCK",
          "JobNumber": "502-1601595-2",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50485 PONTIAC TRAIL",
          "JobAddress2": "",
          "JobFullAddress": "50485 PONTIAC TRAIL",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEAF HOME SOLUTIONS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,506.00",
          "BalanceRemaining": "$2,780.36",
          "BranchNumber": 9,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "LEAF HOME SOLUTIONS"
        },
        {
          "WorkOrder": 4107048,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DISPATCH OFFICE",
          "JobNumber": "502-949575-132",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7047 W Warren Ave",
          "JobAddress2": "",
          "JobFullAddress": "7047 W Warren Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$1,936.83",
          "BranchNumber": 9,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4107047,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BOLDT COMPANY",
          "JobNumber": "502-949575-131",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "51740 GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "51740 GRAND RIVER AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$11,006.00",
          "BalanceRemaining": "$7,656.48",
          "BranchNumber": 9,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4107046,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WWCS John Glenn High",
          "JobNumber": "502-920940-100",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "36105 Marquette St",
          "JobAddress2": "",
          "JobFullAddress": "36105 Marquette St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4107045,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OU FITZGERALD ANIBAL",
          "JobNumber": "502-920940-99",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "614 PIONEER DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "614 PIONEER DRIVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$7,687.57",
          "BranchNumber": 8,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4107044,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DOLLAR TREE FREEMONT",
          "JobNumber": "502-876752-24",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1421 WEST MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "1421 WEST MAIN ST",
          "JobCounty": "NEWAYGO",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$2,644.55",
          "BranchNumber": 8,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 4107043,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dollar Tree St.Clair",
          "JobNumber": "502-876752-23",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "210 North Riverside Ave",
          "JobAddress2": "",
          "JobFullAddress": "210 North Riverside Ave",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,062.22",
          "BranchNumber": 8,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 4107042,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Lutheran Redeemer",
          "JobNumber": "502-817800-54",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1800 W Maple Rd",
          "JobAddress2": "",
          "JobFullAddress": "1800 W Maple Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4107041,
          "ParentWorkOrder": "",
          "JobName": "(NOF)APOLLO",
          "JobNumber": "502-815750-309",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30 CORPORATE DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "30 CORPORATE DRIVE",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,885.06",
          "BranchNumber": 8,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS INC"
        },
        {
          "WorkOrder": 4107040,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Jail Juvenail Det.",
          "JobNumber": "502-812350-103",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5560 Rivard Street",
          "JobAddress2": "",
          "JobFullAddress": "5560 Rivard Street",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4107039,
          "ParentWorkOrder": "",
          "JobName": "(NOF)St.Joe Mercy Oak EP",
          "JobNumber": "502-812350-100",
          "FirstdayontheJob": "03-02-2021",
          "LastdayontheJob": "03-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44405 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "44405 Woodward Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-03-2021",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4106233,
          "ParentWorkOrder": 4101573,
          "JobName": "(NOF)Strip Mall Orchard",
          "JobNumber": "502-812860-18",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6645 Orchard Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "6645 Orchard Lake Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BAYVIEW CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-01-2021",
          "ClientonJob": "BAYVIEW CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 4106231,
          "ParentWorkOrder": 4099412,
          "JobName": "(NOF)5229 LAKESHORE DR",
          "JobNumber": "502-164470-5",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5229 LAKESHORE DR",
          "JobAddress2": "",
          "JobFullAddress": "5229 LAKESHORE DR",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$4,917.30",
          "BranchNumber": 11,
          "SubmitDate": "03-01-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4105563,
          "ParentWorkOrder": 4101578,
          "JobName": "(NOF)Northline Church",
          "JobNumber": "502-916315-204",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22140 Champaign St",
          "JobAddress2": "",
          "JobFullAddress": "22140 Champaign St",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "02-25-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4105556,
          "ParentWorkOrder": 4103215,
          "JobName": "(NOF) WILL L LEE ELEM",
          "JobNumber": "502-810150-60",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "68399 S FOREST AVE",
          "JobAddress2": "",
          "JobFullAddress": "68399 S FOREST AVE",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTIC CEIL & PART",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$7,293.77",
          "BranchNumber": 7,
          "SubmitDate": "02-25-2021",
          "ClientonJob": "ACOUSTIC CEIL & PART"
        },
        {
          "WorkOrder": 4105552,
          "ParentWorkOrder": 4099640,
          "JobName": "(NOF) TIFFIN UNIVERSITY",
          "JobNumber": "502-1301172-58",
          "FirstdayontheJob": "02-03-2021",
          "LastdayontheJob": "02-03-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "203 MIAMI ST",
          "JobAddress2": "",
          "JobFullAddress": "203 MIAMI ST",
          "JobCounty": "SENECA",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$41,497.69",
          "BranchNumber": 13,
          "SubmitDate": "02-25-2021",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 4105412,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SANDUSKY CO SR CNTR",
          "JobNumber": "502-1305825-32",
          "FirstdayontheJob": "02-24-2021",
          "LastdayontheJob": "02-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1101 CASTALIA ST",
          "JobAddress2": "",
          "JobFullAddress": "1101 CASTALIA ST",
          "JobCounty": "SANDUSKY",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,969.36",
          "BranchNumber": 13,
          "SubmitDate": "02-25-2021",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 4105410,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Precision Concrete",
          "JobNumber": "502-1301172-60",
          "FirstdayontheJob": "02-24-2021",
          "LastdayontheJob": "02-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4851 COUNTY HWY 96",
          "JobAddress2": "",
          "JobFullAddress": "4851 COUNTY HWY 96",
          "JobCounty": "WYANDOT",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$11,869.50",
          "BranchNumber": 13,
          "SubmitDate": "02-25-2021",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 4105409,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TIFFIN UNIVERSITY",
          "JobNumber": "502-1301172-58",
          "FirstdayontheJob": "02-24-2021",
          "LastdayontheJob": "02-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "203 MIAMI ST",
          "JobAddress2": "",
          "JobFullAddress": "203 MIAMI ST",
          "JobCounty": "SENECA",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$38,873.71",
          "BranchNumber": 13,
          "SubmitDate": "02-25-2021",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 4105408,
          "ParentWorkOrder": "",
          "JobName": "915 VINE ST PROJECT",
          "JobNumber": "65-124263-3",
          "FirstdayontheJob": "02-24-2021",
          "LastdayontheJob": "02-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "915 VINE ST",
          "JobAddress2": "",
          "JobFullAddress": "915 VINE ST",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "WELLS & DAUGHTERS DRYWALL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,856.09",
          "BranchNumber": 12,
          "SubmitDate": "02-25-2021",
          "ClientonJob": "WELLS & DAUGHTERS DRYWALL"
        },
        {
          "WorkOrder": 4105407,
          "ParentWorkOrder": "",
          "JobName": "NORWOOD VIEW ELEMENTARY",
          "JobNumber": "65-550796-8",
          "FirstdayontheJob": "02-24-2021",
          "LastdayontheJob": "02-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5328 CARTHAGE AVE",
          "JobAddress2": "",
          "JobFullAddress": "5328 CARTHAGE AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "DE GEORGE CEILINGS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$6,943.24",
          "BranchNumber": 2,
          "SubmitDate": "02-25-2021",
          "ClientonJob": "DE GEORGE CEILINGS"
        },
        {
          "WorkOrder": 4105406,
          "ParentWorkOrder": "",
          "JobName": "CINCINNATI POOL",
          "JobNumber": "65-505800-13",
          "FirstdayontheJob": "02-24-2021",
          "LastdayontheJob": "02-24-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10731 Reading Rd",
          "JobAddress2": "",
          "JobFullAddress": "10731 Reading Rd",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "KIESLAND DEVELOPMENT SERVICES LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$8,360.52",
          "BranchNumber": 2,
          "SubmitDate": "02-25-2021",
          "ClientonJob": "KIESLAND DEVELOPMENT SERVICES LLC"
        },
        {
          "WorkOrder": 4105003,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BUCKLE",
          "JobNumber": "502-913820-600",
          "FirstdayontheJob": "02-23-2021",
          "LastdayontheJob": "02-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17420 HALL RD",
          "JobAddress2": "",
          "JobFullAddress": "17420 HALL RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$4,173.79",
          "BranchNumber": 9,
          "SubmitDate": "02-24-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4105002,
          "ParentWorkOrder": "",
          "JobName": "(NOF) JONES MAIN ST DEVEP",
          "JobNumber": "502-913438-12",
          "FirstdayontheJob": "02-23-2021",
          "LastdayontheJob": "02-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "510 MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "510 MAIN ST",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Davenport Brothers Constr",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,766.60",
          "BranchNumber": 9,
          "SubmitDate": "02-24-2021",
          "ClientonJob": "Davenport Brothers Constr"
        },
        {
          "WorkOrder": 4105001,
          "ParentWorkOrder": "",
          "JobName": "(NOF)NTH FARMS",
          "JobNumber": "502-913001-7",
          "FirstdayontheJob": "02-23-2021",
          "LastdayontheJob": "02-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1255 Falahee Rd",
          "JobAddress2": "",
          "JobFullAddress": "1255 Falahee Rd",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Interior Resource",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,284.55",
          "BranchNumber": 9,
          "SubmitDate": "02-24-2021",
          "ClientonJob": "Commercial Interior Resource"
        },
        {
          "WorkOrder": 4105000,
          "ParentWorkOrder": "",
          "JobName": "(NOF)AMAZON PNCL PARK PIL",
          "JobNumber": "502-910900-11",
          "FirstdayontheJob": "02-23-2021",
          "LastdayontheJob": "02-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34999 PENNSYLVANIA RD",
          "JobAddress2": "",
          "JobFullAddress": "34999 PENNSYLVANIA RD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANN ARBOR CEILING & PART",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,000.00",
          "BalanceRemaining": "$14,849.42",
          "BranchNumber": 9,
          "SubmitDate": "02-24-2021",
          "ClientonJob": "ANN ARBOR CEILING & PART"
        },
        {
          "WorkOrder": 4104999,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SCSP Vascular",
          "JobNumber": "502-823300-83",
          "FirstdayontheJob": "02-23-2021",
          "LastdayontheJob": "02-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18001 E 10 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "18001 E 10 Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-24-2021",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 4104998,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hudson's Basement",
          "JobNumber": "502-812350-101",
          "FirstdayontheJob": "02-23-2021",
          "LastdayontheJob": "02-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5500 Rivard St",
          "JobAddress2": "",
          "JobFullAddress": "5500 Rivard St",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-24-2021",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4104997,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WesternWayne Physici",
          "JobNumber": "502-16564-4",
          "FirstdayontheJob": "02-23-2021",
          "LastdayontheJob": "02-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15160 Levan Rd",
          "JobAddress2": "",
          "JobFullAddress": "15160 Levan Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARINE CITY CONTRACTING INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$5,078.50",
          "BranchNumber": 8,
          "SubmitDate": "02-24-2021",
          "ClientonJob": "MARINE CITY CONTRACTING INC"
        },
        {
          "WorkOrder": 4104996,
          "ParentWorkOrder": "",
          "JobName": "(NOF)STOCKBRIDGE",
          "JobNumber": "502-1610483-45",
          "FirstdayontheJob": "02-23-2021",
          "LastdayontheJob": "02-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "833 S CLINTON RD",
          "JobAddress2": "",
          "JobFullAddress": "833 S CLINTON RD",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARTIN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,413.33",
          "BranchNumber": 5,
          "SubmitDate": "02-24-2021",
          "ClientonJob": "MARTIN DRYWALL INC"
        },
        {
          "WorkOrder": 4104995,
          "ParentWorkOrder": "",
          "JobName": "(NOF)212 RIVER ST",
          "JobNumber": "502-50435-81",
          "FirstdayontheJob": "02-23-2021",
          "LastdayontheJob": "02-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "212 S RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "212 S RIVER AVE",
          "JobCounty": "Ottawa",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR BOUWKAMP & ASSOC. INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "02-24-2021",
          "ClientonJob": "JR BOUWKAMP & ASSOC. INC"
        },
        {
          "WorkOrder": 4103232,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CHIPOTLE",
          "JobNumber": "502-1304186-73",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "851 SEAN DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "851 SEAN DRIVE",
          "JobCounty": "SANDUSKY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,011.00",
          "BalanceRemaining": "$7,564.09",
          "BranchNumber": 13,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4103231,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Rite Aid",
          "JobNumber": "502-1304186-71",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13741 Euclid Ave",
          "JobAddress2": "",
          "JobFullAddress": "13741 Euclid Ave",
          "JobCounty": "Cuyahoga",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$13,974.75",
          "BranchNumber": 13,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4103230,
          "ParentWorkOrder": "",
          "JobName": "ARTISTRY CINCINNATI",
          "JobNumber": "65-510457-4",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "601 E Pete Rose Way",
          "JobAddress2": "",
          "JobFullAddress": "601 E Pete Rose Way",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "PRIORITY 1 CONST SERVICES",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,000.00",
          "BalanceRemaining": "$28,958.23",
          "BranchNumber": 12,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "PRIORITY 1 CONST SERVICES"
        },
        {
          "WorkOrder": 4103229,
          "ParentWorkOrder": "",
          "JobName": "Kroger 836",
          "JobNumber": "65-473759-15",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 LEXINGTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "1500 LEXINGTON AVE",
          "JobCounty": "Richland",
          "JobState": "Ohio",
          "CustomerCompanyName": "R&W Fixturing Co Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$7,667.81",
          "BranchNumber": 4,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "R&W Fixturing Co Inc"
        },
        {
          "WorkOrder": 4103228,
          "ParentWorkOrder": "",
          "JobName": "INNOMARK",
          "JobNumber": "65-510300-64",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "420 Distribution Cir",
          "JobAddress2": "",
          "JobFullAddress": "420 Distribution Cir",
          "JobCounty": "Butler",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,742.11",
          "BranchNumber": 2,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4103227,
          "ParentWorkOrder": "",
          "JobName": "MINI SELF STORAGE",
          "JobNumber": "65-54907-2",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4615 BETHANY RD",
          "JobAddress2": "",
          "JobFullAddress": "4615 BETHANY RD",
          "JobCounty": "Warren",
          "JobState": "Ohio",
          "CustomerCompanyName": "JSD CONSTRUCTION SERVICES",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,232.84",
          "BranchNumber": 2,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "JSD CONSTRUCTION SERVICES"
        },
        {
          "WorkOrder": 4103224,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CVS HEALTH HUB 8049",
          "JobNumber": "502-811591-14",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4995 HIGHLAND RD",
          "JobAddress2": "",
          "JobFullAddress": "4995 HIGHLAND RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Fortney & Weygandt, Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,428.07",
          "BranchNumber": 11,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "Fortney & Weygandt, Inc."
        },
        {
          "WorkOrder": 4103223,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TSC-MARINE CITY",
          "JobNumber": "502-168313-2",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6730 S RIVER RD",
          "JobAddress2": "",
          "JobFullAddress": "6730 S RIVER RD",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "IDEAL COMPANY INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,011.00",
          "BalanceRemaining": "$8,791.05",
          "BranchNumber": 11,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "IDEAL COMPANY INC"
        },
        {
          "WorkOrder": 4103222,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BRANDENBURG PARK 24",
          "JobNumber": "502-164470-9",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34341 Dante Dr",
          "JobAddress2": "",
          "JobFullAddress": "34341 Dante Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,806.00",
          "BalanceRemaining": "$4,351.49",
          "BranchNumber": 11,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4103221,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Hickey Meadows West",
          "JobNumber": "502-164470-8",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29698 ANTHONY CT",
          "JobAddress2": "",
          "JobFullAddress": "29698 ANTHONY CT",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$3,685.81",
          "BranchNumber": 11,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4103220,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BOOK TOWER",
          "JobNumber": "502-981334-4",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1114 Washington Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1114 Washington Blvd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Christman Constructors",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "Christman Constructors"
        },
        {
          "WorkOrder": 4103219,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Greystone Financial",
          "JobNumber": "502-916315-205",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "665 Hulet Dr",
          "JobAddress2": "",
          "JobFullAddress": "665 Hulet Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$3,091.17",
          "BranchNumber": 9,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4103218,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Auto Zone Midland",
          "JobNumber": "502-916315-203",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1409 Joe Mann Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1409 Joe Mann Blvd",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,012.79",
          "BranchNumber": 9,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4103217,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9025 HFHybrid OR",
          "JobNumber": "502-815250-385",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2799 W Grand Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2799 W Grand Blvd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4103216,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Nothing Bundt Cakes",
          "JobNumber": "502-16564-5",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44630 Ford Rd",
          "JobAddress2": "",
          "JobFullAddress": "44630 Ford Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARINE CITY CONTRACTING INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$4,053.48",
          "BranchNumber": 8,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "MARINE CITY CONTRACTING INC"
        },
        {
          "WorkOrder": 4103215,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WILL L LEE ELEM",
          "JobNumber": "502-810150-60",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "68399 S FOREST AVE",
          "JobAddress2": "",
          "JobFullAddress": "68399 S FOREST AVE",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTIC CEIL & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$7,293.77",
          "BranchNumber": 7,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "ACOUSTIC CEIL & PART"
        },
        {
          "WorkOrder": 4103214,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MichiganInst.Urology",
          "JobNumber": "502-1162526-4",
          "FirstdayontheJob": "02-16-2021",
          "LastdayontheJob": "02-16-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11051 Hall Rd",
          "JobAddress2": "Suite 200",
          "JobFullAddress": "11051 Hall Rd Suite 200",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ALAN JAMES CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "02-17-2021",
          "ClientonJob": "ALAN JAMES CONSTRUCTION"
        },
        {
          "WorkOrder": 4102368,
          "ParentWorkOrder": 4092061,
          "JobName": "(NOF)PANDA EXP TOLDDO OH",
          "JobNumber": "502-858231-58",
          "FirstdayontheJob": "01-05-2021",
          "LastdayontheJob": "01-05-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5080 Monroe St",
          "JobAddress2": "",
          "JobFullAddress": "5080 Monroe St",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,386.81",
          "BranchNumber": 11,
          "SubmitDate": "02-12-2021",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 4102362,
          "ParentWorkOrder": 4097713,
          "JobName": "HARMONY @ ANDERSON",
          "JobNumber": "65-2134688-23",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6201 CLOUGH PIKE",
          "JobAddress2": "",
          "JobFullAddress": "6201 CLOUGH PIKE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVICE",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$300,000.00",
          "BalanceRemaining": "$15,801.36",
          "BranchNumber": 12,
          "SubmitDate": "02-12-2021",
          "ClientonJob": "BENCO CONSTRUCTION SERVICE"
        },
        {
          "WorkOrder": 4102027,
          "ParentWorkOrder": 4099402,
          "JobName": "(NOF)SJP ICU SOUTHFIELD",
          "JobNumber": "502-815250-383",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16001 W 9 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "16001 W 9 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$65,006.00",
          "BalanceRemaining": "$6,720.83",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2021",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4101617,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FIRST TEE",
          "JobNumber": "502-1304700-26",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4450 HILL AVE",
          "JobAddress2": "",
          "JobFullAddress": "4450 HILL AVE",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "LATHROP COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$13,104.65",
          "BranchNumber": 13,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "LATHROP COMPANY"
        },
        {
          "WorkOrder": 4101616,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SOLA SALON-WALKER",
          "JobNumber": "502-137665-13",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3165 ALPINE AVE NW",
          "JobAddress2": "SUITE H",
          "JobFullAddress": "3165 ALPINE AVE NW SUITE H",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "BANYAN CONSTRUCTION SERVICES LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "BANYAN CONSTRUCTION SERVICES LLC"
        },
        {
          "WorkOrder": 4101615,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SCOTTS MAINTENANCE",
          "JobNumber": "65-484402-8",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14101 INDUSTRIAL PKWY",
          "JobAddress2": "",
          "JobFullAddress": "14101 INDUSTRIAL PKWY",
          "JobCounty": "UNION",
          "JobState": "Ohio",
          "CustomerCompanyName": "LCS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$13,519.65",
          "BranchNumber": 4,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "LCS LLC"
        },
        {
          "WorkOrder": 4101614,
          "ParentWorkOrder": "",
          "JobName": "Kroger - 942",
          "JobNumber": "65-473759-14",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1375 CHAMBERS RD",
          "JobAddress2": "",
          "JobFullAddress": "1375 CHAMBERS RD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "R&W Fixturing Co Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$12,052.98",
          "BranchNumber": 4,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "R&W Fixturing Co Inc"
        },
        {
          "WorkOrder": 4101585,
          "ParentWorkOrder": "",
          "JobName": "(NOF)IV116 RM",
          "JobNumber": "502-859751-8",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "58733 Omo Rd",
          "JobAddress2": "",
          "JobFullAddress": "58733 Omo Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PIERSON - GIBBS HOMES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$5,611.64",
          "BranchNumber": 11,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "PIERSON - GIBBS HOMES"
        },
        {
          "WorkOrder": 4101584,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LANDMARK HURON AVE",
          "JobNumber": "502-164470-7",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "218 HURON AVE",
          "JobAddress2": "",
          "JobFullAddress": "218 HURON AVE",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$12,127.57",
          "BranchNumber": 11,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4101583,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BEAUMONT HOSP.WAYNE",
          "JobNumber": "502-1612050-3",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33155 Annapolis St",
          "JobAddress2": "",
          "JobFullAddress": "33155 Annapolis St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "THE ALBERT M HIGLEY CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,011.00",
          "BalanceRemaining": "$19,547.25",
          "BranchNumber": 9,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "THE ALBERT M HIGLEY CO"
        },
        {
          "WorkOrder": 4101582,
          "ParentWorkOrder": "",
          "JobName": "(NOF) THE ABINGTON",
          "JobNumber": "502-1325000-43",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "700 Seward St",
          "JobAddress2": "",
          "JobFullAddress": "700 Seward St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,011.00",
          "BalanceRemaining": "$7,110.43",
          "BranchNumber": 9,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4101581,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FRANK'S PIZZA",
          "JobNumber": "502-949575-129",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3144 Biddle Ave",
          "JobAddress2": "",
          "JobFullAddress": "3144 Biddle Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4101580,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GROSSE POINT 19060",
          "JobNumber": "502-933550-202",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17145 Maumee Ave",
          "JobAddress2": "",
          "JobFullAddress": "17145 Maumee Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,054.93",
          "BranchNumber": 9,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4101579,
          "ParentWorkOrder": "",
          "JobName": "(NOF) JACKSON DIST LIBRAR",
          "JobNumber": "502-919700-143",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "207 N MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "207 N MAIN ST",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,011.00",
          "BalanceRemaining": "$6,729.16",
          "BranchNumber": 9,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 4101578,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Northline Church",
          "JobNumber": "502-916315-204",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22140 Champaign St",
          "JobAddress2": "",
          "JobFullAddress": "22140 Champaign St",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4101577,
          "ParentWorkOrder": "",
          "JobName": "(NOF) RAIR BIG RAPIDS",
          "JobNumber": "502-916315-193",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "702 PERRY AVE",
          "JobAddress2": "",
          "JobFullAddress": "702 PERRY AVE",
          "JobCounty": "MECOSTA",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$24,380.06",
          "BranchNumber": 9,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4101576,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ASHLEY CAPITAL LAKE",
          "JobNumber": "502-939300-19",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "325 W Silverbell Rd Ste 220",
          "JobAddress2": "",
          "JobFullAddress": "325 W Silverbell Rd Ste 220",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D&S Contractors Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$55,006.00",
          "BalanceRemaining": "$19,512.14",
          "BranchNumber": 8,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "D&S Contractors Inc"
        },
        {
          "WorkOrder": 4101575,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Verizon Data Center",
          "JobNumber": "502-823200-98",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21009 Lahser Rd",
          "JobAddress2": "",
          "JobFullAddress": "21009 Lahser Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PONTIAC CEILING & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "PONTIAC CEILING & PART"
        },
        {
          "WorkOrder": 4101574,
          "ParentWorkOrder": "",
          "JobName": "(NOF)VIPER ROMULUS",
          "JobNumber": "502-823200-97",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15675 WAHRMAN RD",
          "JobAddress2": "",
          "JobFullAddress": "15675 WAHRMAN RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PONTIAC CEILING & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$250,000.00",
          "BalanceRemaining": "$2,680.26",
          "BranchNumber": 8,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "PONTIAC CEILING & PART"
        },
        {
          "WorkOrder": 4101573,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Strip Mall Orchard",
          "JobNumber": "502-812860-18",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6645 Orchard Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "6645 Orchard Lake Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BAYVIEW CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "BAYVIEW CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 4101572,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FAIRFIELD INN",
          "JobNumber": "502-812692-24",
          "FirstdayontheJob": "02-09-2021",
          "LastdayontheJob": "02-09-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3125 W Silver Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "3125 W Silver Lake Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "HARBAUGH CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,011.00",
          "BalanceRemaining": "$2,615.17",
          "BranchNumber": 8,
          "SubmitDate": "02-10-2021",
          "ClientonJob": "HARBAUGH CONSTRUCTION"
        },
        {
          "WorkOrder": 4099640,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TIFFIN UNIVERSITY",
          "JobNumber": "502-1301172-58",
          "FirstdayontheJob": "02-03-2021",
          "LastdayontheJob": "02-03-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "203 MIAMI ST",
          "JobAddress2": "",
          "JobFullAddress": "203 MIAMI ST",
          "JobCounty": "SENECA",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$41,497.69",
          "BranchNumber": 13,
          "SubmitDate": "02-04-2021",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 4099639,
          "ParentWorkOrder": "",
          "JobName": "CHRISTIAN VILLAGE STONE",
          "JobNumber": "65-128740-2",
          "FirstdayontheJob": "02-03-2021",
          "LastdayontheJob": "02-03-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "411 Western Row Rd",
          "JobAddress2": "",
          "JobFullAddress": "411 Western Row Rd",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "S&B CONSTRUCTION GROUP",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,000.00",
          "BalanceRemaining": "$43,351.05",
          "BranchNumber": 12,
          "SubmitDate": "02-04-2021",
          "ClientonJob": "S&B CONSTRUCTION GROUP"
        },
        {
          "WorkOrder": 4099638,
          "ParentWorkOrder": "",
          "JobName": "NOF BEACON ORTHO",
          "JobNumber": "65-510300-63",
          "FirstdayontheJob": "02-03-2021",
          "LastdayontheJob": "02-03-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8099 CORNELLE RD",
          "JobAddress2": "",
          "JobFullAddress": "8099 CORNELLE RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$11,295.48",
          "BranchNumber": 2,
          "SubmitDate": "02-04-2021",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4099600,
          "ParentWorkOrder": 4097767,
          "JobName": "(NOF)RAIR Lowell",
          "JobNumber": "502-916315-199",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2264 West Main Street",
          "JobAddress2": "",
          "JobFullAddress": "2264 West Main Street",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$21,863.38",
          "BranchNumber": 9,
          "SubmitDate": "02-04-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4099414,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TSC-MARINE CITY",
          "JobNumber": "502-1605050-2",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6730 S RIVER RD",
          "JobAddress2": "",
          "JobFullAddress": "6730 S RIVER RD",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "BERGER CONSTRUCTION SERVICE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,915.42",
          "BranchNumber": 11,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "BERGER CONSTRUCTION SERVICE"
        },
        {
          "WorkOrder": 4099413,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Royal Park sub",
          "JobNumber": "502-164470-6",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14297 KINGS PARK COURT",
          "JobAddress2": "",
          "JobFullAddress": "14297 KINGS PARK COURT",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$5,559.81",
          "BranchNumber": 11,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4099412,
          "ParentWorkOrder": "",
          "JobName": "(NOF)5229 LAKESHORE DR",
          "JobNumber": "502-164470-5",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5229 LAKESHORE DR",
          "JobAddress2": "",
          "JobFullAddress": "5229 LAKESHORE DR",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$4,917.30",
          "BranchNumber": 11,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4099411,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ENGLISH ESTATES 44",
          "JobNumber": "502-164470-4",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "ENGLISH DRIVE LOT 44",
          "JobAddress2": "",
          "JobFullAddress": "ENGLISH DRIVE LOT 44",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$3,409.89",
          "BranchNumber": 11,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4099410,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ENGLISH ESTATES 8",
          "JobNumber": "502-164470-3",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "ENGLISH DRIVE LOT 8",
          "JobAddress2": "",
          "JobFullAddress": "ENGLISH DRIVE LOT 8",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$3,409.89",
          "BranchNumber": 11,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4099409,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ENGLISH ESTATES #42",
          "JobNumber": "502-164470-2",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "36101 ENGLISH DR",
          "JobAddress2": "",
          "JobFullAddress": "36101 ENGLISH DR",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "JEDDO DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$3,409.89",
          "BranchNumber": 11,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "JEDDO DRYWALL INC"
        },
        {
          "WorkOrder": 4099408,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dick's Sporting Good",
          "JobNumber": "502-934634-21",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44225 12 Mile Rd.",
          "JobAddress2": "",
          "JobFullAddress": "44225 12 Mile Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONST. GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,011.00",
          "BalanceRemaining": "$5,934.94",
          "BranchNumber": 9,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "BLUESTONE CONST. GROUP"
        },
        {
          "WorkOrder": 4099407,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SUGAR HILL DEVELOPMT",
          "JobNumber": "502-931850-12",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "112 GARFIELD ST",
          "JobAddress2": "",
          "JobFullAddress": "112 GARFIELD ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "STONY CREEK SERV",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$18,011.00",
          "BalanceRemaining": "$10,196.48",
          "BranchNumber": 9,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "STONY CREEK SERV"
        },
        {
          "WorkOrder": 4099406,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LAKES COMMUNITY CHUR",
          "JobNumber": "502-916315-202",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1450 S Hospital Rd",
          "JobAddress2": "",
          "JobFullAddress": "1450 S Hospital Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,011.00",
          "BalanceRemaining": "$10,063.79",
          "BranchNumber": 9,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4099405,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GENISYS BELLEVILLE",
          "JobNumber": "502-910868-69",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10444 BELLEVILLE RD",
          "JobAddress2": "",
          "JobFullAddress": "10444 BELLEVILLE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construct",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,011.00",
          "BalanceRemaining": "$336.01",
          "BranchNumber": 9,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "American Family Construct"
        },
        {
          "WorkOrder": 4099404,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Restore Birmingham",
          "JobNumber": "502-1631500-5",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "643 S Adams Rd.",
          "JobAddress2": "",
          "JobFullAddress": "643 S Adams Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOWER INTERIORS GROUP LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "TOWER INTERIORS GROUP LLC"
        },
        {
          "WorkOrder": 4099403,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hadrych Residence",
          "JobNumber": "502-876650-385",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "319 Oak Street",
          "JobAddress2": "",
          "JobFullAddress": "319 Oak Street",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$18,006.00",
          "BalanceRemaining": "$2,625.78",
          "BranchNumber": 8,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4099402,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SJP ICU SOUTHFIELD",
          "JobNumber": "502-815250-383",
          "FirstdayontheJob": "02-02-2021",
          "LastdayontheJob": "02-02-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16001 W 9 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "16001 W 9 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$65,006.00",
          "BalanceRemaining": "$6,720.83",
          "BranchNumber": 8,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4099388,
          "ParentWorkOrder": 4083962,
          "JobName": "(NOF) Circle K #5650",
          "JobNumber": "502-1304186-70",
          "FirstdayontheJob": "12-02-2020",
          "LastdayontheJob": "12-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "975 West Market St",
          "JobAddress2": "",
          "JobFullAddress": "975 West Market St",
          "JobCounty": "SENECA",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,402.42",
          "BranchNumber": 13,
          "SubmitDate": "02-03-2021",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4098345,
          "ParentWorkOrder": "",
          "JobName": "Octhapharma Plasma",
          "JobNumber": "393-16464-4",
          "FirstdayontheJob": "",
          "LastdayontheJob": "01-06-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4212 Elvis Presley Blvd",
          "JobAddress2": "",
          "JobFullAddress": "4212 Elvis Presley Blvd",
          "JobCounty": "Shelby",
          "JobState": "Tennessee",
          "CustomerCompanyName": "J&D Construction",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Olivia Quinones",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$45,970.45",
          "BranchNumber": 265,
          "SubmitDate": "01-29-2021",
          "ClientonJob": "J&D Construction"
        },
        {
          "WorkOrder": 4098338,
          "ParentWorkOrder": "",
          "JobName": "Octapharma",
          "JobNumber": "393-16464-2",
          "FirstdayontheJob": "",
          "LastdayontheJob": "12-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7124 Winchester Rd",
          "JobAddress2": "",
          "JobFullAddress": "7124 Winchester Rd",
          "JobCounty": "Shelby",
          "JobState": "Tennessee",
          "CustomerCompanyName": "J&D Construction",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Olivia Quinones",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$16,553.63",
          "BranchNumber": 265,
          "SubmitDate": "01-29-2021",
          "ClientonJob": "J&D Construction"
        },
        {
          "WorkOrder": 4098135,
          "ParentWorkOrder": 4074194,
          "JobName": "(NOF)KROGER#688",
          "JobNumber": "502-20010-35",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "400 S Maple Rd",
          "JobAddress2": "",
          "JobFullAddress": "400 S Maple Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$12,515.37",
          "BranchNumber": 8,
          "SubmitDate": "01-28-2021",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4098124,
          "ParentWorkOrder": 4089279,
          "JobName": "(NOF)Romeo Middle School",
          "JobNumber": "502-94091-25",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "297 Prospect St.",
          "JobAddress2": "",
          "JobFullAddress": "297 Prospect St.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "EJ Kaneris Inc",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$11,339.43",
          "BranchNumber": 8,
          "SubmitDate": "01-28-2021",
          "ClientonJob": "EJ Kaneris Inc"
        },
        {
          "WorkOrder": 4098122,
          "ParentWorkOrder": 4090453,
          "JobName": "(NOF) LMCU",
          "JobNumber": "502-814500-84",
          "FirstdayontheJob": "12-29-2020",
          "LastdayontheJob": "12-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "310 W Tienken Rd",
          "JobAddress2": "",
          "JobFullAddress": "310 W Tienken Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "CIC Contracting",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$21,867.41",
          "BranchNumber": 8,
          "SubmitDate": "01-28-2021",
          "ClientonJob": "CIC Contracting"
        },
        {
          "WorkOrder": 4097777,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HMOR-6948 Ridgewood",
          "JobNumber": "502-849801-284",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6948 Ridgewood Rd",
          "JobAddress2": "",
          "JobFullAddress": "6948 Ridgewood Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$5,959.07",
          "BranchNumber": 11,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 4097776,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HMOR-ALMONT HOUSE",
          "JobNumber": "502-849801-282",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8460 Almont Rd",
          "JobAddress2": "",
          "JobFullAddress": "8460 Almont Rd",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$8,196.01",
          "BranchNumber": 11,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 4097774,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WOODBRIDGE #55",
          "JobNumber": "502-847401-175",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1367 LINCOLN ST",
          "JobAddress2": "",
          "JobFullAddress": "1367 LINCOLN ST",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$2,366.62",
          "BranchNumber": 11,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4097773,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WOODBRIDGE PARK #54",
          "JobNumber": "502-847401-174",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1369 LINCOLN ST",
          "JobAddress2": "",
          "JobFullAddress": "1369 LINCOLN ST",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$2,658.50",
          "BranchNumber": 11,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4097772,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WOODBRIDGE PARK #53",
          "JobNumber": "502-847401-173",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1371 Lincoln St",
          "JobAddress2": "",
          "JobFullAddress": "1371 Lincoln St",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$2,530.69",
          "BranchNumber": 11,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4097771,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CANDLEWOOD HOTEL",
          "JobNumber": "502-1677740-40",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15595 HALL RD",
          "JobAddress2": "",
          "JobFullAddress": "15595 HALL RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$9,136.90",
          "BranchNumber": 9,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 4097770,
          "ParentWorkOrder": "",
          "JobName": "(NOF) X-GOLF  WOODHAVEN",
          "JobNumber": "502-1621630-3",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19840 WEST RD",
          "JobAddress2": "",
          "JobFullAddress": "19840 WEST RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "RUGER CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$13,275.52",
          "BranchNumber": 9,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "RUGER CONSTRUCTION"
        },
        {
          "WorkOrder": 4097769,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BIRM.COUNTRY CLUB",
          "JobNumber": "502-1507798-44",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1750 Saxon Dr",
          "JobAddress2": "",
          "JobFullAddress": "1750 Saxon Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,522.78",
          "BranchNumber": 9,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4097768,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ROCHESTER HOUSE",
          "JobNumber": "502-950450-22",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1856 S Rochester Rd",
          "JobAddress2": "",
          "JobFullAddress": "1856 S Rochester Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "William West Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,506.00",
          "BalanceRemaining": "$5,989.91",
          "BranchNumber": 9,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "William West Inc."
        },
        {
          "WorkOrder": 4097767,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RAIR Lowell",
          "JobNumber": "502-916315-199",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2264 West Main Street",
          "JobAddress2": "",
          "JobFullAddress": "2264 West Main Street",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$21,863.38",
          "BranchNumber": 9,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4097766,
          "ParentWorkOrder": "",
          "JobName": "(NOF)  CVS WYANDOTTE",
          "JobNumber": "502-913820-593",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2025 FORT ST",
          "JobAddress2": "",
          "JobFullAddress": "2025 FORT ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$1,318.98",
          "BranchNumber": 9,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4097765,
          "ParentWorkOrder": "",
          "JobName": "(NOF)UWM SPORTS COMPLEX",
          "JobNumber": "502-1639418-34",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "867 SOUTH BLVD E",
          "JobAddress2": "",
          "JobFullAddress": "867 SOUTH BLVD E",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$55,006.00",
          "BalanceRemaining": "$542.46",
          "BranchNumber": 8,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4097764,
          "ParentWorkOrder": "",
          "JobName": "(NOF)JETS PIZZA ST HTS",
          "JobNumber": "502-856071-88",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "37501 Mound Rd",
          "JobAddress2": "",
          "JobFullAddress": "37501 Mound Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$22,006.00",
          "BalanceRemaining": "$1,353.54",
          "BranchNumber": 8,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "M & V CONSTRUCTION"
        },
        {
          "WorkOrder": 4097763,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Meijer Outlet",
          "JobNumber": "502-815630-246",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22600 ALLEN RD",
          "JobAddress2": "",
          "JobFullAddress": "22600 ALLEN RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4097762,
          "ParentWorkOrder": "",
          "JobName": "(NOF) VA CANTON",
          "JobNumber": "502-814500-85",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5900 N Lotz Rd",
          "JobAddress2": "",
          "JobFullAddress": "5900 N Lotz Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "COMMERCIAL INT CONST"
        },
        {
          "WorkOrder": 4097761,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dr. Breasbois Dental",
          "JobNumber": "502-990400-8",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1455 N Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "1455 N Michigan Ave",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "RAJALA & SONS FINISHES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,890.00",
          "BranchNumber": 7,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "RAJALA & SONS FINISHES"
        },
        {
          "WorkOrder": 4097760,
          "ParentWorkOrder": "",
          "JobName": "(NOF) PREMIER BOTANICS",
          "JobNumber": "502-50435-80",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1450 W MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "1450 W MAIN ST",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR BOUWKAMP & ASSOC. INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$484.95",
          "BranchNumber": 5,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "JR BOUWKAMP & ASSOC. INC"
        },
        {
          "WorkOrder": 4097759,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GROWHOUSE",
          "JobNumber": "502-148155-6",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "902 BURSTEIN DR",
          "JobAddress2": "",
          "JobFullAddress": "902 BURSTEIN DR",
          "JobCounty": "CALHOUN",
          "JobState": "Michigan",
          "CustomerCompanyName": "METRO GENERAL CONTRACTOR INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "METRO GENERAL CONTRACTOR INC"
        },
        {
          "WorkOrder": 4097716,
          "ParentWorkOrder": "",
          "JobName": "(NOF)URBAN PINE",
          "JobNumber": "502-1399009-7",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3415 BRIARFIELD BLVD",
          "JobAddress2": "",
          "JobFullAddress": "3415 BRIARFIELD BLVD",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Canaan Construction Inc.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$5,172.58",
          "BranchNumber": 13,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "Canaan Construction Inc."
        },
        {
          "WorkOrder": 4097715,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CORNERSTONE APTS",
          "JobNumber": "65-2137179-17",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4950 CORNERSTONE N BLVD",
          "JobAddress2": "",
          "JobFullAddress": "4950 CORNERSTONE N BLVD",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "DURANGO DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$1,049.20",
          "BranchNumber": 13,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "DURANGO DRYWALL LLC"
        },
        {
          "WorkOrder": 4097714,
          "ParentWorkOrder": "",
          "JobName": "NOF SILCO FIRE & SECURITY",
          "JobNumber": "65-2137099-18",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10200 READING RD",
          "JobAddress2": "",
          "JobFullAddress": "10200 READING RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & A INTERIOR SYSTEMS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$2,459.48",
          "BranchNumber": 12,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "J & A INTERIOR SYSTEMS"
        },
        {
          "WorkOrder": 4097713,
          "ParentWorkOrder": "",
          "JobName": "HARMONY @ ANDERSON",
          "JobNumber": "65-2134688-23",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6201 CLOUGH PIKE",
          "JobAddress2": "",
          "JobFullAddress": "6201 CLOUGH PIKE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVICE",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$300,000.00",
          "BalanceRemaining": "$15,801.36",
          "BranchNumber": 12,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "BENCO CONSTRUCTION SERVICE"
        },
        {
          "WorkOrder": 4097712,
          "ParentWorkOrder": "",
          "JobName": "Krispy Kreme Doughnuts",
          "JobNumber": "65-120090-2",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7757 Tylersville RD",
          "JobAddress2": "",
          "JobFullAddress": "7757 Tylersville RD",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "MARK RUMPKE SERVICES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,189.38",
          "BranchNumber": 12,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "MARK RUMPKE SERVICES INC"
        },
        {
          "WorkOrder": 4097711,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BANK OF AMERICA",
          "JobNumber": "65-55219-14",
          "FirstdayontheJob": "01-26-2021",
          "LastdayontheJob": "01-26-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "132 CALHOUN ST",
          "JobAddress2": "",
          "JobFullAddress": "132 CALHOUN ST",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lemcer Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,373.87",
          "BranchNumber": 2,
          "SubmitDate": "01-27-2021",
          "ClientonJob": "Lemcer Construction LLC"
        },
        {
          "WorkOrder": 4097113,
          "ParentWorkOrder": 4094036,
          "JobName": "(NOF)CULVERS CLARE",
          "JobNumber": "502-916315-200",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10359 S Clare Ave",
          "JobAddress2": "",
          "JobFullAddress": "10359 S Clare Ave",
          "JobCounty": "CLARE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$223.36",
          "BranchNumber": 9,
          "SubmitDate": "01-25-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4096780,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CARDINAL STORAGE",
          "JobNumber": "65-417414-4",
          "FirstdayontheJob": "01-23-2021",
          "LastdayontheJob": "01-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5050 RIGGINS RD",
          "JobAddress2": "",
          "JobFullAddress": "5050 RIGGINS RD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "THE RABCO CORPORATION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$11,664.62",
          "BranchNumber": 4,
          "SubmitDate": "01-24-2021",
          "ClientonJob": "THE RABCO CORPORATION"
        },
        {
          "WorkOrder": 4096779,
          "ParentWorkOrder": "",
          "JobName": "(   ) ST. VINCENT DE PAUL",
          "JobNumber": "65-134513-2",
          "FirstdayontheJob": "01-23-2021",
          "LastdayontheJob": "01-23-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1133 S Edwin C Moses Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1133 S Edwin C Moses Blvd",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "COTTERMAN & COMPANY, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$2,393.40",
          "BranchNumber": 13,
          "SubmitDate": "01-24-2021",
          "ClientonJob": "COTTERMAN & COMPANY, INC."
        },
        {
          "WorkOrder": 4096573,
          "ParentWorkOrder": 4087718,
          "JobName": "(NOF)3163 North Oak Healt",
          "JobNumber": "502-813200-146",
          "FirstdayontheJob": "12-16-2020",
          "LastdayontheJob": "12-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34 East Building",
          "JobAddress2": "1200 Telegraph Rd.",
          "JobFullAddress": "34 East Building 1200 Telegraph Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$107.98",
          "BranchNumber": 8,
          "SubmitDate": "01-22-2021",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4095999,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ZF-FENTON",
          "JobNumber": "502-1650650-18",
          "FirstdayontheJob": "01-19-2021",
          "LastdayontheJob": "01-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9475 CENTER RD",
          "JobAddress2": "",
          "JobFullAddress": "9475 CENTER RD",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDUSTRIAL TECH SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,576.93",
          "BranchNumber": 11,
          "SubmitDate": "01-20-2021",
          "ClientonJob": "INDUSTRIAL TECH SERVICES"
        },
        {
          "WorkOrder": 4095998,
          "ParentWorkOrder": "",
          "JobName": "(NOF)AJM PACKING",
          "JobNumber": "502-933550-199",
          "FirstdayontheJob": "01-19-2021",
          "LastdayontheJob": "01-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4111 ANDOVER RD",
          "JobAddress2": "",
          "JobFullAddress": "4111 ANDOVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,254.67",
          "BranchNumber": 9,
          "SubmitDate": "01-20-2021",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4095997,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TACO BELL FRASER",
          "JobNumber": "502-916315-198",
          "FirstdayontheJob": "01-19-2021",
          "LastdayontheJob": "01-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34701 UTICA RD",
          "JobAddress2": "",
          "JobFullAddress": "34701 UTICA RD",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM CONST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,388.30",
          "BranchNumber": 9,
          "SubmitDate": "01-20-2021",
          "ClientonJob": "INDEPENDENCE COMM CONST"
        },
        {
          "WorkOrder": 4095996,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Culver Davison",
          "JobNumber": "502-916315-190",
          "FirstdayontheJob": "01-19-2021",
          "LastdayontheJob": "01-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7500 E Court St",
          "JobAddress2": "",
          "JobFullAddress": "7500 E Court St",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$2,817.63",
          "BranchNumber": 9,
          "SubmitDate": "01-20-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4095995,
          "ParentWorkOrder": "",
          "JobName": "(NOF) THE BRISTOL PHASE 2",
          "JobNumber": "502-910850-10",
          "FirstdayontheJob": "01-19-2021",
          "LastdayontheJob": "01-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "420 E Frank St",
          "JobAddress2": "",
          "JobFullAddress": "420 E Frank St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "AMERICAN WALL SYSTEM",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$3,622.16",
          "BranchNumber": 9,
          "SubmitDate": "01-20-2021",
          "ClientonJob": "AMERICAN WALL SYSTEM"
        },
        {
          "WorkOrder": 4095994,
          "ParentWorkOrder": "",
          "JobName": "(NOF)The Simple Greek ph2",
          "JobNumber": "502-1634342-4",
          "FirstdayontheJob": "01-19-2021",
          "LastdayontheJob": "01-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44510 Garfield Rd",
          "JobAddress2": "",
          "JobFullAddress": "44510 Garfield Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JC CONTRACTING NET LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$418.89",
          "BranchNumber": 8,
          "SubmitDate": "01-20-2021",
          "ClientonJob": "JC CONTRACTING NET LLC"
        },
        {
          "WorkOrder": 4095993,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WBC-Troy CampusBonus",
          "JobNumber": "502-1631500-3",
          "FirstdayontheJob": "01-19-2021",
          "LastdayontheJob": "01-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6600 Rochester Rd",
          "JobAddress2": "",
          "JobFullAddress": "6600 Rochester Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOWER INTERIORS GROUP LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "01-20-2021",
          "ClientonJob": "TOWER INTERIORS GROUP LLC"
        },
        {
          "WorkOrder": 4095992,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Ahmadiyya Muslim Ctr",
          "JobNumber": "502-1604171-31",
          "FirstdayontheJob": "01-19-2021",
          "LastdayontheJob": "01-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1730 W Auburn Rd",
          "JobAddress2": "",
          "JobFullAddress": "1730 W Auburn Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "01-20-2021",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4095991,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Connolly Residence",
          "JobNumber": "502-1604171-30",
          "FirstdayontheJob": "01-19-2021",
          "LastdayontheJob": "01-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "583 Tracy Lane",
          "JobAddress2": "",
          "JobFullAddress": "583 Tracy Lane",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "01-20-2021",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4095990,
          "ParentWorkOrder": "",
          "JobName": "(NOF) H&M RIVERTOWN MALL",
          "JobNumber": "502-1060142-41",
          "FirstdayontheJob": "01-19-2021",
          "LastdayontheJob": "01-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3700 RIVERTOWN PKWY SW",
          "JobAddress2": "2nd Floor",
          "JobFullAddress": "3700 RIVERTOWN PKWY SW 2nd Floor",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$198.74",
          "BranchNumber": 5,
          "SubmitDate": "01-20-2021",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 4095989,
          "ParentWorkOrder": "",
          "JobName": "(NOF)H&M",
          "JobNumber": "502-1060142-39",
          "FirstdayontheJob": "01-19-2021",
          "LastdayontheJob": "01-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3700 RIVERTOWN PKWY SW",
          "JobAddress2": "",
          "JobFullAddress": "3700 RIVERTOWN PKWY SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$610.56",
          "BranchNumber": 5,
          "SubmitDate": "01-20-2021",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 4095987,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FERGUSON",
          "JobNumber": "502-1542854-2",
          "FirstdayontheJob": "01-19-2021",
          "LastdayontheJob": "01-19-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "55500 GRAND RIVER AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "55500 GRAND RIVER AVENUE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MCNELLY CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,941.77",
          "BranchNumber": 1,
          "SubmitDate": "01-20-2021",
          "ClientonJob": "MCNELLY CONSTRUCTION"
        },
        {
          "WorkOrder": 4094050,
          "ParentWorkOrder": "",
          "JobName": "BROOKSIDE LAB",
          "JobNumber": "65-137581-5",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "200 WHITE MOUNTAIN DR",
          "JobAddress2": "",
          "JobFullAddress": "200 WHITE MOUNTAIN DR",
          "JobCounty": "AUGLAIZE",
          "JobState": "Ohio",
          "CustomerCompanyName": "LIS CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$13,143.15",
          "BranchNumber": 13,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "LIS CONSTRUCTION"
        },
        {
          "WorkOrder": 4094049,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OHIO AIR NAT. GUARD",
          "JobNumber": "65-135125-2",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 W Blee Rd",
          "JobAddress2": "",
          "JobFullAddress": "1500 W Blee Rd",
          "JobCounty": "CLARK",
          "JobState": "Ohio",
          "CustomerCompanyName": "DRIVE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,111.95",
          "BranchNumber": 13,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "DRIVE CONSTRUCTION"
        },
        {
          "WorkOrder": 4094048,
          "ParentWorkOrder": "",
          "JobName": "EMERY CENTER APARTMENTS",
          "JobNumber": "65-2135198-6",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 E Central Pkwy",
          "JobAddress2": "",
          "JobFullAddress": "100 E Central Pkwy",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "GREEN VALLEY CONTRACTORS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$4,060.07",
          "BranchNumber": 12,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "GREEN VALLEY CONTRACTORS"
        },
        {
          "WorkOrder": 4094046,
          "ParentWorkOrder": "",
          "JobName": "(NOF) POPEYES CHICKEN",
          "JobNumber": "65-421701-27",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5150 Tuttle Crossing Blvd",
          "JobAddress2": "",
          "JobFullAddress": "5150 Tuttle Crossing Blvd",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "W P CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$4,397.17",
          "BranchNumber": 4,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "W P CONSTRUCTION"
        },
        {
          "WorkOrder": 4094045,
          "ParentWorkOrder": "",
          "JobName": "KINGSTON PUBLIC LIBRARY",
          "JobNumber": "65-416900-9",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29 N MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "29 N MAIN ST",
          "JobCounty": "ROSS",
          "JobState": "Ohio",
          "CustomerCompanyName": "ProCon Professional Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,556.08",
          "BranchNumber": 4,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "ProCon Professional Construction"
        },
        {
          "WorkOrder": 4094044,
          "ParentWorkOrder": "",
          "JobName": "2291 W 4th St",
          "JobNumber": "65-400546-2",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2291 W FOURTH ST",
          "JobAddress2": "",
          "JobFullAddress": "2291 W FOURTH ST",
          "JobCounty": "RICHLAND",
          "JobState": "Ohio",
          "CustomerCompanyName": "ADENA CORPORATION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,224.37",
          "BranchNumber": 4,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "ADENA CORPORATION"
        },
        {
          "WorkOrder": 4094043,
          "ParentWorkOrder": "",
          "JobName": "DOLLAR TREE",
          "JobNumber": "65-551119-10",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "792 NW Washington Blvd",
          "JobAddress2": "",
          "JobFullAddress": "792 NW Washington Blvd",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "RELIANCE  CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$16,031.61",
          "BranchNumber": 2,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "RELIANCE  CONSTRUCTION"
        },
        {
          "WorkOrder": 4094042,
          "ParentWorkOrder": "",
          "JobName": "PIG CANDY",
          "JobNumber": "65-504193-47",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5901 Kellogg Ave",
          "JobAddress2": "",
          "JobFullAddress": "5901 Kellogg Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "GPT ENTERPRISE INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$2,019.59",
          "BranchNumber": 2,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "GPT ENTERPRISE INC"
        },
        {
          "WorkOrder": 4094041,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 4860 Fish Rd",
          "JobNumber": "502-1509095-36",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4860 Fish Rd",
          "JobAddress2": "",
          "JobFullAddress": "4860 Fish Rd",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "KOREY EAGEN",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$5,839.02",
          "BranchNumber": 11,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "KOREY EAGEN"
        },
        {
          "WorkOrder": 4094040,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Labelle Development",
          "JobNumber": "502-858171-130",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32212 N River Rd",
          "JobAddress2": "",
          "JobFullAddress": "32212 N River Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DGS DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$7,828.90",
          "BranchNumber": 11,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "DGS DRYWALL LLC"
        },
        {
          "WorkOrder": 4094039,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HMOR-30060 33 MILE",
          "JobNumber": "502-858171-129",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30060 33 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "30060 33 MILE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DGS DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$5,058.35",
          "BranchNumber": 11,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "DGS DRYWALL LLC"
        },
        {
          "WorkOrder": 4094038,
          "ParentWorkOrder": "",
          "JobName": "(NOF) X-GOLF CANTON",
          "JobNumber": "502-1621630-5",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44524 FORD RD",
          "JobAddress2": "",
          "JobFullAddress": "44524 FORD RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "RUGER CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,706.75",
          "BranchNumber": 9,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "RUGER CONSTRUCTION"
        },
        {
          "WorkOrder": 4094037,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CCCU ROSEVILLE",
          "JobNumber": "502-1507798-57",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20778 13 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "20778 13 MILE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$38,006.00",
          "BalanceRemaining": "$996.23",
          "BranchNumber": 9,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4094036,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CULVERS CLARE",
          "JobNumber": "502-916315-200",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10359 S Clare Ave",
          "JobAddress2": "",
          "JobFullAddress": "10359 S Clare Ave",
          "JobCounty": "CLARE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$223.36",
          "BranchNumber": 9,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4094035,
          "ParentWorkOrder": "",
          "JobName": "(NOF)QUANTUM DYNAMIC MNFT",
          "JobNumber": "502-1639418-33",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2990 LAPEER RD",
          "JobAddress2": "",
          "JobFullAddress": "2990 LAPEER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$686.36",
          "BranchNumber": 8,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4094034,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KENT & JOI GARABALDA",
          "JobNumber": "502-1604171-29",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "217 DOURADAN",
          "JobAddress2": "",
          "JobFullAddress": "217 DOURADAN",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,386.02",
          "BranchNumber": 8,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4094033,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dr. Atallah",
          "JobNumber": "502-875466-9",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "134 W University Dr",
          "JobAddress2": "",
          "JobFullAddress": "134 W University Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Facilities Management Gro",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$9,200.55",
          "BranchNumber": 8,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "Facilities Management Gro"
        },
        {
          "WorkOrder": 4094032,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MEIJER #27",
          "JobNumber": "502-23110-38",
          "FirstdayontheJob": "01-12-2021",
          "LastdayontheJob": "01-12-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3825 CAPENTER RD",
          "JobAddress2": "",
          "JobFullAddress": "3825 CAPENTER RD",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "SIWEK CONSTRUCTION CO.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$11,265.68",
          "BranchNumber": 1,
          "SubmitDate": "01-13-2021",
          "ClientonJob": "SIWEK CONSTRUCTION CO."
        },
        {
          "WorkOrder": 4093441,
          "ParentWorkOrder": 4083295,
          "JobName": "(NOF)FORD FHUB",
          "JobNumber": "502-812350-99",
          "FirstdayontheJob": "12-01-2020",
          "LastdayontheJob": "12-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20901 OAKWOOD BLVD",
          "JobAddress2": "",
          "JobFullAddress": "20901 OAKWOOD BLVD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$4,329.46",
          "BranchNumber": 8,
          "SubmitDate": "01-11-2021",
          "ClientonJob": "BARTON MALOW COMPANY"
        },
        {
          "WorkOrder": 4092064,
          "ParentWorkOrder": 4083960,
          "JobName": "WILLKOMMEN PROJECT",
          "JobNumber": "65-124263-2",
          "FirstdayontheJob": "12-02-2020",
          "LastdayontheJob": "12-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1611 RACE STREET",
          "JobAddress2": "",
          "JobFullAddress": "1611 RACE STREET",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "WELLS & DAUGHTERS DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,000.00",
          "BalanceRemaining": "$9,321.28",
          "BranchNumber": 12,
          "SubmitDate": "01-06-2021",
          "ClientonJob": "WELLS & DAUGHTERS DRYWALL"
        },
        {
          "WorkOrder": 4092062,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Bopra residence",
          "JobNumber": "502-877250-166",
          "FirstdayontheJob": "01-05-2021",
          "LastdayontheJob": "01-05-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30 Capitol Dr",
          "JobAddress2": "",
          "JobFullAddress": "30 Capitol Dr",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Cons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$5,704.73",
          "BranchNumber": 11,
          "SubmitDate": "01-06-2021",
          "ClientonJob": "Blue Water Drywall & Cons"
        },
        {
          "WorkOrder": 4092061,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PANDA EXP TOLDDO OH",
          "JobNumber": "502-858231-58",
          "FirstdayontheJob": "01-05-2021",
          "LastdayontheJob": "01-05-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5080 Monroe St",
          "JobAddress2": "",
          "JobFullAddress": "5080 Monroe St",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,386.81",
          "BranchNumber": 11,
          "SubmitDate": "01-06-2021",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 4092060,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Air National Base",
          "JobNumber": "502-919910-5",
          "FirstdayontheJob": "01-05-2021",
          "LastdayontheJob": "01-05-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3367 W DICKMAN RD",
          "JobAddress2": "",
          "JobFullAddress": "3367 W DICKMAN RD",
          "JobCounty": "CALHOUN",
          "JobState": "Michigan",
          "CustomerCompanyName": "Nora Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "01-06-2021",
          "ClientonJob": "Nora Contracting"
        },
        {
          "WorkOrder": 4092059,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Black River Vet",
          "JobNumber": "502-916315-195",
          "FirstdayontheJob": "01-05-2021",
          "LastdayontheJob": "01-05-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2820 Stable Dr",
          "JobAddress2": "",
          "JobFullAddress": "2820 Stable Dr",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$5,149.08",
          "BranchNumber": 9,
          "SubmitDate": "01-06-2021",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4092058,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RESEARCH PARK DRIVE",
          "JobNumber": "502-1632825-9",
          "FirstdayontheJob": "01-05-2021",
          "LastdayontheJob": "01-05-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3860 RESEARCH PARK DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "3860 RESEARCH PARK DRIVE",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR CONST & DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,703.21",
          "BranchNumber": 8,
          "SubmitDate": "01-06-2021",
          "ClientonJob": "SUPERIOR CONST & DRYWALL LLC"
        },
        {
          "WorkOrder": 4092057,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Prefix 3500",
          "JobNumber": "502-1526605-2",
          "FirstdayontheJob": "01-05-2021",
          "LastdayontheJob": "01-05-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3500 JOSLYN RD",
          "JobAddress2": "",
          "JobFullAddress": "3500 JOSLYN RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "K&B CONTRACTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "01-06-2021",
          "ClientonJob": "K&B CONTRACTING LLC"
        },
        {
          "WorkOrder": 4092056,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GUN LAKE CASINO",
          "JobNumber": "502-920940-98",
          "FirstdayontheJob": "01-05-2021",
          "LastdayontheJob": "01-05-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1123 129TH AVE.",
          "JobAddress2": "",
          "JobFullAddress": "1123 129TH AVE.",
          "JobCounty": "ALLEGAN",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$22,298.16",
          "BranchNumber": 8,
          "SubmitDate": "01-06-2021",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4092055,
          "ParentWorkOrder": "",
          "JobName": "(NOF)VISTAR",
          "JobNumber": "502-823400-56",
          "FirstdayontheJob": "01-05-2021",
          "LastdayontheJob": "01-05-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33000 SMITH RD",
          "JobAddress2": "",
          "JobFullAddress": "33000 SMITH RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PARALLEL CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$55,006.00",
          "BalanceRemaining": "$12,176.56",
          "BranchNumber": 8,
          "SubmitDate": "01-06-2021",
          "ClientonJob": "PARALLEL CONSTRUCTION"
        },
        {
          "WorkOrder": 4092054,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HFHS MACOMB FORCE CT",
          "JobNumber": "502-815250-384",
          "FirstdayontheJob": "01-05-2021",
          "LastdayontheJob": "01-05-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15855 19 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "15855 19 MILE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$182.81",
          "BranchNumber": 8,
          "SubmitDate": "01-06-2021",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4092053,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MEMORIALPRESBYTERIAN",
          "JobNumber": "502-14239-5",
          "FirstdayontheJob": "01-05-2021",
          "LastdayontheJob": "01-05-2021",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1310 ASHMAN ST",
          "JobAddress2": "",
          "JobFullAddress": "1310 ASHMAN ST",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Sugar Construction Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$18,006.00",
          "BalanceRemaining": "$16,854.00",
          "BranchNumber": 1,
          "SubmitDate": "01-06-2021",
          "ClientonJob": "Sugar Construction Inc."
        },
        {
          "WorkOrder": 4090534,
          "ParentWorkOrder": "",
          "JobName": "GDAHA",
          "JobNumber": "65-421030-6",
          "FirstdayontheJob": "12-29-2020",
          "LastdayontheJob": "12-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "124 E THIRD ST SUITE 400",
          "JobAddress2": "",
          "JobFullAddress": "124 E THIRD ST SUITE 400",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Windsor Construct Svc LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$131.15",
          "BranchNumber": 4,
          "SubmitDate": "12-30-2020",
          "ClientonJob": "Windsor Construct Svc LLC"
        },
        {
          "WorkOrder": 4090506,
          "ParentWorkOrder": 4064541,
          "JobName": "(NOF) TACO BELL RICHMOND",
          "JobNumber": "502-920300-99",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "67556 S MAIN STREET",
          "JobAddress2": "",
          "JobFullAddress": "67556 S MAIN STREET",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "GEORGE H PASTOR & SONS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "12-30-2020",
          "ClientonJob": "GEORGE H PASTOR & SONS"
        },
        {
          "WorkOrder": 4090505,
          "ParentWorkOrder": 4078372,
          "JobName": "(NOF) TACO BELL ASHLAND",
          "JobNumber": "502-920300-105",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "715 STATE ROUTE 250",
          "JobAddress2": "",
          "JobFullAddress": "715 STATE ROUTE 250",
          "JobCounty": "ASHLAND",
          "JobState": "Ohio",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$8,268.58",
          "BranchNumber": 9,
          "SubmitDate": "12-30-2020",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 4090458,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dollar Tree Whitmore",
          "JobNumber": "502-876752-22",
          "FirstdayontheJob": "12-29-2020",
          "LastdayontheJob": "12-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11900 Whitmore Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "11900 Whitmore Lake Rd",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$4,400.49",
          "BranchNumber": 8,
          "SubmitDate": "12-30-2020",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 4090457,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TROPICAL SMOOTHIE",
          "JobNumber": "502-876650-384",
          "FirstdayontheJob": "12-29-2020",
          "LastdayontheJob": "12-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2417 Gratiot Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2417 Gratiot Blvd",
          "JobCounty": "St Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,289.02",
          "BranchNumber": 8,
          "SubmitDate": "12-30-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4090456,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Public Service CU",
          "JobNumber": "502-823300-82",
          "FirstdayontheJob": "12-29-2020",
          "LastdayontheJob": "12-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3555 West Rd",
          "JobAddress2": "",
          "JobFullAddress": "3555 West Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "12-30-2020",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 4090455,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Jing Jin",
          "JobNumber": "502-820500-45",
          "FirstdayontheJob": "12-29-2020",
          "LastdayontheJob": "12-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34700 Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "34700 Grand River Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEE CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$26,208.35",
          "BranchNumber": 8,
          "SubmitDate": "12-30-2020",
          "ClientonJob": "LEE CONTRACTING"
        },
        {
          "WorkOrder": 4090454,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Pace Admin. Project",
          "JobNumber": "502-815630-245",
          "FirstdayontheJob": "12-29-2020",
          "LastdayontheJob": "12-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24463 W 10 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "24463 W 10 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D A CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "12-30-2020",
          "ClientonJob": "D A CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4090453,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LMCU",
          "JobNumber": "502-814500-84",
          "FirstdayontheJob": "12-29-2020",
          "LastdayontheJob": "12-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "310 W Tienken Rd",
          "JobAddress2": "",
          "JobFullAddress": "310 W Tienken Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "CIC Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$21,867.41",
          "BranchNumber": 8,
          "SubmitDate": "12-30-2020",
          "ClientonJob": "CIC Contracting"
        },
        {
          "WorkOrder": 4089314,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Harding BB",
          "JobNumber": "502-1305848-3",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2755 Harding Hwy",
          "JobAddress2": "",
          "JobFullAddress": "2755 Harding Hwy",
          "JobCounty": "ALLEN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Miller Contracting Group",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$9,894.26",
          "BranchNumber": 13,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "Miller Contracting Group"
        },
        {
          "WorkOrder": 4089313,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Fremont Retail",
          "JobNumber": "502-1304186-72",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "851 Sean Drive",
          "JobAddress2": "",
          "JobFullAddress": "851 Sean Drive",
          "JobCounty": "SANDUSKY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$13,236.77",
          "BranchNumber": 13,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4089312,
          "ParentWorkOrder": "",
          "JobName": "(NOF)City of Decatur Wate",
          "JobNumber": "502-1304099-12",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1815 Winchester Street",
          "JobAddress2": "",
          "JobFullAddress": "1815 Winchester Street",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "J & B ACOUSTICAL",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "On Hold",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "J & B ACOUSTICAL"
        },
        {
          "WorkOrder": 4089311,
          "ParentWorkOrder": "",
          "JobName": "MARRIOTT HOTEL",
          "JobNumber": "65-45820-2",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2901 AIRPORT DR",
          "JobAddress2": "",
          "JobFullAddress": "2901 AIRPORT DR",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "LC CONTRACT LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$5,532.93",
          "BranchNumber": 9,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "LC CONTRACT LLC"
        },
        {
          "WorkOrder": 4089310,
          "ParentWorkOrder": "",
          "JobName": "RADIO RD APTS",
          "JobNumber": "65-421030-5",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1750 Radio Rd",
          "JobAddress2": "",
          "JobFullAddress": "1750 Radio Rd",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Windsor Construct Svc LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$326.80",
          "BranchNumber": 4,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "Windsor Construct Svc LLC"
        },
        {
          "WorkOrder": 4089309,
          "ParentWorkOrder": "",
          "JobName": "TJ MAX Voice of america",
          "JobNumber": "65-511551-4",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7572 VOICE OF AMERICA CENTRE DR",
          "JobAddress2": "",
          "JobFullAddress": "7572 VOICE OF AMERICA CENTRE DR",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "SALVADOR YANEZ",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$1,528.60",
          "BranchNumber": 2,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "SALVADOR YANEZ"
        },
        {
          "WorkOrder": 4089308,
          "ParentWorkOrder": "",
          "JobName": "UC DENTAL CENTER",
          "JobNumber": "65-510590-46",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3231 HARVEY AVE",
          "JobAddress2": "",
          "JobFullAddress": "3231 HARVEY AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$9,411.43",
          "BranchNumber": 2,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "TRIVERSITY CRAFT FORCE LLC"
        },
        {
          "WorkOrder": 4089294,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DPS ALLEN PARK",
          "JobNumber": "502-1507798-56",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24000 OUTER DR",
          "JobAddress2": "",
          "JobFullAddress": "24000 OUTER DR",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$911.75",
          "BranchNumber": 9,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4089293,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PITNEY BOWES BDG II",
          "JobNumber": "502-1507798-55",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "37350 ECORSE RD",
          "JobAddress2": "",
          "JobFullAddress": "37350 ECORSE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$85,006.00",
          "BalanceRemaining": "$18,227.09",
          "BranchNumber": 9,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4089292,
          "ParentWorkOrder": "",
          "JobName": "(NOF) UT-OMPT",
          "JobNumber": "502-1507798-54",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 W UNIVERSITY DR",
          "JobAddress2": "",
          "JobFullAddress": "1000 W UNIVERSITY DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$449.16",
          "BranchNumber": 9,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4089291,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DUNHILL ESTATES",
          "JobNumber": "502-1325000-40",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20825 Dunhill Dr",
          "JobAddress2": "",
          "JobFullAddress": "20825 Dunhill Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$7,037.53",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4089290,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TROPICAL SMOOTH",
          "JobNumber": "502-949575-128",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2260 S MILFORD RD",
          "JobAddress2": "",
          "JobFullAddress": "2260 S MILFORD RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$1,427.24",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4089289,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hazel Park Bldg.",
          "JobNumber": "502-932775-70",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21323 John R Rd",
          "JobAddress2": "",
          "JobFullAddress": "21323 John R Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TLA INTERIORS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$5,210.11",
          "BranchNumber": 9,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "TLA INTERIORS, INC."
        },
        {
          "WorkOrder": 4089288,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDONALDS MONTPIELIE",
          "JobNumber": "502-916315-196",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14271 GRATIOT AVE",
          "JobAddress2": "",
          "JobFullAddress": "14271 GRATIOT AVE",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commercial Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,268.28",
          "BranchNumber": 9,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "Independence Commercial Construction"
        },
        {
          "WorkOrder": 4089287,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MEIJER MARYSVILLE",
          "JobNumber": "502-916315-194",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "205 S Range Rd",
          "JobAddress2": "",
          "JobFullAddress": "205 S Range Rd",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$957.60",
          "BranchNumber": 9,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4089286,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DAIRY QUEEN",
          "JobNumber": "502-910621-50",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8115 TELEGRAPH RD",
          "JobAddress2": "",
          "JobFullAddress": "8115 TELEGRAPH RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$2,761.97",
          "BranchNumber": 9,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 4089284,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Regency Club Apt #20",
          "JobNumber": "502-8997117-27",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26026 Regency Club Circle DR",
          "JobAddress2": "",
          "JobFullAddress": "26026 Regency Club Circle DR",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "FRONTGATE VENTURES LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$55,006.00",
          "BalanceRemaining": "$30,537.59",
          "BranchNumber": 8,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "FRONTGATE VENTURES LLC"
        },
        {
          "WorkOrder": 4089283,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Holistic Industries",
          "JobNumber": "502-1639418-32",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29600 Stephenson Hwy",
          "JobAddress2": "",
          "JobFullAddress": "29600 Stephenson Hwy",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$165.57",
          "BranchNumber": 8,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4089282,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BROWNSTOWN REDWOOD",
          "JobNumber": "502-1621700-11",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18708 TELEGRAPH RD",
          "JobAddress2": "",
          "JobFullAddress": "18708 TELEGRAPH RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHINGLETON CONTRACTORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$170,000.00",
          "BalanceRemaining": "$5,500.50",
          "BranchNumber": 8,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "SHINGLETON CONTRACTORS INC"
        },
        {
          "WorkOrder": 4089281,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Beaumont Chesterfiel",
          "JobNumber": "502-815750-302",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50964 Gratiot Ave",
          "JobAddress2": "",
          "JobFullAddress": "50964 Gratiot Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$790.99",
          "BranchNumber": 8,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC."
        },
        {
          "WorkOrder": 4089280,
          "ParentWorkOrder": "",
          "JobName": "(NOF)VILLAGE CLUB",
          "JobNumber": "502-813200-145",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "64 NORTH SAGINAW ST",
          "JobAddress2": "",
          "JobFullAddress": "64 NORTH SAGINAW ST",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$459.98",
          "BranchNumber": 8,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4089279,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Romeo Middle School",
          "JobNumber": "502-94091-25",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "297 Prospect St.",
          "JobAddress2": "",
          "JobFullAddress": "297 Prospect St.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "EJ Kaneris Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$11,339.43",
          "BranchNumber": 8,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "EJ Kaneris Inc"
        },
        {
          "WorkOrder": 4089278,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ST.JOHNS LUTHERAN",
          "JobNumber": "502-20010-38",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16339 E 14 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "16339 E 14 Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,506.00",
          "BalanceRemaining": "$3,364.68",
          "BranchNumber": 8,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4089277,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DOLLAR TREE",
          "JobNumber": "502-1607676-2",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1232 LINCOLN RD",
          "JobAddress2": "",
          "JobFullAddress": "1232 LINCOLN RD",
          "JobCounty": "ALLEGAN",
          "JobState": "Michigan",
          "CustomerCompanyName": "VIPER WALL SYSTEMS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$16,025.98",
          "BranchNumber": 5,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "VIPER WALL SYSTEMS"
        },
        {
          "WorkOrder": 4089276,
          "ParentWorkOrder": "",
          "JobName": "(NOF)JERSEY MIKES MV OH",
          "JobNumber": "502-1602000-37",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1401 COSHOCTON AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "1401 COSHOCTON AVENUE",
          "JobCounty": "KNOX",
          "JobState": "Ohio",
          "CustomerCompanyName": "Great Lakes Bay Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$653.46",
          "BranchNumber": 1,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "Great Lakes Bay Construction"
        },
        {
          "WorkOrder": 4089275,
          "ParentWorkOrder": "",
          "JobName": "(NOF)JIMMYJOHNSFMUTH",
          "JobNumber": "502-148155-8",
          "FirstdayontheJob": "12-22-2020",
          "LastdayontheJob": "12-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "527 N MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "527 N MAIN ST",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "METRO GENERAL CONTRACTOR INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "12-23-2020",
          "ClientonJob": "METRO GENERAL CONTRACTOR INC"
        },
        {
          "WorkOrder": 4087726,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NOVELLA",
          "JobNumber": "502-1621630-4",
          "FirstdayontheJob": "12-16-2020",
          "LastdayontheJob": "12-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "426 NORTH MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "426 NORTH MAIN ST",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "RUGER CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$1,364.74",
          "BranchNumber": 9,
          "SubmitDate": "12-17-2020",
          "ClientonJob": "RUGER CONSTRUCTION"
        },
        {
          "WorkOrder": 4087725,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HICKORY WAY",
          "JobNumber": "502-1325000-42",
          "FirstdayontheJob": "12-16-2020",
          "LastdayontheJob": "12-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1120 S Maple Rd",
          "JobAddress2": "",
          "JobFullAddress": "1120 S Maple Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,968.64",
          "BranchNumber": 9,
          "SubmitDate": "12-17-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4087724,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BIRMINGHAM HOUSE",
          "JobNumber": "502-941500-48",
          "FirstdayontheJob": "12-16-2020",
          "LastdayontheJob": "12-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "400 S OLD WOODWARD AVE",
          "JobAddress2": "",
          "JobFullAddress": "400 S OLD WOODWARD AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "FREEMAN INTERIOR",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$7,440.94",
          "BranchNumber": 9,
          "SubmitDate": "12-17-2020",
          "ClientonJob": "FREEMAN INTERIOR"
        },
        {
          "WorkOrder": 4087723,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Kevadiya Foam Replac",
          "JobNumber": "502-1604171-28",
          "FirstdayontheJob": "12-16-2020",
          "LastdayontheJob": "12-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 N Saginaw St",
          "JobAddress2": "",
          "JobFullAddress": "100 N Saginaw St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "12-17-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4087722,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Residence 3600",
          "JobNumber": "502-860900-5",
          "FirstdayontheJob": "12-16-2020",
          "LastdayontheJob": "12-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3600 Trefoil Trl",
          "JobAddress2": "",
          "JobFullAddress": "3600 Trefoil Trl",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "H & S Contractors",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$6,390.08",
          "BranchNumber": 8,
          "SubmitDate": "12-17-2020",
          "ClientonJob": "H & S Contractors"
        },
        {
          "WorkOrder": 4087721,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Endura Coatings",
          "JobNumber": "502-843701-218",
          "FirstdayontheJob": "12-16-2020",
          "LastdayontheJob": "12-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "42250 Yearago Drive",
          "JobAddress2": "",
          "JobFullAddress": "42250 Yearago Drive",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,332.12",
          "BranchNumber": 8,
          "SubmitDate": "12-17-2020",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 4087719,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BETTER MADE",
          "JobNumber": "502-815250-382",
          "FirstdayontheJob": "12-16-2020",
          "LastdayontheJob": "12-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10148 GRATIOT AVE",
          "JobAddress2": "",
          "JobFullAddress": "10148 GRATIOT AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$12,018.72",
          "BranchNumber": 8,
          "SubmitDate": "12-17-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4087718,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3163 North Oak Healt",
          "JobNumber": "502-813200-146",
          "FirstdayontheJob": "12-16-2020",
          "LastdayontheJob": "12-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34 East Building",
          "JobAddress2": "1200 Telegraph Rd.",
          "JobFullAddress": "34 East Building 1200 Telegraph Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$107.98",
          "BranchNumber": 8,
          "SubmitDate": "12-17-2020",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4087717,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Richland Elementary",
          "JobNumber": "502-1344933-18",
          "FirstdayontheJob": "12-16-2020",
          "LastdayontheJob": "12-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9476 M-89",
          "JobAddress2": "",
          "JobFullAddress": "9476 M-89",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER 1 INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "12-17-2020",
          "ClientonJob": "PREMIER 1 INTERIORS LLC"
        },
        {
          "WorkOrder": 4087392,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BP / Barney's",
          "JobNumber": "502-1305825-31",
          "FirstdayontheJob": "12-15-2020",
          "LastdayontheJob": "12-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26475 Dixie Hwy",
          "JobAddress2": "",
          "JobFullAddress": "26475 Dixie Hwy",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "12-16-2020",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 4087391,
          "ParentWorkOrder": "",
          "JobName": "LINWOOD BUILDING",
          "JobNumber": "65-2135198-8",
          "FirstdayontheJob": "12-15-2020",
          "LastdayontheJob": "12-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3152 Linwood Ave",
          "JobAddress2": "",
          "JobFullAddress": "3152 Linwood Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "GREEN VALLEY CONTRACTORS,",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$1,696.82",
          "BranchNumber": 12,
          "SubmitDate": "12-16-2020",
          "ClientonJob": "GREEN VALLEY CONTRACTORS,"
        },
        {
          "WorkOrder": 4087390,
          "ParentWorkOrder": "",
          "JobName": "GRAEERS KENWOOD",
          "JobNumber": "65-2134669-9",
          "FirstdayontheJob": "12-15-2020",
          "LastdayontheJob": "12-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8167 MONTGOMERY RD",
          "JobAddress2": "",
          "JobFullAddress": "8167 MONTGOMERY RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "MCCOOL PLASTER AND DRYWAL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$124.15",
          "BranchNumber": 12,
          "SubmitDate": "12-16-2020",
          "ClientonJob": "MCCOOL PLASTER AND DRYWAL"
        },
        {
          "WorkOrder": 4087389,
          "ParentWorkOrder": "",
          "JobName": "GOODWILL BUCKEYE LAKE",
          "JobNumber": "65-422006-37",
          "FirstdayontheJob": "12-15-2020",
          "LastdayontheJob": "12-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10933 HEBRON RD",
          "JobAddress2": "",
          "JobFullAddress": "10933 HEBRON RD",
          "JobCounty": "LICKING",
          "JobState": "Ohio",
          "CustomerCompanyName": "WAYNE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$29,678.68",
          "BranchNumber": 4,
          "SubmitDate": "12-16-2020",
          "ClientonJob": "WAYNE CONSTRUCTION"
        },
        {
          "WorkOrder": 4087388,
          "ParentWorkOrder": "",
          "JobName": "RURAL KING",
          "JobNumber": "65-421045-4",
          "FirstdayontheJob": "12-15-2020",
          "LastdayontheJob": "12-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "61690 SOUTHGATE RD",
          "JobAddress2": "",
          "JobFullAddress": "61690 SOUTHGATE RD",
          "JobCounty": "GUERNSEY",
          "JobState": "Ohio",
          "CustomerCompanyName": "ALLTRADE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$25,030.43",
          "BranchNumber": 4,
          "SubmitDate": "12-16-2020",
          "ClientonJob": "ALLTRADE CONSTRUCTION"
        },
        {
          "WorkOrder": 4087387,
          "ParentWorkOrder": "",
          "JobName": "ARCADIA HOSPITAL",
          "JobNumber": "65-55219-13",
          "FirstdayontheJob": "12-15-2020",
          "LastdayontheJob": "12-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2446 Kipling Ave",
          "JobAddress2": "",
          "JobFullAddress": "2446 Kipling Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lemcer Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$9,986.86",
          "BranchNumber": 2,
          "SubmitDate": "12-16-2020",
          "ClientonJob": "Lemcer Construction LLC"
        },
        {
          "WorkOrder": 4086786,
          "ParentWorkOrder": 4070623,
          "JobName": "(NOF) SCIO RETAIL",
          "JobNumber": "502-876650-373",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5609 JACKSON RD",
          "JobAddress2": "",
          "JobFullAddress": "5609 JACKSON RD",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,605.09",
          "BranchNumber": 8,
          "SubmitDate": "12-14-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4085933,
          "ParentWorkOrder": 4078367,
          "JobName": "(NOF)Central Campus ProII",
          "JobNumber": "502-876650-376",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32 Market Street",
          "JobAddress2": "",
          "JobFullAddress": "32 Market Street",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$7,664.89",
          "BranchNumber": 8,
          "SubmitDate": "12-10-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4085851,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CROWN OF LIFE",
          "JobNumber": "502-861787-42",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32725 RYAN RD",
          "JobAddress2": "",
          "JobFullAddress": "32725 RYAN RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$55,006.00",
          "BalanceRemaining": "$467.25",
          "BranchNumber": 11,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "JFS Company"
        },
        {
          "WorkOrder": 4085850,
          "ParentWorkOrder": "",
          "JobName": "(NOF)EAGLE RIDGE LOT 57",
          "JobNumber": "502-18879-62",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5403 MORGAN LAKE DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "5403 MORGAN LAKE DRIVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SAVYER DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,906.00",
          "BalanceRemaining": "$5,598.35",
          "BranchNumber": 11,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "SAVYER DRYWALL LLC"
        },
        {
          "WorkOrder": 4085849,
          "ParentWorkOrder": "",
          "JobName": "(NOF)EAGLE RIDGE LOT 25",
          "JobNumber": "502-18879-60",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5436 MORGAN LAKE DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "5436 MORGAN LAKE DRIVE",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SAVYER DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$5,812.64",
          "BranchNumber": 11,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "SAVYER DRYWALL LLC"
        },
        {
          "WorkOrder": 4085848,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Vibe Berkley",
          "JobNumber": "502-1507798-52",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3082 Coolidge Hwy",
          "JobAddress2": "",
          "JobFullAddress": "3082 Coolidge Hwy",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$287.11",
          "BranchNumber": 9,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4085847,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ROTC FLINT",
          "JobNumber": "502-981570-17",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5081 TORREY RD",
          "JobAddress2": "",
          "JobFullAddress": "5081 TORREY RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DTS Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,506.00",
          "BalanceRemaining": "$8,645.36",
          "BranchNumber": 9,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "DTS Contracting"
        },
        {
          "WorkOrder": 4085846,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TACO BELL",
          "JobNumber": "502-920300-75",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19000 NORTHLINE RD",
          "JobAddress2": "",
          "JobFullAddress": "19000 NORTHLINE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "GEORGE PASTORS & SONS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$93.45",
          "BranchNumber": 9,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "GEORGE PASTORS & SONS"
        },
        {
          "WorkOrder": 4085845,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PET SUITES NOVI",
          "JobNumber": "502-1639418-31",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "47025 GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "47025 GRAND RIVER AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,933.69",
          "BranchNumber": 8,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4085844,
          "ParentWorkOrder": "",
          "JobName": "(NOF) UFCU FRANKENLUST",
          "JobNumber": "502-1604171-27",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6354 Westside Saginaw Rd",
          "JobAddress2": "",
          "JobFullAddress": "6354 Westside Saginaw Rd",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,889.07",
          "BranchNumber": 8,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4085843,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Aspen Dental St. Hts",
          "JobNumber": "502-1603839-2",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35372 Van Dyke Ave",
          "JobAddress2": "",
          "JobFullAddress": "35372 Van Dyke Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "BHL SERVICES INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "BHL SERVICES INC"
        },
        {
          "WorkOrder": 4085842,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HTH COMMUNICATIONS",
          "JobNumber": "502-843701-221",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15802 LEONE DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "15802 LEONE DRIVE",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$3,961.01",
          "BranchNumber": 8,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 4085841,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Allison Transmission",
          "JobNumber": "502-820500-43",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4120 Luella Lane",
          "JobAddress2": "",
          "JobFullAddress": "4120 Luella Lane",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEE CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,000.00",
          "BalanceRemaining": "$18,567.56",
          "BranchNumber": 8,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "LEE CONTRACTING"
        },
        {
          "WorkOrder": 4085840,
          "ParentWorkOrder": "",
          "JobName": "(NOF)COURTYARD BY MARRIOT",
          "JobNumber": "502-990401-69",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "46000 UTICA PARK BLVD",
          "JobAddress2": "",
          "JobFullAddress": "46000 UTICA PARK BLVD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$16,741.15",
          "BranchNumber": 7,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4085838,
          "ParentWorkOrder": "",
          "JobName": "(NOF)COMERICA ROYAL OAK",
          "JobNumber": "502-1162526-3",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "323 S MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "323 S MAIN ST",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ALAN JAMES CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$11,006.00",
          "BalanceRemaining": "$94.76",
          "BranchNumber": 1,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "ALAN JAMES CONSTRUCTION"
        },
        {
          "WorkOrder": 4085837,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LIBERTY SQUARE",
          "JobNumber": "502-1609034-56",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "909 MADISON AVE",
          "JobAddress2": "",
          "JobFullAddress": "909 MADISON AVE",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,645.21",
          "BranchNumber": 13,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4085836,
          "ParentWorkOrder": "",
          "JobName": "Kroger Dublin",
          "JobNumber": "65-473759-13",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7625 SAWMILL RD",
          "JobAddress2": "",
          "JobFullAddress": "7625 SAWMILL RD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "R&W Fixturing Co Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$8,394.30",
          "BranchNumber": 4,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "R&W Fixturing Co Inc"
        },
        {
          "WorkOrder": 4085835,
          "ParentWorkOrder": "",
          "JobName": "DEEPER ROOTS",
          "JobNumber": "65-504193-46",
          "FirstdayontheJob": "12-08-2020",
          "LastdayontheJob": "12-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "338 W 4TH ST",
          "JobAddress2": "",
          "JobFullAddress": "338 W 4TH ST",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "GPT ENTERPRISE INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$1,646.70",
          "BranchNumber": 2,
          "SubmitDate": "12-09-2020",
          "ClientonJob": "GPT ENTERPRISE INC"
        },
        {
          "WorkOrder": 4085307,
          "ParentWorkOrder": 4057340,
          "JobName": "(NOF)LOCS Webber Elem.",
          "JobNumber": "502-920940-89",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3191 W CLARKSTON RD",
          "JobAddress2": "",
          "JobFullAddress": "3191 W CLARKSTON RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$5,825.18",
          "BranchNumber": 8,
          "SubmitDate": "12-08-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4083962,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Circle K #5650",
          "JobNumber": "502-1304186-70",
          "FirstdayontheJob": "12-02-2020",
          "LastdayontheJob": "12-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "975 West Market St",
          "JobAddress2": "",
          "JobFullAddress": "975 West Market St",
          "JobCounty": "SENECA",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,402.42",
          "BranchNumber": 13,
          "SubmitDate": "12-03-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4083961,
          "ParentWorkOrder": "",
          "JobName": "BUCKEYE CENTRAL SCHOOL",
          "JobNumber": "65-2135185-5",
          "FirstdayontheJob": "12-02-2020",
          "LastdayontheJob": "12-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "938 S KIBLER ST",
          "JobAddress2": "",
          "JobFullAddress": "938 S KIBLER ST",
          "JobCounty": "CRAWFORD",
          "JobState": "Ohio",
          "CustomerCompanyName": "HALKER DRYWALL, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$7,946.40",
          "BranchNumber": 12,
          "SubmitDate": "12-03-2020",
          "ClientonJob": "HALKER DRYWALL, INC."
        },
        {
          "WorkOrder": 4083960,
          "ParentWorkOrder": "",
          "JobName": "WILLKOMMEN PROJECT",
          "JobNumber": "65-124263-2",
          "FirstdayontheJob": "12-02-2020",
          "LastdayontheJob": "12-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1611 RACE STREET",
          "JobAddress2": "",
          "JobFullAddress": "1611 RACE STREET",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "WELLS & DAUGHTERS DRYWALL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,000.00",
          "BalanceRemaining": "$9,321.28",
          "BranchNumber": 12,
          "SubmitDate": "12-03-2020",
          "ClientonJob": "WELLS & DAUGHTERS DRYWALL"
        },
        {
          "WorkOrder": 4083959,
          "ParentWorkOrder": "",
          "JobName": "LA PINATA",
          "JobNumber": "65-510300-62",
          "FirstdayontheJob": "12-02-2020",
          "LastdayontheJob": "12-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3731 STONECREEK BLVD",
          "JobAddress2": "",
          "JobFullAddress": "3731 STONECREEK BLVD",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$7,845.74",
          "BranchNumber": 2,
          "SubmitDate": "12-03-2020",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4083958,
          "ParentWorkOrder": "",
          "JobName": "UHAUL",
          "JobNumber": "65-504193-45",
          "FirstdayontheJob": "12-02-2020",
          "LastdayontheJob": "12-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "751 CHAMBER DR",
          "JobAddress2": "",
          "JobFullAddress": "751 CHAMBER DR",
          "JobCounty": "CLERMONT",
          "JobState": "Ohio",
          "CustomerCompanyName": "GPT ENTERPRISE INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,403.31",
          "BranchNumber": 2,
          "SubmitDate": "12-03-2020",
          "ClientonJob": "GPT ENTERPRISE INC"
        },
        {
          "WorkOrder": 4083324,
          "ParentWorkOrder": "",
          "JobName": "NOF USF WAREHOUSE",
          "JobNumber": "502-1650650-17",
          "FirstdayontheJob": "12-01-2020",
          "LastdayontheJob": "12-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2210 BEARD ST",
          "JobAddress2": "",
          "JobFullAddress": "2210 BEARD ST",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDUSTRIAL TECH SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$1,103.20",
          "BranchNumber": 11,
          "SubmitDate": "12-02-2020",
          "ClientonJob": "INDUSTRIAL TECH SERVICES"
        },
        {
          "WorkOrder": 4083321,
          "ParentWorkOrder": "",
          "JobName": "(NOF) YANFENG",
          "JobNumber": "502-1650650-16",
          "FirstdayontheJob": "12-01-2020",
          "LastdayontheJob": "12-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "42150 EXECUTIVE DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "42150 EXECUTIVE DRIVE",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDUSTRIAL TECH SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,954.36",
          "BranchNumber": 11,
          "SubmitDate": "12-02-2020",
          "ClientonJob": "INDUSTRIAL TECH SERVICES"
        },
        {
          "WorkOrder": 4083317,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MODERN DRYWALL",
          "JobNumber": "502-847401-169",
          "FirstdayontheJob": "12-01-2020",
          "LastdayontheJob": "12-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6463 WEST RD",
          "JobAddress2": "",
          "JobFullAddress": "6463 WEST RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$6,051.41",
          "BranchNumber": 11,
          "SubmitDate": "12-02-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4083314,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MODERN DRYWALL",
          "JobNumber": "502-847401-168",
          "FirstdayontheJob": "12-01-2020",
          "LastdayontheJob": "12-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6 NORWICH RD",
          "JobAddress2": "",
          "JobFullAddress": "6 NORWICH RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,206.00",
          "BalanceRemaining": "$6,747.74",
          "BranchNumber": 11,
          "SubmitDate": "12-02-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4083311,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ECORSE COMMONS 1&2",
          "JobNumber": "502-847401-167",
          "FirstdayontheJob": "12-01-2020",
          "LastdayontheJob": "12-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "37350 ECORSE RD.",
          "JobAddress2": "",
          "JobFullAddress": "37350 ECORSE RD.",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$2,792.76",
          "BranchNumber": 11,
          "SubmitDate": "12-02-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4083308,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FIVE GUYS WHITE BOX",
          "JobNumber": "502-1507798-51",
          "FirstdayontheJob": "12-01-2020",
          "LastdayontheJob": "12-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1985 S TELEGRAPH RD",
          "JobAddress2": "",
          "JobFullAddress": "1985 S TELEGRAPH RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$7,699.81",
          "BranchNumber": 9,
          "SubmitDate": "12-02-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4083305,
          "ParentWorkOrder": "",
          "JobName": "(NOF)STEVENS RED MAPLE",
          "JobNumber": "502-1604171-26",
          "FirstdayontheJob": "12-01-2020",
          "LastdayontheJob": "12-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6545 RED MAPLE LANE",
          "JobAddress2": "",
          "JobFullAddress": "6545 RED MAPLE LANE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,727.46",
          "BranchNumber": 8,
          "SubmitDate": "12-02-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4083301,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DOLLAR TREE IMLAY",
          "JobNumber": "502-876752-21",
          "FirstdayontheJob": "12-01-2020",
          "LastdayontheJob": "12-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 4083298,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GDLS",
          "JobNumber": "502-817800-52",
          "FirstdayontheJob": "12-01-2020",
          "LastdayontheJob": "12-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "38500 Mound Rd",
          "JobAddress2": "",
          "JobFullAddress": "38500 Mound Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$2,091.70",
          "BranchNumber": 8,
          "SubmitDate": "12-02-2020",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4083295,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FORD FHUB",
          "JobNumber": "502-812350-99",
          "FirstdayontheJob": "12-01-2020",
          "LastdayontheJob": "12-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20901 OAKWOOD BLVD",
          "JobAddress2": "",
          "JobFullAddress": "20901 OAKWOOD BLVD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$4,329.46",
          "BranchNumber": 8,
          "SubmitDate": "12-02-2020",
          "ClientonJob": "BARTON MALOW COMPANY"
        },
        {
          "WorkOrder": 4082572,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PENROSE EASTERN WOOD",
          "JobNumber": "502-1609034-55",
          "FirstdayontheJob": "11-29-2020",
          "LastdayontheJob": "11-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15105 BIRCHAVEN LN",
          "JobAddress2": "",
          "JobFullAddress": "15105 BIRCHAVEN LN",
          "JobCounty": "HANCOCK",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$4,657.18",
          "BranchNumber": 13,
          "SubmitDate": "11-30-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4082571,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ST. MICHAELS CHURCH",
          "JobNumber": "502-1304186-69",
          "FirstdayontheJob": "11-29-2020",
          "LastdayontheJob": "11-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "219 CHAPEL STREET",
          "JobAddress2": "",
          "JobFullAddress": "219 CHAPEL STREET",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,436.12",
          "BranchNumber": 13,
          "SubmitDate": "11-30-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4082570,
          "ParentWorkOrder": "",
          "JobName": "THOMPKINS STUDIOS",
          "JobNumber": "65-42690-3",
          "FirstdayontheJob": "11-29-2020",
          "LastdayontheJob": "11-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2500 N HIGH ST",
          "JobAddress2": "",
          "JobFullAddress": "2500 N HIGH ST",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "VERGE DEVELOPMENTS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$6,664.31",
          "BranchNumber": 4,
          "SubmitDate": "11-30-2020",
          "ClientonJob": "VERGE DEVELOPMENTS LLC"
        },
        {
          "WorkOrder": 4082569,
          "ParentWorkOrder": "",
          "JobName": "SYCAMORE APARTMENTS",
          "JobNumber": "65-516400-5",
          "FirstdayontheJob": "11-29-2020",
          "LastdayontheJob": "11-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1118 SYCAMORE ST",
          "JobAddress2": "",
          "JobFullAddress": "1118 SYCAMORE ST",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "AL NEYER LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$6,468.00",
          "BranchNumber": 2,
          "SubmitDate": "11-30-2020",
          "ClientonJob": "AL NEYER LLC"
        },
        {
          "WorkOrder": 4081992,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MUSLIM FAMILY SERVIC",
          "JobNumber": "502-949575-127",
          "FirstdayontheJob": "11-24-2020",
          "LastdayontheJob": "11-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12500 MITCHELL ST",
          "JobAddress2": "",
          "JobFullAddress": "12500 MITCHELL ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$2,137.82",
          "BranchNumber": 9,
          "SubmitDate": "11-25-2020",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4081991,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Kalamazoo Redwood",
          "JobNumber": "502-1621700-10",
          "FirstdayontheJob": "11-24-2020",
          "LastdayontheJob": "11-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6711 West Q Ave",
          "JobAddress2": "",
          "JobFullAddress": "6711 West Q Ave",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHINGLETON CONTRACTORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$170,000.00",
          "BalanceRemaining": "$8,132.76",
          "BranchNumber": 8,
          "SubmitDate": "11-25-2020",
          "ClientonJob": "SHINGLETON CONTRACTORS INC"
        },
        {
          "WorkOrder": 4081990,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MILWAUKEE JUNCTION",
          "JobNumber": "502-1605903-6",
          "FirstdayontheJob": "11-24-2020",
          "LastdayontheJob": "11-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "258 E MILWAUKEE ST",
          "JobAddress2": "",
          "JobFullAddress": "258 E MILWAUKEE ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLOVER CONSTRUCTION SERVICES INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,000.00",
          "BalanceRemaining": "$1,416.71",
          "BranchNumber": 8,
          "SubmitDate": "11-25-2020",
          "ClientonJob": "CLOVER CONSTRUCTION SERVICES INC"
        },
        {
          "WorkOrder": 4081989,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RESIDENCE MEADOWLAKE",
          "JobNumber": "502-1605903-5",
          "FirstdayontheJob": "11-24-2020",
          "LastdayontheJob": "11-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6765 Meadowlake Rd",
          "JobAddress2": "",
          "JobFullAddress": "6765 Meadowlake Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLOVER CONSTRUCTION SERVICES INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,213.97",
          "BranchNumber": 8,
          "SubmitDate": "11-25-2020",
          "ClientonJob": "CLOVER CONSTRUCTION SERVICES INC"
        },
        {
          "WorkOrder": 4081988,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BOB'S FURNITURE",
          "JobNumber": "502-876650-382",
          "FirstdayontheJob": "11-24-2020",
          "LastdayontheJob": "11-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "280 BROWN RD.",
          "JobAddress2": "",
          "JobFullAddress": "280 BROWN RD.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$1,888.13",
          "BranchNumber": 8,
          "SubmitDate": "11-25-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4081987,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HERMES SOMERSET MALL",
          "JobNumber": "502-825063-57",
          "FirstdayontheJob": "11-24-2020",
          "LastdayontheJob": "11-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHAFFER INCORPORATED",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$10,978.48",
          "BranchNumber": 8,
          "SubmitDate": "11-25-2020",
          "ClientonJob": "SHAFFER INCORPORATED"
        },
        {
          "WorkOrder": 4081986,
          "ParentWorkOrder": "",
          "JobName": "(NOF) PACE NOVI",
          "JobNumber": "502-823400-55",
          "FirstdayontheJob": "11-24-2020",
          "LastdayontheJob": "11-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "28125 CABOT DR",
          "JobAddress2": "",
          "JobFullAddress": "28125 CABOT DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PARALLEL CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$4,385.27",
          "BranchNumber": 8,
          "SubmitDate": "11-25-2020",
          "ClientonJob": "PARALLEL CONSTRUCTION"
        },
        {
          "WorkOrder": 4081985,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HFHS WYANDOTTE HOSP",
          "JobNumber": "502-815750-300",
          "FirstdayontheJob": "11-24-2020",
          "LastdayontheJob": "11-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2333 BIDDLE AVE",
          "JobAddress2": "",
          "JobFullAddress": "2333 BIDDLE AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$464.70",
          "BranchNumber": 8,
          "SubmitDate": "11-25-2020",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS, INC."
        },
        {
          "WorkOrder": 4081984,
          "ParentWorkOrder": "",
          "JobName": "(NOF) OAK ST HEALTH",
          "JobNumber": "502-815400-29",
          "FirstdayontheJob": "11-24-2020",
          "LastdayontheJob": "11-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1663 S Westnedge Ave",
          "JobAddress2": "",
          "JobFullAddress": "1663 S Westnedge Ave",
          "JobCounty": "Kalamazoo",
          "JobState": "Michigan",
          "CustomerCompanyName": "DEVANGE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$14,995.16",
          "BranchNumber": 8,
          "SubmitDate": "11-25-2020",
          "ClientonJob": "DEVANGE CONSTRUCTION"
        },
        {
          "WorkOrder": 4081983,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FRANKENMUTH PT",
          "JobNumber": "502-14634-45",
          "FirstdayontheJob": "11-24-2020",
          "LastdayontheJob": "11-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "406 W GENESSEE ST",
          "JobAddress2": "",
          "JobFullAddress": "406 W GENESSEE ST",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Ramirez Quality Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$690.92",
          "BranchNumber": 1,
          "SubmitDate": "11-25-2020",
          "ClientonJob": "Ramirez Quality Drywall"
        },
        {
          "WorkOrder": 4080596,
          "ParentWorkOrder": 4076485,
          "JobName": "(NOF) W. Bloomfield M.S.",
          "JobNumber": "502-910050-59",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4925 Orchard lake Rd.",
          "JobAddress2": "",
          "JobFullAddress": "4925 Orchard lake Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONSTRUCTION CO INC",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$2,795.86",
          "BranchNumber": 9,
          "SubmitDate": "11-19-2020",
          "ClientonJob": "ANM CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4080595,
          "ParentWorkOrder": 4064032,
          "JobName": "(NOF) Oak Leaf",
          "JobNumber": "502-1609034-50",
          "FirstdayontheJob": "09-21-2020",
          "LastdayontheJob": "09-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4230 N Holland Sylvania Rd",
          "JobAddress2": "",
          "JobFullAddress": "4230 N Holland Sylvania Rd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$517.79",
          "BranchNumber": 13,
          "SubmitDate": "11-19-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4080186,
          "ParentWorkOrder": "",
          "JobName": "(NOF)APOSTOLIC CHRISTIAN",
          "JobNumber": "502-1609034-54",
          "FirstdayontheJob": "11-18-2020",
          "LastdayontheJob": "11-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3730 Herr Rd",
          "JobAddress2": "",
          "JobFullAddress": "3730 Herr Rd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$10,551.15",
          "BranchNumber": 13,
          "SubmitDate": "11-19-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4080185,
          "ParentWorkOrder": "",
          "JobName": "(NOF)T-MOBIL NOVI",
          "JobNumber": "502-134220-10",
          "FirstdayontheJob": "11-18-2020",
          "LastdayontheJob": "11-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26285 NOVI RD",
          "JobAddress2": "",
          "JobFullAddress": "26285 NOVI RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HORIZON RETAIL CONSTRUCT",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$170,000.00",
          "BalanceRemaining": "$222.41",
          "BranchNumber": 13,
          "SubmitDate": "11-19-2020",
          "ClientonJob": "HORIZON RETAIL CONSTRUCT"
        },
        {
          "WorkOrder": 4080184,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Preferred Bldg",
          "JobNumber": "502-859551-30",
          "FirstdayontheJob": "11-18-2020",
          "LastdayontheJob": "11-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5289 Jessica Lane",
          "JobAddress2": "",
          "JobFullAddress": "5289 Jessica Lane",
          "JobCounty": "St Clair Township",
          "JobState": "Michigan",
          "CustomerCompanyName": "PICKLEHAUPT DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$6,533.86",
          "BranchNumber": 11,
          "SubmitDate": "11-19-2020",
          "ClientonJob": "PICKLEHAUPT DRYWALL"
        },
        {
          "WorkOrder": 4080183,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WISD HIGH POINT",
          "JobNumber": "502-933550-197",
          "FirstdayontheJob": "11-18-2020",
          "LastdayontheJob": "11-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1735 S WAGNER RD",
          "JobAddress2": "",
          "JobFullAddress": "1735 S WAGNER RD",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$12,607.63",
          "BranchNumber": 9,
          "SubmitDate": "11-19-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4080182,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LIVONIA DPW   20016",
          "JobNumber": "502-933550-186",
          "FirstdayontheJob": "11-18-2020",
          "LastdayontheJob": "11-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12973 FARMINGTON RD",
          "JobAddress2": "",
          "JobFullAddress": "12973 FARMINGTON RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$16,816.91",
          "BranchNumber": 9,
          "SubmitDate": "11-19-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4080181,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DUNKIN DONUTS MAUMEE",
          "JobNumber": "502-916315-192",
          "FirstdayontheJob": "11-18-2020",
          "LastdayontheJob": "11-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1398 CONANT ST",
          "JobAddress2": "",
          "JobFullAddress": "1398 CONANT ST",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Independence Commercial Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$841.80",
          "BranchNumber": 9,
          "SubmitDate": "11-19-2020",
          "ClientonJob": "Independence Commercial Construction"
        },
        {
          "WorkOrder": 4080180,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WATER DAMAGE #1627",
          "JobNumber": "502-913820-590",
          "FirstdayontheJob": "11-18-2020",
          "LastdayontheJob": "11-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32100 Telegraph Rd",
          "JobAddress2": "",
          "JobFullAddress": "32100 Telegraph Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$3,104.14",
          "BranchNumber": 9,
          "SubmitDate": "11-19-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4080179,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GARDEN CITY HOSP",
          "JobNumber": "502-910900-10",
          "FirstdayontheJob": "11-18-2020",
          "LastdayontheJob": "11-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6245 INKSTER RD",
          "JobAddress2": "",
          "JobFullAddress": "6245 INKSTER RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANN ARBOR CEILING & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,606.00",
          "BalanceRemaining": "$6,733.53",
          "BranchNumber": 9,
          "SubmitDate": "11-19-2020",
          "ClientonJob": "ANN ARBOR CEILING & PART"
        },
        {
          "WorkOrder": 4080178,
          "ParentWorkOrder": "",
          "JobName": "NOF Our CreditUnionShelby",
          "JobNumber": "502-823300-81",
          "FirstdayontheJob": "11-18-2020",
          "LastdayontheJob": "11-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "54870 Mound Rd",
          "JobAddress2": "",
          "JobFullAddress": "54870 Mound Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$1,106.85",
          "BranchNumber": 8,
          "SubmitDate": "11-19-2020",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 4080177,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Bank of America Lans",
          "JobNumber": "502-815750-301",
          "FirstdayontheJob": "11-18-2020",
          "LastdayontheJob": "11-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "208 ALBERT AVE",
          "JobAddress2": "",
          "JobFullAddress": "208 ALBERT AVE",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCT ION SPECIALISTS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "11-19-2020",
          "ClientonJob": "DIVERSIFIED CONSTRUCT ION SPECIALISTS, INC."
        },
        {
          "WorkOrder": 4080176,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TRU HOTEL",
          "JobNumber": "502-812692-23",
          "FirstdayontheJob": "11-18-2020",
          "LastdayontheJob": "11-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1048 W MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "1048 W MAIN ST",
          "JobCounty": "Otsego",
          "JobState": "Michigan",
          "CustomerCompanyName": "HARBAUGH CONST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "11-19-2020",
          "ClientonJob": "HARBAUGH CONST"
        },
        {
          "WorkOrder": 4080174,
          "ParentWorkOrder": 4072424,
          "JobName": "(NOF)20-2052 SJP MRI SFLD",
          "JobNumber": "502-815250-380",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16001 W 9 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "16001 W 9 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "11-18-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4080173,
          "ParentWorkOrder": 4068493,
          "JobName": "FRANKLIN COUNTY DR",
          "JobNumber": "65-416900-8",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "373 S High St",
          "JobAddress2": "",
          "JobFullAddress": "373 S High St",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "ProCon PROFESSIONAL CONST",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$1,042.75",
          "BranchNumber": 4,
          "SubmitDate": "11-18-2020",
          "ClientonJob": "ProCon PROFESSIONAL CONST"
        },
        {
          "WorkOrder": 4078379,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ZF FOWELERVILLE",
          "JobNumber": "502-1650650-10",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "500 E Van Riper Rd",
          "JobAddress2": "",
          "JobFullAddress": "500 E Van Riper Rd",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDUSTRIAL TECH SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,987.85",
          "BranchNumber": 11,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "INDUSTRIAL TECH SERVICES"
        },
        {
          "WorkOrder": 4078378,
          "ParentWorkOrder": "",
          "JobName": "(NOF)EAGAN HOMEOWNER",
          "JobNumber": "502-1509095-30",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6900 HOLLOW CORNERS RD",
          "JobAddress2": "",
          "JobFullAddress": "6900 HOLLOW CORNERS RD",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "KOREY EAGEN",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$5,383.94",
          "BranchNumber": 11,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "KOREY EAGEN"
        },
        {
          "WorkOrder": 4078377,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LaLonde Chrysler",
          "JobNumber": "502-861787-41",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2093 S VanDyke Rd",
          "JobAddress2": "",
          "JobFullAddress": "2093 S VanDyke Rd",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$165,006.00",
          "BalanceRemaining": "$22,700.24",
          "BranchNumber": 11,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "JFS Company"
        },
        {
          "WorkOrder": 4078376,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Panda Exp.-Portage",
          "JobNumber": "502-858231-57",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5811 S Westnedge Ave",
          "JobAddress2": "",
          "JobFullAddress": "5811 S Westnedge Ave",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONSTRUCTION., LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,261.21",
          "BranchNumber": 11,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "MIDWEST METAL CONSTRUCTION., LLC"
        },
        {
          "WorkOrder": 4078375,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hampton Inn & Suites",
          "JobNumber": "502-847401-166",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1951 S TELEGRAPH RD",
          "JobAddress2": "",
          "JobFullAddress": "1951 S TELEGRAPH RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$514.41",
          "BranchNumber": 11,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4078373,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Crystal Pines #8",
          "JobNumber": "502-839201-89",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5664 Norway Spruce Dr",
          "JobAddress2": "",
          "JobFullAddress": "5664 Norway Spruce Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLINTON INTERIORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,717.50",
          "BranchNumber": 11,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "CLINTON INTERIORS INC"
        },
        {
          "WorkOrder": 4078372,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TACO BELL ASHLAND",
          "JobNumber": "502-920300-105",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "715 STATE ROUTE 250",
          "JobAddress2": "",
          "JobFullAddress": "715 STATE ROUTE 250",
          "JobCounty": "ASHLAND",
          "JobState": "Ohio",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$8,268.58",
          "BranchNumber": 9,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 4078371,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Walgreens Rochester",
          "JobNumber": "502-913820-591",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1280 Walton Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1280 Walton Blvd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4078370,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MULTI FAM HOUSING",
          "JobNumber": "502-1605903-4",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1110 ROOT STREET",
          "JobAddress2": "",
          "JobFullAddress": "1110 ROOT STREET",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLOVER CONSTRUCTION SERVICES INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$7,505.15",
          "BranchNumber": 8,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "CLOVER CONSTRUCTION SERVICES INC"
        },
        {
          "WorkOrder": 4078369,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CONTINENTAL HOMES",
          "JobNumber": "502-876752-20",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2436 S I-75 BUSINESS LOOP",
          "JobAddress2": "",
          "JobFullAddress": "2436 S I-75 BUSINESS LOOP",
          "JobCounty": "CRAWFORD",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,050.89",
          "BranchNumber": 8,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 4078368,
          "ParentWorkOrder": "",
          "JobName": "(NOF) KFC FLINT",
          "JobNumber": "502-876650-379",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1765 S DORT HIGHWAY",
          "JobAddress2": "",
          "JobFullAddress": "1765 S DORT HIGHWAY",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$687.48",
          "BranchNumber": 8,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4078367,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Central Campus ProII",
          "JobNumber": "502-876650-376",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32 Market Street",
          "JobAddress2": "",
          "JobFullAddress": "32 Market Street",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$7,664.89",
          "BranchNumber": 8,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4078365,
          "ParentWorkOrder": "",
          "JobName": "(NOF)UAW Solidarity House",
          "JobNumber": "502-812350-98",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8000 E Jefferson Ave",
          "JobAddress2": "",
          "JobFullAddress": "8000 E Jefferson Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW BUILDERS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "BARTON MALOW BUILDERS"
        },
        {
          "WorkOrder": 4078258,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dollar General Conv",
          "JobNumber": "502-1304186-68",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "851 E Tully St",
          "JobAddress2": "",
          "JobFullAddress": "851 E Tully St",
          "JobCounty": "VAN WERT",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,336.79",
          "BranchNumber": 13,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4078257,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TYLERSVILLE STORAGE",
          "JobNumber": "65-2137179-18",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3939 TYLERSVILLE RD",
          "JobAddress2": "",
          "JobFullAddress": "3939 TYLERSVILLE RD",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "DURANGO DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$4,650.58",
          "BranchNumber": 13,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "DURANGO DRYWALL LLC"
        },
        {
          "WorkOrder": 4078256,
          "ParentWorkOrder": "",
          "JobName": "PINNACLE TREATMENT CENTER",
          "JobNumber": "65-505398-11",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "287 E LEFFEL LN",
          "JobAddress2": "",
          "JobFullAddress": "287 E LEFFEL LN",
          "JobCounty": "CLARK",
          "JobState": "Ohio",
          "CustomerCompanyName": "RAY ISAAC PLASTERING LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,930.21",
          "BranchNumber": 13,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "RAY ISAAC PLASTERING LLC"
        },
        {
          "WorkOrder": 4078255,
          "ParentWorkOrder": "",
          "JobName": "(NOF )FISCHER HOMES OFC.",
          "JobNumber": "65-133836-3",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7777 WASHINGTON VLG.DR.",
          "JobAddress2": "",
          "JobFullAddress": "7777 WASHINGTON VLG.DR.",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "WOODARD DEVELOPMENT",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$2,449.05",
          "BranchNumber": 13,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "WOODARD DEVELOPMENT"
        },
        {
          "WorkOrder": 4078254,
          "ParentWorkOrder": "",
          "JobName": "TRADITIONS OF LEBANON",
          "JobNumber": "65-2134671-4",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "401 NEIL ARMSTRONG WAY",
          "JobAddress2": "",
          "JobFullAddress": "401 NEIL ARMSTRONG WAY",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "MONARCH HOMES LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$140,006.00",
          "BalanceRemaining": "$8,330.26",
          "BranchNumber": 12,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "MONARCH HOMES LLC"
        },
        {
          "WorkOrder": 4078253,
          "ParentWorkOrder": "",
          "JobName": "FEDERAL RESERVE BANK",
          "JobNumber": "65-12221-2",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "150 E FOURTH ST",
          "JobAddress2": "",
          "JobFullAddress": "150 E FOURTH ST",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "CONSTRUCTION SERVICE PARTNERS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$65,006.00",
          "BalanceRemaining": "$14,568.45",
          "BranchNumber": 12,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "CONSTRUCTION SERVICE PARTNERS"
        },
        {
          "WorkOrder": 4078252,
          "ParentWorkOrder": "",
          "JobName": "TOWNEPLACE SUITE",
          "JobNumber": "65-402070-5",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1547 ROMBACH AVE",
          "JobAddress2": "",
          "JobFullAddress": "1547 ROMBACH AVE",
          "JobCounty": "CLINTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "BRACKETT BUILDERS,INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,063.92",
          "BranchNumber": 4,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "BRACKETT BUILDERS,INC."
        },
        {
          "WorkOrder": 4078251,
          "ParentWorkOrder": "",
          "JobName": "(NOF) JERSEY MIKES MV",
          "JobNumber": "65-402000-5",
          "FirstdayontheJob": "11-10-2020",
          "LastdayontheJob": "11-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1401 COSHOCTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "1401 COSHOCTON AVE",
          "JobCounty": "KNOX",
          "JobState": "Ohio",
          "CustomerCompanyName": "GREAT LAKES BAY CONSTR",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,254.21",
          "BranchNumber": 4,
          "SubmitDate": "11-11-2020",
          "ClientonJob": "GREAT LAKES BAY CONSTR"
        },
        {
          "WorkOrder": 4078023,
          "ParentWorkOrder": 4072397,
          "JobName": "GREENE COUNTY SANITARY",
          "JobNumber": "65-502280-68",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "667 Dayton Xenia Rd",
          "JobAddress2": "",
          "JobFullAddress": "667 Dayton Xenia Rd",
          "JobCounty": "Greene",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALTIES INC",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$6,289.20",
          "BranchNumber": 13,
          "SubmitDate": "11-10-2020",
          "ClientonJob": "COMBS INTERIOR SPECIALTIES INC"
        },
        {
          "WorkOrder": 4077707,
          "ParentWorkOrder": 4064530,
          "JobName": "(NOF) Hamtramck",
          "JobNumber": "502-1606296-13",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2500 E Grand Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2500 E Grand Blvd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Regis Construction, LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "11-09-2020",
          "ClientonJob": "Regis Construction, LLC"
        },
        {
          "WorkOrder": 4077621,
          "ParentWorkOrder": 4070615,
          "JobName": "(NOF)ARMADAWASTEWATER",
          "JobNumber": "502-12540-25",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "73175 Fulton St",
          "JobAddress2": "",
          "JobFullAddress": "73175 Fulton St",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "LUPE DRYWALL",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "11-09-2020",
          "ClientonJob": "LUPE DRYWALL"
        },
        {
          "WorkOrder": 4076496,
          "ParentWorkOrder": "",
          "JobName": "POWELL VALVES",
          "JobNumber": "65-2134999-18",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3241 SPRING GROVE AVE",
          "JobAddress2": "",
          "JobFullAddress": "3241 SPRING GROVE AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "REYES/TDR INTERIORS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$115,006.00",
          "BalanceRemaining": "$11,003.89",
          "BranchNumber": 12,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "REYES/TDR INTERIORS"
        },
        {
          "WorkOrder": 4076495,
          "ParentWorkOrder": "",
          "JobName": "ORESTOD MARIAS",
          "JobNumber": "65-2134688-22",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25 E WRIGHT ST",
          "JobAddress2": "",
          "JobFullAddress": "25 E WRIGHT ST",
          "JobCounty": "MIAMI",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,000.00",
          "BalanceRemaining": "$40,177.69",
          "BranchNumber": 12,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 4076494,
          "ParentWorkOrder": "",
          "JobName": "CVS health hub store03410",
          "JobNumber": "65-4077685-38",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "60 North Stygler Road",
          "JobAddress2": "",
          "JobFullAddress": "60 North Stygler Road",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "FORTNEY & WEYGANDT, INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$1,880.77",
          "BranchNumber": 4,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "FORTNEY & WEYGANDT, INC"
        },
        {
          "WorkOrder": 4076493,
          "ParentWorkOrder": "",
          "JobName": "Rural King Chilicothe",
          "JobNumber": "65-421045-3",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1470 N BRIDGE STREET",
          "JobAddress2": "",
          "JobFullAddress": "1470 N BRIDGE STREET",
          "JobCounty": "ROSS",
          "JobState": "Ohio",
          "CustomerCompanyName": "ALLTRADE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$12,924.24",
          "BranchNumber": 4,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "ALLTRADE CONSTRUCTION"
        },
        {
          "WorkOrder": 4076492,
          "ParentWorkOrder": "",
          "JobName": "XENIA HIGH SCHOOL",
          "JobNumber": "65-550796-7",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "303 KINSLEY RD",
          "JobAddress2": "",
          "JobFullAddress": "303 KINSLEY RD",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "DE GEORGE CEILINGS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$9,845.28",
          "BranchNumber": 2,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "DE GEORGE CEILINGS"
        },
        {
          "WorkOrder": 4076491,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BLUE WATER DRYWALL",
          "JobNumber": "502-877250-159",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3900 Military St",
          "JobAddress2": "",
          "JobFullAddress": "3900 Military St",
          "JobCounty": "Saint Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Cons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$5,269.51",
          "BranchNumber": 11,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "Blue Water Drywall & Cons"
        },
        {
          "WorkOrder": 4076490,
          "ParentWorkOrder": "",
          "JobName": "(NOF) STONEHENGE CONDOS",
          "JobNumber": "502-849801-262",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "48571, 48593, 48615, 48637, 48659, 48681 Birmingham Dr",
          "JobAddress2": "",
          "JobFullAddress": "48571, 48593, 48615, 48637, 48659, 48681 Birmingham Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$17,006.00",
          "BalanceRemaining": "$4,853.28",
          "BranchNumber": 11,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 4076489,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HIGHLAND VIEW",
          "JobNumber": "502-847401-163",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "900 HIGHLAND VIEW LN",
          "JobAddress2": "",
          "JobFullAddress": "900 HIGHLAND VIEW LN",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$5,005.65",
          "BranchNumber": 11,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4076488,
          "ParentWorkOrder": "",
          "JobName": "(NOF)2040 HILLER RD.",
          "JobNumber": "502-847401-162",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2040 HILLER RD.",
          "JobAddress2": "",
          "JobFullAddress": "2040 HILLER RD.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$5,189.33",
          "BranchNumber": 11,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4076487,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TACO BELL-PORTLAND",
          "JobNumber": "502-920300-100",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1433 E GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "1433 E GRAND RIVER AVE",
          "JobCounty": "IONIA",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$12,199.14",
          "BranchNumber": 9,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 4076486,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RED CEDAR APARTMENTS",
          "JobNumber": "502-919700-142",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "203 S. CLIPPERT ST",
          "JobAddress2": "",
          "JobFullAddress": "203 S. CLIPPERT ST",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$95,006.00",
          "BalanceRemaining": "$83,201.02",
          "BranchNumber": 9,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 4076485,
          "ParentWorkOrder": "",
          "JobName": "(NOF) W. Bloomfield M.S.",
          "JobNumber": "502-910050-59",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4925 Orchard lake Rd.",
          "JobAddress2": "",
          "JobFullAddress": "4925 Orchard lake Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$2,795.86",
          "BranchNumber": 9,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 4076484,
          "ParentWorkOrder": "",
          "JobName": "(NOF) AMAZON PONTIAC",
          "JobNumber": "502-192371-3",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1220/1300 FEATHERSTONE RD",
          "JobAddress2": "",
          "JobFullAddress": "1220/1300 FEATHERSTONE RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "National Specialty Contractors",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "National Specialty Contractors"
        },
        {
          "WorkOrder": 4076483,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HOMEWOOD SUITES A2",
          "JobNumber": "502-1632825-8",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2457 S. STATE ST.",
          "JobAddress2": "",
          "JobFullAddress": "2457 S. STATE ST.",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR CONST & DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$645.99",
          "BranchNumber": 8,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "SUPERIOR CONST & DRYWALL"
        },
        {
          "WorkOrder": 4076482,
          "ParentWorkOrder": "",
          "JobName": "(NOF) PAUL LEASI NEWPORT",
          "JobNumber": "502-1604171-21",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3600 LAKESHORE DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "3600 LAKESHORE DRIVE",
          "JobCounty": "MONROE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,752.54",
          "BranchNumber": 8,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4076481,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Parkdale",
          "JobNumber": "502-876650-380",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44315 N. Gratiot Ave.",
          "JobAddress2": "",
          "JobFullAddress": "44315 N. Gratiot Ave.",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$935.45",
          "BranchNumber": 8,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4076480,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Burger King Laporte",
          "JobNumber": "502-876650-370",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1012 State Rd.",
          "JobAddress2": "",
          "JobFullAddress": "1012 State Rd.",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$7,739.83",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4076479,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GALCO",
          "JobNumber": "502-843701-219",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1451 E LINCOLN AVE",
          "JobAddress2": "",
          "JobFullAddress": "1451 E LINCOLN AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$639.14",
          "BranchNumber": 8,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 4076478,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CLIFF DICE RESIDENCE",
          "JobNumber": "502-990401-68",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3409 E SIEBERT RD.",
          "JobAddress2": "",
          "JobFullAddress": "3409 E SIEBERT RD.",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,769.33",
          "BranchNumber": 7,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4076477,
          "ParentWorkOrder": "",
          "JobName": "(NOF) T-MOBILE NOVI",
          "JobNumber": "502-1699146-4",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26285 NOVI RD.",
          "JobAddress2": "",
          "JobFullAddress": "26285 NOVI RD.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "GIRA LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,116.73",
          "BranchNumber": 1,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "GIRA LLC"
        },
        {
          "WorkOrder": 4076476,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 4323W. VIENNA RD.",
          "JobNumber": "502-148155-7",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4323 W. VIENNA RD.",
          "JobAddress2": "",
          "JobFullAddress": "4323 W. VIENNA RD.",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "METRO GENERAL CONTRACTOR INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "METRO GENERAL CONTRACTOR INC"
        },
        {
          "WorkOrder": 4076475,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 2680 Baldwin Rd",
          "JobNumber": "502-11170-28",
          "FirstdayontheJob": "11-04-2020",
          "LastdayontheJob": "11-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2680 Baldwin Rd",
          "JobAddress2": "",
          "JobFullAddress": "2680 Baldwin Rd",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "JOSEPH L. DEBUSK",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$6,716.47",
          "BranchNumber": 1,
          "SubmitDate": "11-05-2020",
          "ClientonJob": "JOSEPH L. DEBUSK"
        },
        {
          "WorkOrder": 4075570,
          "ParentWorkOrder": 4068486,
          "JobName": "(NOF)FIFTH-THIRD SHELBY",
          "JobNumber": "502-932775-68",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13741 HALL RD.",
          "JobAddress2": "",
          "JobFullAddress": "13741 HALL RD.",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "TLA INTERIORS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,241.98",
          "BranchNumber": 9,
          "SubmitDate": "11-02-2020",
          "ClientonJob": "TLA INTERIORS, INC."
        },
        {
          "WorkOrder": 4075557,
          "ParentWorkOrder": 4068486,
          "JobName": "(NOF)FIFTH-THIRD SHELBY",
          "JobNumber": "502-932775-68",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13741 HALL RD.",
          "JobAddress2": "",
          "JobFullAddress": "13741 HALL RD.",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "TLA INTERIORS, INC.",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,241.98",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "TLA INTERIORS, INC."
        },
        {
          "WorkOrder": 4075555,
          "ParentWorkOrder": 4068022,
          "JobName": "(NOF)GREATER LAKES AMBULA",
          "JobNumber": "502-932775-69",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16100 19 MILE RD. STE 100",
          "JobAddress2": "",
          "JobFullAddress": "16100 19 MILE RD. STE 100",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "TLA INTERIORS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$11,372.32",
          "BranchNumber": 9,
          "SubmitDate": "11-02-2020",
          "ClientonJob": "TLA INTERIORS, INC."
        },
        {
          "WorkOrder": 4074556,
          "ParentWorkOrder": 4064525,
          "JobName": "(NOF_ Andy's Place Apts.",
          "JobNumber": "502-230032-30",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2388 W Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "2388 W Michigan Ave",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "DSI ACOUSTICAL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "10-29-2020",
          "ClientonJob": "DSI ACOUSTICAL"
        },
        {
          "WorkOrder": 4074547,
          "ParentWorkOrder": 4068017,
          "JobName": "(NOF)REAL TIME CRIME CTR",
          "JobNumber": "502-195531-12",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1301 THIRD ST, STE 328",
          "JobAddress2": "",
          "JobFullAddress": "1301 THIRD ST, STE 328",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TravCo Interiors LLC",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,229.70",
          "BranchNumber": 9,
          "SubmitDate": "10-29-2020",
          "ClientonJob": "TravCo Interiors LLC"
        },
        {
          "WorkOrder": 4074524,
          "ParentWorkOrder": 4053541,
          "JobName": "NOF KROGER HYDE PARK",
          "JobNumber": "65-744000-12",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3760 PAXTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "3760 PAXTON AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "GEM INTERIORS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$6,742.67",
          "BranchNumber": 1,
          "SubmitDate": "10-29-2020",
          "ClientonJob": "GEM INTERIORS"
        },
        {
          "WorkOrder": 4074269,
          "ParentWorkOrder": 4068010,
          "JobName": "(NOF)Jail Adult Detention",
          "JobNumber": "502-812350-97",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5301 Russell St",
          "JobAddress2": "",
          "JobFullAddress": "5301 Russell St",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$350,000.00",
          "BalanceRemaining": "$5,826.86",
          "BranchNumber": 8,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4074267,
          "ParentWorkOrder": "",
          "JobName": "DESIGN HOMES",
          "JobNumber": "65-502950-14",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "DEVIN CONSTRUCTION CO LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "DEVIN CONSTRUCTION CO LLC"
        },
        {
          "WorkOrder": 4074266,
          "ParentWorkOrder": "",
          "JobName": "MANHATTAN PROJECT",
          "JobNumber": "65-133836-2",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "601 E. 3RD ST.",
          "JobAddress2": "",
          "JobFullAddress": "601 E. 3RD ST.",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "WOODARD DEVELOPMENT",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$7,500.00",
          "BalanceRemaining": "$5,530.05",
          "BranchNumber": 13,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "WOODARD DEVELOPMENT"
        },
        {
          "WorkOrder": 4074208,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BANFIELD PET HOSP.",
          "JobNumber": "502-1507798-50",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "307 E BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "307 E BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,750.00",
          "BranchNumber": 9,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4074206,
          "ParentWorkOrder": "",
          "JobName": "(NOF)NORTH OAK VILLAS",
          "JobNumber": "502-1325000-37",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "NIXON RD / HAYSTER RD",
          "JobAddress2": "",
          "JobFullAddress": "NIXON RD / HAYSTER RD",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$4,117.24",
          "BranchNumber": 9,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4074205,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDONALDS HARPER WOO",
          "JobNumber": "502-944100-347",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17800 E 8 Mile Rd / 17800 VERNIER RD",
          "JobAddress2": "",
          "JobFullAddress": "17800 E 8 Mile Rd / 17800 VERNIER RD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4074204,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Center Street Garage",
          "JobNumber": "502-934634-20",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "212 South Center Street",
          "JobAddress2": "",
          "JobFullAddress": "212 South Center Street",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONST. GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$4,948.37",
          "BranchNumber": 9,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "BLUESTONE CONST. GROUP"
        },
        {
          "WorkOrder": 4074203,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PLANTE MORAN",
          "JobNumber": "502-933550-198",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3000 TOWN CENTER STE 200, 1ST FL, FL 5-12",
          "JobAddress2": "",
          "JobFullAddress": "3000 TOWN CENTER STE 200, 1ST FL, FL 5-12",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$7,552.98",
          "BranchNumber": 9,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4074202,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WELL SPRING CHURCH",
          "JobNumber": "502-916315-189",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "485 FARNSWORTH RD",
          "JobAddress2": "",
          "JobFullAddress": "485 FARNSWORTH RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$289.34",
          "BranchNumber": 9,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4074201,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Taco Bell Macomb",
          "JobNumber": "502-916315-188",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45590 Gratiot Ave.",
          "JobAddress2": "",
          "JobFullAddress": "45590 Gratiot Ave.",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$994.41",
          "BranchNumber": 9,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4074200,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 6023 Indianwood Tr.",
          "JobNumber": "502-147906-5",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6023 Indianwood Trail",
          "JobAddress2": "",
          "JobFullAddress": "6023 Indianwood Trail",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARINE CITY DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,061.50",
          "BranchNumber": 9,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "MARINE CITY DRYWALL LLC"
        },
        {
          "WorkOrder": 4074198,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HEN ENTERPRISE",
          "JobNumber": "502-886833-6",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1055 PRINCETON AVE",
          "JobAddress2": "",
          "JobFullAddress": "1055 PRINCETON AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Motor City Constr.& Redmo",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,000.00",
          "BalanceRemaining": "$133.64",
          "BranchNumber": 8,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "Motor City Constr.& Redmo"
        },
        {
          "WorkOrder": 4074197,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PINE TREE TOWNS",
          "JobNumber": "502-876650-377",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "527 PINE ST.",
          "JobAddress2": "",
          "JobFullAddress": "527 PINE ST.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$78.78",
          "BranchNumber": 8,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4074196,
          "ParentWorkOrder": "",
          "JobName": "(NOF) H.I.E. UTICA",
          "JobNumber": "502-812692-12",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45555 UTICA PARK BLVD",
          "JobAddress2": "",
          "JobFullAddress": "45555 UTICA PARK BLVD",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "HARBAUGH CONST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$249.72",
          "BranchNumber": 8,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "HARBAUGH CONST"
        },
        {
          "WorkOrder": 4074195,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Meijer DC880 Guard",
          "JobNumber": "502-811817-13",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8857 Swan Creek Rd.",
          "JobAddress2": "",
          "JobFullAddress": "8857 Swan Creek Rd.",
          "JobCounty": "Monroe",
          "JobState": "Michigan",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$6,562.58",
          "BranchNumber": 8,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 4074194,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KROGER#688",
          "JobNumber": "502-20010-35",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "400 S Maple Rd",
          "JobAddress2": "",
          "JobFullAddress": "400 S Maple Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$12,515.37",
          "BranchNumber": 8,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4074193,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NEXTER PLANT 3",
          "JobNumber": "502-16100-2",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3900 E HOLLAND RD",
          "JobAddress2": "",
          "JobFullAddress": "3900 E HOLLAND RD",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "ECKEL INDUSTRIES INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$1,358.09",
          "BranchNumber": 8,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "ECKEL INDUSTRIES INC"
        },
        {
          "WorkOrder": 4074192,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Buena Vista",
          "JobNumber": "502-990400-6",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4273 East Holland Rd",
          "JobAddress2": "",
          "JobFullAddress": "4273 East Holland Rd",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "RAJALA & SONS FINISHES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$17,719.58",
          "BranchNumber": 7,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "RAJALA & SONS FINISHES"
        },
        {
          "WorkOrder": 4074191,
          "ParentWorkOrder": "",
          "JobName": "(NOF) M-R Builders",
          "JobNumber": "502-967000-17",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12435 Sutfin Rd.",
          "JobAddress2": "",
          "JobFullAddress": "12435 Sutfin Rd.",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRENNERS DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$6,171.39",
          "BranchNumber": 7,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "BRENNERS DRYWALL"
        },
        {
          "WorkOrder": 4074190,
          "ParentWorkOrder": "",
          "JobName": "(NOF)T-Mobile Dearborn",
          "JobNumber": "502-1699146-3",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23050 Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "23050 Michigan Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "GIRA LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,006.00",
          "BalanceRemaining": "$1,082.23",
          "BranchNumber": 1,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "GIRA LLC"
        },
        {
          "WorkOrder": 4074189,
          "ParentWorkOrder": "",
          "JobName": "(NOF) T-MOBILE",
          "JobNumber": "502-1699146-2",
          "FirstdayontheJob": "10-27-2020",
          "LastdayontheJob": "10-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3917 BALDWIN RD",
          "JobAddress2": "",
          "JobFullAddress": "3917 BALDWIN RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "GIRA LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,480.31",
          "BranchNumber": 1,
          "SubmitDate": "10-28-2020",
          "ClientonJob": "GIRA LLC"
        },
        {
          "WorkOrder": 4073943,
          "ParentWorkOrder": 4064030,
          "JobName": "(NOF) WALGREENS",
          "JobNumber": "502-134220-8",
          "FirstdayontheJob": "09-21-2020",
          "LastdayontheJob": "09-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3270 W Silver Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "3270 W Silver Lake Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "HORIZON RETAIL CONSTRUCT",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$11,716.44",
          "BranchNumber": 13,
          "SubmitDate": "10-27-2020",
          "ClientonJob": "HORIZON RETAIL CONSTRUCT"
        },
        {
          "WorkOrder": 4072901,
          "ParentWorkOrder": 4068018,
          "JobName": "(NOF) RAIR MUSKEGON",
          "JobNumber": "502-916315-182",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1871 PECK ST",
          "JobAddress2": "",
          "JobFullAddress": "1871 PECK ST",
          "JobCounty": "MUSKEGON",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM. CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$25,383.22",
          "BranchNumber": 9,
          "SubmitDate": "10-23-2020",
          "ClientonJob": "INDEPENDENCE COMM. CONST."
        },
        {
          "WorkOrder": 4072435,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ALPINE VIEW DR",
          "JobNumber": "502-847401-161",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7373 ALPINE VIEW DR",
          "JobAddress2": "",
          "JobFullAddress": "7373 ALPINE VIEW DR",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$5,078.94",
          "BranchNumber": 11,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4072434,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MONTCARET",
          "JobNumber": "502-1325000-39",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19074 FLORISSANT DR",
          "JobAddress2": "",
          "JobFullAddress": "19074 FLORISSANT DR",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$90,006.00",
          "BalanceRemaining": "$8,992.71",
          "BranchNumber": 9,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4072433,
          "ParentWorkOrder": "",
          "JobName": "(NOF)N.Oaks Townhome",
          "JobNumber": "502-1325000-35",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2999 NIXON RD",
          "JobAddress2": "",
          "JobFullAddress": "2999 NIXON RD",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$8,107.31",
          "BranchNumber": 9,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4072432,
          "ParentWorkOrder": "",
          "JobName": "(NOF)AMAZON SAGINAW",
          "JobNumber": "502-934150-69",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3280 COMMERCE CENTRE DR.",
          "JobAddress2": "",
          "JobFullAddress": "3280 COMMERCE CENTRE DR.",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "WOODS CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$16,668.24",
          "BranchNumber": 9,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "WOODS CONSTRUCTION INC"
        },
        {
          "WorkOrder": 4072431,
          "ParentWorkOrder": "",
          "JobName": "(NOF)UHS BEAUMONT",
          "JobNumber": "502-933550-190",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18001 ROTUNDA DR",
          "JobAddress2": "",
          "JobFullAddress": "18001 ROTUNDA DR",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,935.10",
          "BranchNumber": 9,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4072430,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TLC",
          "JobNumber": "502-916315-187",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30330 HICKEY RD.",
          "JobAddress2": "",
          "JobFullAddress": "30330 HICKEY RD.",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4072429,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ULTRA SALON SUITES",
          "JobNumber": "502-916315-186",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5066 HIGHLAND RD.",
          "JobAddress2": "",
          "JobFullAddress": "5066 HIGHLAND RD.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$6,099.64",
          "BranchNumber": 9,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4072428,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GALLAGER RESIDENCE",
          "JobNumber": "502-886833-13",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2067 Cass Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2067 Cass Blvd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Motor City Constr.& Remodeling",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$727.54",
          "BranchNumber": 8,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "Motor City Constr.& Remodeling"
        },
        {
          "WorkOrder": 4072427,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Pet Suites",
          "JobNumber": "502-876650-375",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "47025 Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "47025 Grand River Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,035.67",
          "BranchNumber": 8,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4072426,
          "ParentWorkOrder": "",
          "JobName": "(NOF)INDOOR BASEBALL FACI",
          "JobNumber": "502-825063-56",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6564 S STATE ST",
          "JobAddress2": "",
          "JobFullAddress": "6564 S STATE ST",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHAFFER INCORPORATED",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,506.00",
          "BalanceRemaining": "$2,512.07",
          "BranchNumber": 8,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "SHAFFER INCORPORATED"
        },
        {
          "WorkOrder": 4072425,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-2029 SJP OR SFLD",
          "JobNumber": "502-815250-381",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16001 W 9 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "16001 W 9 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4072424,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-2052 SJP MRI SFLD",
          "JobNumber": "502-815250-380",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16001 W 9 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "16001 W 9 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4072423,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HELIOS GREENHOUSE",
          "JobNumber": "502-20010-37",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4121 LANG RD",
          "JobAddress2": "",
          "JobFullAddress": "4121 LANG RD",
          "JobCounty": "GLADWIN",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$2,455.75",
          "BranchNumber": 8,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4072422,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WOLF LAKE RD HOUSE",
          "JobNumber": "502-1606795-6",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2040 WOLF LAKE RD",
          "JobAddress2": "",
          "JobFullAddress": "2040 WOLF LAKE RD",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "RIVER CITY INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,144.33",
          "BranchNumber": 7,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "RIVER CITY INTERIORS"
        },
        {
          "WorkOrder": 4072421,
          "ParentWorkOrder": "",
          "JobName": "(NOF)NORTH PARK",
          "JobNumber": "502-1668468-11",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8798 E PARK RIDGE CIRCLE",
          "JobAddress2": "",
          "JobFullAddress": "8798 E PARK RIDGE CIRCLE",
          "JobCounty": "MONROE",
          "JobState": "Michigan",
          "CustomerCompanyName": "WADE JURNEY HOMES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$8,547.84",
          "BranchNumber": 1,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "WADE JURNEY HOMES"
        },
        {
          "WorkOrder": 4072420,
          "ParentWorkOrder": "",
          "JobName": "(NOF)STANDISH MCD #27799",
          "JobNumber": "502-13380-135",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "515 S US HWY 23",
          "JobAddress2": "",
          "JobFullAddress": "515 S US HWY 23",
          "JobCounty": "ARENAC",
          "JobState": "Michigan",
          "CustomerCompanyName": "RAYMOND RENOVATIONS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$15,271.49",
          "BranchNumber": 1,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "RAYMOND RENOVATIONS"
        },
        {
          "WorkOrder": 4072398,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Torrey Hill",
          "JobNumber": "502-1609034-53",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1101 N. Summit St./316 Elm St / 1116 N Superior St",
          "JobAddress2": "",
          "JobFullAddress": "1101 N. Summit St./316 Elm St / 1116 N Superior St",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$12,753.61",
          "BranchNumber": 13,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4072397,
          "ParentWorkOrder": "",
          "JobName": "GREENE COUNTY SANITARY",
          "JobNumber": "65-502280-68",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "667 Dayton Xenia Rd",
          "JobAddress2": "",
          "JobFullAddress": "667 Dayton Xenia Rd",
          "JobCounty": "Greene",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALTIES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$6,289.20",
          "BranchNumber": 13,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "COMBS INTERIOR SPECIALTIES INC"
        },
        {
          "WorkOrder": 4072396,
          "ParentWorkOrder": "",
          "JobName": "PORT WILLIAM FIRE STATION",
          "JobNumber": "65-510560-4",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7277 N SR 134",
          "JobAddress2": "",
          "JobFullAddress": "7277 N SR 134",
          "JobCounty": "Clinton",
          "JobState": "Ohio",
          "CustomerCompanyName": "TUMLIN, JOHN P. & SONS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,796.02",
          "BranchNumber": 2,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "TUMLIN, JOHN P. & SONS"
        },
        {
          "WorkOrder": 4072395,
          "ParentWorkOrder": "",
          "JobName": "R & L TECH CENTER/OFFICE",
          "JobNumber": "65-508635-3",
          "FirstdayontheJob": "10-20-2020",
          "LastdayontheJob": "10-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "215 W CURRY RD",
          "JobAddress2": "",
          "JobFullAddress": "215 W CURRY RD",
          "JobCounty": "Clinton",
          "JobState": "Ohio",
          "CustomerCompanyName": "R & L CARRIERS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$21,867.97",
          "BranchNumber": 2,
          "SubmitDate": "10-21-2020",
          "ClientonJob": "R & L CARRIERS"
        },
        {
          "WorkOrder": 4071151,
          "ParentWorkOrder": "",
          "JobName": "CVS",
          "JobNumber": "65-492299-4",
          "FirstdayontheJob": "10-15-2020",
          "LastdayontheJob": "10-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1331 N FAIRFIELD RD",
          "JobAddress2": "",
          "JobFullAddress": "1331 N FAIRFIELD RD",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "KYROS DEVELOPMENT",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$15,000.00",
          "BranchNumber": "Not specified",
          "SubmitDate": "10-16-2020",
          "ClientonJob": "KYROS DEVELOPMENT"
        },
        {
          "WorkOrder": 4071026,
          "ParentWorkOrder": "",
          "JobName": "(NOF) American Axel Plant",
          "JobNumber": "502-1609034-52",
          "FirstdayontheJob": "10-14-2020",
          "LastdayontheJob": "10-14-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3255 Alliance Rd NW",
          "JobAddress2": "",
          "JobFullAddress": "3255 Alliance Rd NW",
          "JobCounty": "CARROLL",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$65,006.00",
          "BalanceRemaining": "$62,733.69",
          "BranchNumber": 13,
          "SubmitDate": "10-15-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4071025,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LOFTS @ WILLOW CREEK",
          "JobNumber": "65-139797-2",
          "FirstdayontheJob": "10-14-2020",
          "LastdayontheJob": "10-14-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2621 HIBISCUS WAY",
          "JobAddress2": "",
          "JobFullAddress": "2621 HIBISCUS WAY",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "PURE PROPERTY SOLUTIONS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$4,191.03",
          "BranchNumber": 13,
          "SubmitDate": "10-15-2020",
          "ClientonJob": "PURE PROPERTY SOLUTIONS"
        },
        {
          "WorkOrder": 4071024,
          "ParentWorkOrder": "",
          "JobName": "COLLEGE HILL PRESB CHURCH",
          "JobNumber": "65-125599-5",
          "FirstdayontheJob": "10-14-2020",
          "LastdayontheJob": "10-14-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5742 HAMILTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "5742 HAMILTON AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "ADVANCED ACOUSTICAL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$9,521.68",
          "BranchNumber": 12,
          "SubmitDate": "10-15-2020",
          "ClientonJob": "ADVANCED ACOUSTICAL LLC"
        },
        {
          "WorkOrder": 4071023,
          "ParentWorkOrder": "",
          "JobName": "Tarrier Foods",
          "JobNumber": "65-407200-2",
          "FirstdayontheJob": "10-14-2020",
          "LastdayontheJob": "10-14-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2700 International St",
          "JobAddress2": "",
          "JobFullAddress": "2700 International St",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "FABRICATED STRUCTURES",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,056.44",
          "BranchNumber": 4,
          "SubmitDate": "10-15-2020",
          "ClientonJob": "FABRICATED STRUCTURES"
        },
        {
          "WorkOrder": 4071022,
          "ParentWorkOrder": "",
          "JobName": "SOLA SALON",
          "JobNumber": "65-700161-9",
          "FirstdayontheJob": "10-14-2020",
          "LastdayontheJob": "10-14-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33425 AURORA RD",
          "JobAddress2": "",
          "JobFullAddress": "33425 AURORA RD",
          "JobCounty": "CUYAHOGA",
          "JobState": "Ohio",
          "CustomerCompanyName": "BANYAN CONSTRUCTION SERVICES LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$12,370.90",
          "BranchNumber": 1,
          "SubmitDate": "10-15-2020",
          "ClientonJob": "BANYAN CONSTRUCTION SERVICES LLC"
        },
        {
          "WorkOrder": 4070639,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Gardens of Riverside",
          "JobNumber": "502-847401-160",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "313 Hidden River East",
          "JobAddress2": "",
          "JobFullAddress": "313 Hidden River East",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$3,198.30",
          "BranchNumber": 11,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4070638,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RESERVE @ W.BLOOMFIELD",
          "JobNumber": "502-1325000-41",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "ARIMOORE DR",
          "JobAddress2": "",
          "JobFullAddress": "ARIMOORE DR",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4070637,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TRAILWOODS",
          "JobNumber": "502-1325000-34",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "S. Staebler Rd./N Trailwoods Dr",
          "JobAddress2": "",
          "JobFullAddress": "S. Staebler Rd./N Trailwoods Dr",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$4,747.34",
          "BranchNumber": 9,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4070636,
          "ParentWorkOrder": "",
          "JobName": "(NOF) PROVISIONING CENTER",
          "JobNumber": "502-949575-126",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1810 E WEST MAPLE RD",
          "JobAddress2": "",
          "JobFullAddress": "1810 E WEST MAPLE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,506.00",
          "BalanceRemaining": "$2,495.11",
          "BranchNumber": 9,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4070635,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ira Township",
          "JobNumber": "502-948945-62",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9415 ARNOLD RD",
          "JobAddress2": "",
          "JobFullAddress": "9415 ARNOLD RD",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trinity Building Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$7,163.90",
          "BranchNumber": 9,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "Trinity Building Group"
        },
        {
          "WorkOrder": 4070634,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MCDONALDS WALLED LK",
          "JobNumber": "502-944100-346",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1212 E WEST MAPLE RD",
          "JobAddress2": "",
          "JobFullAddress": "1212 E WEST MAPLE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$16,006.00",
          "BalanceRemaining": "$352.86",
          "BranchNumber": 9,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4070632,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LAUREL PARK COMMONS",
          "JobNumber": "502-944100-345",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17300 N LAUREL PARK DR",
          "JobAddress2": "",
          "JobFullAddress": "17300 N LAUREL PARK DR",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,153.94",
          "BranchNumber": 9,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4070631,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BEAUMONT HOSP - TROY",
          "JobNumber": "502-944100-344",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44201 DEQUINDRE RD",
          "JobAddress2": "",
          "JobFullAddress": "44201 DEQUINDRE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$7,385.91",
          "BranchNumber": 9,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4070630,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FCA TRUCK CAF 20-325",
          "JobNumber": "502-937250-162",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21500 MOUND RD",
          "JobAddress2": "",
          "JobFullAddress": "21500 MOUND RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINKER TEAM CONSTRUCTION, CO.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$2,571.43",
          "BranchNumber": 9,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "BRINKER TEAM CONSTRUCTION, CO."
        },
        {
          "WorkOrder": 4070629,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KLA R&D CENTER",
          "JobNumber": "502-933550-193",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1200 WOODRIDGE AVE",
          "JobAddress2": "",
          "JobFullAddress": "1200 WOODRIDGE AVE",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$15,355.98",
          "BranchNumber": 9,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4070628,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Value City Furniture",
          "JobNumber": "502-919700-141",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50400 Gratiot Ave",
          "JobAddress2": "",
          "JobFullAddress": "50400 Gratiot Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$1,539.85",
          "BranchNumber": 9,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 4070627,
          "ParentWorkOrder": "",
          "JobName": "(NOF)VALUE CITY FURNITURE",
          "JobNumber": "502-919700-140",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4577 Miller Rd",
          "JobAddress2": "",
          "JobFullAddress": "4577 Miller Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 4070626,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Regency Club Apt #21",
          "JobNumber": "502-8997117-26",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26250 Regency Club Circle",
          "JobAddress2": "",
          "JobFullAddress": "26250 Regency Club Circle",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "FRONTGATE VENTURES LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$9,205.46",
          "BranchNumber": 8,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "FRONTGATE VENTURES LLC"
        },
        {
          "WorkOrder": 4070625,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ASH",
          "JobNumber": "502-1604171-25",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4065 Golf Ridge Dr E",
          "JobAddress2": "",
          "JobFullAddress": "4065 Golf Ridge Dr E",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4070624,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MI Advanced Surgery",
          "JobNumber": "502-876650-374",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27101 Schoenherr Rd",
          "JobAddress2": "Ste 200",
          "JobFullAddress": "27101 Schoenherr Rd Ste 200",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$9,388.50",
          "BranchNumber": 8,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4070623,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SCIO RETAIL",
          "JobNumber": "502-876650-373",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5609 JACKSON RD",
          "JobAddress2": "",
          "JobFullAddress": "5609 JACKSON RD",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,605.09",
          "BranchNumber": 8,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4070622,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(275)Powel 9th Grade",
          "JobNumber": "502-816350-160",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "62100 Jewell Rd",
          "JobAddress2": "",
          "JobFullAddress": "62100 Jewell Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,650.32",
          "BranchNumber": 8,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4070621,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-2049 DTE SECURITY",
          "JobNumber": "502-815250-379",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 ENERGY PLAZA",
          "JobAddress2": "",
          "JobFullAddress": "1 ENERGY PLAZA",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,136.43",
          "BranchNumber": 8,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4070620,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Green Mitten Office",
          "JobNumber": "502-116213-2",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9384 N Saginaw Rd",
          "JobAddress2": "",
          "JobFullAddress": "9384 N Saginaw Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "GREEN MITTEN HARVEST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$29,121.27",
          "BranchNumber": 8,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "GREEN MITTEN HARVEST"
        },
        {
          "WorkOrder": 4070618,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GEORGETOWN APTS",
          "JobNumber": "502-1645792-6",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5721 Westtown Dr / Easttown Dr",
          "JobAddress2": "",
          "JobFullAddress": "5721 Westtown Dr / Easttown Dr",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "PRD Construction, Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "PRD Construction, Inc"
        },
        {
          "WorkOrder": 4070617,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Mattawan School",
          "JobNumber": "502-1344933-16",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "56720 Murray ST",
          "JobAddress2": "",
          "JobFullAddress": "56720 Murray ST",
          "JobCounty": "Van Buren",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER 1 INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$50,343.07",
          "BranchNumber": 5,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "PREMIER 1 INTERIORS LLC"
        },
        {
          "WorkOrder": 4070616,
          "ParentWorkOrder": "",
          "JobName": "(NOF)5206 GATEWAY CENTER",
          "JobNumber": "502-112908-15",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5206 Gateway Center",
          "JobAddress2": "",
          "JobFullAddress": "5206 Gateway Center",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DW LURVEY CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$6,149.53",
          "BranchNumber": 1,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "DW LURVEY CONSTRUCTION"
        },
        {
          "WorkOrder": 4070615,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ARMADAWASTEWATER",
          "JobNumber": "502-12540-25",
          "FirstdayontheJob": "10-13-2020",
          "LastdayontheJob": "10-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "73175 Fulton St",
          "JobAddress2": "",
          "JobFullAddress": "73175 Fulton St",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "LUPE DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "LUPE DRYWALL"
        },
        {
          "WorkOrder": 4070570,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Wattles Creek Apt.s",
          "JobNumber": "502-1601205-3",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3928 Old Creek Rd",
          "JobAddress2": "",
          "JobFullAddress": "3928 Old Creek Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Liincoln Hanccok",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "N/A",
          "BranchNumber": 7,
          "SubmitDate": "10-14-2020",
          "ClientonJob": "Liincoln Hanccok"
        },
        {
          "WorkOrder": 4069230,
          "ParentWorkOrder": 4055484,
          "JobName": "(NOF)Orchard Brook Ph 2",
          "JobNumber": "502-1621700-9",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "57163 Cypress St",
          "JobAddress2": "",
          "JobFullAddress": "57163 Cypress St",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHINGLETON CONTRACTORS INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-08-2020",
          "ClientonJob": "SHINGLETON CONTRACTORS INC"
        },
        {
          "WorkOrder": 4069226,
          "ParentWorkOrder": 4041650,
          "JobName": "(NOF)GARDEN CITY MOB",
          "JobNumber": "502-944100-337",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35750 WARREN RD",
          "JobAddress2": "",
          "JobFullAddress": "35750 WARREN RD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$12,252.12",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4068497,
          "ParentWorkOrder": "",
          "JobName": "(NOF) VANCREST HEALTH CAR",
          "JobNumber": "502-1304186-65",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "600 JOE E BROWN AVE",
          "JobAddress2": "",
          "JobFullAddress": "600 JOE E BROWN AVE",
          "JobCounty": "Henry",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,072.07",
          "BranchNumber": 13,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4068496,
          "ParentWorkOrder": "",
          "JobName": "WCTC BLDG 5,6,7",
          "JobNumber": "65-2137099-17",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8531 TRADE CENTER DR",
          "JobAddress2": "",
          "JobFullAddress": "8531 TRADE CENTER DR",
          "JobCounty": "Butler",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & A INTERIOR SYSTEMS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$6,964.85",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "J & A INTERIOR SYSTEMS"
        },
        {
          "WorkOrder": 4068495,
          "ParentWorkOrder": "",
          "JobName": "NOF THE ARTISTRY PROJECT",
          "JobNumber": "65-406640-3",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "601 E Pete Rose Way",
          "JobAddress2": "",
          "JobFullAddress": "601 E Pete Rose Way",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "DRYWALL CONTRACTORS INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$8,406.74",
          "BranchNumber": 12,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "DRYWALL CONTRACTORS INC"
        },
        {
          "WorkOrder": 4068494,
          "ParentWorkOrder": "",
          "JobName": "VETERANS CONTRACTING INC",
          "JobNumber": "65-124770-3",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4770 W 139TH ST",
          "JobAddress2": "",
          "JobFullAddress": "4770 W 139TH ST",
          "JobCounty": "Cuyahoga",
          "JobState": "Ohio",
          "CustomerCompanyName": "VETERANS CONTRACTING INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$50,000.00",
          "BalanceRemaining": "$35.00",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "VETERANS CONTRACTING INC"
        },
        {
          "WorkOrder": 4068493,
          "ParentWorkOrder": "",
          "JobName": "FRANKLIN COUNTY DR",
          "JobNumber": "65-416900-8",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "373 S High St",
          "JobAddress2": "",
          "JobFullAddress": "373 S High St",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "ProCon PROFESSIONAL CONST",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$1,042.75",
          "BranchNumber": 4,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "ProCon PROFESSIONAL CONST"
        },
        {
          "WorkOrder": 4068491,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CHRISTENBURY SUB",
          "JobNumber": "502-877250-156",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22055 Majestic Dr E",
          "JobAddress2": "",
          "JobFullAddress": "22055 Majestic Dr E",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Cons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$5,209.89",
          "BranchNumber": 11,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "Blue Water Drywall & Cons"
        },
        {
          "WorkOrder": 4068490,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WEST BAYSHORE DR",
          "JobNumber": "502-847401-158",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "862 WEST BAY SHORE DR",
          "JobAddress2": "",
          "JobFullAddress": "862 WEST BAY SHORE DR",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,506.24",
          "BranchNumber": 11,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4068489,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HAMLET",
          "JobNumber": "502-1325000-38",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50617 Scarborough Rd",
          "JobAddress2": "",
          "JobFullAddress": "50617 Scarborough Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$120,006.00",
          "BalanceRemaining": "$4,789.78",
          "BranchNumber": 9,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4068488,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CADY NORTHVILLE",
          "JobNumber": "502-950450-21",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "345 E Cady St",
          "JobAddress2": "",
          "JobFullAddress": "345 E Cady St",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "William West Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$5,708.82",
          "BranchNumber": 9,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "William West Inc."
        },
        {
          "WorkOrder": 4068487,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SCHOOLCRAFT COLLEGE",
          "JobNumber": "502-933550-194",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18600 Haggerty Rd",
          "JobAddress2": "#20040",
          "JobFullAddress": "18600 Haggerty Rd #20040",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$3,917.20",
          "BranchNumber": 9,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4068486,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FIFTH-THIRD SHELBY",
          "JobNumber": "502-932775-68",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13741 HALL RD.",
          "JobAddress2": "",
          "JobFullAddress": "13741 HALL RD.",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "TLA INTERIORS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,241.98",
          "BranchNumber": 9,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "TLA INTERIORS, INC."
        },
        {
          "WorkOrder": 4068485,
          "ParentWorkOrder": "",
          "JobName": "(NOF)#1625-MSGCU",
          "JobNumber": "502-913820-588",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16224 26 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "16224 26 MILE RD",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$1,495.16",
          "BranchNumber": 9,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4068484,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Clinton Hilton Garde",
          "JobNumber": "502-1632825-4",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44700 Hayes Road",
          "JobAddress2": "",
          "JobFullAddress": "44700 Hayes Road",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR CONST & DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,279.81",
          "BranchNumber": 8,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "SUPERIOR CONST & DRYWALL LLC"
        },
        {
          "WorkOrder": 4068483,
          "ParentWorkOrder": "",
          "JobName": "(NOF) RESIDENCE 79 MAPLE",
          "JobNumber": "502-886833-12",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "49 Maplefield Rd",
          "JobAddress2": "",
          "JobFullAddress": "49 Maplefield Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Motor City Constr.& Remodeling",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$2,190.68",
          "BranchNumber": 8,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "Motor City Constr.& Remodeling"
        },
        {
          "WorkOrder": 4068482,
          "ParentWorkOrder": "",
          "JobName": "(NOF)UNITED SHORE BRIDGE",
          "JobNumber": "502-877300-41",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "700 South Blvd E",
          "JobAddress2": "",
          "JobFullAddress": "700 South Blvd E",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR DESIGNS INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$8,612.60",
          "BranchNumber": 8,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "SUPERIOR DESIGNS INC."
        },
        {
          "WorkOrder": 4068481,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dollar Tree Sterlin",
          "JobNumber": "502-876752-18",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "40828 Ryan Rd.",
          "JobAddress2": "",
          "JobFullAddress": "40828 Ryan Rd.",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$170,000.00",
          "BalanceRemaining": "$418.28",
          "BranchNumber": 8,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 4068480,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dollar Tree Lansing",
          "JobNumber": "502-876752-17",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "647 N Marketplace Blvd",
          "JobAddress2": "",
          "JobFullAddress": "647 N Marketplace Blvd",
          "JobCounty": "EATON",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,533.29",
          "BranchNumber": 8,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 4068479,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dollar Tree Utica",
          "JobNumber": "502-876752-16",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "52925 Hayes Rd.",
          "JobAddress2": "",
          "JobFullAddress": "52925 Hayes Rd.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,904.15",
          "BranchNumber": 8,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 4068478,
          "ParentWorkOrder": "",
          "JobName": "(NOF) EMERALD LAKE PLAZA",
          "JobNumber": "502-876650-371",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5903 JOHN R ROAD",
          "JobAddress2": "",
          "JobFullAddress": "5903 JOHN R ROAD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4068477,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Residenc Epping Lane",
          "JobNumber": "502-876650-369",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1445 Epping Lane",
          "JobAddress2": "",
          "JobFullAddress": "1445 Epping Lane",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,654.24",
          "BranchNumber": 8,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4068476,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Balbes Construction",
          "JobNumber": "502-839201-70",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4509 Lakeview Ct",
          "JobAddress2": "",
          "JobFullAddress": "4509 Lakeview Ct",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLINTON INTERIORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$5,758.73",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "CLINTON INTERIORS INC"
        },
        {
          "WorkOrder": 4068475,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MARQUETTE BLDG.",
          "JobNumber": "502-823200-96",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "243 W CONGRESS ST",
          "JobAddress2": "",
          "JobFullAddress": "243 W CONGRESS ST",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "PONTIAC CEILING & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$8,576.93",
          "BranchNumber": 8,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "PONTIAC CEILING & PART"
        },
        {
          "WorkOrder": 4068474,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Shelby Towncenter",
          "JobNumber": "502-815630-244",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14195 Hall Rd.",
          "JobAddress2": "",
          "JobFullAddress": "14195 Hall Rd.",
          "JobCounty": "Oceana",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$30,498.35",
          "BranchNumber": 8,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4068473,
          "ParentWorkOrder": "",
          "JobName": "(NOF) McEldowney",
          "JobNumber": "502-1606795-5",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2811 W. Michigan Ave.",
          "JobAddress2": "",
          "JobFullAddress": "2811 W. Michigan Ave.",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "RIVER CITY INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$3,928.37",
          "BranchNumber": 7,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "RIVER CITY INTERIORS"
        },
        {
          "WorkOrder": 4068472,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Verhines",
          "JobNumber": "502-1606795-4",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "201 Highland Dr.",
          "JobAddress2": "",
          "JobFullAddress": "201 Highland Dr.",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "RIVER CITY INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$6,375.34",
          "BranchNumber": 7,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "RIVER CITY INTERIORS"
        },
        {
          "WorkOrder": 4068471,
          "ParentWorkOrder": "",
          "JobName": "(NOF) M-R Builders",
          "JobNumber": "502-967000-16",
          "FirstdayontheJob": "10-06-2020",
          "LastdayontheJob": "10-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3027 Blue Ridge Rd., LB 1946",
          "JobAddress2": "",
          "JobFullAddress": "3027 Blue Ridge Rd., LB 1946",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRENNERS DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$7,325.83",
          "BranchNumber": 7,
          "SubmitDate": "10-07-2020",
          "ClientonJob": "BRENNERS DRYWALL"
        },
        {
          "WorkOrder": 4068023,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LH MEDICAL FLORAL RM",
          "JobNumber": "502-855801-18",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2601 ELECTRIC AVE",
          "JobAddress2": "",
          "JobFullAddress": "2601 ELECTRIC AVE",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "BEEM CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$16,992.14",
          "BranchNumber": 11,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "BEEM CONSTRUCTION"
        },
        {
          "WorkOrder": 4068022,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GREATER LAKES AMBULA",
          "JobNumber": "502-932775-69",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16100 19 MILE RD. STE 100",
          "JobAddress2": "",
          "JobFullAddress": "16100 19 MILE RD. STE 100",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "TLA INTERIORS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$11,372.32",
          "BranchNumber": 9,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "TLA INTERIORS, INC."
        },
        {
          "WorkOrder": 4068021,
          "ParentWorkOrder": "",
          "JobName": "(NOF)COMMERCE COMFORTCARE",
          "JobNumber": "502-919390-8",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 DECKER RD.",
          "JobAddress2": "",
          "JobFullAddress": "100 DECKER RD.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Midwest Interiors, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$3,448.01",
          "BranchNumber": 9,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "Midwest Interiors, LLC"
        },
        {
          "WorkOrder": 4068020,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Taco Bell Troy",
          "JobNumber": "502-916315-185",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41167 Dequindre Rd.",
          "JobAddress2": "",
          "JobFullAddress": "41167 Dequindre Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$552.28",
          "BranchNumber": 9,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4068019,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Everbrook Academy",
          "JobNumber": "502-916315-183",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "57156 10 Mile Rd.",
          "JobAddress2": "",
          "JobFullAddress": "57156 10 Mile Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,667.60",
          "BranchNumber": 9,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4068018,
          "ParentWorkOrder": "",
          "JobName": "(NOF) RAIR MUSKEGON",
          "JobNumber": "502-916315-182",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1871 PECK ST",
          "JobAddress2": "",
          "JobFullAddress": "1871 PECK ST",
          "JobCounty": "MUSKEGON",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$25,383.22",
          "BranchNumber": 9,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4068017,
          "ParentWorkOrder": "",
          "JobName": "(NOF)REAL TIME CRIME CTR",
          "JobNumber": "502-195531-12",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1301 THIRD ST, STE 328",
          "JobAddress2": "",
          "JobFullAddress": "1301 THIRD ST, STE 328",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TravCo Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,229.70",
          "BranchNumber": 9,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "TravCo Interiors LLC"
        },
        {
          "WorkOrder": 4068016,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ACTIONWOOD360",
          "JobNumber": "502-1644500-2",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44500 REYNOLDS DR.",
          "JobAddress2": "",
          "JobFullAddress": "44500 REYNOLDS DR.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACTION WOOD TECHNOLOGIES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$13,006.00",
          "BalanceRemaining": "$11,130.00",
          "BranchNumber": 8,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "ACTION WOOD TECHNOLOGIES"
        },
        {
          "WorkOrder": 4068015,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SHEFFER HOLDINGS",
          "JobNumber": "502-1632825-3",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3700 SHEFFER AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "3700 SHEFFER AVENUE",
          "JobCounty": "iNGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR CONST & DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,068.94",
          "BranchNumber": 8,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "SUPERIOR CONST & DRYWALL"
        },
        {
          "WorkOrder": 4068014,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Harper Retail",
          "JobNumber": "502-876650-368",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21323 Harper Ave.",
          "JobAddress2": "",
          "JobFullAddress": "21323 Harper Ave.",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$2,443.10",
          "BranchNumber": 8,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4068013,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Residence Frank St.",
          "JobNumber": "502-876650-367",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "633 W Frank St.",
          "JobAddress2": "",
          "JobFullAddress": "633 W Frank St.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,329.40",
          "BranchNumber": 8,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4068012,
          "ParentWorkOrder": "",
          "JobName": "NOF PANDA EXPRESS",
          "JobNumber": "502-876650-252",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3715 & 3721 ALPINE AVE NW",
          "JobAddress2": "",
          "JobFullAddress": "3715 & 3721 ALPINE AVE NW",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$401.16",
          "BranchNumber": 8,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4068011,
          "ParentWorkOrder": "",
          "JobName": "(NOF)THS ASSISTED LIVING",
          "JobNumber": "502-839201-73",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "71149 Orchard Crossing Ln",
          "JobAddress2": "",
          "JobFullAddress": "71149 Orchard Crossing Ln",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLINTON INTERIORS INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$4,192.51",
          "BranchNumber": 8,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "CLINTON INTERIORS INC"
        },
        {
          "WorkOrder": 4068010,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Jail Adult Detention",
          "JobNumber": "502-812350-97",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1201 E WARREN ST",
          "JobAddress2": "",
          "JobFullAddress": "1201 E WARREN ST",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$350,000.00",
          "BalanceRemaining": "$5,826.86",
          "BranchNumber": 8,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4068009,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Jimmy Johns",
          "JobNumber": "502-16564-3",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "527 N. Main St.",
          "JobAddress2": "",
          "JobFullAddress": "527 N. Main St.",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARINE CITY CONTRACTING INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$3,461.81",
          "BranchNumber": 8,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "MARINE CITY CONTRACTING INC"
        },
        {
          "WorkOrder": 4068008,
          "ParentWorkOrder": "",
          "JobName": "(NOF)NORTHWEST MUTUAL ACT",
          "JobNumber": "502-1612480-5",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2947 Eyde Pkwy Ste 210",
          "JobAddress2": "",
          "JobFullAddress": "2947 Eyde Pkwy Ste 210",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "LOUIS J EYDE FAMILY LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,043.20",
          "BranchNumber": 7,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "LOUIS J EYDE FAMILY LLC"
        },
        {
          "WorkOrder": 4068007,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Charlotte Homes",
          "JobNumber": "502-1606795-3",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 Otto Rd.",
          "JobAddress2": "",
          "JobFullAddress": "2800 Otto Rd.",
          "JobCounty": "Eaton",
          "JobState": "Michigan",
          "CustomerCompanyName": "RIVER CITY INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,755.11",
          "BranchNumber": 7,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "RIVER CITY INTERIORS"
        },
        {
          "WorkOrder": 4068006,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WATTLES CREEK APTS",
          "JobNumber": "502-1601205-3",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3930 OLD CREEK RD",
          "JobAddress2": "",
          "JobFullAddress": "3930 OLD CREEK RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "LINCOLN HANCOCK",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,272.91",
          "BranchNumber": 7,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "LINCOLN HANCOCK"
        },
        {
          "WorkOrder": 4068005,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Ann Arbor Addition",
          "JobNumber": "502-967000-15",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2343 Estates Ct.",
          "JobAddress2": "",
          "JobFullAddress": "2343 Estates Ct.",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRENNERS DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$998.05",
          "BranchNumber": 7,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "BRENNERS DRYWALL"
        },
        {
          "WorkOrder": 4068004,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GRAND BLANC HOTEL",
          "JobNumber": "502-1629870-2",
          "FirstdayontheJob": "10-05-2020",
          "LastdayontheJob": "10-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9020 N HOLLY RD.",
          "JobAddress2": "",
          "JobFullAddress": "9020 N HOLLY RD.",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "GB CAPITAL LLC / PINNACLE HOSPITALITY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$18,492.99",
          "BranchNumber": 1,
          "SubmitDate": "10-06-2020",
          "ClientonJob": "GB CAPITAL LLC / PINNACLE HOSPITALITY"
        },
        {
          "WorkOrder": 4066777,
          "ParentWorkOrder": 4062706,
          "JobName": "(NOF)SHEPHERD LAKES LUTH",
          "JobNumber": "502-21350-63",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16214 LINDEN RD",
          "JobAddress2": "",
          "JobFullAddress": "16214 LINDEN RD",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "ERICKSON & LINDSTROM Construction Group/E&L CONSTRUCTION GROUP INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,020.40",
          "BranchNumber": 8,
          "SubmitDate": "10-01-2020",
          "ClientonJob": "ERICKSON & LINDSTROM Construction Group/E&L CONSTRUCTION GROUP INC"
        },
        {
          "WorkOrder": 4066310,
          "ParentWorkOrder": 4049680,
          "JobName": "(NOF)Panda Exp.-Southgate",
          "JobNumber": "502-858231-54",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16240 Fort St",
          "JobAddress2": "",
          "JobFullAddress": "16240 Fort St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,222.54",
          "BranchNumber": 11,
          "SubmitDate": "09-30-2020",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 4066101,
          "ParentWorkOrder": 4062713,
          "JobName": "(NOF)20-2001 Universal",
          "JobNumber": "502-815250-378",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4440 Wyoming St",
          "JobAddress2": "",
          "JobFullAddress": "4440 Wyoming St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,561.97",
          "BranchNumber": 8,
          "SubmitDate": "09-29-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4066001,
          "ParentWorkOrder": "",
          "JobName": "FESTO",
          "JobNumber": "65-2134688-21",
          "FirstdayontheJob": "09-28-2020",
          "LastdayontheJob": "09-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7777 COLUMBIA RD",
          "JobAddress2": "",
          "JobFullAddress": "7777 COLUMBIA RD",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVICES LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,658.56",
          "BranchNumber": 12,
          "SubmitDate": "09-29-2020",
          "ClientonJob": "BENCO CONSTRUCTION SERVICES LLC"
        },
        {
          "WorkOrder": 4066000,
          "ParentWorkOrder": "",
          "JobName": "BETHANY VILLAGE",
          "JobNumber": "65-501291-4",
          "FirstdayontheJob": "09-28-2020",
          "LastdayontheJob": "09-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6443 FAR HILLS AVE",
          "JobAddress2": "",
          "JobFullAddress": "6443 FAR HILLS AVE",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "BOSSE DRYWALL, INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$6,516.55",
          "BranchNumber": 2,
          "SubmitDate": "09-29-2020",
          "ClientonJob": "BOSSE DRYWALL, INC"
        },
        {
          "WorkOrder": 4065844,
          "ParentWorkOrder": 4041608,
          "JobName": "(NOF)Vanguard Career CNTR",
          "JobNumber": "502-1305825-28",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1306 Cedar Street",
          "JobAddress2": "",
          "JobFullAddress": "1306 Cedar Street",
          "JobCounty": "Sandusky",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,343.89",
          "BranchNumber": 13,
          "SubmitDate": "09-28-2020",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 4064549,
          "ParentWorkOrder": "",
          "JobName": "(NOF)THERMO FISHER",
          "JobNumber": "502-1650650-12",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "920 HENRY STREET",
          "JobAddress2": "",
          "JobFullAddress": "920 HENRY STREET",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDUSTRIAL TECH SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$4,903.23",
          "BranchNumber": 11,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "INDUSTRIAL TECH SERVICES"
        },
        {
          "WorkOrder": 4064547,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Michael Knoblock",
          "JobNumber": "502-877250-154",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "401 FRED W MOORE HWY",
          "JobAddress2": "",
          "JobFullAddress": "401 FRED W MOORE HWY",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Cons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$6,950.18",
          "BranchNumber": 11,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "Blue Water Drywall & Cons"
        },
        {
          "WorkOrder": 4064546,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CVS HEALTH #8002",
          "JobNumber": "502-811591-13",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 W WEST MAPLE RD",
          "JobAddress2": "",
          "JobFullAddress": "100 W WEST MAPLE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Fortney & Weygandt, Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,506.00",
          "BalanceRemaining": "$2,198.05",
          "BranchNumber": 11,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "Fortney & Weygandt, Inc."
        },
        {
          "WorkOrder": 4064545,
          "ParentWorkOrder": "",
          "JobName": "(NOF) OCTATHARMA",
          "JobNumber": "502-948945-61",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31079 Harper Ave",
          "JobAddress2": "",
          "JobFullAddress": "31079 Harper Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trinity Building Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$5,819.98",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "Trinity Building Group"
        },
        {
          "WorkOrder": 4064543,
          "ParentWorkOrder": "",
          "JobName": "(NOF)STERLING HTS LIBRARY",
          "JobNumber": "502-944100-336",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "40255 DODGE PARK RD",
          "JobAddress2": "",
          "JobFullAddress": "40255 DODGE PARK RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$10,455.20",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4064542,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BioLife Plasma Serv.",
          "JobNumber": "502-934634-19",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3725 Carpenter Rd",
          "JobAddress2": "",
          "JobFullAddress": "3725 Carpenter Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONSTRUCTION GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "BLUESTONE CONSTRUCTION GROUP"
        },
        {
          "WorkOrder": 4064541,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TACO BELL RICHMOND",
          "JobNumber": "502-920300-99",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "67556 S MAIN STREET",
          "JobAddress2": "",
          "JobFullAddress": "67556 S MAIN STREET",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "GEORGE H PASTOR & SONS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "GEORGE H PASTOR & SONS"
        },
        {
          "WorkOrder": 4064540,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Taco Bell Wapakonet",
          "JobNumber": "502-916315-184",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "903 Apollo Drive",
          "JobAddress2": "",
          "JobFullAddress": "903 Apollo Drive",
          "JobCounty": "AUGLAIZE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Independence Commercial Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$194.38",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "Independence Commercial Construction"
        },
        {
          "WorkOrder": 4064539,
          "ParentWorkOrder": "",
          "JobName": "(NOF)INFUSION STE ST JOHN",
          "JobNumber": "502-913820-585",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "47601 GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "47601 GRAND RIVER AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$426.88",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4064538,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MT. HOPE VET",
          "JobNumber": "502-913400-29",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1114 E Mount Hope Ave",
          "JobAddress2": "",
          "JobFullAddress": "1114 E Mount Hope Ave",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "DANCO CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$2,716.85",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "DANCO CONTRACTING, INC."
        },
        {
          "WorkOrder": 4064537,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WHISTLE STOP SUNOCO",
          "JobNumber": "502-910621-49",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3670 N Branch Rd",
          "JobAddress2": "",
          "JobFullAddress": "3670 N Branch Rd",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$841.15",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 4064536,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Thai Rose",
          "JobNumber": "502-1652637-2",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4409 Interpark Dr",
          "JobAddress2": "",
          "JobFullAddress": "4409 Interpark Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HANS & ASSOCIATES LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "HANS & ASSOCIATES LLC"
        },
        {
          "WorkOrder": 4064535,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CLEAN HUB CAR WASH",
          "JobNumber": "502-1605101-10",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34180 VANDYKE AVE",
          "JobAddress2": "",
          "JobFullAddress": "34180 VANDYKE AVE",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ARCHITECTURAL PLANNERS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,074.63",
          "BranchNumber": 8,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "ARCHITECTURAL PLANNERS"
        },
        {
          "WorkOrder": 4064534,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LASCO RESIDENCE",
          "JobNumber": "502-1604171-24",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2485 MARGARET DR",
          "JobAddress2": "",
          "JobFullAddress": "2485 MARGARET DR",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,889.77",
          "BranchNumber": 8,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4064533,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Panda Express Sgate",
          "JobNumber": "502-876650-363",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16100 Fort St",
          "JobAddress2": "",
          "JobFullAddress": "16100 Fort St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,295.38",
          "BranchNumber": 8,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4064532,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Commerce Catholic CU",
          "JobNumber": "502-823300-80",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7875 Cooley Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "7875 Cooley Lake Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$491.34",
          "BranchNumber": 8,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 4064531,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GARNER ELEMENTARY",
          "JobNumber": "502-21350-65",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10271 N CLIO RD",
          "JobAddress2": "",
          "JobFullAddress": "10271 N CLIO RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ERICKSON&LINDSTROM CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,852.90",
          "BranchNumber": 8,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "ERICKSON&LINDSTROM CONST."
        },
        {
          "WorkOrder": 4064530,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Hamtramck",
          "JobNumber": "502-1606296-13",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2500 E Grand Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2500 E Grand Blvd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Regis Construction, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "Regis Construction, LLC"
        },
        {
          "WorkOrder": 4064529,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WATTLES CREEK APTS",
          "JobNumber": "502-1601205-2",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3930 OLD CREEK RD",
          "JobAddress2": "UNIT 3934",
          "JobFullAddress": "3930 OLD CREEK RD UNIT 3934",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "LINCOLN HANCOCK RESTORATION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,360.51",
          "BranchNumber": 7,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "LINCOLN HANCOCK RESTORATION"
        },
        {
          "WorkOrder": 4064528,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LaQUINTA HOTEL",
          "JobNumber": "502-990401-67",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "52554 SR-933",
          "JobAddress2": "",
          "JobFullAddress": "52554 SR-933",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$11,968.69",
          "BranchNumber": 7,
          "SubmitDate": "",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4064527,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KANAN RESIDENCE",
          "JobNumber": "502-967000-14",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2188 SUNSET PARK DR",
          "JobAddress2": "",
          "JobFullAddress": "2188 SUNSET PARK DR",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRENNERS DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$295.69",
          "BranchNumber": 7,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "BRENNERS DRYWALL"
        },
        {
          "WorkOrder": 4064526,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Vanassche Basement",
          "JobNumber": "502-708250-9",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10732 Chase Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "10732 Chase Lake Rd",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "A4H Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,312.33",
          "BranchNumber": 7,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "A4H Construction LLC"
        },
        {
          "WorkOrder": 4064525,
          "ParentWorkOrder": "",
          "JobName": "(NOF_ Andy's Place Apts.",
          "JobNumber": "502-230032-30",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2388 W Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "2388 W Michigan Ave",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "DSI ACOUSTICAL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "DSI ACOUSTICAL"
        },
        {
          "WorkOrder": 4064524,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Domino's Davison",
          "JobNumber": "502-1602000-36",
          "FirstdayontheJob": "09-22-2020",
          "LastdayontheJob": "09-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "825 S State St",
          "JobAddress2": "Unit 1",
          "JobFullAddress": "825 S State St Unit 1",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Construction, Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$217.26",
          "BranchNumber": 1,
          "SubmitDate": "09-23-2020",
          "ClientonJob": "Great Lakes Bay Construction, Inc."
        },
        {
          "WorkOrder": 4064032,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Oak Leaf",
          "JobNumber": "502-1609034-50",
          "FirstdayontheJob": "09-21-2020",
          "LastdayontheJob": "09-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4230 N Holland Sylvania Rd",
          "JobAddress2": "",
          "JobFullAddress": "4230 N Holland Sylvania Rd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$517.79",
          "BranchNumber": 13,
          "SubmitDate": "09-22-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4064031,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Anspach Law Office",
          "JobNumber": "502-1399009-6",
          "FirstdayontheJob": "09-21-2020",
          "LastdayontheJob": "09-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "300 MADISON AVE #1600",
          "JobAddress2": "",
          "JobFullAddress": "300 MADISON AVE #1600",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Canaan Construction Inc.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,337.85",
          "BranchNumber": 13,
          "SubmitDate": "09-22-2020",
          "ClientonJob": "Canaan Construction Inc."
        },
        {
          "WorkOrder": 4064030,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WALGREENS",
          "JobNumber": "502-134220-8",
          "FirstdayontheJob": "09-21-2020",
          "LastdayontheJob": "09-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3270 W Silver Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "3270 W Silver Lake Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "HORIZON RETAIL CONSTRUCT",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$11,716.44",
          "BranchNumber": 13,
          "SubmitDate": "09-22-2020",
          "ClientonJob": "HORIZON RETAIL CONSTRUCT"
        },
        {
          "WorkOrder": 4064029,
          "ParentWorkOrder": "",
          "JobName": "HCCAO",
          "JobNumber": "65-422686-2",
          "FirstdayontheJob": "09-21-2020",
          "LastdayontheJob": "09-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1300 JEFFERSON ST",
          "JobAddress2": "",
          "JobFullAddress": "1300 JEFFERSON ST",
          "JobCounty": "HIGHLAND",
          "JobState": "Ohio",
          "CustomerCompanyName": "WOODEN IT BE NICE LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$11,277.87",
          "BranchNumber": 4,
          "SubmitDate": "09-22-2020",
          "ClientonJob": "WOODEN IT BE NICE LLC"
        },
        {
          "WorkOrder": 4064028,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FRIENDSHIP VILLAGE",
          "JobNumber": "65-412360-19",
          "FirstdayontheJob": "09-21-2020",
          "LastdayontheJob": "09-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6000 RIVERSIDE DR",
          "JobAddress2": "",
          "JobFullAddress": "6000 RIVERSIDE DR",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Liberty General Contracting Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$709.73",
          "BranchNumber": 4,
          "SubmitDate": "09-22-2020",
          "ClientonJob": "Liberty General Contracting Inc"
        },
        {
          "WorkOrder": 4064027,
          "ParentWorkOrder": "",
          "JobName": "CREATIVE ON CALL",
          "JobNumber": "65-510300-58",
          "FirstdayontheJob": "09-21-2020",
          "LastdayontheJob": "09-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4420 COOPER RD SUITE 140",
          "JobAddress2": "",
          "JobFullAddress": "4420 COOPER RD SUITE 140",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,744.20",
          "BranchNumber": 2,
          "SubmitDate": "09-22-2020",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4064026,
          "ParentWorkOrder": "",
          "JobName": "NOF UHAUL",
          "JobNumber": "65-505800-12",
          "FirstdayontheJob": "09-21-2020",
          "LastdayontheJob": "09-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "751 CHAMBER DR",
          "JobAddress2": "",
          "JobFullAddress": "751 CHAMBER DR",
          "JobCounty": "CLERMONT",
          "JobState": "Ohio",
          "CustomerCompanyName": "KIESLAND DEVELOPMENT SERVICES LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$196,341.69",
          "BranchNumber": 2,
          "SubmitDate": "09-22-2020",
          "ClientonJob": "KIESLAND DEVELOPMENT SERVICES LLC"
        },
        {
          "WorkOrder": 4063027,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dunkin Donuts",
          "JobNumber": "502-1304186-64",
          "FirstdayontheJob": "09-16-2020",
          "LastdayontheJob": "09-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "499 State Route 103",
          "JobAddress2": "",
          "JobFullAddress": "499 State Route 103",
          "JobCounty": "HANCOCK",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,641.17",
          "BranchNumber": 13,
          "SubmitDate": "09-17-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4063026,
          "ParentWorkOrder": "",
          "JobName": "TCH 3 WEST LTAC",
          "JobNumber": "65-2134669-8",
          "FirstdayontheJob": "09-16-2020",
          "LastdayontheJob": "09-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2139 AUBURN AVE",
          "JobAddress2": "",
          "JobFullAddress": "2139 AUBURN AVE",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "MCCOOL PLASTER AND DRYWAL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,121.12",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "MCCOOL PLASTER AND DRYWAL"
        },
        {
          "WorkOrder": 4063025,
          "ParentWorkOrder": "",
          "JobName": "ICE",
          "JobNumber": "65-124880-4",
          "FirstdayontheJob": "09-16-2020",
          "LastdayontheJob": "09-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9875 REDHILL DR",
          "JobAddress2": "",
          "JobFullAddress": "9875 REDHILL DR",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "DAVID BRUNEMANN",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$22,457.39",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "DAVID BRUNEMANN"
        },
        {
          "WorkOrder": 4062761,
          "ParentWorkOrder": 4055480,
          "JobName": "(NOF) 20-2035 DTE 22 FL",
          "JobNumber": "502-815250-376",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Plaza",
          "JobAddress2": "",
          "JobFullAddress": "1 Energy Plaza",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$1,389.96",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4062755,
          "ParentWorkOrder": 4049676,
          "JobName": "(NOF) Westwood Inn",
          "JobNumber": "502-933550-185",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19759 23 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "19759 23 Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$2,827.66",
          "BranchNumber": 9,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4062751,
          "ParentWorkOrder": 4054634,
          "JobName": "(NOF)Aspen Dental Okemos",
          "JobNumber": "502-1601244-4",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2085 W Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "2085 W Grand River Ave",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "PAULON CONSTRUCTION MANAGEMENT CORP",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,098.80",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "PAULON CONSTRUCTION MANAGEMENT CORP"
        },
        {
          "WorkOrder": 4062733,
          "ParentWorkOrder": 4047688,
          "JobName": "(NOF) MEIJER - HOWELL",
          "JobNumber": "502-811817-12",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3883 E Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "3883 E Grand River Ave",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 4062729,
          "ParentWorkOrder": 4049673,
          "JobName": "(NOF)Aspen Dental",
          "JobNumber": "502-1601244-3",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20891 East 13 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "20891 East 13 Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "PAULON CONSTRUCTION MANAGEMENT CORP",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,911.61",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "PAULON CONSTRUCTION MANAGEMENT CORP"
        },
        {
          "WorkOrder": 4062722,
          "ParentWorkOrder": 4055478,
          "JobName": "(NOF) Ford CEC",
          "JobNumber": "502-1606296-12",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1151 Village Rd.",
          "JobAddress2": "",
          "JobFullAddress": "1151 Village Rd.",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Regis Construction, LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "Regis Construction, LLC"
        },
        {
          "WorkOrder": 4062721,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Bluffs Lot 17",
          "JobNumber": "502-18879-32",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "612 Overlook Drive",
          "JobAddress2": "",
          "JobFullAddress": "612 Overlook Drive",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SAVYER DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$5,754.45",
          "BranchNumber": 11,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "SAVYER DRYWALL LLC"
        },
        {
          "WorkOrder": 4062720,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Battle Creek",
          "JobNumber": "502-1161763-4",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5420 Beckly Rd",
          "JobAddress2": "Suite H",
          "JobFullAddress": "5420 Beckly Rd Suite H",
          "JobCounty": "CALHOUN",
          "JobState": "Michigan",
          "CustomerCompanyName": "CZACH PROPERTIES LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$2,718.74",
          "BranchNumber": 9,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "CZACH PROPERTIES LLC"
        },
        {
          "WorkOrder": 4062719,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BANNER SIGN PH4",
          "JobNumber": "502-916315-180",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6538 RUSSEL ST",
          "JobAddress2": "",
          "JobFullAddress": "6538 RUSSEL ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commercial Construction, Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,246.13",
          "BranchNumber": 9,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "Independence Commercial Construction, Inc"
        },
        {
          "WorkOrder": 4062718,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CLEAN JUICE # 1624",
          "JobNumber": "502-913820-587",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "148 PIERCE ST",
          "JobAddress2": "",
          "JobFullAddress": "148 PIERCE ST",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$1,189.32",
          "BranchNumber": 9,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4062717,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MAX MARA # 1621",
          "JobNumber": "502-913820-584",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "SOMERSET SOUTH",
          "JobAddress2": "",
          "JobFullAddress": "SOMERSET SOUTH",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$1,465.21",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4062716,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BIOLIFE PLASMA CNTR",
          "JobNumber": "502-1604171-23",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29959 PLYMOUTH RD",
          "JobAddress2": "",
          "JobFullAddress": "29959 PLYMOUTH RD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$9,886.06",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4062715,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PK Contracting",
          "JobNumber": "502-843701-214",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2411 Xcelsior Dr",
          "JobAddress2": "",
          "JobFullAddress": "2411 Xcelsior Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,433.96",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 4062714,
          "ParentWorkOrder": "",
          "JobName": "(NOF)STERLING HTS RETAIL",
          "JobNumber": "502-815630-242",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35372 Van Dyke Ave",
          "JobAddress2": "",
          "JobFullAddress": "35372 Van Dyke Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$22,211.79",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4062713,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-2001 Universal",
          "JobNumber": "502-815250-378",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4440 Wyoming St",
          "JobAddress2": "",
          "JobFullAddress": "4440 Wyoming St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,561.97",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4062712,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NEXCOR INTERIOR",
          "JobNumber": "502-815250-377",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "36555 26 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "36555 26 MILE RD",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$5,125.38",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4062711,
          "ParentWorkOrder": "",
          "JobName": "(NOF)JAIL CUP- UTILITY",
          "JobNumber": "502-812350-96",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5560 RIVARD ST.",
          "JobAddress2": "JOB# 200114",
          "JobFullAddress": "5560 RIVARD ST. JOB# 200114",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$20,148.74",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4062710,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SHERIFF/ADMIN BLDG.",
          "JobNumber": "502-812350-95",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5560 RIVARD ST",
          "JobAddress2": "",
          "JobFullAddress": "5560 RIVARD ST",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$6,745.04",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4062709,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BELLE TIRE#73BAYCITY",
          "JobNumber": "502-811150-19",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3930 WILDER RD",
          "JobAddress2": "",
          "JobFullAddress": "3930 WILDER RD",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTICAL SYSTEMS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,689.37",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "ACOUSTICAL SYSTEMS"
        },
        {
          "WorkOrder": 4062708,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FITZGERALD ADMIN",
          "JobNumber": "502-164068-2",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23200 RYAN RD",
          "JobAddress2": "",
          "JobFullAddress": "23200 RYAN RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PGC DEVELOPMENT",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$7,845.45",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "PGC DEVELOPMENT"
        },
        {
          "WorkOrder": 4062707,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ZF AUTOMOTIVE",
          "JobNumber": "502-21350-64",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9475 CENTER ROAD",
          "JobAddress2": "",
          "JobFullAddress": "9475 CENTER ROAD",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "ERICKSON & LINDSTROM Construction Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,884.72",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "ERICKSON & LINDSTROM Construction Group"
        },
        {
          "WorkOrder": 4062706,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SHEPHERD LAKES LUTH",
          "JobNumber": "502-21350-63",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16214 LINDEN RD",
          "JobAddress2": "",
          "JobFullAddress": "16214 LINDEN RD",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "ERICKSON & LINDSTROM Construction Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,020.40",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "ERICKSON & LINDSTROM Construction Group"
        },
        {
          "WorkOrder": 4062705,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GRCC FINKELSTEIN",
          "JobNumber": "502-51950-45",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "143 BOSTWICK AVE NE",
          "JobAddress2": "",
          "JobFullAddress": "143 BOSTWICK AVE NE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "SOBIE COMPANY, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "SOBIE COMPANY, INC."
        },
        {
          "WorkOrder": 4062704,
          "ParentWorkOrder": "",
          "JobName": "(NOF)COMERICA ORCHESTRA",
          "JobNumber": "502-1162526-2",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3663 WOODWARD AVE",
          "JobAddress2": "",
          "JobFullAddress": "3663 WOODWARD AVE",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "ALAN JAMES CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$186.72",
          "BranchNumber": 1,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "ALAN JAMES CONSTRUCTION"
        },
        {
          "WorkOrder": 4062703,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MARINE INDUSTRIES",
          "JobNumber": "502-30978-13",
          "FirstdayontheJob": "09-15-2020",
          "LastdayontheJob": "09-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2900 BOYNE RD",
          "JobAddress2": "",
          "JobFullAddress": "2900 BOYNE RD",
          "JobCounty": "SANILAC",
          "JobState": "Michigan",
          "CustomerCompanyName": "Kolar Brothers Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,956.00",
          "BalanceRemaining": "$4,707.21",
          "BranchNumber": 1,
          "SubmitDate": "09-16-2020",
          "ClientonJob": "Kolar Brothers Construction"
        },
        {
          "WorkOrder": 4062192,
          "ParentWorkOrder": 4059541,
          "JobName": "(NOF) Urban Air",
          "JobNumber": "502-1609034-49",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5243 Airport Highway",
          "JobAddress2": "",
          "JobFullAddress": "5243 Airport Highway",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,571.31",
          "BranchNumber": 13,
          "SubmitDate": "09-14-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4062100,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Chase Bank Sylvania",
          "JobNumber": "502-1304700-25",
          "FirstdayontheJob": "09-13-2020",
          "LastdayontheJob": "09-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5700 Monroe St",
          "JobAddress2": "",
          "JobFullAddress": "5700 Monroe St",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "LATHROP COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "09-14-2020",
          "ClientonJob": "LATHROP COMPANY"
        },
        {
          "WorkOrder": 4062099,
          "ParentWorkOrder": "",
          "JobName": "TCH 3 WEST LTAC",
          "JobNumber": "65-2134669-8",
          "FirstdayontheJob": "09-13-2020",
          "LastdayontheJob": "09-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2139 AUBURN AVE",
          "JobAddress2": "",
          "JobFullAddress": "2139 AUBURN AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "MCCOOL PLASTER AND DRYWAL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,121.12",
          "BranchNumber": 12,
          "SubmitDate": "09-14-2020",
          "ClientonJob": "MCCOOL PLASTER AND DRYWAL"
        },
        {
          "WorkOrder": 4062098,
          "ParentWorkOrder": "",
          "JobName": "ICE",
          "JobNumber": "65-124880-4",
          "FirstdayontheJob": "09-13-2020",
          "LastdayontheJob": "09-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9875 Redhill Dr",
          "JobAddress2": "",
          "JobFullAddress": "9875 Redhill Dr",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "DAVID BRUNEMANN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$22,457.39",
          "BranchNumber": 12,
          "SubmitDate": "09-14-2020",
          "ClientonJob": "DAVID BRUNEMANN CONSTRUCTION"
        },
        {
          "WorkOrder": 4060775,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Gold Shield Services",
          "JobNumber": "502-861507-63",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45910 Private Shore Dr",
          "JobAddress2": "",
          "JobFullAddress": "45910 Private Shore Dr",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "LARRY CLEVELAND",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$4,183.13",
          "BranchNumber": 11,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "LARRY CLEVELAND"
        },
        {
          "WorkOrder": 4060774,
          "ParentWorkOrder": "",
          "JobName": "(NOF)4773 Lakeshore",
          "JobNumber": "502-859551-29",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4773 Lakeshore Rd",
          "JobAddress2": "",
          "JobFullAddress": "4773 Lakeshore Rd",
          "JobCounty": "Sanilac",
          "JobState": "Michigan",
          "CustomerCompanyName": "PICKLEHAUPT DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$3,637.84",
          "BranchNumber": 11,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "PICKLEHAUPT DRYWALL"
        },
        {
          "WorkOrder": 4060773,
          "ParentWorkOrder": "",
          "JobName": "(NOF)COMMERCIAL NEW HUDSO",
          "JobNumber": "502-950450-20",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30764 Lyon Center Dr E",
          "JobAddress2": "",
          "JobFullAddress": "30764 Lyon Center Dr E",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "William West Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,806.00",
          "BalanceRemaining": "$7,389.54",
          "BranchNumber": 9,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "William West Inc."
        },
        {
          "WorkOrder": 4060772,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MULBERRY PLAZA RETAI",
          "JobNumber": "502-949575-125",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14605 NORTHLINE RD",
          "JobAddress2": "",
          "JobFullAddress": "14605 NORTHLINE RD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$18,052.80",
          "BranchNumber": 9,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4060771,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Chesterfield Pump",
          "JobNumber": "502-948945-60",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "55800 Gratiot Ave",
          "JobAddress2": "",
          "JobFullAddress": "55800 Gratiot Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trinity Building Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$1,696.00",
          "BranchNumber": 9,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "Trinity Building Group"
        },
        {
          "WorkOrder": 4060770,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Toll Brothers",
          "JobNumber": "502-944100-343",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26200 Town Center Dr",
          "JobAddress2": "Ste 200",
          "JobFullAddress": "26200 Town Center Dr Ste 200",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,703.00",
          "BranchNumber": 9,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4060769,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BILLINGS JOB",
          "JobNumber": "502-932775-67",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "221 NORTH MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "221 NORTH MAIN ST",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TLA INTERIORS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$965.06",
          "BranchNumber": 9,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "TLA INTERIORS, INC."
        },
        {
          "WorkOrder": 4060768,
          "ParentWorkOrder": "",
          "JobName": "(NOF)UPTOWN ANN ARBOR APT",
          "JobNumber": "502-1605903-3",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3300 Ann Arbor Saline Rd",
          "JobAddress2": "",
          "JobFullAddress": "3300 Ann Arbor Saline Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLOVER CONSTRUCTION SERVICES INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$350,000.00",
          "BalanceRemaining": "$2,850.94",
          "BranchNumber": 8,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "CLOVER CONSTRUCTION SERVICES INC"
        },
        {
          "WorkOrder": 4060767,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MARK MIGGINIS",
          "JobNumber": "502-1604171-22",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8254 Sashabaw Ridge Dr",
          "JobAddress2": "",
          "JobFullAddress": "8254 Sashabaw Ridge Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,201.66",
          "BranchNumber": 8,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4060766,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MD MCKENNA REALTY",
          "JobNumber": "502-876650-362",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "123 S MAIN STREET",
          "JobAddress2": "",
          "JobFullAddress": "123 S MAIN STREET",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,954.75",
          "BranchNumber": 8,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4060765,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Fifth-Third Bank",
          "JobNumber": "502-876650-360",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13741 Hall Rd",
          "JobAddress2": "",
          "JobFullAddress": "13741 Hall Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$6,417.58",
          "BranchNumber": 8,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4060764,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Delta Technology",
          "JobNumber": "502-820500-41",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1350 Harmon Rd",
          "JobAddress2": "",
          "JobFullAddress": "1350 Harmon Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEE CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$1,047.96",
          "BranchNumber": 8,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "LEE CONTRACTING"
        },
        {
          "WorkOrder": 4060763,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DR SULIMAN DENIST",
          "JobNumber": "502-815630-243",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9834 CONANT ST",
          "JobAddress2": "",
          "JobFullAddress": "9834 CONANT ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$136.17",
          "BranchNumber": 8,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4060762,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WTAP Sawtooth Roof",
          "JobNumber": "502-813200-143",
          "FirstdayontheJob": "09-09-2020",
          "LastdayontheJob": "09-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21500 Mound Rd",
          "JobAddress2": "",
          "JobFullAddress": "21500 Mound Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Contracting Corporation",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$350,006.00",
          "BalanceRemaining": "$31,039.31",
          "BranchNumber": 8,
          "SubmitDate": "09-10-2020",
          "ClientonJob": "Commercial Contracting Corporation"
        },
        {
          "WorkOrder": 4060277,
          "ParentWorkOrder": 4053552,
          "JobName": "NOF WILKOMMEN APTS",
          "JobNumber": "65-2134999-17",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1606 RACE ST",
          "JobAddress2": "",
          "JobFullAddress": "1606 RACE ST",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "REYES/TDR INTERIORS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$47.19",
          "BranchNumber": 12,
          "SubmitDate": "09-08-2020",
          "ClientonJob": "REYES/TDR INTERIORS"
        },
        {
          "WorkOrder": 4060276,
          "ParentWorkOrder": 4054642,
          "JobName": "(NOF) Panda Ex-Caledonia",
          "JobNumber": "502-858231-55",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1717 Market Place Dr",
          "JobAddress2": "",
          "JobFullAddress": "1717 Market Place Dr",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,254.77",
          "BranchNumber": 11,
          "SubmitDate": "09-08-2020",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 4060274,
          "ParentWorkOrder": 4053550,
          "JobName": "MADEIRA HIGHSCHOOL",
          "JobNumber": "65-125599-4",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7465 LOANNES DR",
          "JobAddress2": "",
          "JobFullAddress": "7465 LOANNES DR",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "ADVANCED ACOUSTICAL LLC",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$5,305.44",
          "BranchNumber": 12,
          "SubmitDate": "09-08-2020",
          "ClientonJob": "ADVANCED ACOUSTICAL LLC"
        },
        {
          "WorkOrder": 4059541,
          "ParentWorkOrder": 4053554,
          "JobName": "(NOF) Urban Air",
          "JobNumber": "502-1609034-49",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5243 Airport Highway",
          "JobAddress2": "",
          "JobFullAddress": "5243 Airport Highway",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,571.31",
          "BranchNumber": 13,
          "SubmitDate": "09-04-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4059055,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Detroit Metro Airpt",
          "JobNumber": "502-858231-53",
          "FirstdayontheJob": "09-02-2020",
          "LastdayontheJob": "09-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11050 Rogell Dr #602",
          "JobAddress2": "",
          "JobFullAddress": "11050 Rogell Dr #602",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONSTRUCTION., LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,030.36",
          "BranchNumber": 11,
          "SubmitDate": "09-03-2020",
          "ClientonJob": "MIDWEST METAL CONSTRUCTION., LLC"
        },
        {
          "WorkOrder": 4059054,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Triumph Academy",
          "JobNumber": "502-916315-179",
          "FirstdayontheJob": "09-02-2020",
          "LastdayontheJob": "09-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3000 Vivian Rd",
          "JobAddress2": "",
          "JobFullAddress": "3000 Vivian Rd",
          "JobCounty": "MONROE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$1,675.42",
          "BranchNumber": 9,
          "SubmitDate": "09-03-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4059053,
          "ParentWorkOrder": "",
          "JobName": "(NOF)#1621 MAX MARA",
          "JobNumber": "502-913820-583",
          "FirstdayontheJob": "09-02-2020",
          "LastdayontheJob": "09-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "2800 W Big Beaver Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,748.55",
          "BranchNumber": 9,
          "SubmitDate": "09-03-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4059052,
          "ParentWorkOrder": "",
          "JobName": "(NOF)VIP Homes Condos",
          "JobNumber": "502-1639425-6",
          "FirstdayontheJob": "09-02-2020",
          "LastdayontheJob": "09-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5394 Swan Dr",
          "JobAddress2": "",
          "JobFullAddress": "5394 Swan Dr",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "09-03-2020",
          "ClientonJob": "PREMIER INTERIORS LLC"
        },
        {
          "WorkOrder": 4059051,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Grass Lake House",
          "JobNumber": "502-1606795-2",
          "FirstdayontheJob": "09-02-2020",
          "LastdayontheJob": "09-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14397 S Francisco Rd",
          "JobAddress2": "",
          "JobFullAddress": "14397 S Francisco Rd",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "RIVER CITY INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,597.19",
          "BranchNumber": 7,
          "SubmitDate": "09-03-2020",
          "ClientonJob": "RIVER CITY INTERIORS"
        },
        {
          "WorkOrder": 4059050,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Baker Builders",
          "JobNumber": "502-968000-6",
          "FirstdayontheJob": "09-02-2020",
          "LastdayontheJob": "09-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10940 Waldron Rd",
          "JobAddress2": "",
          "JobFullAddress": "10940 Waldron Rd",
          "JobCounty": "HILLSDALE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MILLER WALLBOARD",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "09-03-2020",
          "ClientonJob": "MILLER WALLBOARD"
        },
        {
          "WorkOrder": 4059049,
          "ParentWorkOrder": "",
          "JobName": "ADVANCED SOLUTIONS",
          "JobNumber": "65-2135211-9",
          "FirstdayontheJob": "09-02-2020",
          "LastdayontheJob": "09-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2050 BYERS RD",
          "JobAddress2": "",
          "JobFullAddress": "2050 BYERS RD",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "GANE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$2,360.81",
          "BranchNumber": 13,
          "SubmitDate": "09-03-2020",
          "ClientonJob": "GANE CONSTRUCTION"
        },
        {
          "WorkOrder": 4059048,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Wilkshire Elem",
          "JobNumber": "502-715336-10",
          "FirstdayontheJob": "09-02-2020",
          "LastdayontheJob": "09-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5750 Academic Way",
          "JobAddress2": "",
          "JobFullAddress": "5750 Academic Way",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Walker Commercial Interiors",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,106.60",
          "BranchNumber": 7,
          "SubmitDate": "09-03-2020",
          "ClientonJob": "Walker Commercial Interiors"
        },
        {
          "WorkOrder": 4059047,
          "ParentWorkOrder": "",
          "JobName": "ACADIA NEW BEHAVIOR",
          "JobNumber": "65-13105-5",
          "FirstdayontheJob": "09-02-2020",
          "LastdayontheJob": "09-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2446 KIPLING AVE",
          "JobAddress2": "",
          "JobFullAddress": "2446 KIPLING AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "INTEGRITY INTERIORS US LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$12,066.41",
          "BranchNumber": 13,
          "SubmitDate": "09-03-2020",
          "ClientonJob": "INTEGRITY INTERIORS US LLC"
        },
        {
          "WorkOrder": 4059046,
          "ParentWorkOrder": "",
          "JobName": "FRIENDSHIP VILLAGE 2.2",
          "JobNumber": "65-412360-42",
          "FirstdayontheJob": "09-02-2020",
          "LastdayontheJob": "09-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6000 RIVERSIDE DR",
          "JobAddress2": "",
          "JobFullAddress": "6000 RIVERSIDE DR",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Liberty General Contracting Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$868.60",
          "BranchNumber": 4,
          "SubmitDate": "09-03-2020",
          "ClientonJob": "Liberty General Contracting Inc"
        },
        {
          "WorkOrder": 4059045,
          "ParentWorkOrder": "",
          "JobName": "DISTRICT AT DEERFIELD",
          "JobNumber": "65-550796-6",
          "FirstdayontheJob": "09-02-2020",
          "LastdayontheJob": "09-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9230 One Deerfield Pl",
          "JobAddress2": "",
          "JobFullAddress": "9230 One Deerfield Pl",
          "JobCounty": "Warren",
          "JobState": "Ohio",
          "CustomerCompanyName": "DE GEORGE CEILINGS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,299.20",
          "BranchNumber": 2,
          "SubmitDate": "09-03-2020",
          "ClientonJob": "DE GEORGE CEILINGS"
        },
        {
          "WorkOrder": 4058639,
          "ParentWorkOrder": 4039959,
          "JobName": "(NOF)Romulus High School",
          "JobNumber": "502-920940-87",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9650 Wayne Rd",
          "JobAddress2": "",
          "JobFullAddress": "9650 Wayne Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Contracting Services, Inc",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$16,006.00",
          "BalanceRemaining": "$15,687.72",
          "BranchNumber": 8,
          "SubmitDate": "09-01-2020",
          "ClientonJob": "Clark Contracting Services, Inc"
        },
        {
          "WorkOrder": 4058348,
          "ParentWorkOrder": 4049675,
          "JobName": "(NOF)Webesto",
          "JobNumber": "502-916331-16",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14200 Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "14200 Haggerty Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "IDEAL CONTRACTING LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$510.39",
          "BranchNumber": 9,
          "SubmitDate": "08-31-2020",
          "ClientonJob": "IDEAL CONTRACTING LLC"
        },
        {
          "WorkOrder": 4057996,
          "ParentWorkOrder": 4055793,
          "JobName": "(NOF)MonteithElem. School",
          "JobNumber": "502-1609003-4",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1275 COOK RD",
          "JobAddress2": "",
          "JobFullAddress": "1275 COOK RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "North American Construction Enterprises, LLC",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,243.39",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "North American Construction Enterprises, LLC"
        },
        {
          "WorkOrder": 4057729,
          "ParentWorkOrder": 4053547,
          "JobName": "GRAPHIC ARTS",
          "JobNumber": "65-421030-4",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "221 S LUDLOW ST",
          "JobAddress2": "",
          "JobFullAddress": "221 S LUDLOW ST",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "Windsor Construct Svc LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,336.50",
          "BranchNumber": 4,
          "SubmitDate": "08-27-2020",
          "ClientonJob": "Windsor Construct Svc LLC"
        },
        {
          "WorkOrder": 4057353,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TOWN PLACE SUITES",
          "JobNumber": "502-1603235-2",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1440 N DIXIE HIGHWAY",
          "JobAddress2": "",
          "JobFullAddress": "1440 N DIXIE HIGHWAY",
          "JobCounty": "MONROE",
          "JobState": "Michigan",
          "CustomerCompanyName": "A1 PAINTING & DECORATING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$4,768.44",
          "BranchNumber": 13,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "A1 PAINTING & DECORATING"
        },
        {
          "WorkOrder": 4057352,
          "ParentWorkOrder": "",
          "JobName": "(   ) HARBOR FREIGHT",
          "JobNumber": "65-130634-2",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1780 W PARK SQUARE",
          "JobAddress2": "",
          "JobFullAddress": "1780 W PARK SQUARE",
          "JobCounty": "Greene",
          "JobState": "Ohio",
          "CustomerCompanyName": "CONSTRUCTION CONCEPTS OF INDY LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$9,417.37",
          "BranchNumber": 13,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "CONSTRUCTION CONCEPTS OF INDY LLC"
        },
        {
          "WorkOrder": 4057351,
          "ParentWorkOrder": "",
          "JobName": "COVER MY MEDS",
          "JobNumber": "65-416969-2",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "910 JOHN ST",
          "JobAddress2": "",
          "JobFullAddress": "910 JOHN ST",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "PAINTING CO",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$1,161.00",
          "BranchNumber": 4,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "PAINTING CO"
        },
        {
          "WorkOrder": 4057350,
          "ParentWorkOrder": "",
          "JobName": "(NOF) John Lindsay",
          "JobNumber": "502-859551-28",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1686 St Clair River Dr",
          "JobAddress2": "",
          "JobFullAddress": "1686 St Clair River Dr",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "PICKLEHAUPT DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$10,398.36",
          "BranchNumber": 11,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "PICKLEHAUPT DRYWALL"
        },
        {
          "WorkOrder": 4057349,
          "ParentWorkOrder": "",
          "JobName": "(NOF) KEN PARKER",
          "JobNumber": "502-847401-156",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "308 N BALDWIN RD",
          "JobAddress2": "",
          "JobFullAddress": "308 N BALDWIN RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$6,220.62",
          "BranchNumber": 11,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4057348,
          "ParentWorkOrder": "",
          "JobName": "(NOF)COMFORT INN-CLARKSTN",
          "JobNumber": "502-1325000-31",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6874 SASHABAW RD",
          "JobAddress2": "",
          "JobFullAddress": "6874 SASHABAW RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$140,006.00",
          "BalanceRemaining": "$14,607.70",
          "BranchNumber": 9,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4057347,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BCBS TOWER 18 BLUE",
          "JobNumber": "502-933550-191",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "600 E LAFAYETTE BLVD 18TH FL",
          "JobAddress2": "",
          "JobFullAddress": "600 E LAFAYETTE BLVD 18TH FL",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,984.44",
          "BranchNumber": 9,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4057346,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PO 8575 PET STE NOVI",
          "JobNumber": "502-919250-5",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "47025 GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "47025 GRAND RIVER AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "T.H.MARSH CONST.CO.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "T.H.MARSH CONST.CO."
        },
        {
          "WorkOrder": 4057345,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GENISYS CREDIT UNION",
          "JobNumber": "502-910868-68",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1611 S ROCHESTER RD",
          "JobAddress2": "",
          "JobFullAddress": "1611 S ROCHESTER RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construct",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$12,623.53",
          "BranchNumber": 9,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "American Family Construct"
        },
        {
          "WorkOrder": 4057344,
          "ParentWorkOrder": "",
          "JobName": "(NOF)REGENCY CLUB APR #22",
          "JobNumber": "502-8997117-25",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26250 REGENCY CLUB CIRCLE",
          "JobAddress2": "",
          "JobFullAddress": "26250 REGENCY CLUB CIRCLE",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "FRONTGATE VENTURES LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$2,540.92",
          "BranchNumber": 8,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "FRONTGATE VENTURES LLC"
        },
        {
          "WorkOrder": 4057343,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Oakland Business Pk",
          "JobNumber": "502-1639418-30",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "180 Premier Dr",
          "JobAddress2": "",
          "JobFullAddress": "180 Premier Dr",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,020.36",
          "BranchNumber": 8,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4057342,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ROC",
          "JobNumber": "502-1604171-20",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29200 Calahan Rd",
          "JobAddress2": "",
          "JobFullAddress": "29200 Calahan Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4057340,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LOCS Webber Elem.",
          "JobNumber": "502-920940-89",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3191 W CLARKSTON RD",
          "JobAddress2": "",
          "JobFullAddress": "3191 W CLARKSTON RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$5,825.18",
          "BranchNumber": 8,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4057339,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Chimney",
          "JobNumber": "502-876650-359",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1110 Timberlake Dr",
          "JobAddress2": "",
          "JobFullAddress": "1110 Timberlake Dr",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$734.80",
          "BranchNumber": 8,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4057338,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ALDI LIVONIA",
          "JobNumber": "502-815630-241",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13900 MIDDLEBELT RD",
          "JobAddress2": "",
          "JobFullAddress": "13900 MIDDLEBELT RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$31,786.49",
          "BranchNumber": 8,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4057337,
          "ParentWorkOrder": "",
          "JobName": "(NOF)COSTCO MIDLAND",
          "JobNumber": "502-20010-34",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4816 BAY CITY RD",
          "JobAddress2": "",
          "JobFullAddress": "4816 BAY CITY RD",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$3,460.03",
          "BranchNumber": 8,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4057336,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FH ORCHARD VIEW",
          "JobNumber": "502-1344933-17",
          "FirstdayontheJob": "08-25-2020",
          "LastdayontheJob": "08-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2770 LEFFINGWELL AVE NE",
          "JobAddress2": "",
          "JobFullAddress": "2770 LEFFINGWELL AVE NE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER 1 INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$525.36",
          "BranchNumber": 5,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "PREMIER 1 INTERIORS LLC"
        },
        {
          "WorkOrder": 4057124,
          "ParentWorkOrder": 4051295,
          "JobName": "(NOF) LANGTOFT",
          "JobNumber": "502-1325000-29",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6721 LANGTOFT ST",
          "JobAddress2": "",
          "JobFullAddress": "6721 LANGTOFT ST",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$9,076.24",
          "BranchNumber": 9,
          "SubmitDate": "08-26-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4056668,
          "ParentWorkOrder": 4052126,
          "JobName": "NOF Hillsboro HS New",
          "JobNumber": "65-2137101-6",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "550 US-62",
          "JobAddress2": "",
          "JobFullAddress": "550 US-62",
          "JobCounty": "HIGHLAND",
          "JobState": "Ohio",
          "CustomerCompanyName": "PERFORMANCE CONTRACTING",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$170,006.00",
          "BalanceRemaining": "$1,880.10",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "PERFORMANCE CONTRACTING"
        },
        {
          "WorkOrder": 4056046,
          "ParentWorkOrder": 4035723,
          "JobName": "(NOF)#1617 TIFFANY & CO",
          "JobNumber": "502-913820-578",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$8,367.70",
          "BranchNumber": 9,
          "SubmitDate": "08-21-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4055793,
          "ParentWorkOrder": 4047683,
          "JobName": "(NOF)MonteithElem. School",
          "JobNumber": "502-1609003-4",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1275 COOK RD",
          "JobAddress2": "",
          "JobFullAddress": "1275 COOK RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "North American Construction Enterprises, LLC",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,243.39",
          "BranchNumber": 9,
          "SubmitDate": "08-21-2020",
          "ClientonJob": "North American Construction Enterprises, LLC"
        },
        {
          "WorkOrder": 4055764,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dollar General",
          "JobNumber": "502-1304186-63",
          "FirstdayontheJob": "08-20-2020",
          "LastdayontheJob": "08-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8145 Hawthorne St",
          "JobAddress2": "",
          "JobFullAddress": "8145 Hawthorne St",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,394.69",
          "BranchNumber": 13,
          "SubmitDate": "08-21-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4055763,
          "ParentWorkOrder": "",
          "JobName": "(   )LIMA MEMORIAL HOSP",
          "JobNumber": "65-2135185-4",
          "FirstdayontheJob": "08-20-2020",
          "LastdayontheJob": "08-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1001 BELLEFONTAINE AVE",
          "JobAddress2": "",
          "JobFullAddress": "1001 BELLEFONTAINE AVE",
          "JobCounty": "ALLEN",
          "JobState": "Ohio",
          "CustomerCompanyName": "HALKER DRYWALL, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$12,373.20",
          "BranchNumber": 12,
          "SubmitDate": "08-21-2020",
          "ClientonJob": "HALKER DRYWALL, INC."
        },
        {
          "WorkOrder": 4055762,
          "ParentWorkOrder": "",
          "JobName": "CITY BARBEQUE",
          "JobNumber": "65-2134688-20",
          "FirstdayontheJob": "08-20-2020",
          "LastdayontheJob": "08-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5168 BLAZER PARKWAY",
          "JobAddress2": "",
          "JobFullAddress": "5168 BLAZER PARKWAY",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$7,540.26",
          "BranchNumber": 12,
          "SubmitDate": "08-21-2020",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 4055761,
          "ParentWorkOrder": "",
          "JobName": "UC MEDICAL ARTS BUILDING",
          "JobNumber": "65-510590-43",
          "FirstdayontheJob": "08-20-2020",
          "LastdayontheJob": "08-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "222 PIEDMONT AVE",
          "JobAddress2": "",
          "JobFullAddress": "222 PIEDMONT AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,610.29",
          "BranchNumber": 2,
          "SubmitDate": "08-21-2020",
          "ClientonJob": "TRIVERSITY CRAFT FORCE LLC"
        },
        {
          "WorkOrder": 4055489,
          "ParentWorkOrder": 4046443,
          "JobName": "(NOF) NORTHVILLE SCHOOLS",
          "JobNumber": "502-981570-16",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "46180 W 9 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "46180 W 9 Mile Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DTS Contracting",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$1,187.90",
          "BranchNumber": 9,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "DTS Contracting"
        },
        {
          "WorkOrder": 4055488,
          "ParentWorkOrder": 4047687,
          "JobName": "(NOF)KROGER G.P.W.",
          "JobNumber": "502-20010-32",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20422 Mack Ave",
          "JobAddress2": "",
          "JobFullAddress": "20422 Mack Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$14,243.07",
          "BranchNumber": 8,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4055487,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Eagle Ridge Lot 16",
          "JobNumber": "502-18879-26",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5401 Aberdeen Ct",
          "JobAddress2": "",
          "JobFullAddress": "5401 Aberdeen Ct",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SAVYER DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$5,844.02",
          "BranchNumber": 11,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "SAVYER DRYWALL LLC"
        },
        {
          "WorkOrder": 4055486,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CREST-1450SILVERBELL",
          "JobNumber": "502-18879-25",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1450 SILVERBELL RD",
          "JobAddress2": "",
          "JobFullAddress": "1450 SILVERBELL RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SAVYER DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$8,874.58",
          "BranchNumber": 11,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "SAVYER DRYWALL LLC"
        },
        {
          "WorkOrder": 4055485,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BULLARD RD",
          "JobNumber": "502-1325000-32",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5747 BULLARD RD",
          "JobAddress2": "",
          "JobFullAddress": "5747 BULLARD RD",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4055484,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Orchard Brook Ph 2",
          "JobNumber": "502-1621700-9",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "57163 Cypress St",
          "JobAddress2": "",
          "JobFullAddress": "57163 Cypress St",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHINGLETON CONTRACTORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "SHINGLETON CONTRACTORS INC"
        },
        {
          "WorkOrder": 4055483,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Mid-Michigan Medical",
          "JobNumber": "502-1604171-19",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2463 S M-30",
          "JobAddress2": "",
          "JobFullAddress": "2463 S M-30",
          "JobCounty": "Ogemaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,418.25",
          "BranchNumber": 8,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4055482,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RADIA RES",
          "JobNumber": "502-1604171-18",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6885 HALYARD RD",
          "JobAddress2": "",
          "JobFullAddress": "6885 HALYARD RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$485.69",
          "BranchNumber": 8,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4055481,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HomeGoods Chesterfie",
          "JobNumber": "502-856071-87",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "I-94 & 59",
          "JobAddress2": "",
          "JobFullAddress": "I-94 & 59",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$15,271.45",
          "BranchNumber": 8,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "M & V CONSTRUCTION"
        },
        {
          "WorkOrder": 4055480,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 20-2035 DTE 22 FL",
          "JobNumber": "502-815250-376",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Plaza",
          "JobAddress2": "",
          "JobFullAddress": "1 Energy Plaza",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$1,389.96",
          "BranchNumber": 8,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4055479,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DG CASS CITY",
          "JobNumber": "502-20010-33",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6129 E CASS CITY RD",
          "JobAddress2": "",
          "JobFullAddress": "6129 E CASS CITY RD",
          "JobCounty": "Tuscola",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$2,640.32",
          "BranchNumber": 8,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4055478,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Ford CEC",
          "JobNumber": "502-1606296-12",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1151 Village Rd.",
          "JobAddress2": "",
          "JobFullAddress": "1151 Village Rd.",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Regis Construction, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "Regis Construction, LLC"
        },
        {
          "WorkOrder": 4055477,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HIGHLAND ESTATES",
          "JobNumber": "502-1668468-12",
          "FirstdayontheJob": "08-19-2020",
          "LastdayontheJob": "08-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Aberdeen Dr",
          "JobAddress2": "",
          "JobFullAddress": "Aberdeen Dr",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "WADE JURNEY HOMES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$2,341.75",
          "BranchNumber": 1,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "WADE JURNEY HOMES"
        },
        {
          "WorkOrder": 4055476,
          "ParentWorkOrder": 4043466,
          "JobName": "(NOF)Dry Goods",
          "JobNumber": "502-934150-67",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "236 N ADAMS RD",
          "JobAddress2": "",
          "JobFullAddress": "236 N ADAMS RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "WOODS CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "08-20-2020",
          "ClientonJob": "WOODS CONSTRUCTION INC"
        },
        {
          "WorkOrder": 4055154,
          "ParentWorkOrder": 4046392,
          "JobName": "PRI-MED",
          "JobNumber": "65-573117-10",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "200 ROCKRIDGE RD",
          "JobAddress2": "",
          "JobFullAddress": "200 ROCKRIDGE RD",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "CORE RESOURCES INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,011.67",
          "BranchNumber": 2,
          "SubmitDate": "08-19-2020",
          "ClientonJob": "CORE RESOURCES INC"
        },
        {
          "WorkOrder": 4054905,
          "ParentWorkOrder": 4049667,
          "JobName": "(NOF) OCTTAPHARMA",
          "JobNumber": "502-940253-4",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "815 W MICHIGAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "815 W MICHIGAN AVE",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "D L P Construction Company INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$3,146.60",
          "BranchNumber": 7,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "D L P Construction Company INC"
        },
        {
          "WorkOrder": 4054902,
          "ParentWorkOrder": 4047664,
          "JobName": "AMAZON DCM5",
          "JobNumber": "65-700193-5",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8591 Mink St SW",
          "JobAddress2": "",
          "JobFullAddress": "8591 Mink St SW",
          "JobCounty": "LICKING",
          "JobState": "Ohio",
          "CustomerCompanyName": "UNITED CONTRACTOR SERVICE",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$9,701.67",
          "BranchNumber": 1,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "UNITED CONTRACTOR SERVICE"
        },
        {
          "WorkOrder": 4054900,
          "ParentWorkOrder": 4046396,
          "JobName": "UC VONTZ CENTER BUILDING",
          "JobNumber": "65-2134915-4",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3125 Eden Ave",
          "JobAddress2": "",
          "JobFullAddress": "3125 Eden Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "LITHKO RESTORATION TECHNO",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$624.63",
          "BranchNumber": 12,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "LITHKO RESTORATION TECHNO"
        },
        {
          "WorkOrder": 4054642,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Panda Ex-Caledonia",
          "JobNumber": "502-858231-55",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1717 Market Place Dr",
          "JobAddress2": "",
          "JobFullAddress": "1717 Market Place Dr",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,254.77",
          "BranchNumber": 11,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 4054641,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Greg",
          "JobNumber": "502-849801-249",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "60553 Pennington Way",
          "JobAddress2": "",
          "JobFullAddress": "60553 Pennington Way",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$4,895.64",
          "BranchNumber": 11,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 4054640,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Tru by Hilton-Troy",
          "JobNumber": "502-847401-154",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1575 E Maple RD",
          "JobAddress2": "",
          "JobFullAddress": "1575 E Maple RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$1,392.23",
          "BranchNumber": 11,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4054639,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CAMBRIA HOTEL PH2",
          "JobNumber": "502-989801-13",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50741 CORPORATE DR",
          "JobAddress2": "",
          "JobFullAddress": "50741 CORPORATE DR",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "RC PLASTERING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$9,580.69",
          "BranchNumber": 9,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "RC PLASTERING, LLC"
        },
        {
          "WorkOrder": 4054638,
          "ParentWorkOrder": "",
          "JobName": "NOF BIOLIFE PLASMA SERV",
          "JobNumber": "502-934634-18",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3725 CARPENTER RD",
          "JobAddress2": "",
          "JobFullAddress": "3725 CARPENTER RD",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONSTRUCTION GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$8,538.89",
          "BranchNumber": 9,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "BLUESTONE CONSTRUCTION GROUP"
        },
        {
          "WorkOrder": 4054637,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Emmons Ave Plaza",
          "JobNumber": "502-917835-13",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1585 East Auburn Rd",
          "JobAddress2": "",
          "JobFullAddress": "1585 East Auburn Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "LASALLE GROUP INC,",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$353.78",
          "BranchNumber": 9,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "LASALLE GROUP INC,"
        },
        {
          "WorkOrder": 4054636,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Crumble Cookies",
          "JobNumber": "502-916315-178",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "155 N Maple Rd # 396",
          "JobAddress2": "",
          "JobFullAddress": "155 N Maple Rd # 396",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commercial Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$1,453.19",
          "BranchNumber": 9,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "Independence Commercial Construction"
        },
        {
          "WorkOrder": 4054635,
          "ParentWorkOrder": "",
          "JobName": "(NOF)NEIGHBORHOOD POLICE",
          "JobNumber": "502-195531-13",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1301 THIRD ST",
          "JobAddress2": "",
          "JobFullAddress": "1301 THIRD ST",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TravCo Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$332.99",
          "BranchNumber": 9,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "TravCo Interiors LLC"
        },
        {
          "WorkOrder": 4054634,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Aspen Dental Okemos",
          "JobNumber": "502-1601244-4",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2085 W Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "2085 W Grand River Ave",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "PAULON CONSTRUCTION MANAGEMENT CORP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,098.80",
          "BranchNumber": 8,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "PAULON CONSTRUCTION MANAGEMENT CORP"
        },
        {
          "WorkOrder": 4054633,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WARREN 10 MILE STATE",
          "JobNumber": "502-876650-358",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13041 E 10 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "13041 E 10 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$6,049.08",
          "BranchNumber": 8,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4054632,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Noah Hershberger",
          "JobNumber": "502-1329127-2",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "28675 V Drive South",
          "JobAddress2": "",
          "JobFullAddress": "28675 V Drive South",
          "JobCounty": "CALHOUN",
          "JobState": "Michigan",
          "CustomerCompanyName": "MILO BEECHY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,063.00",
          "BalanceRemaining": "$4,069.84",
          "BranchNumber": 7,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "MILO BEECHY"
        },
        {
          "WorkOrder": 4054631,
          "ParentWorkOrder": "",
          "JobName": "(NOF)H2 SUITES GRAND BLAN",
          "JobNumber": "502-990401-64",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9044 HOLLY RD",
          "JobAddress2": "",
          "JobFullAddress": "9044 HOLLY RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,616.83",
          "BranchNumber": 7,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4054629,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Sachse New HQ",
          "JobNumber": "502-1161528-2",
          "FirstdayontheJob": "08-16-2020",
          "LastdayontheJob": "08-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3663 WOODWARD AVE",
          "JobAddress2": "",
          "JobFullAddress": "3663 WOODWARD AVE",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "MORREYS CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$259.59",
          "BranchNumber": 6,
          "SubmitDate": "08-17-2020",
          "ClientonJob": "MORREYS CONTRACTING"
        },
        {
          "WorkOrder": 4053554,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Urban Air",
          "JobNumber": "502-1609034-49",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5243 Airport Highway",
          "JobAddress2": "",
          "JobFullAddress": "5243 Airport Highway",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,571.31",
          "BranchNumber": 13,
          "SubmitDate": "08-13-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4053553,
          "ParentWorkOrder": "",
          "JobName": "NOF WPAFB BLDG 45 ADD",
          "JobNumber": "65-502280-67",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5135 PEARSON RD",
          "JobAddress2": "",
          "JobFullAddress": "5135 PEARSON RD",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALTIES INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$638.90",
          "BranchNumber": 13,
          "SubmitDate": "08-13-2020",
          "ClientonJob": "COMBS INTERIOR SPECIALTIES INC."
        },
        {
          "WorkOrder": 4053552,
          "ParentWorkOrder": "",
          "JobName": "NOF WILKOMMEN APTS",
          "JobNumber": "65-2134999-17",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1606 RACE ST",
          "JobAddress2": "",
          "JobFullAddress": "1606 RACE ST",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "REYES/TDR INTERIORS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$47.19",
          "BranchNumber": 12,
          "SubmitDate": "08-13-2020",
          "ClientonJob": "REYES/TDR INTERIORS"
        },
        {
          "WorkOrder": 4053551,
          "ParentWorkOrder": "",
          "JobName": "AES SOC RENOVATION",
          "JobNumber": "65-2134688-19",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1065 WOODMAN DR",
          "JobAddress2": "",
          "JobFullAddress": "1065 WOODMAN DR",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVICE",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$185,000.00",
          "BalanceRemaining": "$14,123.00",
          "BranchNumber": 12,
          "SubmitDate": "08-13-2020",
          "ClientonJob": "BENCO CONSTRUCTION SERVICE"
        },
        {
          "WorkOrder": 4053550,
          "ParentWorkOrder": "",
          "JobName": "MADEIRA HIGHSCHOOL",
          "JobNumber": "65-125599-4",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7465 LOANNES DR",
          "JobAddress2": "",
          "JobFullAddress": "7465 LOANNES DR",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "ADVANCED ACOUSTICAL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$5,305.44",
          "BranchNumber": 12,
          "SubmitDate": "08-13-2020",
          "ClientonJob": "ADVANCED ACOUSTICAL LLC"
        },
        {
          "WorkOrder": 4053549,
          "ParentWorkOrder": "",
          "JobName": "CORR CHOICE",
          "JobNumber": "65-12430-2",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7741 SCHOOL RD",
          "JobAddress2": "",
          "JobFullAddress": "7741 SCHOOL RD",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "NORTH SHORE CONST & DEV",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$5,495.40",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "NORTH SHORE CONST & DEV"
        },
        {
          "WorkOrder": 4053548,
          "ParentWorkOrder": "",
          "JobName": "Kroger - 891",
          "JobNumber": "65-473759-12",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3387 MAPLE AVE",
          "JobAddress2": "",
          "JobFullAddress": "3387 MAPLE AVE",
          "JobCounty": "Muskingum",
          "JobState": "Ohio",
          "CustomerCompanyName": "R&W Fixturing Co Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$5,725.90",
          "BranchNumber": 4,
          "SubmitDate": "08-13-2020",
          "ClientonJob": "R&W Fixturing Co Inc"
        },
        {
          "WorkOrder": 4053547,
          "ParentWorkOrder": "",
          "JobName": "GRAPHIC ARTS",
          "JobNumber": "65-421030-4",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "221 S LUDLOW ST",
          "JobAddress2": "",
          "JobFullAddress": "221 S LUDLOW ST",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "Windsor Construct Svc LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,336.50",
          "BranchNumber": 4,
          "SubmitDate": "08-13-2020",
          "ClientonJob": "Windsor Construct Svc LLC"
        },
        {
          "WorkOrder": 4053546,
          "ParentWorkOrder": "",
          "JobName": "Tropical Smoothie Avon",
          "JobNumber": "65-402000-4",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "36050 DETROIT RD",
          "JobAddress2": "",
          "JobFullAddress": "36050 DETROIT RD",
          "JobCounty": "Lorain",
          "JobState": "Ohio",
          "CustomerCompanyName": "GREAT LAKES BAY CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,000.00",
          "BalanceRemaining": "$4,460.31",
          "BranchNumber": 4,
          "SubmitDate": "08-13-2020",
          "ClientonJob": "GREAT LAKES BAY CONSTRUCTION"
        },
        {
          "WorkOrder": 4053545,
          "ParentWorkOrder": "",
          "JobName": "CHRIST HOSPITAL MOB",
          "JobNumber": "65-516400-4",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8989 WINTON RD",
          "JobAddress2": "",
          "JobFullAddress": "8989 WINTON RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "AL NEYER LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$212.93",
          "BranchNumber": 2,
          "SubmitDate": "08-13-2020",
          "ClientonJob": "AL NEYER LLC"
        },
        {
          "WorkOrder": 4053544,
          "ParentWorkOrder": "",
          "JobName": "NOF CINCINNATI BALLET",
          "JobNumber": "65-510590-44",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1801 GILBERT AVE",
          "JobAddress2": "",
          "JobFullAddress": "1801 GILBERT AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$12,349.76",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "TRIVERSITY CRAFT FORCE"
        },
        {
          "WorkOrder": 4053543,
          "ParentWorkOrder": "",
          "JobName": "(   ) RACHEL CT PROJECT",
          "JobNumber": "65-508631-6",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "185 RACHEL CT",
          "JobAddress2": "",
          "JobFullAddress": "185 RACHEL CT",
          "JobCounty": "Clark",
          "JobState": "Ohio",
          "CustomerCompanyName": "RCS CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 2,
          "SubmitDate": "08-13-2020",
          "ClientonJob": "RCS CONSTRUCTION"
        },
        {
          "WorkOrder": 4053542,
          "ParentWorkOrder": "",
          "JobName": "NOF KROGER-ANDERSON",
          "JobNumber": "65-744000-13",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7580 BEECHMONT AVE",
          "JobAddress2": "",
          "JobFullAddress": "7580 BEECHMONT AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "GEM INTERIORS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,404.46",
          "BranchNumber": 1,
          "SubmitDate": "08-13-2020",
          "ClientonJob": "GEM INTERIORS"
        },
        {
          "WorkOrder": 4053541,
          "ParentWorkOrder": "",
          "JobName": "NOF KROGER HYDE PARK",
          "JobNumber": "65-744000-12",
          "FirstdayontheJob": "08-12-2020",
          "LastdayontheJob": "08-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3760 PAXTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "3760 PAXTON AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "GEM INTERIORS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$6,742.67",
          "BranchNumber": 1,
          "SubmitDate": "08-13-2020",
          "ClientonJob": "GEM INTERIORS"
        },
        {
          "WorkOrder": 4052945,
          "ParentWorkOrder": 4043451,
          "JobName": "NOF MEIJERS 136",
          "JobNumber": "65-48844-6",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1355 N Lexington-Springmill Rd",
          "JobAddress2": "",
          "JobFullAddress": "1355 N Lexington-Springmill Rd",
          "JobCounty": "Richland",
          "JobState": "Ohio",
          "CustomerCompanyName": "Big Sky Service Company LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$2,906.91",
          "BranchNumber": 4,
          "SubmitDate": "08-11-2020",
          "ClientonJob": "Big Sky Service Company LLC"
        },
        {
          "WorkOrder": 4052858,
          "ParentWorkOrder": 4041633,
          "JobName": "(NOF)ALVIN'S RESIDENCE",
          "JobNumber": "502-815630-240",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2955 CHILDS LAKE RD",
          "JobAddress2": "",
          "JobFullAddress": "2955 CHILDS LAKE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,724.74",
          "BranchNumber": 8,
          "SubmitDate": "08-11-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4052850,
          "ParentWorkOrder": 4046444,
          "JobName": "(NOF) ALDI'S  #50",
          "JobNumber": "502-1507798-48",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4189 24th Ave",
          "JobAddress2": "",
          "JobFullAddress": "4189 24th Ave",
          "JobCounty": "St Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$10,444.59",
          "BranchNumber": 9,
          "SubmitDate": "08-11-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4052126,
          "ParentWorkOrder": 4024358,
          "JobName": "NOF Hillsboro HS New",
          "JobNumber": "65-2137101-6",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "550 US-62",
          "JobAddress2": "",
          "JobFullAddress": "550 US-62",
          "JobCounty": "HIGHLAND",
          "JobState": "Ohio",
          "CustomerCompanyName": "PERFORMANCE CONTRACTING",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$170,006.00",
          "BalanceRemaining": "$1,880.10",
          "BranchNumber": 12,
          "SubmitDate": "08-07-2020",
          "ClientonJob": "PERFORMANCE CONTRACTING"
        },
        {
          "WorkOrder": 4052076,
          "ParentWorkOrder": 4047695,
          "JobName": "PENN STATION",
          "JobNumber": "65-53046-3",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1044 Interstate Ct",
          "JobAddress2": "",
          "JobFullAddress": "1044 Interstate Ct",
          "JobCounty": "HANCOCK",
          "JobState": "Ohio",
          "CustomerCompanyName": "CURRAN CONSTRUCTION CO.",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,932.91",
          "BranchNumber": 2,
          "SubmitDate": "08-07-2020",
          "ClientonJob": "CURRAN CONSTRUCTION CO."
        },
        {
          "WorkOrder": 4051303,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Hansen Industries",
          "JobNumber": "502-1609034-48",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2824 N Summit St",
          "JobAddress2": "",
          "JobFullAddress": "2824 N Summit St",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$11,836.62",
          "BranchNumber": 13,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4051302,
          "ParentWorkOrder": "",
          "JobName": "CORR CHOICE",
          "JobNumber": "65-12430-2",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7741 SCHOOL RD",
          "JobAddress2": "",
          "JobFullAddress": "7741 SCHOOL RD",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "North Shore Construction & Development Services",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$3,926.43",
          "BranchNumber": 12,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "North Shore Construction & Development Services"
        },
        {
          "WorkOrder": 4051301,
          "ParentWorkOrder": "",
          "JobName": "NOF CINCINNATI BALLET",
          "JobNumber": "65-510590-44",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1801 GILBERT AVE",
          "JobAddress2": "",
          "JobFullAddress": "1801 GILBERT AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$612.36",
          "BranchNumber": 2,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "TRIVERSITY CRAFT FORCE LLC"
        },
        {
          "WorkOrder": 4051300,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Villas @ Bloomfd Grv",
          "JobNumber": "502-847401-152",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "183 Timber Trace Ln",
          "JobAddress2": "",
          "JobFullAddress": "183 Timber Trace Ln",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,391.72",
          "BranchNumber": 11,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4051299,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CRUNCH FIT-E LANSING",
          "JobNumber": "502-111914-13",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2655 E Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "2655 E Grand River Ave",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Custom Builder",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,366.27",
          "BranchNumber": 11,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "Great Lakes Custom Builder"
        },
        {
          "WorkOrder": 4051298,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BELLE CONE LOT 1",
          "JobNumber": "502-18879-18",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3372 MILDRED AVE",
          "JobAddress2": "",
          "JobFullAddress": "3372 MILDRED AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SAVYER DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "SAVYER DRYWALL LLC"
        },
        {
          "WorkOrder": 4051297,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Brighton House",
          "JobNumber": "502-1604855-9",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "221 Ashford St",
          "JobAddress2": "",
          "JobFullAddress": "221 Ashford St",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "R&V DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,560.75",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "R&V DRYWALL INC"
        },
        {
          "WorkOrder": 4051296,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WALNUT LAKE HOMES",
          "JobNumber": "502-1325000-30",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "285 E LONG LAKE RD",
          "JobAddress2": "",
          "JobFullAddress": "285 E LONG LAKE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4051295,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LANGTOFT",
          "JobNumber": "502-1325000-29",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6721 LANGTOFT ST",
          "JobAddress2": "",
          "JobFullAddress": "6721 LANGTOFT ST",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$9,076.24",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4051294,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BANQUET CENTER",
          "JobNumber": "502-949575-124",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34615 WARREN RD",
          "JobAddress2": "",
          "JobFullAddress": "34615 WARREN RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$10,528.88",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4051293,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ROSE PIONEER",
          "JobNumber": "502-946850-11",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7110 MILFORD RD",
          "JobAddress2": "",
          "JobFullAddress": "7110 MILFORD RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NORTHWEST ACOUSTICAL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "NORTHWEST ACOUSTICAL"
        },
        {
          "WorkOrder": 4051292,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DAVISBURG SCHOOL",
          "JobNumber": "502-946850-10",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12003 DAVISBURG RD",
          "JobAddress2": "",
          "JobFullAddress": "12003 DAVISBURG RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "NORTHWEST ACOUSTICAL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "NORTHWEST ACOUSTICAL"
        },
        {
          "WorkOrder": 4051291,
          "ParentWorkOrder": "",
          "JobName": "(NOF) STAR ACADEMY",
          "JobNumber": "502-944100-342",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45081 Geddes Rd",
          "JobAddress2": "",
          "JobFullAddress": "45081 Geddes Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$7,466.22",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4051290,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ROYAL OAK POLICE",
          "JobNumber": "502-944100-341",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "450 E 11TH MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "450 E 11TH MILE RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$14,025.72",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4051289,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BATTLE CREEK VA MED",
          "JobNumber": "502-944100-339",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5500 ARMSTRONG RD",
          "JobAddress2": "",
          "JobFullAddress": "5500 ARMSTRONG RD",
          "JobCounty": "Calhoun",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$10,198.39",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4051288,
          "ParentWorkOrder": "",
          "JobName": "(NOF) B O T FERNDALE",
          "JobNumber": "502-944100-338",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "221 W Troy St",
          "JobAddress2": "",
          "JobFullAddress": "221 W Troy St",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,822.64",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4051287,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Iafrate",
          "JobNumber": "502-916331-17",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29755 Louis Chevrolet Rd",
          "JobAddress2": "",
          "JobFullAddress": "29755 Louis Chevrolet Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "IDEAL CONTRACTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,346.06",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "IDEAL CONTRACTING LLC"
        },
        {
          "WorkOrder": 4051286,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Signal Restoration",
          "JobNumber": "502-916315-177",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33222 Groesbeck Hwy",
          "JobAddress2": "",
          "JobFullAddress": "33222 Groesbeck Hwy",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMMERVIAL CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$55,006.00",
          "BalanceRemaining": "$15,837.35",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "INDEPENDENCE COMMERVIAL CONSTRUCTION"
        },
        {
          "WorkOrder": 4051285,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BioLife",
          "JobNumber": "502-916315-176",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29959 Plymouth Rd",
          "JobAddress2": "",
          "JobFullAddress": "29959 Plymouth Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commercial Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$170,000.00",
          "BalanceRemaining": "$27,338.00",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "Independence Commercial Construction"
        },
        {
          "WorkOrder": 4051284,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Chicago Deli",
          "JobNumber": "502-916315-175",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8701 E 13 Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "8701 E 13 Mile Road",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commercial Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$4,142.27",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "Independence Commercial Construction"
        },
        {
          "WorkOrder": 4051283,
          "ParentWorkOrder": "",
          "JobName": "(NOF) AAA - LAUREL PARK",
          "JobNumber": "502-910868-65",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "37383 6 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "37383 6 MILE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "American Family Construction Inc"
        },
        {
          "WorkOrder": 4051282,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SHOOT POINT BLANK",
          "JobNumber": "502-910621-47",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "48900 W 12 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "48900 W 12 Mile Rd",
          "JobCounty": "96-22-08-376-018",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceilings & Carpentry LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "All Phases Ceilings & Carpentry LLC"
        },
        {
          "WorkOrder": 4051281,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OMG Longevity Health",
          "JobNumber": "502-843701-217",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "633 South Blvd E",
          "JobAddress2": "",
          "JobFullAddress": "633 South Blvd E",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$2,479.84",
          "BranchNumber": 8,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 4051280,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Ajax Bald Mountain",
          "JobNumber": "502-843701-215",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4883 Bald Mountain Rd",
          "JobAddress2": "",
          "JobFullAddress": "4883 Bald Mountain Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$2,135.44",
          "BranchNumber": 8,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 4051279,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9025 HFHyb.OR 2",
          "JobNumber": "502-815250-374",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2799 W Grand Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2799 W Grand Blvd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,311.99",
          "BranchNumber": 8,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4051278,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TRU HOTEL",
          "JobNumber": "502-812692-21",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1048 W MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "1048 W MAIN ST",
          "JobCounty": "Otsego",
          "JobState": "Michigan",
          "CustomerCompanyName": "Harbaugh Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,000.00",
          "BalanceRemaining": "$31,371.96",
          "BranchNumber": 8,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "Harbaugh Construction"
        },
        {
          "WorkOrder": 4051277,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Northwest Mutual",
          "JobNumber": "502-1612480-4",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2947 Eyde Pkwy",
          "JobAddress2": "",
          "JobFullAddress": "2947 Eyde Pkwy",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "LOUIS J EYDE FAMILY LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,520.16",
          "BranchNumber": 7,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "LOUIS J EYDE FAMILY LLC"
        },
        {
          "WorkOrder": 4051276,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Willis Road",
          "JobNumber": "502-966450-16",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3401 Willis Road",
          "JobAddress2": "",
          "JobFullAddress": "3401 Willis Road",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "BACHELDER DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "BACHELDER DRYWALL"
        },
        {
          "WorkOrder": 4051275,
          "ParentWorkOrder": "",
          "JobName": "(NOF)COSTCOMIDLAND",
          "JobNumber": "502-1602000-35",
          "FirstdayontheJob": "08-05-2020",
          "LastdayontheJob": "08-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4816 BAY CITY RD",
          "JobAddress2": "",
          "JobFullAddress": "4816 BAY CITY RD",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Constr",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$28,109.65",
          "BranchNumber": 1,
          "SubmitDate": "08-06-2020",
          "ClientonJob": "Great Lakes Bay Constr"
        },
        {
          "WorkOrder": 4050789,
          "ParentWorkOrder": 4041627,
          "JobName": "(NOF)KROGER ESSEXVILLE",
          "JobNumber": "502-20010-31",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2910 CENTER AVE",
          "JobAddress2": "",
          "JobFullAddress": "2910 CENTER AVE",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$263.69",
          "BranchNumber": 8,
          "SubmitDate": "08-04-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4050327,
          "ParentWorkOrder": 4041623,
          "JobName": "(NOF)894 LAKE SHORE RD",
          "JobNumber": "502-1650969-85",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "994 S LAKE SHORE RD",
          "JobAddress2": "",
          "JobFullAddress": "994 S LAKE SHORE RD",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,408.78",
          "BranchNumber": 1,
          "SubmitDate": "08-03-2020",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 4049684,
          "ParentWorkOrder": "",
          "JobName": "(   )TRILOGY HEALTH - DAY",
          "JobNumber": "65-2137179-16",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2510 Vienna Parkway",
          "JobAddress2": "",
          "JobFullAddress": "2510 Vienna Parkway",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "DURANGO DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$250,000.00",
          "BalanceRemaining": "$3,923.66",
          "BranchNumber": 13,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "DURANGO DRYWALL LLC"
        },
        {
          "WorkOrder": 4049683,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HARRISON III",
          "JobNumber": "65-131137-6",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3044 Burgoyne Ct",
          "JobAddress2": "",
          "JobFullAddress": "3044 Burgoyne Ct",
          "JobCounty": "Miami",
          "JobState": "Ohio",
          "CustomerCompanyName": "A PLUS PAINTERS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$361.04",
          "BranchNumber": 13,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "A PLUS PAINTERS LLC"
        },
        {
          "WorkOrder": 4049682,
          "ParentWorkOrder": "",
          "JobName": "TACO BELL",
          "JobNumber": "65-72136-4",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8220 Ohio River Rd",
          "JobAddress2": "",
          "JobFullAddress": "8220 Ohio River Rd",
          "JobCounty": "SCIOTO",
          "JobState": "Ohio",
          "CustomerCompanyName": "EXTERIOR SOLUTIONS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$10,291.26",
          "BranchNumber": 7,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "EXTERIOR SOLUTIONS"
        },
        {
          "WorkOrder": 4049681,
          "ParentWorkOrder": "",
          "JobName": "CROCS (PROJECT DAWN)",
          "JobNumber": "65-534004-11",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10391 DOG LEG RD",
          "JobAddress2": "BLDG 5",
          "JobFullAddress": "10391 DOG LEG RD BLDG 5",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRUE CUT CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$15,372.40",
          "BranchNumber": 2,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "TRUE CUT CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 4049680,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Panda Exp.-Southgate",
          "JobNumber": "502-858231-54",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16240 Fort St",
          "JobAddress2": "",
          "JobFullAddress": "16240 Fort St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,222.54",
          "BranchNumber": 11,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 4049679,
          "ParentWorkOrder": "",
          "JobName": "BLOSSOM CREEK 6 PLEX(NOF)",
          "JobNumber": "502-847401-151",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3224-3234 Blossom Creek D",
          "JobAddress2": "Building 5",
          "JobFullAddress": "3224-3234 Blossom Creek D Building 5",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,666.56",
          "BranchNumber": 11,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4049678,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BELLE CONE LOT 9",
          "JobNumber": "502-18879-17",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3260 CONE AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "3260 CONE AVENUE",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SAVYER DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,906.00",
          "BalanceRemaining": "$4,611.22",
          "BranchNumber": 11,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "SAVYER DRYWALL LLC"
        },
        {
          "WorkOrder": 4049677,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HENRY FORD CANCER CT",
          "JobNumber": "502-933550-189",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2799 W Grand Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2799 W Grand Blvd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$1,108.25",
          "BranchNumber": 9,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4049676,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Westwood Inn",
          "JobNumber": "502-933550-185",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19759 23 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "19759 23 Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$2,827.66",
          "BranchNumber": 9,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4049675,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Webesto",
          "JobNumber": "502-916331-16",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14200 Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "14200 Haggerty Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "IDEAL CONTRACTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$510.39",
          "BranchNumber": 9,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "IDEAL CONTRACTING LLC"
        },
        {
          "WorkOrder": 4049674,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Culvers Flint",
          "JobNumber": "502-916315-174",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4034 MILLER RD",
          "JobAddress2": "",
          "JobFullAddress": "4034 MILLER RD",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commercial Construction, Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$232.98",
          "BranchNumber": 9,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "Independence Commercial Construction, Inc"
        },
        {
          "WorkOrder": 4049673,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Aspen Dental",
          "JobNumber": "502-1601244-3",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20891 East 13 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "20891 East 13 Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "PAULON CONSTRUCTION MANAGEMENT CORP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,911.61",
          "BranchNumber": 8,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "PAULON CONSTRUCTION MANAGEMENT CORP"
        },
        {
          "WorkOrder": 4049672,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OPPA",
          "JobNumber": "502-939300-18",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23261 SCOTIA RD",
          "JobAddress2": "",
          "JobFullAddress": "23261 SCOTIA RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "D&S Contractors Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$4,617.71",
          "BranchNumber": 8,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "D&S Contractors Inc"
        },
        {
          "WorkOrder": 4049671,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(274)EARLY CHILD CNT",
          "JobNumber": "502-816350-159",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1155 JOSLYN ROAD",
          "JobAddress2": "",
          "JobFullAddress": "1155 JOSLYN ROAD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$11,491.89",
          "BranchNumber": 8,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4049670,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9072 DTE GO7 ph2",
          "JobNumber": "502-815250-373",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Plaza",
          "JobAddress2": "",
          "JobFullAddress": "1 Energy Plaza",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$987.15",
          "BranchNumber": 8,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4049669,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3069 PECK ELEMENTARY",
          "JobNumber": "502-813200-144",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11300 ENGLEMAN RD",
          "JobAddress2": "",
          "JobFullAddress": "11300 ENGLEMAN RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$41.55",
          "BranchNumber": 8,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4049668,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HUDSON FIELD OFFICE",
          "JobNumber": "502-812350-94",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1401 FARMER ST",
          "JobAddress2": "",
          "JobFullAddress": "1401 FARMER ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,766.06",
          "BranchNumber": 8,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4049667,
          "ParentWorkOrder": "",
          "JobName": "(NOF) OCTTAPHARMA",
          "JobNumber": "502-940253-4",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "815 W MICHIGAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "815 W MICHIGAN AVE",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "D L P Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$3,146.60",
          "BranchNumber": 7,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "D L P Construction"
        },
        {
          "WorkOrder": 4049666,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Stone Builders",
          "JobNumber": "502-11890-59",
          "FirstdayontheJob": "07-29-2020",
          "LastdayontheJob": "07-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3600 Lakeshore Rd",
          "JobAddress2": "",
          "JobFullAddress": "3600 Lakeshore Rd",
          "JobCounty": "SANILAC",
          "JobState": "Michigan",
          "CustomerCompanyName": "Gypsum Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,206.00",
          "BalanceRemaining": "$4,953.71",
          "BranchNumber": 1,
          "SubmitDate": "07-30-2020",
          "ClientonJob": "Gypsum Interiors LLC"
        },
        {
          "WorkOrder": 4049206,
          "ParentWorkOrder": 4035713,
          "JobName": "(NOF)(269)Carpenter Elem.",
          "JobNumber": "502-816350-157",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2290 Flintridge St",
          "JobAddress2": "",
          "JobFullAddress": "2290 Flintridge St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,231.66",
          "BranchNumber": 8,
          "SubmitDate": "07-28-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4049131,
          "ParentWorkOrder": 4041641,
          "JobName": "(NOF)Supinos",
          "JobNumber": "502-875466-8",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6519 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "6519 Woodward Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Facilities Management Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,280.82",
          "BranchNumber": 8,
          "SubmitDate": "07-28-2020",
          "ClientonJob": "Facilities Management Group"
        },
        {
          "WorkOrder": 4048813,
          "ParentWorkOrder": 4021050,
          "JobName": "Midmark Research & Dev",
          "JobNumber": "65-2134688-14",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "60 Vista Dr",
          "JobAddress2": "",
          "JobFullAddress": "60 Vista Dr",
          "JobCounty": "DARKE",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$500,006.00",
          "BalanceRemaining": "$27,961.42",
          "BranchNumber": 12,
          "SubmitDate": "07-27-2020",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 4048812,
          "ParentWorkOrder": 4037896,
          "JobName": "(NOF) MLK Center",
          "JobNumber": "502-919700-139",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1107 Adrian St",
          "JobAddress2": "",
          "JobFullAddress": "1107 Adrian St",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "07-27-2020",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 4048363,
          "ParentWorkOrder": 4041651,
          "JobName": "(NOF) Telada Comm. Mall",
          "JobNumber": "502-967075-128",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21629 Telegraph Rd",
          "JobAddress2": "",
          "JobFullAddress": "21629 Telegraph Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,558.16",
          "BranchNumber": 7,
          "SubmitDate": "07-24-2020",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 4048362,
          "ParentWorkOrder": 4043452,
          "JobName": "RESIDENCE INN BY MARRIOTT",
          "JobNumber": "65-13105-3",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4600 International Gtwy",
          "JobAddress2": "",
          "JobFullAddress": "4600 International Gtwy",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "INTEGRITY INTERIORS US LLC",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$1,686.44",
          "BranchNumber": 13,
          "SubmitDate": "07-24-2020",
          "ClientonJob": "INTEGRITY INTERIORS US LLC"
        },
        {
          "WorkOrder": 4048361,
          "ParentWorkOrder": 4041599,
          "JobName": "NOF TURTLE CREEK TOWNSHIP",
          "JobNumber": "65-51919-2",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1550 N STATE RT 741",
          "JobAddress2": "",
          "JobFullAddress": "1550 N STATE RT 741",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "SUNFOREST BUILDING COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,675.21",
          "BranchNumber": 2,
          "SubmitDate": "07-24-2020",
          "ClientonJob": "SUNFOREST BUILDING COMPANY"
        },
        {
          "WorkOrder": 4047764,
          "ParentWorkOrder": 4039970,
          "JobName": "(NOF) CATHERAL TOWERS",
          "JobNumber": "502-1325000-27",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "80 E HANCOCK",
          "JobAddress2": "",
          "JobFullAddress": "80 E HANCOCK",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,139.57",
          "BranchNumber": 9,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4047763,
          "ParentWorkOrder": 4039971,
          "JobName": "(NOF)Charles Parcells MS",
          "JobNumber": "502-1609003-3",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20600 Mack Ave",
          "JobAddress2": "",
          "JobFullAddress": "20600 Mack Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "North American Construction",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$1,017.52",
          "BranchNumber": 9,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "North American Construction"
        },
        {
          "WorkOrder": 4047698,
          "ParentWorkOrder": "",
          "JobName": "(   ) KROGER# 938",
          "JobNumber": "65-13300-2",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "885 UNION BLVD",
          "JobAddress2": "",
          "JobFullAddress": "885 UNION BLVD",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "KATIE SMITH CONTRACTING h",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$4,724.35",
          "BranchNumber": 13,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "KATIE SMITH CONTRACTING h"
        },
        {
          "WorkOrder": 4047697,
          "ParentWorkOrder": "",
          "JobName": "NOF LINDEN PARK",
          "JobNumber": "65-123946-2",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1350 BRIARWOOD AVE",
          "JobAddress2": "",
          "JobFullAddress": "1350 BRIARWOOD AVE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "HAROLD J BECKER CO INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$72,932.48",
          "BranchNumber": 12,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "HAROLD J BECKER CO INC"
        },
        {
          "WorkOrder": 4047696,
          "ParentWorkOrder": "",
          "JobName": "NJP GM DMAX",
          "JobNumber": "65-506416-3",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "101 W Campus Blvd",
          "JobAddress2": "",
          "JobFullAddress": "101 W Campus Blvd",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "BARTON MALOW COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$14,000.32",
          "BranchNumber": 2,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "BARTON MALOW COMPANY"
        },
        {
          "WorkOrder": 4047695,
          "ParentWorkOrder": "",
          "JobName": "PENN STATION",
          "JobNumber": "65-53046-3",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1044 Interstate Ct",
          "JobAddress2": "",
          "JobFullAddress": "1044 Interstate Ct",
          "JobCounty": "HANCOCK",
          "JobState": "Ohio",
          "CustomerCompanyName": "CURRAN CONSTRUCTION CO.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,932.91",
          "BranchNumber": 2,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "CURRAN CONSTRUCTION CO."
        },
        {
          "WorkOrder": 4047693,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MARRIOT YPSILANTI",
          "JobNumber": "502-948945-59",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1275 S Huron St",
          "JobAddress2": "",
          "JobFullAddress": "1275 S Huron St",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trinity Building Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,204.82",
          "BranchNumber": 9,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "Trinity Building Group"
        },
        {
          "WorkOrder": 4047692,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Brain Mahhafy",
          "JobNumber": "502-858171-122",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4733 Yankee Rd",
          "JobAddress2": "",
          "JobFullAddress": "4733 Yankee Rd",
          "JobCounty": "St Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "DGS DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$4,864.38",
          "BranchNumber": 11,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "DGS DRYWALL LLC"
        },
        {
          "WorkOrder": 4047691,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Crystal Pines",
          "JobNumber": "502-839201-52",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5643 Flagstaff Pines Dr",
          "JobAddress2": "",
          "JobFullAddress": "5643 Flagstaff Pines Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLINTON INTERIORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$669.16",
          "BranchNumber": 8,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "CLINTON INTERIORS INC"
        },
        {
          "WorkOrder": 4047690,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Lot 16 Enclaves",
          "JobNumber": "502-839201-51",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14073 Copse Dr",
          "JobAddress2": "",
          "JobFullAddress": "14073 Copse Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLINTON INTERIORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,109.34",
          "BranchNumber": 8,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "CLINTON INTERIORS INC"
        },
        {
          "WorkOrder": 4047689,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(267)Hill Elementary",
          "JobNumber": "502-816350-149",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4600 Forsyth Dr",
          "JobAddress2": "",
          "JobFullAddress": "4600 Forsyth Dr",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$125.08",
          "BranchNumber": 8,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4047688,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MEIJER - HOWELL",
          "JobNumber": "502-811817-12",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3883 E Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "3883 E Grand River Ave",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 4047687,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KROGER G.P.W.",
          "JobNumber": "502-20010-32",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20422 Mack Ave",
          "JobAddress2": "",
          "JobFullAddress": "20422 Mack Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$14,243.07",
          "BranchNumber": 8,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4047686,
          "ParentWorkOrder": "",
          "JobName": "(NOF)EAGLE RIDGE LOT 13",
          "JobNumber": "502-18879-20",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5413 Aberdeen Ct",
          "JobAddress2": "",
          "JobFullAddress": "5413 Aberdeen Ct",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SAVYER DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,906.00",
          "BalanceRemaining": "$5,051.77",
          "BranchNumber": 11,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "SAVYER DRYWALL LLC"
        },
        {
          "WorkOrder": 4047685,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1053 S. GREY RD.",
          "JobNumber": "502-1650969-88",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1053 S Grey Rd",
          "JobAddress2": "",
          "JobFullAddress": "1053 S Grey Rd",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$2,329.91",
          "BranchNumber": 1,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 4047684,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Stonefield",
          "JobNumber": "502-1632825-2",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "42280 Garfield Rd",
          "JobAddress2": "",
          "JobFullAddress": "42280 Garfield Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR CONST & DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$8,865.42",
          "BranchNumber": 8,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "SUPERIOR CONST & DRYWALL LLC"
        },
        {
          "WorkOrder": 4047683,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MonteithElem. School",
          "JobNumber": "502-1609003-4",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1275 COOK RD",
          "JobAddress2": "",
          "JobFullAddress": "1275 COOK RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "North American Construction Enterprises, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,243.39",
          "BranchNumber": 9,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "North American Construction Enterprises, LLC"
        },
        {
          "WorkOrder": 4047682,
          "ParentWorkOrder": "",
          "JobName": "(NOF)JERSEY MIKES MDLND",
          "JobNumber": "502-1602000-34",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1917 S Saginaw Rd",
          "JobAddress2": "",
          "JobFullAddress": "1917 S Saginaw Rd",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Construction, Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$653.26",
          "BranchNumber": 1,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "Great Lakes Bay Construction, Inc."
        },
        {
          "WorkOrder": 4047681,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Jerry B Condo's",
          "JobNumber": "502-1590590-2",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "266 E Bacon St",
          "JobAddress2": "",
          "JobFullAddress": "266 E Bacon St",
          "JobCounty": "HILLSDALE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Stoll Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,184.57",
          "BranchNumber": 7,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "Stoll Construction LLC"
        },
        {
          "WorkOrder": 4047680,
          "ParentWorkOrder": "",
          "JobName": "(NOF) OREILLYS DEFIANCE",
          "JobNumber": "502-1573445-9",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1925 E 2ND ST",
          "JobAddress2": "",
          "JobFullAddress": "1925 E 2ND ST",
          "JobCounty": "DEFIANCE",
          "JobState": "Ohio",
          "CustomerCompanyName": "QUALITY DESIGN INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$18,171.68",
          "BranchNumber": 1,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "QUALITY DESIGN INC"
        },
        {
          "WorkOrder": 4047679,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LEE RD",
          "JobNumber": "502-1161763-3",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14920 LEE RD",
          "JobAddress2": "",
          "JobFullAddress": "14920 LEE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "CZACH PROPERTIES LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "CZACH PROPERTIES LLC"
        },
        {
          "WorkOrder": 4047678,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HILTON GARDEN INN",
          "JobNumber": "502-112908-14",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "352 S Saginaw St",
          "JobAddress2": "",
          "JobFullAddress": "352 S Saginaw St",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DW Lurvey Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,862.80",
          "BranchNumber": 1,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "DW Lurvey Construction"
        },
        {
          "WorkOrder": 4047677,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FLINT SURGERY CENTER",
          "JobNumber": "502-112908-13",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5202 MILLER RD",
          "JobAddress2": "",
          "JobFullAddress": "5202 MILLER RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DW Lurvey Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$4,031.53",
          "BranchNumber": 1,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "DW Lurvey Construction"
        },
        {
          "WorkOrder": 4047676,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Parkview Place Apts",
          "JobNumber": "502-111914-12",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1401 Chene",
          "JobAddress2": "",
          "JobFullAddress": "1401 Chene",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Custom Builder",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$2,170.88",
          "BranchNumber": 11,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "Great Lakes Custom Builder"
        },
        {
          "WorkOrder": 4047675,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MARY FREE BED KITCHE",
          "JobNumber": "502-1060142-29",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "235 WEALTHY ST SE",
          "JobAddress2": "",
          "JobFullAddress": "235 WEALTHY ST SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$18.23",
          "BranchNumber": 5,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 4047674,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Chemical Bank",
          "JobNumber": "502-1014005-11",
          "FirstdayontheJob": "07-21-2020",
          "LastdayontheJob": "07-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "RUDYS PLASTERING AND",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "",
          "ClientonJob": "RUDYS PLASTERING AND"
        },
        {
          "WorkOrder": 4047664,
          "ParentWorkOrder": 4041598,
          "JobName": "AMAZON DCM5",
          "JobNumber": "65-700193-5",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8591 Mink St SW",
          "JobAddress2": "",
          "JobFullAddress": "8591 Mink St SW",
          "JobCounty": "LICKING",
          "JobState": "Ohio",
          "CustomerCompanyName": "UNITED CONTRACTOR SERVICE",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$9,701.67",
          "BranchNumber": 1,
          "SubmitDate": "07-22-2020",
          "ClientonJob": "UNITED CONTRACTOR SERVICE"
        },
        {
          "WorkOrder": 4047048,
          "ParentWorkOrder": 4037880,
          "JobName": "(NOF)KROGER CLARKSTON",
          "JobNumber": "502-20010-28",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9741 DIXIE HIGHWAY",
          "JobAddress2": "",
          "JobFullAddress": "9741 DIXIE HIGHWAY",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$772.41",
          "BranchNumber": 8,
          "SubmitDate": "07-20-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4047047,
          "ParentWorkOrder": 4041607,
          "JobName": "EXCELITAS OFFICE RENOV",
          "JobNumber": "65-2134688-18",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1100 VANGUARD BLVD",
          "JobAddress2": "",
          "JobFullAddress": "1100 VANGUARD BLVD",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVICE",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$13,581.05",
          "BranchNumber": 12,
          "SubmitDate": "07-20-2020",
          "ClientonJob": "BENCO CONSTRUCTION SERVICE"
        },
        {
          "WorkOrder": 4046447,
          "ParentWorkOrder": "",
          "JobName": "(NOF) VonAllmen residence",
          "JobNumber": "502-859551-27",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6300 Lakeshore Rd",
          "JobAddress2": "",
          "JobFullAddress": "6300 Lakeshore Rd",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "PICKLEHAUPT DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$5,565.06",
          "BranchNumber": 11,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "PICKLEHAUPT DRYWALL"
        },
        {
          "WorkOrder": 4046446,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FOX RUN CC  NOVI",
          "JobNumber": "502-849801-246",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41215 FOX RUN RD",
          "JobAddress2": "",
          "JobFullAddress": "41215 FOX RUN RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 4046445,
          "ParentWorkOrder": "",
          "JobName": "(NOF)EAGLE RIDGE LOT 15",
          "JobNumber": "502-18879-16",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5405 Aberdeen Ct",
          "JobAddress2": "",
          "JobFullAddress": "5405 Aberdeen Ct",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SAVYER DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,906.00",
          "BalanceRemaining": "$5,598.37",
          "BranchNumber": 11,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "SAVYER DRYWALL LLC"
        },
        {
          "WorkOrder": 4046444,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ALDI'S  #50",
          "JobNumber": "502-1507798-48",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4189 24th Ave",
          "JobAddress2": "",
          "JobFullAddress": "4189 24th Ave",
          "JobCounty": "St Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$10,444.59",
          "BranchNumber": 9,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4046443,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NORTHVILLE SCHOOLS",
          "JobNumber": "502-981570-16",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "46180 W 9 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "46180 W 9 Mile Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DTS Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$1,187.90",
          "BranchNumber": 9,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "DTS Contracting"
        },
        {
          "WorkOrder": 4046442,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HAJA DANCE STUDIO",
          "JobNumber": "502-949575-123",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3132 NEWPORT RD",
          "JobAddress2": "#109",
          "JobFullAddress": "3132 NEWPORT RD #109",
          "JobCounty": "Monroe",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,593.52",
          "BranchNumber": 9,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4046441,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Lakesport State Park",
          "JobNumber": "502-937675-11",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7605 Lakeshore Rd",
          "JobAddress2": "",
          "JobFullAddress": "7605 Lakeshore Rd",
          "JobCounty": "St Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "Angels General Construction, Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$170.55",
          "BranchNumber": 9,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "Angels General Construction, Inc"
        },
        {
          "WorkOrder": 4046440,
          "ParentWorkOrder": "",
          "JobName": "NOF NYX",
          "JobNumber": "502-913438-11",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 Manufacturers Dr",
          "JobAddress2": "",
          "JobFullAddress": "1000 Manufacturers Dr",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Davenport Brothers Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$11,730.50",
          "BranchNumber": 9,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "Davenport Brothers Construction Co"
        },
        {
          "WorkOrder": 4046439,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TOP CATS SALES",
          "JobNumber": "502-910621-48",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32475 Stephenson Hwy",
          "JobAddress2": "",
          "JobFullAddress": "32475 Stephenson Hwy",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 4046438,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MORAINE ELEMENTARY",
          "JobNumber": "502-910050-58",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "46811 EIGHT MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "46811 EIGHT MILE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$30.53",
          "BranchNumber": 9,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 4046437,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ZTA SORORITY",
          "JobNumber": "502-165739-3",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1550 Washtenaw Ave",
          "JobAddress2": "",
          "JobFullAddress": "1550 Washtenaw Ave",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "TMS Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$107.63",
          "BranchNumber": 9,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "TMS Construction Inc"
        },
        {
          "WorkOrder": 4046436,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Regency Club Apt #24",
          "JobNumber": "502-8997117-24",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26250 Regency Club Circle",
          "JobAddress2": "Building 24",
          "JobFullAddress": "26250 Regency Club Circle Building 24",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "FRONTGATE VENTURES LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$27,463.61",
          "BranchNumber": 8,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "FRONTGATE VENTURES LLC"
        },
        {
          "WorkOrder": 4046435,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Aldi Chesterfield",
          "JobNumber": "502-1601233-2",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50700 Waterside Dr",
          "JobAddress2": "",
          "JobFullAddress": "50700 Waterside Dr",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "ABUNDANT CONSTRUCTION SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$55.35",
          "BranchNumber": 8,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "ABUNDANT CONSTRUCTION SERVICES"
        },
        {
          "WorkOrder": 4046434,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Kalkanis Residence",
          "JobNumber": "502-1311893-4",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1290 W Long Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "1290 W Long Lake Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "STEFANI FINISH INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$9,277.75",
          "BranchNumber": 8,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "STEFANI FINISH INC"
        },
        {
          "WorkOrder": 4046433,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DAVISBURG",
          "JobNumber": "502-886833-11",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12850 FOUNTAIN SQUARE",
          "JobAddress2": "",
          "JobFullAddress": "12850 FOUNTAIN SQUARE",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Motor City Constr.& Redmo",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$5,794.67",
          "BranchNumber": 8,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "Motor City Constr.& Redmo"
        },
        {
          "WorkOrder": 4046432,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Atty Residence",
          "JobNumber": "502-876650-350",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4889 Lockhart St",
          "JobAddress2": "",
          "JobFullAddress": "4889 Lockhart St",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4046431,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 41A DISTRICT COURT",
          "JobNumber": "502-839201-49",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "40111 Dodge Park Rd",
          "JobAddress2": "",
          "JobFullAddress": "40111 Dodge Park Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLINTON INTERIORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,355.75",
          "BranchNumber": 8,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "CLINTON INTERIORS INC"
        },
        {
          "WorkOrder": 4046430,
          "ParentWorkOrder": "",
          "JobName": "(NOF) RAYMOND JAMES",
          "JobNumber": "502-839201-48",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "251 DIVERSION ST",
          "JobAddress2": "",
          "JobFullAddress": "251 DIVERSION ST",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLINTON INTERIORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$7,095.38",
          "BranchNumber": 8,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "CLINTON INTERIORS INC"
        },
        {
          "WorkOrder": 4046429,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Mathworks TI",
          "JobNumber": "502-823400-54",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "28125 Cabot Dr",
          "JobAddress2": "Ste 200",
          "JobFullAddress": "28125 Cabot Dr Ste 200",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "PARALLEL CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "PARALLEL CONSTRUCTION"
        },
        {
          "WorkOrder": 4046428,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Amazon",
          "JobNumber": "502-823200-95",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1200 Featherstone Rd",
          "JobAddress2": "",
          "JobFullAddress": "1200 Featherstone Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PONTIAC CEILING & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$400,024.00",
          "BalanceRemaining": "$3,430.48",
          "BranchNumber": 8,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "PONTIAC CEILING & PART"
        },
        {
          "WorkOrder": 4046427,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(270)Roseville High",
          "JobNumber": "502-816350-156",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17855 Common Rd",
          "JobAddress2": "",
          "JobFullAddress": "17855 Common Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$21,261.17",
          "BranchNumber": 8,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4046426,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HOLIDAY INN& SUITES",
          "JobNumber": "502-812692-22",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "870 Tower Dr",
          "JobAddress2": "",
          "JobFullAddress": "870 Tower Dr",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Starbest Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$2,619.89",
          "BranchNumber": 8,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "Starbest Construction"
        },
        {
          "WorkOrder": 4046425,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WHITTEMORE-PRESCOTT",
          "JobNumber": "502-22580-5",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6001 Mills Rd",
          "JobAddress2": "",
          "JobFullAddress": "6001 Mills Rd",
          "JobCounty": "IOSCO",
          "JobState": "Michigan",
          "CustomerCompanyName": "J.Perez Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$22,459.28",
          "BranchNumber": 1,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "J.Perez Construction"
        },
        {
          "WorkOrder": 4046398,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Inspiration Dance",
          "JobNumber": "502-1304186-62",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1399 Lear Industrial Pky",
          "JobAddress2": "",
          "JobFullAddress": "1399 Lear Industrial Pky",
          "JobCounty": "LORAIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,697.49",
          "BranchNumber": 13,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4046397,
          "ParentWorkOrder": "",
          "JobName": "(NOF) OHIO LOGISTICS",
          "JobNumber": "502-1301172-51",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "400 N Buckeye St",
          "JobAddress2": "",
          "JobFullAddress": "400 N Buckeye St",
          "JobCounty": "HURON",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$7,838.66",
          "BranchNumber": 13,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 4046396,
          "ParentWorkOrder": "",
          "JobName": "UC VONTZ CENTER BUILDING",
          "JobNumber": "65-2134915-4",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3125 Eden Ave",
          "JobAddress2": "",
          "JobFullAddress": "3125 Eden Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "LITHKO RESTORATION TECHNO",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$624.63",
          "BranchNumber": 12,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "LITHKO RESTORATION TECHNO"
        },
        {
          "WorkOrder": 4046395,
          "ParentWorkOrder": "",
          "JobName": "5/3 BANK MART ANDERSON",
          "JobNumber": "65-2134669-7",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7580 Beechmont Ave",
          "JobAddress2": "",
          "JobFullAddress": "7580 Beechmont Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "MCCOOL PLASTER AND DRYWAL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,359.72",
          "BranchNumber": 12,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "MCCOOL PLASTER AND DRYWAL"
        },
        {
          "WorkOrder": 4046394,
          "ParentWorkOrder": "",
          "JobName": "CVS RX EXPANSION 03422",
          "JobNumber": "65-4077685-35",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "131 THIRD STREET",
          "JobAddress2": "",
          "JobFullAddress": "131 THIRD STREET",
          "JobCounty": "Washington",
          "JobState": "Ohio",
          "CustomerCompanyName": "FORTNEY & WEYGANDT,INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$2,030.31",
          "BranchNumber": 4,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "FORTNEY & WEYGANDT,INC"
        },
        {
          "WorkOrder": 4046393,
          "ParentWorkOrder": "",
          "JobName": "NOF PENSKE TRUCKING",
          "JobNumber": "65-412776-11",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2470 Westbelt Dr",
          "JobAddress2": "",
          "JobFullAddress": "2470 Westbelt Dr",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "M&MCONSTR'T & MECHAN'L LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,724.29",
          "BranchNumber": 4,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "M&MCONSTR'T & MECHAN'L LLC"
        },
        {
          "WorkOrder": 4046392,
          "ParentWorkOrder": "",
          "JobName": "PRI-MED",
          "JobNumber": "65-573117-10",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "200 ROCKRIDGE RD",
          "JobAddress2": "",
          "JobFullAddress": "200 ROCKRIDGE RD",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "CORE RESOURCES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,011.67",
          "BranchNumber": 2,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "CORE RESOURCES INC"
        },
        {
          "WorkOrder": 4046391,
          "ParentWorkOrder": "",
          "JobName": "NOF PET PALACE",
          "JobNumber": "65-510300-57",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8386 Highland Pointe Drive",
          "JobAddress2": "",
          "JobFullAddress": "8386 Highland Pointe Drive",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$8,833.60",
          "BranchNumber": 2,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4046390,
          "ParentWorkOrder": "",
          "JobName": "WEST CHESTER DENTIST",
          "JobNumber": "65-505800-11",
          "FirstdayontheJob": "07-16-2020",
          "LastdayontheJob": "07-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9215 CINCINNATI COLUMBUS RD",
          "JobAddress2": "",
          "JobFullAddress": "9215 CINCINNATI COLUMBUS RD",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "KIESLAND DEVELOPMENT SERVICES LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,203.88",
          "BranchNumber": 2,
          "SubmitDate": "07-17-2020",
          "ClientonJob": "KIESLAND DEVELOPMENT SERVICES LLC"
        },
        {
          "WorkOrder": 4045615,
          "ParentWorkOrder": 4042124,
          "JobName": "(   )COTTAGES BEAVERCREEK",
          "JobNumber": "65-502950-10",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4445-4435 FAITH COURT",
          "JobAddress2": "BUILDING # 4",
          "JobFullAddress": "4445-4435 FAITH COURT BUILDING # 4",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "DEVIN CONSTRUCTION CO LLC",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$3,467.52",
          "BranchNumber": 13,
          "SubmitDate": "07-15-2020",
          "ClientonJob": "DEVIN CONSTRUCTION CO LLC"
        },
        {
          "WorkOrder": 4045210,
          "ParentWorkOrder": 4039952,
          "JobName": "(NOF)202008-01 Nexcore",
          "JobNumber": "502-815250-366",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "36555 26 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "36555 26 Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$195,006.00",
          "BalanceRemaining": "$932.80",
          "BranchNumber": 8,
          "SubmitDate": "07-14-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4044345,
          "ParentWorkOrder": 4030642,
          "JobName": "(NOF)QUICKEN PHA#2 20-311",
          "JobNumber": "502-937250-157",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 CAMPUS MARTIUS",
          "JobAddress2": "",
          "JobFullAddress": "1 CAMPUS MARTIUS",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINKER TEAM CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "07-10-2020",
          "ClientonJob": "BRINKER TEAM CONSTRUCTION CO"
        },
        {
          "WorkOrder": 4043913,
          "ParentWorkOrder": 4037664,
          "JobName": "(NOF)WESTGATE LIQUOR STOR",
          "JobNumber": "502-1609034-47",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3310 Secor Rd",
          "JobAddress2": "",
          "JobFullAddress": "3310 Secor Rd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,714.21",
          "BranchNumber": 13,
          "SubmitDate": "07-09-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4043467,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LOC FCU Phase II",
          "JobNumber": "502-858231-52",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3020 East Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "3020 East Grand River Ave",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Midwest Metal Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,621.24",
          "BranchNumber": 11,
          "SubmitDate": "07-08-2020",
          "ClientonJob": "Midwest Metal Construction LLC"
        },
        {
          "WorkOrder": 4043466,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dry Goods",
          "JobNumber": "502-934150-67",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "236 N ADAMS RD",
          "JobAddress2": "",
          "JobFullAddress": "236 N ADAMS RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "WOODS CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "07-08-2020",
          "ClientonJob": "WOODS CONSTRUCTION INC"
        },
        {
          "WorkOrder": 4043465,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 511 WOODWARD OFFICE",
          "JobNumber": "502-933550-184",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "511 WOODWARD AVE",
          "JobAddress2": "",
          "JobFullAddress": "511 WOODWARD AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$4,146.48",
          "BranchNumber": 9,
          "SubmitDate": "07-08-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4043464,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Shoreline Dr",
          "JobNumber": "502-1604171-17",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3830 S Shoreline Drive",
          "JobAddress2": "",
          "JobFullAddress": "3830 S Shoreline Drive",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$414.82",
          "BranchNumber": 8,
          "SubmitDate": "07-08-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4043463,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Reuther Middle Sch.",
          "JobNumber": "502-920940-85",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1430 E Auburn Rd",
          "JobAddress2": "",
          "JobFullAddress": "1430 E Auburn Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$429.63",
          "BranchNumber": 8,
          "SubmitDate": "07-08-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4043462,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Suburban Mazda",
          "JobNumber": "502-877300-38",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1794 Maplelawn Drive",
          "JobAddress2": "",
          "JobFullAddress": "1794 Maplelawn Drive",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR DESIGNS INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$20,296.21",
          "BranchNumber": 8,
          "SubmitDate": "07-08-2020",
          "ClientonJob": "SUPERIOR DESIGNS INC."
        },
        {
          "WorkOrder": 4043461,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TRU HOTEL - CP",
          "JobNumber": "502-1014005-10",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "340 DODGE RD NE",
          "JobAddress2": "",
          "JobFullAddress": "340 DODGE RD NE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "RUDYS PLASTERING AND DRYWALL FINISH",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$8,535.79",
          "BranchNumber": 5,
          "SubmitDate": "07-08-2020",
          "ClientonJob": "RUDYS PLASTERING AND DRYWALL FINISH"
        },
        {
          "WorkOrder": 4043460,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-6 AM 10-COHEN",
          "JobNumber": "502-12280-20",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4138 VILLAGE GREEN",
          "JobAddress2": "",
          "JobFullAddress": "4138 VILLAGE GREEN",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDLAND DRYWALL CORP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,506.00",
          "BalanceRemaining": "$862.20",
          "BranchNumber": 1,
          "SubmitDate": "07-08-2020",
          "ClientonJob": "MIDLAND DRYWALL CORP"
        },
        {
          "WorkOrder": 4043455,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Prada-Saks Fifth",
          "JobNumber": "502-134220-7",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2901 W Big Beaver Rd.",
          "JobAddress2": "",
          "JobFullAddress": "2901 W Big Beaver Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HORIZON RETAIL CONSTRUCT",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$840.83",
          "BranchNumber": 13,
          "SubmitDate": "07-08-2020",
          "ClientonJob": "HORIZON RETAIL CONSTRUCT"
        },
        {
          "WorkOrder": 4043454,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MARSHALLS",
          "JobNumber": "65-2137179-15",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1481 WAGNER AVE",
          "JobAddress2": "",
          "JobFullAddress": "1481 WAGNER AVE",
          "JobCounty": "Darke",
          "JobState": "Ohio",
          "CustomerCompanyName": "DURANGO DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$7,170.63",
          "BranchNumber": 13,
          "SubmitDate": "07-08-2020",
          "ClientonJob": "DURANGO DRYWALL LLC"
        },
        {
          "WorkOrder": 4043452,
          "ParentWorkOrder": "",
          "JobName": "RESIDENCE INN BY MARRIOTT",
          "JobNumber": "65-13105-3",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4600 International Gtwy",
          "JobAddress2": "",
          "JobFullAddress": "4600 International Gtwy",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "INTEGRITY INTERIORS US LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$1,686.44",
          "BranchNumber": 13,
          "SubmitDate": "07-08-2020",
          "ClientonJob": "INTEGRITY INTERIORS US LLC"
        },
        {
          "WorkOrder": 4043451,
          "ParentWorkOrder": "",
          "JobName": "NOF MEIJERS 136",
          "JobNumber": "65-48844-6",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1321 N Lexington-Springmill Rd",
          "JobAddress2": "",
          "JobFullAddress": "1321 N Lexington-Springmill Rd",
          "JobCounty": "Richland",
          "JobState": "Ohio",
          "CustomerCompanyName": "Big Sky Service Company LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$2,906.91",
          "BranchNumber": 4,
          "SubmitDate": "07-08-2020",
          "ClientonJob": "Big Sky Service Company LLC"
        },
        {
          "WorkOrder": 4043450,
          "ParentWorkOrder": "",
          "JobName": "HUNTER FINANCIAL",
          "JobNumber": "65-105201-2",
          "FirstdayontheJob": "07-07-2020",
          "LastdayontheJob": "07-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5506 Irwin Simpson Rd",
          "JobAddress2": "",
          "JobFullAddress": "5506 Irwin Simpson Rd",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "CONSTRUCTION SERVICES UNLIMITED INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,899.18",
          "BranchNumber": 1,
          "SubmitDate": "07-08-2020",
          "ClientonJob": "CONSTRUCTION SERVICES UNLIMITED INC"
        },
        {
          "WorkOrder": 4042580,
          "ParentWorkOrder": 4013952,
          "JobName": "(NOF) Cavanaugh Rd.",
          "JobNumber": "502-968000-4",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15630 Cavanaugh Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "15630 Cavanaugh Lake Rd",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "MILLER WALLBOARD",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "07-06-2020",
          "ClientonJob": "MILLER WALLBOARD"
        },
        {
          "WorkOrder": 4042576,
          "ParentWorkOrder": 4035724,
          "JobName": "(NOF)WASHINGTON TWP FIRE",
          "JobNumber": "502-933550-187",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "61111 MOUND RD",
          "JobAddress2": "",
          "JobFullAddress": "61111 MOUND RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$6,875.19",
          "BranchNumber": 9,
          "SubmitDate": "07-06-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4042575,
          "ParentWorkOrder": 3988378,
          "JobName": "NOF Reibold 5th Floor",
          "JobNumber": "65-502280-61",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "117 S Main St",
          "JobAddress2": "",
          "JobFullAddress": "117 S Main St",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALITIES INC",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$1,112.20",
          "BranchNumber": 13,
          "SubmitDate": "07-06-2020",
          "ClientonJob": "COMBS INTERIOR SPECIALITIES INC"
        },
        {
          "WorkOrder": 4042140,
          "ParentWorkOrder": 4025946,
          "JobName": "(NOF) Sterling Heights",
          "JobNumber": "502-967075-127",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7000 19 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "7000 19 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$16,043.25",
          "BranchNumber": 7,
          "SubmitDate": "",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 4042124,
          "ParentWorkOrder": 4032444,
          "JobName": "(   )COTTAGES BEAVERCREEK",
          "JobNumber": "65-502950-10",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4445-4435 FAITH COURT",
          "JobAddress2": "BUILDING # 4",
          "JobFullAddress": "4445-4435 FAITH COURT BUILDING # 4",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "DEVIN CONSTRUCTION CO LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$3,467.52",
          "BranchNumber": 13,
          "SubmitDate": "07-02-2020",
          "ClientonJob": "DEVIN CONSTRUCTION CO LLC"
        },
        {
          "WorkOrder": 4042123,
          "ParentWorkOrder": 4030506,
          "JobName": "(NOF) SOMO Flats",
          "JobNumber": "502-16037-2",
          "FirstdayontheJob": "05-19-2020",
          "LastdayontheJob": "05-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6455 Monroe St.",
          "JobAddress2": "",
          "JobFullAddress": "6455 Monroe St.",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "MORNINGSIDE GROUP INC",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$5,121.65",
          "BranchNumber": 13,
          "SubmitDate": "07-02-2020",
          "ClientonJob": "MORNINGSIDE GROUP INC"
        },
        {
          "WorkOrder": 4042116,
          "ParentWorkOrder": 4030635,
          "JobName": "(NOF)19-29 SC 34 STOLL",
          "JobNumber": "502-12280-19",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15 MILLPOND TRAIL",
          "JobAddress2": "",
          "JobFullAddress": "15 MILLPOND TRAIL",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDLAND DRYWALL CORP",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,506.00",
          "BalanceRemaining": "$281.09",
          "BranchNumber": 1,
          "SubmitDate": "07-02-2020",
          "ClientonJob": "MIDLAND DRYWALL CORP"
        },
        {
          "WorkOrder": 4042107,
          "ParentWorkOrder": 4030634,
          "JobName": "(NOF)CC-20-HN-26-MODEL",
          "JobNumber": "502-12280-17",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5212 FALCON WAY",
          "JobAddress2": "",
          "JobFullAddress": "5212 FALCON WAY",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDLAND DRYWALL CORP",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,506.00",
          "BalanceRemaining": "$833.39",
          "BranchNumber": 1,
          "SubmitDate": "07-02-2020",
          "ClientonJob": "MIDLAND DRYWALL CORP"
        },
        {
          "WorkOrder": 4042100,
          "ParentWorkOrder": 4016196,
          "JobName": "(NOF)Sylvania ScienceReno",
          "JobNumber": "502-1305825-26",
          "FirstdayontheJob": "03-31-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7225 Sylvania Avenue",
          "JobAddress2": "",
          "JobFullAddress": "7225 Sylvania Avenue",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,844.67",
          "BranchNumber": 13,
          "SubmitDate": "07-02-2020",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 4042095,
          "ParentWorkOrder": 4030641,
          "JobName": "(NOF) Henry Ford Corp",
          "JobNumber": "502-920940-90",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Ford Place",
          "JobAddress2": "",
          "JobFullAddress": "1 Ford Place",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "07-02-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4042086,
          "ParentWorkOrder": 4033827,
          "JobName": "(NOF)Holiday Inn",
          "JobNumber": "502-1308136-4",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11313 Milan Road",
          "JobAddress2": "",
          "JobFullAddress": "11313 Milan Road",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Schnippel Construction,Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$28,019.56",
          "BranchNumber": 13,
          "SubmitDate": "07-02-2020",
          "ClientonJob": "Schnippel Construction,Inc"
        },
        {
          "WorkOrder": 4042084,
          "ParentWorkOrder": 4032440,
          "JobName": "Artech  Sharonville",
          "JobNumber": "65-520101-2",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11988 Tramway Drive",
          "JobAddress2": "",
          "JobFullAddress": "11988 Tramway Drive",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "ARTECH GROUP LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 2,
          "SubmitDate": "07-02-2020",
          "ClientonJob": "ARTECH GROUP LLC"
        },
        {
          "WorkOrder": 4041655,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Staybridge Suites",
          "JobNumber": "502-990401-35",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "46155 Utica Park Blvd",
          "JobAddress2": "",
          "JobFullAddress": "46155 Utica Park Blvd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4041654,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PLYMOUTH LIBRARY",
          "JobNumber": "502-981570-15",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "223 S MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "223 S MAIN ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DTS Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$994.30",
          "BranchNumber": 9,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "DTS Contracting"
        },
        {
          "WorkOrder": 4041653,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HORIZON BANK",
          "JobNumber": "502-981570-14",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2555 Crooks Rd",
          "JobAddress2": "",
          "JobFullAddress": "2555 Crooks Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DTS Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,380.70",
          "BranchNumber": 9,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "DTS Contracting"
        },
        {
          "WorkOrder": 4041652,
          "ParentWorkOrder": "",
          "JobName": "(NOF) OAKLAND ELEMENTARY",
          "JobNumber": "502-981570-13",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2415 Brockton Ave",
          "JobAddress2": "",
          "JobFullAddress": "2415 Brockton Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DTS Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$6,387.14",
          "BranchNumber": 9,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "DTS Contracting"
        },
        {
          "WorkOrder": 4041651,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Telada Comm. Mall",
          "JobNumber": "502-967075-128",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21629 Telegraph Rd",
          "JobAddress2": "",
          "JobFullAddress": "21629 Telegraph Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,558.16",
          "BranchNumber": 7,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 4041650,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GARDEN CITY MOB",
          "JobNumber": "502-944100-337",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35750 WARREN RD",
          "JobAddress2": "",
          "JobFullAddress": "35750 WARREN RD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$12,252.12",
          "BranchNumber": 9,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4041648,
          "ParentWorkOrder": "",
          "JobName": "NOF FIRST CONGREGATIONAL",
          "JobNumber": "502-941950-11",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "608 E WILLIAM ST",
          "JobAddress2": "",
          "JobFullAddress": "608 E WILLIAM ST",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "GREAT LAKES CEILING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$201.40",
          "BranchNumber": 9,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "GREAT LAKES CEILING"
        },
        {
          "WorkOrder": 4041647,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BOOK TOWER 20-313",
          "JobNumber": "502-937250-161",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1265 WASHINGTON BLVD",
          "JobAddress2": "",
          "JobFullAddress": "1265 WASHINGTON BLVD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINKER TEAM CONSTRUCTION CO.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "BRINKER TEAM CONSTRUCTION CO."
        },
        {
          "WorkOrder": 4041646,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Earthwise",
          "JobNumber": "502-916315-173",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2207A S. Telegraph Rd",
          "JobAddress2": "Unit 12B  Lockbox 1408",
          "JobFullAddress": "2207A S. Telegraph Rd Unit 12B  Lockbox 1408",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4041645,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Auto Zone LincolnPrk",
          "JobNumber": "502-916315-171",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3605 Dix Highway",
          "JobAddress2": "",
          "JobFullAddress": "3605 Dix Highway",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commercial Construction, Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,532.17",
          "BranchNumber": 9,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "Independence Commercial Construction, Inc."
        },
        {
          "WorkOrder": 4041643,
          "ParentWorkOrder": "",
          "JobName": "(NOF)School of Rock",
          "JobNumber": "502-916315-170",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5810 N Sheldon Rd",
          "JobAddress2": "",
          "JobFullAddress": "5810 N Sheldon Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commercial Construction, Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$8,259.36",
          "BranchNumber": 9,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "Independence Commercial Construction, Inc"
        },
        {
          "WorkOrder": 4041642,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Okemos Mall",
          "JobNumber": "502-877300-36",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2085 W Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "2085 W Grand River Ave",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR DESIGNS INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$12,708.58",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "SUPERIOR DESIGNS INC."
        },
        {
          "WorkOrder": 4041641,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Supinos",
          "JobNumber": "502-875466-8",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6519 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "6519 Woodward Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Facilities Management Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,280.82",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "Facilities Management Group"
        },
        {
          "WorkOrder": 4041640,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dollar General",
          "JobNumber": "502-864751-220",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "403 S Main St",
          "JobAddress2": "",
          "JobFullAddress": "403 S Main St",
          "JobCounty": "Saint Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "STILES DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "STILES DRYWALL LLC"
        },
        {
          "WorkOrder": 4041639,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MALCOMB CENTER",
          "JobNumber": "502-859551-26",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7104 STARVILLE RD",
          "JobAddress2": "",
          "JobFullAddress": "7104 STARVILLE RD",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "PICKLEHAUPT DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$6,621.63",
          "BranchNumber": 11,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "PICKLEHAUPT DRYWALL"
        },
        {
          "WorkOrder": 4041638,
          "ParentWorkOrder": "",
          "JobName": "(NOF) VICTOR PARKWAY BLDG",
          "JobNumber": "502-822250-31",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19500 Victor Pkwy",
          "JobAddress2": "",
          "JobFullAddress": "19500 Victor Pkwy",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NORTHERN INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,043.80",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "NORTHERN INTERIORS LLC"
        },
        {
          "WorkOrder": 4041637,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Freestar Financial",
          "JobNumber": "502-817800-51",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22522 E 9 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "22522 E 9 MILE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,566.70",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4041636,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1004 HARVARD",
          "JobNumber": "502-816400-192",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1004 Harvard Rd",
          "JobAddress2": "",
          "JobFullAddress": "1004 Harvard Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELITE DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$6,576.63",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "ELITE DRYWALL"
        },
        {
          "WorkOrder": 4041635,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(267)Martell Elem.",
          "JobNumber": "502-816350-148",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5666 Livernois Rd",
          "JobAddress2": "",
          "JobFullAddress": "5666 Livernois Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$974.15",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4041634,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(267)Costello Elem.",
          "JobNumber": "502-816350-147",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1333 Hamman Dr",
          "JobAddress2": "",
          "JobFullAddress": "1333 Hamman Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$900.44",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4041633,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ALVIN'S RESIDENCE",
          "JobNumber": "502-815630-240",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2955 CHILDS LAKE RD",
          "JobAddress2": "",
          "JobFullAddress": "2955 CHILDS LAKE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,724.74",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4041632,
          "ParentWorkOrder": "",
          "JobName": "(NOF)New Oakland SchlCrft",
          "JobNumber": "502-815630-238",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31500 SCHOOLCRAFT RD",
          "JobAddress2": "",
          "JobFullAddress": "31500 SCHOOLCRAFT RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4041631,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Gentherm Tech Ctr.",
          "JobNumber": "502-815400-27",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "38455 Hills Tech Drive",
          "JobAddress2": "",
          "JobFullAddress": "38455 Hills Tech Drive",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DEVANGE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "DEVANGE CONSTRUCTION"
        },
        {
          "WorkOrder": 4041629,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Lawrence Tech Univ.",
          "JobNumber": "502-813200-142",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21211 W 10 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "21211 W 10 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$949.76",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4041628,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ATTWOOD ELEMENTARY",
          "JobNumber": "502-230032-29",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "915 ATTWOOD DR.",
          "JobAddress2": "",
          "JobFullAddress": "915 ATTWOOD DR.",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "DSI ACOUSTICAL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$9,387.97",
          "BranchNumber": 7,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "DSI ACOUSTICAL"
        },
        {
          "WorkOrder": 4041627,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KROGER ESSEXVILLE",
          "JobNumber": "502-20010-31",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2910 CENTER AVE",
          "JobAddress2": "",
          "JobFullAddress": "2910 CENTER AVE",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$263.69",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4041626,
          "ParentWorkOrder": "",
          "JobName": "(NOF)JOHNSON CUSTOM HOMES",
          "JobNumber": "502-18879-15",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5080 TOOTMOOR RD",
          "JobAddress2": "",
          "JobFullAddress": "5080 TOOTMOOR RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SAVYER DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$7,843.87",
          "BranchNumber": 11,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "SAVYER DRYWALL LLC"
        },
        {
          "WorkOrder": 4041625,
          "ParentWorkOrder": "",
          "JobName": "(NOF)EAGLE RIDGE LOT 45",
          "JobNumber": "502-18879-14",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5451 MORGAN LAKE DR",
          "JobAddress2": "",
          "JobFullAddress": "5451 MORGAN LAKE DR",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SAVYER DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,906.00",
          "BalanceRemaining": "$5,092.05",
          "BranchNumber": 11,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "SAVYER DRYWALL LLC"
        },
        {
          "WorkOrder": 4041623,
          "ParentWorkOrder": "",
          "JobName": "(NOF)894 LAKE SHORE RD",
          "JobNumber": "502-1650969-85",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "894 S LAKE SHORE RD",
          "JobAddress2": "",
          "JobFullAddress": "894 S LAKE SHORE RD",
          "JobCounty": 48441,
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,408.78",
          "BranchNumber": 1,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 4041622,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 1450 E PONTIAC",
          "JobNumber": "502-1604171-16",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1450 E Pontiac",
          "JobAddress2": "",
          "JobFullAddress": "1450 E Pontiac",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING &",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "DISTINCTIVE GRADING &"
        },
        {
          "WorkOrder": 4041621,
          "ParentWorkOrder": "",
          "JobName": "(NOF)2840 W Hickory Grove",
          "JobNumber": "502-1604171-15",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2840 W Hickory Grove Rd",
          "JobAddress2": "",
          "JobFullAddress": "2840 W Hickory Grove Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,506.43",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4041620,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Aldi Shelby Township",
          "JobNumber": "502-1601233-3",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "47342 Van Dyke Ave",
          "JobAddress2": "",
          "JobFullAddress": "47342 Van Dyke Ave",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "ABUNDANT CONSTRUCTION SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$4,816.65",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "ABUNDANT CONSTRUCTION SERVICES"
        },
        {
          "WorkOrder": 4041619,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DRAGONFLY",
          "JobNumber": "502-1507798-49",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2929 E Grand Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2929 E Grand Blvd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$449.48",
          "BranchNumber": 9,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4041617,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SEWARD",
          "JobNumber": "502-1507798-47",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "741 Seward St",
          "JobAddress2": "",
          "JobFullAddress": "741 Seward St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,373.96",
          "BranchNumber": 9,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4041616,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LaMarco Homes",
          "JobNumber": "502-1311893-3",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6065 FRANKLIN RD",
          "JobAddress2": "",
          "JobFullAddress": "6065 FRANKLIN RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "STEFANI FINISH INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "STEFANI FINISH INC"
        },
        {
          "WorkOrder": 4041609,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Waterville-Monclova",
          "JobNumber": "502-1445958-36",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5503 WATERVILLE MONCLOVA RD",
          "JobAddress2": "",
          "JobFullAddress": "5503 WATERVILLE MONCLOVA RD",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "PREMIER BUILDERS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "PREMIER BUILDERS"
        },
        {
          "WorkOrder": 4041608,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Vanguard Career CNTR",
          "JobNumber": "502-1305825-28",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1306 Cedar Street",
          "JobAddress2": "",
          "JobFullAddress": "1306 Cedar Street",
          "JobCounty": "Sandusky",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,343.89",
          "BranchNumber": 13,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 4041607,
          "ParentWorkOrder": "",
          "JobName": "EXCELITAS OFFICE RENOV",
          "JobNumber": "65-2134688-18",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1100 VANGUARD BLVD",
          "JobAddress2": "",
          "JobFullAddress": "1100 VANGUARD BLVD",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVICE",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$13,581.05",
          "BranchNumber": 12,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "BENCO CONSTRUCTION SERVICE"
        },
        {
          "WorkOrder": 4041606,
          "ParentWorkOrder": "",
          "JobName": "NOF KNOWLEDGE WORKS",
          "JobNumber": "65-125599-3",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "312 PLUM ST FL 9",
          "JobAddress2": "",
          "JobFullAddress": "312 PLUM ST FL 9",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "ADVANCED ACOUSTICAL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$14,120.54",
          "BranchNumber": 12,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "ADVANCED ACOUSTICAL LLC"
        },
        {
          "WorkOrder": 4041605,
          "ParentWorkOrder": "",
          "JobName": "COLUMBUS NORTH",
          "JobNumber": "65-492299-3",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 E Arcadia Ave",
          "JobAddress2": "",
          "JobFullAddress": "100 E Arcadia Ave",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "KYROS DEVELOPMENT",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,377.77",
          "BranchNumber": 4,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "KYROS DEVELOPMENT"
        },
        {
          "WorkOrder": 4041604,
          "ParentWorkOrder": "",
          "JobName": "MY PLACE INN HOTEL",
          "JobNumber": "65-481001-2",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "299 MCMAHAN BLVD",
          "JobAddress2": "",
          "JobFullAddress": "299 MCMAHAN BLVD",
          "JobCounty": "Marion",
          "JobState": "Ohio",
          "CustomerCompanyName": "PYRAMID PAINTING AND DW",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,250.62",
          "BranchNumber": 4,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "PYRAMID PAINTING AND DW"
        },
        {
          "WorkOrder": 4041603,
          "ParentWorkOrder": "",
          "JobName": "ROW HOUSE",
          "JobNumber": "65-422745-86",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1635 W Lane Ave",
          "JobAddress2": "",
          "JobFullAddress": "1635 W Lane Ave",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "J. YANKLE COMPANY LTD.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$3,717.01",
          "BranchNumber": 4,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "J. YANKLE COMPANY LTD."
        },
        {
          "WorkOrder": 4041601,
          "ParentWorkOrder": "",
          "JobName": "KENWORTH",
          "JobNumber": "65-410450-2",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "65 KENWORTH DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "65 KENWORTH DRIVE",
          "JobCounty": "ROSS",
          "JobState": "Ohio",
          "CustomerCompanyName": "INGLE-BARR INCORPORATED",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 4,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "INGLE-BARR INCORPORATED"
        },
        {
          "WorkOrder": 4041600,
          "ParentWorkOrder": "",
          "JobName": "NOF PENSKE TRUCKING",
          "JobNumber": "65-555301-38",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2470 WESTBELT DR",
          "JobAddress2": "",
          "JobFullAddress": "2470 WESTBELT DR",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "M&M CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$56.44",
          "BranchNumber": 2,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "M&M CONSTRUCTION"
        },
        {
          "WorkOrder": 4041599,
          "ParentWorkOrder": "",
          "JobName": "NOF TURTLE CREEK TOWNSHIP",
          "JobNumber": "65-51919-2",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1550 N STATE RT 741",
          "JobAddress2": "",
          "JobFullAddress": "1550 N STATE RT 741",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "SUNFOREST BUILDING COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,675.21",
          "BranchNumber": 2,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "SUNFOREST BUILDING COMPANY"
        },
        {
          "WorkOrder": 4041598,
          "ParentWorkOrder": "",
          "JobName": "AMAZON DCM5",
          "JobNumber": "65-700193-5",
          "FirstdayontheJob": "06-30-2020",
          "LastdayontheJob": "06-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8591 Mink St SW",
          "JobAddress2": "",
          "JobFullAddress": "8591 Mink St SW",
          "JobCounty": "LICKING",
          "JobState": "Ohio",
          "CustomerCompanyName": "UNITED CONTRACTOR SERVICE",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$9,701.67",
          "BranchNumber": 1,
          "SubmitDate": "07-01-2020",
          "ClientonJob": "UNITED CONTRACTOR SERVICE"
        },
        {
          "WorkOrder": 4039995,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Wynford Middle/HS",
          "JobNumber": "502-160605-2",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3288 Holms Center Rd",
          "JobAddress2": "",
          "JobFullAddress": "3288 Holms Center Rd",
          "JobCounty": "CRAWFORD",
          "JobState": "Ohio",
          "CustomerCompanyName": "K DAVIS INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,912.48",
          "BranchNumber": 13,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "K DAVIS INC"
        },
        {
          "WorkOrder": 4039994,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Xfinity",
          "JobNumber": "502-134220-6",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3440 S Rochester Rd",
          "JobAddress2": "",
          "JobFullAddress": "3440 S Rochester Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Horizon Retail Construction Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$875.09",
          "BranchNumber": 13,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "Horizon Retail Construction Inc"
        },
        {
          "WorkOrder": 4039993,
          "ParentWorkOrder": "",
          "JobName": "P&G FHCIC LOBBY REMODEL",
          "JobNumber": "65-2135214-7",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5289 VINE STREET",
          "JobAddress2": "",
          "JobFullAddress": "5289 VINE STREET",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "AKA CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$8,005.00",
          "BalanceRemaining": "$416.80",
          "BranchNumber": 13,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "AKA CONSTRUCTION INC"
        },
        {
          "WorkOrder": 4039992,
          "ParentWorkOrder": "",
          "JobName": "NOF RIDGE HILL MANOR",
          "JobNumber": "65-2137106-4",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6346 MONTGOMERY RD",
          "JobAddress2": "",
          "JobFullAddress": "6346 MONTGOMERY RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "HAYS & SONS CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,804.91",
          "BranchNumber": 12,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "HAYS & SONS CONSTRUCTION"
        },
        {
          "WorkOrder": 4039991,
          "ParentWorkOrder": "",
          "JobName": "NOF WILLKOMMEN APTS",
          "JobNumber": "65-2134999-16",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1623 RACE ST",
          "JobAddress2": "",
          "JobFullAddress": "1623 RACE ST",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "REYES/TDR INTERIORS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$4,483.85",
          "BranchNumber": 12,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "REYES/TDR INTERIORS"
        },
        {
          "WorkOrder": 4039990,
          "ParentWorkOrder": "",
          "JobName": "4739 HALLWAY PROJECT",
          "JobNumber": "65-407717-3",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4739 HILTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "4739 HILTON AVE",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Olympic Realty LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,860.20",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "Olympic Realty LLC"
        },
        {
          "WorkOrder": 4039989,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Pinnacle Treat. Ctr",
          "JobNumber": "65-400641-2",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7400 HUNTINGTON PARK DR",
          "JobAddress2": "",
          "JobFullAddress": "7400 HUNTINGTON PARK DR",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Miller Site Mgmt LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$7,214.65",
          "BranchNumber": 4,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "Miller Site Mgmt LLC"
        },
        {
          "WorkOrder": 4039988,
          "ParentWorkOrder": "",
          "JobName": "NOF ZIPS CARWASH",
          "JobNumber": "65-503145-6",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12167 MONTOGOMERY RD",
          "JobAddress2": "",
          "JobFullAddress": "12167 MONTOGOMERY RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "WILD BUILDING CONTRACTOR",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,529.18",
          "BranchNumber": 2,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "WILD BUILDING CONTRACTOR"
        },
        {
          "WorkOrder": 4039987,
          "ParentWorkOrder": "",
          "JobName": "NOF ZIPS CARWASH DIXIE",
          "JobNumber": "65-503145-5",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5274 DIXIE HIGHWAY",
          "JobAddress2": "",
          "JobFullAddress": "5274 DIXIE HIGHWAY",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "WILD BUILDING CONTRACTOR",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$15,553.76",
          "BranchNumber": 2,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "WILD BUILDING CONTRACTOR"
        },
        {
          "WorkOrder": 4039986,
          "ParentWorkOrder": "",
          "JobName": "CHANCE HOMES",
          "JobNumber": "65-501978-6",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "499 S NIXON CAMP RD",
          "JobAddress2": "",
          "JobFullAddress": "499 S NIXON CAMP RD",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "CHANCE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 2,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "CHANCE CONSTRUCTION"
        },
        {
          "WorkOrder": 4039971,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Charles Parcells MS",
          "JobNumber": "502-1609003-3",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20600 Mack Ave",
          "JobAddress2": "",
          "JobFullAddress": "20600 Mack Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "North American Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$1,017.52",
          "BranchNumber": 9,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "North American Construction"
        },
        {
          "WorkOrder": 4039970,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CATHERAL TOWERS",
          "JobNumber": "502-1325000-27",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "80 E HANCOCK",
          "JobAddress2": "",
          "JobFullAddress": "80 E HANCOCK",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,139.57",
          "BranchNumber": 9,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4039969,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FRESENIUS CARE",
          "JobNumber": "502-934634-17",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19701 VERNIER RD",
          "JobAddress2": "",
          "JobFullAddress": "19701 VERNIER RD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONST. GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$55,006.00",
          "BalanceRemaining": "$5,907.58",
          "BranchNumber": 9,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "BLUESTONE CONST. GROUP"
        },
        {
          "WorkOrder": 4039968,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Niles Comm School",
          "JobNumber": "502-932775-66",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "201 W Square Lake Road",
          "JobAddress2": "",
          "JobFullAddress": "201 W Square Lake Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TLA INTERIORS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "TLA INTERIORS, INC."
        },
        {
          "WorkOrder": 4039967,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Taco Bell Hillsdale",
          "JobNumber": "502-920300-97",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3011 W Carleton Rd",
          "JobAddress2": "",
          "JobFullAddress": "3011 W Carleton Rd",
          "JobCounty": "HILLSDALE",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$10,660.41",
          "BranchNumber": 9,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 4039966,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TRAVELERS TOWER CAFE",
          "JobNumber": "502-920000-29",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26555 EVERGREEN RD",
          "JobAddress2": "",
          "JobFullAddress": "26555 EVERGREEN RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "NVR FINISHES, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,340.26",
          "BranchNumber": 9,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "NVR FINISHES, INC."
        },
        {
          "WorkOrder": 4039965,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TACO BELL JACKSON",
          "JobNumber": "502-916315-169",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "801 W Ganson St",
          "JobAddress2": "",
          "JobFullAddress": "801 W Ganson St",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commercial Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$488.44",
          "BranchNumber": 9,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "Independence Commercial Construction"
        },
        {
          "WorkOrder": 4039964,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Canton Plaza Facade",
          "JobNumber": "502-916315-167",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45188 Ford Rd",
          "JobAddress2": "",
          "JobFullAddress": "45188 Ford Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Independence Commercial Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$9,798.48",
          "BranchNumber": 9,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "Independence Commercial Construction"
        },
        {
          "WorkOrder": 4039963,
          "ParentWorkOrder": "",
          "JobName": "NOF LOURDES REHAB",
          "JobNumber": "502-910050-57",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2300 WATKINS LAKE RD",
          "JobAddress2": "",
          "JobFullAddress": "2300 WATKINS LAKE RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$2,252.29",
          "BranchNumber": 9,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 4039962,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SUN FUN POOLS",
          "JobNumber": "502-1604171-14",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "345 N GROESBECK HWY",
          "JobAddress2": "",
          "JobFullAddress": "345 N GROESBECK HWY",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$597.28",
          "BranchNumber": 8,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4039961,
          "ParentWorkOrder": "",
          "JobName": "(NOF)7711 Knolls",
          "JobNumber": "502-1604171-13",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7711 Knolls Dr",
          "JobAddress2": "",
          "JobFullAddress": "7711 Knolls Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4039960,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Phase2-Card Med.Bld.",
          "JobNumber": "502-947437-3",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45200 Card Road",
          "JobAddress2": "",
          "JobFullAddress": "45200 Card Road",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PMP Developers LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$413.72",
          "BranchNumber": 8,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "PMP Developers LLC"
        },
        {
          "WorkOrder": 4039959,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Romulus High School",
          "JobNumber": "502-920940-87",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9650 Wayne Rd",
          "JobAddress2": "",
          "JobFullAddress": "9650 Wayne Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$16,006.00",
          "BalanceRemaining": "$15,687.72",
          "BranchNumber": 8,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4039958,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Hampton INN Adrian",
          "JobNumber": "502-877300-37",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1335 South Main ST",
          "JobAddress2": "",
          "JobFullAddress": "1335 South Main ST",
          "JobCounty": "LENAWEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR DESIGNS INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,696.41",
          "BranchNumber": 8,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "SUPERIOR DESIGNS INC."
        },
        {
          "WorkOrder": 4039957,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DOLLAR TREE WESTLAND",
          "JobNumber": "502-876752-15",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8208 N MERRIMAN RD",
          "JobAddress2": "",
          "JobFullAddress": "8208 N MERRIMAN RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,506.00",
          "BalanceRemaining": "$2,009.59",
          "BranchNumber": 8,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 4039956,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(265)Schoolcraft Ele",
          "JobNumber": "502-816350-154",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6400 Maceday Dr",
          "JobAddress2": "",
          "JobFullAddress": "6400 Maceday Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$17,486.93",
          "BranchNumber": 8,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4039955,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Golfview Apts",
          "JobNumber": "502-815630-239",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "823 Golf DR",
          "JobAddress2": "",
          "JobFullAddress": "823 Golf DR",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4039954,
          "ParentWorkOrder": "",
          "JobName": "(NOF)202017 SJP Macomb 2W",
          "JobNumber": "502-815250-372",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11800 12 Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "11800 12 Mile Road",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4039953,
          "ParentWorkOrder": "",
          "JobName": "(NOF)202019 SJP 1STFLTOIL",
          "JobNumber": "502-815250-371",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11800 E 12 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "11800 E 12 MILE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,678.09",
          "BranchNumber": 8,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4039952,
          "ParentWorkOrder": "",
          "JobName": "(NOF)202008-01 Nexcore",
          "JobNumber": "502-815250-366",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "36555 26 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "36555 26 Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$195,006.00",
          "BalanceRemaining": "$932.80",
          "BranchNumber": 8,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4039951,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3129 Oakland ElemSch",
          "JobNumber": "502-813200-137",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2415 Brockton Ave",
          "JobAddress2": "",
          "JobFullAddress": "2415 Brockton Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$1,357.99",
          "BranchNumber": 8,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4039950,
          "ParentWorkOrder": "",
          "JobName": "NOF Ryan's House Repair",
          "JobNumber": "502-990401-63",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7007 LANSING AVE",
          "JobAddress2": "",
          "JobFullAddress": "7007 LANSING AVE",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$386.56",
          "BranchNumber": 7,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4039949,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HARTUNG RESIDENCE",
          "JobNumber": "502-967000-13",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11291 TEC-CLINTON HWY",
          "JobAddress2": "",
          "JobFullAddress": "11291 TEC-CLINTON HWY",
          "JobCounty": "LENAWEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRENNERS DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,085.24",
          "BranchNumber": 7,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "BRENNERS DRYWALL"
        },
        {
          "WorkOrder": 4039948,
          "ParentWorkOrder": "",
          "JobName": "(NOF)9704 SHORE DR",
          "JobNumber": "502-1650969-84",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9704 N SHORE DR",
          "JobAddress2": "",
          "JobFullAddress": "9704 N SHORE DR",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,506.00",
          "BalanceRemaining": "$4,481.29",
          "BranchNumber": 1,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 4039947,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LEO'S CONEY ISLAND",
          "JobNumber": "502-148155-2",
          "FirstdayontheJob": "06-24-2020",
          "LastdayontheJob": "06-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11470 N LINDEN RD",
          "JobAddress2": "",
          "JobFullAddress": "11470 N LINDEN RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "METRO GENERAL CONTRACTOR INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "METRO GENERAL CONTRACTOR INC"
        },
        {
          "WorkOrder": 4039946,
          "ParentWorkOrder": "",
          "JobName": "BOBBY CRAFT",
          "JobNumber": "114-1381217-2",
          "FirstdayontheJob": "06-19-2020",
          "LastdayontheJob": "06-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "219 River Rd N",
          "JobAddress2": "",
          "JobFullAddress": "219 River Rd N",
          "JobCounty": "HINDS",
          "JobState": "Mississippi",
          "CustomerCompanyName": "CRAFTWELL CONSTRUCTION",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$3,117.08",
          "BranchNumber": 13,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "CRAFTWELL CONSTRUCTION"
        },
        {
          "WorkOrder": 4039945,
          "ParentWorkOrder": "",
          "JobName": "DOLLAR GENERAL CLARKSDALE",
          "JobNumber": "114-1389795-5",
          "FirstdayontheJob": "06-17-2020",
          "LastdayontheJob": "06-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "236 DESOTO AVE",
          "JobAddress2": "",
          "JobFullAddress": "236 DESOTO AVE",
          "JobCounty": "COAHOMA",
          "JobState": "Mississippi",
          "CustomerCompanyName": "Mike Rozier Construcion",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$7,073.04",
          "BranchNumber": 13,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "Mike Rozier Construcion"
        },
        {
          "WorkOrder": 4039944,
          "ParentWorkOrder": "",
          "JobName": "ROBERT E LEE BUILDING",
          "JobNumber": "114-1380183-4",
          "FirstdayontheJob": "06-09-2020",
          "LastdayontheJob": "06-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "239 N LAMAR ST",
          "JobAddress2": "",
          "JobFullAddress": "239 N LAMAR ST",
          "JobCounty": "HINDS",
          "JobState": "Mississippi",
          "CustomerCompanyName": "SHANE ORMON CONSTRUCTION",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$2,311.06",
          "BranchNumber": 13,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "SHANE ORMON CONSTRUCTION"
        },
        {
          "WorkOrder": 4039943,
          "ParentWorkOrder": "",
          "JobName": "BAPTIST CLINIC - PEARL",
          "JobNumber": "114-1380183-3",
          "FirstdayontheJob": "06-09-2020",
          "LastdayontheJob": "06-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "110 S PEARSON ROAD",
          "JobAddress2": "",
          "JobFullAddress": "110 S PEARSON ROAD",
          "JobCounty": "RANKIN",
          "JobState": "Mississippi",
          "CustomerCompanyName": "SHANE ORMON CONSTRUCTION",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$20,073.68",
          "BranchNumber": 13,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "SHANE ORMON CONSTRUCTION"
        },
        {
          "WorkOrder": 4039942,
          "ParentWorkOrder": "",
          "JobName": "FAMILY DOLLAR #32474",
          "JobNumber": "114-1312139-36",
          "FirstdayontheJob": "06-02-2020",
          "LastdayontheJob": "06-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20482 HWY 61",
          "JobAddress2": "",
          "JobFullAddress": "20482 HWY 61",
          "JobCounty": "SHARKEY",
          "JobState": "Mississippi",
          "CustomerCompanyName": "VISION CONTRACTORS INC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$12,931.99",
          "BranchNumber": 14,
          "SubmitDate": "06-25-2020",
          "ClientonJob": "VISION CONTRACTORS INC"
        },
        {
          "WorkOrder": 4039421,
          "ParentWorkOrder": 4024362,
          "JobName": "(NOF) Bay View Villas",
          "JobNumber": "502-1609034-44",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2599 S Waterside Ct",
          "JobAddress2": "",
          "JobFullAddress": "2599 S Waterside Ct",
          "JobCounty": "OTTAWA",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$575.16",
          "BranchNumber": 13,
          "SubmitDate": "06-23-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4038369,
          "ParentWorkOrder": 4024507,
          "JobName": "(NOF)Blossom Springs",
          "JobNumber": "502-847401-148",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3215 SilverBell Rd",
          "JobAddress2": "",
          "JobFullAddress": "3215 SilverBell Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "06-19-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4038368,
          "ParentWorkOrder": 4036387,
          "JobName": "(NOF)20-2CL 13",
          "JobNumber": "502-12280-16",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "407 COPPER LEAF CT",
          "JobAddress2": "",
          "JobFullAddress": "407 COPPER LEAF CT",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDLAND DRYWALL CORP",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$2,506.00",
          "BalanceRemaining": "$737.72",
          "BranchNumber": 1,
          "SubmitDate": "",
          "ClientonJob": "MIDLAND DRYWALL CORP"
        },
        {
          "WorkOrder": 4038063,
          "ParentWorkOrder": "",
          "JobName": "NAVY FEDERAL CREDIT UNION",
          "JobNumber": "114-311637-5",
          "FirstdayontheJob": "03-06-2020",
          "LastdayontheJob": "03-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9070 W HWY 98",
          "JobAddress2": "",
          "JobFullAddress": "9070 W HWY 98",
          "JobCounty": "ESCAMBIA",
          "JobState": "Florida",
          "CustomerCompanyName": "SOUTHEASTERN DRYWALL LLC",
          "RequestType": "Notice to Owner/Notice to Contractor",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,521.47",
          "BranchNumber": 1,
          "SubmitDate": "06-18-2020",
          "ClientonJob": "SOUTHEASTERN DRYWALL LLC"
        },
        {
          "WorkOrder": 4037901,
          "ParentWorkOrder": "",
          "JobName": "NOF SAVANNAH LOT 28",
          "JobNumber": "502-18879-11",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1880 WHITE PINE WAY",
          "JobAddress2": "",
          "JobFullAddress": "1880 WHITE PINE WAY",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SAVYER DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,306.00",
          "BalanceRemaining": "$5,211.44",
          "BranchNumber": 11,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "SAVYER DRYWALL LLC"
        },
        {
          "WorkOrder": 4037900,
          "ParentWorkOrder": "",
          "JobName": "NOF SAVANNAH LOT 24",
          "JobNumber": "502-18879-10",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1790 WHITE PINE WAY",
          "JobAddress2": "",
          "JobFullAddress": "1790 WHITE PINE WAY",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SAVYER DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,306.00",
          "BalanceRemaining": "$5,211.44",
          "BranchNumber": 11,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "SAVYER DRYWALL LLC"
        },
        {
          "WorkOrder": 4037899,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LADYWOOD",
          "JobNumber": "502-1507798-46",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14680 NEWBURG RD",
          "JobAddress2": "",
          "JobFullAddress": "14680 NEWBURG RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4037898,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WCCCD WELCOME CENTER",
          "JobNumber": "502-939250-52",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8200 OUTER DRIVE W",
          "JobAddress2": "",
          "JobFullAddress": "8200 OUTER DRIVE W",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.C.P.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$9,795.69",
          "BranchNumber": 9,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "D.C.P."
        },
        {
          "WorkOrder": 4037897,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MOTOWN HITSVILLE NXT",
          "JobNumber": "502-937250-160",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2654 W GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "2654 W GRAND RIVER AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINKER TEAM CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$9,808.75",
          "BranchNumber": 9,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "BRINKER TEAM CONSTRUCTION"
        },
        {
          "WorkOrder": 4037896,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MLK Center",
          "JobNumber": "502-919700-139",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1107 Adrian St",
          "JobAddress2": "",
          "JobFullAddress": "1107 Adrian St",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 4037894,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Warren Commerce Cent",
          "JobNumber": "502-916315-168",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24400 Dequindre Rd",
          "JobAddress2": "",
          "JobFullAddress": "24400 Dequindre Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$10,917.82",
          "BranchNumber": 9,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4037893,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Culvers Ludington",
          "JobNumber": "502-916315-163",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5089 W US 10",
          "JobAddress2": "",
          "JobFullAddress": "5089 W US 10",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4037892,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DEVINE CHILD SUMMER",
          "JobNumber": "502-910868-62",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25001 HERBERT WEIER DR",
          "JobAddress2": "",
          "JobFullAddress": "25001 HERBERT WEIER DR",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construct",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,851.97",
          "BranchNumber": 9,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "American Family Construct"
        },
        {
          "WorkOrder": 4037891,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Whitehouse",
          "JobNumber": "502-1604171-12",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2581 MEADOWVIEW CT",
          "JobAddress2": "",
          "JobFullAddress": "2581 MEADOWVIEW CT",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$2,188.07",
          "BranchNumber": 8,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4037890,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FAMILY DOLLAR",
          "JobNumber": "502-876752-14",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "312 S JAMES ST",
          "JobAddress2": "",
          "JobFullAddress": "312 S JAMES ST",
          "JobCounty": "CRAWFORD",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,263.54",
          "BranchNumber": 8,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 4037888,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TOWN COURT APARTMENT",
          "JobNumber": "502-839201-37",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7110 ORCHARD LAKE RD",
          "JobAddress2": "",
          "JobFullAddress": "7110 ORCHARD LAKE RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLINTON INTERIORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$7,283.82",
          "BranchNumber": 8,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "CLINTON INTERIORS INC"
        },
        {
          "WorkOrder": 4037887,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(267)Athens High Sch",
          "JobNumber": "502-816350-152",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4333 John R Rd",
          "JobAddress2": "",
          "JobFullAddress": "4333 John R Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$435.43",
          "BranchNumber": 8,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4037886,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-2025 AH GENESYS",
          "JobNumber": "502-815250-370",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 GENESYS PARKWAY",
          "JobAddress2": "",
          "JobFullAddress": "1 GENESYS PARKWAY",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,735.77",
          "BranchNumber": 8,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4037884,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Brighton Fire Dept.",
          "JobNumber": "502-814500-83",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2755 Dorr Rd",
          "JobAddress2": "",
          "JobFullAddress": "2755 Dorr Rd",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$10,271.07",
          "BranchNumber": 8,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "COMMERCIAL INT CONST"
        },
        {
          "WorkOrder": 4037883,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GLANBIA FOODS DAIRY",
          "JobNumber": "502-20010-30",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1300 E Walker Rd",
          "JobAddress2": "",
          "JobFullAddress": "1300 E Walker Rd",
          "JobCounty": "CLINTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,179.38",
          "BranchNumber": 8,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4037881,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ABC PHARMACY",
          "JobNumber": "502-20010-29",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5446 LAPEER RD",
          "JobAddress2": "",
          "JobFullAddress": "5446 LAPEER RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,506.00",
          "BalanceRemaining": "$2,000.45",
          "BranchNumber": 8,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4037880,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KROGER CLARKSTON",
          "JobNumber": "502-20010-28",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9741 DIXIE HIGHWAY",
          "JobAddress2": "",
          "JobFullAddress": "9741 DIXIE HIGHWAY",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$772.41",
          "BranchNumber": 8,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 4037879,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DAR Lansing",
          "JobNumber": "502-230032-28",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5656 S Cedar St",
          "JobAddress2": "",
          "JobFullAddress": "5656 S Cedar St",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "DSI ACOUSTICAL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$55,006.00",
          "BalanceRemaining": "$4,694.75",
          "BranchNumber": 7,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "DSI ACOUSTICAL"
        },
        {
          "WorkOrder": 4037877,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Andy's Place",
          "JobNumber": "502-1610483-10",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2388 W Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "2388 W Michigan Ave",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARTIN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "MARTIN DRYWALL INC"
        },
        {
          "WorkOrder": 4037876,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WALGREENSSAGINAW",
          "JobNumber": "502-162032-2",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3730 DIXIE HIGHWAY",
          "JobAddress2": "",
          "JobFullAddress": "3730 DIXIE HIGHWAY",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "WALLACE BUILDERS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "WALLACE BUILDERS"
        },
        {
          "WorkOrder": 4037664,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WESTGATE LIQUOR STOR",
          "JobNumber": "502-1609034-47",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3324 SECOR RD",
          "JobAddress2": "",
          "JobFullAddress": "3324 SECOR RD",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,714.21",
          "BranchNumber": 13,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4037662,
          "ParentWorkOrder": "",
          "JobName": "BCBS TRANSPORTATION",
          "JobNumber": "65-2134669-6",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1260 MARR RD",
          "JobAddress2": "",
          "JobFullAddress": "1260 MARR RD",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "MCCOOL PLASTER AND DRYWAL",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "On Hold",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,135.21",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "MCCOOL PLASTER AND DRYWAL"
        },
        {
          "WorkOrder": 4037660,
          "ParentWorkOrder": "",
          "JobName": "CVS CENTERVILLE  40019",
          "JobNumber": "65-4077685-34",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5981 FAR HILLS AVE",
          "JobAddress2": "",
          "JobFullAddress": "5981 FAR HILLS AVE",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "FORTNEY & WEYGANDT,INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$986.93",
          "BranchNumber": 4,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "FORTNEY & WEYGANDT,INC"
        },
        {
          "WorkOrder": 4037659,
          "ParentWorkOrder": "",
          "JobName": "4739 HALLWAY PROJECT",
          "JobNumber": "65-407717-3",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4739 HILTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "4739 HILTON AVE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Olympic Realty LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,860.20",
          "BranchNumber": 4,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "Olympic Realty LLC"
        },
        {
          "WorkOrder": 4037658,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Pinnacle Treat. Ctr",
          "JobNumber": "65-400641-2",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7400 HUNTINGTON PARK DR",
          "JobAddress2": "",
          "JobFullAddress": "7400 HUNTINGTON PARK DR",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Miller Site Mgmt LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$7,214.65",
          "BranchNumber": 4,
          "SubmitDate": "06-17-2020",
          "ClientonJob": "Miller Site Mgmt LLC"
        },
        {
          "WorkOrder": 4037657,
          "ParentWorkOrder": "",
          "JobName": "Best Western Plus",
          "JobNumber": "65-71425-2",
          "FirstdayontheJob": "06-16-2020",
          "LastdayontheJob": "06-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1425 Broadway Street",
          "JobAddress2": "",
          "JobFullAddress": "1425 Broadway Street",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "XENIA CLARKSVILLE",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "On Hold",
          "ContractAmount": "$130,006.00",
          "BalanceRemaining": "$2,867.40",
          "BranchNumber": 1,
          "SubmitDate": "",
          "ClientonJob": "XENIA CLARKSVILLE"
        },
        {
          "WorkOrder": 4036387,
          "ParentWorkOrder": 4030633,
          "JobName": "(NOF)20-2CL 13",
          "JobNumber": "502-12280-16",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "407 COPPER LEAF CT",
          "JobAddress2": "",
          "JobFullAddress": "407 COPPER LEAF CT",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDLAND DRYWALL CORP",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,506.00",
          "BalanceRemaining": "$737.72",
          "BranchNumber": 1,
          "SubmitDate": "06-12-2020",
          "ClientonJob": "MIDLAND DRYWALL CORP"
        },
        {
          "WorkOrder": 4036267,
          "ParentWorkOrder": 4011629,
          "JobName": "(NOF)Stoney Creek High S",
          "JobNumber": "502-920940-80",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6755 Sheldon Rd.",
          "JobAddress2": "",
          "JobFullAddress": "6755 Sheldon Rd.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$85,006.00",
          "BalanceRemaining": "$1,061.87",
          "BranchNumber": 8,
          "SubmitDate": "06-12-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4036158,
          "ParentWorkOrder": 4024654,
          "JobName": "(NJP) RIVERSIDE TRAIL",
          "JobNumber": "65-463111-5",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5785 CATTERTON RD",
          "JobAddress2": "",
          "JobFullAddress": "5785 CATTERTON RD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "HERMAN & KITTLE PROPERTIES , Inc.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$87,238.80",
          "BranchNumber": 4,
          "SubmitDate": "06-12-2020",
          "ClientonJob": "HERMAN & KITTLE PROPERTIES , Inc."
        },
        {
          "WorkOrder": 4036050,
          "ParentWorkOrder": 4007949,
          "JobName": "NOF UC CRC MULTI ROOM",
          "JobNumber": "65-2134669-5",
          "FirstdayontheJob": "03-03-2020",
          "LastdayontheJob": "03-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2840 BEARCAT WAY",
          "JobAddress2": "",
          "JobFullAddress": "2840 BEARCAT WAY",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "MCCOOL PLASTER AND DRYWAL",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,387.94",
          "BranchNumber": 12,
          "SubmitDate": "06-12-2020",
          "ClientonJob": "MCCOOL PLASTER AND DRYWAL"
        },
        {
          "WorkOrder": 4035939,
          "ParentWorkOrder": "",
          "JobName": "NUNEZ COMMUNITY COLLEGE",
          "JobNumber": "114-1334600-5",
          "FirstdayontheJob": "03-27-2020",
          "LastdayontheJob": "04-06-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3710 PARIS RD",
          "JobAddress2": "BLDG C",
          "JobFullAddress": "3710 PARIS RD BLDG C",
          "JobCounty": "",
          "JobState": "Louisiana",
          "CustomerCompanyName": "BOASSO CONSTRUCTION LLC",
          "RequestType": "Notice of Non-Payment (Suppliers Only)",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Stopped",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,848.43",
          "BranchNumber": 14,
          "SubmitDate": "",
          "ClientonJob": "BOASSO CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 4035849,
          "ParentWorkOrder": 4021044,
          "JobName": "FAIRBORN II",
          "JobNumber": "65-131137-5",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2200 COMMERCE CENTER BLVD",
          "JobAddress2": "",
          "JobFullAddress": "2200 COMMERCE CENTER BLVD",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "A PLUS PAINTERS LLC",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$100,005.00",
          "BalanceRemaining": "$47,023.44",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "A PLUS PAINTERS LLC"
        },
        {
          "WorkOrder": 4035812,
          "ParentWorkOrder": "",
          "JobName": "Navy Federal Credit Union",
          "JobNumber": "114-311637-4",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4891 Highway 90",
          "JobAddress2": "",
          "JobFullAddress": "4891 Highway 90",
          "JobCounty": "SANTA ROSA",
          "JobState": "Florida",
          "CustomerCompanyName": "Southeastern Drywall LLC",
          "RequestType": "Notice to Owner/Notice to Contractor",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$30,000.00",
          "BalanceRemaining": "$507.38",
          "BranchNumber": 1,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "Southeastern Drywall LLC"
        },
        {
          "WorkOrder": 4035726,
          "ParentWorkOrder": "",
          "JobName": "(NOF) JUNODS",
          "JobNumber": "502-859751-2",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7505 Maple Ridge Drive",
          "JobAddress2": "",
          "JobFullAddress": "7505 Maple Ridge Drive",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PIERSON - GIBBS HOMES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$7,600.39",
          "BranchNumber": 11,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "PIERSON - GIBBS HOMES"
        },
        {
          "WorkOrder": 4035725,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Venture X Detroit",
          "JobNumber": "502-934634-16",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "220 W CONGRESS ST",
          "JobAddress2": "",
          "JobFullAddress": "220 W CONGRESS ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONST. GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "BLUESTONE CONST. GROUP"
        },
        {
          "WorkOrder": 4035724,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WASHINGTON TWP FIRE",
          "JobNumber": "502-933550-187",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "61111 MOUND RD",
          "JobAddress2": "",
          "JobFullAddress": "61111 MOUND RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$6,875.19",
          "BranchNumber": 9,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4035723,
          "ParentWorkOrder": "",
          "JobName": "(NOF)#1617 TIFFANY & CO",
          "JobNumber": "502-913820-578",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$8,367.70",
          "BranchNumber": 9,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4035722,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1580 VRS",
          "JobNumber": "502-913820-543",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8562 N HOLLY ROAD",
          "JobAddress2": "",
          "JobFullAddress": "8562 N HOLLY ROAD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$10,588.75",
          "BranchNumber": 9,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4035721,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CENTERLINE ACADEMY",
          "JobNumber": "502-910868-66",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8155 Ritter",
          "JobAddress2": "",
          "JobFullAddress": "8155 Ritter",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construct",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,220.84",
          "BranchNumber": 9,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "American Family Construct"
        },
        {
          "WorkOrder": 4035720,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BRYANT ELEMENTARY",
          "JobNumber": "502-910050-56",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2150 Santa Rosa Dr",
          "JobAddress2": "",
          "JobFullAddress": "2150 Santa Rosa Dr",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,861.65",
          "BranchNumber": 9,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 4035719,
          "ParentWorkOrder": "",
          "JobName": "(NOF)XFINITY",
          "JobNumber": "502-1676767-2",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3044 S Rochester Rd",
          "JobAddress2": "",
          "JobFullAddress": "3044 S Rochester Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRAMMELL COMMERCIAL INTERIOR",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,041.20",
          "BranchNumber": 8,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "TRAMMELL COMMERCIAL INTERIOR"
        },
        {
          "WorkOrder": 4035718,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3801 Royal Ave",
          "JobNumber": "502-1647906-19",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3801 Royal Ave",
          "JobAddress2": "",
          "JobFullAddress": "3801 Royal Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "V & S QUALITY DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "V & S QUALITY DRYWALL INC"
        },
        {
          "WorkOrder": 4035717,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Kevadiya Properties",
          "JobNumber": "502-1604171-11",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 N SAGINAW",
          "JobAddress2": "",
          "JobFullAddress": "100 N SAGINAW",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,153.43",
          "BranchNumber": 8,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4035716,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Lisa Rybak",
          "JobNumber": "502-1604171-10",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20035 WARRINGTON DR",
          "JobAddress2": "",
          "JobFullAddress": "20035 WARRINGTON DR",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,876.43",
          "BranchNumber": 8,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4035715,
          "ParentWorkOrder": "",
          "JobName": "(NOF)United Shore",
          "JobNumber": "502-843701-216",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "585 South Blvd E",
          "JobAddress2": "",
          "JobFullAddress": "585 South Blvd E",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$1,139.48",
          "BranchNumber": 8,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 4035714,
          "ParentWorkOrder": "",
          "JobName": "(NOF)363 Lakeland",
          "JobNumber": "502-816400-190",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "363 Lakeland St",
          "JobAddress2": "",
          "JobFullAddress": "363 Lakeland St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELITE DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$10,338.61",
          "BranchNumber": 8,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "ELITE DRYWALL"
        },
        {
          "WorkOrder": 4035713,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(269)Carpenter Elem.",
          "JobNumber": "502-816350-157",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2290 Flintridge St",
          "JobAddress2": "",
          "JobFullAddress": "2290 Flintridge St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,231.66",
          "BranchNumber": 8,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4035712,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(267)Wattles Elem.",
          "JobNumber": "502-816350-151",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3555 Ellenboro Dr",
          "JobAddress2": "",
          "JobFullAddress": "3555 Ellenboro Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,097.67",
          "BranchNumber": 8,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4035711,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(267)InternationalAE",
          "JobNumber": "502-816350-150",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1291 Torpey Dr",
          "JobAddress2": "",
          "JobFullAddress": "1291 Torpey Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,900.88",
          "BranchNumber": 8,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4035710,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ORKIN CORNERS",
          "JobNumber": "502-1612480-3",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1161 E Clark Rd",
          "JobAddress2": "",
          "JobFullAddress": "1161 E Clark Rd",
          "JobCounty": "Clinton",
          "JobState": "Michigan",
          "CustomerCompanyName": "LOUIS J EYDE FAMILY LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,513.73",
          "BranchNumber": 7,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "LOUIS J EYDE FAMILY LLC"
        },
        {
          "WorkOrder": 4035709,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DUBLIN PARTY STORE",
          "JobNumber": "502-163270-2",
          "FirstdayontheJob": "06-10-2020",
          "LastdayontheJob": "06-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18372 Hoxeyville Rd",
          "JobAddress2": "",
          "JobFullAddress": "18372 Hoxeyville Rd",
          "JobCounty": "MANISTEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BOSSMAN CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$43,503.95",
          "BranchNumber": 1,
          "SubmitDate": "06-11-2020",
          "ClientonJob": "BOSSMAN CONSTRUCTION CO"
        },
        {
          "WorkOrder": 4035619,
          "ParentWorkOrder": 4021044,
          "JobName": "FAIRBORN II",
          "JobNumber": "65-131137-5",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2200 COMMERCE CENTER BLVD",
          "JobAddress2": "",
          "JobFullAddress": "2200 COMMERCE CENTER BLVD",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "A PLUS PAINTERS LLC",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,005.00",
          "BalanceRemaining": "$47,023.44",
          "BranchNumber": 13,
          "SubmitDate": "06-10-2020",
          "ClientonJob": "A PLUS PAINTERS LLC"
        },
        {
          "WorkOrder": 4035501,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Humane Ohio",
          "JobNumber": "502-1609034-45",
          "FirstdayontheJob": "06-09-2020",
          "LastdayontheJob": "06-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3948 W Alexis Rd",
          "JobAddress2": "",
          "JobFullAddress": "3948 W Alexis Rd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,813.82",
          "BranchNumber": 13,
          "SubmitDate": "06-10-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4035500,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Autozone Ontario",
          "JobNumber": "502-1304186-61",
          "FirstdayontheJob": "06-09-2020",
          "LastdayontheJob": "06-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "701 N Lexington Springmil Rd",
          "JobAddress2": "",
          "JobFullAddress": "701 N Lexington Springmil Rd",
          "JobCounty": "RICHLAND",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,098.71",
          "BranchNumber": 13,
          "SubmitDate": "06-10-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4035499,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Blackman Apartments",
          "JobNumber": "502-1084306-6",
          "FirstdayontheJob": "06-09-2020",
          "LastdayontheJob": "06-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2659 RUFF RD",
          "JobAddress2": "",
          "JobFullAddress": "2659 RUFF RD",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "T&B CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$2,508.78",
          "BranchNumber": 13,
          "SubmitDate": "06-10-2020",
          "ClientonJob": "T&B CONSTRUCTION"
        },
        {
          "WorkOrder": 4035498,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WSOS SUTTON CENTER",
          "JobNumber": "502-132412-11",
          "FirstdayontheJob": "06-09-2020",
          "LastdayontheJob": "06-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1848 E PERRY ST",
          "JobAddress2": "",
          "JobFullAddress": "1848 E PERRY ST",
          "JobCounty": "OTTAWA",
          "JobState": "Ohio",
          "CustomerCompanyName": "D&G Focht Construction Co",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,243.64",
          "BranchNumber": 13,
          "SubmitDate": "06-10-2020",
          "ClientonJob": "D&G Focht Construction Co"
        },
        {
          "WorkOrder": 4035497,
          "ParentWorkOrder": "",
          "JobName": "NOF OAK ST HEALTH",
          "JobNumber": "65-2134688-17",
          "FirstdayontheJob": "06-09-2020",
          "LastdayontheJob": "06-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2429 HARRISON AVE",
          "JobAddress2": "",
          "JobFullAddress": "2429 HARRISON AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$9,868.35",
          "BranchNumber": 12,
          "SubmitDate": "06-10-2020",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 4035496,
          "ParentWorkOrder": "",
          "JobName": "TACO BELL",
          "JobNumber": "65-72136-3",
          "FirstdayontheJob": "06-09-2020",
          "LastdayontheJob": "06-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10800 HEBRON RD",
          "JobAddress2": "",
          "JobFullAddress": "10800 HEBRON RD",
          "JobCounty": "LICKING",
          "JobState": "Ohio",
          "CustomerCompanyName": "EXTERIOR SOLUTIONS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$7,758.37",
          "BranchNumber": 7,
          "SubmitDate": "06-10-2020",
          "ClientonJob": "EXTERIOR SOLUTIONS"
        },
        {
          "WorkOrder": 4035449,
          "ParentWorkOrder": 4022426,
          "JobName": "HIGHLAND DIST. HOSPITAL",
          "JobNumber": "65-502280-62",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1275 N. HIGH STREET",
          "JobAddress2": "",
          "JobFullAddress": "1275 N. HIGH STREET",
          "JobCounty": "Highland",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALTIES INC",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "06-10-2020",
          "ClientonJob": "COMBS INTERIOR SPECIALTIES INC"
        },
        {
          "WorkOrder": 4034101,
          "ParentWorkOrder": "",
          "JobName": "SOUTHERN PLAZA",
          "JobNumber": "114-63921-16",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6615 WASHINGTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "6615 WASHINGTON AVE",
          "JobCounty": "",
          "JobState": "Mississippi",
          "CustomerCompanyName": "HOBBS CONSTRUCTION",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Canceled",
          "ContractAmount": "$25,011.00",
          "BalanceRemaining": "$6,963.12",
          "BranchNumber": 6,
          "SubmitDate": "",
          "ClientonJob": "HOBBS CONSTRUCTION"
        },
        {
          "WorkOrder": 4034100,
          "ParentWorkOrder": "",
          "JobName": "CALIBER MORTGAGE",
          "JobNumber": "114-1382652-2",
          "FirstdayontheJob": "05-27-2020",
          "LastdayontheJob": "05-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1368 Old Fannin Rd",
          "JobAddress2": "Ste 150",
          "JobFullAddress": "1368 Old Fannin Rd Ste 150",
          "JobCounty": "Rankin",
          "JobState": "Mississippi",
          "CustomerCompanyName": "ALLIANCE CONSTRUCTION GROUP",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,840.87",
          "BranchNumber": 13,
          "SubmitDate": "06-05-2020",
          "ClientonJob": "ALLIANCE CONSTRUCTION GROUP"
        },
        {
          "WorkOrder": 4034090,
          "ParentWorkOrder": 4028744,
          "JobName": "(NOF) Eden By The Rouge",
          "JobNumber": "502-1325000-22",
          "FirstdayontheJob": "05-13-2020",
          "LastdayontheJob": "05-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44100 Connection Way",
          "JobAddress2": "",
          "JobFullAddress": "44100 Connection Way",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$21,038.53",
          "BranchNumber": 9,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4033837,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LOT 38 Montcaret",
          "JobNumber": "502-847401-149",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18941 Chaumont Way",
          "JobAddress2": "",
          "JobFullAddress": "18941 Chaumont Way",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$12,221.18",
          "BranchNumber": 11,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4033836,
          "ParentWorkOrder": "",
          "JobName": "(NOF) University Trails",
          "JobNumber": "502-1507798-45",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 W University Dr",
          "JobAddress2": "",
          "JobFullAddress": "1000 W University Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4033835,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-315 DTE HQEC",
          "JobNumber": "502-937250-159",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "ONE ENERGY PLAZA",
          "JobAddress2": "",
          "JobFullAddress": "ONE ENERGY PLAZA",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "L.S. Brinker Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "L.S. Brinker Company"
        },
        {
          "WorkOrder": 4033834,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Culvers Ludington",
          "JobNumber": "502-916315-164",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5089 W US 10",
          "JobAddress2": "",
          "JobFullAddress": "5089 W US 10",
          "JobCounty": "MASON",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$9,971.21",
          "BranchNumber": 9,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4033833,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3506 Prairie Ave",
          "JobNumber": "502-1647906-18",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3506 Prairie Ave",
          "JobAddress2": "",
          "JobFullAddress": "3506 Prairie Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "V & S QUALITY DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$5,637.28",
          "BranchNumber": 8,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "V & S QUALITY DRYWALL INC"
        },
        {
          "WorkOrder": 4033832,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Billings Place",
          "JobNumber": "502-876650-328",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "221 N Main Street",
          "JobAddress2": "",
          "JobFullAddress": "221 N Main Street",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$32,703.71",
          "BranchNumber": 8,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 4033831,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Venshure Test Serv",
          "JobNumber": "502-820500-39",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18600 W Old US 12",
          "JobAddress2": "",
          "JobFullAddress": "18600 W Old US 12",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEE CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "LEE CONTRACTING"
        },
        {
          "WorkOrder": 4033830,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dakkota Kettering",
          "JobNumber": "502-1606296-10",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5405 Van Dyke St",
          "JobAddress2": "",
          "JobFullAddress": "5405 Van Dyke St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Regis Construction, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "Regis Construction, LLC"
        },
        {
          "WorkOrder": 4033829,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TRU HOTEL-WARSAW",
          "JobNumber": "502-990401-60",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "310 PROSPERITY DR",
          "JobAddress2": "",
          "JobFullAddress": "310 PROSPERITY DR",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$26,214.51",
          "BranchNumber": 7,
          "SubmitDate": "",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4033828,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDC",
          "JobNumber": "502-11041-32",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2614 CENTER AVE",
          "JobAddress2": "",
          "JobFullAddress": "2614 CENTER AVE",
          "JobCounty": "Bay",
          "JobState": "Michigan",
          "CustomerCompanyName": "Complete Acoustical",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "Complete Acoustical"
        },
        {
          "WorkOrder": 4033827,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Holiday Inn",
          "JobNumber": "502-1308136-4",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11313 Milan Road",
          "JobAddress2": "",
          "JobFullAddress": "11313 Milan Road",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Schnippel Construction,Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$28,019.56",
          "BranchNumber": 13,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "Schnippel Construction,Inc"
        },
        {
          "WorkOrder": 4033826,
          "ParentWorkOrder": "",
          "JobName": "FISCHER HOMES",
          "JobNumber": "65-502950-15",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "DEVIN CONSTRUCTION CO LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "DEVIN CONSTRUCTION CO LLC"
        },
        {
          "WorkOrder": 4033825,
          "ParentWorkOrder": "",
          "JobName": "NOF BSMH CANOPY",
          "JobNumber": "65-2135182-4",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1855 MERCY HEALTH PL",
          "JobAddress2": "",
          "JobFullAddress": "1855 MERCY HEALTH PL",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "COUSIN CONSTRUCTION COMPA",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,263.50",
          "BranchNumber": 12,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "COUSIN CONSTRUCTION COMPA"
        },
        {
          "WorkOrder": 4033824,
          "ParentWorkOrder": "",
          "JobName": "NOF COMMUNITY LIVING",
          "JobNumber": "65-124770-2",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3200 VINE ST",
          "JobAddress2": "",
          "JobFullAddress": "3200 VINE ST",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "VETERANS CONTRACTING INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,000.00",
          "BalanceRemaining": "$981.60",
          "BranchNumber": 12,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "VETERANS CONTRACTING INC"
        },
        {
          "WorkOrder": 4033823,
          "ParentWorkOrder": "",
          "JobName": "NOF FCC CINCINNATI EXTERI",
          "JobNumber": "65-71344-16",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1501 CENTRAL PARKWAY",
          "JobAddress2": "",
          "JobFullAddress": "1501 CENTRAL PARKWAY",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Northside Supply",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$63,901.58",
          "BranchNumber": 12,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "Northside Supply"
        },
        {
          "WorkOrder": 4033822,
          "ParentWorkOrder": "",
          "JobName": "NCH SPEECH",
          "JobNumber": "65-422745-85",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6499 E BROAD ST",
          "JobAddress2": "",
          "JobFullAddress": "6499 E BROAD ST",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "J. YANKLE COMPANY LTD.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$4,464.11",
          "BranchNumber": 4,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "J. YANKLE COMPANY LTD."
        },
        {
          "WorkOrder": 4033821,
          "ParentWorkOrder": "",
          "JobName": "NOF Krogers Oxford OH",
          "JobNumber": "65-589140-4",
          "FirstdayontheJob": "06-03-2020",
          "LastdayontheJob": "06-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "300 South Locust Street",
          "JobAddress2": "",
          "JobFullAddress": "300 South Locust Street",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "BIGNER CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$55,006.00",
          "BalanceRemaining": "$41,815.81",
          "BranchNumber": 2,
          "SubmitDate": "06-04-2020",
          "ClientonJob": "BIGNER CONSTRUCTION"
        },
        {
          "WorkOrder": 4032444,
          "ParentWorkOrder": "",
          "JobName": "(   )COTTAGES BEAVERCREEK",
          "JobNumber": "65-502950-10",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4445-4435 FAITH COURT",
          "JobAddress2": "BUILDING # 4",
          "JobFullAddress": "4445-4435 FAITH COURT BUILDING # 4",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "DEVIN CONSTRUCTION CO LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$3,467.52",
          "BranchNumber": 13,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "DEVIN CONSTRUCTION CO LLC"
        },
        {
          "WorkOrder": 4032443,
          "ParentWorkOrder": "",
          "JobName": "NOF CAMBRIDGE APARTMENTS",
          "JobNumber": "65-502280-66",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "135-141 CAMBRIDGE AVE",
          "JobAddress2": "",
          "JobFullAddress": "135-141 CAMBRIDGE AVE",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALTIES INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$176.30",
          "BranchNumber": 13,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "COMBS INTERIOR SPECIALTIES INC."
        },
        {
          "WorkOrder": 4032442,
          "ParentWorkOrder": "",
          "JobName": "NOF CHARTER SPECTRUM",
          "JobNumber": "65-46160-2",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3251 MAPLE AVE",
          "JobAddress2": "",
          "JobFullAddress": "3251 MAPLE AVE",
          "JobCounty": "MUSKINGUM",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDTOWN CONSTRUCTION SERVICES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$5,927.50",
          "BranchNumber": 13,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "MIDTOWN CONSTRUCTION SERVICES INC"
        },
        {
          "WorkOrder": 4032441,
          "ParentWorkOrder": "",
          "JobName": "NOF WYOMING HS",
          "JobNumber": "65-414950-8",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "106 PENDERY AVE",
          "JobAddress2": "",
          "JobFullAddress": "106 PENDERY AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "R J Beischel Building Co",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$4,913.08",
          "BranchNumber": 12,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "R J Beischel Building Co"
        },
        {
          "WorkOrder": 4032440,
          "ParentWorkOrder": "",
          "JobName": "Artech  Sharonville",
          "JobNumber": "65-520101-2",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11988 Tramway Drive",
          "JobAddress2": "",
          "JobFullAddress": "11988 Tramway Drive",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "ARTECH GROUP LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 2,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "ARTECH GROUP LLC"
        },
        {
          "WorkOrder": 4032439,
          "ParentWorkOrder": "",
          "JobName": "NOF BUFFALO WINGS&RINGS",
          "JobNumber": "65-510300-56",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "856 STATE ROUTE 28",
          "JobAddress2": "",
          "JobFullAddress": "856 STATE ROUTE 28",
          "JobCounty": "CLERMONT",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$20,393.33",
          "BranchNumber": 2,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4032405,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Crunch Shell",
          "JobNumber": "502-11552-7",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4925 Jackman Road",
          "JobAddress2": "",
          "JobFullAddress": "4925 Jackman Road",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Kerricook Const. Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,417.70",
          "BranchNumber": 11,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "Kerricook Const. Inc"
        },
        {
          "WorkOrder": 4032404,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FAMILY DOLLAR",
          "JobNumber": "502-949575-122",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21800 W 11 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "21800 W 11 MILE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$1,703.39",
          "BranchNumber": 9,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4032403,
          "ParentWorkOrder": "",
          "JobName": "NOF ZTA SORORITY HOUSE",
          "JobNumber": "502-919700-138",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1550 WASHTENAW AVE",
          "JobAddress2": "",
          "JobFullAddress": "1550 WASHTENAW AVE",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$1,121.12",
          "BranchNumber": 9,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 4032402,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KFC/Taco Bell",
          "JobNumber": "502-916315-165",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1590 S Main Street",
          "JobAddress2": "",
          "JobFullAddress": "1590 S Main Street",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4032401,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1615 ST JOES LAB REN",
          "JobNumber": "502-913820-576",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14650 E OLD US HWY 12",
          "JobAddress2": "",
          "JobFullAddress": "14650 E OLD US HWY 12",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$715.49",
          "BranchNumber": 9,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4032400,
          "ParentWorkOrder": "",
          "JobName": "(NOF)8544 Pellet",
          "JobNumber": "502-1647906-17",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8544 Pellett Dr",
          "JobAddress2": "",
          "JobFullAddress": "8544 Pellett Dr",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "V & S QUALITY DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "V & S QUALITY DRYWALL INC"
        },
        {
          "WorkOrder": 4032399,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Residence at Grande",
          "JobNumber": "502-1647906-15",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "N. Grande Way",
          "JobAddress2": "",
          "JobFullAddress": "N. Grande Way",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "V & S QUALITY DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$8,809.70",
          "BranchNumber": 8,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "V & S QUALITY DRYWALL INC"
        },
        {
          "WorkOrder": 4032398,
          "ParentWorkOrder": "",
          "JobName": "NOF MARK SOULE",
          "JobNumber": "502-877250-150",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8538 LAKESHORE RD",
          "JobAddress2": "",
          "JobFullAddress": "8538 LAKESHORE RD",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Cons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,352.14",
          "BranchNumber": 8,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "Blue Water Drywall & Cons"
        },
        {
          "WorkOrder": 4032397,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Avon Industrial",
          "JobNumber": "502-822250-30",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2191 Avon Industrial Dr",
          "JobAddress2": "",
          "JobFullAddress": "2191 Avon Industrial Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NORTHERN INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "NORTHERN INTERIORS LLC"
        },
        {
          "WorkOrder": 4032396,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Albion Project",
          "JobNumber": "502-815630-236",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1410 N Eaton St",
          "JobAddress2": "",
          "JobFullAddress": "1410 N Eaton St",
          "JobCounty": "CALHOUN",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4032395,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Harrison Grow",
          "JobNumber": "502-815630-234",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41929 Irwin Dr",
          "JobAddress2": "",
          "JobFullAddress": "41929 Irwin Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4032394,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9061 AH SJP Pharm",
          "JobNumber": "502-815250-368",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16001 W. Nine Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "16001 W. Nine Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4032393,
          "ParentWorkOrder": "",
          "JobName": "NOF ROYAL OAK OAKLAND",
          "JobNumber": "502-813200-140",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2415 Brockton Ave",
          "JobAddress2": "",
          "JobFullAddress": "2415 Brockton Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$418.36",
          "BranchNumber": 8,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4032392,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3131 Zen Apartments",
          "JobNumber": "502-813200-139",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "777 Wilshire Dr.",
          "JobAddress2": "",
          "JobFullAddress": "777 Wilshire Dr.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$500,006.00",
          "BalanceRemaining": "$1,508.70",
          "BranchNumber": 8,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4032391,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FCA Paint Warren",
          "JobNumber": "502-812350-92",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21500 Mound Rd.",
          "JobAddress2": "",
          "JobFullAddress": "21500 Mound Rd.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$95,006.00",
          "BalanceRemaining": "$5,223.46",
          "BranchNumber": 8,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4032390,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Spring Arbor Univ",
          "JobNumber": "502-969300-6",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "106 E. Main St",
          "JobAddress2": "",
          "JobFullAddress": "106 E. Main St",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "O'HARROW CONST. COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$118.17",
          "BranchNumber": 7,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "O'HARROW CONST. COMPANY"
        },
        {
          "WorkOrder": 4032389,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Popeye's",
          "JobNumber": "502-16046-2",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "970 N West Ave",
          "JobAddress2": "",
          "JobFullAddress": "970 N West Ave",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "F & S INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$2,168.00",
          "BranchNumber": 7,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "F & S INTERIORS"
        },
        {
          "WorkOrder": 4032388,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CC-20-2 HN 29 MILLS",
          "JobNumber": "502-12280-18",
          "FirstdayontheJob": "05-28-2020",
          "LastdayontheJob": "05-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5502 RED TAIL LANE",
          "JobAddress2": "",
          "JobFullAddress": "5502 RED TAIL LANE",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDLAND DRYWALL CORP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,229.58",
          "BranchNumber": 1,
          "SubmitDate": "05-29-2020",
          "ClientonJob": "MIDLAND DRYWALL CORP"
        },
        {
          "WorkOrder": 4031985,
          "ParentWorkOrder": 4017791,
          "JobName": "(NOF)DPW",
          "JobNumber": "502-817800-50",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1141 Wilcox St.",
          "JobAddress2": "",
          "JobFullAddress": "1141 Wilcox St.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Draft",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,788.48",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4031830,
          "ParentWorkOrder": "",
          "JobName": "SOUTHERN PLAZA",
          "JobNumber": "114-63921-16",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6615 WASHINGTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "6615 WASHINGTON AVE",
          "JobCounty": "Jackson",
          "JobState": "Mississippi",
          "CustomerCompanyName": "HOBBS CONSTRUCTION",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$300,000.00",
          "BalanceRemaining": "$425.57",
          "BranchNumber": 6,
          "SubmitDate": "05-27-2020",
          "ClientonJob": "HOBBS CONSTRUCTION"
        },
        {
          "WorkOrder": 4031554,
          "ParentWorkOrder": "",
          "JobName": "Engravable.N.",
          "JobNumber": "114-13859-2",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "930 Brookway blvd",
          "JobAddress2": "",
          "JobFullAddress": "930 Brookway blvd",
          "JobCounty": "",
          "JobState": "Mississippi",
          "CustomerCompanyName": "HALLEY CONSTRUCTION LLC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Canceled",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$7,538.00",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "HALLEY CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 4031553,
          "ParentWorkOrder": "",
          "JobName": "ROSES - GREENVILLE",
          "JobNumber": "114-1385582-3",
          "FirstdayontheJob": "05-04-2020",
          "LastdayontheJob": "05-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3001 HWY 82 E",
          "JobAddress2": "",
          "JobFullAddress": "3001 HWY 82 E",
          "JobCounty": "WASHINGTON",
          "JobState": "Mississippi",
          "CustomerCompanyName": "CANTU CONSTRUCTION INC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$5,464.00",
          "BranchNumber": 13,
          "SubmitDate": "05-26-2020",
          "ClientonJob": "CANTU CONSTRUCTION INC"
        },
        {
          "WorkOrder": 4031408,
          "ParentWorkOrder": "",
          "JobName": "WEST JACKSON PLANNING DPT",
          "JobNumber": "114-67840-22",
          "FirstdayontheJob": "05-11-2020",
          "LastdayontheJob": "05-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6904 WASHINGTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "6904 WASHINGTON AVE",
          "JobCounty": "JACKSON",
          "JobState": "Mississippi",
          "CustomerCompanyName": "Rushing Acoustics LLC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$1,659.29",
          "BranchNumber": 6,
          "SubmitDate": "05-26-2020",
          "ClientonJob": "Rushing Acoustics LLC"
        },
        {
          "WorkOrder": 4031189,
          "ParentWorkOrder": "",
          "JobName": "COLISEUM PLACE APTS",
          "JobNumber": "114-1335208-2",
          "FirstdayontheJob": "01-02-2020",
          "LastdayontheJob": "04-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1765 COLISIUM STREET",
          "JobAddress2": "",
          "JobFullAddress": "1765 COLISIUM STREET",
          "JobCounty": "",
          "JobState": "Louisiana",
          "CustomerCompanyName": "LOUISIANA COASTAL MGMT",
          "RequestType": "Notice of Non-Payment (Suppliers Only)",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "On Hold",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$16,609.75",
          "BranchNumber": 14,
          "SubmitDate": "",
          "ClientonJob": "LOUISIANA COASTAL MGMT"
        },
        {
          "WorkOrder": 4031188,
          "ParentWorkOrder": "",
          "JobName": "TRACTOR SUPPLY FLOWOOD",
          "JobNumber": "114-200828-42",
          "FirstdayontheJob": "04-28-2020",
          "LastdayontheJob": "05-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5572 Highway 25",
          "JobAddress2": "",
          "JobFullAddress": "5572 Highway 25",
          "JobCounty": "RANKIN",
          "JobState": "Mississippi",
          "CustomerCompanyName": "THE DAVE JOHNSTON CO. INC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$1,992.24",
          "BranchNumber": 13,
          "SubmitDate": "05-23-2020",
          "ClientonJob": "THE DAVE JOHNSTON CO. INC"
        },
        {
          "WorkOrder": 4031187,
          "ParentWorkOrder": "",
          "JobName": "TRACTOR SUPPLY GENEVA",
          "JobNumber": "114-200828-43",
          "FirstdayontheJob": "04-28-2020",
          "LastdayontheJob": "05-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1632 W Magnolia Ave",
          "JobAddress2": "",
          "JobFullAddress": "1632 W Magnolia Ave",
          "JobCounty": "GENEVA",
          "JobState": "Alabama",
          "CustomerCompanyName": "THE DAVE JOHNSTON CO. INC",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$10,890.26",
          "BranchNumber": 13,
          "SubmitDate": "05-25-2020",
          "ClientonJob": "THE DAVE JOHNSTON CO. INC"
        },
        {
          "WorkOrder": 4030963,
          "ParentWorkOrder": 4013846,
          "JobName": "(NOF) Waterville Place",
          "JobNumber": "502-1084306-5",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1501 Pray Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1501 Pray Blvd",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "T&B CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$2,731.50",
          "BranchNumber": 13,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "T&B CONSTRUCTION"
        },
        {
          "WorkOrder": 4030934,
          "ParentWorkOrder": 4004237,
          "JobName": "(NOF)Lansing Comm College",
          "JobNumber": "502-30900-68",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "400 N Capitol Ave",
          "JobAddress2": "",
          "JobFullAddress": "400 N Capitol Ave",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "Integrity Interiors Inc.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$12,760.70",
          "BranchNumber": 7,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "Integrity Interiors Inc."
        },
        {
          "WorkOrder": 4030791,
          "ParentWorkOrder": 4020594,
          "JobName": "GRACEWAY APARTMENTS",
          "JobNumber": "114-60558-2",
          "FirstdayontheJob": "01-14-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20815 BISHOP ROAD",
          "JobAddress2": "",
          "JobFullAddress": "20815 BISHOP ROAD",
          "JobCounty": "Baldwin",
          "JobState": "Alabama",
          "CustomerCompanyName": "DCY ENTERPRISES LLC",
          "RequestType": "Satisfaction",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Recorded",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$44,004.75",
          "BranchNumber": 6,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "DCY ENTERPRISES LLC"
        },
        {
          "WorkOrder": 4030644,
          "ParentWorkOrder": "",
          "JobName": "NOF HOMEOWNER",
          "JobNumber": "502-858171-118",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8596 SHORT CUT RD",
          "JobAddress2": "",
          "JobFullAddress": "8596 SHORT CUT RD",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "DGS DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$4,584.67",
          "BranchNumber": 11,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "DGS DRYWALL LLC"
        },
        {
          "WorkOrder": 4030643,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Milford House",
          "JobNumber": "502-1325000-25",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3830 S Shoreline Dr",
          "JobAddress2": "",
          "JobFullAddress": "3830 S Shoreline Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,506.00",
          "BalanceRemaining": "$12,334.09",
          "BranchNumber": 9,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4030642,
          "ParentWorkOrder": "",
          "JobName": "(NOF)QUICKEN PHA#2 20-311",
          "JobNumber": "502-937250-157",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 CAMPUS MARTIUS",
          "JobAddress2": "",
          "JobFullAddress": "1 CAMPUS MARTIUS",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINKER TEAM CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "BRINKER TEAM CONSTRUCTION CO"
        },
        {
          "WorkOrder": 4030641,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Henry Ford Corp",
          "JobNumber": "502-920940-90",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Ford Place",
          "JobAddress2": "",
          "JobFullAddress": "1 Ford Place",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4030640,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OSF",
          "JobNumber": "502-823400-53",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1885 N Pontiac Trail",
          "JobAddress2": "",
          "JobFullAddress": "1885 N Pontiac Trail",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PARALLEL CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "PARALLEL CONSTRUCTION"
        },
        {
          "WorkOrder": 4030639,
          "ParentWorkOrder": "",
          "JobName": "(NOF)McLaren Macomb LINAC",
          "JobNumber": "502-815250-319",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1000 HARRINGTON ST",
          "JobAddress2": "",
          "JobFullAddress": "1000 HARRINGTON ST",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$48.55",
          "BranchNumber": 8,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4030638,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Courthouse-WC Jail",
          "JobNumber": "502-812350-91",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5560 Rivard Street",
          "JobAddress2": "",
          "JobFullAddress": "5560 Rivard Street",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$350,006.00",
          "BalanceRemaining": "$6,769.29",
          "BranchNumber": 8,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4030637,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Culvers Ft Gratiot",
          "JobNumber": "502-94091-22",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4280 24TH AVE",
          "JobAddress2": "",
          "JobFullAddress": "4280 24TH AVE",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "EJ Kaneris Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$12,841.84",
          "BranchNumber": 8,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "EJ Kaneris Inc"
        },
        {
          "WorkOrder": 4030636,
          "ParentWorkOrder": "",
          "JobName": "NOF BEAVERTON CITY HALL",
          "JobNumber": "502-14600-42",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "124 W BROWN ST",
          "JobAddress2": "",
          "JobFullAddress": "124 W BROWN ST",
          "JobCounty": "GLADWIN",
          "JobState": "Michigan",
          "CustomerCompanyName": "Tri-City Acoustical",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,055.60",
          "BranchNumber": 1,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "Tri-City Acoustical"
        },
        {
          "WorkOrder": 4030635,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-29 SC 34 STOLL",
          "JobNumber": "502-12280-19",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15 MILLPOND TRAIL",
          "JobAddress2": "",
          "JobFullAddress": "15 MILLPOND TRAIL",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDLAND DRYWALL CORP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,506.00",
          "BalanceRemaining": "$281.09",
          "BranchNumber": 1,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "MIDLAND DRYWALL CORP"
        },
        {
          "WorkOrder": 4030634,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CC-20-HN-26-MODEL",
          "JobNumber": "502-12280-17",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5212 FALCON WAY",
          "JobAddress2": "",
          "JobFullAddress": "5212 FALCON WAY",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDLAND DRYWALL CORP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,506.00",
          "BalanceRemaining": "$833.39",
          "BranchNumber": 1,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "MIDLAND DRYWALL CORP"
        },
        {
          "WorkOrder": 4030633,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-2CL 13",
          "JobNumber": "502-12280-16",
          "FirstdayontheJob": "05-20-2020",
          "LastdayontheJob": "05-20-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "407 COPPER LEAF CT",
          "JobAddress2": "",
          "JobFullAddress": "407 COPPER LEAF CT",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDLAND DRYWALL CORP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,506.00",
          "BalanceRemaining": "$737.72",
          "BranchNumber": 1,
          "SubmitDate": "05-21-2020",
          "ClientonJob": "MIDLAND DRYWALL CORP"
        },
        {
          "WorkOrder": 4030507,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Talmadge Crossing",
          "JobNumber": "502-1609034-46",
          "FirstdayontheJob": "05-19-2020",
          "LastdayontheJob": "05-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4701 Talmadge Rd",
          "JobAddress2": "",
          "JobFullAddress": "4701 Talmadge Rd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$7,511.10",
          "BranchNumber": 13,
          "SubmitDate": "05-20-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4030506,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SOMO Flats",
          "JobNumber": "502-16037-2",
          "FirstdayontheJob": "05-19-2020",
          "LastdayontheJob": "05-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6455 Monroe St.",
          "JobAddress2": "",
          "JobFullAddress": "6455 Monroe St.",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "MORNINGSIDE GROUP INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$5,121.65",
          "BranchNumber": 13,
          "SubmitDate": "05-20-2020",
          "ClientonJob": "MORNINGSIDE GROUP INC"
        },
        {
          "WorkOrder": 4030505,
          "ParentWorkOrder": "",
          "JobName": "STAR AVE",
          "JobNumber": "65-420501-3",
          "FirstdayontheJob": "05-19-2020",
          "LastdayontheJob": "05-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "989 N HIGH ST",
          "JobAddress2": "",
          "JobFullAddress": "989 N HIGH ST",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "SUPERIOR HOME MAINT.CO",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$3,134.11",
          "BranchNumber": 4,
          "SubmitDate": "05-20-2020",
          "ClientonJob": "SUPERIOR HOME MAINT.CO"
        },
        {
          "WorkOrder": 4030504,
          "ParentWorkOrder": "",
          "JobName": "NOF FREEMAN AUTO AUCTION",
          "JobNumber": "65-510300-55",
          "FirstdayontheJob": "05-19-2020",
          "LastdayontheJob": "05-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8340 NORTH GILMORE ROAD",
          "JobAddress2": "",
          "JobFullAddress": "8340 NORTH GILMORE ROAD",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$705.49",
          "BranchNumber": 2,
          "SubmitDate": "05-20-2020",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4028745,
          "ParentWorkOrder": "",
          "JobName": "NOF DEONNA MORIARTY",
          "JobNumber": "502-861507-59",
          "FirstdayontheJob": "05-13-2020",
          "LastdayontheJob": "05-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7250 FLAMINGO ST",
          "JobAddress2": "",
          "JobFullAddress": "7250 FLAMINGO ST",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "LARRY CLEVELAND",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,806.00",
          "BalanceRemaining": "$2,660.71",
          "BranchNumber": 11,
          "SubmitDate": "05-14-2020",
          "ClientonJob": "LARRY CLEVELAND"
        },
        {
          "WorkOrder": 4028744,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Eden By The Rouge",
          "JobNumber": "502-1325000-22",
          "FirstdayontheJob": "05-13-2020",
          "LastdayontheJob": "05-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44100 Connection Way",
          "JobAddress2": "",
          "JobFullAddress": "44100 Connection Way",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$21,038.53",
          "BranchNumber": 9,
          "SubmitDate": "05-14-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4028743,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3800 Centennial Blvd",
          "JobNumber": "502-1639418-29",
          "FirstdayontheJob": "05-13-2020",
          "LastdayontheJob": "05-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3800 Centennial Blvd",
          "JobAddress2": "",
          "JobFullAddress": "3800 Centennial Blvd",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,553.74",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2020",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4028742,
          "ParentWorkOrder": "",
          "JobName": "NOF PREFERRED CHARTERS",
          "JobNumber": "502-877250-149",
          "FirstdayontheJob": "05-13-2020",
          "LastdayontheJob": "05-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3001 DOVE ROAD",
          "JobAddress2": "",
          "JobFullAddress": "3001 DOVE ROAD",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Cons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$11,631.08",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2020",
          "ClientonJob": "Blue Water Drywall & Cons"
        },
        {
          "WorkOrder": 4028741,
          "ParentWorkOrder": "",
          "JobName": "(NOF)H2 Home 2 Suites Sag",
          "JobNumber": "502-990401-59",
          "FirstdayontheJob": "05-13-2020",
          "LastdayontheJob": "05-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2569 Trautner Dr.",
          "JobAddress2": "",
          "JobFullAddress": "2569 Trautner Dr.",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "05-14-2020",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4028740,
          "ParentWorkOrder": "",
          "JobName": "(NOF)American Axle",
          "JobNumber": "502-60243-39",
          "FirstdayontheJob": "05-13-2020",
          "LastdayontheJob": "05-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "One Dauch Drive",
          "JobAddress2": "",
          "JobFullAddress": "One Dauch Drive",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANGSTROM TECHNOLOGY LTD",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,942.60",
          "BranchNumber": 5,
          "SubmitDate": "05-14-2020",
          "ClientonJob": "ANGSTROM TECHNOLOGY LTD"
        },
        {
          "WorkOrder": 4028739,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CLIO FOOT & ANKLE",
          "JobNumber": "502-20630-52",
          "FirstdayontheJob": "05-13-2020",
          "LastdayontheJob": "05-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4091 W VIENNA RD",
          "JobAddress2": "",
          "JobFullAddress": "4091 W VIENNA RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "CASE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$835.44",
          "BranchNumber": 1,
          "SubmitDate": "05-14-2020",
          "ClientonJob": "CASE CONSTRUCTION"
        },
        {
          "WorkOrder": 4028624,
          "ParentWorkOrder": "",
          "JobName": "NOF BANK OF AMERICA",
          "JobNumber": "65-2135016-4",
          "FirstdayontheJob": "05-12-2020",
          "LastdayontheJob": "05-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7796 MONTGOMERY  RD",
          "JobAddress2": "",
          "JobFullAddress": "7796 MONTGOMERY  RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "SAINT PLASTERING",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$9,441.48",
          "BranchNumber": 12,
          "SubmitDate": "05-13-2020",
          "ClientonJob": "SAINT PLASTERING"
        },
        {
          "WorkOrder": 4028623,
          "ParentWorkOrder": "",
          "JobName": "BEHAVIORAL HOSPITAL",
          "JobNumber": "65-2134999-14",
          "FirstdayontheJob": "05-12-2020",
          "LastdayontheJob": "05-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2446 KIPLING AVE",
          "JobAddress2": "",
          "JobFullAddress": "2446 KIPLING AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "REYES/TDR INTERIORS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$815.43",
          "BranchNumber": 12,
          "SubmitDate": "05-13-2020",
          "ClientonJob": "REYES/TDR INTERIORS"
        },
        {
          "WorkOrder": 4028622,
          "ParentWorkOrder": "",
          "JobName": "KROGER N-591",
          "JobNumber": "65-473759-10",
          "FirstdayontheJob": "05-12-2020",
          "LastdayontheJob": "05-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "350 EAST BROAD ST",
          "JobAddress2": "",
          "JobFullAddress": "350 EAST BROAD ST",
          "JobCounty": "LICKING",
          "JobState": "Ohio",
          "CustomerCompanyName": "R&W Fixturing Co Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,000,000.00",
          "BalanceRemaining": "$913.05",
          "BranchNumber": 4,
          "SubmitDate": "05-13-2020",
          "ClientonJob": "R&W Fixturing Co Inc"
        },
        {
          "WorkOrder": 4028621,
          "ParentWorkOrder": "",
          "JobName": "TropicalSmoothie19100",
          "JobNumber": "65-402000-3",
          "FirstdayontheJob": "05-12-2020",
          "LastdayontheJob": "05-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17878 Royalton Rd",
          "JobAddress2": "",
          "JobFullAddress": "17878 Royalton Rd",
          "JobCounty": "Cuyahoga",
          "JobState": "Ohio",
          "CustomerCompanyName": "GREAT LAKES BAY CONSTR",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$7,500.00",
          "BalanceRemaining": "$3,590.36",
          "BranchNumber": 4,
          "SubmitDate": "05-13-2020",
          "ClientonJob": "GREAT LAKES BAY CONSTR"
        },
        {
          "WorkOrder": 4028620,
          "ParentWorkOrder": "",
          "JobName": "NOF DEDICATED SENIOR",
          "JobNumber": "65-732612-10",
          "FirstdayontheJob": "05-12-2020",
          "LastdayontheJob": "05-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6121 COLERAIN AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "6121 COLERAIN AVENUE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "CBJ CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$7,034.45",
          "BranchNumber": 1,
          "SubmitDate": "05-13-2020",
          "ClientonJob": "CBJ CONSTRUCTION INC"
        },
        {
          "WorkOrder": 4028619,
          "ParentWorkOrder": "",
          "JobName": "MEDLINE",
          "JobNumber": "65-700142-5",
          "FirstdayontheJob": "05-12-2020",
          "LastdayontheJob": "05-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1040 Enterprise Parkway South",
          "JobAddress2": "",
          "JobFullAddress": "1040 Enterprise Parkway South",
          "JobCounty": "MADISON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Ultra Commercial Int",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$6,057.74",
          "BranchNumber": 1,
          "SubmitDate": "05-13-2020",
          "ClientonJob": "Ultra Commercial Int"
        },
        {
          "WorkOrder": 4027865,
          "ParentWorkOrder": "",
          "JobName": "DOLLAR GENERAL VICKSBURG",
          "JobNumber": "114-200828-40",
          "FirstdayontheJob": "03-02-2020",
          "LastdayontheJob": "03-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2764 WASHINGTON ST",
          "JobAddress2": "",
          "JobFullAddress": "2764 WASHINGTON ST",
          "JobCounty": "WARREN",
          "JobState": "Mississippi",
          "CustomerCompanyName": "THE DAVE JOHNSTON CO. INC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$4,856.80",
          "BranchNumber": 13,
          "SubmitDate": "05-11-2020",
          "ClientonJob": "THE DAVE JOHNSTON CO. INC"
        },
        {
          "WorkOrder": 4026947,
          "ParentWorkOrder": "",
          "JobName": "EVT LAKE CHARLES BLDG#4.",
          "JobNumber": "114-1331440-45",
          "FirstdayontheJob": "04-23-2020",
          "LastdayontheJob": "04-23-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5665 NELSON RD",
          "JobAddress2": "",
          "JobFullAddress": "5665 NELSON RD",
          "JobCounty": "CALCASIEU",
          "JobState": "Louisiana",
          "CustomerCompanyName": "TIKE INTERIORS LLC",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,469.73",
          "BranchNumber": 14,
          "SubmitDate": "05-08-2020",
          "ClientonJob": "TIKE INTERIORS LLC"
        },
        {
          "WorkOrder": 4026946,
          "ParentWorkOrder": "",
          "JobName": "EVT LAKE CHARLES BLDG#3.",
          "JobNumber": "114-1331440-44",
          "FirstdayontheJob": "04-23-2020",
          "LastdayontheJob": "04-23-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5665 NELSON RD",
          "JobAddress2": "",
          "JobFullAddress": "5665 NELSON RD",
          "JobCounty": "CALCASIEU",
          "JobState": "Louisiana",
          "CustomerCompanyName": "TIKE INTERIORS LLC",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$7,152.44",
          "BranchNumber": 14,
          "SubmitDate": "05-08-2020",
          "ClientonJob": "TIKE INTERIORS LLC"
        },
        {
          "WorkOrder": 4026945,
          "ParentWorkOrder": "",
          "JobName": "EVT LAKE CHARLES BLDG#1",
          "JobNumber": "114-1331440-43",
          "FirstdayontheJob": "04-23-2020",
          "LastdayontheJob": "04-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5665 NELSON RD",
          "JobAddress2": "",
          "JobFullAddress": "5665 NELSON RD",
          "JobCounty": "CALCASIEU",
          "JobState": "Louisiana",
          "CustomerCompanyName": "TIKE INTERIORS LLC",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$10,147.16",
          "BranchNumber": 14,
          "SubmitDate": "05-08-2020",
          "ClientonJob": "TIKE INTERIORS LLC"
        },
        {
          "WorkOrder": 4026944,
          "ParentWorkOrder": "",
          "JobName": "ENGRAVABLES",
          "JobNumber": "114-13859-2",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "930 BROOKWAY BLVD",
          "JobAddress2": "",
          "JobFullAddress": "930 BROOKWAY BLVD",
          "JobCounty": "LINCOLN",
          "JobState": "Mississippi",
          "CustomerCompanyName": "HALLEY CONSTRUCTION LLC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$7,536.90",
          "BranchNumber": 13,
          "SubmitDate": "05-08-2020",
          "ClientonJob": "HALLEY CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 4025965,
          "ParentWorkOrder": 4018583,
          "JobName": "MIMS OFFICE",
          "JobNumber": "114-24387-3",
          "FirstdayontheJob": "01-23-2020",
          "LastdayontheJob": "04-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "781 TRADE CENTER STREET",
          "JobAddress2": "",
          "JobFullAddress": "781 TRADE CENTER STREET",
          "JobCounty": "Montgomery",
          "JobState": "Alabama",
          "CustomerCompanyName": "Webb Builders",
          "RequestType": "Satisfaction",
          "CustomerRepresentative": "Valerie Nottage",
          "Status": "Recorded",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$2,357.94",
          "BranchNumber": 16,
          "SubmitDate": "05-06-2020",
          "ClientonJob": "Webb Builders"
        },
        {
          "WorkOrder": 4025960,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dollar General",
          "JobNumber": "502-1304186-60",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1319 Ottawa Ave.",
          "JobAddress2": "",
          "JobFullAddress": "1319 Ottawa Ave.",
          "JobCounty": "DEFIANCE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,311.95",
          "BranchNumber": 13,
          "SubmitDate": "05-06-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4025959,
          "ParentWorkOrder": "",
          "JobName": "NOF COURT&WALNUT",
          "JobNumber": "65-2134792-4",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "39-41 COURT ST",
          "JobAddress2": "",
          "JobFullAddress": "39-41 COURT ST",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "CUSTOM CARVING SOURCE LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$8,438.05",
          "BranchNumber": 12,
          "SubmitDate": "05-06-2020",
          "ClientonJob": "CUSTOM CARVING SOURCE LLC"
        },
        {
          "WorkOrder": 4025958,
          "ParentWorkOrder": "",
          "JobName": "CINTAS CORP RACKS",
          "JobNumber": "65-2134778-4",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6800 CINTAS BLVD",
          "JobAddress2": "",
          "JobFullAddress": "6800 CINTAS BLVD",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "CINTAS CORP FACILITY SERVICES - LOC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 12,
          "SubmitDate": "05-06-2020",
          "ClientonJob": "CINTAS CORP FACILITY SERVICES - LOC"
        },
        {
          "WorkOrder": 4025957,
          "ParentWorkOrder": "",
          "JobName": "NOF DEDICATED SENIOR",
          "JobNumber": "65-732612-9",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5050 GLENCROSSING WAY",
          "JobAddress2": "",
          "JobFullAddress": "5050 GLENCROSSING WAY",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "CBJ CONSTRUCTION OF VA.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$7,747.07",
          "BranchNumber": 1,
          "SubmitDate": "05-06-2020",
          "ClientonJob": "CBJ CONSTRUCTION OF VA."
        },
        {
          "WorkOrder": 4025956,
          "ParentWorkOrder": "",
          "JobName": "Kroger store Cincinnatia",
          "JobNumber": "65-589140-3",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "BIGNER CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "BIGNER CONSTRUCTION"
        },
        {
          "WorkOrder": 4025954,
          "ParentWorkOrder": "",
          "JobName": "Fabret",
          "JobNumber": "65-468866-4",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5690 Olentangy BLVD",
          "JobAddress2": "",
          "JobFullAddress": "5690 Olentangy BLVD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "PRV REMODELING",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$4,449.54",
          "BranchNumber": 4,
          "SubmitDate": "05-06-2020",
          "ClientonJob": "PRV REMODELING"
        },
        {
          "WorkOrder": 4025953,
          "ParentWorkOrder": "",
          "JobName": "WILLIS TOWERS WATSON",
          "JobNumber": "65-416700-12",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "775 YARD STREET #200",
          "JobAddress2": "",
          "JobFullAddress": "775 YARD STREET #200",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "PEPPER CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,831.52",
          "BranchNumber": 4,
          "SubmitDate": "05-06-2020",
          "ClientonJob": "PEPPER CONSTRUCTION"
        },
        {
          "WorkOrder": 4025952,
          "ParentWorkOrder": "",
          "JobName": "NOF OXFORD BIBLE",
          "JobNumber": "65-55219-12",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "800 S MAPLE AVE",
          "JobAddress2": "",
          "JobFullAddress": "800 S MAPLE AVE",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lemcer Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$302.97",
          "BranchNumber": 2,
          "SubmitDate": "05-06-2020",
          "ClientonJob": "Lemcer Construction LLC"
        },
        {
          "WorkOrder": 4025951,
          "ParentWorkOrder": "",
          "JobName": "NOF 1610 DBM FIRE PROTECT",
          "JobNumber": "502-913820-572",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18100 OAKWOOD",
          "JobAddress2": "",
          "JobFullAddress": "18100 OAKWOOD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$54.00",
          "BranchNumber": 9,
          "SubmitDate": "05-06-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4025950,
          "ParentWorkOrder": "",
          "JobName": "(NOF)NEWSTANDARD",
          "JobNumber": "502-913001-5",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24649 MOUND RD",
          "JobAddress2": "",
          "JobFullAddress": "24649 MOUND RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INTERIOR RESOURCES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$5,502.57",
          "BranchNumber": 9,
          "SubmitDate": "05-06-2020",
          "ClientonJob": "COMMERCIAL INTERIOR RESOURCES"
        },
        {
          "WorkOrder": 4025949,
          "ParentWorkOrder": "",
          "JobName": "(NOF)4949 Hobbiton Ln",
          "JobNumber": "502-1647906-16",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4949 Hobbiton Ln",
          "JobAddress2": "",
          "JobFullAddress": "4949 Hobbiton Ln",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "V & S QUALITY DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-06-2020",
          "ClientonJob": "V & S QUALITY DRYWALL INC"
        },
        {
          "WorkOrder": 4025947,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-2011 Dearborn For",
          "JobNumber": "502-815250-367",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15041 S Commerce Dr",
          "JobAddress2": "",
          "JobFullAddress": "15041 S Commerce Dr",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-06-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4025946,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Sterling Heights",
          "JobNumber": "502-967075-127",
          "FirstdayontheJob": "05-05-2020",
          "LastdayontheJob": "05-05-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7000 19 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "7000 19 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$16,043.25",
          "BranchNumber": 7,
          "SubmitDate": "05-06-2020",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 4024654,
          "ParentWorkOrder": "",
          "JobName": "(NJP) RIVERSIDE TRAIL",
          "JobNumber": "65-463111-5",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5785 CATTERTON RD",
          "JobAddress2": "",
          "JobFullAddress": "5785 CATTERTON RD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "HERMAN & KITTLE PROPERTIES , Inc.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$87,238.80",
          "BranchNumber": 4,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "HERMAN & KITTLE PROPERTIES , Inc."
        },
        {
          "WorkOrder": 4024653,
          "ParentWorkOrder": "",
          "JobName": "(NJP)CROSSING @ GROVE CTY",
          "JobNumber": "65-463111-4",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5015 SOUTH BIG RUN ROAD",
          "JobAddress2": "",
          "JobFullAddress": "5015 SOUTH BIG RUN ROAD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "HERMAN & KITTLE PROPERTIE",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$300,006.00",
          "BalanceRemaining": "$224,722.54",
          "BranchNumber": 4,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "HERMAN & KITTLE PROPERTIE"
        },
        {
          "WorkOrder": 4024508,
          "ParentWorkOrder": "",
          "JobName": "NOF MIDTOWN PARK",
          "JobNumber": "502-849801-242",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "51401 TIMES SQUARE DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "51401 TIMES SQUARE DRIVE",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$13,006.00",
          "BalanceRemaining": "$4,513.94",
          "BranchNumber": 11,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 4024507,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Blossom Springs",
          "JobNumber": "502-847401-148",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3215 SilverBell Rd",
          "JobAddress2": "",
          "JobFullAddress": "3215 SilverBell Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4024506,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Courtyard by Marriot",
          "JobNumber": "502-1604171-9",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1525 E Maple Road",
          "JobAddress2": "",
          "JobFullAddress": "1525 E Maple Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$7,250.37",
          "BranchNumber": 8,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4024505,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Project Cougar",
          "JobNumber": "502-823200-93",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1200 Featherstone Rd",
          "JobAddress2": "",
          "JobFullAddress": "1200 Featherstone Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PONTIAC CEILING & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "PONTIAC CEILING & PART"
        },
        {
          "WorkOrder": 4024504,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dakota Detering",
          "JobNumber": "502-162525-2",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6101 Van Dyke St",
          "JobAddress2": "",
          "JobFullAddress": "6101 Van Dyke St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST STEEL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$10,975.04",
          "BranchNumber": 7,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "MIDWEST STEEL INC"
        },
        {
          "WorkOrder": 4024503,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CHERRY MEADOWS PS 2",
          "JobNumber": "502-50435-64",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6505 CHERRY MEADOW DR SE",
          "JobAddress2": "",
          "JobFullAddress": "6505 CHERRY MEADOW DR SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR BOUWKAMP & ASSOC. INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$10,776.86",
          "BranchNumber": 5,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "JR BOUWKAMP & ASSOC. INC"
        },
        {
          "WorkOrder": 4024362,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Bay View Villas",
          "JobNumber": "502-1609034-44",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2599 S Waterside Ct",
          "JobAddress2": "",
          "JobFullAddress": "2599 S Waterside Ct",
          "JobCounty": "OTTAWA",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$575.16",
          "BranchNumber": 13,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4024361,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1st United Methodist",
          "JobNumber": "502-1302425-5",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "200 W Second Street",
          "JobAddress2": "",
          "JobFullAddress": "200 W Second Street",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "THE DELVENTHAL COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$234.60",
          "BranchNumber": 13,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "THE DELVENTHAL COMPANY"
        },
        {
          "WorkOrder": 4024360,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CENTRAL STATE UNIV",
          "JobNumber": "65-502280-65",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1400 BRUSH ROW RD",
          "JobAddress2": "",
          "JobFullAddress": "1400 BRUSH ROW RD",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALTIES INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$318.63",
          "BranchNumber": 13,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "COMBS INTERIOR SPECIALTIES INC."
        },
        {
          "WorkOrder": 4024359,
          "ParentWorkOrder": "",
          "JobName": "MCDONALD'S REMODEL",
          "JobNumber": "65-137581-3",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "LIS CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "LIS CONSTRUCTION"
        },
        {
          "WorkOrder": 4024358,
          "ParentWorkOrder": "",
          "JobName": "NOF Hillsboro HS New",
          "JobNumber": "65-2137101-6",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "550 US-62",
          "JobAddress2": "",
          "JobFullAddress": "550 US-62",
          "JobCounty": "HIGHLAND",
          "JobState": "Ohio",
          "CustomerCompanyName": "PERFORMANCE CONTRACTING",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$170,006.00",
          "BalanceRemaining": "$1,880.10",
          "BranchNumber": 12,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "PERFORMANCE CONTRACTING"
        },
        {
          "WorkOrder": 4024357,
          "ParentWorkOrder": "",
          "JobName": "Zoological Scoiety Cinci",
          "JobNumber": "65-2134777-4",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3400 Vine Street",
          "JobAddress2": "",
          "JobFullAddress": "3400 Vine Street",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "CINCINNATI ZOO & BOTANICA",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 12,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "CINCINNATI ZOO & BOTANICA"
        },
        {
          "WorkOrder": 4024356,
          "ParentWorkOrder": "",
          "JobName": "Amazon CMH4",
          "JobNumber": "65-2134665-5",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1550 W Main St",
          "JobAddress2": "",
          "JobFullAddress": "1550 W Main St",
          "JobCounty": "MADISON",
          "JobState": "Ohio",
          "CustomerCompanyName": "H.G.C., INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$94.94",
          "BranchNumber": 12,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "H.G.C., INC."
        },
        {
          "WorkOrder": 4024355,
          "ParentWorkOrder": "",
          "JobName": "AMAZON",
          "JobNumber": "65-2134665-4",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3538 TRADEPORT CT",
          "JobAddress2": "",
          "JobFullAddress": "3538 TRADEPORT CT",
          "JobCounty": "PICKAWAY",
          "JobState": "Ohio",
          "CustomerCompanyName": "H.G.C., INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 12,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "H.G.C., INC."
        },
        {
          "WorkOrder": 4024354,
          "ParentWorkOrder": "",
          "JobName": "CLEVELAND CONST-MISC",
          "JobNumber": "65-728746-3",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "CLEVELAND CONST. INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$1,381.71",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "CLEVELAND CONST. INC."
        },
        {
          "WorkOrder": 4024353,
          "ParentWorkOrder": "",
          "JobName": "SHERATON SUITES",
          "JobNumber": "65-499550-2",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "201 HUTCHINSON AVE",
          "JobAddress2": "",
          "JobFullAddress": "201 HUTCHINSON AVE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "FOX VALLEY HOSPITALITY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$60,000.00",
          "BalanceRemaining": "$6,126.23",
          "BranchNumber": 4,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "FOX VALLEY HOSPITALITY"
        },
        {
          "WorkOrder": 4024352,
          "ParentWorkOrder": "",
          "JobName": "KROGER N - 817",
          "JobNumber": "65-473759-11",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7100 Hospital Dr",
          "JobAddress2": "",
          "JobFullAddress": "7100 Hospital Dr",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "R&W Fixturing Co Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$6,228.12",
          "BranchNumber": 4,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "R&W Fixturing Co Inc"
        },
        {
          "WorkOrder": 4024351,
          "ParentWorkOrder": "",
          "JobName": "ACE HARDWARE",
          "JobNumber": "65-422006-36",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10 ENTERPRISE PARKWAY",
          "JobAddress2": "",
          "JobFullAddress": "10 ENTERPRISE PARKWAY",
          "JobCounty": "MADISON",
          "JobState": "Ohio",
          "CustomerCompanyName": "WAYNE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 4,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "WAYNE CONSTRUCTION"
        },
        {
          "WorkOrder": 4024350,
          "ParentWorkOrder": "",
          "JobName": "CORE LIFE",
          "JobNumber": "65-422006-35",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17 EAST LANE AVE",
          "JobAddress2": "",
          "JobFullAddress": "17 EAST LANE AVE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "WAYNE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 4,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "WAYNE CONSTRUCTION"
        },
        {
          "WorkOrder": 4024349,
          "ParentWorkOrder": "",
          "JobName": "Sola Salon",
          "JobNumber": "65-419100-10",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10516 Sawmill RD",
          "JobAddress2": "",
          "JobFullAddress": "10516 Sawmill RD",
          "JobCounty": "DELAWARE",
          "JobState": "Ohio",
          "CustomerCompanyName": "VEATCH MASON & COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$323.55",
          "BranchNumber": 4,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "VEATCH MASON & COMPANY"
        },
        {
          "WorkOrder": 4024348,
          "ParentWorkOrder": "",
          "JobName": "UC Health Avondale Clinic",
          "JobNumber": "65-510590-35",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3559 Reading Rd",
          "JobAddress2": "234 Goodman ST",
          "JobFullAddress": "3559 Reading Rd 234 Goodman ST",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$630.60",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "TRIVERSITY CRAFT FORCE"
        },
        {
          "WorkOrder": 4024347,
          "ParentWorkOrder": "",
          "JobName": "BEAVERCREEK RETAIL",
          "JobNumber": "65-510300-42",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2820 CENTRE DR",
          "JobAddress2": "",
          "JobFullAddress": "2820 CENTRE DR",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$218.50",
          "BranchNumber": 2,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4024346,
          "ParentWorkOrder": "",
          "JobName": "MADISON SQUARE",
          "JobNumber": "65-507630-6",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5300 MEDPACE WAY",
          "JobAddress2": "",
          "JobFullAddress": "5300 MEDPACE WAY",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "PERFORMANCE CONTRACTING INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$1,050.00",
          "BranchNumber": 2,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "PERFORMANCE CONTRACTING INC"
        },
        {
          "WorkOrder": 4024345,
          "ParentWorkOrder": "",
          "JobName": "DISTRICT @ DEERFIELD",
          "JobNumber": "65-505447-8",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9345 S MASON MONTGOMERY RD",
          "JobAddress2": "",
          "JobFullAddress": "9345 S MASON MONTGOMERY RD",
          "JobCounty": "Warren",
          "JobState": "Ohio",
          "CustomerCompanyName": "KEN JACOB DRYWALL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 2,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "KEN JACOB DRYWALL"
        },
        {
          "WorkOrder": 4024344,
          "ParentWorkOrder": "",
          "JobName": "General Mills",
          "JobNumber": "65-500871-10",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11301 Mosteller rd",
          "JobAddress2": "",
          "JobFullAddress": "11301 Mosteller rd",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "BLOOMFIELD INTERIORS, INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "BLOOMFIELD INTERIORS, INC"
        },
        {
          "WorkOrder": 4024343,
          "ParentWorkOrder": "",
          "JobName": "OHIO THORTONS",
          "JobNumber": "65-749907-2",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "MUST HAVE LOCATION AND",
          "JobAddress2": "STORE NUMBER ON INVOICE",
          "JobFullAddress": "MUST HAVE LOCATION AND STORE NUMBER ON INVOICE",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "THORNTONS, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "",
          "ClientonJob": "THORNTONS, INC."
        },
        {
          "WorkOrder": 4024342,
          "ParentWorkOrder": "",
          "JobName": "DEDICATED SENIOR MEDICAL",
          "JobNumber": "65-732612-7",
          "FirstdayontheJob": "04-29-2020",
          "LastdayontheJob": "04-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7642 READING ROAD",
          "JobAddress2": "",
          "JobFullAddress": "7642 READING ROAD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "CBJ CONSTRUCTION OF VA.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$6,785.09",
          "BranchNumber": 1,
          "SubmitDate": "04-30-2020",
          "ClientonJob": "CBJ CONSTRUCTION OF VA."
        },
        {
          "WorkOrder": 4024288,
          "ParentWorkOrder": 4016197,
          "JobName": "(NOF)Rossford Senior Apar",
          "JobNumber": "502-1609034-43",
          "FirstdayontheJob": "03-31-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27480 Simmons Rd./1 Diemling Rd",
          "JobAddress2": "",
          "JobFullAddress": "27480 Simmons Rd./1 Diemling Rd",
          "JobCounty": "Wood",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$3,348.02",
          "BranchNumber": 13,
          "SubmitDate": "04-29-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4023979,
          "ParentWorkOrder": 4011636,
          "JobName": "(NOF) City Flats-phase II",
          "JobNumber": "502-861787-40",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "802 Military St",
          "JobAddress2": "",
          "JobFullAddress": "802 Military St",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$115.33",
          "BranchNumber": 11,
          "SubmitDate": "04-28-2020",
          "ClientonJob": "JFS Company"
        },
        {
          "WorkOrder": 4023533,
          "ParentWorkOrder": "",
          "JobName": "BEAN AND BREW",
          "JobNumber": "114-138111-2",
          "FirstdayontheJob": "04-17-2020",
          "LastdayontheJob": "04-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2914 N State St",
          "JobAddress2": "",
          "JobFullAddress": "2914 N State St",
          "JobCounty": "HINDS",
          "JobState": "Mississippi",
          "CustomerCompanyName": "S5 CONSTRUCTION LLC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$333.41",
          "BranchNumber": 13,
          "SubmitDate": "04-27-2020",
          "ClientonJob": "S5 CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 4023532,
          "ParentWorkOrder": "",
          "JobName": "DORM BUILDING 550",
          "JobNumber": "114-24426-2",
          "FirstdayontheJob": "04-08-2020",
          "LastdayontheJob": "04-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "464 Harpe Blvd",
          "JobAddress2": "",
          "JobFullAddress": "464 Harpe Blvd",
          "JobCounty": "LOWNDES",
          "JobState": "Mississippi",
          "CustomerCompanyName": "RHYE ENTERPRISES INC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$5,236.93",
          "BranchNumber": 16,
          "SubmitDate": "04-27-2020",
          "ClientonJob": "RHYE ENTERPRISES INC"
        },
        {
          "WorkOrder": 4023359,
          "ParentWorkOrder": "",
          "JobName": "TRACTOR SUPPLY WIGGINS",
          "JobNumber": "114-200828-41",
          "FirstdayontheJob": "03-24-2020",
          "LastdayontheJob": "04-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2110 SOUTH AZALEA DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "2110 SOUTH AZALEA DRIVE",
          "JobCounty": "Stone",
          "JobState": "Mississippi",
          "CustomerCompanyName": "THE DAVE JOHNSTON CO. INC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$11,340.07",
          "BranchNumber": 13,
          "SubmitDate": "04-25-2020",
          "ClientonJob": "THE DAVE JOHNSTON CO. INC"
        },
        {
          "WorkOrder": 4023358,
          "ParentWorkOrder": "",
          "JobName": "MCALISTER'S DELI",
          "JobNumber": "114-1388417-4",
          "FirstdayontheJob": "04-15-2020",
          "LastdayontheJob": "04-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5576 HY 45 ALT S",
          "JobAddress2": "",
          "JobFullAddress": "5576 HY 45 ALT S",
          "JobCounty": "CLAY",
          "JobState": "Mississippi",
          "CustomerCompanyName": "SITI LLC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$2,990.09",
          "BranchNumber": 13,
          "SubmitDate": "04-25-2020",
          "ClientonJob": "SITI LLC"
        },
        {
          "WorkOrder": 4023357,
          "ParentWorkOrder": "",
          "JobName": "PORT GIBSON BUS BARN",
          "JobNumber": "114-1381468-4",
          "FirstdayontheJob": "04-14-2020",
          "LastdayontheJob": "04-14-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "800 ANTHONY STREET",
          "JobAddress2": "",
          "JobFullAddress": "800 ANTHONY STREET",
          "JobCounty": "Claiborne",
          "JobState": "Mississippi",
          "CustomerCompanyName": "SCARBROUGH CONSTRUCTION",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$1,382.53",
          "BranchNumber": 13,
          "SubmitDate": "04-25-2020",
          "ClientonJob": "SCARBROUGH CONSTRUCTION"
        },
        {
          "WorkOrder": 4023356,
          "ParentWorkOrder": "",
          "JobName": "SOCIAL SECURITY ADMIN.",
          "JobNumber": "114-1383715-2",
          "FirstdayontheJob": "04-13-2020",
          "LastdayontheJob": "04-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3505 Pemberton Square Blvd",
          "JobAddress2": "",
          "JobFullAddress": "3505 Pemberton Square Blvd",
          "JobCounty": "WARREN",
          "JobState": "Mississippi",
          "CustomerCompanyName": "SOUTHPAW USA INC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$24,006.00",
          "BalanceRemaining": "$22,791.51",
          "BranchNumber": 13,
          "SubmitDate": "04-25-2020",
          "ClientonJob": "SOUTHPAW USA INC"
        },
        {
          "WorkOrder": 4023355,
          "ParentWorkOrder": "",
          "JobName": "DOLLAR GENERAL FOREST",
          "JobNumber": "114-200828-39",
          "FirstdayontheJob": "04-08-2020",
          "LastdayontheJob": "04-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8761 HIGHWAY 35 SOUTH",
          "JobAddress2": "",
          "JobFullAddress": "8761 HIGHWAY 35 SOUTH",
          "JobCounty": "SCOTT",
          "JobState": "Mississippi",
          "CustomerCompanyName": "THE DAVE JOHNSTON CO. INC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$4,781.28",
          "BranchNumber": 13,
          "SubmitDate": "04-25-2020",
          "ClientonJob": "THE DAVE JOHNSTON CO. INC"
        },
        {
          "WorkOrder": 4022850,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Oak Street Health",
          "JobNumber": "502-934634-15",
          "FirstdayontheJob": "04-22-2020",
          "LastdayontheJob": "04-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26390 Gratiot Avenue",
          "JobAddress2": "",
          "JobFullAddress": "26390 Gratiot Avenue",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONST. GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$4,005.80",
          "BranchNumber": 9,
          "SubmitDate": "04-23-2020",
          "ClientonJob": "BLUESTONE CONST. GROUP"
        },
        {
          "WorkOrder": 4022849,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Oak Street Pontiac",
          "JobNumber": "502-934634-14",
          "FirstdayontheJob": "04-22-2020",
          "LastdayontheJob": "04-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "462 N Telegraph Rd",
          "JobAddress2": "",
          "JobFullAddress": "462 N Telegraph Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONST. GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,866.58",
          "BranchNumber": 9,
          "SubmitDate": "04-23-2020",
          "ClientonJob": "BLUESTONE CONST. GROUP"
        },
        {
          "WorkOrder": 4022848,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Oak Street Health",
          "JobNumber": "502-934634-13",
          "FirstdayontheJob": "04-22-2020",
          "LastdayontheJob": "04-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14507 Woodward Avenue",
          "JobAddress2": "",
          "JobFullAddress": "14507 Woodward Avenue",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONST. GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$28,006.00",
          "BalanceRemaining": "$16,244.00",
          "BranchNumber": 9,
          "SubmitDate": "04-23-2020",
          "ClientonJob": "BLUESTONE CONST. GROUP"
        },
        {
          "WorkOrder": 4022847,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GM Brownstown",
          "JobNumber": "502-812350-93",
          "FirstdayontheJob": "04-22-2020",
          "LastdayontheJob": "04-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20001 Brownstown Center DR",
          "JobAddress2": "",
          "JobFullAddress": "20001 Brownstown Center DR",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-23-2020",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4022846,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Belle Tire Brownsbu",
          "JobNumber": "502-967075-126",
          "FirstdayontheJob": "04-22-2020",
          "LastdayontheJob": "04-22-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "318 East Northfield Dr.",
          "JobAddress2": "",
          "JobFullAddress": "318 East Northfield Dr.",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,779.44",
          "BranchNumber": 7,
          "SubmitDate": "",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 4022428,
          "ParentWorkOrder": "",
          "JobName": "Cincinnati VA Medical",
          "JobNumber": "65-2135243-4",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3200 VINE ST",
          "JobAddress2": "",
          "JobFullAddress": "3200 VINE ST",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "NORTHVIEW ENTERPRISES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$6,897.50",
          "BranchNumber": 13,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "NORTHVIEW ENTERPRISES INC"
        },
        {
          "WorkOrder": 4022427,
          "ParentWorkOrder": "",
          "JobName": "INVERNESS HOMES",
          "JobNumber": "65-502950-6",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "DEVIN CONSTRUCTION CO LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$50,000.00",
          "BalanceRemaining": "$13,287.59",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "DEVIN CONSTRUCTION CO LLC"
        },
        {
          "WorkOrder": 4022426,
          "ParentWorkOrder": "",
          "JobName": "HIGHLAND DIST. HOSPITAL",
          "JobNumber": "65-502280-62",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1275 N. HIGH STREET",
          "JobAddress2": "",
          "JobFullAddress": "1275 N. HIGH STREET",
          "JobCounty": "Highland",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALTIES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "COMBS INTERIOR SPECIALTIES INC"
        },
        {
          "WorkOrder": 4022425,
          "ParentWorkOrder": "",
          "JobName": "GE Mid Campus Revital",
          "JobNumber": "65-502280-59",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Neumann Way",
          "JobAddress2": "",
          "JobFullAddress": "1 Neumann Way",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,343.06",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "COMBS INTERIOR"
        },
        {
          "WorkOrder": 4022424,
          "ParentWorkOrder": "",
          "JobName": "(   ) NEW BREMEN SCHOOLS",
          "JobNumber": "65-134513-3",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "COTTERMAN & COMPANY, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$2,664.38",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "COTTERMAN & COMPANY, INC."
        },
        {
          "WorkOrder": 4022423,
          "ParentWorkOrder": "",
          "JobName": "FAIRFIELD LAKES",
          "JobNumber": "65-133572-2",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2715 HEMLOCK DR",
          "JobAddress2": "JOB# 9742",
          "JobFullAddress": "2715 HEMLOCK DR JOB# 9742",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "KATERRA RENOVATIONS/",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$517.61",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "KATERRA RENOVATIONS/"
        },
        {
          "WorkOrder": 4022422,
          "ParentWorkOrder": "",
          "JobName": "NOF FAIRBORN II",
          "JobNumber": "65-131137-5",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2200 COMMERCE CENTER BLVD",
          "JobAddress2": "",
          "JobFullAddress": "2200 COMMERCE CENTER BLVD",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "A PLUS PAINTERS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$100,005.00",
          "BalanceRemaining": "$34,120.87",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "A PLUS PAINTERS LLC"
        },
        {
          "WorkOrder": 4022421,
          "ParentWorkOrder": "",
          "JobName": "MIAMI COUNTY HOSPICE",
          "JobNumber": "65-13105-2",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3230 N COUNTY RD   25A",
          "JobAddress2": "",
          "JobFullAddress": "3230 N COUNTY RD   25A",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "INTEGRITY INTERIORS US",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$12,782.19",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "INTEGRITY INTERIORS US"
        },
        {
          "WorkOrder": 4022420,
          "ParentWorkOrder": "",
          "JobName": "NOF REDS BUILDING B",
          "JobNumber": "65-505398-10",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5215 HERRINGBONE DR",
          "JobAddress2": "",
          "JobFullAddress": "5215 HERRINGBONE DR",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "RAY ISAAC PLASTERING LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$21,234.13",
          "BranchNumber": 13,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "RAY ISAAC PLASTERING LLC"
        },
        {
          "WorkOrder": 4022419,
          "ParentWorkOrder": "",
          "JobName": "BRANDT MEADOWS APARTMENTS",
          "JobNumber": "65-2135207-4",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4148 BRANDT MEADOWS DR",
          "JobAddress2": "",
          "JobFullAddress": "4148 BRANDT MEADOWS DR",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "AUGUSTUS DRYWALL & INTERI",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$31,154.47",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "AUGUSTUS DRYWALL & INTERI"
        },
        {
          "WorkOrder": 4022418,
          "ParentWorkOrder": "",
          "JobName": "WHITE OAKS LANDING",
          "JobNumber": "65-2134905-4",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2500-2504 CENTERS POINT",
          "JobAddress2": "***LAY ALL BOARD DOWN***",
          "JobFullAddress": "2500-2504 CENTERS POINT ***LAY ALL BOARD DOWN***",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "L & G CONTRACTING  INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$35,882.88",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "L & G CONTRACTING  INC."
        },
        {
          "WorkOrder": 4022417,
          "ParentWorkOrder": "",
          "JobName": "OTTERBEIN SENIOR LIFE",
          "JobNumber": "65-2134688-15",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "585 OH-741",
          "JobAddress2": "",
          "JobFullAddress": "585 OH-741",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$90,006.00",
          "BalanceRemaining": "$52,474.70",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 4022416,
          "ParentWorkOrder": "",
          "JobName": "NOF STONEGATE",
          "JobNumber": "65-2135198-5",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7945 STONEGATE DR",
          "JobAddress2": "",
          "JobFullAddress": "7945 STONEGATE DR",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "GREEN VALLEY CONTRACTORS,",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$72,384.84",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "GREEN VALLEY CONTRACTORS,"
        },
        {
          "WorkOrder": 4022415,
          "ParentWorkOrder": "",
          "JobName": "EUCLID AVE",
          "JobNumber": "65-2135053-4",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7766 EUCLID AVE",
          "JobAddress2": "",
          "JobFullAddress": "7766 EUCLID AVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRI-STATE STUCCO",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$10,049.50",
          "BranchNumber": 12,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "TRI-STATE STUCCO"
        },
        {
          "WorkOrder": 4022414,
          "ParentWorkOrder": "",
          "JobName": "OAKSTREET HEALTH",
          "JobNumber": "65-2134688-16",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1431 WAYNE AVE",
          "JobAddress2": "",
          "JobFullAddress": "1431 WAYNE AVE",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 4022413,
          "ParentWorkOrder": "",
          "JobName": "HYATT HOUSE",
          "JobNumber": "65-123331-3",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7800 LIBERTY FIELD DR",
          "JobAddress2": "",
          "JobFullAddress": "7800 LIBERTY FIELD DR",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "RAFIC NAKOUZI",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$25,262.40",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "RAFIC NAKOUZI"
        },
        {
          "WorkOrder": 4022412,
          "ParentWorkOrder": "",
          "JobName": "Midmark Research & Dev",
          "JobNumber": "65-2134688-14",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "60 Vista Dr",
          "JobAddress2": "",
          "JobFullAddress": "60 Vista Dr",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$500,006.00",
          "BalanceRemaining": "$28,062.22",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 4022410,
          "ParentWorkOrder": "",
          "JobName": "C&R GROUP",
          "JobNumber": "65-2137209-5",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 NEUMANN WAY",
          "JobAddress2": "",
          "JobFullAddress": "1 NEUMANN WAY",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "WOLSELEY INDUSTRIAL GRO",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$112.00",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "WOLSELEY INDUSTRIAL GRO"
        },
        {
          "WorkOrder": 4022409,
          "ParentWorkOrder": "",
          "JobName": "LIVINGSTON",
          "JobNumber": "65-2136289-7",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1573 E LIVINGSTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "1573 E LIVINGSTON AVE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "SOWERS COMMERCIAL, LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$21,693.07",
          "BranchNumber": 9,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "SOWERS COMMERCIAL, LLC"
        },
        {
          "WorkOrder": 4022408,
          "ParentWorkOrder": "",
          "JobName": "NOF Scholar House",
          "JobNumber": "65-2136289-6",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "93 North 17th St",
          "JobAddress2": "",
          "JobFullAddress": "93 North 17th St",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "SOWERS COMMERCIAL, LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,243.62",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "SOWERS COMMERCIAL, LLC"
        },
        {
          "WorkOrder": 4022407,
          "ParentWorkOrder": "",
          "JobName": "Suttervi",
          "JobNumber": "65-2134540-11",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1901 Knob Ct",
          "JobAddress2": "",
          "JobFullAddress": "1901 Knob Ct",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "ACE BUILDING SYTEM",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$40,134.16",
          "BranchNumber": 7,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "ACE BUILDING SYTEM"
        },
        {
          "WorkOrder": 4022406,
          "ParentWorkOrder": "",
          "JobName": "WOC UNITED METHODIST CHUR",
          "JobNumber": "65-422006-25",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32 WESLEY BLVD",
          "JobAddress2": "",
          "JobFullAddress": "32 WESLEY BLVD",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "WAYNE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "WAYNE CONSTRUCTION"
        },
        {
          "WorkOrder": 4022405,
          "ParentWorkOrder": "",
          "JobName": "(NJP)BMI CREDIT",
          "JobNumber": "65-484402-3",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "543 N CLEVELAND AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "543 N CLEVELAND AVENUE",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "LCS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$11,006.00",
          "BalanceRemaining": "$371.20",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "LCS LLC"
        },
        {
          "WorkOrder": 4022404,
          "ParentWorkOrder": "",
          "JobName": "Affordable Dentures",
          "JobNumber": "65-460499-2",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5980 E Main",
          "JobAddress2": "",
          "JobFullAddress": "5980 E Main",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "LAWSON CONTRACTING",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$11,395.60",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "LAWSON CONTRACTING"
        },
        {
          "WorkOrder": 4022403,
          "ParentWorkOrder": "",
          "JobName": "(   )SCHUMACHER HOMES",
          "JobNumber": "65-449935-3",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "TERSUS LUX DRYWALL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "TERSUS LUX DRYWALL"
        },
        {
          "WorkOrder": 4022402,
          "ParentWorkOrder": "",
          "JobName": "BWC",
          "JobNumber": "65-422745-84",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30 W SPRING ST",
          "JobAddress2": "",
          "JobFullAddress": "30 W SPRING ST",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "J. YANKLE COMPANY LTD.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$2,020.39",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "J. YANKLE COMPANY LTD."
        },
        {
          "WorkOrder": 4022401,
          "ParentWorkOrder": "",
          "JobName": "Pet Supplies Plus",
          "JobNumber": "65-422745-83",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2430 STRINGTOWN RD",
          "JobAddress2": "SUITE 211",
          "JobFullAddress": "2430 STRINGTOWN RD SUITE 211",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "J. YANKLE COMPANY LTD.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$1,054.06",
          "BranchNumber": 4,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "J. YANKLE COMPANY LTD."
        },
        {
          "WorkOrder": 4022400,
          "ParentWorkOrder": "",
          "JobName": "Park West Spec Office",
          "JobNumber": "65-422745-82",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "220 Park West Dr",
          "JobAddress2": "",
          "JobFullAddress": "220 Park West Dr",
          "JobCounty": "MADISON",
          "JobState": "Ohio",
          "CustomerCompanyName": "J. YANKLE COMPANY LTD.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$1,830.60",
          "BranchNumber": 4,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "J. YANKLE COMPANY LTD."
        },
        {
          "WorkOrder": 4022399,
          "ParentWorkOrder": "",
          "JobName": "OPPORTUNITIES GARDENS",
          "JobNumber": "65-412360-40",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1275 S PLUM ST",
          "JobAddress2": "",
          "JobFullAddress": "1275 S PLUM ST",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Liberty General Contr",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,385.69",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "Liberty General Contr"
        },
        {
          "WorkOrder": 4022398,
          "ParentWorkOrder": "",
          "JobName": "ELKS BUILDING",
          "JobNumber": "65-421030-3",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 EAST 3RD",
          "JobAddress2": "",
          "JobFullAddress": "100 EAST 3RD",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Windsor Construct Svc LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,271.93",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "Windsor Construct Svc LLC"
        },
        {
          "WorkOrder": 4022397,
          "ParentWorkOrder": "",
          "JobName": "(NJP)Rockford Homes",
          "JobNumber": "65-412229-9",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "*ADDRESS NEEDED*",
          "JobAddress2": "",
          "JobFullAddress": "*ADDRESS NEEDED*",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "LAKE HILL DEV.GROUP (COL)",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$487.14",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "LAKE HILL DEV.GROUP (COL)"
        },
        {
          "WorkOrder": 4022396,
          "ParentWorkOrder": "",
          "JobName": "WHITEOAK LANDINGS",
          "JobNumber": "65-408044-4",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4236 CENTERPOINT DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "4236 CENTERPOINT DRIVE",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "PRIDE ONE CONSTRUCTION SV",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "PRIDE ONE CONSTRUCTION SV"
        },
        {
          "WorkOrder": 4022395,
          "ParentWorkOrder": "",
          "JobName": "(NJP)TROPSMOOTH19131",
          "JobNumber": "65-402000-2",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4038 MORSE CROSSING RD.",
          "JobAddress2": "TROPICAL SMOOTHIE CAFE",
          "JobFullAddress": "4038 MORSE CROSSING RD. TROPICAL SMOOTHIE CAFE",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "GREAT LAKES BAY CONSTR",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "GREAT LAKES BAY CONSTR"
        },
        {
          "WorkOrder": 4022394,
          "ParentWorkOrder": "",
          "JobName": "SPENGA",
          "JobNumber": "65-42942-5",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4686 CEMETERY RD.",
          "JobAddress2": "BCR CONST.AND DEVELOPMENT",
          "JobFullAddress": "4686 CEMETERY RD. BCR CONST.AND DEVELOPMENT",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "C.A. Orr Construction Co.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$20,000.00",
          "BalanceRemaining": "$9,393.85",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "C.A. Orr Construction Co."
        },
        {
          "WorkOrder": 4022392,
          "ParentWorkOrder": "",
          "JobName": "FULL THROTTLE",
          "JobNumber": "65-551119-9",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11725 COMMONS DR",
          "JobAddress2": "",
          "JobFullAddress": "11725 COMMONS DR",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "RELIANCE  CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,525.68",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "RELIANCE  CONSTRUCTION"
        },
        {
          "WorkOrder": 4022391,
          "ParentWorkOrder": "",
          "JobName": "STOP 8",
          "JobNumber": "65-510300-54",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3545 STOP 8 RD",
          "JobAddress2": "",
          "JobFullAddress": "3545 STOP 8 RD",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$32,246.70",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4022390,
          "ParentWorkOrder": "",
          "JobName": "ABBOTT",
          "JobNumber": "65-565722-3",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3900 LINEMAN DR",
          "JobAddress2": "",
          "JobFullAddress": "3900 LINEMAN DR",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "WB SERVICES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$100,000,000.00",
          "BalanceRemaining": "$923.54",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "WB SERVICES INC"
        },
        {
          "WorkOrder": 4022389,
          "ParentWorkOrder": "",
          "JobName": "(NOF)COMFORT INN MAINSTAY",
          "JobNumber": "65-565722-2",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2363 READING RD",
          "JobAddress2": "",
          "JobFullAddress": "2363 READING RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "WB SERVICES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$33,674.63",
          "BranchNumber": 2,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "WB SERVICES INC"
        },
        {
          "WorkOrder": 4022388,
          "ParentWorkOrder": "",
          "JobName": "Embassy Suites",
          "JobNumber": "65-530141-2",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4554 Lakeforest Dr",
          "JobAddress2": "",
          "JobFullAddress": "4554 Lakeforest Dr",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "KEELER CONTRACTING",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 2,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "KEELER CONTRACTING"
        },
        {
          "WorkOrder": 4022387,
          "ParentWorkOrder": "",
          "JobName": "Equitas Health",
          "JobNumber": "65-510590-41",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2805 Gilbert Ave",
          "JobAddress2": "",
          "JobFullAddress": "2805 Gilbert Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,235.97",
          "BranchNumber": 2,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "TRIVERSITY CRAFT FORCE"
        },
        {
          "WorkOrder": 4022386,
          "ParentWorkOrder": "",
          "JobName": "UC Radiology",
          "JobNumber": "65-510590-39",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "234 Goodman Street",
          "JobAddress2": "",
          "JobFullAddress": "234 Goodman Street",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$1,818.55",
          "BranchNumber": 2,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "TRIVERSITY CRAFT FORCE LLC"
        },
        {
          "WorkOrder": 4022385,
          "ParentWorkOrder": "",
          "JobName": "Prus Office Expansion",
          "JobNumber": "65-510590-38",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5325 Wooster Pike",
          "JobAddress2": "",
          "JobFullAddress": "5325 Wooster Pike",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,630.23",
          "BranchNumber": 2,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "TRIVERSITY CRAFT FORCE LLC"
        },
        {
          "WorkOrder": 4022384,
          "ParentWorkOrder": "",
          "JobName": "SALVAGNINI",
          "JobNumber": "65-505800-10",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27 BICENTENNIAL CT",
          "JobAddress2": "",
          "JobFullAddress": "27 BICENTENNIAL CT",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "KIESLAND DEVELOPMENT SERVICES LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$8,308.57",
          "BranchNumber": 2,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "KIESLAND DEVELOPMENT SERVICES LLC"
        },
        {
          "WorkOrder": 4022383,
          "ParentWorkOrder": "",
          "JobName": "TDG ROCKFIELD",
          "JobNumber": "65-510300-53",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11400 ROCKFIELD CT",
          "JobAddress2": "",
          "JobFullAddress": "11400 ROCKFIELD CT",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$15,151.63",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4022382,
          "ParentWorkOrder": "",
          "JobName": "Frist Financial",
          "JobNumber": "65-510300-47",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "920 E McMillan",
          "JobAddress2": "(GC North Shore)",
          "JobFullAddress": "920 E McMillan (GC North Shore)",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$486.47",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4022381,
          "ParentWorkOrder": "",
          "JobName": "BEACON ORTHOPAEDICS",
          "JobNumber": "65-505800-9",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "500 E BUSINESS WAY",
          "JobAddress2": "",
          "JobFullAddress": "500 E BUSINESS WAY",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "KIESLAND DEVELOPMENT SERVICES LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,893.42",
          "BranchNumber": 2,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "KIESLAND DEVELOPMENT SERVICES LLC"
        },
        {
          "WorkOrder": 4022380,
          "ParentWorkOrder": "",
          "JobName": "5/3 CENTER",
          "JobNumber": "65-505694-2",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 S MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "1 S MAIN ST",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "KIBLER MILLING CO., INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$4,189.36",
          "BranchNumber": 2,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "KIBLER MILLING CO., INC."
        },
        {
          "WorkOrder": 4022379,
          "ParentWorkOrder": "",
          "JobName": "NOF Maple Knoll Village",
          "JobNumber": "65-55219-10",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11100 Springfield Pike",
          "JobAddress2": "",
          "JobFullAddress": "11100 Springfield Pike",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lemcer Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "Lemcer Construction LLC"
        },
        {
          "WorkOrder": 4022378,
          "ParentWorkOrder": "",
          "JobName": "NOC MARSHALLS",
          "JobNumber": "65-50355-3",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3862 PAXTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "3862 PAXTON AVE",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "KERRICOOK CONST. INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$27,963.98",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "KERRICOOK CONST. INC"
        },
        {
          "WorkOrder": 4022377,
          "ParentWorkOrder": "",
          "JobName": "AVID HOTEL",
          "JobNumber": "65-770564-22",
          "FirstdayontheJob": "04-21-2020",
          "LastdayontheJob": "04-21-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8927 LAKOTA DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "8927 LAKOTA DRIVE",
          "JobCounty": "Butler",
          "JobState": "Ohio",
          "CustomerCompanyName": "PRODIGY CONSTRUCTION CORP",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "04-22-2020",
          "ClientonJob": "PRODIGY CONSTRUCTION CORP"
        },
        {
          "WorkOrder": 4021074,
          "ParentWorkOrder": "",
          "JobName": "ELKS BUILDING",
          "JobNumber": "65-421030-3",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 EAST 3RD ST",
          "JobAddress2": "",
          "JobFullAddress": "100 EAST 3RD ST",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "Windsor Construct Svc LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,271.93",
          "BranchNumber": 4,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "Windsor Construct Svc LLC"
        },
        {
          "WorkOrder": 4021073,
          "ParentWorkOrder": "",
          "JobName": "(NJP)BMI CREDIT",
          "JobNumber": "65-484402-3",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "543 N CLEVELAND AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "543 N CLEVELAND AVENUE",
          "JobCounty": "DELAWARE",
          "JobState": "Ohio",
          "CustomerCompanyName": "LCS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$11,006.00",
          "BalanceRemaining": "$371.20",
          "BranchNumber": 4,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "LCS LLC"
        },
        {
          "WorkOrder": 4021072,
          "ParentWorkOrder": "",
          "JobName": "Equitas Health",
          "JobNumber": "65-510590-41",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2805 Gilbert Ave",
          "JobAddress2": "",
          "JobFullAddress": "2805 Gilbert Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$576.17",
          "BranchNumber": 2,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "TRIVERSITY CRAFT FORCE LLC"
        },
        {
          "WorkOrder": 4021071,
          "ParentWorkOrder": "",
          "JobName": "Prus Office Expansion",
          "JobNumber": "65-510590-38",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5325 Wooster Pike",
          "JobAddress2": "",
          "JobFullAddress": "5325 Wooster Pike",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,350.02",
          "BranchNumber": 2,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "TRIVERSITY CRAFT FORCE LLC"
        },
        {
          "WorkOrder": 4021070,
          "ParentWorkOrder": "",
          "JobName": "HYATT HOUSE",
          "JobNumber": "65-123331-3",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7800 LIBERTY FIELD DR",
          "JobAddress2": "",
          "JobFullAddress": "7800 LIBERTY FIELD DR",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "RAFIC NAKOUZI",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$25,262.40",
          "BranchNumber": 12,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "RAFIC NAKOUZI"
        },
        {
          "WorkOrder": 4021069,
          "ParentWorkOrder": "",
          "JobName": "FULL THROTTLE",
          "JobNumber": "65-551119-9",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11725 COMMONS DR",
          "JobAddress2": "",
          "JobFullAddress": "11725 COMMONS DR",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "RELIANCE  CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$1,622.79",
          "BranchNumber": 2,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "RELIANCE  CONSTRUCTION"
        },
        {
          "WorkOrder": 4021068,
          "ParentWorkOrder": "",
          "JobName": "AVID HOTEL",
          "JobNumber": "65-770564-22",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8927 LAKOTA DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "8927 LAKOTA DRIVE",
          "JobCounty": "Butler",
          "JobState": "Ohio",
          "CustomerCompanyName": "PRODIGY CONSTRUCTION CORP",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "PRODIGY CONSTRUCTION CORP"
        },
        {
          "WorkOrder": 4021067,
          "ParentWorkOrder": "",
          "JobName": "WHITE OAKS LANDING",
          "JobNumber": "65-2134905-4",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2500-2504 CENTER POINT DR",
          "JobAddress2": "",
          "JobFullAddress": "2500-2504 CENTER POINT DR",
          "JobCounty": "Greene",
          "JobState": "Ohio",
          "CustomerCompanyName": "L & G CONTRACTING  INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$35,882.88",
          "BranchNumber": 12,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "L & G CONTRACTING  INC."
        },
        {
          "WorkOrder": 4021066,
          "ParentWorkOrder": "",
          "JobName": "WHITEOAK LANDINGS",
          "JobNumber": "65-408044-4",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4236 CENTER POINT DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "4236 CENTER POINT DRIVE",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "PRIDE ONE CONSTRUCTION SV",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 4,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "PRIDE ONE CONSTRUCTION SV"
        },
        {
          "WorkOrder": 4021065,
          "ParentWorkOrder": "",
          "JobName": "First Financial",
          "JobNumber": "65-510300-47",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "920 E McMillan St",
          "JobAddress2": "",
          "JobFullAddress": "920 E McMillan St",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$486.47",
          "BranchNumber": 2,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4021064,
          "ParentWorkOrder": "",
          "JobName": "STOP 8",
          "JobNumber": "65-510300-54",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3435/3545 STOP 8 RD",
          "JobAddress2": "",
          "JobFullAddress": "3435/3545 STOP 8 RD",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$32,185.39",
          "BranchNumber": 2,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4021063,
          "ParentWorkOrder": "",
          "JobName": "UC Radiology",
          "JobNumber": "65-510590-39",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "234 Goodman Street",
          "JobAddress2": "",
          "JobFullAddress": "234 Goodman Street",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$1,818.55",
          "BranchNumber": 2,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "TRIVERSITY CRAFT FORCE LLC"
        },
        {
          "WorkOrder": 4021062,
          "ParentWorkOrder": "",
          "JobName": "OAKSTREET HEALTH",
          "JobNumber": "65-2134688-16",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1431 WAYNE AVE",
          "JobAddress2": "",
          "JobFullAddress": "1431 WAYNE AVE",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVICES",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 12,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "BENCO CONSTRUCTION SERVICES"
        },
        {
          "WorkOrder": 4021061,
          "ParentWorkOrder": "",
          "JobName": "SALVAGNINI",
          "JobNumber": "65-505800-10",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27 BICENTENNIAL CT",
          "JobAddress2": "",
          "JobFullAddress": "27 BICENTENNIAL CT",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "KIESLAND DEVELOPMENT SERVICES LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$4,585.81",
          "BranchNumber": 2,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "KIESLAND DEVELOPMENT SERVICES LLC"
        },
        {
          "WorkOrder": 4021060,
          "ParentWorkOrder": "",
          "JobName": "BEACON ORTHOPAEDICS",
          "JobNumber": "65-505800-9",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "500 E Business Way",
          "JobAddress2": "",
          "JobFullAddress": "500 E Business Way",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "KIESLAND DEVELOPMENT SERVICES LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,546.32",
          "BranchNumber": 2,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "KIESLAND DEVELOPMENT SERVICES LLC"
        },
        {
          "WorkOrder": 4021059,
          "ParentWorkOrder": "",
          "JobName": "NOC MARSHALLS",
          "JobNumber": "65-50355-3",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3862 Paxton Ave",
          "JobAddress2": "",
          "JobFullAddress": "3862 Paxton Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "KERRICOOK CONST. INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$27,963.98",
          "BranchNumber": 2,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "KERRICOOK CONST. INC"
        },
        {
          "WorkOrder": 4021058,
          "ParentWorkOrder": "",
          "JobName": "FAIRFIELD LAKES",
          "JobNumber": "65-133572-2",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2715 HEMLOCK DR",
          "JobAddress2": "",
          "JobFullAddress": "2715 HEMLOCK DR",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "KATERRA RENOVATIONS/CONSTRCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$517.61",
          "BranchNumber": 13,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "KATERRA RENOVATIONS/CONSTRCTION"
        },
        {
          "WorkOrder": 4021057,
          "ParentWorkOrder": "",
          "JobName": "BWC",
          "JobNumber": "65-422745-84",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30 W SPRING ST",
          "JobAddress2": "",
          "JobFullAddress": "30 W SPRING ST",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "J. YANKLE COMPANY LTD.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$2,020.39",
          "BranchNumber": 4,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "J. YANKLE COMPANY LTD."
        },
        {
          "WorkOrder": 4021056,
          "ParentWorkOrder": "",
          "JobName": "OTTERBEIN SENIOR LIFE",
          "JobNumber": "65-2134688-15",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "585 OH-741",
          "JobAddress2": "",
          "JobFullAddress": "585 OH-741",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$90,006.00",
          "BalanceRemaining": "$46,305.05",
          "BranchNumber": 12,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 4021055,
          "ParentWorkOrder": "",
          "JobName": "BRANDT MEADOWS APARTMENTS",
          "JobNumber": "65-2135207-4",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4148 BRANDT MEADOWS DR",
          "JobAddress2": "",
          "JobFullAddress": "4148 BRANDT MEADOWS DR",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "AUGUSTUS DRYWALL & INTERI",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$30,687.55",
          "BranchNumber": 13,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "AUGUSTUS DRYWALL & INTERI"
        },
        {
          "WorkOrder": 4021054,
          "ParentWorkOrder": "",
          "JobName": "(NJP)TROPSMOOTH19131",
          "JobNumber": "65-402000-2",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4038 MORSE CROSSING RD",
          "JobAddress2": "",
          "JobFullAddress": "4038 MORSE CROSSING RD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "GREAT LAKES BAY CONSTR",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 4,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "GREAT LAKES BAY CONSTR"
        },
        {
          "WorkOrder": 4021053,
          "ParentWorkOrder": "",
          "JobName": "GE Mid Campus Revital",
          "JobNumber": "65-502280-59",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Neumann Way",
          "JobAddress2": "",
          "JobFullAddress": "1 Neumann Way",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALTIES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,422.90",
          "BranchNumber": 13,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "COMBS INTERIOR SPECIALTIES INC"
        },
        {
          "WorkOrder": 4021052,
          "ParentWorkOrder": "",
          "JobName": "Affordable Dentures",
          "JobNumber": "65-460499-2",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5980 E Main St",
          "JobAddress2": "",
          "JobFullAddress": "5980 E Main St",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "LAWSON CONTRACTING",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$11,395.60",
          "BranchNumber": 4,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "LAWSON CONTRACTING"
        },
        {
          "WorkOrder": 4021051,
          "ParentWorkOrder": "",
          "JobName": "DAVITA DIALYSIS",
          "JobNumber": "65-2134697-13",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11135 MONTGOMERY RD",
          "JobAddress2": "",
          "JobFullAddress": "11135 MONTGOMERY RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "J.F. BRAKE CONTRACTOR",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$6,889.87",
          "BranchNumber": 12,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "J.F. BRAKE CONTRACTOR"
        },
        {
          "WorkOrder": 4021050,
          "ParentWorkOrder": "",
          "JobName": "Midmark Research & Dev",
          "JobNumber": "65-2134688-14",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "60 Vista Dr",
          "JobAddress2": "",
          "JobFullAddress": "60 Vista Dr",
          "JobCounty": "DARKE",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$500,006.00",
          "BalanceRemaining": "$27,961.42",
          "BranchNumber": 12,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 4021049,
          "ParentWorkOrder": "",
          "JobName": "SPENGA",
          "JobNumber": "65-42942-5",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4686 CEMETERY RD",
          "JobAddress2": "",
          "JobFullAddress": "4686 CEMETERY RD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "C.A. Orr Construction Co.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,000.00",
          "BalanceRemaining": "$9,080.43",
          "BranchNumber": 4,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "C.A. Orr Construction Co."
        },
        {
          "WorkOrder": 4021048,
          "ParentWorkOrder": "",
          "JobName": "WOC UNITED METHODIST CHUR",
          "JobNumber": "65-422006-25",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32 Wesley Blvd",
          "JobAddress2": "",
          "JobFullAddress": "32 Wesley Blvd",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "WAYNE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 4,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "WAYNE CONSTRUCTION"
        },
        {
          "WorkOrder": 4021047,
          "ParentWorkOrder": "",
          "JobName": "MIAMI COUNTY HOSPICE",
          "JobNumber": "65-13105-2",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3230 N COUNTY RD 25A",
          "JobAddress2": "",
          "JobFullAddress": "3230 N COUNTY RD 25A",
          "JobCounty": "MIAMI",
          "JobState": "Ohio",
          "CustomerCompanyName": "INTEGRITY INTERIORS US LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$5,358.39",
          "BranchNumber": 13,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "INTEGRITY INTERIORS US LLC"
        },
        {
          "WorkOrder": 4021046,
          "ParentWorkOrder": "",
          "JobName": "OPPORTUNITIES GARDENS",
          "JobNumber": "65-412360-40",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1275 S PLUM ST",
          "JobAddress2": "",
          "JobFullAddress": "1275 S PLUM ST",
          "JobCounty": "CLARK",
          "JobState": "Ohio",
          "CustomerCompanyName": "Liberty General Contr",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$930.04",
          "BranchNumber": 4,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "Liberty General Contr"
        },
        {
          "WorkOrder": 4021045,
          "ParentWorkOrder": "",
          "JobName": "STONEGATE",
          "JobNumber": "65-2135198-5",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7945 STONEGATE DR",
          "JobAddress2": "",
          "JobFullAddress": "7945 STONEGATE DR",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "GREEN VALLEY CONTRACTORS,",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$52,392.91",
          "BranchNumber": 12,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "GREEN VALLEY CONTRACTORS,"
        },
        {
          "WorkOrder": 4021044,
          "ParentWorkOrder": "",
          "JobName": "FAIRBORN II",
          "JobNumber": "65-131137-5",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2200 COMMERCE CENTER BLVD",
          "JobAddress2": "",
          "JobFullAddress": "2200 COMMERCE CENTER BLVD",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "A PLUS PAINTERS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,005.00",
          "BalanceRemaining": "$47,023.44",
          "BranchNumber": 13,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "A PLUS PAINTERS LLC"
        },
        {
          "WorkOrder": 4021043,
          "ParentWorkOrder": "",
          "JobName": "TDG ROCKFIELD",
          "JobNumber": "65-510300-53",
          "FirstdayontheJob": "04-16-2020",
          "LastdayontheJob": "04-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11400 ROCKFIELD CT",
          "JobAddress2": "",
          "JobFullAddress": "11400 ROCKFIELD CT",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$15,151.63",
          "BranchNumber": 2,
          "SubmitDate": "04-17-2020",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 4020983,
          "ParentWorkOrder": 4011630,
          "JobName": "(NOF)THE BRISTOL",
          "JobNumber": "502-910850-8",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "420 E Frank St",
          "JobAddress2": "",
          "JobFullAddress": "420 E Frank St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "AMERICAN WALL SYSTEM",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$7,584.88",
          "BranchNumber": 9,
          "SubmitDate": "04-16-2020",
          "ClientonJob": "AMERICAN WALL SYSTEM"
        },
        {
          "WorkOrder": 4020712,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Andover Apartments",
          "JobNumber": "502-17250-2",
          "FirstdayontheJob": "04-14-2020",
          "LastdayontheJob": "04-14-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2647 Eastgate Rd",
          "JobAddress2": "",
          "JobFullAddress": "2647 Eastgate Rd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "PARKE BROTHERS CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$2,126.11",
          "BranchNumber": 13,
          "SubmitDate": "04-15-2020",
          "ClientonJob": "PARKE BROTHERS CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 4020711,
          "ParentWorkOrder": "",
          "JobName": "NOF Scholar House",
          "JobNumber": "65-2136289-6",
          "FirstdayontheJob": "04-14-2020",
          "LastdayontheJob": "04-14-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "93 North 17th St",
          "JobAddress2": "",
          "JobFullAddress": "93 North 17th St",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "SOWERS COMMERCIAL, LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,076.84",
          "BranchNumber": 9,
          "SubmitDate": "04-15-2020",
          "ClientonJob": "SOWERS COMMERCIAL, LLC"
        },
        {
          "WorkOrder": 4020710,
          "ParentWorkOrder": "",
          "JobName": "NOF Maple Knoll Village",
          "JobNumber": "65-55219-10",
          "FirstdayontheJob": "04-14-2020",
          "LastdayontheJob": "04-14-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11100 Springfield Pike",
          "JobAddress2": "",
          "JobFullAddress": "11100 Springfield Pike",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lemcer Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$18,251.04",
          "BranchNumber": 2,
          "SubmitDate": "04-15-2020",
          "ClientonJob": "Lemcer Construction LLC"
        },
        {
          "WorkOrder": 4020594,
          "ParentWorkOrder": "",
          "JobName": "GRACEWAY APARTMENTS",
          "JobNumber": "114-60558-2",
          "FirstdayontheJob": "01-14-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20815 BISHOP ROAD",
          "JobAddress2": "",
          "JobFullAddress": "20815 BISHOP ROAD",
          "JobCounty": "Baldwin",
          "JobState": "Alabama",
          "CustomerCompanyName": "DCY ENTERPRISES LLC",
          "RequestType": "Statement of Lien (Unpaid Balance Lien)",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Recorded",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$44,004.75",
          "BranchNumber": 6,
          "SubmitDate": "04-15-2020",
          "ClientonJob": "DCY ENTERPRISES LLC"
        },
        {
          "WorkOrder": 4020260,
          "ParentWorkOrder": "",
          "JobName": "DCY ENTERPRISES LLC",
          "JobNumber": "114-60558-1",
          "FirstdayontheJob": "01-22-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5805 AL HWY A SOUTH",
          "JobAddress2": "",
          "JobFullAddress": "5805 AL HWY A SOUTH",
          "JobCounty": "",
          "JobState": "Alabama",
          "CustomerCompanyName": "DCY ENTERPRISES LLC",
          "RequestType": "Statement of Lien (Unpaid Balance Lien)",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Canceled",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$1,229.63",
          "BranchNumber": 6,
          "SubmitDate": "",
          "ClientonJob": "DCY ENTERPRISES LLC"
        },
        {
          "WorkOrder": 4020248,
          "ParentWorkOrder": "",
          "JobName": "SJPH,ST JAMES HOSPITAL",
          "JobNumber": "114-1331440-42",
          "FirstdayontheJob": "02-28-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1645 Lutcher Ave",
          "JobAddress2": "",
          "JobFullAddress": "1645 Lutcher Ave",
          "JobCounty": "St James",
          "JobState": "Louisiana",
          "CustomerCompanyName": "TIKE INTERIORS LLC",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$39,337.42",
          "BranchNumber": 14,
          "SubmitDate": "04-14-2020",
          "ClientonJob": "TIKE INTERIORS LLC"
        },
        {
          "WorkOrder": 4020247,
          "ParentWorkOrder": "",
          "JobName": "SELU STUDENT HALL   =",
          "JobNumber": "114-1331440-37",
          "FirstdayontheJob": "02-05-2020",
          "LastdayontheJob": "03-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "349 S NED MCGEHEE DRIVE",
          "JobAddress2": "JACK TINLEY HALL",
          "JobFullAddress": "349 S NED MCGEHEE DRIVE JACK TINLEY HALL",
          "JobCounty": "",
          "JobState": "Louisiana",
          "CustomerCompanyName": "TIKE INTERIORS LLC",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "On Hold",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$939.26",
          "BranchNumber": 14,
          "SubmitDate": "",
          "ClientonJob": "TIKE INTERIORS LLC"
        },
        {
          "WorkOrder": 4020246,
          "ParentWorkOrder": "",
          "JobName": "LAFOURCHE COMMUNITY &",
          "JobNumber": "114-1331440-32",
          "FirstdayontheJob": "02-13-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5610 HIGHWAY 1",
          "JobAddress2": "RECREATION CENTER",
          "JobFullAddress": "5610 HIGHWAY 1 RECREATION CENTER",
          "JobCounty": "",
          "JobState": "Louisiana",
          "CustomerCompanyName": "TIKE INTERIORS LLC",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "On Hold",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$287.37",
          "BranchNumber": 14,
          "SubmitDate": "",
          "ClientonJob": "TIKE INTERIORS LLC"
        },
        {
          "WorkOrder": 4019874,
          "ParentWorkOrder": "",
          "JobName": "MEXICAN RESTAURANT",
          "JobNumber": "114-1333875-99",
          "FirstdayontheJob": "03-23-2020",
          "LastdayontheJob": "03-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7335 JEFFERSON HWY",
          "JobAddress2": "",
          "JobFullAddress": "7335 JEFFERSON HWY",
          "JobCounty": "JEFFERSON",
          "JobState": "Louisiana",
          "CustomerCompanyName": "HIGHLAND COMMERCIAL CONST",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$5,438.42",
          "BranchNumber": 14,
          "SubmitDate": "04-13-2020",
          "ClientonJob": "HIGHLAND COMMERCIAL CONST"
        },
        {
          "WorkOrder": 4019873,
          "ParentWorkOrder": "",
          "JobName": "100 JAMES DR STE 160",
          "JobNumber": "114-1332300-33",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 James Dr E Ste 160",
          "JobAddress2": "",
          "JobFullAddress": "100 James Dr E Ste 160",
          "JobCounty": "SAINT CHARLES",
          "JobState": "Louisiana",
          "CustomerCompanyName": "Chas Construction",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$2,130.67",
          "BranchNumber": 14,
          "SubmitDate": "04-13-2020",
          "ClientonJob": "Chas Construction"
        },
        {
          "WorkOrder": 4019872,
          "ParentWorkOrder": "",
          "JobName": "JTI",
          "JobNumber": "114-1333875-95",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2740 SAINT LOUIS STREET",
          "JobAddress2": "",
          "JobFullAddress": "2740 SAINT LOUIS STREET",
          "JobCounty": "ORLEANS",
          "JobState": "Louisiana",
          "CustomerCompanyName": "HIGHLAND COMMERCIAL CONST",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,121.42",
          "BranchNumber": 14,
          "SubmitDate": "04-13-2020",
          "ClientonJob": "HIGHLAND COMMERCIAL CONST"
        },
        {
          "WorkOrder": 4019572,
          "ParentWorkOrder": "",
          "JobName": "STORAGEMAX MADISON",
          "JobNumber": "114-10107-2",
          "FirstdayontheJob": "03-01-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "358 CHURCH ROAD",
          "JobAddress2": "",
          "JobFullAddress": "358 CHURCH ROAD",
          "JobCounty": "MADISON",
          "JobState": "Mississippi",
          "CustomerCompanyName": "Catterton Interiors, Inc.",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$2,669.95",
          "BranchNumber": 11,
          "SubmitDate": "04-11-2020",
          "ClientonJob": "Catterton Interiors, Inc."
        },
        {
          "WorkOrder": 4019571,
          "ParentWorkOrder": "",
          "JobName": "HOMEWOOD SUITES FONDREN =",
          "JobNumber": "114-64657-5",
          "FirstdayontheJob": "08-12-2019",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2815 N STATE STREET",
          "JobAddress2": "MPC MP-31057275",
          "JobFullAddress": "2815 N STATE STREET MPC MP-31057275",
          "JobCounty": "",
          "JobState": "Mississippi",
          "CustomerCompanyName": "S&R Development",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "On Hold",
          "ContractAmount": "$205,006.00",
          "BalanceRemaining": "$18,994.89",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "S&R Development"
        },
        {
          "WorkOrder": 4019570,
          "ParentWorkOrder": "",
          "JobName": "AARP - ONE JACKSON PLACE",
          "JobNumber": "114-1385090-7",
          "FirstdayontheJob": "03-24-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "188 E Capitol St",
          "JobAddress2": "",
          "JobFullAddress": "188 E Capitol St",
          "JobCounty": "HINDS",
          "JobState": "Mississippi",
          "CustomerCompanyName": "TROTTI GENERAL CONSTRUCTION",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$2,878.87",
          "BranchNumber": 13,
          "SubmitDate": "04-11-2020",
          "ClientonJob": "TROTTI GENERAL CONSTRUCTION"
        },
        {
          "WorkOrder": 4019569,
          "ParentWorkOrder": "",
          "JobName": "FBC RICHLAND GRACE CENTER",
          "JobNumber": "114-1380354-8",
          "FirstdayontheJob": "03-16-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "155 BRANDON AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "155 BRANDON AVENUE",
          "JobCounty": "Rankin",
          "JobState": "Mississippi",
          "CustomerCompanyName": "Probity Contracting Group",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$5,195.55",
          "BranchNumber": 13,
          "SubmitDate": "04-11-2020",
          "ClientonJob": "Probity Contracting Group"
        },
        {
          "WorkOrder": 4019568,
          "ParentWorkOrder": "",
          "JobName": "BUMPERS - YAZOO CITY",
          "JobNumber": "114-1331750-31",
          "FirstdayontheJob": "03-30-2020",
          "LastdayontheJob": "03-30-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1920 US 49",
          "JobAddress2": "",
          "JobFullAddress": "1920 US 49",
          "JobCounty": "Yazoo",
          "JobState": "Mississippi",
          "CustomerCompanyName": "CERTIFIED CONSTRUCTION",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$229.84",
          "BranchNumber": 13,
          "SubmitDate": "04-11-2020",
          "ClientonJob": "CERTIFIED CONSTRUCTION"
        },
        {
          "WorkOrder": 4019567,
          "ParentWorkOrder": "",
          "JobName": "WINSTON CO ANNEX BLDG",
          "JobNumber": "114-1380118-8",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "04-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "136 S Court Ave",
          "JobAddress2": "",
          "JobFullAddress": "136 S Court Ave",
          "JobCounty": "WINSTON",
          "JobState": "Mississippi",
          "CustomerCompanyName": "KASSEL TILE INC",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$12,032.35",
          "BranchNumber": 13,
          "SubmitDate": "04-11-2020",
          "ClientonJob": "KASSEL TILE INC"
        },
        {
          "WorkOrder": 4019566,
          "ParentWorkOrder": "",
          "JobName": "HOME2 SUITES",
          "JobNumber": "114-64657-6",
          "FirstdayontheJob": "03-16-2020",
          "LastdayontheJob": "03-27-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "437 RIVERWIND DR",
          "JobAddress2": "MPC MP-31059716",
          "JobFullAddress": "437 RIVERWIND DR MPC MP-31059716",
          "JobCounty": "",
          "JobState": "Mississippi",
          "CustomerCompanyName": "S&R Development",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "On Hold",
          "ContractAmount": "$115,006.00",
          "BalanceRemaining": "$8,724.00",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "S&R Development"
        },
        {
          "WorkOrder": 4019565,
          "ParentWorkOrder": "",
          "JobName": "COMMUNITY PLACE NURSING",
          "JobNumber": "114-1381122-8",
          "FirstdayontheJob": "03-10-2020",
          "LastdayontheJob": "04-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "116 LAKE VISTA PLACE",
          "JobAddress2": "",
          "JobFullAddress": "116 LAKE VISTA PLACE",
          "JobCounty": "RANKIN",
          "JobState": "Mississippi",
          "CustomerCompanyName": "Finch Inc",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$10,650.90",
          "BranchNumber": 13,
          "SubmitDate": "04-11-2020",
          "ClientonJob": "Finch Inc"
        },
        {
          "WorkOrder": 4018589,
          "ParentWorkOrder": "",
          "JobName": "Gary Phillips Const.",
          "JobNumber": "394-31065-6",
          "FirstdayontheJob": "12-30-2019",
          "LastdayontheJob": "02-28-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "203 Alto Tree Blvd",
          "JobAddress2": "",
          "JobFullAddress": "203 Alto Tree Blvd",
          "JobCounty": "Washington",
          "JobState": "Tennessee",
          "CustomerCompanyName": "GARY PHILLIPS CONST",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Valerie Nottage",
          "Status": "Mailed",
          "ContractAmount": "$4,000.00",
          "BalanceRemaining": "$3,621.78",
          "BranchNumber": 3,
          "SubmitDate": "04-09-2020",
          "ClientonJob": "GARY PHILLIPS CONST"
        },
        {
          "WorkOrder": 4018588,
          "ParentWorkOrder": "",
          "JobName": "Vine Ave",
          "JobNumber": "394-21505-10",
          "FirstdayontheJob": "12-17-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "535 West Vine Ave",
          "JobAddress2": "",
          "JobFullAddress": "535 West Vine Ave",
          "JobCounty": "KNOX",
          "JobState": "Tennessee",
          "CustomerCompanyName": "WISE CONSTRUCTION, LLC",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Valerie Nottage",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$13,773.32",
          "BranchNumber": 2,
          "SubmitDate": "04-09-2020",
          "ClientonJob": "WISE CONSTRUCTION, LLC"
        },
        {
          "WorkOrder": 4018587,
          "ParentWorkOrder": "",
          "JobName": "1837 Zurich ct",
          "JobNumber": "394-10473-48",
          "FirstdayontheJob": "03-16-2020",
          "LastdayontheJob": "03-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1837 Zurich Ct",
          "JobAddress2": "",
          "JobFullAddress": "1837 Zurich Ct",
          "JobCounty": "Sevier",
          "JobState": "Tennessee",
          "CustomerCompanyName": "RANGEL PAINTING",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Valerie Nottage",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$3,413.57",
          "BranchNumber": 1,
          "SubmitDate": "",
          "ClientonJob": "RANGEL PAINTING"
        },
        {
          "WorkOrder": 4018586,
          "ParentWorkOrder": "",
          "JobName": 1305,
          "JobNumber": "394-10473-47",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1305 Legacy Cove Way",
          "JobAddress2": "",
          "JobFullAddress": "1305 Legacy Cove Way",
          "JobCounty": "Knox",
          "JobState": "Tennessee",
          "CustomerCompanyName": "RANGEL PAINTING",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Valerie Nottage",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$4,597.15",
          "BranchNumber": 1,
          "SubmitDate": "04-09-2020",
          "ClientonJob": "RANGEL PAINTING"
        },
        {
          "WorkOrder": 4018585,
          "ParentWorkOrder": "",
          "JobName": "Rio drive",
          "JobNumber": "394-10473-46",
          "FirstdayontheJob": "02-24-2020",
          "LastdayontheJob": "02-24-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "722 Rio Dr",
          "JobAddress2": "",
          "JobFullAddress": "722 Rio Dr",
          "JobCounty": "Blount",
          "JobState": "Tennessee",
          "CustomerCompanyName": "RANGEL PAINTING",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Valerie Nottage",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$3,886.05",
          "BranchNumber": 1,
          "SubmitDate": "",
          "ClientonJob": "RANGEL PAINTING"
        },
        {
          "WorkOrder": 4018584,
          "ParentWorkOrder": "",
          "JobName": "4625 Meredith Rd",
          "JobNumber": "394-10473-44",
          "FirstdayontheJob": "01-31-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4625 Meredith Rd",
          "JobAddress2": "",
          "JobFullAddress": "4625 Meredith Rd",
          "JobCounty": "Knox",
          "JobState": "Tennessee",
          "CustomerCompanyName": "RANGEL PAINTING",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Valerie Nottage",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$6,676.06",
          "BranchNumber": 1,
          "SubmitDate": "",
          "ClientonJob": "RANGEL PAINTING"
        },
        {
          "WorkOrder": 4018583,
          "ParentWorkOrder": "",
          "JobName": "MIMS OFFICE",
          "JobNumber": "114-24387-3",
          "FirstdayontheJob": "01-23-2020",
          "LastdayontheJob": "04-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "781 TRADE CENTER STREET",
          "JobAddress2": "",
          "JobFullAddress": "781 TRADE CENTER STREET",
          "JobCounty": "Montgomery",
          "JobState": "Alabama",
          "CustomerCompanyName": "Webb Builders",
          "RequestType": "Statement of Lien (Unpaid Balance Lien)",
          "CustomerRepresentative": "Valerie Nottage",
          "Status": "Recorded",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$2,357.94",
          "BranchNumber": 16,
          "SubmitDate": "04-09-2020",
          "ClientonJob": "Webb Builders"
        },
        {
          "WorkOrder": 4018505,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Belle Tire Lafayette",
          "JobNumber": "502-967075-125",
          "FirstdayontheJob": "04-08-2020",
          "LastdayontheJob": "04-08-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4713 Meijer",
          "JobAddress2": "",
          "JobFullAddress": "4713 Meijer",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,886.36",
          "BranchNumber": 7,
          "SubmitDate": "",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 4017794,
          "ParentWorkOrder": 4004238,
          "JobName": "(NOF)Lifeways Bldg Remodel",
          "JobNumber": "502-948950-53",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1200 N. West Ave.",
          "JobAddress2": "",
          "JobFullAddress": "1200 N. West Ave.",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHIVELY-BOUMA, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$2,798.13",
          "BranchNumber": 7,
          "SubmitDate": "04-07-2020",
          "ClientonJob": "SHIVELY-BOUMA, INC."
        },
        {
          "WorkOrder": 4017791,
          "ParentWorkOrder": 4011626,
          "JobName": "(NOF)DPW",
          "JobNumber": "502-817800-50",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1141 Wilcox St.",
          "JobAddress2": "",
          "JobFullAddress": "1141 Wilcox St.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,788.48",
          "BranchNumber": 8,
          "SubmitDate": "04-07-2020",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4016466,
          "ParentWorkOrder": 4006051,
          "JobName": "(NOF) Spencer Gifts",
          "JobNumber": "502-1680400-5",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4314 Milan Rd",
          "JobAddress2": "",
          "JobFullAddress": "4314 Milan Rd",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Nova Construction Millwork",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$4,867.14",
          "BranchNumber": 5,
          "SubmitDate": "04-02-2020",
          "ClientonJob": "Nova Construction Millwork"
        },
        {
          "WorkOrder": 4016225,
          "ParentWorkOrder": "",
          "JobName": "N/A",
          "JobNumber": "114-1336335-4",
          "FirstdayontheJob": "12-23-2019",
          "LastdayontheJob": "01-29-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "842 CAMP STREET",
          "JobAddress2": "",
          "JobFullAddress": "842 CAMP STREET",
          "JobCounty": "",
          "JobState": "Louisiana",
          "CustomerCompanyName": "MKRED BUILD LLC",
          "RequestType": "Notice of Non-Payment (Suppliers Only)",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Stopped",
          "ContractAmount": "$25,000.00",
          "BalanceRemaining": "$5,643.63",
          "BranchNumber": 14,
          "SubmitDate": "",
          "ClientonJob": "MKRED BUILD LLC"
        },
        {
          "WorkOrder": 4016224,
          "ParentWorkOrder": "",
          "JobName": "COLISEUM PLACE APTS",
          "JobNumber": "114-1335208-2",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1765 Coliseum St",
          "JobAddress2": "",
          "JobFullAddress": "1765 Coliseum St",
          "JobCounty": "Orleans",
          "JobState": "Louisiana",
          "CustomerCompanyName": "LOUISIANA COASTAL MGMT",
          "RequestType": "Notice of Non-Payment (Suppliers Only)",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed and Notarized",
          "ContractAmount": "$20,000.00",
          "BalanceRemaining": "$16,287.37",
          "BranchNumber": 14,
          "SubmitDate": "04-01-2020",
          "ClientonJob": "LOUISIANA COASTAL MGMT"
        },
        {
          "WorkOrder": 4016218,
          "ParentWorkOrder": "",
          "JobName": "OCHSNER OUTPATIENT & HOME INFUSION CENTER",
          "JobNumber": "114-150065-15",
          "FirstdayontheJob": "07-19-2019",
          "LastdayontheJob": "04-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4115 Jefferson Hwy",
          "JobAddress2": "",
          "JobFullAddress": "4115 Jefferson Hwy",
          "JobCounty": "Jefferson",
          "JobState": "Louisiana",
          "CustomerCompanyName": "DRYWALL SPECIALTIES INC",
          "RequestType": "Notice of Non-Payment (Suppliers Only)",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed and Notarized",
          "ContractAmount": "$100,000.00",
          "BalanceRemaining": "$10,528.33",
          "BranchNumber": 14,
          "SubmitDate": "04-01-2020",
          "ClientonJob": "DRYWALL SPECIALTIES INC"
        },
        {
          "WorkOrder": 4016213,
          "ParentWorkOrder": "",
          "JobName": "TULANE",
          "JobNumber": "114-1382900-22",
          "FirstdayontheJob": "12-09-2019",
          "LastdayontheJob": "01-14-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1555 Poydras St",
          "JobAddress2": "",
          "JobFullAddress": "1555 Poydras St",
          "JobCounty": "Orleans",
          "JobState": "Louisiana",
          "CustomerCompanyName": "CONSTRUCTION SOUTH INC",
          "RequestType": "Notice of Non-Payment (Suppliers Only)",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed and Notarized",
          "ContractAmount": "$15,000.00",
          "BalanceRemaining": "$4,001.63",
          "BranchNumber": 14,
          "SubmitDate": "04-01-2020",
          "ClientonJob": "CONSTRUCTION SOUTH INC"
        },
        {
          "WorkOrder": 4016210,
          "ParentWorkOrder": "",
          "JobName": "ROCK N ROLL SUSHI",
          "JobNumber": "114-114270-2",
          "FirstdayontheJob": "12-20-2019",
          "LastdayontheJob": "02-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "500 PORT OF NEW ORLEANS PLACE",
          "JobAddress2": "",
          "JobFullAddress": "500 PORT OF NEW ORLEANS PLACE",
          "JobCounty": "Orleans",
          "JobState": "Louisiana",
          "CustomerCompanyName": "AFFINITY CONST GROUP LLC",
          "RequestType": "Notice of Non-Payment (Suppliers Only)",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed and Notarized",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$2,365.90",
          "BranchNumber": "NEW ORLEANS",
          "SubmitDate": "04-01-2020",
          "ClientonJob": "AFFINITY CONST GROUP LLC"
        },
        {
          "WorkOrder": 4016197,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Rossford Senior Apar",
          "JobNumber": "502-1609034-43",
          "FirstdayontheJob": "03-31-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27480 Simmons Rd.",
          "JobAddress2": "",
          "JobFullAddress": "27480 Simmons Rd.",
          "JobCounty": "Wood",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$3,348.02",
          "BranchNumber": 13,
          "SubmitDate": "04-01-2020",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 4016196,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Sylvania ScienceReno",
          "JobNumber": "502-1305825-26",
          "FirstdayontheJob": "03-31-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7225 Sylvania Avenue",
          "JobAddress2": "",
          "JobFullAddress": "7225 Sylvania Avenue",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,844.67",
          "BranchNumber": 13,
          "SubmitDate": "04-01-2020",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 4016193,
          "ParentWorkOrder": "",
          "JobName": "(NOF)X Golf - Royal Oak",
          "JobNumber": "502-1621630-2",
          "FirstdayontheJob": "03-31-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30955 Woodward Ave.",
          "JobAddress2": "Ste 720",
          "JobFullAddress": "30955 Woodward Ave. Ste 720",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "RUGER CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,722.33",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2020",
          "ClientonJob": "RUGER CONSTRUCTION"
        },
        {
          "WorkOrder": 4016192,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Milan",
          "JobNumber": "502-943807-13",
          "FirstdayontheJob": "03-31-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5206B Monroe St",
          "JobAddress2": "Lockbox 1948",
          "JobFullAddress": "5206B Monroe St Lockbox 1948",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "JD Engle Construction",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,810.73",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2020",
          "ClientonJob": "JD Engle Construction"
        },
        {
          "WorkOrder": 4016191,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Regency Club Apts",
          "JobNumber": "502-8997117-21",
          "FirstdayontheJob": "03-31-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26188 Regency Club Cir.",
          "JobAddress2": "",
          "JobFullAddress": "26188 Regency Club Cir.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "FRONTGATE VENTURES LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$33,588.91",
          "BranchNumber": 8,
          "SubmitDate": "04-01-2020",
          "ClientonJob": "FRONTGATE VENTURES LLC"
        },
        {
          "WorkOrder": 4016190,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Olga's ComstockPark",
          "JobNumber": "502-1649046-2",
          "FirstdayontheJob": "03-31-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4076 Alpine Ave NW",
          "JobAddress2": "",
          "JobFullAddress": "4076 Alpine Ave NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Skyscraper Customs LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,815.35",
          "BranchNumber": 8,
          "SubmitDate": "04-01-2020",
          "ClientonJob": "Skyscraper Customs LLC"
        },
        {
          "WorkOrder": 4016189,
          "ParentWorkOrder": "",
          "JobName": "(NOF)272 Velocity Work Sp",
          "JobNumber": "502-816350-158",
          "FirstdayontheJob": "03-31-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6633 Eighteen Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "6633 Eighteen Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$18,006.00",
          "BalanceRemaining": "$905.10",
          "BranchNumber": 8,
          "SubmitDate": "04-01-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4016188,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ANDY'S APARTMENTS",
          "JobNumber": "502-1603009-6",
          "FirstdayontheJob": "03-31-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2388 W MICHIGAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "2388 W MICHIGAN AVE",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "G & M CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "04-01-2020",
          "ClientonJob": "G & M CONSTRUCTION"
        },
        {
          "WorkOrder": 4016187,
          "ParentWorkOrder": "",
          "JobName": "(NOF)676 COVE DRIVE",
          "JobNumber": "502-1650969-81",
          "FirstdayontheJob": "03-31-2020",
          "LastdayontheJob": "03-31-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "676 COVE DR",
          "JobAddress2": "",
          "JobFullAddress": "676 COVE DR",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,506.00",
          "BalanceRemaining": "$3,777.79",
          "BranchNumber": 1,
          "SubmitDate": "04-01-2020",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 4013968,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MASSAGE LUX",
          "JobNumber": "502-949575-121",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5500 ARBOR CHASE DR",
          "JobAddress2": "STE B",
          "JobFullAddress": "5500 ARBOR CHASE DR STE B",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$2,033.18",
          "BranchNumber": 9,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4013967,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GATEWAY PLAZA",
          "JobNumber": "502-941500-47",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8300 MERRIMAN RD",
          "JobAddress2": "",
          "JobFullAddress": "8300 MERRIMAN RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "FREEMAN INTERIOR",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$12,551.67",
          "BranchNumber": 9,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "FREEMAN INTERIOR"
        },
        {
          "WorkOrder": 4013966,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-312 JPMC LOCKBOX",
          "JobNumber": "502-937250-158",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 VILLAGE CENTER DR",
          "JobAddress2": "",
          "JobFullAddress": "1 VILLAGE CENTER DR",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINKER TEAM CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$65,006.00",
          "BalanceRemaining": "$5,631.35",
          "BranchNumber": 9,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "BRINKER TEAM CONSTRUCTION CO"
        },
        {
          "WorkOrder": 4013965,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LEO'S CONEY ISLAND",
          "JobNumber": "502-920300-96",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5151 ANTHONY WAYNE DR",
          "JobAddress2": "",
          "JobFullAddress": "5151 ANTHONY WAYNE DR",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$7,150.56",
          "BranchNumber": 9,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 4013964,
          "ParentWorkOrder": "",
          "JobName": "(NOF)AT&T Corktown",
          "JobNumber": "502-916315-162",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1620 Michigan Ave. Ste 126",
          "JobAddress2": "",
          "JobFullAddress": "1620 Michigan Ave. Ste 126",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,169.28",
          "BranchNumber": 9,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4013963,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Rair Tower Phase 2",
          "JobNumber": "502-916315-161",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3772 Wilder Rd.",
          "JobAddress2": "",
          "JobFullAddress": "3772 Wilder Rd.",
          "JobCounty": "Bay",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$2,744.44",
          "BranchNumber": 9,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4013962,
          "ParentWorkOrder": "",
          "JobName": "(NOF)McDonalds Hartland",
          "JobNumber": "502-916315-159",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10600 Highland Rd.",
          "JobAddress2": "",
          "JobFullAddress": "10600 Highland Rd.",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,205.34",
          "BranchNumber": 9,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4013961,
          "ParentWorkOrder": "",
          "JobName": "NOF)H.F CANCER CNT-SKYWAL",
          "JobNumber": "502-915260-5",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2799 W GRAND BLVD",
          "JobAddress2": "",
          "JobFullAddress": "2799 W GRAND BLVD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "3LK Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$9,481.31",
          "BranchNumber": 9,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "3LK Construction"
        },
        {
          "WorkOrder": 4013960,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Steven Jenkins",
          "JobNumber": "502-1604171-8",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13151 Torrey Rd",
          "JobAddress2": "",
          "JobFullAddress": "13151 Torrey Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,000,000.00",
          "BalanceRemaining": "$708.56",
          "BranchNumber": 8,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4013959,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Mark Reese",
          "JobNumber": "502-1604171-7",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11178 Tyrone tr",
          "JobAddress2": "",
          "JobFullAddress": "11178 Tyrone tr",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4013958,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MARL",
          "JobNumber": "502-920940-88",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "790 Featherstone St",
          "JobAddress2": "",
          "JobFullAddress": "790 Featherstone St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4013957,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Iafrate Office",
          "JobNumber": "502-843701-213",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26300 Sherwood Ave",
          "JobAddress2": "",
          "JobFullAddress": "26300 Sherwood Ave",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,596.57",
          "BranchNumber": 8,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 4013956,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20298 Middlebelt",
          "JobNumber": "502-815630-233",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20298 Middlebelt Rd",
          "JobAddress2": "",
          "JobFullAddress": "20298 Middlebelt Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4013955,
          "ParentWorkOrder": "",
          "JobName": "(NOF)200-0009SS Greektown",
          "JobNumber": "502-815250-365",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "555 E. Lafayette Blvd",
          "JobAddress2": "",
          "JobFullAddress": "555 E. Lafayette Blvd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$261.12",
          "BranchNumber": 8,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4013954,
          "ParentWorkOrder": "",
          "JobName": "(NOF)200 BLDG - JOB #190247-09",
          "JobNumber": "502-971850-33",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "200 N. Jackson St",
          "JobAddress2": "",
          "JobFullAddress": "200 N. Jackson St",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "R.W. MERCER",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,790.64",
          "BranchNumber": 7,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "R.W. MERCER"
        },
        {
          "WorkOrder": 4013952,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Cavanaugh Rd.",
          "JobNumber": "502-968000-4",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15680 Cavanaugh Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "15680 Cavanaugh Lake Rd",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "MILLER WALLBOARD",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "MILLER WALLBOARD"
        },
        {
          "WorkOrder": 4013951,
          "ParentWorkOrder": "",
          "JobName": "(NOF) M-R UPDIKE",
          "JobNumber": "502-967000-11",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12000 Stephenson Rd",
          "JobAddress2": "",
          "JobFullAddress": "12000 Stephenson Rd",
          "JobCounty": "LENAWEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRENNERS DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "BRENNERS DRYWALL"
        },
        {
          "WorkOrder": 4013950,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BLAINS PO:51051",
          "JobNumber": "502-50550-76",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "145 WILSON AVE NW",
          "JobAddress2": "",
          "JobFullAddress": "145 WILSON AVE NW",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "CENTRAL CEILING WEST",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "CENTRAL CEILING WEST"
        },
        {
          "WorkOrder": 4013949,
          "ParentWorkOrder": "",
          "JobName": "(NJP)CONSUMERSMAINOFFICE",
          "JobNumber": "502-13260-4",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 ENERGY PLAZA DR",
          "JobAddress2": "",
          "JobFullAddress": "1 ENERGY PLAZA DR",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "PUMFORD CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "PUMFORD CONSTRUCTION INC"
        },
        {
          "WorkOrder": 4013846,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Waterville Place",
          "JobNumber": "502-1084306-5",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1501 Pray Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1501 Pray Blvd",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "T&B CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$2,731.50",
          "BranchNumber": 13,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "T&B CONSTRUCTION"
        },
        {
          "WorkOrder": 4013845,
          "ParentWorkOrder": "",
          "JobName": "NOF ERKENBRECHER REMODEL",
          "JobNumber": "65-2134832-4",
          "FirstdayontheJob": "03-25-2020",
          "LastdayontheJob": "03-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "304 ERKENBRECHER AVE",
          "JobAddress2": "",
          "JobFullAddress": "304 ERKENBRECHER AVE",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "GENERAL CONTRACTING PARTNERS LTD",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$3,787.23",
          "BranchNumber": 12,
          "SubmitDate": "03-26-2020",
          "ClientonJob": "GENERAL CONTRACTING PARTNERS LTD"
        },
        {
          "WorkOrder": 4012885,
          "ParentWorkOrder": 4010202,
          "JobName": "(NOF)19-9073 PH2 Beech St",
          "JobNumber": "502-815250-364",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "One Energy Plaza",
          "JobAddress2": "",
          "JobFullAddress": "One Energy Plaza",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$235.57",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4012874,
          "ParentWorkOrder": 4004242,
          "JobName": "(NOF) Beaumont (Superior Fish)",
          "JobNumber": "502-815750-271",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "309 East 11 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "309 East 11 Mile Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,898.78",
          "BranchNumber": 8,
          "SubmitDate": "03-23-2020",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS INC"
        },
        {
          "WorkOrder": 4011645,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Crestview Local Scho",
          "JobNumber": "502-1304186-53",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "531 E Tully St",
          "JobAddress2": "",
          "JobFullAddress": "531 E Tully St",
          "JobCounty": "VAN WERT",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$1,741.49",
          "BranchNumber": 13,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4011644,
          "ParentWorkOrder": "",
          "JobName": "NOF 22 W ELDER STREET",
          "JobNumber": "65-2134789-8",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "MARKET SQUARE 3",
          "JobAddress2": "",
          "JobFullAddress": "MARKET SQUARE 3",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "COX DRYWALL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$22.15",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "COX DRYWALL"
        },
        {
          "WorkOrder": 4011636,
          "ParentWorkOrder": "",
          "JobName": "(NOF) City Flats-phase II",
          "JobNumber": "502-861787-40",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "802 Military St",
          "JobAddress2": "",
          "JobFullAddress": "802 Military St",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$115.33",
          "BranchNumber": 11,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "JFS Company"
        },
        {
          "WorkOrder": 4011635,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Free Star Financial",
          "JobNumber": "502-858231-51",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22522 East Nine Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "22522 East Nine Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$8,851.12",
          "BranchNumber": 11,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 4011634,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DIA Auto Collection",
          "JobNumber": "502-916315-160",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5200 Woodward Ave.",
          "JobAddress2": "",
          "JobFullAddress": "5200 Woodward Ave.",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,197.25",
          "BranchNumber": 9,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4011633,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dunkin Donuts BG OH",
          "JobNumber": "502-916315-158",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1073 South Main Street",
          "JobAddress2": "",
          "JobFullAddress": "1073 South Main Street",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$6,222.14",
          "BranchNumber": 9,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4011632,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Thornton & Grooms",
          "JobNumber": "502-916315-157",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24565 Hallwood Ct",
          "JobAddress2": "",
          "JobFullAddress": "24565 Hallwood Ct",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,651.32",
          "BranchNumber": 9,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4011631,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Culvers Lapeer",
          "JobNumber": "502-916315-154",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "840 West Street",
          "JobAddress2": "",
          "JobFullAddress": "840 West Street",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$249.70",
          "BranchNumber": 9,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4011630,
          "ParentWorkOrder": "",
          "JobName": "(NOF)THE BRISTOL",
          "JobNumber": "502-910850-8",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "420 E Frank St",
          "JobAddress2": "",
          "JobFullAddress": "420 E Frank St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "AMERICAN WALL SYSTEM",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$7,584.88",
          "BranchNumber": 9,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "AMERICAN WALL SYSTEM"
        },
        {
          "WorkOrder": 4011629,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Stoney Creek High S",
          "JobNumber": "502-920940-80",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6755 Sheldon Rd.",
          "JobAddress2": "",
          "JobFullAddress": "6755 Sheldon Rd.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$85,006.00",
          "BalanceRemaining": "$1,061.87",
          "BranchNumber": 8,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4011628,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Lakeshore Woods",
          "JobNumber": "502-877250-145",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4849 Lakeshore Rd",
          "JobAddress2": "",
          "JobFullAddress": "4849 Lakeshore Rd",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Cons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$2,762.13",
          "BranchNumber": 8,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "Blue Water Drywall & Cons"
        },
        {
          "WorkOrder": 4011627,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dr. Nakisher",
          "JobNumber": "502-823300-79",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8898 Commerce Rd.",
          "JobAddress2": "",
          "JobFullAddress": "8898 Commerce Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,378.33",
          "BranchNumber": 8,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 4011626,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DPW",
          "JobNumber": "502-817800-50",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1141 Wilcox St.",
          "JobAddress2": "",
          "JobFullAddress": "1141 Wilcox St.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,788.48",
          "BranchNumber": 8,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4011625,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 3097 GREAT LAKES WA",
          "JobNumber": "502-813200-138",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9300 W JEFFERSON AVE",
          "JobAddress2": "",
          "JobFullAddress": "9300 W JEFFERSON AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4011624,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Active Future Roches",
          "JobNumber": "502-812250-24",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "105 S. Main St",
          "JobAddress2": "",
          "JobFullAddress": "105 S. Main St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENNIS BAULT CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "DENNIS BAULT CONSTRUCTION"
        },
        {
          "WorkOrder": 4011623,
          "ParentWorkOrder": "",
          "JobName": "(NOF) McDonalds #2274",
          "JobNumber": "502-1645311-21",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "731 Trenton Ave",
          "JobAddress2": "",
          "JobFullAddress": "731 Trenton Ave",
          "JobCounty": "HANCOCK",
          "JobState": "Ohio",
          "CustomerCompanyName": "Crist Ceilings",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,498.82",
          "BranchNumber": 5,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "Crist Ceilings"
        },
        {
          "WorkOrder": 4011622,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WHITE HOUSE/BLACK MK",
          "JobNumber": "502-1060142-23",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3195 28TH ST SE",
          "JobAddress2": "",
          "JobFullAddress": "3195 28TH ST SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 4011621,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HELEN DEVOS CHILDRE",
          "JobNumber": "502-1060142-22",
          "FirstdayontheJob": "03-17-2020",
          "LastdayontheJob": "03-17-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 NE MICHIGAN ST",
          "JobAddress2": "",
          "JobFullAddress": "100 NE MICHIGAN ST",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 4011531,
          "ParentWorkOrder": 4004249,
          "JobName": "(NOF) THE GRADUATE HOTEL",
          "JobNumber": "502-933550-183",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "130 W GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "130 W GRAND RIVER AVE",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$120,006.00",
          "BalanceRemaining": "$1,890.00",
          "BranchNumber": 9,
          "SubmitDate": "03-18-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4011076,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Wyandot Dialysis",
          "JobNumber": "502-1304186-59",
          "FirstdayontheJob": "03-16-2020",
          "LastdayontheJob": "03-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1207 E. Wyandot Ave.",
          "JobAddress2": "",
          "JobFullAddress": "1207 E. Wyandot Ave.",
          "JobCounty": "WYANDOT",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,772.37",
          "BranchNumber": 13,
          "SubmitDate": "03-17-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4011075,
          "ParentWorkOrder": "",
          "JobName": "NOF ATHLETE INSTINCTS",
          "JobNumber": "65-919219-3",
          "FirstdayontheJob": "03-16-2020",
          "LastdayontheJob": "03-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "774 LOVELAND MIAMIVILLE RD",
          "JobAddress2": "",
          "JobFullAddress": "774 LOVELAND MIAMIVILLE RD",
          "JobCounty": "Clermont",
          "JobState": "Ohio",
          "CustomerCompanyName": "WILDCAT DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$7,213.11",
          "BranchNumber": 9,
          "SubmitDate": "03-17-2020",
          "ClientonJob": "WILDCAT DRYWALL LLC"
        },
        {
          "WorkOrder": 4011048,
          "ParentWorkOrder": 4007950,
          "JobName": "(NOF) Autozone Sandusky",
          "JobNumber": "502-1304186-57",
          "FirstdayontheJob": "03-03-2020",
          "LastdayontheJob": "03-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4637 Milan Road",
          "JobAddress2": "",
          "JobFullAddress": "4637 Milan Road",
          "JobCounty": "Erie",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,344.07",
          "BranchNumber": 13,
          "SubmitDate": "03-16-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4010534,
          "ParentWorkOrder": 4006058,
          "JobName": "(NOF)MCC SK Renovations",
          "JobNumber": "502-816350-153",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14500 East 12 Mile Rd.",
          "JobAddress2": "",
          "JobFullAddress": "14500 East 12 Mile Rd.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$20,913.18",
          "BranchNumber": 8,
          "SubmitDate": "03-13-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4010214,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LOC Federal Cr Union",
          "JobNumber": "502-858231-50",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3020 E Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "3020 E Grand River Ave",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 4010213,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Garfield-Clinton Twp",
          "JobNumber": "502-111914-11",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41601 Garfield Rd",
          "JobAddress2": "",
          "JobFullAddress": "41601 Garfield Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Custom Builder",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$703.17",
          "BranchNumber": 11,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "Great Lakes Custom Builder"
        },
        {
          "WorkOrder": 4010212,
          "ParentWorkOrder": "",
          "JobName": "(NOF) PLANET FITNESS CANT",
          "JobNumber": "502-1507798-43",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "43555 FORD RD",
          "JobAddress2": "",
          "JobFullAddress": "43555 FORD RD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4010211,
          "ParentWorkOrder": "",
          "JobName": "(NOF)5435 Corporate Drive",
          "JobNumber": "502-916425-5",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5435 Corporate Drive",
          "JobAddress2": "",
          "JobFullAddress": "5435 Corporate Drive",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "INTEGRATED ACOUSTICAL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "INTEGRATED ACOUSTICAL"
        },
        {
          "WorkOrder": 4010210,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Shenendoah Country C",
          "JobNumber": "502-916315-155",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5600 Walnut Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "5600 Walnut Lake Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,422.58",
          "BranchNumber": 9,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4010209,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1602 FABLETICS",
          "JobNumber": "502-913820-565",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27500 Novi Rd",
          "JobAddress2": "",
          "JobFullAddress": "27500 Novi Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$4,620.74",
          "BranchNumber": 9,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4010208,
          "ParentWorkOrder": "",
          "JobName": "(NOF)AVID HOTEL",
          "JobNumber": "502-910850-7",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8225 WESTPARK WAY",
          "JobAddress2": "",
          "JobFullAddress": "8225 WESTPARK WAY",
          "JobCounty": "Ottawa",
          "JobState": "Michigan",
          "CustomerCompanyName": "AMERICAN WALL SYSTEM",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "AMERICAN WALL SYSTEM"
        },
        {
          "WorkOrder": 4010207,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Flint Temp Library",
          "JobNumber": "502-920940-86",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4190 E. Court Street",
          "JobAddress2": "",
          "JobFullAddress": "4190 E. Court Street",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$1,528.51",
          "BranchNumber": 8,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4010206,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Fanuc Robotics",
          "JobNumber": "502-817800-49",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3900 W. Hamlin Rd.",
          "JobAddress2": "",
          "JobFullAddress": "3900 W. Hamlin Rd.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,124.74",
          "BranchNumber": 8,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4010205,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Madonna University",
          "JobNumber": "502-817800-48",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "36700 Schoolcraft Rd",
          "JobAddress2": "",
          "JobFullAddress": "36700 Schoolcraft Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$1,731.60",
          "BranchNumber": 8,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4010204,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(273)LakesCommChurch",
          "JobNumber": "502-816350-155",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1450 S. Hospital Rd.",
          "JobAddress2": "",
          "JobFullAddress": "1450 S. Hospital Rd.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$4,206.11",
          "BranchNumber": 8,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4010203,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Sigma",
          "JobNumber": "502-815630-232",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30200 Telegraph Rd.",
          "JobAddress2": "",
          "JobFullAddress": "30200 Telegraph Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4010202,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9073 PH2 Beech St",
          "JobNumber": "502-815250-364",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "One Energy Plaza",
          "JobAddress2": "",
          "JobFullAddress": "One Energy Plaza",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$235.57",
          "BranchNumber": 8,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4010201,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Choice Laboratories",
          "JobNumber": "502-968000-5",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4493 Phelps Rd",
          "JobAddress2": "",
          "JobFullAddress": "4493 Phelps Rd",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "MILLER WALLBOARD",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$942.42",
          "BranchNumber": 7,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "MILLER WALLBOARD"
        },
        {
          "WorkOrder": 4010200,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Mid West Conv./19DGE375",
          "JobNumber": "502-967075-123",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18125 E. 10 Mile Rd.",
          "JobAddress2": "",
          "JobFullAddress": "18125 E. 10 Mile Rd.",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 4010199,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ARGIL",
          "JobNumber": "502-708250-7",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10350 HARVEST PARK",
          "JobAddress2": "",
          "JobFullAddress": "10350 HARVEST PARK",
          "JobCounty": "EATON",
          "JobState": "Michigan",
          "CustomerCompanyName": "A4H Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,223.59",
          "BranchNumber": 7,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "A4H Construction LLC"
        },
        {
          "WorkOrder": 4010198,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Freedom Green LLC",
          "JobNumber": "502-1444189-5",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1689 Enterprise Dr",
          "JobAddress2": "",
          "JobFullAddress": "1689 Enterprise Dr",
          "JobCounty": "Kalkaska",
          "JobState": "Michigan",
          "CustomerCompanyName": "GREEN INDUSTRY CONST SVCS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$12,356.74",
          "BranchNumber": 5,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "GREEN INDUSTRY CONST SVCS"
        },
        {
          "WorkOrder": 4010197,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDS#12131",
          "JobNumber": "502-13380-133",
          "FirstdayontheJob": "03-11-2020",
          "LastdayontheJob": "03-11-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3430 MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "3430 MAIN ST",
          "JobCounty": "Sanilac",
          "JobState": "Michigan",
          "CustomerCompanyName": "RAYMOND RENOVATIONS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,506.00",
          "BalanceRemaining": "$2,302.56",
          "BranchNumber": 1,
          "SubmitDate": "03-12-2020",
          "ClientonJob": "RAYMOND RENOVATIONS"
        },
        {
          "WorkOrder": 4010079,
          "ParentWorkOrder": 3986864,
          "JobName": "(NOF)TCCC Job",
          "JobNumber": "502-94091-20",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1650 EAST TEN MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "1650 EAST TEN MILE RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "EJ Kaneris Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,778.76",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2020",
          "ClientonJob": "EJ Kaneris Inc"
        },
        {
          "WorkOrder": 4010077,
          "ParentWorkOrder": 4001570,
          "JobName": "(NOF)(264)OCC AH Bld-C",
          "JobNumber": "502-816350-145",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2900 Featherstone Rd",
          "JobAddress2": "",
          "JobFullAddress": "2900 Featherstone Rd",
          "JobCounty": "Oakalnd",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$300,006.00",
          "BalanceRemaining": "$167.95",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4010073,
          "ParentWorkOrder": 4001568,
          "JobName": "(NOF)19-9032 SJMO MCI Pha",
          "JobNumber": "502-815250-359",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44405 Woodward Ave.",
          "JobAddress2": "Phase 2",
          "JobFullAddress": "44405 Woodward Ave. Phase 2",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4010045,
          "ParentWorkOrder": 4002297,
          "JobName": "(NOF)Tru By Hilton Novi",
          "JobNumber": "502-990401-56",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "40255 W. 13 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "40255 W. 13 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "03-11-2020",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4009675,
          "ParentWorkOrder": 4002299,
          "JobName": "(NOF)McLaren Hospital/ACP/Reichenbach J.V.",
          "JobNumber": "502-810150-52",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3101 Technology",
          "JobAddress2": "ACP/Reichenbach J.V.",
          "JobFullAddress": "3101 Technology ACP/Reichenbach J.V.",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTIC CEIL & PART",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-10-2020",
          "ClientonJob": "ACOUSTIC CEIL & PART"
        },
        {
          "WorkOrder": 4009094,
          "ParentWorkOrder": 4001037,
          "JobName": "LAFOURCHE PARISH LIBRARY",
          "JobNumber": "114-1331440-39",
          "FirstdayontheJob": "12-19-2019",
          "LastdayontheJob": "12-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "705 W 5TH STREET",
          "JobAddress2": "",
          "JobFullAddress": "705 W 5TH STREET",
          "JobCounty": "LAFOURCHE",
          "JobState": "Louisiana",
          "CustomerCompanyName": "TIKE INTERIORS LLC",
          "RequestType": "Notice of Non-Payment (Suppliers Only)",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed and Notarized",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$3,603.60",
          "BranchNumber": 14,
          "SubmitDate": "03-09-2020",
          "ClientonJob": "TIKE INTERIORS LLC"
        },
        {
          "WorkOrder": 4008620,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Camp Cavell",
          "JobNumber": "502-1611539-2",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3335 Lakeshore Rd",
          "JobAddress2": "",
          "JobFullAddress": "3335 Lakeshore Rd",
          "JobCounty": "SANILAC",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLUM TREE INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,206.00",
          "BalanceRemaining": "$4,932.73",
          "BranchNumber": 11,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "PLUM TREE INTERIORS LLC"
        },
        {
          "WorkOrder": 4008619,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Bella Homes",
          "JobNumber": "502-849801-235",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "610 Lake Angelus Shores",
          "JobAddress2": "",
          "JobFullAddress": "610 Lake Angelus Shores",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$8,269.61",
          "BranchNumber": 11,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 4008618,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TEXAS ROADHOUSE",
          "JobNumber": "502-821181-5",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26730 ADELL CENTER DR",
          "JobAddress2": "",
          "JobFullAddress": "26730 ADELL CENTER DR",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Monnin Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,506.00",
          "BalanceRemaining": "$2,494.14",
          "BranchNumber": 11,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "Monnin Construction LLC"
        },
        {
          "WorkOrder": 4008617,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Edge Fitness Shelby",
          "JobNumber": "502-934634-12",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14495 Hall Rd.",
          "JobAddress2": "",
          "JobFullAddress": "14495 Hall Rd.",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONST. GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,110.30",
          "BranchNumber": 9,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "BLUESTONE CONST. GROUP"
        },
        {
          "WorkOrder": 4008616,
          "ParentWorkOrder": "",
          "JobName": "(nof)mcdonalds fenton",
          "JobNumber": "502-916315-156",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3216 Owen Rd",
          "JobAddress2": "",
          "JobFullAddress": "3216 Owen Rd",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4008615,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1598 PHARMACY GLCC",
          "JobNumber": "502-913820-560",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17900 23 MILE ROAD",
          "JobAddress2": "",
          "JobFullAddress": "17900 23 MILE ROAD",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$99.26",
          "BranchNumber": 9,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4008614,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 2617 Royal",
          "JobNumber": "502-1647906-11",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2617 Royal Ave",
          "JobAddress2": "",
          "JobFullAddress": "2617 Royal Ave",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "V & S QUALITY DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,527.65",
          "BranchNumber": 8,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "V & S QUALITY DRYWALL INC"
        },
        {
          "WorkOrder": 4008613,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1405 Pebblecreek",
          "JobNumber": "502-1639425-5",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1405 Pebblecreek Dr",
          "JobAddress2": "",
          "JobFullAddress": "1405 Pebblecreek Dr",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$112.57",
          "BranchNumber": 8,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "PREMIER INTERIORS LLC"
        },
        {
          "WorkOrder": 4008612,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-2005 DTE WCB 12th",
          "JobNumber": "502-815250-363",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Plaza",
          "JobAddress2": "WCB 12th Floor",
          "JobFullAddress": "1 Energy Plaza WCB 12th Floor",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$5,685.19",
          "BranchNumber": 8,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4008611,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-2006 CrittentonCL",
          "JobNumber": "502-815250-360",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1101 W University Dr",
          "JobAddress2": "",
          "JobFullAddress": "1101 W University Dr",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,267.59",
          "BranchNumber": 8,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4008610,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Ford Field 190682, Dynatrace",
          "JobNumber": "502-812350-90",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2000 Brush Street Ste 441",
          "JobAddress2": "",
          "JobFullAddress": "2000 Brush Street Ste 441",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4008609,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Thai Summit",
          "JobNumber": "502-811817-11",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1480 McPherson Park Dr",
          "JobAddress2": "",
          "JobFullAddress": "1480 McPherson Park Dr",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,245.75",
          "BranchNumber": 8,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 4008608,
          "ParentWorkOrder": "",
          "JobName": "(NOF) RANSOM LIBRARY",
          "JobNumber": "502-1344933-15",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "180 S SHERWOOD AVE",
          "JobAddress2": "",
          "JobFullAddress": "180 S SHERWOOD AVE",
          "JobCounty": "ALLEGAN",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER 1 INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$20,895.96",
          "BranchNumber": 5,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "PREMIER 1 INTERIORS LLC"
        },
        {
          "WorkOrder": 4008607,
          "ParentWorkOrder": "",
          "JobName": "(NOF)JJ CONCEPTS",
          "JobNumber": "502-54471-220",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4850 QUINCY ST",
          "JobAddress2": "",
          "JobFullAddress": "4850 QUINCY ST",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Michael Topping Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,000.00",
          "BalanceRemaining": "$5,567.62",
          "BranchNumber": 5,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "Michael Topping Inc."
        },
        {
          "WorkOrder": 4008606,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ENCOMPASS THERAPY",
          "JobNumber": "502-12660-3",
          "FirstdayontheJob": "03-04-2020",
          "LastdayontheJob": "03-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3710 KATALIN CT",
          "JobAddress2": "",
          "JobFullAddress": "3710 KATALIN CT",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "McINTOSH CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,500.05",
          "BranchNumber": 1,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "McINTOSH CONSTRUCTION"
        },
        {
          "WorkOrder": 4008462,
          "ParentWorkOrder": 3993707,
          "JobName": "(NOF)44th ST",
          "JobNumber": "502-1601354-2",
          "FirstdayontheJob": "01-13-2020",
          "LastdayontheJob": "01-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2301 44TH ST SE",
          "JobAddress2": "",
          "JobFullAddress": "2301 44TH ST SE",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONST & CONSULTING LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,815.03",
          "BranchNumber": 5,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "KEYSTONE CONST & CONSULTING LLC"
        },
        {
          "WorkOrder": 4008308,
          "ParentWorkOrder": 3991066,
          "JobName": "(NOF)La-Z-Boy Furniture",
          "JobNumber": "502-877300-32",
          "FirstdayontheJob": "01-02-2020",
          "LastdayontheJob": "01-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4829 S. Baldwin Rd",
          "JobAddress2": "",
          "JobFullAddress": "4829 S. Baldwin Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR DESIGNS INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,942.88",
          "BranchNumber": 8,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "SUPERIOR DESIGNS INC."
        },
        {
          "WorkOrder": 4008299,
          "ParentWorkOrder": 3994816,
          "JobName": "(NOF) 2900 DIVISION",
          "JobNumber": "502-1601354-3",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2900 DIVISION AVE SOUTH",
          "JobAddress2": "",
          "JobFullAddress": "2900 DIVISION AVE SOUTH",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONST & CONSULTING LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,071.00",
          "BranchNumber": 5,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "KEYSTONE CONST & CONSULTING LLC"
        },
        {
          "WorkOrder": 4008298,
          "ParentWorkOrder": 3994817,
          "JobName": "(NOF)PEREGRINE DISPENCERY",
          "JobNumber": "502-1601354-5",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3075 NE PEREGRINE DR",
          "JobAddress2": "",
          "JobFullAddress": "3075 NE PEREGRINE DR",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONST & CONSULTING LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$120,006.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "KEYSTONE CONST & CONSULTING LLC"
        },
        {
          "WorkOrder": 4008294,
          "ParentWorkOrder": 3944013,
          "JobName": "(NOF)N Oakland Plastic Su",
          "JobNumber": "502-817800-41",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "303 E Third Street",
          "JobAddress2": "Suite 220",
          "JobFullAddress": "303 E Third Street Suite 220",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,503.90",
          "BranchNumber": 8,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4008287,
          "ParentWorkOrder": 4001683,
          "JobName": "(NOF)DOUBLETREE",
          "JobNumber": "502-1507798-40",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3600 PLYMOUTH RD",
          "JobAddress2": "",
          "JobFullAddress": "3600 PLYMOUTH RD",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4008286,
          "ParentWorkOrder": 3986865,
          "JobName": "(NOF)HOLT MSU FCU",
          "JobNumber": "502-810150-50",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2313 Cedar St.",
          "JobAddress2": "",
          "JobFullAddress": "2313 Cedar St.",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTIC CEIL & PART",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$20,051.27",
          "BranchNumber": 8,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "ACOUSTIC CEIL & PART"
        },
        {
          "WorkOrder": 4008284,
          "ParentWorkOrder": 3994815,
          "JobName": "(NOF)GOOD SAMARITAN RESC",
          "JobNumber": "502-12150-13",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "713 9TH ST",
          "JobAddress2": "",
          "JobFullAddress": "713 9TH ST",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "SERENUS JOHNSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,091.79",
          "BranchNumber": 1,
          "SubmitDate": "03-05-2020",
          "ClientonJob": "SERENUS JOHNSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4007950,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Autozone Sandusky",
          "JobNumber": "502-1304186-57",
          "FirstdayontheJob": "03-03-2020",
          "LastdayontheJob": "03-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4637 Milan Road",
          "JobAddress2": "",
          "JobFullAddress": "4637 Milan Road",
          "JobCounty": "Erie",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,344.07",
          "BranchNumber": 13,
          "SubmitDate": "03-04-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4007949,
          "ParentWorkOrder": "",
          "JobName": "NOF UC CRC MULTI ROOM",
          "JobNumber": "65-2134669-5",
          "FirstdayontheJob": "03-03-2020",
          "LastdayontheJob": "03-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2840 BEARCAT WAY",
          "JobAddress2": "",
          "JobFullAddress": "2840 BEARCAT WAY",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "MCCOOL PLASTER AND DRYWAL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,387.94",
          "BranchNumber": 12,
          "SubmitDate": "03-04-2020",
          "ClientonJob": "MCCOOL PLASTER AND DRYWAL"
        },
        {
          "WorkOrder": 4007703,
          "ParentWorkOrder": 4002305,
          "JobName": "(NOF)US FARATHANE",
          "JobNumber": "502-913400-28",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "39200 FORD RD",
          "JobAddress2": "",
          "JobFullAddress": "39200 FORD RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DANCO CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$5,994.63",
          "BranchNumber": 9,
          "SubmitDate": "03-04-2020",
          "ClientonJob": "DANCO CONTRACTING, INC."
        },
        {
          "WorkOrder": 4007445,
          "ParentWorkOrder": 4002309,
          "JobName": "(NOF) Panera Bread",
          "JobNumber": "502-944100-333",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "37900 W 12 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "37900 W 12 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-03-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4007432,
          "ParentWorkOrder": 4001587,
          "JobName": "(NOF)NORTHWESTERN TECH",
          "JobNumber": "502-1507798-39",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24800 NORTHWESTERN HWY",
          "JobAddress2": "",
          "JobFullAddress": "24800 NORTHWESTERN HWY",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$42,006.00",
          "BalanceRemaining": "$4,505.18",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4007396,
          "ParentWorkOrder": 4002306,
          "JobName": "(NOF)1604 GUERNSEY",
          "JobNumber": "502-913820-567",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21300 NOVI ROAD",
          "JobAddress2": "",
          "JobFullAddress": "21300 NOVI ROAD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$1,838.94",
          "BranchNumber": 9,
          "SubmitDate": "03-03-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4007368,
          "ParentWorkOrder": 4001585,
          "JobName": "(NOF)ASSURANCE HEALTH",
          "JobNumber": "502-1507798-35",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4040 KING RD",
          "JobAddress2": "",
          "JobFullAddress": "4040 KING RD",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,221.69",
          "BranchNumber": 9,
          "SubmitDate": "03-03-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4007298,
          "ParentWorkOrder": 3983365,
          "JobName": "(NOF)DEARBORN AAA",
          "JobNumber": "502-920000-28",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15500 LUNDY PARKWAY",
          "JobAddress2": "",
          "JobFullAddress": "15500 LUNDY PARKWAY",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NVR FINISHES, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$6,048.43",
          "BranchNumber": 9,
          "SubmitDate": "03-03-2020",
          "ClientonJob": "NVR FINISHES, INC."
        },
        {
          "WorkOrder": 4007276,
          "ParentWorkOrder": 3984431,
          "JobName": "(NOF)Chipotle Taylor Sout",
          "JobNumber": "502-877300-30",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23013 Eureka Rd",
          "JobAddress2": "",
          "JobFullAddress": "23013 Eureka Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR DESIGNS INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,708.82",
          "BranchNumber": 8,
          "SubmitDate": "03-03-2020",
          "ClientonJob": "SUPERIOR DESIGNS INC."
        },
        {
          "WorkOrder": 4007230,
          "ParentWorkOrder": 3980431,
          "JobName": "(NOF)DTMB VetHome Interior - Project # 218470",
          "JobNumber": "502-823200-78",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Donner Rd/Douglas Dr / 48512 HAWK DR",
          "JobAddress2": "",
          "JobFullAddress": "Donner Rd/Douglas Dr / 48512 HAWK DR",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PONTIAC CEILING & PART",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$250,006.00",
          "BalanceRemaining": "$16,590.59",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "PONTIAC CEILING & PART"
        },
        {
          "WorkOrder": 4007195,
          "ParentWorkOrder": 3986870,
          "JobName": "(NOF) SME LAB",
          "JobNumber": "502-941950-10",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "43980 Plymouth Oaks Blvd",
          "JobAddress2": "",
          "JobFullAddress": "43980 Plymouth Oaks Blvd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "GREAT LAKES CEILING",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,483.23",
          "BranchNumber": 9,
          "SubmitDate": "03-03-2020",
          "ClientonJob": "GREAT LAKES CEILING"
        },
        {
          "WorkOrder": 4007130,
          "ParentWorkOrder": 3991067,
          "JobName": "(NOF) Detroit Taco",
          "JobNumber": "502-1605101-8",
          "FirstdayontheJob": "01-02-2020",
          "LastdayontheJob": "01-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50767 Corporate Drive",
          "JobAddress2": "",
          "JobFullAddress": "50767 Corporate Drive",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "ARCHITECTURAL PLANNERS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-03-2020",
          "ClientonJob": "ARCHITECTURAL PLANNERS"
        },
        {
          "WorkOrder": 4007092,
          "ParentWorkOrder": 3990937,
          "JobName": "(NOF) Autozone",
          "JobNumber": "502-1304186-54",
          "FirstdayontheJob": "01-01-2020",
          "LastdayontheJob": "01-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1846 E. Perry St.",
          "JobAddress2": "",
          "JobFullAddress": "1846 E. Perry St.",
          "JobCounty": "OTTAWA",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$4,990.08",
          "BranchNumber": 13,
          "SubmitDate": "03-03-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4007087,
          "ParentWorkOrder": 3992283,
          "JobName": "(NOF)Imagination Station",
          "JobNumber": "502-1308300-4",
          "FirstdayontheJob": "01-07-2020",
          "LastdayontheJob": "01-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Discovery Way",
          "JobAddress2": "",
          "JobFullAddress": "1 Discovery Way",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "THE SPIEKER COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "03-03-2020",
          "ClientonJob": "THE SPIEKER COMPANY"
        },
        {
          "WorkOrder": 4007074,
          "ParentWorkOrder": 3990934,
          "JobName": "(NOF) O. U. CREDIT",
          "JobNumber": "65-484402-7",
          "FirstdayontheJob": "01-01-2020",
          "LastdayontheJob": "01-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "90 S Shafer St",
          "JobAddress2": "",
          "JobFullAddress": "90 S Shafer St",
          "JobCounty": "ATHENS",
          "JobState": "Ohio",
          "CustomerCompanyName": "LCS LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 4,
          "SubmitDate": "03-03-2020",
          "ClientonJob": "LCS LLC"
        },
        {
          "WorkOrder": 4006066,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Uptown Square Apts",
          "JobNumber": "502-849801-232",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1300 Livernois Rd",
          "JobAddress2": "",
          "JobFullAddress": "1300 Livernois Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$7,764.79",
          "BranchNumber": 11,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 4006065,
          "ParentWorkOrder": "",
          "JobName": "(NOF)All Season Ann Arbor",
          "JobNumber": "502-847401-147",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4540 Geddes Rd",
          "JobAddress2": "",
          "JobFullAddress": "4540 Geddes Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,788.52",
          "BranchNumber": 11,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4006064,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DSV",
          "JobNumber": "502-949575-118",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27651 HILDEBRANDT ST",
          "JobAddress2": "STE 100",
          "JobFullAddress": "27651 HILDEBRANDT ST STE 100",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$15,398.99",
          "BranchNumber": 9,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4006063,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MacAllisterCatWarehs",
          "JobNumber": "502-919910-4",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4005 West Fort Street",
          "JobAddress2": "",
          "JobFullAddress": "4005 West Fort Street",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Nora Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$4,085.29",
          "BranchNumber": 9,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "Nora Contracting"
        },
        {
          "WorkOrder": 4006062,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Stonegate Village",
          "JobNumber": "502-147906-2",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1846 Coral Ct.",
          "JobAddress2": "",
          "JobFullAddress": "1846 Coral Ct.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARINE CITY DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$4,418.27",
          "BranchNumber": 9,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "MARINE CITY DRYWALL LLC"
        },
        {
          "WorkOrder": 4006061,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Roberts Elementary",
          "JobNumber": "502-920940-83",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2400 Belle View Dr.",
          "JobAddress2": "",
          "JobFullAddress": "2400 Belle View Dr.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$582.88",
          "BranchNumber": 8,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4006060,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Havel Elementary",
          "JobNumber": "502-920940-82",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41855 Schoenherr Rd",
          "JobAddress2": "",
          "JobFullAddress": "41855 Schoenherr Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4006059,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Graebner Elementary",
          "JobNumber": "502-920940-81",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41875 Saal Rd.",
          "JobAddress2": "",
          "JobFullAddress": "41875 Saal Rd.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$591.78",
          "BranchNumber": 8,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4006058,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCC SK Renovations",
          "JobNumber": "502-816350-153",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14500 East 12 Mile Rd.",
          "JobAddress2": "",
          "JobFullAddress": "14500 East 12 Mile Rd.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$20,913.18",
          "BranchNumber": 8,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4006057,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Oakland Southgate",
          "JobNumber": "502-815630-231",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13305 Reeck Road",
          "JobAddress2": "",
          "JobFullAddress": "13305 Reeck Road",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,608.89",
          "BranchNumber": 8,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4006056,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20-2007 SJP Warren",
          "JobNumber": "502-815250-362",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11800 E 12 Mile Rd.",
          "JobAddress2": "",
          "JobFullAddress": "11800 E 12 Mile Rd.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$390.62",
          "BranchNumber": 8,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4006054,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DTE Office Build",
          "JobNumber": "502-812350-88",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5560 Rivard St",
          "JobAddress2": "",
          "JobFullAddress": "5560 Rivard St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$596.72",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4006053,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Royal Oak Medical",
          "JobNumber": "502-94091-23",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5140 Coolidge Hwy",
          "JobAddress2": "",
          "JobFullAddress": "5140 Coolidge Hwy",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "EJ Kaneris Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$11,760.60",
          "BranchNumber": 8,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "EJ Kaneris Inc"
        },
        {
          "WorkOrder": 4006051,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Spencer Gifts",
          "JobNumber": "502-1680400-5",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4314 Milan Rd",
          "JobAddress2": "",
          "JobFullAddress": "4314 Milan Rd",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "Nova Construction Millwork",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$4,867.14",
          "BranchNumber": 5,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "Nova Construction Millwork"
        },
        {
          "WorkOrder": 4006050,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ROBERT HALF 9TH",
          "JobNumber": "502-1601354-9",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "355 BRIDGE ST NW",
          "JobAddress2": "",
          "JobFullAddress": "355 BRIDGE ST NW",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONST & CONSULTING LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "KEYSTONE CONST & CONSULTING LLC"
        },
        {
          "WorkOrder": 4006049,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DEMISING WALL- 9TH",
          "JobNumber": "502-1601354-8",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "335 BRIDGE ST NW",
          "JobAddress2": "",
          "JobFullAddress": "335 BRIDGE ST NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONST & CONSULTING LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "KEYSTONE CONST & CONSULTING LLC"
        },
        {
          "WorkOrder": 4006048,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TIM HORTONS 19030",
          "JobNumber": "502-1602000-30",
          "FirstdayontheJob": "02-26-2020",
          "LastdayontheJob": "02-26-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2003 S SAGINAW RD",
          "JobAddress2": "",
          "JobFullAddress": "2003 S SAGINAW RD",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Constr",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$285.14",
          "BranchNumber": 1,
          "SubmitDate": "02-27-2020",
          "ClientonJob": "Great Lakes Bay Constr"
        },
        {
          "WorkOrder": 4005646,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Owens Dana Phase2",
          "JobNumber": "502-1305825-25",
          "FirstdayontheJob": "02-25-2020",
          "LastdayontheJob": "02-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "310 3rd Street",
          "JobAddress2": "JOB#19-224",
          "JobFullAddress": "310 3rd Street JOB#19-224",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$24,460.13",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 4005645,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Pet Supplies Plus",
          "JobNumber": "502-1304186-58",
          "FirstdayontheJob": "02-25-2020",
          "LastdayontheJob": "02-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2180 Sean Drive Unit 6A",
          "JobAddress2": "",
          "JobFullAddress": "2180 Sean Drive Unit 6A",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,848.43",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4005644,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Sephora",
          "JobNumber": "502-1304186-56",
          "FirstdayontheJob": "02-25-2020",
          "LastdayontheJob": "02-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2110 Levis Commons Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2110 Levis Commons Blvd",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,911.85",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4005643,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Bridgestone",
          "JobNumber": "502-1301172-49",
          "FirstdayontheJob": "02-25-2020",
          "LastdayontheJob": "02-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "245 Commerce Way",
          "JobAddress2": "",
          "JobFullAddress": "245 Commerce Way",
          "JobCounty": "Wyandot",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,810.74",
          "BranchNumber": 13,
          "SubmitDate": "02-26-2020",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 4005641,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Caseys General Store",
          "JobNumber": "502-1163909-5",
          "FirstdayontheJob": "02-25-2020",
          "LastdayontheJob": "02-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "301 W. Wyndot Ave",
          "JobAddress2": "Job# 4049",
          "JobFullAddress": "301 W. Wyndot Ave Job# 4049",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "DREAMKEEPER PROPERTIES",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "DREAMKEEPER PROPERTIES"
        },
        {
          "WorkOrder": 4005640,
          "ParentWorkOrder": "",
          "JobName": "(NOF)St Clair Apt PhaseII",
          "JobNumber": "502-1084306-4",
          "FirstdayontheJob": "02-25-2020",
          "LastdayontheJob": "02-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Clinton Ave",
          "JobAddress2": "c/o 1970 River Rd",
          "JobFullAddress": "Clinton Ave c/o 1970 River Rd",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "T&B CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,953.64",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "T&B CONSTRUCTION"
        },
        {
          "WorkOrder": 4005639,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Adidas Great Lakes",
          "JobNumber": "502-134220-5",
          "FirstdayontheJob": "02-25-2020",
          "LastdayontheJob": "02-25-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4698 Baldwin Rd.",
          "JobAddress2": "Suite A-903.",
          "JobFullAddress": "4698 Baldwin Rd. Suite A-903.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "HORIZON RETAIL CONSTRUCT",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$11,767.83",
          "BranchNumber": 13,
          "SubmitDate": "02-26-2020",
          "ClientonJob": "HORIZON RETAIL CONSTRUCT"
        },
        {
          "WorkOrder": 4004254,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Crazy Gringo",
          "JobNumber": "502-849801-230",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22222 Harper Ave",
          "JobAddress2": "",
          "JobFullAddress": "22222 Harper Ave",
          "JobCounty": "St Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "VASILOS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,806.00",
          "BalanceRemaining": "$1,687.93",
          "BranchNumber": 11,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "VASILOS INC"
        },
        {
          "WorkOrder": 4004253,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LAURELS OF LAKE ORION",
          "JobNumber": "502-1677740-33",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3451 W. CLARKSTON RD",
          "JobAddress2": "",
          "JobFullAddress": "3451 W. CLARKSTON RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,943.94",
          "BranchNumber": 9,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 4004252,
          "ParentWorkOrder": "",
          "JobName": "(NOF) JOGUE RESTROOMS",
          "JobNumber": "502-1507798-42",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "101 RURAL HILL ST",
          "JobAddress2": "",
          "JobFullAddress": "101 RURAL HILL ST",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$249.45",
          "BranchNumber": 9,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4004251,
          "ParentWorkOrder": "",
          "JobName": "(NOF) AUTOPETS",
          "JobNumber": "502-1507798-41",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1080 W. ENTRANCE DR",
          "JobAddress2": "",
          "JobFullAddress": "1080 W. ENTRANCE DR",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,048.82",
          "BranchNumber": 9,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4004250,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FIRESTATION 1 -IMLAY",
          "JobNumber": "502-949575-119",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "571 E. BORLAND RD",
          "JobAddress2": "",
          "JobFullAddress": "571 E. BORLAND RD",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$1,786.34",
          "BranchNumber": 9,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 4004249,
          "ParentWorkOrder": "",
          "JobName": "(NOF) THE GRADUATE HOTEL",
          "JobNumber": "502-933550-183",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "130 W GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "130 W GRAND RIVER AVE",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$120,006.00",
          "BalanceRemaining": "$1,890.00",
          "BranchNumber": 9,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4004248,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MUC MUSLIM UNITY CTR",
          "JobNumber": "502-911550-11",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1830 W. SQUARE LAKE RD",
          "JobAddress2": "",
          "JobFullAddress": "1830 W. SQUARE LAKE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BEST FINISHERS INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,324.80",
          "BranchNumber": 9,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "BEST FINISHERS INC."
        },
        {
          "WorkOrder": 4004247,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Shelby Pointe Plaza",
          "JobNumber": "502-1604171-6",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "56290 VAN DYKE AVE",
          "JobAddress2": "",
          "JobFullAddress": "56290 VAN DYKE AVE",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 4004246,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hoover School",
          "JobNumber": "502-920940-51",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3750 Howard Street",
          "JobAddress2": "",
          "JobFullAddress": "3750 Howard Street",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$8,113.66",
          "BranchNumber": 8,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 4004245,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Torch Development",
          "JobNumber": "502-839201-14",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "751 Berkshire Rd",
          "JobAddress2": "",
          "JobFullAddress": "751 Berkshire Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLINTON INTERIORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,144.22",
          "BranchNumber": 8,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "CLINTON INTERIORS INC"
        },
        {
          "WorkOrder": 4004244,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Tappers",
          "JobNumber": "502-825063-54",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6337 Orchard Lake Rd.",
          "JobAddress2": "",
          "JobFullAddress": "6337 Orchard Lake Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHAFFER INCORPORATED",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "SHAFFER INCORPORATED"
        },
        {
          "WorkOrder": 4004243,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Neuro Nexus",
          "JobNumber": "502-817800-47",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5200 S State Rd",
          "JobAddress2": "Suite 200",
          "JobFullAddress": "5200 S State Rd Suite 200",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,039.67",
          "BranchNumber": 8,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4004242,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Beaumont (Superior Fish)",
          "JobNumber": "502-815750-271",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "309 East 11 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "309 East 11 Mile Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,898.78",
          "BranchNumber": 8,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS INC"
        },
        {
          "WorkOrder": 4004241,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Barclay Circle MRI",
          "JobNumber": "502-813200-136",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "355 Barclay Cir",
          "JobAddress2": "",
          "JobFullAddress": "355 Barclay Cir",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$230.25",
          "BranchNumber": 8,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 4004240,
          "ParentWorkOrder": "",
          "JobName": "(NOF) H.I.E. BAY CITY",
          "JobNumber": "502-812692-20",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3959 Traxler Ct",
          "JobAddress2": "",
          "JobFullAddress": "3959 Traxler Ct",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "Starbest Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$2,140.70",
          "BranchNumber": 8,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "Starbest Construction"
        },
        {
          "WorkOrder": 4004239,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WCCJC",
          "JobNumber": "502-812350-89",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1201 E Warren Ave",
          "JobAddress2": "",
          "JobFullAddress": "1201 E Warren Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$1,384.42",
          "BranchNumber": 8,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 4004238,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Lifeways Bldg Remodl",
          "JobNumber": "502-948950-53",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1200 N. West Ave.",
          "JobAddress2": "",
          "JobFullAddress": "1200 N. West Ave.",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHIVELY-BOUMA, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$2,798.13",
          "BranchNumber": 7,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "SHIVELY-BOUMA, INC."
        },
        {
          "WorkOrder": 4004237,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Lansing Comm College",
          "JobNumber": "502-30900-68",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "400 N Capitol Ave",
          "JobAddress2": "",
          "JobFullAddress": "400 N Capitol Ave",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "Integrity Interiors Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$12,760.70",
          "BranchNumber": 7,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "Integrity Interiors Inc."
        },
        {
          "WorkOrder": 4004236,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HOLIDAY INN",
          "JobNumber": "502-1634746-2",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "310 PEARL ST NW",
          "JobAddress2": "",
          "JobFullAddress": "310 PEARL ST NW",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "GC TIMBERLINE LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "GC TIMBERLINE LLC"
        },
        {
          "WorkOrder": 4004235,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HIGHLAND DAIRY",
          "JobNumber": "502-30978-12",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2031 STOUTENBURG ST",
          "JobAddress2": "",
          "JobFullAddress": "2031 STOUTENBURG ST",
          "JobCounty": "Sanilac",
          "JobState": "Michigan",
          "CustomerCompanyName": "Kolar Brothers Constr.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$25,466.50",
          "BranchNumber": 1,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "Kolar Brothers Constr."
        },
        {
          "WorkOrder": 4004234,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LAKESHORECHRYSLER",
          "JobNumber": "502-30930-8",
          "FirstdayontheJob": "02-19-2020",
          "LastdayontheJob": "02-19-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4235 FRUITVALE RD",
          "JobAddress2": "",
          "JobFullAddress": "4235 FRUITVALE RD",
          "JobCounty": "Muskegon",
          "JobState": "Michigan",
          "CustomerCompanyName": "JBS CONTRACTING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$5,492.24",
          "BranchNumber": 1,
          "SubmitDate": "02-20-2020",
          "ClientonJob": "JBS CONTRACTING, INC"
        },
        {
          "WorkOrder": 4003550,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Owens Dana Phase2",
          "JobNumber": "502-1305825-25/JOB 19-224",
          "FirstdayontheJob": "02-18-2020",
          "LastdayontheJob": "02-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "310 3rd Street",
          "JobAddress2": "",
          "JobFullAddress": "310 3rd Street",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$24,166.30",
          "BranchNumber": 13,
          "SubmitDate": "02-19-2020",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 4003549,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Pet Supplies Plus",
          "JobNumber": "502-1304186-58",
          "FirstdayontheJob": "02-18-2020",
          "LastdayontheJob": "02-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2180 Sean Drive Unit 6A",
          "JobAddress2": "",
          "JobFullAddress": "2180 Sean Drive Unit 6A",
          "JobCounty": "Sandusky",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,611.94",
          "BranchNumber": 13,
          "SubmitDate": "02-19-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4003548,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Sephora",
          "JobNumber": "502-1304186-56",
          "FirstdayontheJob": "02-18-2020",
          "LastdayontheJob": "02-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2110 Levis Commons Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2110 Levis Commons Blvd",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,771.08",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4003547,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Bridgestone",
          "JobNumber": "502-1301172-49",
          "FirstdayontheJob": "02-18-2020",
          "LastdayontheJob": "02-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "245 Commerce Way",
          "JobAddress2": "",
          "JobFullAddress": "245 Commerce Way",
          "JobCounty": "WYANDOT",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,810.74",
          "BranchNumber": 13,
          "SubmitDate": "02-19-2020",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 4003546,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Caseys General Store",
          "JobNumber": "502-1163909-5",
          "FirstdayontheJob": "02-18-2020",
          "LastdayontheJob": "02-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "301 W. Wyndot Ave",
          "JobAddress2": "Job# 4049",
          "JobFullAddress": "301 W. Wyndot Ave Job# 4049",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "DREAMKEEPER PROPERTIES",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "DREAMKEEPER PROPERTIES"
        },
        {
          "WorkOrder": 4003545,
          "ParentWorkOrder": "",
          "JobName": "(NOF)St Clair Apt PhaseII",
          "JobNumber": "502-1084306-4",
          "FirstdayontheJob": "02-18-2020",
          "LastdayontheJob": "02-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Clinton Ave",
          "JobAddress2": "c/o 1970 River Rd",
          "JobFullAddress": "Clinton Ave c/o 1970 River Rd",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "T&B CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,953.64",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "T&B CONSTRUCTION"
        },
        {
          "WorkOrder": 4003544,
          "ParentWorkOrder": "",
          "JobName": "NOF KROGER",
          "JobNumber": "65-700189-7",
          "FirstdayontheJob": "02-18-2020",
          "LastdayontheJob": "02-18-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "855 COSHOCTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "855 COSHOCTON AVE",
          "JobCounty": "KNOX",
          "JobState": "Ohio",
          "CustomerCompanyName": "CAHILL CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$19,134.96",
          "BranchNumber": 1,
          "SubmitDate": "02-19-2020",
          "ClientonJob": "CAHILL CONSTRUCTION INC"
        },
        {
          "WorkOrder": 4002828,
          "ParentWorkOrder": "",
          "JobName": "WALMART - MCCOMB",
          "JobNumber": "114-1386900-2",
          "FirstdayontheJob": "01-30-2020",
          "LastdayontheJob": "02-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1608 VETERANS BLVD",
          "JobAddress2": "",
          "JobFullAddress": "1608 VETERANS BLVD",
          "JobCounty": "Pike",
          "JobState": "Mississippi",
          "CustomerCompanyName": "JOE R JONES CONSTRUCTION",
          "RequestType": "Notice of Lien Rights to Contractor or (If No Contractor) Owner",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$951.95",
          "BranchNumber": 13,
          "SubmitDate": "02-17-2020",
          "ClientonJob": "JOE R JONES CONSTRUCTION"
        },
        {
          "WorkOrder": 4002827,
          "ParentWorkOrder": "",
          "JobName": "AMAZON DELIVERY CENTER",
          "JobNumber": "114-1332253-8",
          "FirstdayontheJob": "01-03-2020",
          "LastdayontheJob": "02-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5733 CITRUS BLVD",
          "JobAddress2": "",
          "JobFullAddress": "5733 CITRUS BLVD",
          "JobCounty": "Jefferson",
          "JobState": "Louisiana",
          "CustomerCompanyName": "SUPERIOR DRYWALL & PAINT",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$150,000.00",
          "BalanceRemaining": "$31,875.44",
          "BranchNumber": 14,
          "SubmitDate": "02-17-2020",
          "ClientonJob": "SUPERIOR DRYWALL & PAINT"
        },
        {
          "WorkOrder": 4002826,
          "ParentWorkOrder": "",
          "JobName": "COTEAU FIRE STATION",
          "JobNumber": "114-1331440-41",
          "FirstdayontheJob": "02-04-2020",
          "LastdayontheJob": "02-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2325 Coteau Rd",
          "JobAddress2": "",
          "JobFullAddress": "2325 Coteau Rd",
          "JobCounty": "Terrebonne",
          "JobState": "Louisiana",
          "CustomerCompanyName": "TIKE INTERIORS LLC",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$6,503.18",
          "BranchNumber": 14,
          "SubmitDate": "02-17-2020",
          "ClientonJob": "TIKE INTERIORS LLC"
        },
        {
          "WorkOrder": 4002825,
          "ParentWorkOrder": "",
          "JobName": "ST CHARLES PAR COURTHOUSE / AHU-2/AHU-4 HVAC REPLACEM",
          "JobNumber": "114-150294-4",
          "FirstdayontheJob": "01-14-2020",
          "LastdayontheJob": "02-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15045 RIVER ROAD",
          "JobAddress2": "",
          "JobFullAddress": "15045 RIVER ROAD",
          "JobCounty": "St Charles",
          "JobState": "Louisiana",
          "CustomerCompanyName": "TUNA CONSTRUCTION L.L.C.",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$10,147.04",
          "BranchNumber": 14,
          "SubmitDate": "02-17-2020",
          "ClientonJob": "TUNA CONSTRUCTION L.L.C."
        },
        {
          "WorkOrder": 4002409,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Owens Dana Phase2",
          "JobNumber": "502-1305825-25",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "310 3rd Street",
          "JobAddress2": "JOB#19-224",
          "JobFullAddress": "310 3rd Street JOB#19-224",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$26,874.51",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 4002408,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Sephora",
          "JobNumber": "502-1304186-56",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2110 Levis Commons Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2110 Levis Commons Blvd",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,258.82",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4002407,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Caseys General Store",
          "JobNumber": "502-1163909-5",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "301 W. Wyandot Ave",
          "JobAddress2": "",
          "JobFullAddress": "301 W. Wyandot Ave",
          "JobCounty": "Wyandot",
          "JobState": "Ohio",
          "CustomerCompanyName": "DREAMKEEPER PROPERTIES",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "DREAMKEEPER PROPERTIES"
        },
        {
          "WorkOrder": 4002406,
          "ParentWorkOrder": "",
          "JobName": "(NOF)St Clair Apt PhaseII",
          "JobNumber": "502-1084306-4",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Clinton Ave",
          "JobAddress2": "c/o 1970 River Rd",
          "JobFullAddress": "Clinton Ave c/o 1970 River Rd",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "T&B CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,328.72",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "T&B CONSTRUCTION"
        },
        {
          "WorkOrder": 4002405,
          "ParentWorkOrder": "",
          "JobName": "NOF UVMC CARDIO PULMONARY",
          "JobNumber": "65-502787-57",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3130 N COUNTY RD 25A",
          "JobAddress2": "",
          "JobFullAddress": "3130 N COUNTY RD 25A",
          "JobCounty": "MIAMI",
          "JobState": "Ohio",
          "CustomerCompanyName": "DAYTON WALLS & CEILINGS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$544.00",
          "BranchNumber": 13,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "DAYTON WALLS & CEILINGS"
        },
        {
          "WorkOrder": 4002404,
          "ParentWorkOrder": "",
          "JobName": "NOF Fayette Co Detention",
          "JobNumber": "65-502280-60",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 Robinson Rd SE",
          "JobAddress2": "",
          "JobFullAddress": "1500 Robinson Rd SE",
          "JobCounty": "Fayette",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALTIES INC",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$10,225.72",
          "BranchNumber": 13,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "COMBS INTERIOR SPECIALTIES INC"
        },
        {
          "WorkOrder": 4002403,
          "ParentWorkOrder": "",
          "JobName": "NOF RICKENBAKER HANGER 885",
          "JobNumber": "65-47310-2",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7370 MINUTEMAN WAY",
          "JobAddress2": "",
          "JobFullAddress": "7370 MINUTEMAN WAY",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "LGC GLOBAL INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$8,222.68",
          "BranchNumber": 4,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "LGC GLOBAL INC"
        },
        {
          "WorkOrder": 4002314,
          "ParentWorkOrder": "",
          "JobName": "(NOF)AGS 181/2 Court yard",
          "JobNumber": "502-1650650-9",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6700 18 1/2 mile road",
          "JobAddress2": "",
          "JobFullAddress": "6700 18 1/2 mile road",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDUSTRIAL TECH SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$12,079.33",
          "BranchNumber": 11,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "INDUSTRIAL TECH SERVICES"
        },
        {
          "WorkOrder": 4002313,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Marysville Housing",
          "JobNumber": "502-861787-39",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1100 New York Ave",
          "JobAddress2": "",
          "JobFullAddress": "1100 New York Ave",
          "JobCounty": "Saint Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$19,006.00",
          "BalanceRemaining": "$1,780.37",
          "BranchNumber": 11,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "JFS Company"
        },
        {
          "WorkOrder": 4002312,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Westridge Estates",
          "JobNumber": "502-847401-146",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50516 Hesperus",
          "JobAddress2": "",
          "JobFullAddress": "50516 Hesperus",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,306.00",
          "BalanceRemaining": "$5,130.54",
          "BranchNumber": 11,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 4002311,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BARTON HILLS KITCHEN",
          "JobNumber": "502-1507798-38",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "730 COUNTRY CLUB RD",
          "JobAddress2": "",
          "JobFullAddress": "730 COUNTRY CLUB RD",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$607.69",
          "BranchNumber": 9,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4002310,
          "ParentWorkOrder": "",
          "JobName": "(NOF)INDIAN MEADOWS",
          "JobNumber": "502-1325000-23",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1085 TAHOE TRAIL",
          "JobAddress2": "",
          "JobFullAddress": "1085 TAHOE TRAIL",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$1,006.00",
          "BalanceRemaining": "$166.36",
          "BranchNumber": 9,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4002309,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Panera Bread",
          "JobNumber": "502-944100-333",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "37611 W 12 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "37611 W 12 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 4002308,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Target Auburn Hills",
          "JobNumber": "502-934150-65",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "650 Brown Road",
          "JobAddress2": "",
          "JobFullAddress": "650 Brown Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "WOODS CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$12,454.60",
          "BranchNumber": 9,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "WOODS CONSTRUCTION INC"
        },
        {
          "WorkOrder": 4002307,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1605 NEURO-MEG RENO",
          "JobNumber": "502-913820-568",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3601 W. 13 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "3601 W. 13 MILE RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$604.45",
          "BranchNumber": 9,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4002306,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1604 GUERNSEY",
          "JobNumber": "502-913820-567",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21300 NOVI ROAD",
          "JobAddress2": "",
          "JobFullAddress": "21300 NOVI ROAD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$1,838.94",
          "BranchNumber": 9,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4002305,
          "ParentWorkOrder": "",
          "JobName": "(NOF)US FARATHANE",
          "JobNumber": "502-913400-28",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "39200 FORD RD",
          "JobAddress2": "",
          "JobFullAddress": "39200 FORD RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DANCO CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$5,994.63",
          "BranchNumber": 9,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "DANCO CONTRACTING, INC."
        },
        {
          "WorkOrder": 4002304,
          "ParentWorkOrder": "",
          "JobName": "(NOF)114 Bellevue Ave",
          "JobNumber": "502-8997117-20",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "114 Bellevue Ave",
          "JobAddress2": "",
          "JobFullAddress": "114 Bellevue Ave",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "FRONTGATE VENTURES LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,360.93",
          "BranchNumber": 8,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "FRONTGATE VENTURES LLC"
        },
        {
          "WorkOrder": 4002303,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Ultra Pro. Outdoor",
          "JobNumber": "502-1639418-28",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4565 Dixie Highway",
          "JobAddress2": "",
          "JobFullAddress": "4565 Dixie Highway",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$977.51",
          "BranchNumber": 8,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 4002302,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Jeremy Kaleniecki",
          "JobNumber": "502-877250-142",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6801 Holland Rd",
          "JobAddress2": "",
          "JobFullAddress": "6801 Holland Rd",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Cons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$4,848.03",
          "BranchNumber": 8,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "Blue Water Drywall & Cons"
        },
        {
          "WorkOrder": 4002301,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Encore Bldg/Westwd",
          "JobNumber": "502-839201-12",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "52477 Battanwood Dr",
          "JobAddress2": "",
          "JobFullAddress": "52477 Battanwood Dr",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLINTON INTERIORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,283.14",
          "BranchNumber": 8,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "CLINTON INTERIORS INC"
        },
        {
          "WorkOrder": 4002299,
          "ParentWorkOrder": "",
          "JobName": "(NOF)McLaren Hospital/ACP/Reichenbach J.V.",
          "JobNumber": "502-810150-52",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3101 Technology",
          "JobAddress2": "ACP/Reichenbach J.V.",
          "JobFullAddress": "3101 Technology ACP/Reichenbach J.V.",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTIC CEIL & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "ACOUSTIC CEIL & PART"
        },
        {
          "WorkOrder": 4002298,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hampton Inn Bloomfld",
          "JobNumber": "502-990401-57",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1951 S Telegraph Rd.",
          "JobAddress2": "",
          "JobFullAddress": "1951 S Telegraph Rd.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4002297,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Tru By Hilton Novi",
          "JobNumber": "502-990401-56",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "40255 W. 13 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "40255 W. 13 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 4002296,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DSW SHOES",
          "JobNumber": "502-1060142-19",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4830 WILSON AVE SW",
          "JobAddress2": "SUITE 620",
          "JobFullAddress": "4830 WILSON AVE SW SUITE 620",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$7,133.52",
          "BranchNumber": 5,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 4002295,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WILDBILLSLAPEER",
          "JobNumber": "502-1602000-29",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "864 S MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "864 S MAIN ST",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,206.00",
          "BalanceRemaining": "$2,560.47",
          "BranchNumber": 1,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "Great Lakes Bay Construction"
        },
        {
          "WorkOrder": 4002293,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDS#19180",
          "JobNumber": "502-1417251-10",
          "FirstdayontheJob": "02-12-2020",
          "LastdayontheJob": "02-12-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "122 W. Chicago St.",
          "JobAddress2": "",
          "JobFullAddress": "122 W. Chicago St.",
          "JobCounty": "Branch",
          "JobState": "Michigan",
          "CustomerCompanyName": "LYNCH CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,487.87",
          "BranchNumber": 1,
          "SubmitDate": "02-13-2020",
          "ClientonJob": "LYNCH CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 4001692,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Owens Advance Phase2",
          "JobNumber": "502-1305825-25",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "310 3rd St",
          "JobAddress2": "",
          "JobFullAddress": "310 3rd St",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$7,230.45",
          "BranchNumber": 13,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 4001691,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Sephora",
          "JobNumber": "502-1304186-56",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2110 Levis Commons Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2110 Levis Commons Blvd",
          "JobCounty": "Wood",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,258.82",
          "BranchNumber": 13,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 4001690,
          "ParentWorkOrder": "",
          "JobName": "(NOF)St Clair Apt PhaseII",
          "JobNumber": "502-1084306-4",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1124 CLINTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "1124 CLINTON AVE",
          "JobCounty": "St Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "T&B CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$3,201.95",
          "BranchNumber": 13,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "T&B CONSTRUCTION"
        },
        {
          "WorkOrder": 4001684,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Moran Auto Body",
          "JobNumber": "502-841801-13",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2900 Krafft Rd",
          "JobAddress2": "",
          "JobFullAddress": "2900 Krafft Rd",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "DUALL CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,906.00",
          "BalanceRemaining": "$4,511.20",
          "BranchNumber": 11,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "DUALL CONSTRUCTION"
        },
        {
          "WorkOrder": 4001683,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DOUBLETREE",
          "JobNumber": "502-1507798-40",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3600 PLYMOUTH RD",
          "JobAddress2": "",
          "JobFullAddress": "3600 PLYMOUTH RD",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4001682,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MUNN ICE ARENA",
          "JobNumber": "502-933550-180",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "509 BIRCH RD.",
          "JobAddress2": "",
          "JobFullAddress": "509 BIRCH RD.",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$1,957.81",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4001681,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GM Lk Orion Mezzanin/Job 190927 PO 190927-1002",
          "JobNumber": "502-916331-15",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4555 Giddings Rd",
          "JobAddress2": "",
          "JobFullAddress": "4555 Giddings Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "IDEAL CONTRACTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$13,049.93",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "IDEAL CONTRACTING LLC"
        },
        {
          "WorkOrder": 4001680,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Uncle Julians",
          "JobNumber": "502-916315-153",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1985 W Big Beaver Rd.",
          "JobAddress2": "",
          "JobFullAddress": "1985 W Big Beaver Rd.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$99.93",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4001679,
          "ParentWorkOrder": "",
          "JobName": "(NOF)#1600 BEAUMONT PEDIA/NEURO SCIENCE 3RD FLOOR",
          "JobNumber": "502-913820-561",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3601 W 13 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "3601 W 13 MILE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$2,099.43",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 4001678,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LIBERTY SCHOOL",
          "JobNumber": "502-910050-55",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7265 N ANN ARBOR ST",
          "JobAddress2": "",
          "JobFullAddress": "7265 N ANN ARBOR ST",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,999.08",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 4001677,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Courtyard Marriott",
          "JobNumber": "502-1695830-2",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1525 E. Maple Rd.",
          "JobAddress2": "",
          "JobFullAddress": "1525 E. Maple Rd.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "GC RENOVATION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$1,245.71",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "GC RENOVATION"
        },
        {
          "WorkOrder": 4001676,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Kid City",
          "JobNumber": "502-1639425-3",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50773 Corporate Dr",
          "JobAddress2": "Lockbox 1934",
          "JobFullAddress": "50773 Corporate Dr Lockbox 1934",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "PREMIER INTERIORS LLC"
        },
        {
          "WorkOrder": 4001675,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1 Stop Market",
          "JobNumber": "502-939300-17",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25155 Greenfield Rd.",
          "JobAddress2": "",
          "JobFullAddress": "25155 Greenfield Rd.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "D&S Contractors Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$919.44",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "D&S Contractors Inc"
        },
        {
          "WorkOrder": 4001673,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(268)Sunrise Hibachi",
          "JobNumber": "502-816350-146",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "56519 Van Dyke Ave",
          "JobAddress2": "",
          "JobFullAddress": "56519 Van Dyke Ave",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,270.82",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4001672,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FIREKEEPERS",
          "JobNumber": "502-1688225-2",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11177 E MICHIGAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "11177 E MICHIGAN AVE",
          "JobCounty": "Calhoun",
          "JobState": "Michigan",
          "CustomerCompanyName": "UNIVERSAL GLASS AND METAL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$15,129.10",
          "BranchNumber": 7,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "UNIVERSAL GLASS AND METAL INC"
        },
        {
          "WorkOrder": 4001671,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hans Water & Power",
          "JobNumber": "502-990400-5",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "500 Packard Hwy",
          "JobAddress2": "",
          "JobFullAddress": "500 Packard Hwy",
          "JobCounty": "EATON",
          "JobState": "Michigan",
          "CustomerCompanyName": "RAJALA & SONS FINISHES",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$4,977.50",
          "BranchNumber": 7,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "RAJALA & SONS FINISHES"
        },
        {
          "WorkOrder": 4001670,
          "ParentWorkOrder": "",
          "JobName": "(NOF)P18543 MMHP",
          "JobNumber": "502-31890-23",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4851 EAST PICKARD RD",
          "JobAddress2": "",
          "JobFullAddress": "4851 EAST PICKARD RD",
          "JobCounty": "Isabella",
          "JobState": "Michigan",
          "CustomerCompanyName": "THREE RIVERS CORPORATION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "THREE RIVERS CORPORATION"
        },
        {
          "WorkOrder": 4001669,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HARBOR FREIGHT BC",
          "JobNumber": "502-23110-11",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3770 WILDER RD",
          "JobAddress2": "",
          "JobFullAddress": "3770 WILDER RD",
          "JobCounty": "Bay",
          "JobState": "Michigan",
          "CustomerCompanyName": "SIWEK CONSTRUCTION CO.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,409.66",
          "BranchNumber": 1,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "SIWEK CONSTRUCTION CO."
        },
        {
          "WorkOrder": 4001591,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Commercial Property",
          "JobNumber": "502-865069-11",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25599 Kelly Rd",
          "JobAddress2": "",
          "JobFullAddress": "25599 Kelly Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "Salski Construction LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "Salski Construction LLC"
        },
        {
          "WorkOrder": 4001590,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LaBelle Development",
          "JobNumber": "502-858171-113",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "38267 Huron Pointe",
          "JobAddress2": "",
          "JobFullAddress": "38267 Huron Pointe",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "DGS DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$5,894.43",
          "BranchNumber": 11,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "DGS DRYWALL LLC"
        },
        {
          "WorkOrder": 4001589,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Captain D's Seafood",
          "JobNumber": "502-1634596-3",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35134 Groesbeck Hwy",
          "JobAddress2": "",
          "JobFullAddress": "35134 Groesbeck Hwy",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUMMIT PROPERTIES & DEVELOMENT CO INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "SUMMIT PROPERTIES & DEVELOMENT CO INC"
        },
        {
          "WorkOrder": 4001588,
          "ParentWorkOrder": "",
          "JobName": "NOF RESIDENTIAL HOUSE",
          "JobNumber": "502-1604855-7",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "348 LENOX LN",
          "JobAddress2": "",
          "JobFullAddress": "348 LENOX LN",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "R&V DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$4,566.91",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "R&V DRYWALL INC"
        },
        {
          "WorkOrder": 4001587,
          "ParentWorkOrder": "",
          "JobName": "(NOF)NORTHWESTERN TECH",
          "JobNumber": "502-1507798-39",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24800 NORTHWESTERN HWY",
          "JobAddress2": "",
          "JobFullAddress": "24800 NORTHWESTERN HWY",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$42,006.00",
          "BalanceRemaining": "$4,505.18",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4001586,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BARTON HILLS",
          "JobNumber": "502-1507798-37",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "730 COUNTRY CLUB RD",
          "JobAddress2": "",
          "JobFullAddress": "730 COUNTRY CLUB RD",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$448.38",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4001585,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ASSURANCE HEALTH",
          "JobNumber": "502-1507798-35",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4040 KING RD",
          "JobAddress2": "",
          "JobFullAddress": "4040 KING RD",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,221.69",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 4001584,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CROOKS",
          "JobNumber": "502-1325000-21",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5545 CROOKS RD",
          "JobAddress2": "",
          "JobFullAddress": "5545 CROOKS RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 4001583,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ASHLEY HOME STORE",
          "JobNumber": "502-941500-46",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4936 S. BALDWIN RD",
          "JobAddress2": "",
          "JobFullAddress": "4936 S. BALDWIN RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "FREEMAN INTERIOR",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$8,690.87",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "FREEMAN INTERIOR"
        },
        {
          "WorkOrder": 4001582,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Meijer Bay City",
          "JobNumber": "502-938450-4",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2980 Wilder Rd.",
          "JobAddress2": "",
          "JobFullAddress": "2980 Wilder Rd.",
          "JobCounty": "Bay",
          "JobState": "Michigan",
          "CustomerCompanyName": "CITY RENOVATION & TRIM",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$9,090.49",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "CITY RENOVATION & TRIM"
        },
        {
          "WorkOrder": 4001581,
          "ParentWorkOrder": "",
          "JobName": "NOF)QUICKEN PHAS 1 20-307",
          "JobNumber": "502-937250-156",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "ONE CAMPUS MARTIUS",
          "JobAddress2": "",
          "JobFullAddress": "ONE CAMPUS MARTIUS",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINKER TEAM CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$4,107.93",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "BRINKER TEAM CONSTRUCTION CO"
        },
        {
          "WorkOrder": 4001580,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Quicken Loans-Chase",
          "JobNumber": "502-933550-182",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "611 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "611 Woodward Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$14,106.45",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 4001579,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RESERVE @ W.BLOOMFIE",
          "JobNumber": "502-919700-137",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7893 ARIMOORE DR",
          "JobAddress2": "",
          "JobFullAddress": "7893 ARIMOORE DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition Llc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "Multi Drywall & Partition Llc"
        },
        {
          "WorkOrder": 4001578,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Toyota CALTY",
          "JobNumber": "502-916331-8",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4400 Goss Road",
          "JobAddress2": "",
          "JobFullAddress": "4400 Goss Road",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "IDEAL CONTRACTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "IDEAL CONTRACTING LLC"
        },
        {
          "WorkOrder": 4001577,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Chic Fil-A Novi",
          "JobNumber": "502-916315-150",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27750 Novi Rd",
          "JobAddress2": "",
          "JobFullAddress": "27750 Novi Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,997.86",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 4001575,
          "ParentWorkOrder": "",
          "JobName": "(NOF) IAGD ROCHESTER HILL",
          "JobNumber": "502-911550-9",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "879 W. AUBURN RD",
          "JobAddress2": "",
          "JobFullAddress": "879 W. AUBURN RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BEST FINISHERS INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "BEST FINISHERS INC."
        },
        {
          "WorkOrder": 4001574,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Detroit Taco",
          "JobNumber": "502-1639425-2",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50767 Corporate Dr",
          "JobAddress2": "Lockbox 1934",
          "JobFullAddress": "50767 Corporate Dr Lockbox 1934",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "PREMIER INTERIORS LLC"
        },
        {
          "WorkOrder": 4001573,
          "ParentWorkOrder": "",
          "JobName": "(NOF)IKEA CANTON",
          "JobNumber": "502-1601280-2",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41640 Ford Rd",
          "JobAddress2": "",
          "JobFullAddress": "41640 Ford Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Facility Maintenance & Construction of Michigan",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$138.79",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "Facility Maintenance & Construction of Michigan"
        },
        {
          "WorkOrder": 4001572,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Detroit Shipyard",
          "JobNumber": "502-875466-5",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "474 Peterboro St",
          "JobAddress2": "",
          "JobFullAddress": "474 Peterboro St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Facilities Management Gro",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,569.82",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "Facilities Management Gro"
        },
        {
          "WorkOrder": 4001571,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Par Building #8",
          "JobNumber": "502-817800-46",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "870 Parkdale Rd",
          "JobAddress2": "",
          "JobFullAddress": "870 Parkdale Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$4,521.73",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 4001570,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(264)OCC AH Bld-C",
          "JobNumber": "502-816350-145",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2900 Featherstone Rd",
          "JobAddress2": "",
          "JobFullAddress": "2900 Featherstone Rd",
          "JobCounty": "Oakalnd",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$300,006.00",
          "BalanceRemaining": "$167.95",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 4001569,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hand and Stone",
          "JobNumber": "502-815630-230",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50932 Gratiot Ave",
          "JobAddress2": "",
          "JobFullAddress": "50932 Gratiot Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 4001568,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9032 SJMO MCI Pha",
          "JobNumber": "502-815250-359",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44405 Woodward Ave.",
          "JobAddress2": "Phase 2",
          "JobFullAddress": "44405 Woodward Ave. Phase 2",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 4001567,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Carvana Novi",
          "JobNumber": "502-814500-82",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26890 Adell Center Dr.",
          "JobAddress2": "",
          "JobFullAddress": "26890 Adell Center Dr.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "COMMERCIAL INT CONST"
        },
        {
          "WorkOrder": 4001566,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BOURROUGH-HATTERES",
          "JobNumber": "502-811800-69",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41100 PLYMOUTH RD",
          "JobAddress2": "BLDG # 2",
          "JobFullAddress": "41100 PLYMOUTH RD BLDG # 2",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "BHD BUILDERS, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "BHD BUILDERS, INC"
        },
        {
          "WorkOrder": 4001565,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RADIO CHMISTRY LAB",
          "JobNumber": "502-1693475-3",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2450 Port Lansing Rd",
          "JobAddress2": "",
          "JobFullAddress": "2450 Port Lansing Rd",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "NIOWAVE INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$27,093.37",
          "BranchNumber": 7,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "NIOWAVE INC."
        },
        {
          "WorkOrder": 4001564,
          "ParentWorkOrder": "",
          "JobName": "(NOF)JXSN Free Methodist",
          "JobNumber": "502-30900-67",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2829 Park Dr.",
          "JobAddress2": "",
          "JobFullAddress": "2829 Park Dr.",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "Integrity Interiors Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "Integrity Interiors Inc."
        },
        {
          "WorkOrder": 4001563,
          "ParentWorkOrder": "",
          "JobName": "(NOF) PORT VIEW FLATS",
          "JobNumber": "502-50435-63",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5300 60TH ST SE",
          "JobAddress2": "",
          "JobFullAddress": "5300 60TH ST SE",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR BOUWKAMP & ASSOC. INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$65,006.00",
          "BalanceRemaining": "$28,871.02",
          "BranchNumber": 5,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "JR BOUWKAMP & ASSOC. INC"
        },
        {
          "WorkOrder": 4001562,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 9504 MANSION #2",
          "JobNumber": "502-1650969-70",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9504 Crescent Beach Rd.",
          "JobAddress2": "",
          "JobFullAddress": "9504 Crescent Beach Rd.",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,592.20",
          "BranchNumber": 1,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 4001561,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HERITAGE6024KOCHVILL",
          "JobNumber": "502-1604200-2",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6024 Kochiville Rd",
          "JobAddress2": "",
          "JobFullAddress": "6024 Kochiville Rd",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "TAMMY LEE HAMMOND LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$4,319.14",
          "BranchNumber": 1,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "TAMMY LEE HAMMOND LLC"
        },
        {
          "WorkOrder": 4001560,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TIM HORTONS BAY CTY",
          "JobNumber": "502-1602000-28",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3460 WILDER RD",
          "JobAddress2": "",
          "JobFullAddress": "3460 WILDER RD",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$610.64",
          "BranchNumber": 1,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "Great Lakes Bay Construction"
        },
        {
          "WorkOrder": 4001559,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SAINTGOBAIN",
          "JobNumber": "502-1602000-27",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3910 TERRY-DIANE ST",
          "JobAddress2": "",
          "JobFullAddress": "3910 TERRY-DIANE ST",
          "JobCounty": "GLADWIN",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Constr",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "Great Lakes Bay Constr"
        },
        {
          "WorkOrder": 4001558,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GREENHILL APARTMENTS",
          "JobNumber": "502-11041-31",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1010 EASTLAWN DR.",
          "JobAddress2": "",
          "JobFullAddress": "1010 EASTLAWN DR.",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Complete Acoustical",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,661.50",
          "BranchNumber": 1,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "Complete Acoustical"
        },
        {
          "WorkOrder": 4001556,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HarborBeachCommHosp",
          "JobNumber": "502-10550-39",
          "FirstdayontheJob": "02-10-2020",
          "LastdayontheJob": "02-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "229 S Huron Ave",
          "JobAddress2": "",
          "JobFullAddress": "229 S Huron Ave",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Booms Construction Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$11,027.12",
          "BranchNumber": 1,
          "SubmitDate": "02-11-2020",
          "ClientonJob": "Booms Construction Inc."
        },
        {
          "WorkOrder": 4001042,
          "ParentWorkOrder": "",
          "JobName": "ADVANCED AUTO PARTS",
          "JobNumber": "114-1361355-5",
          "FirstdayontheJob": "01-27-2020",
          "LastdayontheJob": "02-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4936 VETERANS BLVD",
          "JobAddress2": "",
          "JobFullAddress": "4936 VETERANS BLVD",
          "JobCounty": "JEFFERSON",
          "JobState": "Louisiana",
          "CustomerCompanyName": "SOUTHWESTERN SERVICES",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$2,416.52",
          "BranchNumber": 14,
          "SubmitDate": "02-10-2020",
          "ClientonJob": "SOUTHWESTERN SERVICES"
        },
        {
          "WorkOrder": 4001041,
          "ParentWorkOrder": "",
          "JobName": "842 CAMP STREET",
          "JobNumber": "114-1336335-4",
          "FirstdayontheJob": "12-23-2019",
          "LastdayontheJob": "02-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "842 CAMP STREET",
          "JobAddress2": "",
          "JobFullAddress": "842 CAMP STREET",
          "JobCounty": "ORLEANS",
          "JobState": "Louisiana",
          "CustomerCompanyName": "MKRED BUILD LLC",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Stopped",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,592.43",
          "BranchNumber": 14,
          "SubmitDate": "",
          "ClientonJob": "MKRED BUILD LLC"
        },
        {
          "WorkOrder": 4001040,
          "ParentWorkOrder": "",
          "JobName": "4927 FRERET STREET",
          "JobNumber": "114-1333830-2",
          "FirstdayontheJob": "01-22-2020",
          "LastdayontheJob": "02-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4927 FRERET ST",
          "JobAddress2": "",
          "JobFullAddress": "4927 FRERET ST",
          "JobCounty": "ORLEANS",
          "JobState": "Louisiana",
          "CustomerCompanyName": "TRIDENT SUPPLY LLC",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$1,793.73",
          "BranchNumber": 14,
          "SubmitDate": "02-10-2020",
          "ClientonJob": "TRIDENT SUPPLY LLC"
        },
        {
          "WorkOrder": 4001039,
          "ParentWorkOrder": "",
          "JobName": "1113 BOURBON STREET.",
          "JobNumber": "114-1331851-20",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "02-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1113 BOURBON STREET.",
          "JobAddress2": "JOSH,504-259-2931.",
          "JobFullAddress": "1113 BOURBON STREET. JOSH,504-259-2931.",
          "JobCounty": "",
          "JobState": "Louisiana",
          "CustomerCompanyName": "Cain Construction",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$4,661.70",
          "BranchNumber": 14,
          "SubmitDate": "",
          "ClientonJob": "Cain Construction"
        },
        {
          "WorkOrder": 4001038,
          "ParentWorkOrder": "",
          "JobName": "ST PETER SCHOOL",
          "JobNumber": "114-1331440-40",
          "FirstdayontheJob": "01-31-2020",
          "LastdayontheJob": "02-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "130 E TEMPERANCE STREET",
          "JobAddress2": "",
          "JobFullAddress": "130 E TEMPERANCE STREET",
          "JobCounty": "ST TAMMANY",
          "JobState": "Louisiana",
          "CustomerCompanyName": "TIKE INTERIORS LLC",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$200,000.00",
          "BalanceRemaining": "$70,817.47",
          "BranchNumber": 14,
          "SubmitDate": "02-10-2020",
          "ClientonJob": "TIKE INTERIORS LLC"
        },
        {
          "WorkOrder": 4001037,
          "ParentWorkOrder": "",
          "JobName": "LAFOURCHE PARISH LIBRARY",
          "JobNumber": "114-1331440-39",
          "FirstdayontheJob": "12-19-2019",
          "LastdayontheJob": "02-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "705 W 5TH STREET",
          "JobAddress2": "",
          "JobFullAddress": "705 W 5TH STREET",
          "JobCounty": "LAFOURCHE",
          "JobState": "Louisiana",
          "CustomerCompanyName": "TIKE INTERIORS LLC",
          "RequestType": "Preliminary Notice",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$3,603.60",
          "BranchNumber": 14,
          "SubmitDate": "02-10-2020",
          "ClientonJob": "TIKE INTERIORS LLC"
        },
        {
          "WorkOrder": 4001036,
          "ParentWorkOrder": "",
          "JobName": "1643 JOSEPHINE ST APTS",
          "JobNumber": "114-1330934-2",
          "FirstdayontheJob": "11-20-2019",
          "LastdayontheJob": "02-09-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1643 JOSEPHINE STREET",
          "JobAddress2": "",
          "JobFullAddress": "1643 JOSEPHINE STREET",
          "JobCounty": "Orleans",
          "JobState": "Louisiana",
          "CustomerCompanyName": "ACMS INC",
          "RequestType": "Notice of Non-Payment (Suppliers Only)",
          "CustomerRepresentative": "Maria Rizo",
          "Status": "Mailed and Notarized",
          "ContractAmount": "$55,006.00",
          "BalanceRemaining": "$25,815.85",
          "BranchNumber": 14,
          "SubmitDate": "02-10-2020",
          "ClientonJob": "ACMS INC"
        },
        {
          "WorkOrder": 4001030,
          "ParentWorkOrder": "",
          "JobName": "Mountain View Apartments",
          "JobNumber": "394-0002229",
          "FirstdayontheJob": "",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2330 Hamilton Avenue",
          "JobAddress2": "",
          "JobFullAddress": "2330 Hamilton Avenue",
          "JobCounty": "Marion",
          "JobState": "Tennessee",
          "CustomerCompanyName": "Dan’s Handyman Service LLC",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Valerie Nottage",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$6,337.87",
          "BranchNumber": ".",
          "SubmitDate": "02-10-2020",
          "ClientonJob": "Dan’s Handyman Service LLC"
        },
        {
          "WorkOrder": 4001028,
          "ParentWorkOrder": "",
          "JobName": "5733 Nails Creek Rd",
          "JobNumber": "394-119507",
          "FirstdayontheJob": "",
          "LastdayontheJob": "01-10-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5733 Nails Creek Rd",
          "JobAddress2": "",
          "JobFullAddress": "5733 Nails Creek Rd",
          "JobCounty": "BLOUNT",
          "JobState": "Tennessee",
          "CustomerCompanyName": "East Tennessee Painting",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Valerie Nottage",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$3,509.88",
          "BranchNumber": ".",
          "SubmitDate": "02-10-2020",
          "ClientonJob": "East Tennessee Painting"
        },
        {
          "WorkOrder": 4000998,
          "ParentWorkOrder": "",
          "JobName": "6104 Nails Creek Rd",
          "JobNumber": "394-119507",
          "FirstdayontheJob": "",
          "LastdayontheJob": "12-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6104 Nails Creek Rd",
          "JobAddress2": "",
          "JobFullAddress": "6104 Nails Creek Rd",
          "JobCounty": "Sevier",
          "JobState": "Tennessee",
          "CustomerCompanyName": "East Tennessee Painting",
          "RequestType": "Notice of Non Payment",
          "CustomerRepresentative": "Valerie Nottage",
          "Status": "Mailed",
          "ContractAmount": "N/A",
          "BalanceRemaining": "$8,162.91",
          "BranchNumber": ".",
          "SubmitDate": "02-10-2020",
          "ClientonJob": "East Tennessee Painting"
        },
        {
          "WorkOrder": 3999659,
          "ParentWorkOrder": "",
          "JobName": "NOF ZIPS CARWASH",
          "JobNumber": "65-503145-4",
          "FirstdayontheJob": "02-04-2020",
          "LastdayontheJob": "02-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11346 PRINCETON PIKE",
          "JobAddress2": "",
          "JobFullAddress": "11346 PRINCETON PIKE",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "WILD BUILDING CONTRACTOR",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$9,021.79",
          "BranchNumber": 2,
          "SubmitDate": "02-05-2020",
          "ClientonJob": "WILD BUILDING CONTRACTOR"
        },
        {
          "WorkOrder": 3999658,
          "ParentWorkOrder": "",
          "JobName": "(NOF)485 METRO PLACE",
          "JobNumber": "65-400551-2",
          "FirstdayontheJob": "02-04-2020",
          "LastdayontheJob": "02-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "485 METRO PLACE SO",
          "JobAddress2": "",
          "JobFullAddress": "485 METRO PLACE SO",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "Adena Commercial LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$701.76",
          "BranchNumber": 4,
          "SubmitDate": "02-05-2020",
          "ClientonJob": "Adena Commercial LLC"
        },
        {
          "WorkOrder": 3999657,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Gateway Lofts",
          "JobNumber": "65-131500-3",
          "FirstdayontheJob": "02-04-2020",
          "LastdayontheJob": "02-04-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Chardonnay Dr",
          "JobAddress2": "",
          "JobFullAddress": "Chardonnay Dr",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "HCC CONSTRUCTION CO LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$514.61",
          "BranchNumber": 13,
          "SubmitDate": "02-05-2020",
          "ClientonJob": "HCC CONSTRUCTION CO LLC"
        },
        {
          "WorkOrder": 3999238,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Owens Advance Phase2",
          "JobNumber": "502-1305825-25",
          "FirstdayontheJob": "02-03-2020",
          "LastdayontheJob": "02-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "310 3rd Street",
          "JobAddress2": "",
          "JobFullAddress": "310 3rd Street",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$5,796.85",
          "BranchNumber": 13,
          "SubmitDate": "02-04-2020",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 3999237,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Sephora",
          "JobNumber": "502-1304186-56",
          "FirstdayontheJob": "02-03-2020",
          "LastdayontheJob": "02-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2110 Levis Commons Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2110 Levis Commons Blvd",
          "JobCounty": "Wood",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,258.82",
          "BranchNumber": 13,
          "SubmitDate": "02-04-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3999236,
          "ParentWorkOrder": "",
          "JobName": "NOF NORTH POINT BLDG 4",
          "JobNumber": "65-2137099-13",
          "FirstdayontheJob": "02-03-2020",
          "LastdayontheJob": "02-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8511/8531 TRADE CENTER DR",
          "JobAddress2": "",
          "JobFullAddress": "8511/8531 TRADE CENTER DR",
          "JobCounty": "Butler",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & A INTERIOR SYSTEMS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$104.63",
          "BranchNumber": 12,
          "SubmitDate": "02-04-2020",
          "ClientonJob": "J & A INTERIOR SYSTEMS"
        },
        {
          "WorkOrder": 3999235,
          "ParentWorkOrder": "",
          "JobName": "NOF Zips Carwash-Colerain",
          "JobNumber": "65-503145-3",
          "FirstdayontheJob": "02-03-2020",
          "LastdayontheJob": "02-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8301 Colerain Ave",
          "JobAddress2": "",
          "JobFullAddress": "8301 Colerain Ave",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "WILD BUILDING CONTRACTOR",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$14,506.00",
          "BalanceRemaining": "$5,210.50",
          "BranchNumber": 2,
          "SubmitDate": "02-04-2020",
          "ClientonJob": "WILD BUILDING CONTRACTOR"
        },
        {
          "WorkOrder": 3999234,
          "ParentWorkOrder": "",
          "JobName": "NOF WPAFB Building 20",
          "JobNumber": "65-502280-64",
          "FirstdayontheJob": "02-03-2020",
          "LastdayontheJob": "02-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "NW Corner of 7th & E St",
          "JobAddress2": "",
          "JobFullAddress": "NW Corner of 7th & E St",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALITIES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$6,616.49",
          "BranchNumber": 13,
          "SubmitDate": "02-04-2020",
          "ClientonJob": "COMBS INTERIOR SPECIALITIES INC"
        },
        {
          "WorkOrder": 3999233,
          "ParentWorkOrder": "",
          "JobName": "NOF TRU HOTEL",
          "JobNumber": "65-123331-2",
          "FirstdayontheJob": "02-03-2020",
          "LastdayontheJob": "02-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "300 ORTON DR",
          "JobAddress2": "",
          "JobFullAddress": "300 ORTON DR",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "RAFIC NAKOUZI",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$11,875.08",
          "BranchNumber": 12,
          "SubmitDate": "02-04-2020",
          "ClientonJob": "RAFIC NAKOUZI"
        },
        {
          "WorkOrder": 3999232,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MEIJERS STORE",
          "JobNumber": "65-48844-3",
          "FirstdayontheJob": "02-03-2020",
          "LastdayontheJob": "02-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5050 N HAMILTON ROAD",
          "JobAddress2": "",
          "JobFullAddress": "5050 N HAMILTON ROAD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$10,192.09",
          "BranchNumber": 4,
          "SubmitDate": "02-04-2020",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 3999231,
          "ParentWorkOrder": "",
          "JobName": "NOF Redwood Miami Twp 131",
          "JobNumber": "65-27529-17",
          "FirstdayontheJob": "02-03-2020",
          "LastdayontheJob": "02-03-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1331 State Route 131",
          "JobAddress2": "",
          "JobFullAddress": "1331 State Route 131",
          "JobCounty": "Clermont",
          "JobState": "Ohio",
          "CustomerCompanyName": "NEWPHASE DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$4,956.48",
          "BranchNumber": 2,
          "SubmitDate": "02-04-2020",
          "ClientonJob": "NEWPHASE DRYWALL LLC"
        },
        {
          "WorkOrder": 3998931,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Top Golf - San Jose",
          "JobNumber": "65-2134999-13",
          "FirstdayontheJob": "02-02-2020",
          "LastdayontheJob": "02-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4701 N 1st St",
          "JobAddress2": "",
          "JobFullAddress": "4701 N 1st St",
          "JobCounty": "Santa Clara",
          "JobState": "California",
          "CustomerCompanyName": "REYES/TDR INTERIORS",
          "RequestType": "Preliminary Notice - Private Work",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$71,334.07",
          "BranchNumber": 12,
          "SubmitDate": "02-03-2020",
          "ClientonJob": "REYES/TDR INTERIORS"
        },
        {
          "WorkOrder": 3996232,
          "ParentWorkOrder": 3984440,
          "JobName": "(NOF) Swanton Creek Apts. - Village of Swanton",
          "JobNumber": "502-1160130-2",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "105 N Munson Rd",
          "JobAddress2": "",
          "JobFullAddress": "105 N Munson Rd",
          "JobCounty": "Fulton",
          "JobState": "Ohio",
          "CustomerCompanyName": "SOWERS COMMERCIAL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$10,261.56",
          "BranchNumber": 13,
          "SubmitDate": "01-23-2020",
          "ClientonJob": "SOWERS COMMERCIAL LLC"
        },
        {
          "WorkOrder": 3995617,
          "ParentWorkOrder": 3990940,
          "JobName": "(NOF) Superior",
          "JobNumber": "502-1399009-4",
          "FirstdayontheJob": "01-01-2020",
          "LastdayontheJob": "01-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "155 S. Superior St.",
          "JobAddress2": "",
          "JobFullAddress": "155 S. Superior St.",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Canaan Construction Inc.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,426.98",
          "BranchNumber": 13,
          "SubmitDate": "01-21-2020",
          "ClientonJob": "Canaan Construction Inc."
        },
        {
          "WorkOrder": 3995609,
          "ParentWorkOrder": 3988380,
          "JobName": "(NOF) NW Ohio Psych Hosp - Patient Unit Reno",
          "JobNumber": "502-1304700-19",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "930 S. Detroit Ave",
          "JobAddress2": "",
          "JobFullAddress": "930 S. Detroit Ave",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "LATHROP COMPANY",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$9,203.77",
          "BranchNumber": 13,
          "SubmitDate": "01-21-2020",
          "ClientonJob": "LATHROP COMPANY"
        },
        {
          "WorkOrder": 3995586,
          "ParentWorkOrder": 3978097,
          "JobName": "(NOF)Carhartt 2nd 3rd Flr",
          "JobNumber": "502-817800-43",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5800 Cass Ave.",
          "JobAddress2": "",
          "JobFullAddress": "5800 Cass Ave.",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$2,993.10",
          "BranchNumber": 8,
          "SubmitDate": "01-21-2020",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3994828,
          "ParentWorkOrder": "",
          "JobName": "(NOF) RESIDENCE INN",
          "JobNumber": "502-847401-145",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33163 HAMILTON CT",
          "JobAddress2": "",
          "JobFullAddress": "33163 HAMILTON CT",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "01-17-2020",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 3994827,
          "ParentWorkOrder": "",
          "JobName": "(NOF)  HHH 1122-19",
          "JobNumber": "502-952599-24",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5440 CORPORATE DR",
          "JobAddress2": "STE 100",
          "JobFullAddress": "5440 CORPORATE DR STE 100",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$1,029.79",
          "BranchNumber": 9,
          "SubmitDate": "01-17-2020",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3994826,
          "ParentWorkOrder": "",
          "JobName": "NOF JAZWIECKI RESIDENCE",
          "JobNumber": "502-917690-11",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "832 REAGAN ST",
          "JobAddress2": "",
          "JobFullAddress": "832 REAGAN ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Kingdom Construction, Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,483.88",
          "BranchNumber": 9,
          "SubmitDate": "01-17-2020",
          "ClientonJob": "Kingdom Construction, Inc"
        },
        {
          "WorkOrder": 3994825,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Culvers West Branch",
          "JobNumber": "502-916315-152",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2939 Cook Rd",
          "JobAddress2": "",
          "JobFullAddress": "2939 Cook Rd",
          "JobCounty": "OGEMAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "01-17-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3994824,
          "ParentWorkOrder": "",
          "JobName": "(NOF)#1599 CRATE & BARREL",
          "JobNumber": "502-913820-562",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$17,506.00",
          "BalanceRemaining": "$1,033.49",
          "BranchNumber": 9,
          "SubmitDate": "01-17-2020",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3994823,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Detroit Kid City",
          "JobNumber": "502-1605101-9",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50773 Corporate Dr",
          "JobAddress2": "",
          "JobFullAddress": "50773 Corporate Dr",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "ARCHITECTURAL PLANNERS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$1,144.93",
          "BranchNumber": 8,
          "SubmitDate": "01-17-2020",
          "ClientonJob": "ARCHITECTURAL PLANNERS"
        },
        {
          "WorkOrder": 3994822,
          "ParentWorkOrder": "",
          "JobName": "(NOF)South Lyon",
          "JobNumber": "502-843701-209",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 3994821,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Metro Welding",
          "JobNumber": "502-823300-78",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12620 Southfield Fwy",
          "JobAddress2": "",
          "JobFullAddress": "12620 Southfield Fwy",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,239.46",
          "BranchNumber": 8,
          "SubmitDate": "01-17-2020",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 3994820,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Beyond Juice",
          "JobNumber": "502-815750-270",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "400 RENAISSANCE CENTER",
          "JobAddress2": "",
          "JobFullAddress": "400 RENAISSANCE CENTER",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DIVERSIFIED CONSTRUCTION SPECIALISTS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,191.93",
          "BranchNumber": 8,
          "SubmitDate": "01-17-2020",
          "ClientonJob": "DIVERSIFIED CONSTRUCTION SPECIALISTS INC"
        },
        {
          "WorkOrder": 3994819,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Pace Pontiac",
          "JobNumber": "502-815630-229",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "823 Golf Dr",
          "JobAddress2": "",
          "JobFullAddress": "823 Golf Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "01-17-2020",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3994817,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PEREGRINE DISPENCERY",
          "JobNumber": "502-1601354-5",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3075 NE PEREGRINE DR",
          "JobAddress2": "",
          "JobFullAddress": "3075 NE PEREGRINE DR",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONST & CONSULTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$120,006.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "01-17-2020",
          "ClientonJob": "KEYSTONE CONST & CONSULTING LLC"
        },
        {
          "WorkOrder": 3994816,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 2900 DIVISION",
          "JobNumber": "502-1601354-3",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2900 DIVISION AVE SOUTH",
          "JobAddress2": "",
          "JobFullAddress": "2900 DIVISION AVE SOUTH",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONST & CONSULTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,071.00",
          "BranchNumber": 5,
          "SubmitDate": "01-17-2020",
          "ClientonJob": "KEYSTONE CONST & CONSULTING LLC"
        },
        {
          "WorkOrder": 3994815,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GOOD SAMARITAN RESC",
          "JobNumber": "502-12150-13",
          "FirstdayontheJob": "01-16-2020",
          "LastdayontheJob": "01-16-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "713 9TH ST",
          "JobAddress2": "",
          "JobFullAddress": "713 9TH ST",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "SERENUS JOHNSON",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,091.79",
          "BranchNumber": 1,
          "SubmitDate": "01-17-2020",
          "ClientonJob": "SERENUS JOHNSON"
        },
        {
          "WorkOrder": 3994551,
          "ParentWorkOrder": 3938551,
          "JobName": "(NOF)HENRY FORD AH JACKSO",
          "JobNumber": "502-933550-165",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "205 N EAST AVE",
          "JobAddress2": "",
          "JobFullAddress": "205 N EAST AVE",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$245.36",
          "BranchNumber": 9,
          "SubmitDate": "01-16-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3994550,
          "ParentWorkOrder": 3976298,
          "JobName": "(NOF) HFAH HYBRID O/R",
          "JobNumber": "502-715336-7",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "205 N. EAST AVE",
          "JobAddress2": "",
          "JobFullAddress": "205 N. EAST AVE",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Walker Commercial Interiors",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$78.72",
          "BranchNumber": 7,
          "SubmitDate": "01-16-2020",
          "ClientonJob": "Walker Commercial Interiors"
        },
        {
          "WorkOrder": 3994513,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Autozone",
          "JobNumber": "502-1304186-55",
          "FirstdayontheJob": "01-15-2020",
          "LastdayontheJob": "01-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2249 N. Canton Center Rd",
          "JobAddress2": "",
          "JobFullAddress": "2249 N. Canton Center Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,975.52",
          "BranchNumber": 13,
          "SubmitDate": "01-16-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3994512,
          "ParentWorkOrder": "",
          "JobName": "NOFWHEATLAND CROSSING APT",
          "JobNumber": "65-2136289-5",
          "FirstdayontheJob": "01-15-2020",
          "LastdayontheJob": "01-15-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "220 N WHEATLAND AVE",
          "JobAddress2": "",
          "JobFullAddress": "220 N WHEATLAND AVE",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "SOWERS COMMERCIAL, LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$7,538.24",
          "BranchNumber": 9,
          "SubmitDate": "01-16-2020",
          "ClientonJob": "SOWERS COMMERCIAL, LLC"
        },
        {
          "WorkOrder": 3994132,
          "ParentWorkOrder": 3980176,
          "JobName": "(NOF) EMAGINE CANTON",
          "JobNumber": "502-944100-327",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1525 SUPERIOR PARKWAY",
          "JobAddress2": "",
          "JobFullAddress": "1525 SUPERIOR PARKWAY",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,523.77",
          "BranchNumber": 9,
          "SubmitDate": "01-15-2020",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3993906,
          "ParentWorkOrder": 3972352,
          "JobName": "(NOF)Kroger Plymouth",
          "JobNumber": "502-20010-26",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15455 N Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "15455 N Haggerty Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "01-14-2020",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 3993734,
          "ParentWorkOrder": 3974430,
          "JobName": "(NOF)TACO BELL",
          "JobNumber": "502-920300-90",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7931 LAPEER RD",
          "JobAddress2": "",
          "JobFullAddress": "7931 LAPEER RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$5,157.92",
          "BranchNumber": 9,
          "SubmitDate": "01-14-2020",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 3993730,
          "ParentWorkOrder": 3974432,
          "JobName": "(NOF)MARQUETTE BLG #19087",
          "JobNumber": "502-933550-179",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "243 W.CONGRESS ST",
          "JobAddress2": "",
          "JobFullAddress": "243 W.CONGRESS ST",
          "JobCounty": "wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$14,652.53",
          "BranchNumber": 9,
          "SubmitDate": "01-14-2020",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3993711,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Northpointe OB/GYN",
          "JobNumber": "502-855801-17",
          "FirstdayontheJob": "01-13-2020",
          "LastdayontheJob": "01-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1206 Washington Ave",
          "JobAddress2": "",
          "JobFullAddress": "1206 Washington Ave",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "BEEM CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$11,607.71",
          "BranchNumber": 11,
          "SubmitDate": "01-14-2020",
          "ClientonJob": "BEEM CONSTRUCTION"
        },
        {
          "WorkOrder": 3993710,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RANCH - HARTLAND",
          "JobNumber": "502-910400-148",
          "FirstdayontheJob": "01-13-2020",
          "LastdayontheJob": "01-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "286 S TIPSICO LAKE RD",
          "JobAddress2": "",
          "JobFullAddress": "286 S TIPSICO LAKE RD",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACTION DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,297.67",
          "BranchNumber": 9,
          "SubmitDate": "01-14-2020",
          "ClientonJob": "ACTION DRYWALL"
        },
        {
          "WorkOrder": 3993709,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Route One 2nd&3rd Fl",
          "JobNumber": "502-843701-207",
          "FirstdayontheJob": "01-13-2020",
          "LastdayontheJob": "01-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31500 Northwestern Hwy",
          "JobAddress2": "",
          "JobFullAddress": "31500 Northwestern Hwy",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$2,739.75",
          "BranchNumber": 8,
          "SubmitDate": "01-14-2020",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 3993708,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TERRAPIN CARE",
          "JobNumber": "502-1601354-4",
          "FirstdayontheJob": "01-13-2020",
          "LastdayontheJob": "01-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2055 OAK INDUSTRIAL DR NE",
          "JobAddress2": "",
          "JobFullAddress": "2055 OAK INDUSTRIAL DR NE",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONST & CONSULTING LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$120,006.00",
          "BalanceRemaining": "$1,818.12",
          "BranchNumber": 5,
          "SubmitDate": "01-14-2020",
          "ClientonJob": "KEYSTONE CONST & CONSULTING LLC"
        },
        {
          "WorkOrder": 3993707,
          "ParentWorkOrder": "",
          "JobName": "(NOF)44th ST",
          "JobNumber": "502-1601354-2",
          "FirstdayontheJob": "01-13-2020",
          "LastdayontheJob": "01-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2301 44TH ST SE",
          "JobAddress2": "",
          "JobFullAddress": "2301 44TH ST SE",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONST & CONSULTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,815.03",
          "BranchNumber": 5,
          "SubmitDate": "01-14-2020",
          "ClientonJob": "KEYSTONE CONST & CONSULTING LLC"
        },
        {
          "WorkOrder": 3993706,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BEZERK BREWERY",
          "JobNumber": "502-50435-62",
          "FirstdayontheJob": "01-13-2020",
          "LastdayontheJob": "01-13-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1442 WASHINGTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "1442 WASHINGTON AVE",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR BOUWKAMP & ASSOC. INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "01-14-2020",
          "ClientonJob": "JR BOUWKAMP & ASSOC. INC"
        },
        {
          "WorkOrder": 3992283,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Imagination Station",
          "JobNumber": "502-1308300-4",
          "FirstdayontheJob": "01-07-2020",
          "LastdayontheJob": "01-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Discovery Way",
          "JobAddress2": "",
          "JobFullAddress": "1 Discovery Way",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "THE SPIEKER COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "01-08-2020",
          "ClientonJob": "THE SPIEKER COMPANY"
        },
        {
          "WorkOrder": 3992282,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Camp Courageous",
          "JobNumber": "502-1302600-8",
          "FirstdayontheJob": "01-07-2020",
          "LastdayontheJob": "01-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12701 Waterville-Swanton Rd",
          "JobAddress2": "",
          "JobFullAddress": "12701 Waterville-Swanton Rd",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "THE DOTSON COMPANY, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,152.00",
          "BranchNumber": 13,
          "SubmitDate": "01-08-2020",
          "ClientonJob": "THE DOTSON COMPANY, INC."
        },
        {
          "WorkOrder": 3992281,
          "ParentWorkOrder": "",
          "JobName": "NOF-BRETHREN RETIREMENT",
          "JobNumber": "65-502280-63",
          "FirstdayontheJob": "01-07-2020",
          "LastdayontheJob": "01-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "750 CHESTNUT STREET",
          "JobAddress2": "",
          "JobFullAddress": "750 CHESTNUT STREET",
          "JobCounty": "DARKE",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALTIES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$1,333.20",
          "BranchNumber": 13,
          "SubmitDate": "01-08-2020",
          "ClientonJob": "COMBS INTERIOR SPECIALTIES INC"
        },
        {
          "WorkOrder": 3992280,
          "ParentWorkOrder": "",
          "JobName": "NOFDiscount Tire Regional",
          "JobNumber": "65-2134697-10",
          "FirstdayontheJob": "01-07-2020",
          "LastdayontheJob": "01-07-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5 Spiral Dr",
          "JobAddress2": "",
          "JobFullAddress": "5 Spiral Dr",
          "JobCounty": "",
          "JobState": "Kentucky",
          "CustomerCompanyName": "J.F. BRAKE CONTRACTOR",
          "RequestType": "Notice of Intent to File a Lien (Commercial)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "On Hold",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$149.80",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "J.F. BRAKE CONTRACTOR"
        },
        {
          "WorkOrder": 3991070,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 3 Star Financial",
          "JobNumber": "502-858231-48",
          "FirstdayontheJob": "01-02-2020",
          "LastdayontheJob": "01-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22522 East Nine Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "22522 East Nine Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$21,498.69",
          "BranchNumber": 11,
          "SubmitDate": "01-03-2020",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 3991069,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LIVWELL OFFICE RENO",
          "JobNumber": "502-1507798-34",
          "FirstdayontheJob": "01-02-2020",
          "LastdayontheJob": "01-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25190 HOOVER RD",
          "JobAddress2": "",
          "JobFullAddress": "25190 HOOVER RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,173.82",
          "BranchNumber": 9,
          "SubmitDate": "01-03-2020",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3991068,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Banner Sign",
          "JobNumber": "502-916315-89",
          "FirstdayontheJob": "01-02-2020",
          "LastdayontheJob": "01-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6538 Russell Street",
          "JobAddress2": "",
          "JobFullAddress": "6538 Russell Street",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "01-03-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3991067,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Detroit Taco",
          "JobNumber": "502-1605101-8",
          "FirstdayontheJob": "01-02-2020",
          "LastdayontheJob": "01-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50767 Corporate Drive",
          "JobAddress2": "",
          "JobFullAddress": "50767 Corporate Drive",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "ARCHITECTURAL PLANNERS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "01-03-2020",
          "ClientonJob": "ARCHITECTURAL PLANNERS"
        },
        {
          "WorkOrder": 3991066,
          "ParentWorkOrder": "",
          "JobName": "(NOF)La-Z-Boy Furniture",
          "JobNumber": "502-877300-32",
          "FirstdayontheJob": "01-02-2020",
          "LastdayontheJob": "01-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4815 S. Baldwin Rd",
          "JobAddress2": "",
          "JobFullAddress": "4815 S. Baldwin Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR DESIGNS INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,942.88",
          "BranchNumber": 8,
          "SubmitDate": "01-03-2020",
          "ClientonJob": "SUPERIOR DESIGNS INC."
        },
        {
          "WorkOrder": 3991065,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Royal Park Hotel",
          "JobNumber": "502-824750-3",
          "FirstdayontheJob": "01-02-2020",
          "LastdayontheJob": "01-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "600 E University Dr",
          "JobAddress2": "",
          "JobFullAddress": "600 E University Dr",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "FRANK REWOLD & SONS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,060.00",
          "BalanceRemaining": "$4,078.48",
          "BranchNumber": 8,
          "SubmitDate": "01-03-2020",
          "ClientonJob": "FRANK REWOLD & SONS"
        },
        {
          "WorkOrder": 3991064,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Ann Arbor Rd.",
          "JobNumber": "502-1603768-4",
          "FirstdayontheJob": "01-02-2020",
          "LastdayontheJob": "01-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4100 Ann Arbor Rd.",
          "JobAddress2": "",
          "JobFullAddress": "4100 Ann Arbor Rd.",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "Merit Commercial Cont LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$45,734.88",
          "BranchNumber": 7,
          "SubmitDate": "01-03-2020",
          "ClientonJob": "Merit Commercial Cont LLC"
        },
        {
          "WorkOrder": 3991052,
          "ParentWorkOrder": 3984432,
          "JobName": "(NOF)Rair Tower",
          "JobNumber": "502-916315-149",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3772 Wilder Rd.",
          "JobAddress2": "",
          "JobFullAddress": "3772 Wilder Rd.",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,827.64",
          "BranchNumber": 9,
          "SubmitDate": "01-03-2020",
          "ClientonJob": "INDEPENDENCE COMM.CONSTRUCTION"
        },
        {
          "WorkOrder": 3990951,
          "ParentWorkOrder": "",
          "JobName": "NOF WINTON WOODS NEW",
          "JobNumber": "65-2135214-6",
          "FirstdayontheJob": "01-02-2020",
          "LastdayontheJob": "01-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "147 FARRAGUT RD",
          "JobAddress2": "PK-6",
          "JobFullAddress": "147 FARRAGUT RD PK-6",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "AKA CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$106,006.00",
          "BalanceRemaining": "$18,643.68",
          "BranchNumber": 13,
          "SubmitDate": "01-03-2020",
          "ClientonJob": "AKA CONSTRUCTION INC"
        },
        {
          "WorkOrder": 3990950,
          "ParentWorkOrder": "",
          "JobName": "NOF LaSoupe",
          "JobNumber": "65-510590-42",
          "FirstdayontheJob": "01-02-2020",
          "LastdayontheJob": "01-02-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "915 East McMillan St",
          "JobAddress2": "",
          "JobFullAddress": "915 East McMillan St",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,928.68",
          "BranchNumber": 2,
          "SubmitDate": "01-03-2020",
          "ClientonJob": "TRIVERSITY CRAFT FORCE LLC"
        },
        {
          "WorkOrder": 3990941,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Valley Hall",
          "JobNumber": "502-1611204-31",
          "FirstdayontheJob": "01-01-2020",
          "LastdayontheJob": "01-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "105 Valley Hall Dr",
          "JobAddress2": "",
          "JobFullAddress": "105 Valley Hall Dr",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "JR Interiors",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$5,733.56",
          "BranchNumber": 13,
          "SubmitDate": "01-02-2020",
          "ClientonJob": "JR Interiors"
        },
        {
          "WorkOrder": 3990940,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Superior",
          "JobNumber": "502-1399009-4",
          "FirstdayontheJob": "01-01-2020",
          "LastdayontheJob": "01-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "155 S. Superior St.",
          "JobAddress2": "",
          "JobFullAddress": "155 S. Superior St.",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Canaan Construction Inc.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,426.98",
          "BranchNumber": 13,
          "SubmitDate": "01-02-2020",
          "ClientonJob": "Canaan Construction Inc."
        },
        {
          "WorkOrder": 3990939,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Nexus Engineering",
          "JobNumber": "502-1309100-18",
          "FirstdayontheJob": "01-01-2020",
          "LastdayontheJob": "01-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "480 W Dussel Drive",
          "JobAddress2": "2nd Floor",
          "JobFullAddress": "480 W Dussel Drive 2nd Floor",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "VAN TASSEL CONSTRUCTION CORP.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$55,006.00",
          "BalanceRemaining": "$19,768.48",
          "BranchNumber": 13,
          "SubmitDate": "01-02-2020",
          "ClientonJob": "VAN TASSEL CONSTRUCTION CORP."
        },
        {
          "WorkOrder": 3990938,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NW Ohio Psych Hosp",
          "JobNumber": "502-1304700-19",
          "FirstdayontheJob": "01-01-2020",
          "LastdayontheJob": "01-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "930 S. Detroit Ave",
          "JobAddress2": "",
          "JobFullAddress": "930 S. Detroit Ave",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "LATHROP COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$9,203.77",
          "BranchNumber": 13,
          "SubmitDate": "01-02-2020",
          "ClientonJob": "LATHROP COMPANY"
        },
        {
          "WorkOrder": 3990937,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Autozone",
          "JobNumber": "502-1304186-54",
          "FirstdayontheJob": "01-01-2020",
          "LastdayontheJob": "01-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1846 E. Perry St.",
          "JobAddress2": "",
          "JobFullAddress": "1846 E. Perry St.",
          "JobCounty": "OTTAWA",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$4,990.08",
          "BranchNumber": 13,
          "SubmitDate": "01-02-2020",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3990935,
          "ParentWorkOrder": "",
          "JobName": "NOF 1830 ELM STREETT",
          "JobNumber": "65-2134789-11",
          "FirstdayontheJob": "01-01-2020",
          "LastdayontheJob": "01-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "MARKET SQUARE 3",
          "JobAddress2": "",
          "JobFullAddress": "MARKET SQUARE 3",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "COX DRYWALL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$783.40",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "COX DRYWALL"
        },
        {
          "WorkOrder": 3990934,
          "ParentWorkOrder": "",
          "JobName": "(NOF) O. U. CREDIT",
          "JobNumber": "65-484402-7",
          "FirstdayontheJob": "01-01-2020",
          "LastdayontheJob": "01-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "90 S Shafer St",
          "JobAddress2": "",
          "JobFullAddress": "90 S Shafer St",
          "JobCounty": "ATHENS",
          "JobState": "Ohio",
          "CustomerCompanyName": "LCS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 4,
          "SubmitDate": "01-02-2020",
          "ClientonJob": "LCS LLC"
        },
        {
          "WorkOrder": 3990933,
          "ParentWorkOrder": "",
          "JobName": "NOF CROSSING AT THE PARK",
          "JobNumber": "65-582260-2",
          "FirstdayontheJob": "01-01-2020",
          "LastdayontheJob": "01-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "510 SALZBERG LANE",
          "JobAddress2": "",
          "JobFullAddress": "510 SALZBERG LANE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "QUALITY CONST RESOURCES",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,202.06",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "QUALITY CONST RESOURCES"
        },
        {
          "WorkOrder": 3990932,
          "ParentWorkOrder": "",
          "JobName": "(NOF) KROGER A-951",
          "JobNumber": "65-506275-3",
          "FirstdayontheJob": "01-01-2020",
          "LastdayontheJob": "01-01-2020",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2917 W ALEX BELL RD",
          "JobAddress2": "",
          "JobFullAddress": "2917 W ALEX BELL RD",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "MDG ENTERPRISES",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$19,220.36",
          "BranchNumber": 2,
          "SubmitDate": "01-02-2020",
          "ClientonJob": "MDG ENTERPRISES"
        },
        {
          "WorkOrder": 3990842,
          "ParentWorkOrder": 3975107,
          "JobName": "(NOF)TACO BELL WARREN",
          "JobNumber": "502-920300-85",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13343 E. 10 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "13343 E. 10 MILE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,606.00",
          "BalanceRemaining": "$5,288.64",
          "BranchNumber": 9,
          "SubmitDate": "01-02-2020",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 3989987,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WOODSPRING SUITES",
          "JobNumber": "502-811591-9",
          "FirstdayontheJob": "12-26-2019",
          "LastdayontheJob": "12-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32401 MALLY DR",
          "JobAddress2": "",
          "JobFullAddress": "32401 MALLY DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Fortney & Weygandt, Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$1,239.99",
          "BranchNumber": 11,
          "SubmitDate": "12-27-2019",
          "ClientonJob": "Fortney & Weygandt, Inc."
        },
        {
          "WorkOrder": 3989986,
          "ParentWorkOrder": "",
          "JobName": "(NOF) PRIMROSE SCHOOL",
          "JobNumber": "502-1507798-33",
          "FirstdayontheJob": "12-26-2019",
          "LastdayontheJob": "12-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45215 PRIMROSE LN",
          "JobAddress2": "",
          "JobFullAddress": "45215 PRIMROSE LN",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,372.49",
          "BranchNumber": 9,
          "SubmitDate": "12-27-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3989985,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1596 CATH LAB 1",
          "JobNumber": "502-913820-558",
          "FirstdayontheJob": "12-26-2019",
          "LastdayontheJob": "12-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16000 W NINE MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "16000 W NINE MILE RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$45.30",
          "BranchNumber": 9,
          "SubmitDate": "12-27-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3989984,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Foot Action Fairlane",
          "JobNumber": "502-1601400-2",
          "FirstdayontheJob": "12-26-2019",
          "LastdayontheJob": "12-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18900 Michigan Ave.",
          "JobAddress2": "Space G-310",
          "JobFullAddress": "18900 Michigan Ave. Space G-310",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "HEALY CONST SVCS INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$18,286.68",
          "BranchNumber": 8,
          "SubmitDate": "12-27-2019",
          "ClientonJob": "HEALY CONST SVCS INC"
        },
        {
          "WorkOrder": 3989983,
          "ParentWorkOrder": "",
          "JobName": "(NOF) IHA Clinton Primary",
          "JobNumber": "502-825063-53",
          "FirstdayontheJob": "12-26-2019",
          "LastdayontheJob": "12-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11775 Tecumseh-Clinton Rd",
          "JobAddress2": "",
          "JobFullAddress": "11775 Tecumseh-Clinton Rd",
          "JobCounty": "LENAWEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHAFFER INCORPORATED",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "12-27-2019",
          "ClientonJob": "SHAFFER INCORPORATED"
        },
        {
          "WorkOrder": 3989982,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DENENBURG TUFFLEY",
          "JobNumber": "502-815630-228",
          "FirstdayontheJob": "12-26-2019",
          "LastdayontheJob": "12-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "28411 NORTHWESTERN HWY.",
          "JobAddress2": "SUITE 670",
          "JobFullAddress": "28411 NORTHWESTERN HWY. SUITE 670",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "12-27-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3989981,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9060 StAnne Conve",
          "JobNumber": "502-815250-349",
          "FirstdayontheJob": "12-26-2019",
          "LastdayontheJob": "12-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2630 W. Lafyette Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2630 W. Lafyette Blvd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,843.60",
          "BranchNumber": 8,
          "SubmitDate": "12-27-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3989980,
          "ParentWorkOrder": "",
          "JobName": "(NOF) URBAN AIR",
          "JobNumber": "502-1637077-2",
          "FirstdayontheJob": "12-26-2019",
          "LastdayontheJob": "12-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12331 JAMES ST",
          "JobAddress2": "",
          "JobFullAddress": "12331 JAMES ST",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "INTEGRITY D & P, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$16,907.74",
          "BranchNumber": 5,
          "SubmitDate": "12-27-2019",
          "ClientonJob": "INTEGRITY D & P, LLC"
        },
        {
          "WorkOrder": 3989979,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Funeral Home",
          "JobNumber": "502-10360-2",
          "FirstdayontheJob": "12-26-2019",
          "LastdayontheJob": "12-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "821 N Pine Rd",
          "JobAddress2": "",
          "JobFullAddress": "821 N Pine Rd",
          "JobCounty": "Bay",
          "JobState": "Michigan",
          "CustomerCompanyName": "BEAGLE CONSTRUCTION,LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "12-27-2019",
          "ClientonJob": "BEAGLE CONSTRUCTION,LLC"
        },
        {
          "WorkOrder": 3988500,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WOODSPRING SUITES",
          "JobNumber": "502-811591-8",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22000 HAGGERTY RD",
          "JobAddress2": "",
          "JobFullAddress": "22000 HAGGERTY RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Fortney & Weygandt, Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$4,715.37",
          "BranchNumber": 11,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "Fortney & Weygandt, Inc."
        },
        {
          "WorkOrder": 3988499,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hisshu Sushi",
          "JobNumber": "502-916315-151",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8650  W Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "8650  W Grand River Ave",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,401.54",
          "BranchNumber": 9,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3988498,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Baracade",
          "JobNumber": "502-916315-148",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "666 Selden St",
          "JobAddress2": "",
          "JobFullAddress": "666 Selden St",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,751.45",
          "BranchNumber": 9,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3988497,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ANN ARBOR TRAIL",
          "JobNumber": "502-910400-147",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41691 E ANN ARBOR TRAIL",
          "JobAddress2": "",
          "JobFullAddress": "41691 E ANN ARBOR TRAIL",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACTION DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "ACTION DRYWALL"
        },
        {
          "WorkOrder": 3988495,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Yoga 6",
          "JobNumber": "502-1605101-6",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33340 W. 14 Mile Rd.",
          "JobAddress2": "",
          "JobFullAddress": "33340 W. 14 Mile Rd.",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ARCHITECTURAL PLANNERS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$883.09",
          "BranchNumber": 8,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "ARCHITECTURAL PLANNERS"
        },
        {
          "WorkOrder": 3988494,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Red Lobster",
          "JobNumber": "502-875466-7",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1420 S Main Street",
          "JobAddress2": "",
          "JobFullAddress": "1420 S Main Street",
          "JobCounty": "Lenawee",
          "JobState": "Michigan",
          "CustomerCompanyName": "Facilities Management Gro",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$7,402.70",
          "BranchNumber": 8,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "Facilities Management Gro"
        },
        {
          "WorkOrder": 3988493,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Planet Fitness",
          "JobNumber": "502-811800-68",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5142 Highland Rd",
          "JobAddress2": "",
          "JobFullAddress": "5142 Highland Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "BHD BUILDERS, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "BHD BUILDERS, INC"
        },
        {
          "WorkOrder": 3988492,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Eaton Corp",
          "JobNumber": "502-968000-2",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "300  S. East Ave.",
          "JobAddress2": "",
          "JobFullAddress": "300  S. East Ave.",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "MILLER WALLBOARD",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,705.44",
          "BranchNumber": 7,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "MILLER WALLBOARD"
        },
        {
          "WorkOrder": 3988491,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DOUBLETREEREMODBAYCT",
          "JobNumber": "502-1610900-2",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 WENONAH PARK PL",
          "JobAddress2": "",
          "JobFullAddress": "1 WENONAH PARK PL",
          "JobCounty": "Bay",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACTION SERVICE GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$756.41",
          "BranchNumber": 1,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "ACTION SERVICE GROUP"
        },
        {
          "WorkOrder": 3988470,
          "ParentWorkOrder": 3976307,
          "JobName": "(NOF)3306 Auburn Rd(billing) 3300 Auburn Rd(site)",
          "JobNumber": "502-1163306-2",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3300 Auburn Rd",
          "JobAddress2": "",
          "JobFullAddress": "3300 Auburn Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DHCM",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$14,062.63",
          "BranchNumber": 8,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "DHCM"
        },
        {
          "WorkOrder": 3988382,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Valley Hall",
          "JobNumber": "502-1611204-31",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "105 Valley Hall Dr",
          "JobAddress2": "",
          "JobFullAddress": "105 Valley Hall Dr",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "JR Interiors",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$5,733.56",
          "BranchNumber": 13,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "JR Interiors"
        },
        {
          "WorkOrder": 3988381,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Nexus Engineering",
          "JobNumber": "502-1309100-18",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "480 W Dussel Drive",
          "JobAddress2": "2nd Floor",
          "JobFullAddress": "480 W Dussel Drive 2nd Floor",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "VAN TASSEL CONSTR. CORP.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$55,006.00",
          "BalanceRemaining": "$16,291.89",
          "BranchNumber": 13,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "VAN TASSEL CONSTR. CORP."
        },
        {
          "WorkOrder": 3988380,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NW Ohio Psych Hosp",
          "JobNumber": "502-1304700-19",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "930 S. Detroit Ave",
          "JobAddress2": "",
          "JobFullAddress": "930 S. Detroit Ave",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "LATHROP COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$9,203.77",
          "BranchNumber": 13,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "LATHROP COMPANY"
        },
        {
          "WorkOrder": 3988379,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FAIRFIELD INN NORTH",
          "JobNumber": "65-2137179-14",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7072 YORK CENTER DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "7072 YORK CENTER DRIVE",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "DURANGO DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,000.00",
          "BalanceRemaining": "$1,816.54",
          "BranchNumber": 13,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "DURANGO DRYWALL LLC"
        },
        {
          "WorkOrder": 3988378,
          "ParentWorkOrder": "",
          "JobName": "NOF Reibold 5th Floor",
          "JobNumber": "65-502280-61",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "117 S Main St",
          "JobAddress2": "",
          "JobFullAddress": "117 S Main St",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALITIES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$1,112.20",
          "BranchNumber": 13,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "COMBS INTERIOR SPECIALITIES INC"
        },
        {
          "WorkOrder": 3988377,
          "ParentWorkOrder": "",
          "JobName": "NOF McALISTER'S DELI",
          "JobNumber": "65-130350-3",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4560 CORNELL RD.",
          "JobAddress2": "",
          "JobFullAddress": "4560 CORNELL RD.",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "DESIGN TEAM SIGN COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$6,723.83",
          "BranchNumber": 13,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "DESIGN TEAM SIGN COMPANY"
        },
        {
          "WorkOrder": 3988376,
          "ParentWorkOrder": "",
          "JobName": "NOF Avondale Tenants",
          "JobNumber": "65-510300-52",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3559 Reading Rd",
          "JobAddress2": "",
          "JobFullAddress": "3559 Reading Rd",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,700.86",
          "BranchNumber": 2,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 3988375,
          "ParentWorkOrder": "",
          "JobName": "NOF Noohra Labs",
          "JobNumber": "65-55219-11",
          "FirstdayontheJob": "12-18-2019",
          "LastdayontheJob": "12-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3700 Inpark Circle",
          "JobAddress2": "",
          "JobFullAddress": "3700 Inpark Circle",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "Lemcer Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$12,713.40",
          "BranchNumber": 2,
          "SubmitDate": "12-19-2019",
          "ClientonJob": "Lemcer Construction LLC"
        },
        {
          "WorkOrder": 3986874,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dollar Gen.- Lapeer",
          "JobNumber": "502-864751-212",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1926 N Saginaw St",
          "JobAddress2": "",
          "JobFullAddress": "1926 N Saginaw St",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "STILES DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$2,330.64",
          "BranchNumber": 11,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "STILES DRYWALL LLC"
        },
        {
          "WorkOrder": 3986873,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Panda Express-Troy",
          "JobNumber": "502-858231-49",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3110 Rochester Rd",
          "JobAddress2": "",
          "JobFullAddress": "3110 Rochester Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,808.40",
          "BranchNumber": 11,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 3986872,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LIVWELL",
          "JobNumber": "502-1507798-32",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21550 - 21590 HOOVER RD",
          "JobAddress2": "",
          "JobFullAddress": "21550 - 21590 HOOVER RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3986871,
          "ParentWorkOrder": "",
          "JobName": "(NOF)IHA - PICKNEY",
          "JobNumber": "502-1507798-31",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10200 DEXTER/PINCKNEY RD",
          "JobAddress2": "",
          "JobFullAddress": "10200 DEXTER/PINCKNEY RD",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$8,549.63",
          "BranchNumber": 9,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3986870,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SME LAB",
          "JobNumber": "502-941950-10",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "43980 Plymouth Oaks Blvd",
          "JobAddress2": "",
          "JobFullAddress": "43980 Plymouth Oaks Blvd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "GREAT LAKES CEILING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,483.23",
          "BranchNumber": 9,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "GREAT LAKES CEILING"
        },
        {
          "WorkOrder": 3986869,
          "ParentWorkOrder": "",
          "JobName": "(NOF)U OF M RUTHVEN 19100",
          "JobNumber": "502-933550-181",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1109 GEDDES AVE",
          "JobAddress2": "",
          "JobFullAddress": "1109 GEDDES AVE",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$180,006.00",
          "BalanceRemaining": "$1,079.89",
          "BranchNumber": 9,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3986868,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BJ'S Warehouse",
          "JobNumber": "502-1604171-5",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45101 Towne center Blvd",
          "JobAddress2": "",
          "JobFullAddress": "45101 Towne center Blvd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 3986867,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9083 DTE GO Stand",
          "JobNumber": "502-815250-358",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "One Energy Plaza",
          "JobAddress2": "",
          "JobFullAddress": "One Energy Plaza",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3986866,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Sky Club A41",
          "JobNumber": "502-813200-135",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9000 Middlebelt Rd",
          "JobAddress2": "",
          "JobFullAddress": "9000 Middlebelt Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$25,077.71",
          "BranchNumber": 8,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3986865,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HOLT MSU FCU",
          "JobNumber": "502-810150-50",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2313 Cedar St.",
          "JobAddress2": "",
          "JobFullAddress": "2313 Cedar St.",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTIC CEIL & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$20,051.27",
          "BranchNumber": 8,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "ACOUSTIC CEIL & PART"
        },
        {
          "WorkOrder": 3986864,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TCCC Job",
          "JobNumber": "502-94091-20",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1420 East Ten Mile Road",
          "JobAddress2": "Suite 260",
          "JobFullAddress": "1420 East Ten Mile Road Suite 260",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "EJ Kaneris Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,778.76",
          "BranchNumber": 8,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "EJ Kaneris Inc"
        },
        {
          "WorkOrder": 3986863,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Holiday Inn Wixom",
          "JobNumber": "502-990401-55",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "48953 Alpha Dr",
          "JobAddress2": "",
          "JobFullAddress": "48953 Alpha Dr",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3986862,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HOYT LIBRARY",
          "JobNumber": "502-12150-12",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "505 JANES AVE",
          "JobAddress2": "",
          "JobFullAddress": "505 JANES AVE",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "SERENUS JOHNSON",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$589.68",
          "BranchNumber": 1,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "SERENUS JOHNSON"
        },
        {
          "WorkOrder": 3986861,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ENCOMPASS THERAPY",
          "JobNumber": "502-11150-4",
          "FirstdayontheJob": "12-11-2019",
          "LastdayontheJob": "12-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3710 KATALIN CT",
          "JobAddress2": "",
          "JobFullAddress": "3710 KATALIN CT",
          "JobCounty": "Bay",
          "JobState": "Michigan",
          "CustomerCompanyName": "J.R. DAHN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,563.50",
          "BranchNumber": 1,
          "SubmitDate": "12-12-2019",
          "ClientonJob": "J.R. DAHN CONSTRUCTION"
        },
        {
          "WorkOrder": 3986190,
          "ParentWorkOrder": "",
          "JobName": "NOF Union Station",
          "JobNumber": "65-551119-8",
          "FirstdayontheJob": "12-10-2019",
          "LastdayontheJob": "12-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1725 Cleneay Ave",
          "JobAddress2": "",
          "JobFullAddress": "1725 Cleneay Ave",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "RELIANCE  CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$481.50",
          "BranchNumber": 2,
          "SubmitDate": "12-11-2019",
          "ClientonJob": "RELIANCE  CONSTRUCTION"
        },
        {
          "WorkOrder": 3985417,
          "ParentWorkOrder": 3972353,
          "JobName": "(NOF)Redford Dist. Court 17th Judicial",
          "JobNumber": "502-814500-81",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15111 Beech Daly Rd",
          "JobAddress2": "",
          "JobFullAddress": "15111 Beech Daly Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONST",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$17,343.68",
          "BranchNumber": 8,
          "SubmitDate": "12-09-2019",
          "ClientonJob": "COMMERCIAL INT CONST"
        },
        {
          "WorkOrder": 3985401,
          "ParentWorkOrder": 3976302,
          "JobName": "(NOF)19-9066 DTE 8th Flr",
          "JobNumber": "502-815250-354",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "One Energy Plaza",
          "JobAddress2": "FL 8th WCB",
          "JobFullAddress": "One Energy Plaza FL 8th WCB",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$65,006.00",
          "BalanceRemaining": "$4,623.58",
          "BranchNumber": 8,
          "SubmitDate": "12-09-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3984440,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Swanton Creek Apts.",
          "JobNumber": "502-1160130-2",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "105 N Munson Rd",
          "JobAddress2": "",
          "JobFullAddress": "105 N Munson Rd",
          "JobCounty": "Fulton",
          "JobState": "Ohio",
          "CustomerCompanyName": "SOWERS COMMERCIAL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$10,261.56",
          "BranchNumber": 13,
          "SubmitDate": "12-05-2019",
          "ClientonJob": "SOWERS COMMERCIAL LLC"
        },
        {
          "WorkOrder": 3984439,
          "ParentWorkOrder": "",
          "JobName": "NOF Winterguard",
          "JobNumber": "65-2135211-8",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1994 Byers Rd.",
          "JobAddress2": "",
          "JobFullAddress": "1994 Byers Rd.",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "GANE CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$978.68",
          "BranchNumber": 13,
          "SubmitDate": "12-05-2019",
          "ClientonJob": "GANE CONSTRUCTION"
        },
        {
          "WorkOrder": 3984438,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NVR",
          "JobNumber": "65-2135211-7",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1974 Byers Rd.",
          "JobAddress2": "",
          "JobFullAddress": "1974 Byers Rd.",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "GANE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,047.32",
          "BranchNumber": 13,
          "SubmitDate": "12-05-2019",
          "ClientonJob": "GANE CONSTRUCTION"
        },
        {
          "WorkOrder": 3984437,
          "ParentWorkOrder": "",
          "JobName": "NOF Wurth Electronics",
          "JobNumber": "65-2135211-6",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1982 Byers Rd.",
          "JobAddress2": "",
          "JobFullAddress": "1982 Byers Rd.",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "GANE CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,138.10",
          "BranchNumber": 13,
          "SubmitDate": "12-05-2019",
          "ClientonJob": "GANE CONSTRUCTION"
        },
        {
          "WorkOrder": 3984436,
          "ParentWorkOrder": "",
          "JobName": "NOF The Room Place",
          "JobNumber": "65-134500-2",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2727 Fairfield Commons Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2727 Fairfield Commons Blvd",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "CML COMMERCIAL CARPENTRY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$6,697.48",
          "BranchNumber": 13,
          "SubmitDate": "12-05-2019",
          "ClientonJob": "CML COMMERCIAL CARPENTRY"
        },
        {
          "WorkOrder": 3984435,
          "ParentWorkOrder": "",
          "JobName": "NOF ST RESEARCH",
          "JobNumber": "65-131330-2",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2611 COMMONS BLVD STE 200",
          "JobAddress2": "",
          "JobFullAddress": "2611 COMMONS BLVD STE 200",
          "JobCounty": "Greene",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRUE UNITED CONTRACTORS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$8,573.60",
          "BranchNumber": 13,
          "SubmitDate": "12-05-2019",
          "ClientonJob": "TRUE UNITED CONTRACTORS LLC"
        },
        {
          "WorkOrder": 3984434,
          "ParentWorkOrder": "",
          "JobName": "NOF 1810 ELM STREET",
          "JobNumber": "65-2134789-6",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "MARKET SQUARE 3",
          "JobAddress2": "",
          "JobFullAddress": "MARKET SQUARE 3",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "COX DRYWALL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$23.90",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "COX DRYWALL"
        },
        {
          "WorkOrder": 3984433,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SHERATON HOTEL",
          "JobNumber": "502-1602218-3",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8000 MERRIMAN RD",
          "JobAddress2": "",
          "JobFullAddress": "8000 MERRIMAN RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "STX CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$2,453.41",
          "BranchNumber": 9,
          "SubmitDate": "12-05-2019",
          "ClientonJob": "STX CONSTRUCTION"
        },
        {
          "WorkOrder": 3984432,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Rair Tower",
          "JobNumber": "502-916315-149",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3772 Wilder Rd.",
          "JobAddress2": "",
          "JobFullAddress": "3772 Wilder Rd.",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,827.64",
          "BranchNumber": 9,
          "SubmitDate": "12-05-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONSTRUCTION"
        },
        {
          "WorkOrder": 3984431,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Chipotle Taylor Sout",
          "JobNumber": "502-877300-30",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23111 Eureka Rd",
          "JobAddress2": "",
          "JobFullAddress": "23111 Eureka Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR DESIGNS INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,708.82",
          "BranchNumber": 8,
          "SubmitDate": "12-05-2019",
          "ClientonJob": "SUPERIOR DESIGNS INC."
        },
        {
          "WorkOrder": 3984430,
          "ParentWorkOrder": "",
          "JobName": "(NOF)U Form",
          "JobNumber": "502-843701-205",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1945 Marston St",
          "JobAddress2": "",
          "JobFullAddress": "1945 Marston St",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$9,988.42",
          "BranchNumber": 8,
          "SubmitDate": "12-05-2019",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 3984429,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Belle Isle DNR",
          "JobNumber": "502-817800-44",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "99 Pleasure Dr.",
          "JobAddress2": "Belle Isle Park",
          "JobFullAddress": "99 Pleasure Dr. Belle Isle Park",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,307.96",
          "BranchNumber": 8,
          "SubmitDate": "12-05-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3984428,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9077 DTE Go South",
          "JobNumber": "502-815250-357",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "One Energy Plaza",
          "JobAddress2": "South Service Entrance",
          "JobFullAddress": "One Energy Plaza South Service Entrance",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,578.48",
          "BranchNumber": 8,
          "SubmitDate": "12-05-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3984427,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Eaton Source Office",
          "JobNumber": "502-967400-13",
          "FirstdayontheJob": "12-04-2019",
          "LastdayontheJob": "12-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "300 S. East Ave",
          "JobAddress2": "",
          "JobFullAddress": "300 S. East Ave",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "COLLINS DESIGN/BUILD",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$569.69",
          "BranchNumber": 7,
          "SubmitDate": "12-05-2019",
          "ClientonJob": "COLLINS DESIGN/BUILD"
        },
        {
          "WorkOrder": 3983868,
          "ParentWorkOrder": 3976309,
          "JobName": "(NOF)BURGER KING WOODHAVE",
          "JobNumber": "502-910621-46",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20993 WEST RD",
          "JobAddress2": "",
          "JobFullAddress": "20993 WEST RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "12-04-2019",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 3983691,
          "ParentWorkOrder": 3976314,
          "JobName": "(NOF)LaDuke Construction",
          "JobNumber": "502-859551-22",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "312 Liberty Ave",
          "JobAddress2": "",
          "JobFullAddress": "312 Liberty Ave",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "PICKLEHAUPT DRYWALL",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$7,960.36",
          "BranchNumber": 11,
          "SubmitDate": "12-03-2019",
          "ClientonJob": "PICKLEHAUPT DRYWALL"
        },
        {
          "WorkOrder": 3983510,
          "ParentWorkOrder": "",
          "JobName": "(NOF) INN @ ROMER'S",
          "JobNumber": "65-2137179-13",
          "FirstdayontheJob": "12-02-2019",
          "LastdayontheJob": "12-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "321 S. EASTERN AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "321 S. EASTERN AVENUE",
          "JobCounty": "Mercer",
          "JobState": "Ohio",
          "CustomerCompanyName": "DURANGO DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,005.00",
          "BalanceRemaining": "$4,582.21",
          "BranchNumber": 13,
          "SubmitDate": "12-03-2019",
          "ClientonJob": "DURANGO DRYWALL LLC"
        },
        {
          "WorkOrder": 3983509,
          "ParentWorkOrder": "",
          "JobName": "(NOF)THE ENCLAVE BLDG #12",
          "JobNumber": "65-2134692-6",
          "FirstdayontheJob": "12-02-2019",
          "LastdayontheJob": "12-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3800 HAUCK RD",
          "JobAddress2": "",
          "JobFullAddress": "3800 HAUCK RD",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "DUKE DRYWALL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$28,117.91",
          "BranchNumber": 12,
          "SubmitDate": "12-03-2019",
          "ClientonJob": "DUKE DRYWALL"
        },
        {
          "WorkOrder": 3983508,
          "ParentWorkOrder": "",
          "JobName": "NOF SWING FIT GOLF",
          "JobNumber": "65-510300-50",
          "FirstdayontheJob": "12-02-2019",
          "LastdayontheJob": "12-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11125 KENWOOD RD",
          "JobAddress2": "",
          "JobFullAddress": "11125 KENWOOD RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,769.50",
          "BranchNumber": 2,
          "SubmitDate": "12-03-2019",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 3983507,
          "ParentWorkOrder": "",
          "JobName": "NOF Club Pilaties",
          "JobNumber": "65-413700-2",
          "FirstdayontheJob": "12-02-2019",
          "LastdayontheJob": "12-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8924 Lyra Dr",
          "JobAddress2": "",
          "JobFullAddress": "8924 Lyra Dr",
          "JobCounty": "Delaware",
          "JobState": "Ohio",
          "CustomerCompanyName": "EZ DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$6,005.00",
          "BalanceRemaining": "$4,075.12",
          "BranchNumber": 4,
          "SubmitDate": "12-03-2019",
          "ClientonJob": "EZ DRYWALL LLC"
        },
        {
          "WorkOrder": 3983370,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Metamora Bldg",
          "JobNumber": "502-864751-210",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15300 Windmill Point Dr",
          "JobAddress2": "",
          "JobFullAddress": "15300 Windmill Point Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "STILES DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$7,652.17",
          "BranchNumber": 11,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "STILES DRYWALL LLC"
        },
        {
          "WorkOrder": 3983369,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Residents Inn",
          "JobNumber": "502-1325000-19",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44700 Hayes Rd",
          "JobAddress2": "",
          "JobFullAddress": "44700 Hayes Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$8,770.94",
          "BranchNumber": 9,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 3983368,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Roush-Particle Lab",
          "JobNumber": "502-948350-5",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12249 Levan Rd",
          "JobAddress2": "",
          "JobFullAddress": "12249 Levan Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ROUSH TECHNOLOGIES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$7,010.84",
          "BranchNumber": 9,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "ROUSH TECHNOLOGIES"
        },
        {
          "WorkOrder": 3983367,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDONLD'S FLINT",
          "JobNumber": "502-944100-325",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4131 W PIERSON RD",
          "JobAddress2": "STORE #2553",
          "JobFullAddress": "4131 W PIERSON RD STORE #2553",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,506.00",
          "BalanceRemaining": "$2,158.57",
          "BranchNumber": 9,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3983366,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDONALD'S",
          "JobNumber": "502-944100-307",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3212 CLIO RD",
          "JobAddress2": "",
          "JobFullAddress": "3212 CLIO RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$1,339.29",
          "BranchNumber": 9,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3983365,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DEARBORN AAA",
          "JobNumber": "502-920000-28",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15500 LUNDY PARKWAY",
          "JobAddress2": "",
          "JobFullAddress": "15500 LUNDY PARKWAY",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NVR FINISHES, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$6,048.43",
          "BranchNumber": 9,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "NVR FINISHES, INC."
        },
        {
          "WorkOrder": 3983364,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Vitron",
          "JobNumber": "502-916425-4",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3250 W. Big Beaver Rd.",
          "JobAddress2": "",
          "JobFullAddress": "3250 W. Big Beaver Rd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INTEGRATED ACOUSTICAL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,884.46",
          "BranchNumber": 9,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "INTEGRATED ACOUSTICAL"
        },
        {
          "WorkOrder": 3983363,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Taco Bell Detroit",
          "JobNumber": "502-916315-147",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14000 Livernois Ave",
          "JobAddress2": "",
          "JobFullAddress": "14000 Livernois Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$7,168.72",
          "BranchNumber": 9,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3983362,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1592 TENANT SHELL",
          "JobNumber": "502-913820-555",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27500 Novi Road",
          "JobAddress2": "",
          "JobFullAddress": "27500 Novi Road",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$1,146.16",
          "BranchNumber": 9,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3983361,
          "ParentWorkOrder": "",
          "JobName": "NOF Ashley Hazel Park",
          "JobNumber": "502-939300-16",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1400 East 10 Mile Rd STE 150",
          "JobAddress2": "",
          "JobFullAddress": "1400 East 10 Mile Rd STE 150",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "D&S Contractors Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,956.14",
          "BranchNumber": 8,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "D&S Contractors Inc"
        },
        {
          "WorkOrder": 3983360,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FCA Warren Stamping",
          "JobNumber": "502-817800-45",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22800 Mound Rd",
          "JobAddress2": "",
          "JobFullAddress": "22800 Mound Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$7,632.88",
          "BranchNumber": 8,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3983359,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Delta Air-ConcourseA",
          "JobNumber": "502-813200-133",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9000 Middlebelt Road",
          "JobAddress2": "RM A2-280 Apron Level",
          "JobFullAddress": "9000 Middlebelt Road RM A2-280 Apron Level",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING CORPORATION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$24,428.87",
          "BranchNumber": 8,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING CORPORATION"
        },
        {
          "WorkOrder": 3983358,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Domino Farms",
          "JobNumber": "502-812350-87",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24 Frank Lloyd Wright Dr",
          "JobAddress2": "",
          "JobFullAddress": "24 Frank Lloyd Wright Dr",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 3983357,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HOBBY LOBBY",
          "JobNumber": "502-1344933-14",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "205 South Greenville West DR",
          "JobAddress2": "M-57",
          "JobFullAddress": "205 South Greenville West DR M-57",
          "JobCounty": "Montcalm",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER 1 INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$65,006.00",
          "BalanceRemaining": "$845.88",
          "BranchNumber": 5,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "PREMIER 1 INTERIORS LLC"
        },
        {
          "WorkOrder": 3983356,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-026VHMDLND",
          "JobNumber": "502-1602000-25",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2125 RIDGEWOOD DR",
          "JobAddress2": "",
          "JobFullAddress": "2125 RIDGEWOOD DR",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Constr",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$5,266.08",
          "BranchNumber": 1,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "Great Lakes Bay Constr"
        },
        {
          "WorkOrder": 3983355,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HAMPTON MANOR",
          "JobNumber": "502-11041-30",
          "FirstdayontheJob": "12-01-2019",
          "LastdayontheJob": "12-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "123 WATERSTRADT-COMMERCE DR",
          "JobAddress2": "",
          "JobFullAddress": "123 WATERSTRADT-COMMERCE DR",
          "JobCounty": "MONROE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Complete Acoustical",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,191.20",
          "BranchNumber": 1,
          "SubmitDate": "12-02-2019",
          "ClientonJob": "Complete Acoustical"
        },
        {
          "WorkOrder": 3982003,
          "ParentWorkOrder": 3970783,
          "JobName": "(NOF)DaVita Detroit",
          "JobNumber": "502-16302-2",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11707 Whittier St",
          "JobAddress2": "",
          "JobFullAddress": "11707 Whittier St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "JONSTIN CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$96.99",
          "BranchNumber": 8,
          "SubmitDate": "11-23-2019",
          "ClientonJob": "JONSTIN CONSTRUCTION INC"
        },
        {
          "WorkOrder": 3981932,
          "ParentWorkOrder": 3974420,
          "JobName": "(NOF)19-9072 DTE GO-7",
          "JobNumber": "502-815250-351",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Plaza",
          "JobAddress2": "",
          "JobFullAddress": "1 Energy Plaza",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3981741,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Timberwood Homes",
          "JobNumber": "502-861507-54",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8575 Colony Drive",
          "JobAddress2": "",
          "JobFullAddress": "8575 Colony Drive",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "LARRY CLEVELAND",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$5,072.94",
          "BranchNumber": 11,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "LARRY CLEVELAND"
        },
        {
          "WorkOrder": 3981740,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Washington St",
          "JobNumber": "502-846001-4",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1260 Washington Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1260 Washington Blvd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "LAWRENCE L. CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,646.62",
          "BranchNumber": 11,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "LAWRENCE L. CONSTRUCTION"
        },
        {
          "WorkOrder": 3981739,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Cambria Suites",
          "JobNumber": "502-989801-12",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50741 Corporate Drive",
          "JobAddress2": "",
          "JobFullAddress": "50741 Corporate Drive",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "RC Plastering",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$9,199.83",
          "BranchNumber": 9,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "RC Plastering"
        },
        {
          "WorkOrder": 3981738,
          "ParentWorkOrder": "",
          "JobName": "(NOF)World Wide Center",
          "JobNumber": "502-989801-10",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34701 Grand River Ave.",
          "JobAddress2": "",
          "JobFullAddress": "34701 Grand River Ave.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "RC Plastering",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,045.97",
          "BranchNumber": 9,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "RC Plastering"
        },
        {
          "WorkOrder": 3981737,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WOODHAVEN SCHOOLS",
          "JobNumber": "502-981570-11",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22700 SIBLEY RD",
          "JobAddress2": "",
          "JobFullAddress": "22700 SIBLEY RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DTS Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$63.60",
          "BranchNumber": 9,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "DTS Contracting"
        },
        {
          "WorkOrder": 3981736,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Tim Horton Romeo",
          "JobNumber": "502-920300-94",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13240 32 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "13240 32 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$617.34",
          "BranchNumber": 9,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 3981735,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1590 5TH & 6TH FLOOR",
          "JobNumber": "502-913820-552",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "301 E. LIBERTY ST",
          "JobAddress2": "",
          "JobFullAddress": "301 E. LIBERTY ST",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$70.92",
          "BranchNumber": 9,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3981734,
          "ParentWorkOrder": "",
          "JobName": "(NOF) COMERICA BANK",
          "JobNumber": "502-1639418-27",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "857 4 MILE RD NW",
          "JobAddress2": "",
          "JobFullAddress": "857 4 MILE RD NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$12,550.79",
          "BranchNumber": 8,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "PATRIOT TEAM, INC"
        },
        {
          "WorkOrder": 3981733,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dollar Tree Southgat",
          "JobNumber": "502-876752-13",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13894 Northline Rd",
          "JobAddress2": "",
          "JobFullAddress": "13894 Northline Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,810.57",
          "BranchNumber": 8,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 3981732,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dollar Tree SH",
          "JobNumber": "502-876752-12",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44645 Mound Road",
          "JobAddress2": "",
          "JobFullAddress": "44645 Mound Road",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,125.25",
          "BranchNumber": 8,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 3981731,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Lapeer Rd Industrial",
          "JobNumber": "502-843701-204",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4130 S. Lapeer Rd",
          "JobAddress2": "",
          "JobFullAddress": "4130 S. Lapeer Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 3981730,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(262)Mott High Schoo",
          "JobNumber": "502-816350-143",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1151 Scott Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "1151 Scott Lake Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,269.63",
          "BranchNumber": 8,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 3981729,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Planet Fitness",
          "JobNumber": "502-811800-67",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4190 E Court St",
          "JobAddress2": "",
          "JobFullAddress": "4190 E Court St",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BHD BUILDERS, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "BHD BUILDERS, INC"
        },
        {
          "WorkOrder": 3981728,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MATTRESS FIRM",
          "JobNumber": "502-940253-3",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2625 JACKSON AVE STE #200",
          "JobAddress2": "",
          "JobFullAddress": "2625 JACKSON AVE STE #200",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "D L P Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,506.00",
          "BalanceRemaining": "$3,513.30",
          "BranchNumber": 7,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "D L P Construction"
        },
        {
          "WorkOrder": 3981727,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ST.MARY'S ASCENSION",
          "JobNumber": "502-11910-2",
          "FirstdayontheJob": "11-21-2019",
          "LastdayontheJob": "11-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1015 S WASHINGTON AVE",
          "JobAddress2": "FL 2",
          "JobFullAddress": "1015 S WASHINGTON AVE FL 2",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "R.C. HENDRICK & SON, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$217.17",
          "BranchNumber": 1,
          "SubmitDate": "11-22-2019",
          "ClientonJob": "R.C. HENDRICK & SON, INC."
        },
        {
          "WorkOrder": 3981631,
          "ParentWorkOrder": 3976297,
          "JobName": "(NOF)TOWNPLACE SUITES",
          "JobNumber": "502-1014005-8",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4834 TOWN CENTER DR SE",
          "JobAddress2": "",
          "JobFullAddress": "4834 TOWN CENTER DR SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "RUDYS PLASTERING AND DRYWALL FINISH",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$4,193.42",
          "BranchNumber": 5,
          "SubmitDate": "11-21-2019",
          "ClientonJob": "RUDYS PLASTERING AND DRYWALL FINISH"
        },
        {
          "WorkOrder": 3981401,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Bridgepoint Church",
          "JobNumber": "502-1614300-2",
          "FirstdayontheJob": "11-20-2019",
          "LastdayontheJob": "11-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9875 Lewis Ave.",
          "JobAddress2": "",
          "JobFullAddress": "9875 Lewis Ave.",
          "JobCounty": "Monroe",
          "JobState": "Michigan",
          "CustomerCompanyName": "McKNIGHT DEVELOPMENT",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$13,409.02",
          "BranchNumber": 13,
          "SubmitDate": "11-21-2019",
          "ClientonJob": "McKNIGHT DEVELOPMENT"
        },
        {
          "WorkOrder": 3981400,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Napoleon Snr Living",
          "JobNumber": "502-1600317-2",
          "FirstdayontheJob": "11-20-2019",
          "LastdayontheJob": "11-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "545 Raymond St",
          "JobAddress2": "",
          "JobFullAddress": "545 Raymond St",
          "JobCounty": "HENRY",
          "JobState": "Ohio",
          "CustomerCompanyName": "RCS CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$2,450.55",
          "BranchNumber": 13,
          "SubmitDate": "11-21-2019",
          "ClientonJob": "RCS CONSTRUCTION"
        },
        {
          "WorkOrder": 3981399,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Hilton Tru",
          "JobNumber": "502-1600276-2",
          "FirstdayontheJob": "11-20-2019",
          "LastdayontheJob": "11-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6110 Milan Rd",
          "JobAddress2": "",
          "JobFullAddress": "6110 Milan Rd",
          "JobCounty": "Sandusky",
          "JobState": "Ohio",
          "CustomerCompanyName": "ALPHA CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "11-21-2019",
          "ClientonJob": "ALPHA CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 3981398,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Fostoria City School",
          "JobNumber": "502-133716-3",
          "FirstdayontheJob": "11-20-2019",
          "LastdayontheJob": "11-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1001 Park Avenue",
          "JobAddress2": "",
          "JobFullAddress": "1001 Park Avenue",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "Halker Drywall Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$36,159.25",
          "BranchNumber": 13,
          "SubmitDate": "11-21-2019",
          "ClientonJob": "Halker Drywall Inc"
        },
        {
          "WorkOrder": 3981397,
          "ParentWorkOrder": "",
          "JobName": "NOF 1936 RACE STREET",
          "JobNumber": "65-2134789-7",
          "FirstdayontheJob": "11-20-2019",
          "LastdayontheJob": "11-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "MARKET SQUARE 3",
          "JobAddress2": "",
          "JobFullAddress": "MARKET SQUARE 3",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "COX DRYWALL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$2,367.98",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "COX DRYWALL"
        },
        {
          "WorkOrder": 3980431,
          "ParentWorkOrder": 3974425,
          "JobName": "(NOF)DTMB VetHome Interior - Project # 218470",
          "JobNumber": "502-823200-78",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Donner Rd/Douglas Dr / 48512 HAWK DR",
          "JobAddress2": "",
          "JobFullAddress": "Donner Rd/Douglas Dr / 48512 HAWK DR",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PONTIAC CEILING & PART",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$250,006.00",
          "BalanceRemaining": "$16,590.59",
          "BranchNumber": 8,
          "SubmitDate": "11-16-2019",
          "ClientonJob": "PONTIAC CEILING & PART"
        },
        {
          "WorkOrder": 3980430,
          "ParentWorkOrder": 3973622,
          "JobName": "(NOF) TRU HOTEL - Columbus Airport",
          "JobNumber": "65-413650-4",
          "FirstdayontheJob": "10-16-2019",
          "LastdayontheJob": "10-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2845 AIRPORT DR",
          "JobAddress2": "",
          "JobFullAddress": "2845 AIRPORT DR",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "MILES-McCLELLAN CONST CO",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$12,911.56",
          "BranchNumber": 4,
          "SubmitDate": "11-16-2019",
          "ClientonJob": "MILES-McCLELLAN CONST CO"
        },
        {
          "WorkOrder": 3980399,
          "ParentWorkOrder": 3970790,
          "JobName": "(NOF)1574 - SOUL CYCLE",
          "JobNumber": "502-913820-536",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1107 S University Ave",
          "JobAddress2": "",
          "JobFullAddress": "1107 S University Ave",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,657.78",
          "BranchNumber": 9,
          "SubmitDate": "11-16-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3980375,
          "ParentWorkOrder": 3974420,
          "JobName": "(NOF)19-9072 DTE GO-7",
          "JobNumber": "502-815250-351",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Plaza",
          "JobAddress2": "",
          "JobFullAddress": "1 Energy Plaza",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Draft",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3980336,
          "ParentWorkOrder": 3970785,
          "JobName": "(NOF)3080 MottCC Southern LAKES BRANCH CENTER IDS PROJECT 17230-1000",
          "JobNumber": "502-813200-130",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2100 West Thompson Rd.",
          "JobAddress2": "",
          "JobFullAddress": "2100 West Thompson Rd.",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$90,006.00",
          "BalanceRemaining": "$14,153.24",
          "BranchNumber": 8,
          "SubmitDate": "11-15-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3980181,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Golf Course",
          "JobNumber": "502-867560-38",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5833 Westwood Dr",
          "JobAddress2": "",
          "JobFullAddress": "5833 Westwood Dr",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ROBERT G WYLIN II",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$6,306.00",
          "BalanceRemaining": "$5,579.18",
          "BranchNumber": 11,
          "SubmitDate": "",
          "ClientonJob": "ROBERT G WYLIN II"
        },
        {
          "WorkOrder": 3980180,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Yurk Construction",
          "JobNumber": "502-864751-208",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2075 Rochester Rd",
          "JobAddress2": "",
          "JobFullAddress": "2075 Rochester Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "STILES DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$5,324.28",
          "BranchNumber": 11,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "STILES DRYWALL LLC"
        },
        {
          "WorkOrder": 3980179,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Koehl residence",
          "JobNumber": "502-861507-53",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2690 Cottage Lane",
          "JobAddress2": "",
          "JobFullAddress": "2690 Cottage Lane",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "LARRY CLEVELAND",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,206.00",
          "BalanceRemaining": "$4,817.47",
          "BranchNumber": 11,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "LARRY CLEVELAND"
        },
        {
          "WorkOrder": 3980178,
          "ParentWorkOrder": "",
          "JobName": "(NOF) O'Rourke residence",
          "JobNumber": "502-859551-24",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4207 River Rd",
          "JobAddress2": "",
          "JobFullAddress": "4207 River Rd",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "PICKLEHAUPT DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$13,006.00",
          "BalanceRemaining": "$1,250.52",
          "BranchNumber": 11,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "PICKLEHAUPT DRYWALL"
        },
        {
          "WorkOrder": 3980177,
          "ParentWorkOrder": "",
          "JobName": "(NOF)EPIC GARDENS CULTIV.",
          "JobNumber": "502-949575-116",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19181 GLENDALE",
          "JobAddress2": "",
          "JobFullAddress": "19181 GLENDALE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$20,211.19",
          "BranchNumber": 9,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3980176,
          "ParentWorkOrder": "",
          "JobName": "(NOF) EMAGINE CANTON",
          "JobNumber": "502-944100-327",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1525 SUPERIOR PARKWAY",
          "JobAddress2": "",
          "JobFullAddress": "1525 SUPERIOR PARKWAY",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,523.77",
          "BranchNumber": 9,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3980175,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Century Plaza",
          "JobNumber": "502-916315-146",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "37270 Five Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "37270 Five Mile Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,142.32",
          "BranchNumber": 9,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3980174,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1589 PANDA EXPRESS",
          "JobNumber": "502-913820-551",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "530 S State St",
          "JobAddress2": "",
          "JobFullAddress": "530 S State St",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$576.91",
          "BranchNumber": 9,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3980173,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Gil-Mar Manufacturin",
          "JobNumber": "502-1526300-2",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12841 Stark Rd",
          "JobAddress2": "",
          "JobFullAddress": "12841 Stark Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "GEORGE ZERVOS CUSTOM HOMES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "GEORGE ZERVOS CUSTOM HOMES"
        },
        {
          "WorkOrder": 3980172,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Planet Fitness",
          "JobNumber": "502-1163384-2",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5142 Highland Rd",
          "JobAddress2": "",
          "JobFullAddress": "5142 Highland Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SQUARE FIT LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "SQUARE FIT LLC"
        },
        {
          "WorkOrder": 3980171,
          "ParentWorkOrder": "",
          "JobName": "(NOF)34435 Michigan Ave",
          "JobNumber": "502-815630-223",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34435  Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "34435  Michigan Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$533.56",
          "BranchNumber": 8,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3980170,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Rose City",
          "JobNumber": "502-967075-121",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "300 W Washington Ave",
          "JobAddress2": "",
          "JobFullAddress": "300 W Washington Ave",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 3980169,
          "ParentWorkOrder": "",
          "JobName": "(NOF) misc",
          "JobNumber": "502-1602293-2",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Misc",
          "JobAddress2": "",
          "JobFullAddress": "Misc",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "WEST MICHIGAN EIFS &",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "",
          "ClientonJob": "WEST MICHIGAN EIFS &"
        },
        {
          "WorkOrder": 3980168,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DOMINSALPENA19122",
          "JobNumber": "502-1602000-26",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "650 N Ripley Blvd",
          "JobAddress2": "",
          "JobFullAddress": "650 N Ripley Blvd",
          "JobCounty": "Alpena",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Constr",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$4,569.11",
          "BranchNumber": 1,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "Great Lakes Bay Constr"
        },
        {
          "WorkOrder": 3980167,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DG FAIRVIEW MI",
          "JobNumber": "502-1160305-6",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1967 E MILLER RD",
          "JobAddress2": "",
          "JobFullAddress": "1967 E MILLER RD",
          "JobCounty": "OSCODA",
          "JobState": "Michigan",
          "CustomerCompanyName": "INNOVATIVE CONSTRUCTION SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$5,663.88",
          "BranchNumber": 1,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "INNOVATIVE CONSTRUCTION SERVICES"
        },
        {
          "WorkOrder": 3980166,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PARTYSTORE",
          "JobNumber": "502-30978-11",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6349 Lapeer Rd",
          "JobAddress2": "",
          "JobFullAddress": "6349 Lapeer Rd",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Kolar Brothers Constr.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,952.48",
          "BranchNumber": 1,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "Kolar Brothers Constr."
        },
        {
          "WorkOrder": 3980165,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 235 Mill St",
          "JobNumber": "502-30300-3",
          "FirstdayontheJob": "11-13-2019",
          "LastdayontheJob": "11-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "235 N Mill St",
          "JobAddress2": "",
          "JobFullAddress": "235 N Mill St",
          "JobCounty": "GRATIOT",
          "JobState": "Michigan",
          "CustomerCompanyName": "J Clark Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$2,614.97",
          "BranchNumber": 1,
          "SubmitDate": "11-14-2019",
          "ClientonJob": "J Clark Drywall Inc"
        },
        {
          "WorkOrder": 3979638,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Kim McClure",
          "JobNumber": "502-1611204-30",
          "FirstdayontheJob": "11-12-2019",
          "LastdayontheJob": "11-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11051 Preserve Blvd.",
          "JobAddress2": "",
          "JobFullAddress": "11051 Preserve Blvd.",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "JR Interiors",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$5,923.71",
          "BranchNumber": 13,
          "SubmitDate": "11-13-2019",
          "ClientonJob": "JR Interiors"
        },
        {
          "WorkOrder": 3979637,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Ridgestone",
          "JobNumber": "502-1611204-29",
          "FirstdayontheJob": "11-12-2019",
          "LastdayontheJob": "11-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4350 Pine Ridge Circle",
          "JobAddress2": "",
          "JobFullAddress": "4350 Pine Ridge Circle",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "JR Interiors",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$5,910.60",
          "BranchNumber": 13,
          "SubmitDate": "11-13-2019",
          "ClientonJob": "JR Interiors"
        },
        {
          "WorkOrder": 3979636,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Home 2 Suites",
          "JobNumber": "502-1609034-42",
          "FirstdayontheJob": "11-12-2019",
          "LastdayontheJob": "11-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1630 E. Wooster St.",
          "JobAddress2": "",
          "JobFullAddress": "1630 E. Wooster St.",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$55,667.65",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 3979635,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Crestview Early Chi",
          "JobNumber": "502-1304186-52",
          "FirstdayontheJob": "11-12-2019",
          "LastdayontheJob": "11-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "510 East Tully St.",
          "JobAddress2": "",
          "JobFullAddress": "510 East Tully St.",
          "JobCounty": "VAN WERT",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,712.90",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3979634,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Sola Salon",
          "JobNumber": "502-137665-12",
          "FirstdayontheJob": "11-12-2019",
          "LastdayontheJob": "11-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30655 Detroit Rd.",
          "JobAddress2": "",
          "JobFullAddress": "30655 Detroit Rd.",
          "JobCounty": "CUYAHOGA",
          "JobState": "Ohio",
          "CustomerCompanyName": "BANYAN CONSTRUCTION SERVICES LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "11-13-2019",
          "ClientonJob": "BANYAN CONSTRUCTION SERVICES LLC"
        },
        {
          "WorkOrder": 3979633,
          "ParentWorkOrder": "",
          "JobName": "(NOF) City of Clyde",
          "JobNumber": "502-133162-3",
          "FirstdayontheJob": "11-12-2019",
          "LastdayontheJob": "11-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "606 S. Church St.",
          "JobAddress2": "",
          "JobFullAddress": "606 S. Church St.",
          "JobCounty": "SANDUSKY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Anstead Construction LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "11-13-2019",
          "ClientonJob": "Anstead Construction LLC"
        },
        {
          "WorkOrder": 3979632,
          "ParentWorkOrder": "",
          "JobName": "NOF Tri Health Western",
          "JobNumber": "65-510590-40",
          "FirstdayontheJob": "11-12-2019",
          "LastdayontheJob": "11-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6949 Good Samaritan Dr",
          "JobAddress2": "",
          "JobFullAddress": "6949 Good Samaritan Dr",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$214.00",
          "BranchNumber": 2,
          "SubmitDate": "11-13-2019",
          "ClientonJob": "TRIVERSITY CRAFT FORCE LLC"
        },
        {
          "WorkOrder": 3978900,
          "ParentWorkOrder": 3972499,
          "JobName": "(NOF)Erie County Board of",
          "JobNumber": "502-132412-8",
          "FirstdayontheJob": "10-10-2019",
          "LastdayontheJob": "10-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3916 Perkins Ave",
          "JobAddress2": "",
          "JobFullAddress": "3916 Perkins Ave",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "D&G Focht Construction Co",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "11-11-2019",
          "ClientonJob": "D&G Focht Construction Co"
        },
        {
          "WorkOrder": 3978887,
          "ParentWorkOrder": 3968895,
          "JobName": "(NOF)Federal Plus C.U.",
          "JobNumber": "502-1165033-2",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8075 Lapeer Rd.",
          "JobAddress2": "",
          "JobFullAddress": "8075 Lapeer Rd.",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "RUSSELL PLASTERING CO",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$1,893.41",
          "BranchNumber": 8,
          "SubmitDate": "11-11-2019",
          "ClientonJob": "RUSSELL PLASTERING CO"
        },
        {
          "WorkOrder": 3978103,
          "ParentWorkOrder": "",
          "JobName": "(nof) Rattle Run Farms",
          "JobNumber": "502-858171-109",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7103 Gratiot Ave",
          "JobAddress2": "",
          "JobFullAddress": "7103 Gratiot Ave",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "DGS DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$13,006.00",
          "BalanceRemaining": "$11,713.15",
          "BranchNumber": 11,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "DGS DRYWALL LLC"
        },
        {
          "WorkOrder": 3978102,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CVS",
          "JobNumber": "502-811591-7",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6070 W MAPLE RD",
          "JobAddress2": "",
          "JobFullAddress": "6070 W MAPLE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Fortney & Weygandt, Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,075.03",
          "BranchNumber": 11,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "Fortney & Weygandt, Inc."
        },
        {
          "WorkOrder": 3978101,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FIsheries Storage",
          "JobNumber": "502-919910-3",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "28403 Old Gibraltar Rd",
          "JobAddress2": "",
          "JobFullAddress": "28403 Old Gibraltar Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Nora Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$5,705.66",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "Nora Contracting"
        },
        {
          "WorkOrder": 3978100,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GM Tech Gate 6",
          "JobNumber": "502-916331-13",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6250 Chicago Rd.",
          "JobAddress2": "",
          "JobFullAddress": "6250 Chicago Rd.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "IDEAL CONTRACTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$18,006.00",
          "BalanceRemaining": "$12,107.79",
          "BranchNumber": 9,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "IDEAL CONTRACTING LLC"
        },
        {
          "WorkOrder": 3978099,
          "ParentWorkOrder": "",
          "JobName": "(NOF)365 Retail Markets",
          "JobNumber": "502-843701-203",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1738 Maplelawn Dr",
          "JobAddress2": "",
          "JobFullAddress": "1738 Maplelawn Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$10,628.05",
          "BranchNumber": 8,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 3978098,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Downriver Comm FedCU",
          "JobNumber": "502-823300-77",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15261 Trenton Rd.",
          "JobAddress2": "",
          "JobFullAddress": "15261 Trenton Rd.",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$4,990.37",
          "BranchNumber": 8,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 3978097,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Carhartt 2nd 3rd Flr",
          "JobNumber": "502-817800-43",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5800 Cass Ave.",
          "JobAddress2": "",
          "JobFullAddress": "5800 Cass Ave.",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$2,993.10",
          "BranchNumber": 8,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3978096,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9069 Genesys",
          "JobNumber": "502-815250-356",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3978095,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Suite 3",
          "JobNumber": "502-89600-3",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "40950 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "40950 Woodward Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Contour Development Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,717.42",
          "BranchNumber": 8,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "Contour Development Group"
        },
        {
          "WorkOrder": 3978093,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dunkin Donuts Troy",
          "JobNumber": "502-1655510-3",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2981 E. Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "2981 E. Big Beaver Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "RESA CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,244.23",
          "BranchNumber": 5,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "RESA CONSTRUCTION INC"
        },
        {
          "WorkOrder": 3978092,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CENTRAL PARK APTS",
          "JobNumber": "502-1603009-4",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "822 S GREENVILLE RD",
          "JobAddress2": "",
          "JobFullAddress": "822 S GREENVILLE RD",
          "JobCounty": "MONTCALM",
          "JobState": "Michigan",
          "CustomerCompanyName": "G & M CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$3,396.32",
          "BranchNumber": 5,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "G & M CONSTRUCTION"
        },
        {
          "WorkOrder": 3978091,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DOMINOSDEARBORN19095",
          "JobNumber": "502-1602000-24",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23960 CARLYSLE ST",
          "JobAddress2": "",
          "JobFullAddress": "23960 CARLYSLE ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Constr",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$795.19",
          "BranchNumber": 1,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "Great Lakes Bay Constr"
        },
        {
          "WorkOrder": 3978089,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Home 2 Suites",
          "JobNumber": "502-1609034-42",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1630 E. Wooster St.",
          "JobAddress2": "",
          "JobFullAddress": "1630 E. Wooster St.",
          "JobCounty": "Wood",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$54,453.37",
          "BranchNumber": 13,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 3978088,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Crestview Early Childhood Center",
          "JobNumber": "502-1304186-52",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "510 East Tully St.",
          "JobAddress2": "",
          "JobFullAddress": "510 East Tully St.",
          "JobCounty": "VAN WERT",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,712.90",
          "BranchNumber": 13,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3978087,
          "ParentWorkOrder": "",
          "JobName": "NOF)OAK CREEK URGENT CARE",
          "JobNumber": "65-137748-2",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1028 MIAMISBURG-CENTERVILLE RD STE 5B",
          "JobAddress2": "",
          "JobFullAddress": "1028 MIAMISBURG-CENTERVILLE RD STE 5B",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "P & G CUSTOM ENT LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$9,051.97",
          "BranchNumber": 13,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "P & G CUSTOM ENT LLC"
        },
        {
          "WorkOrder": 3978086,
          "ParentWorkOrder": "",
          "JobName": "NOF GE ADDITIVES-NORTH",
          "JobNumber": "65-2134697-9",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8556 TRADE CENTER DR,",
          "JobAddress2": "PT-BD2",
          "JobFullAddress": "8556 TRADE CENTER DR, PT-BD2",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "J.F. BRAKE CONTRACTOR",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$125,006.00",
          "BalanceRemaining": "$8,173.20",
          "BranchNumber": 12,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "J.F. BRAKE CONTRACTOR"
        },
        {
          "WorkOrder": 3978085,
          "ParentWorkOrder": "",
          "JobName": "NOF U of Cincinnati",
          "JobNumber": "65-125599-2",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9555 Plainfield Rd",
          "JobAddress2": "MUNTZ HALL",
          "JobFullAddress": "9555 Plainfield Rd MUNTZ HALL",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "ADVANCED ACOUSTICAL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$9,668.88",
          "BranchNumber": 12,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "ADVANCED ACOUSTICAL LLC"
        },
        {
          "WorkOrder": 3978084,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ALLWELL BEHAVIORAL H",
          "JobNumber": "65-433386-8",
          "FirstdayontheJob": "11-06-2019",
          "LastdayontheJob": "11-06-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "710 MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "710 MAIN ST",
          "JobCounty": "COSHOCTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Paul Construction Co Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$4,998.75",
          "BranchNumber": 4,
          "SubmitDate": "11-07-2019",
          "ClientonJob": "Paul Construction Co Inc"
        },
        {
          "WorkOrder": 3977182,
          "ParentWorkOrder": 3970800,
          "JobName": "(NOF)Summit 8",
          "JobNumber": "502-1604171-2",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13800 Cavaliere Dr",
          "JobAddress2": "",
          "JobFullAddress": "13800 Cavaliere Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$6,132.47",
          "BranchNumber": 8,
          "SubmitDate": "11-05-2019",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 3977181,
          "ParentWorkOrder": 3967899,
          "JobName": "(NOF) The Griffin",
          "JobNumber": "502-1605903-2",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25000 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "25000 Woodward Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLOVER CONSTRUCTION SERVICES INC",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,000.00",
          "BalanceRemaining": "$725.04",
          "BranchNumber": 8,
          "SubmitDate": "11-05-2019",
          "ClientonJob": "CLOVER CONSTRUCTION SERVICES INC"
        },
        {
          "WorkOrder": 3977180,
          "ParentWorkOrder": 3938546,
          "JobName": "(NOF) 640 TEMPLE",
          "JobNumber": "502-910900-9",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "640 TEMPLE ST",
          "JobAddress2": "",
          "JobFullAddress": "640 TEMPLE ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANN ARBOR CEILING & PART",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$500,000.00",
          "BalanceRemaining": "$934.53",
          "BranchNumber": 9,
          "SubmitDate": "11-05-2019",
          "ClientonJob": "ANN ARBOR CEILING & PART"
        },
        {
          "WorkOrder": 3977179,
          "ParentWorkOrder": 3965646,
          "JobName": "(NOF)Orchard Lake Plaza",
          "JobNumber": "502-989801-9",
          "FirstdayontheJob": "09-12-2019",
          "LastdayontheJob": "09-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "28859 Orchard Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "28859 Orchard Lake Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "RC Plastering",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,151.25",
          "BranchNumber": 9,
          "SubmitDate": "11-05-2019",
          "ClientonJob": "RC Plastering"
        },
        {
          "WorkOrder": 3976490,
          "ParentWorkOrder": 3954254,
          "JobName": "(NOF) VIEW ON PAVEY",
          "JobNumber": "65-464635-4",
          "FirstdayontheJob": "07-31-2019",
          "LastdayontheJob": "07-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10 W NORTHWOOD AVE",
          "JobAddress2": "",
          "JobFullAddress": "10 W NORTHWOOD AVE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "MARQUEE DEVELOPMENT GROUP",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$23,500.04",
          "BranchNumber": 4,
          "SubmitDate": "11-01-2019",
          "ClientonJob": "MARQUEE DEVELOPMENT GROUP"
        },
        {
          "WorkOrder": 3976378,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Edward Jones #01792",
          "JobNumber": "502-1163909-4",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1071 Pray Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1071 Pray Blvd",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "DREAMKEEPER PROPERTIES",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,775.19",
          "BranchNumber": 13,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "DREAMKEEPER PROPERTIES"
        },
        {
          "WorkOrder": 3976377,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Clean Juice",
          "JobNumber": "502-1163909-2",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3155 Levis Commons Blvd",
          "JobAddress2": "",
          "JobFullAddress": "3155 Levis Commons Blvd",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "DREAMKEEPER PROPERTIES",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,424.47",
          "BranchNumber": 13,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "DREAMKEEPER PROPERTIES"
        },
        {
          "WorkOrder": 3976376,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hampton Inn",
          "JobNumber": "65-2137180-5",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1520 S Court St",
          "JobAddress2": "",
          "JobFullAddress": "1520 S Court St",
          "JobCounty": "PICKAWAY",
          "JobState": "Ohio",
          "CustomerCompanyName": "SCHNIPPEL CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$15,739.38",
          "BranchNumber": 13,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "SCHNIPPEL CONSTRUCTION"
        },
        {
          "WorkOrder": 3976375,
          "ParentWorkOrder": "",
          "JobName": "NOF LEBANON DIST EVENT CE",
          "JobNumber": "65-555301-37",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "160 MILLER RD",
          "JobAddress2": "",
          "JobFullAddress": "160 MILLER RD",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "M&M CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$215.59",
          "BranchNumber": 2,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "M&M CONSTRUCTION"
        },
        {
          "WorkOrder": 3976316,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Bridgewood Church",
          "JobNumber": "502-1650650-5",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10291 Green Rd",
          "JobAddress2": "",
          "JobFullAddress": "10291 Green Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDUSTRIAL TECH SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,059.82",
          "BranchNumber": 11,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "INDUSTRIAL TECH SERVICES"
        },
        {
          "WorkOrder": 3976314,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LaDuke Construction",
          "JobNumber": "502-859551-22",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "312 Liberty Ave",
          "JobAddress2": "",
          "JobFullAddress": "312 Liberty Ave",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "PICKLEHAUPT DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$7,960.36",
          "BranchNumber": 11,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "PICKLEHAUPT DRYWALL"
        },
        {
          "WorkOrder": 3976313,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Crunch Fit. Warren",
          "JobNumber": "502-111914-9",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30750 Schoenherr Rd",
          "JobAddress2": "",
          "JobFullAddress": "30750 Schoenherr Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Custom Builder",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$18,001.09",
          "BranchNumber": 11,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "Great Lakes Custom Builder"
        },
        {
          "WorkOrder": 3976312,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Retail Out Building",
          "JobNumber": "502-989801-11",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50767 Corporate Drive",
          "JobAddress2": "",
          "JobFullAddress": "50767 Corporate Drive",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "RC Plastering",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,674.71",
          "BranchNumber": 9,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "RC Plastering"
        },
        {
          "WorkOrder": 3976311,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Adrian",
          "JobNumber": "502-950465-8",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1424 S Main St",
          "JobAddress2": "",
          "JobFullAddress": "1424 S Main St",
          "JobCounty": "LENAWEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Woodbridge Const & Carpty",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,055.36",
          "BranchNumber": 9,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "Woodbridge Const & Carpty"
        },
        {
          "WorkOrder": 3976310,
          "ParentWorkOrder": "",
          "JobName": "(NOF)AT&T Green Palace",
          "JobNumber": "502-916315-145",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29447 Five Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "29447 Five Mile Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,349.00",
          "BranchNumber": 9,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3976309,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BURGER KING WOODHAVE",
          "JobNumber": "502-910621-46",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20993 WEST RD",
          "JobAddress2": "",
          "JobFullAddress": "20993 WEST RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 3976308,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Lo's Beauty Bar",
          "JobNumber": "502-1594988-5",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "120 S Broadway St Ste 103",
          "JobAddress2": "",
          "JobFullAddress": "120 S Broadway St Ste 103",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SOUTH EAST COMMERCIAL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "SOUTH EAST COMMERCIAL INC"
        },
        {
          "WorkOrder": 3976307,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3306 Auburn Rd",
          "JobNumber": "502-1163306-2",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3306 Auburn Rd",
          "JobAddress2": "",
          "JobFullAddress": "3306 Auburn Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DHCM",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$14,062.63",
          "BranchNumber": 8,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "DHCM"
        },
        {
          "WorkOrder": 3976306,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Flex 5 Flint",
          "JobNumber": "502-856071-86",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4939 Clio Rd",
          "JobAddress2": "",
          "JobFullAddress": "4939 Clio Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,173.59",
          "BranchNumber": 8,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "M & V CONSTRUCTION"
        },
        {
          "WorkOrder": 3976305,
          "ParentWorkOrder": "",
          "JobName": "(NOF)100 Mack",
          "JobNumber": "502-823300-76",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 Mack Ave.",
          "JobAddress2": "",
          "JobFullAddress": "100 Mack Ave.",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$12,334.33",
          "BranchNumber": 8,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 3976304,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PACE Eastpointe",
          "JobNumber": "502-815630-224",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17401 E Ten Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "17401 E Ten Mile Road",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$21,385.76",
          "BranchNumber": 8,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3976303,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9073 DTE Beech St",
          "JobNumber": "502-815250-355",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "One Energy Plaza",
          "JobAddress2": "",
          "JobFullAddress": "One Energy Plaza",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$90,006.00",
          "BalanceRemaining": "$2,355.34",
          "BranchNumber": 8,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3976302,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9066 DTE 8th Flr",
          "JobNumber": "502-815250-354",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "One Energy Plaza",
          "JobAddress2": "8th Floor WCB",
          "JobFullAddress": "One Energy Plaza 8th Floor WCB",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$65,006.00",
          "BalanceRemaining": "$4,623.58",
          "BranchNumber": 8,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3976301,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3097 GrLakesWaterAut",
          "JobNumber": "502-813200-132",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9300 W. Jefferson Ave.",
          "JobAddress2": "",
          "JobFullAddress": "9300 W. Jefferson Ave.",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$6,191.63",
          "BranchNumber": 8,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3976300,
          "ParentWorkOrder": "",
          "JobName": "(NOF) AAA",
          "JobNumber": "502-811800-66",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17900 N Laurel Park Dr",
          "JobAddress2": "",
          "JobFullAddress": "17900 N Laurel Park Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BHD BUILDERS, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$24,006.00",
          "BalanceRemaining": "$3,643.99",
          "BranchNumber": 8,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "BHD BUILDERS, INC"
        },
        {
          "WorkOrder": 3976299,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Burger King Sterling",
          "JobNumber": "502-747764-9",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44751 Mound Rd.",
          "JobAddress2": "",
          "JobFullAddress": "44751 Mound Rd.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "KP Sullivan Builders, Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$3,617.55",
          "BranchNumber": 7,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "KP Sullivan Builders, Inc"
        },
        {
          "WorkOrder": 3976298,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HFAH HYBRID O/R",
          "JobNumber": "502-715336-7",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "205 N. EAST AVE",
          "JobAddress2": "",
          "JobFullAddress": "205 N. EAST AVE",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Walker Commercial Interiors",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$78.72",
          "BranchNumber": 7,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "Walker Commercial Interiors"
        },
        {
          "WorkOrder": 3976297,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TOWNPLACE SUITES",
          "JobNumber": "502-1014005-8",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4834 TOWN CENTER DR SE",
          "JobAddress2": "",
          "JobFullAddress": "4834 TOWN CENTER DR SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "RUDYS PLASTERING AND DRYWALL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$4,193.42",
          "BranchNumber": 5,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "RUDYS PLASTERING AND DRYWALL FINISH"
        },
        {
          "WorkOrder": 3976296,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BINGHAM",
          "JobNumber": "502-54471-210",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6526 BINGHAM ST",
          "JobAddress2": "",
          "JobFullAddress": "6526 BINGHAM ST",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Michael Topping Inc.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$5,346.93",
          "BranchNumber": 5,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "Michael Topping Inc."
        },
        {
          "WorkOrder": 3976295,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DG Johannesburg",
          "JobNumber": "502-1160305-5",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10669 M-32 E",
          "JobAddress2": "",
          "JobFullAddress": "10669 M-32 E",
          "JobCounty": "OTSEGO",
          "JobState": "Michigan",
          "CustomerCompanyName": "INNOVATIVE CONSTRUCTION SERVICES",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$6,113.34",
          "BranchNumber": 1,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "INNOVATIVE CONSTRUCTION SERVICES"
        },
        {
          "WorkOrder": 3976294,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DG Rolling Prarie",
          "JobNumber": "502-1160305-4",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "US 20 N 450 E",
          "JobAddress2": "",
          "JobFullAddress": "US 20 N 450 E",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "INNOVATIVE CONSTRUCTION",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$5,840.10",
          "BranchNumber": 1,
          "SubmitDate": "",
          "ClientonJob": "INNOVATIVE CONSTRUCTION"
        },
        {
          "WorkOrder": 3976293,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DTE-NorthServiceCent",
          "JobNumber": "502-14600-40",
          "FirstdayontheJob": "10-30-2019",
          "LastdayontheJob": "10-30-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4100 Doerr Road",
          "JobAddress2": "",
          "JobFullAddress": "4100 Doerr Road",
          "JobCounty": "TUSCOLA",
          "JobState": "Michigan",
          "CustomerCompanyName": "Tri-City Acoustical",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$349.80",
          "BranchNumber": 1,
          "SubmitDate": "10-31-2019",
          "ClientonJob": "Tri-City Acoustical"
        },
        {
          "WorkOrder": 3975109,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DUNHILL ESTATES",
          "JobNumber": "502-847401-144",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19074 Florissant Dr",
          "JobAddress2": "",
          "JobFullAddress": "19074 Florissant Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$7,143.58",
          "BranchNumber": 11,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 3975107,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TACO BELL WARREN",
          "JobNumber": "502-920300-85",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13343 E. 10 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "13343 E. 10 MILE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,606.00",
          "BalanceRemaining": "$5,288.64",
          "BranchNumber": 9,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 3975106,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Culver's SterlingHts",
          "JobNumber": "502-916315-138",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "36600 Van Dyke Ave",
          "JobAddress2": "",
          "JobFullAddress": "36600 Van Dyke Ave",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$88.93",
          "BranchNumber": 9,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3975105,
          "ParentWorkOrder": "",
          "JobName": "NOF The Oakmont Group",
          "JobNumber": "502-913438-9",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9755 Inkster Rd",
          "JobAddress2": "",
          "JobFullAddress": "9755 Inkster Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Davenport Brothers Constr",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$8,700.35",
          "BranchNumber": 9,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "Davenport Brothers Constr"
        },
        {
          "WorkOrder": 3975104,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BJ'S WAREHOUSE",
          "JobNumber": "502-913400-27",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45101 TOWNE CENTER BLVD",
          "JobAddress2": "",
          "JobFullAddress": "45101 TOWNE CENTER BLVD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DANCO CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$14,313.99",
          "BranchNumber": 9,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "DANCO CONTRACTING, INC."
        },
        {
          "WorkOrder": 3975103,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DOWNRIVER MEDICAL",
          "JobNumber": "502-910868-64",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2300 BIDDLE AVE",
          "JobAddress2": "",
          "JobFullAddress": "2300 BIDDLE AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construct",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$950.57",
          "BranchNumber": 9,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "American Family Construct"
        },
        {
          "WorkOrder": 3975102,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ST THOMAS ORTH.INDIA",
          "JobNumber": "502-910621-45",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2850 Parent Ave",
          "JobAddress2": "",
          "JobFullAddress": "2850 Parent Ave",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,041.50",
          "BranchNumber": 9,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 3975101,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Von Maur",
          "JobNumber": "502-1604171-4",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 Briarwood Circle",
          "JobAddress2": "",
          "JobFullAddress": "100 Briarwood Circle",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,993.27",
          "BranchNumber": 8,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 3975100,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Grosse Pointe Invest",
          "JobNumber": "502-1602035-3",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3330 Greenfield Rd",
          "JobAddress2": "",
          "JobFullAddress": "3330 Greenfield Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLOOMFIELD CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$6,637.06",
          "BranchNumber": 8,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "BLOOMFIELD CONSTRUCTION"
        },
        {
          "WorkOrder": 3975098,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Yanfeng",
          "JobNumber": "502-875466-6",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41935 W 12 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "41935 W 12 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Facilities Management Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,745.28",
          "BranchNumber": 8,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "Facilities Management Group"
        },
        {
          "WorkOrder": 3975097,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Element Material",
          "JobNumber": "502-820500-38",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1920 Concept Dr",
          "JobAddress2": "",
          "JobFullAddress": "1920 Concept Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEE CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$9,712.95",
          "BranchNumber": 8,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "LEE CONTRACTING"
        },
        {
          "WorkOrder": 3975096,
          "ParentWorkOrder": "",
          "JobName": "(NOF)18915 Warwick",
          "JobNumber": "502-815630-225",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18915 Warwick St",
          "JobAddress2": "",
          "JobFullAddress": "18915 Warwick St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$6,334.68",
          "BranchNumber": 8,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3975095,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9051 Crittenton",
          "JobNumber": "502-815250-350",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1101 W University Dr",
          "JobAddress2": "",
          "JobFullAddress": "1101 W University Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3975094,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3102 Carhart",
          "JobNumber": "502-813200-131",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5800 Mercury Dr",
          "JobAddress2": "",
          "JobFullAddress": "5800 Mercury Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$8,822.72",
          "BranchNumber": 8,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3975093,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Kroger Troy #448",
          "JobNumber": "502-20010-27",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1237 Coolidge Hwy",
          "JobAddress2": "",
          "JobFullAddress": "1237 Coolidge Hwy",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$23,086.02",
          "BranchNumber": 8,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 3975092,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dayton Freight",
          "JobNumber": "502-967400-10",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1440 Falahee Rd",
          "JobAddress2": "",
          "JobFullAddress": "1440 Falahee Rd",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "COLLINS DESIGN/BUILD",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$2,830.37",
          "BranchNumber": 7,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "COLLINS DESIGN/BUILD"
        },
        {
          "WorkOrder": 3975091,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Jackson College",
          "JobNumber": "502-30900-64",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2111 Emmons Road",
          "JobAddress2": "",
          "JobFullAddress": "2111 Emmons Road",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "Integrity Interiors Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,659.50",
          "BranchNumber": 7,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "Integrity Interiors Inc."
        },
        {
          "WorkOrder": 3975090,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CARDINAL SR CENTER",
          "JobNumber": "502-50550-71",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1015 28TH ST SE",
          "JobAddress2": "",
          "JobFullAddress": "1015 28TH ST SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "CENTRAL CEILING WEST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "CENTRAL CEILING WEST"
        },
        {
          "WorkOrder": 3975089,
          "ParentWorkOrder": "",
          "JobName": "NOF VERIZON WIRELESS",
          "JobNumber": "502-1694894-2",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2725 CENTER AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "2725 CENTER AVENUE",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "DNC CONTRACTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$16,006.00",
          "BalanceRemaining": "$8,082.81",
          "BranchNumber": 1,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "DNC CONTRACTING LLC"
        },
        {
          "WorkOrder": 3975088,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 6074 Main St",
          "JobNumber": "502-1650969-63",
          "FirstdayontheJob": "10-23-2019",
          "LastdayontheJob": "10-23-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6074 Main St/6168 Port Austin Dr.",
          "JobAddress2": "",
          "JobFullAddress": "6074 Main St/6168 Port Austin Dr.",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$3,527.90",
          "BranchNumber": 1,
          "SubmitDate": "10-24-2019",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 3974772,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Biggby Coffee",
          "JobNumber": "502-1301172-48",
          "FirstdayontheJob": "10-22-2019",
          "LastdayontheJob": "10-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "718  Trenton Ave.",
          "JobAddress2": "",
          "JobFullAddress": "718  Trenton Ave.",
          "JobCounty": "HANCOCK",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,397.43",
          "BranchNumber": 13,
          "SubmitDate": "10-23-2019",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 3974715,
          "ParentWorkOrder": 3968886,
          "JobName": "(NOF)18-8066 DTE WCB Ph2",
          "JobNumber": "502-815250-348",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Center 2nd Floor",
          "JobAddress2": "",
          "JobFullAddress": "1 Energy Center 2nd Floor",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-23-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3974685,
          "ParentWorkOrder": 3967901,
          "JobName": "(NOF) 3RD & GRAND APART",
          "JobNumber": "502-1650066-4",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2911 W Grand Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2911 W Grand Blvd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PCC INTERIORS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$18,295.19",
          "BranchNumber": 8,
          "SubmitDate": "10-23-2019",
          "ClientonJob": "PCC INTERIORS"
        },
        {
          "WorkOrder": 3974534,
          "ParentWorkOrder": 3961805,
          "JobName": "(NOF) CONCENTRA",
          "JobNumber": "502-940900-4",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10940 WAYNE RD",
          "JobAddress2": "",
          "JobFullAddress": "10940 WAYNE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "EJH CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$6,087.26",
          "BranchNumber": 9,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "EJH CONSTRUCTION INC"
        },
        {
          "WorkOrder": 3974434,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Lion's Gate-Davison",
          "JobNumber": "502-864751-206",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "** freetype adress **",
          "JobAddress2": "",
          "JobFullAddress": "** freetype adress **",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "STILES DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$6,260.98",
          "BranchNumber": 11,
          "SubmitDate": "",
          "ClientonJob": "STILES DRYWALL LLC"
        },
        {
          "WorkOrder": 3974433,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FAMILY DOLLAR",
          "JobNumber": "502-949575-115",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19160 GREENFIELD RD",
          "JobAddress2": "",
          "JobFullAddress": "19160 GREENFIELD RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$939.20",
          "BranchNumber": 9,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3974432,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MARQUETTE BLG #19087",
          "JobNumber": "502-933550-179",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "243 W.CONGRESS ST",
          "JobAddress2": "",
          "JobFullAddress": "243 W.CONGRESS ST",
          "JobCounty": "wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$14,652.53",
          "BranchNumber": 9,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3974431,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Taco Bell",
          "JobNumber": "502-920300-91",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7719 Southtown Xing",
          "JobAddress2": "Small Flatbed Only",
          "JobFullAddress": "7719 Southtown Xing Small Flatbed Only",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,908.17",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 3974430,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TACO BELL",
          "JobNumber": "502-920300-90",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7931 LAPEER RD",
          "JobAddress2": "",
          "JobFullAddress": "7931 LAPEER RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$5,157.92",
          "BranchNumber": 9,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 3974429,
          "ParentWorkOrder": "",
          "JobName": "(NOF)NEXTEERAUTOMOTIVE",
          "JobNumber": "502-913001-4",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3900 E HOLLAND RD",
          "JobAddress2": "",
          "JobFullAddress": "3900 E HOLLAND RD",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Commercial Interior Resources",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,806.00",
          "BalanceRemaining": "$1,115.54",
          "BranchNumber": 9,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "Commercial Interior Resources"
        },
        {
          "WorkOrder": 3974428,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Bar Verona",
          "JobNumber": "502-1605101-5",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "59145 Van Dyke Rd",
          "JobAddress2": "",
          "JobFullAddress": "59145 Van Dyke Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ARCHITECTURAL PLANNERS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,444.62",
          "BranchNumber": 8,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "ARCHITECTURAL PLANNERS"
        },
        {
          "WorkOrder": 3974427,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FAMILY DOLLAR ECORSE",
          "JobNumber": "502-876752-11",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3859 W.JEFFERSON AVE",
          "JobAddress2": "",
          "JobFullAddress": "3859 W.JEFFERSON AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$5,066.01",
          "BranchNumber": 8,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 3974426,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Daifuku Novi II",
          "JobNumber": "502-823400-52",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30100 Cabot Drive",
          "JobAddress2": "",
          "JobFullAddress": "30100 Cabot Drive",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PARALLEL CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$65,006.00",
          "BalanceRemaining": "$25,703.99",
          "BranchNumber": 8,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "PARALLEL CONSTRUCTION"
        },
        {
          "WorkOrder": 3974425,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DTMB VetHomeInterior",
          "JobNumber": "502-823200-78",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Donner Rd/Douglas Dr / 48512 HAWK DR",
          "JobAddress2": "",
          "JobFullAddress": "Donner Rd/Douglas Dr / 48512 HAWK DR",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PONTIAC CEILING & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$250,006.00",
          "BalanceRemaining": "$16,590.59",
          "BranchNumber": 8,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "PONTIAC CEILING & PART"
        },
        {
          "WorkOrder": 3974424,
          "ParentWorkOrder": "",
          "JobName": "(NOF)750 Stephenson Hwy",
          "JobNumber": "502-815630-222",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "750 Stephenson Hwy",
          "JobAddress2": "",
          "JobFullAddress": "750 Stephenson Hwy",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3974423,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DHL 38701 7 Mile",
          "JobNumber": "502-815630-221",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "38701 7 Mile",
          "JobAddress2": "",
          "JobFullAddress": "38701 7 Mile",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3974422,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9047 Crittenton",
          "JobNumber": "502-815250-353",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1101 W University Dr",
          "JobAddress2": "",
          "JobFullAddress": "1101 W University Dr",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3974421,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9068 AH RCC Pharm",
          "JobNumber": "502-815250-352",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1901 Star Batt Dr",
          "JobAddress2": "",
          "JobFullAddress": "1901 Star Batt Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$633.57",
          "BranchNumber": 8,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3974420,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9072 DTE GO-7",
          "JobNumber": "502-815250-351",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Plaza",
          "JobAddress2": "",
          "JobFullAddress": "1 Energy Plaza",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3974419,
          "ParentWorkOrder": "",
          "JobName": "(NOF)18-8023 Brose N.B.",
          "JobNumber": "502-815250-344",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23400 Bell Rd.",
          "JobAddress2": "",
          "JobFullAddress": "23400 Bell Rd.",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$797.52",
          "BranchNumber": 8,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3974417,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Don Blaker- Ballard",
          "JobNumber": "502-1672525-2",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "655 Ballard Rd",
          "JobAddress2": "",
          "JobFullAddress": "655 Ballard Rd",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "JOHNSON BUILDING GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$6,805.30",
          "BranchNumber": 7,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "JOHNSON BUILDING GROUP"
        },
        {
          "WorkOrder": 3974416,
          "ParentWorkOrder": "",
          "JobName": "(NOF)E.L. HEALTH CARE",
          "JobNumber": "502-990401-54",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3514-3516 COOLIDGE RD",
          "JobAddress2": "",
          "JobFullAddress": "3514-3516 COOLIDGE RD",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$8,048.33",
          "BranchNumber": 7,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3974415,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Gross Ille Airport",
          "JobNumber": "502-967075-120",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27502 Meridian Road",
          "JobAddress2": "",
          "JobFullAddress": "27502 Meridian Road",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$6,865.83",
          "BranchNumber": 7,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 3974414,
          "ParentWorkOrder": "",
          "JobName": "(NOF)",
          "JobNumber": "502-1014005-7",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5261 S DIVISION AVE",
          "JobAddress2": "",
          "JobFullAddress": "5261 S DIVISION AVE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "RUDYS PLASTERING AND DRYWALL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "RUDYS PLASTERING AND DRYWALL FINISH"
        },
        {
          "WorkOrder": 3974413,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DOLLARGENERALGLENNIE",
          "JobNumber": "502-1160305-3",
          "FirstdayontheJob": "10-21-2019",
          "LastdayontheJob": "10-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3344 SOUTH M-65",
          "JobAddress2": "",
          "JobFullAddress": "3344 SOUTH M-65",
          "JobCounty": "ALcona",
          "JobState": "Michigan",
          "CustomerCompanyName": "INNOVATIVE CONSTRUCTION SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$5,798.06",
          "BranchNumber": 1,
          "SubmitDate": "10-22-2019",
          "ClientonJob": "INNOVATIVE CONSTRUCTION SERVICES"
        },
        {
          "WorkOrder": 3973626,
          "ParentWorkOrder": "",
          "JobName": "(NOF) O'Reilly's Auto Par",
          "JobNumber": "502-1304186-51",
          "FirstdayontheJob": "10-16-2019",
          "LastdayontheJob": "10-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "712 N. Main St.",
          "JobAddress2": "",
          "JobFullAddress": "712 N. Main St.",
          "JobCounty": "Lorain",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,673.70",
          "BranchNumber": 13,
          "SubmitDate": "10-17-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3973625,
          "ParentWorkOrder": "",
          "JobName": "NOF NOTH POINT",
          "JobNumber": "65-2137099-11",
          "FirstdayontheJob": "10-16-2019",
          "LastdayontheJob": "10-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "575-599 GATEWAY BLVD",
          "JobAddress2": "",
          "JobFullAddress": "575-599 GATEWAY BLVD",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & A INTERIOR SYSTEMS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$54.16",
          "BranchNumber": 12,
          "SubmitDate": "10-17-2019",
          "ClientonJob": "J & A INTERIOR SYSTEMS"
        },
        {
          "WorkOrder": 3973624,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SPRINGGROVE CREMATIN",
          "JobNumber": "65-573117-2",
          "FirstdayontheJob": "10-16-2019",
          "LastdayontheJob": "10-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9100 PLAINFIELD RD",
          "JobAddress2": "",
          "JobFullAddress": "9100 PLAINFIELD RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "CORE RESOURCES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$134.63",
          "BranchNumber": 2,
          "SubmitDate": "10-17-2019",
          "ClientonJob": "CORE RESOURCES INC"
        },
        {
          "WorkOrder": 3973623,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KIDS FIRST",
          "JobNumber": "65-510300-49",
          "FirstdayontheJob": "10-16-2019",
          "LastdayontheJob": "10-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7950 E Kemper Rd",
          "JobAddress2": "",
          "JobFullAddress": "7950 E Kemper Rd",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,788.31",
          "BranchNumber": 2,
          "SubmitDate": "10-17-2019",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 3973622,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TRU HOTEL",
          "JobNumber": "65-413650-4",
          "FirstdayontheJob": "10-16-2019",
          "LastdayontheJob": "10-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2845 AIRPORT DR",
          "JobAddress2": "",
          "JobFullAddress": "2845 AIRPORT DR",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "MILES-McCLELLAN CONST CO",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$12,911.56",
          "BranchNumber": 4,
          "SubmitDate": "10-17-2019",
          "ClientonJob": "MILES-McCLELLAN CONST CO"
        },
        {
          "WorkOrder": 3973245,
          "ParentWorkOrder": 3969245,
          "JobName": "(NOF)19-5093 Pomeroy",
          "JobNumber": "502-920940-66",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3500 South Boulevard W",
          "JobAddress2": "",
          "JobFullAddress": "3500 South Boulevard W",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$452.33",
          "BranchNumber": 8,
          "SubmitDate": "10-16-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3973244,
          "ParentWorkOrder": 3962782,
          "JobName": "(NOF)ALDI'S GB",
          "JobNumber": "502-814500-80",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6248 S Saginaw Rd",
          "JobAddress2": "",
          "JobFullAddress": "6248 S Saginaw Rd",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONST",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-16-2019",
          "ClientonJob": "COMMERCIAL INT CONST"
        },
        {
          "WorkOrder": 3973014,
          "ParentWorkOrder": 3962773,
          "JobName": "NOF Meijer Store 159",
          "JobNumber": "65-48844-5",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6325 S Gilmore Rd",
          "JobAddress2": "",
          "JobFullAddress": "6325 S Gilmore Rd",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,849.08",
          "BranchNumber": 4,
          "SubmitDate": "10-15-2019",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 3973013,
          "ParentWorkOrder": 3962773,
          "JobName": "NOF Meijer Store 159",
          "JobNumber": "65-48844-5",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6325 S Gilmore Rd",
          "JobAddress2": "",
          "JobFullAddress": "6325 S Gilmore Rd",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,849.08",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 3973012,
          "ParentWorkOrder": 3962785,
          "JobName": "NOF Fenton House",
          "JobNumber": "502-876650-313",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15326 Bealfred Dr",
          "JobAddress2": "",
          "JobFullAddress": "15326 Bealfred Dr",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,447.53",
          "BranchNumber": 8,
          "SubmitDate": "10-15-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3973011,
          "ParentWorkOrder": 3961800,
          "JobName": "(NOF)Flex High School",
          "JobNumber": "502-856071-85",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1245 South Center Rd",
          "JobAddress2": "",
          "JobFullAddress": "1245 South Center Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$20,289.37",
          "BranchNumber": 8,
          "SubmitDate": "10-15-2019",
          "ClientonJob": "M & V CONSTRUCTION"
        },
        {
          "WorkOrder": 3973010,
          "ParentWorkOrder": 3969043,
          "JobName": "(NOF)19-9053 AH SJPHS ICU",
          "JobNumber": "502-815250-347",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16001 W Nine Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "16001 W Nine Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$250,006.00",
          "BalanceRemaining": "$8,958.54",
          "BranchNumber": 8,
          "SubmitDate": "10-15-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3972501,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Nothing Bundt Cakes",
          "JobNumber": "502-1304186-50",
          "FirstdayontheJob": "10-10-2019",
          "LastdayontheJob": "10-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7427 W Central Avenue",
          "JobAddress2": "",
          "JobFullAddress": "7427 W Central Avenue",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$3,799.38",
          "BranchNumber": 13,
          "SubmitDate": "10-11-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3972500,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Circle K #5727",
          "JobNumber": "502-1304186-49",
          "FirstdayontheJob": "10-10-2019",
          "LastdayontheJob": "10-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3516 Airport Hwy",
          "JobAddress2": "",
          "JobFullAddress": "3516 Airport Hwy",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,410.99",
          "BranchNumber": 13,
          "SubmitDate": "10-11-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3972499,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Erie County Board of",
          "JobNumber": "502-132412-8",
          "FirstdayontheJob": "10-10-2019",
          "LastdayontheJob": "10-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3916 Perkins Ave",
          "JobAddress2": "",
          "JobFullAddress": "3916 Perkins Ave",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "D&G Focht Construction Co",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "10-11-2019",
          "ClientonJob": "D&G Focht Construction Co"
        },
        {
          "WorkOrder": 3972498,
          "ParentWorkOrder": "",
          "JobName": "NOF 127 FINDLAY STREET",
          "JobNumber": "65-2134789-12",
          "FirstdayontheJob": "10-10-2019",
          "LastdayontheJob": "10-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "MARKET SQUARE 3 - SEE LEGAL",
          "JobAddress2": "",
          "JobFullAddress": "MARKET SQUARE 3 - SEE LEGAL",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "COX DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,120.72",
          "BranchNumber": 12,
          "SubmitDate": "10-11-2019",
          "ClientonJob": "COX DRYWALL"
        },
        {
          "WorkOrder": 3972363,
          "ParentWorkOrder": "",
          "JobName": "NOF NEXTEP 1082-19",
          "JobNumber": "502-952599-23",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3310 W Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "3310 W Big Beaver Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$7,264.60",
          "BranchNumber": 9,
          "SubmitDate": "10-10-2019",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3972362,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Cooper Standard",
          "JobNumber": "502-944100-326",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "40300 Traditions Dr",
          "JobAddress2": "",
          "JobFullAddress": "40300 Traditions Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$11,996.82",
          "BranchNumber": 9,
          "SubmitDate": "10-10-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3972361,
          "ParentWorkOrder": "",
          "JobName": "(NOF)EMAGINE THEATER",
          "JobNumber": "502-944100-272",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "200 N MAIN ST.",
          "JobAddress2": "",
          "JobFullAddress": "200 N MAIN ST.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "-$89.41",
          "BranchNumber": 9,
          "SubmitDate": "10-10-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3972360,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Taco Bell Wauseon",
          "JobNumber": "502-916315-144",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1462 N Shoop Ave",
          "JobAddress2": "",
          "JobFullAddress": "1462 N Shoop Ave",
          "JobCounty": "Fulton",
          "JobState": "Ohio",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$18,006.00",
          "BalanceRemaining": "$49.46",
          "BranchNumber": 9,
          "SubmitDate": "10-10-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3972359,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Heartland Dental Off",
          "JobNumber": "502-877300-29",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "102 W. Highland Road",
          "JobAddress2": "",
          "JobFullAddress": "102 W. Highland Road",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR DESIGNS INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-10-2019",
          "ClientonJob": "SUPERIOR DESIGNS INC."
        },
        {
          "WorkOrder": 3972358,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HMOR-Surrey Lane",
          "JobNumber": "502-877250-134",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4739 Surrey Lane",
          "JobAddress2": "",
          "JobFullAddress": "4739 Surrey Lane",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Cons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$4,732.50",
          "BranchNumber": 8,
          "SubmitDate": "10-10-2019",
          "ClientonJob": "Blue Water Drywall & Cons"
        },
        {
          "WorkOrder": 3972357,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Tech Plaza",
          "JobNumber": "502-876650-322",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8393 E 12 Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "8393 E 12 Mile Road",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-10-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3972356,
          "ParentWorkOrder": "",
          "JobName": "(NOF)IHA Pinkney",
          "JobNumber": "502-825063-52",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10200 Dexter Pinckney Rd",
          "JobAddress2": "",
          "JobFullAddress": "10200 Dexter Pinckney Rd",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHAFFER INCORPORATED",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-10-2019",
          "ClientonJob": "SHAFFER INCORPORATED"
        },
        {
          "WorkOrder": 3972355,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Senior Planning",
          "JobNumber": "502-815630-220",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "38695 7 Mile Rd Ste 125",
          "JobAddress2": "",
          "JobFullAddress": "38695 7 Mile Rd Ste 125",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-10-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3972353,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Redford Dist. Court 17th Judicial",
          "JobNumber": "502-814500-81",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15111 Beech Daly Rd",
          "JobAddress2": "",
          "JobFullAddress": "15111 Beech Daly Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$17,343.68",
          "BranchNumber": 8,
          "SubmitDate": "10-10-2019",
          "ClientonJob": "COMMERCIAL INT CONST"
        },
        {
          "WorkOrder": 3972352,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Kroger Plymouth",
          "JobNumber": "502-20010-26",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15455 N Haggerty Rd",
          "JobAddress2": "",
          "JobFullAddress": "15455 N Haggerty Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-10-2019",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 3972351,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Loft Apartments",
          "JobNumber": "502-1690441-5",
          "FirstdayontheJob": "10-09-2019",
          "LastdayontheJob": "10-09-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "123 E. Wesley St.",
          "JobAddress2": "",
          "JobFullAddress": "123 E. Wesley St.",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "MUDMAN DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$2,715.95",
          "BranchNumber": 7,
          "SubmitDate": "10-10-2019",
          "ClientonJob": "MUDMAN DRYWALL"
        },
        {
          "WorkOrder": 3970907,
          "ParentWorkOrder": 3960046,
          "JobName": "(NOF) ST OF MI DHHS 19064",
          "JobNumber": "502-933550-171",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "427 E ALCOTT ST",
          "JobAddress2": "",
          "JobFullAddress": "427 E ALCOTT ST",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$28,869.80",
          "BranchNumber": 9,
          "SubmitDate": "10-04-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3970803,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SOLA SALON",
          "JobNumber": "502-137665-11",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17245 SILVER PARKWAY",
          "JobAddress2": "",
          "JobFullAddress": "17245 SILVER PARKWAY",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BANYAN CONSTRUCTION SERVICES LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$10,686.71",
          "BranchNumber": 13,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "BANYAN CONSTRUCTION SERVICES LLC"
        },
        {
          "WorkOrder": 3970802,
          "ParentWorkOrder": "",
          "JobName": "NOF Marriot Hotel",
          "JobNumber": "65-127390-2",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "500 W 3rd St",
          "JobAddress2": "",
          "JobFullAddress": "500 W 3rd St",
          "JobCounty": "",
          "JobState": "Kentucky",
          "CustomerCompanyName": "A-1 TROPIC PAINTING &",
          "RequestType": "Notice of Intent to File a Lien (Commercial)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "On Hold",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$19,151.26",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "A-1 TROPIC PAINTING &"
        },
        {
          "WorkOrder": 3970801,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RADIO CHEMISTRY LAB",
          "JobNumber": "502-1693475-2",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1012 N WALNUT ST",
          "JobAddress2": "",
          "JobFullAddress": "1012 N WALNUT ST",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "NIOWAVE PROPERTIES INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "NIOWAVE PROPERTIES INC."
        },
        {
          "WorkOrder": 3970800,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Summit 8",
          "JobNumber": "502-1604171-2",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13800 Cavaliere Dr",
          "JobAddress2": "",
          "JobFullAddress": "13800 Cavaliere Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DISTINCTIVE GRADING & DESIGN LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$6,132.47",
          "BranchNumber": 8,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "DISTINCTIVE GRADING & DESIGN LLC"
        },
        {
          "WorkOrder": 3970799,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RED EFFECT GYM",
          "JobNumber": "502-1507798-29",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "110 W ANN ARBOR RD",
          "JobAddress2": "",
          "JobFullAddress": "110 W ANN ARBOR RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$317.09",
          "BranchNumber": 9,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3970798,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DOLLARGENERAL CFW",
          "JobNumber": "502-1160305-2",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1075 N. MAIN STREET",
          "JobAddress2": "",
          "JobFullAddress": "1075 N. MAIN STREET",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "INNOVATIVE CONSTRUCTION",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,229.43",
          "BranchNumber": 1,
          "SubmitDate": "",
          "ClientonJob": "INNOVATIVE CONSTRUCTION"
        },
        {
          "WorkOrder": 3970797,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CARMAX #6041",
          "JobNumber": "502-1060142-16",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4431 28TH ST SE",
          "JobAddress2": "",
          "JobFullAddress": "4431 28TH ST SE",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$7,654.15",
          "BranchNumber": 5,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 3970796,
          "ParentWorkOrder": "",
          "JobName": "(NOF) OKEMOS MARRIOT PHS2",
          "JobNumber": "502-990401-52",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2300 Jolly Oak Rd",
          "JobAddress2": "",
          "JobFullAddress": "2300 Jolly Oak Rd",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$1,006.00",
          "BalanceRemaining": "$269.38",
          "BranchNumber": 7,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3970795,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CITY OF DETROIT-DPD",
          "JobNumber": "502-949575-114",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5100 E NEVADA ST",
          "JobAddress2": "",
          "JobFullAddress": "5100 E NEVADA ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$8,779.76",
          "BranchNumber": 9,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3970794,
          "ParentWorkOrder": "",
          "JobName": "(NOF) RIVIERA THEATER",
          "JobNumber": "502-944100-321",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30170 Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "30170 Grand River Ave",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3970793,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GM Pontiac Plant",
          "JobNumber": "502-916331-12",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "800 N Glenwood Ave",
          "JobAddress2": "",
          "JobFullAddress": "800 N Glenwood Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "IDEAL CONTRACTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$4,009.63",
          "BranchNumber": 9,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "IDEAL CONTRACTING LLC"
        },
        {
          "WorkOrder": 3970792,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Taco Bell Dundee",
          "JobNumber": "502-916315-142",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "537 Tecumseh St",
          "JobAddress2": "",
          "JobFullAddress": "537 Tecumseh St",
          "JobCounty": "Monroe",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$6,455.23",
          "BranchNumber": 9,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3970791,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Signature Smiles",
          "JobNumber": "502-916315-137",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8130 Grand River Rd",
          "JobAddress2": "",
          "JobFullAddress": "8130 Grand River Rd",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$5,114.26",
          "BranchNumber": 9,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3970790,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1574 - SOUL CYCLE",
          "JobNumber": "502-913820-536",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1107 S University Ave",
          "JobAddress2": "",
          "JobFullAddress": "1107 S University Ave",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,657.78",
          "BranchNumber": 9,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3970789,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dollar General",
          "JobNumber": "502-864751-205",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4534 N Eastman Rd",
          "JobAddress2": "",
          "JobFullAddress": "4534 N Eastman Rd",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "STILES DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$2,448.90",
          "BranchNumber": 11,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "STILES DRYWALL LLC"
        },
        {
          "WorkOrder": 3970788,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Metamora Bldg & Dev",
          "JobNumber": "502-864751-204",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15124 Kercheval Ave",
          "JobAddress2": "",
          "JobFullAddress": "15124 Kercheval Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "STILES DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$3,466.41",
          "BranchNumber": 11,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "STILES DRYWALL LLC"
        },
        {
          "WorkOrder": 3970787,
          "ParentWorkOrder": "",
          "JobName": "(NOF)31511 Dequindre",
          "JobNumber": "502-843701-202",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31511 Dequindre Rd",
          "JobAddress2": "",
          "JobFullAddress": "31511 Dequindre Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 3970786,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Columbia Sportswear",
          "JobNumber": "502-823400-50",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4000 Baldwin Rd",
          "JobAddress2": "",
          "JobFullAddress": "4000 Baldwin Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PARALLEL CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "PARALLEL CONSTRUCTION"
        },
        {
          "WorkOrder": 3970785,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3080 MottCC Southern",
          "JobNumber": "502-813200-130",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2100 West Thompson Rd.",
          "JobAddress2": "",
          "JobFullAddress": "2100 West Thompson Rd.",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$90,006.00",
          "BalanceRemaining": "$14,153.24",
          "BranchNumber": 8,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3970784,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FCA Mack",
          "JobNumber": "502-812350-86",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4000 Saint Jean St",
          "JobAddress2": "",
          "JobFullAddress": "4000 Saint Jean St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,949.78",
          "BranchNumber": 8,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 3970783,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DaVita Detroit",
          "JobNumber": "502-16302-2",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11707 Whittier St",
          "JobAddress2": "",
          "JobFullAddress": "11707 Whittier St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "JONSTIN CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$96.99",
          "BranchNumber": 8,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "JONSTIN CONSTRUCTION INC"
        },
        {
          "WorkOrder": 3970782,
          "ParentWorkOrder": "",
          "JobName": "(NOF)O'Reilly's",
          "JobNumber": "502-11041-28",
          "FirstdayontheJob": "10-02-2019",
          "LastdayontheJob": "10-02-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8988 E Lansing Rd",
          "JobAddress2": "",
          "JobFullAddress": "8988 E Lansing Rd",
          "JobCounty": "SHIAWASSEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Complete Acoustical",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$12,410.98",
          "BranchNumber": 1,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "Complete Acoustical"
        },
        {
          "WorkOrder": 3970657,
          "ParentWorkOrder": 3960048,
          "JobName": "(NOF) Peterbuilt",
          "JobNumber": "502-967075-116",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17607 Commerce Dr",
          "JobAddress2": "",
          "JobFullAddress": "17607 Commerce Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$6,491.10",
          "BranchNumber": 7,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 3970656,
          "ParentWorkOrder": 3942781,
          "JobName": "(NOF)1540 ARTHAUS FURN",
          "JobNumber": "502-913820-514",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,223.27",
          "BranchNumber": 9,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3970655,
          "ParentWorkOrder": 3962789,
          "JobName": "(NOF)1568 ARHAUS FURNITUR",
          "JobNumber": "502-913820-530",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$219.43",
          "BranchNumber": 9,
          "SubmitDate": "10-03-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3969246,
          "ParentWorkOrder": 3956786,
          "JobName": "(NOF) Eagle Village",
          "JobNumber": "502-30420-12",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17235 ADVENTURE RD",
          "JobAddress2": "",
          "JobFullAddress": "17235 ADVENTURE RD",
          "JobCounty": "OSCEOLA",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENALI CONSTRUCTION, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$3,052.39",
          "BranchNumber": 1,
          "SubmitDate": "09-28-2019",
          "ClientonJob": "DENALI CONSTRUCTION, INC."
        },
        {
          "WorkOrder": 3969245,
          "ParentWorkOrder": 3956795,
          "JobName": "(NOF)19-5093 Pomeroy",
          "JobNumber": "502-920940-66",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3500 South Boulevard W",
          "JobAddress2": "",
          "JobFullAddress": "3500 South Boulevard W",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$452.33",
          "BranchNumber": 8,
          "SubmitDate": "09-28-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3969244,
          "ParentWorkOrder": 3955294,
          "JobName": "(NOF)1555 CARDIO RM 1311",
          "JobNumber": "502-913820-515",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 E MEDICAL CENTER DR",
          "JobAddress2": "",
          "JobFullAddress": "1500 E MEDICAL CENTER DR",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$262.27",
          "BranchNumber": 9,
          "SubmitDate": "09-28-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3969243,
          "ParentWorkOrder": 3942771,
          "JobName": "(NOF)19-9032 SJMO MCI pharmacy",
          "JobNumber": "502-815250-338",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44405 Woodward Ave.",
          "JobAddress2": "",
          "JobFullAddress": "44405 Woodward Ave.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,834.23",
          "BranchNumber": 8,
          "SubmitDate": "09-28-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3969044,
          "ParentWorkOrder": 3956788,
          "JobName": "(NOF)Mill Creek Middle",
          "JobNumber": "502-813200-120",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7305 Dexter Ann Arbor Rd",
          "JobAddress2": "",
          "JobFullAddress": "7305 Dexter Ann Arbor Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$1,806.77",
          "BranchNumber": 8,
          "SubmitDate": "09-27-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3969043,
          "ParentWorkOrder": 3960040,
          "JobName": "(NOF)19-9053 AH SJPHS ICU",
          "JobNumber": "502-815250-347",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16001 W Nine Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "16001 W Nine Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$250,006.00",
          "BalanceRemaining": "$8,958.54",
          "BranchNumber": 8,
          "SubmitDate": "09-27-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3969042,
          "ParentWorkOrder": 3960045,
          "JobName": "(NOF)BERKSHIRE SUPPLY",
          "JobNumber": "502-933550-160",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "40333 14 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "40333 14 MILE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$180,006.00",
          "BalanceRemaining": "$18,388.06",
          "BranchNumber": 9,
          "SubmitDate": "09-27-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3969041,
          "ParentWorkOrder": 3960034,
          "JobName": "(NOF)Kroger Frankenmuth",
          "JobNumber": "502-20010-24",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "435 N Main Street",
          "JobAddress2": "",
          "JobFullAddress": "435 N Main Street",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$20,448.12",
          "BranchNumber": 8,
          "SubmitDate": "09-27-2019",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 3968959,
          "ParentWorkOrder": 3960038,
          "JobName": "(NOF)Faurecia HQ",
          "JobNumber": "502-813200-128",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 High Meadow Circle",
          "JobAddress2": "",
          "JobFullAddress": "2800 High Meadow Circle",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$472.13",
          "BranchNumber": 8,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3968907,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DUKE ENERGY GARAGE",
          "JobNumber": "65-510590-22",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "424 GEST STREET",
          "JobAddress2": "",
          "JobFullAddress": "424 GEST STREET",
          "JobCounty": "Hamilton",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRIVERSITY CRAFT FORCE LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$1,436.80",
          "BranchNumber": 2,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "TRIVERSITY CRAFT FORCE LLC"
        },
        {
          "WorkOrder": 3968906,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LUTHERAN SOCIAL SERV",
          "JobNumber": "65-422745-77",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2225 N Cassady Ave",
          "JobAddress2": "",
          "JobFullAddress": "2225 N Cassady Ave",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "J. YANKLE COMPANY LTD.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$85,006.00",
          "BalanceRemaining": "$24.94",
          "BranchNumber": 4,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "J. YANKLE COMPANY LTD."
        },
        {
          "WorkOrder": 3968905,
          "ParentWorkOrder": "",
          "JobName": "NOF Comfort Inn/Mainstay",
          "JobNumber": "65-401800-3",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2347 Reading Rd",
          "JobAddress2": "",
          "JobFullAddress": "2347 Reading Rd",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "BEST SUPPLY INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$4,003.20",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "BEST SUPPLY INC"
        },
        {
          "WorkOrder": 3968904,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TC ROGERS HOMES",
          "JobNumber": "65-27529-4",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6029 WINDING CREEK BLVD",
          "JobAddress2": "",
          "JobFullAddress": "6029 WINDING CREEK BLVD",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "NEWPHASE DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$7,000.00",
          "BalanceRemaining": "$2,082.34",
          "BranchNumber": 2,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "NEWPHASE DRYWALL LLC"
        },
        {
          "WorkOrder": 3968900,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Ascension Health",
          "JobNumber": "502-1605400-4",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27483 Dequindre Rd",
          "JobAddress2": "",
          "JobFullAddress": "27483 Dequindre Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Carpenter Brothers",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$2,550.78",
          "BranchNumber": 8,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "Carpenter Brothers"
        },
        {
          "WorkOrder": 3968899,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Augie's Bar",
          "JobNumber": "502-1605101-4",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31660 John R Rd",
          "JobAddress2": "",
          "JobFullAddress": "31660 John R Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ARCHITECTURAL PLANNERS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$528.63",
          "BranchNumber": 8,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "ARCHITECTURAL PLANNERS"
        },
        {
          "WorkOrder": 3968898,
          "ParentWorkOrder": "",
          "JobName": "(NOF)World Wide Plaza",
          "JobNumber": "502-1605101-2",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34701 Grand River Ave.",
          "JobAddress2": "",
          "JobFullAddress": "34701 Grand River Ave.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ARCHITECTURAL PLANNERS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$14,957.25",
          "BranchNumber": 8,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "ARCHITECTURAL PLANNERS INC"
        },
        {
          "WorkOrder": 3968897,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ST JOHN EPSI CHURCH",
          "JobNumber": "502-1507798-28",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2326 WOODWARD AVE",
          "JobAddress2": "",
          "JobFullAddress": "2326 WOODWARD AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$48,006.00",
          "BalanceRemaining": "-$601.52",
          "BranchNumber": 9,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3968896,
          "ParentWorkOrder": "",
          "JobName": "(NOF) VERMONT ST",
          "JobNumber": "502-1325000-18",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "56 VERMONT DR",
          "JobAddress2": "",
          "JobFullAddress": "56 VERMONT DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$5,921.92",
          "BranchNumber": 5,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 3968895,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Federal Plus C.U.",
          "JobNumber": "502-1165033-2",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8075 Lapeer Rd.",
          "JobAddress2": "",
          "JobFullAddress": "8075 Lapeer Rd.",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "RUSSELL PLASTERING CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$1,893.41",
          "BranchNumber": 8,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "RUSSELL PLASTERING CO"
        },
        {
          "WorkOrder": 3968894,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FAMILY DOLLAR",
          "JobNumber": "502-949575-113",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24133 GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "24133 GRAND RIVER AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$5,446.19",
          "BranchNumber": 9,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3968893,
          "ParentWorkOrder": "",
          "JobName": "NOF Bank of Ann Arbor",
          "JobNumber": "502-941950-9",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "801 W Ellsworth Rd",
          "JobAddress2": "",
          "JobFullAddress": "801 W Ellsworth Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "GREAT LAKES CEILING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,196.94",
          "BranchNumber": 9,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "GREAT LAKES CEILING"
        },
        {
          "WorkOrder": 3968892,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Taco Bell Monroe",
          "JobNumber": "502-916315-139",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14495 Laplaisance Rd.",
          "JobAddress2": "",
          "JobFullAddress": "14495 Laplaisance Rd.",
          "JobCounty": "Monroe",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$55.25",
          "BranchNumber": 9,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3968891,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Haase Residence",
          "JobNumber": "502-876650-315",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "552 Fernhurst Ct",
          "JobAddress2": "",
          "JobFullAddress": "552 Fernhurst Ct",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,040.67",
          "BranchNumber": 8,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3968889,
          "ParentWorkOrder": "",
          "JobName": "NOF Gongos",
          "JobNumber": "502-843701-200",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "150 W. Second Street",
          "JobAddress2": "",
          "JobFullAddress": "150 W. Second Street",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,406.47",
          "BranchNumber": 8,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 3968888,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ORION DPW",
          "JobNumber": "502-823400-51",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2685 Joslyn Rd",
          "JobAddress2": "",
          "JobFullAddress": "2685 Joslyn Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "PARALLEL CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "PARALLEL CONSTRUCTION"
        },
        {
          "WorkOrder": 3968887,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 100 Eliot",
          "JobNumber": "502-823300-75",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 Eliot St / 100 Mack St",
          "JobAddress2": "",
          "JobFullAddress": "100 Eliot St / 100 Mack St",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,386.48",
          "BranchNumber": 8,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 3968886,
          "ParentWorkOrder": "",
          "JobName": "(NOF)18-8066 DTE WCB Ph2",
          "JobNumber": "502-815250-348",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Center 2nd Floor",
          "JobAddress2": "",
          "JobFullAddress": "1 Energy Center 2nd Floor",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3968885,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HASTINGS INSURANCE",
          "JobNumber": "502-51950-43",
          "FirstdayontheJob": "09-25-2019",
          "LastdayontheJob": "09-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "404 E WOODLAWN AVE",
          "JobAddress2": "",
          "JobFullAddress": "404 E WOODLAWN AVE",
          "JobCounty": "Barry",
          "JobState": "Michigan",
          "CustomerCompanyName": "SOBIE COMPANY, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$2,554.60",
          "BranchNumber": 5,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "SOBIE COMPANY, INC."
        },
        {
          "WorkOrder": 3968764,
          "ParentWorkOrder": 3946552,
          "JobName": "(NOF)Delta Kelly Elementa",
          "JobNumber": "502-920940-48",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3880 Adams Rd",
          "JobAddress2": "",
          "JobFullAddress": "3880 Adams Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,849.99",
          "BranchNumber": 8,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3968763,
          "ParentWorkOrder": 3946549,
          "JobName": "(NOF) Waterford Beaumont",
          "JobNumber": "502-920940-43",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6532 Elizabeth Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "6532 Elizabeth Lake Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$17,755.88",
          "BranchNumber": 8,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3968762,
          "ParentWorkOrder": 3942784,
          "JobName": "(NOF)Redford Thirsten Hig",
          "JobNumber": "502-920940-64",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26255 Schoolcraft Rd",
          "JobAddress2": "#18-5041 Cafeteria",
          "JobFullAddress": "26255 Schoolcraft Rd #18-5041 Cafeteria",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,138.62",
          "BranchNumber": 8,
          "SubmitDate": "09-26-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3968598,
          "ParentWorkOrder": 3950661,
          "JobName": "(NOF)Parsons Elementary",
          "JobNumber": "502-920940-46",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14473 Middle Gibraltar Rd",
          "JobAddress2": "",
          "JobFullAddress": "14473 Middle Gibraltar Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Company",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,073.54",
          "BranchNumber": 8,
          "SubmitDate": "09-25-2019",
          "ClientonJob": "Clark Construction Company"
        },
        {
          "WorkOrder": 3967902,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SADDLEBROOK FARMS",
          "JobNumber": "502-1668468-3",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "495 SADDLEBROOK LN",
          "JobAddress2": "",
          "JobFullAddress": "495 SADDLEBROOK LN",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "WADE JURNEY HOMES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "WADE JURNEY HOMES"
        },
        {
          "WorkOrder": 3967901,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 3RD & GRAND APART",
          "JobNumber": "502-1650066-4",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2911 W Grand Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2911 W Grand Blvd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PCC INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$18,295.19",
          "BranchNumber": 8,
          "SubmitDate": "09-24-2019",
          "ClientonJob": "PCC INTERIORS"
        },
        {
          "WorkOrder": 3967900,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Pontiac Court House",
          "JobNumber": "502-1609003-2",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "70 N Saginaw St",
          "JobAddress2": "",
          "JobFullAddress": "70 N Saginaw St",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "North American Construction Enterprises",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$687.79",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "North American Construction Enterprises"
        },
        {
          "WorkOrder": 3967899,
          "ParentWorkOrder": "",
          "JobName": "(NOF) The Griffin",
          "JobNumber": "502-1605903-2",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25000 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "25000 Woodward Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "CLOVER CONSTRUCTION SERVICES INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,000.00",
          "BalanceRemaining": "$725.04",
          "BranchNumber": 8,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "CLOVER CONSTRUCTION SERVICES INC"
        },
        {
          "WorkOrder": 3967898,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Suburban Collision",
          "JobNumber": "502-1551310-5",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1520 Temple City Drive",
          "JobAddress2": "",
          "JobFullAddress": "1520 Temple City Drive",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELITE BUILDERS LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,907.32",
          "BranchNumber": 11,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "ELITE BUILDERS LLC"
        },
        {
          "WorkOrder": 3967897,
          "ParentWorkOrder": "",
          "JobName": "(NOF) S.A.U. Fitness Cntr",
          "JobNumber": "502-967075-117",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "106 E. Main St",
          "JobAddress2": "",
          "JobFullAddress": "106 E. Main St",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$22,356.90",
          "BranchNumber": 7,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 3967896,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MISC.",
          "JobNumber": "502-953100-7",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8445 JUDD RD",
          "JobAddress2": "",
          "JobFullAddress": "8445 JUDD RD",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "GIPFERT'S CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$196.14",
          "BranchNumber": 7,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "GIPFERT'S CONST."
        },
        {
          "WorkOrder": 3967895,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KROGER",
          "JobNumber": "502-944100-323",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2051 18 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "2051 18 MILE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,896.54",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3967894,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RIVIERA CINEMA",
          "JobNumber": "502-944100-322",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30170 Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "30170 Grand River Ave",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3967893,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HOUSE",
          "JobNumber": "502-936150-57",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6960 TERNES ST",
          "JobAddress2": "",
          "JobFullAddress": "6960 TERNES ST",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "ABSTRACT CEILINGS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "ABSTRACT CEILINGS, INC."
        },
        {
          "WorkOrder": 3967892,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Burger King Lansing",
          "JobNumber": "502-920300-89",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3311 S Martin Luther King Jr Blvd",
          "JobAddress2": "",
          "JobFullAddress": "3311 S Martin Luther King Jr Blvd",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 3967891,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DIA Collections",
          "JobNumber": "502-916315-141",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5200 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "5200 Woodward Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,600.08",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3967890,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Big Rock Cultivation",
          "JobNumber": "502-916315-140",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "700 N Main Street",
          "JobAddress2": "",
          "JobFullAddress": "700 N Main Street",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$120,006.00",
          "BalanceRemaining": "$28,023.77",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3967889,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1579 EVERYTHING BUT",
          "JobNumber": "502-913820-542",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$271.09",
          "BranchNumber": 9,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3967888,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1540 CATH LAB",
          "JobNumber": "502-913820-501",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "ST. JOHN MACOMB",
          "JobAddress2": "",
          "JobFullAddress": "ST. JOHN MACOMB",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$171.46",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3967887,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Merrillwood Building",
          "JobNumber": "502-876650-314",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "225 E Merrill St",
          "JobAddress2": "",
          "JobFullAddress": "225 E Merrill St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$267.43",
          "BranchNumber": 8,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3967886,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Wirtz Manufacturing",
          "JobNumber": "502-861787-38",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1105 24th ST",
          "JobAddress2": "",
          "JobFullAddress": "1105 24th ST",
          "JobCounty": "Saint Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "JFS Company"
        },
        {
          "WorkOrder": 3967885,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HMOR-22154 28 Mile",
          "JobNumber": "502-861507-51",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22154 28 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "22154 28 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "LARRY CLEVELAND",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$4,899.18",
          "BranchNumber": 11,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "LARRY CLEVELAND"
        },
        {
          "WorkOrder": 3967884,
          "ParentWorkOrder": "",
          "JobName": "(NOF) IHA BRIGHTON",
          "JobNumber": "502-825063-51",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5505 S OLD US 23",
          "JobAddress2": "",
          "JobFullAddress": "5505 S OLD US 23",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHAFFER INCORPORATED",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "SHAFFER INCORPORATED"
        },
        {
          "WorkOrder": 3967883,
          "ParentWorkOrder": "",
          "JobName": "(NOF) UofD Mercy",
          "JobNumber": "502-823300-74",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4001 W. McNichols Rd",
          "JobAddress2": "",
          "JobFullAddress": "4001 W. McNichols Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 3967882,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDONALDS#14664",
          "JobNumber": "502-13380-132",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8760 E LANSING RD.",
          "JobAddress2": "",
          "JobFullAddress": "8760 E LANSING RD.",
          "JobCounty": "Shiawassee",
          "JobState": "Michigan",
          "CustomerCompanyName": "RAYMOND RENOVATIONS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$13,006.00",
          "BalanceRemaining": "$12,188.52",
          "BranchNumber": 1,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "RAYMOND RENOVATIONS"
        },
        {
          "WorkOrder": 3967881,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Mitec Powertrain",
          "JobNumber": "502-1301172-47",
          "FirstdayontheJob": "09-22-2019",
          "LastdayontheJob": "09-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4000 Fostoria Ave",
          "JobAddress2": "",
          "JobFullAddress": "4000 Fostoria Ave",
          "JobCounty": "HANCOCK",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$11,570.50",
          "BranchNumber": 13,
          "SubmitDate": "09-23-2019",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 3965900,
          "ParentWorkOrder": 3952789,
          "JobName": "(NOF)ATI Physical Lansing",
          "JobNumber": "502-948945-56",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3415 E Saginaw St",
          "JobAddress2": "",
          "JobFullAddress": "3415 E Saginaw St",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trinity Building Group",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$2,567.57",
          "BranchNumber": 9,
          "SubmitDate": "09-16-2019",
          "ClientonJob": "Trinity Building Group"
        },
        {
          "WorkOrder": 3965895,
          "ParentWorkOrder": 3952787,
          "JobName": "(NOF) Quicken Loans",
          "JobNumber": "502-933550-168",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "660 WOODWARD AVE",
          "JobAddress2": "",
          "JobFullAddress": "660 WOODWARD AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$18,747.99",
          "BranchNumber": 9,
          "SubmitDate": "09-16-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3965647,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OAKHURST HOUTHOOFD BUILDERS",
          "JobNumber": "502-1650969-58",
          "FirstdayontheJob": "09-12-2019",
          "LastdayontheJob": "09-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6078 Oakhurst Park Dr",
          "JobAddress2": "",
          "JobFullAddress": "6078 Oakhurst Park Dr",
          "JobCounty": "Tuscola",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$2,769.32",
          "BranchNumber": 1,
          "SubmitDate": "09-13-2019",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 3965646,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Orchard Lake Plaza",
          "JobNumber": "502-989801-9",
          "FirstdayontheJob": "09-12-2019",
          "LastdayontheJob": "09-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "28859 Orchard Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "28859 Orchard Lake Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "RC Plastering",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,151.25",
          "BranchNumber": 9,
          "SubmitDate": "09-13-2019",
          "ClientonJob": "RC Plastering"
        },
        {
          "WorkOrder": 3965645,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Golden Rule Invest.",
          "JobNumber": "502-861787-37",
          "FirstdayontheJob": "09-12-2019",
          "LastdayontheJob": "09-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "818 Beard St",
          "JobAddress2": "",
          "JobFullAddress": "818 Beard St",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,000.93",
          "BranchNumber": 11,
          "SubmitDate": "09-13-2019",
          "ClientonJob": "JFS Company"
        },
        {
          "WorkOrder": 3965644,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Panda Ex - Hartland",
          "JobNumber": "502-858231-46",
          "FirstdayontheJob": "09-12-2019",
          "LastdayontheJob": "09-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "US23 & M59",
          "JobAddress2": "",
          "JobFullAddress": "US23 & M59",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,135.90",
          "BranchNumber": 11,
          "SubmitDate": "09-13-2019",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 3965620,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Browning Masonic",
          "JobNumber": "502-1611204-27",
          "FirstdayontheJob": "09-12-2019",
          "LastdayontheJob": "09-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8883 Browning Drive",
          "JobAddress2": "",
          "JobFullAddress": "8883 Browning Drive",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "JR Interiors",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$12,500.82",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "JR Interiors"
        },
        {
          "WorkOrder": 3965619,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Kingdom Hall",
          "JobNumber": "502-1312909-3",
          "FirstdayontheJob": "09-12-2019",
          "LastdayontheJob": "09-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5014 Hayes Ave.",
          "JobAddress2": "",
          "JobFullAddress": "5014 Hayes Ave.",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "JW Congregation Support Inc",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,934.55",
          "BranchNumber": 13,
          "SubmitDate": "09-13-2019",
          "ClientonJob": "JW Congregation Support Inc"
        },
        {
          "WorkOrder": 3965618,
          "ParentWorkOrder": "",
          "JobName": "(NOF)UT N. Engineering",
          "JobNumber": "502-1308300-2",
          "FirstdayontheJob": "09-12-2019",
          "LastdayontheJob": "09-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1700 N. Westwood Ave.",
          "JobAddress2": "",
          "JobFullAddress": "1700 N. Westwood Ave.",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "THE SPIEKER COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$15,796.93",
          "BranchNumber": 13,
          "SubmitDate": "09-13-2019",
          "ClientonJob": "THE SPIEKER COMPANY"
        },
        {
          "WorkOrder": 3965617,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Owens Advanced",
          "JobNumber": "502-1305825-24",
          "FirstdayontheJob": "09-12-2019",
          "LastdayontheJob": "09-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "310 3rd Street",
          "JobAddress2": "",
          "JobFullAddress": "310 3rd Street",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$10,083.35",
          "BranchNumber": 13,
          "SubmitDate": "09-13-2019",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 3965616,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Chase Bank Ottawa Hi",
          "JobNumber": "502-1304700-22",
          "FirstdayontheJob": "09-12-2019",
          "LastdayontheJob": "09-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3301 Secor Rd.",
          "JobAddress2": "",
          "JobFullAddress": "3301 Secor Rd.",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "LATHROP TURNER COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$12,616.33",
          "BranchNumber": 13,
          "SubmitDate": "09-13-2019",
          "ClientonJob": "LATHROP TURNER COMPANY"
        },
        {
          "WorkOrder": 3965615,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Bassetts",
          "JobNumber": "502-132412-7",
          "FirstdayontheJob": "09-12-2019",
          "LastdayontheJob": "09-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "D&G Focht Construction Co",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "D&G Focht Construction Co"
        },
        {
          "WorkOrder": 3965614,
          "ParentWorkOrder": "",
          "JobName": "NOF Prefabricated Ext",
          "JobNumber": "65-2136275-6",
          "FirstdayontheJob": "09-12-2019",
          "LastdayontheJob": "09-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2424 Merchant St.",
          "JobAddress2": "",
          "JobFullAddress": "2424 Merchant St.",
          "JobCounty": "",
          "JobState": "Kentucky",
          "CustomerCompanyName": "GRAYHAWK  LLC - LEXINGTON",
          "RequestType": "Notice of Intent to File a Lien (Commercial)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "On Hold",
          "ContractAmount": "$65,006.00",
          "BalanceRemaining": "$34,406.51",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "GRAYHAWK  LLC - LEXINGTON"
        },
        {
          "WorkOrder": 3965613,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FAIRFIELD HOMES",
          "JobNumber": "65-435948-4",
          "FirstdayontheJob": "09-12-2019",
          "LastdayontheJob": "09-12-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "123 S BAKER AVE",
          "JobAddress2": "",
          "JobFullAddress": "123 S BAKER AVE",
          "JobCounty": "FAIRFIELD",
          "JobState": "Ohio",
          "CustomerCompanyName": "DYNASTY DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$688.14",
          "BranchNumber": 4,
          "SubmitDate": "09-13-2019",
          "ClientonJob": "DYNASTY DRYWALL LLC"
        },
        {
          "WorkOrder": 3965157,
          "ParentWorkOrder": 3960043,
          "JobName": "(NOF)DOLLARTREEGLADWIN",
          "JobNumber": "502-876752-10",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "168 JAMES ROBERTSON DR.",
          "JobAddress2": "",
          "JobFullAddress": "168 JAMES ROBERTSON DR.",
          "JobCounty": "GLADWIN",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,456.10",
          "BranchNumber": 8,
          "SubmitDate": "09-11-2019",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 3964053,
          "ParentWorkOrder": 3950660,
          "JobName": "(NOF)Gibralter Chapman",
          "JobNumber": "502-920940-39",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31500 Olmstead Road",
          "JobAddress2": "",
          "JobFullAddress": "31500 Olmstead Road",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Services",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$2,968.07",
          "BranchNumber": 8,
          "SubmitDate": "09-16-2019",
          "ClientonJob": "Clark Construction Services"
        },
        {
          "WorkOrder": 3962805,
          "ParentWorkOrder": "",
          "JobName": "(NOF) St. Clair Inn",
          "JobNumber": "502-1665004-2",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "500 N Riverside Ave",
          "JobAddress2": "",
          "JobFullAddress": "500 N Riverside Ave",
          "JobCounty": "Saint Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "WEST HAVEN BUILDERS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$1,248.01",
          "BranchNumber": 11,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "WEST HAVEN BUILDERS LLC"
        },
        {
          "WorkOrder": 3962804,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HERZOG8532FILLION",
          "JobNumber": "502-1650969-57",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8532 FILION RD",
          "JobAddress2": "",
          "JobFullAddress": "8532 FILION RD",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$3,914.79",
          "BranchNumber": 1,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 3962803,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Havenwood Southgate",
          "JobNumber": "502-1621700-6",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11601 Allen Road",
          "JobAddress2": "",
          "JobFullAddress": "11601 Allen Road",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHINGLETON CONTRACTORS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "SHINGLETON CONTRACTORS"
        },
        {
          "WorkOrder": 3962802,
          "ParentWorkOrder": "",
          "JobName": "NOF 655 PILGRAM AVE",
          "JobNumber": "502-1601110-2",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "655 PILGRIM AVE",
          "JobAddress2": "",
          "JobFullAddress": "655 PILGRIM AVE",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DLM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$1,335.32",
          "BranchNumber": 1,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "DLM DRYWALL"
        },
        {
          "WorkOrder": 3962801,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Metamora Bldg & Dev",
          "JobNumber": "502-1551310-4",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15124 Kercheval Ave",
          "JobAddress2": "",
          "JobFullAddress": "15124 Kercheval Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELITE BUILDERS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$888.52",
          "BranchNumber": 11,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "ELITE BUILDERS LLC"
        },
        {
          "WorkOrder": 3962800,
          "ParentWorkOrder": "",
          "JobName": "(NOF) T-MOBILE PLYMOUTH",
          "JobNumber": "502-1507798-27",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "110 ANN ARBOR RD. W",
          "JobAddress2": "",
          "JobFullAddress": "110 ANN ARBOR RD. W",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3962799,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LANDS END",
          "JobNumber": "502-1395174-52",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3665 28TH ST SE SPC 3535",
          "JobAddress2": "",
          "JobFullAddress": "3665 28TH ST SE SPC 3535",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,404.08",
          "BranchNumber": 5,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3962798,
          "ParentWorkOrder": "",
          "JobName": "NOF Cobblestone",
          "JobNumber": "502-1344933-9",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2883 44th St SE",
          "JobAddress2": "",
          "JobFullAddress": "2883 44th St SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER 1 INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$45,350.36",
          "BranchNumber": 5,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "PREMIER 1 INTERIORS LLC"
        },
        {
          "WorkOrder": 3962797,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SPC",
          "JobNumber": "502-952599-20",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27555 EXECUTIVE DR",
          "JobAddress2": "SUITE 380",
          "JobFullAddress": "27555 EXECUTIVE DR SUITE 380",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,603.28",
          "BranchNumber": 9,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3962796,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MALEK AL-KCBOB",
          "JobNumber": "502-949575-112",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3351 WEST RD",
          "JobAddress2": "",
          "JobFullAddress": "3351 WEST RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3962795,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Edge Fitness Sterlin",
          "JobNumber": "502-934634-11",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2325 18 Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "2325 18 Mile Road",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONST. GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$220.80",
          "BranchNumber": 9,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "BLUESTONE CONST. GROUP"
        },
        {
          "WorkOrder": 3962794,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DIA Command Center",
          "JobNumber": "502-916315-136",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5200 Woodward Ave",
          "JobAddress2": "(back of building)",
          "JobFullAddress": "5200 Woodward Ave (back of building)",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$4,872.11",
          "BranchNumber": 9,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3962793,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Taco Bell Oak Park",
          "JobNumber": "502-916315-135",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21350 Greenfield Rd",
          "JobAddress2": "",
          "JobFullAddress": "21350 Greenfield Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$22,006.00",
          "BalanceRemaining": "$5,490.11",
          "BranchNumber": 9,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3962792,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Lands End West Oaks",
          "JobNumber": "502-916315-134",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "43520 West Oaks Dr",
          "JobAddress2": "",
          "JobFullAddress": "43520 West Oaks Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$10,795.95",
          "BranchNumber": 9,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3962791,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1520 LULULEMON",
          "JobNumber": "502-913820-537",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17420 HALL ROAD",
          "JobAddress2": "",
          "JobFullAddress": "17420 HALL ROAD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$219.50",
          "BranchNumber": 9,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3962790,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1572 OASIS GRILL FAC",
          "JobNumber": "502-913820-535",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1201 S. UNIVERSITY AVE",
          "JobAddress2": "",
          "JobFullAddress": "1201 S. UNIVERSITY AVE",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$1,183.06",
          "BranchNumber": 9,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3962789,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1568 ARHAUS FURNITUR",
          "JobNumber": "502-913820-530",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$219.43",
          "BranchNumber": 9,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3962788,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ICA",
          "JobNumber": "502-911550-8",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35700 W 12 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "35700 W 12 MILE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BEST FINISHERS INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$172.63",
          "BranchNumber": 9,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "BEST FINISHERS INC."
        },
        {
          "WorkOrder": 3962787,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Excel Homes",
          "JobNumber": "502-910400-146",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "49778 Verschave St",
          "JobAddress2": "",
          "JobFullAddress": "49778 Verschave St",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACTION DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,506.00",
          "BalanceRemaining": "$3,710.36",
          "BranchNumber": 9,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "ACTION DRYWALL"
        },
        {
          "WorkOrder": 3962786,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Excel Homes",
          "JobNumber": "502-910400-145",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "49790 Verschave St",
          "JobAddress2": "",
          "JobFullAddress": "49790 Verschave St",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACTION DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,506.00",
          "BalanceRemaining": "$3,710.36",
          "BranchNumber": 9,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "ACTION DRYWALL"
        },
        {
          "WorkOrder": 3962785,
          "ParentWorkOrder": "",
          "JobName": "NOF Fenton House",
          "JobNumber": "502-876650-313",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15326 Bealfred Dr",
          "JobAddress2": "",
          "JobFullAddress": "15326 Bealfred Dr",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,447.53",
          "BranchNumber": 8,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3962784,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Anchor Bay Dance Ctr",
          "JobNumber": "502-858231-47",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35292 23 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "35292 23 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$2,492.52",
          "BranchNumber": 11,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 3962783,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Panda Ex - Midland",
          "JobNumber": "502-858231-45",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6902 Eastman Ave",
          "JobAddress2": "",
          "JobFullAddress": "6902 Eastman Ave",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,258.50",
          "BranchNumber": 11,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 3962782,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ALDI'S GB",
          "JobNumber": "502-814500-80",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6248 S Saginaw Rd",
          "JobAddress2": "",
          "JobFullAddress": "6248 S Saginaw Rd",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "COMMERCIAL INT CONST"
        },
        {
          "WorkOrder": 3962781,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ARTISAN MEDICAL CENT",
          "JobNumber": "502-50435-61",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3340 84TH AVE",
          "JobAddress2": "",
          "JobFullAddress": "3340 84TH AVE",
          "JobCounty": "Ottawa",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR BOUWKAMP & ASSOC. INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "JR BOUWKAMP & ASSOC. INC"
        },
        {
          "WorkOrder": 3962780,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ZFSITHACA",
          "JobNumber": "502-14239-3",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1266 E WASHINGTON RD",
          "JobAddress2": "",
          "JobFullAddress": "1266 E WASHINGTON RD",
          "JobCounty": "Gratiot",
          "JobState": "Michigan",
          "CustomerCompanyName": "Sugar Construction Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,143.58",
          "BranchNumber": 1,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "Sugar Construction Inc."
        },
        {
          "WorkOrder": 3962779,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Browning Masonic",
          "JobNumber": "502-1611204-27",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8883 Browning Drive",
          "JobAddress2": "",
          "JobFullAddress": "8883 Browning Drive",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "JR Interiors",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$9,824.93",
          "BranchNumber": 13,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "JR Interiors"
        },
        {
          "WorkOrder": 3962778,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Kingdom Hall",
          "JobNumber": "502-1312909-3",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5014 Hayes Ave",
          "JobAddress2": "",
          "JobFullAddress": "5014 Hayes Ave",
          "JobCounty": "Erie",
          "JobState": "Ohio",
          "CustomerCompanyName": "JW Congregation Support Inc.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,934.55",
          "BranchNumber": 13,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "JW Congregation Support Inc."
        },
        {
          "WorkOrder": 3962777,
          "ParentWorkOrder": "",
          "JobName": "(NOF)UT N. Engineering",
          "JobNumber": "502-1308300-2",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1700 N. Westwood Ave.",
          "JobAddress2": "",
          "JobFullAddress": "1700 N. Westwood Ave.",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "THE SPIEKER COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$15,796.93",
          "BranchNumber": 13,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "THE SPIEKER COMPANY"
        },
        {
          "WorkOrder": 3962776,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Bassetts",
          "JobNumber": "502-132412-7",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5486 E Harbor Rd",
          "JobAddress2": "",
          "JobFullAddress": "5486 E Harbor Rd",
          "JobCounty": "OTTAWA",
          "JobState": "Ohio",
          "CustomerCompanyName": "D&G Focht Construction Co",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "D&G Focht Construction Co"
        },
        {
          "WorkOrder": 3962775,
          "ParentWorkOrder": "",
          "JobName": "(NOF) VA HOSPITAL",
          "JobNumber": "65-556601-5",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3085 Woodman Dr",
          "JobAddress2": "",
          "JobFullAddress": "3085 Woodman Dr",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "RELIABLE FINISH LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$1,184.00",
          "BranchNumber": 2,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "RELIABLE FINISH LLC"
        },
        {
          "WorkOrder": 3962774,
          "ParentWorkOrder": "",
          "JobName": "(NOF) KROGER",
          "JobNumber": "65-408494-4",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6095 GENDER RD",
          "JobAddress2": "",
          "JobFullAddress": "6095 GENDER RD",
          "JobCounty": "Franklin",
          "JobState": "Ohio",
          "CustomerCompanyName": "HOLDREN INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$15,984.98",
          "BranchNumber": 4,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "HOLDREN INC"
        },
        {
          "WorkOrder": 3962773,
          "ParentWorkOrder": "",
          "JobName": "NOF Meijer Store 159",
          "JobNumber": "65-48844-5",
          "FirstdayontheJob": "09-04-2019",
          "LastdayontheJob": "09-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6325 S Gilmore Rd",
          "JobAddress2": "",
          "JobFullAddress": "6325 S Gilmore Rd",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,849.08",
          "BranchNumber": 4,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 3962696,
          "ParentWorkOrder": 3931194,
          "JobName": "(NOF)Health Grades #4140",
          "JobNumber": "502-817800-39",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "303 E Third Street",
          "JobAddress2": "Suite 210",
          "JobFullAddress": "303 E Third Street Suite 210",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,410.16",
          "BranchNumber": 8,
          "SubmitDate": "09-05-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3962398,
          "ParentWorkOrder": 3950659,
          "JobName": "(NOF) REGENCY",
          "JobNumber": "502-920000-27",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25150 Regency Rd",
          "JobAddress2": "",
          "JobFullAddress": "25150 Regency Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NVR FINISHES, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$8,397.49",
          "BranchNumber": 9,
          "SubmitDate": "09-04-2019",
          "ClientonJob": "NVR FINISHES, INC."
        },
        {
          "WorkOrder": 3961810,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 497 LAKE DR",
          "JobNumber": "502-1650969-55",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "497 LAKE DR",
          "JobAddress2": "",
          "JobFullAddress": "497 LAKE DR",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,506.00",
          "BalanceRemaining": "$9,689.83",
          "BranchNumber": 1,
          "SubmitDate": "08-29-2019",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 3961809,
          "ParentWorkOrder": "",
          "JobName": "NOF HARBOR FRIEGHT",
          "JobNumber": "502-1344933-13",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "408 PERRY AVE",
          "JobAddress2": "",
          "JobFullAddress": "408 PERRY AVE",
          "JobCounty": "MECOSTA",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER 1 INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$13,330.05",
          "BranchNumber": 5,
          "SubmitDate": "08-29-2019",
          "ClientonJob": "PREMIER 1 INTERIORS LLC"
        },
        {
          "WorkOrder": 3961808,
          "ParentWorkOrder": "",
          "JobName": "NOF ALLEN PARK HOTEL",
          "JobNumber": "502-1325000-17",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16850 SOUTHFIELD RD",
          "JobAddress2": "",
          "JobFullAddress": "16850 SOUTHFIELD RD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$20,074.15",
          "BranchNumber": 5,
          "SubmitDate": "08-29-2019",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 3961807,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ALTERNATIVE HEALTH",
          "JobNumber": "502-952599-22",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1975 Technology Dr",
          "JobAddress2": "Ste 120",
          "JobFullAddress": "1975 Technology Dr Ste 120",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,651.52",
          "BranchNumber": 9,
          "SubmitDate": "08-29-2019",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3961806,
          "ParentWorkOrder": "",
          "JobName": "NOF SPH",
          "JobNumber": "502-952599-21",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27725 Stansbury Blvd",
          "JobAddress2": "Ste 375",
          "JobFullAddress": "27725 Stansbury Blvd Ste 375",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$40.33",
          "BranchNumber": 9,
          "SubmitDate": "08-29-2019",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3961805,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CONCENTRA",
          "JobNumber": "502-940900-4",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10940 WAYNE RD",
          "JobAddress2": "",
          "JobFullAddress": "10940 WAYNE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "EJH CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$6,087.26",
          "BranchNumber": 9,
          "SubmitDate": "08-29-2019",
          "ClientonJob": "EJH CONSTRUCTION INC"
        },
        {
          "WorkOrder": 3961804,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CORKTOWN GARAGE",
          "JobNumber": "502-916800-30",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1701 W LAFAYETTE BLVD",
          "JobAddress2": "",
          "JobFullAddress": "1701 W LAFAYETTE BLVD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "JASMAN CONSTRUCTION, INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$8,618.45",
          "BranchNumber": 9,
          "SubmitDate": "08-29-2019",
          "ClientonJob": "JASMAN CONSTRUCTION, INC"
        },
        {
          "WorkOrder": 3961803,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1564 NEWLAND INFUS",
          "JobNumber": "502-913820-525",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31500 TELEGRAPH RD",
          "JobAddress2": "",
          "JobFullAddress": "31500 TELEGRAPH RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$202.11",
          "BranchNumber": 9,
          "SubmitDate": "08-29-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3961802,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dollar General",
          "JobNumber": "502-864751-202",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14005 Webster Rd",
          "JobAddress2": "",
          "JobFullAddress": "14005 Webster Rd",
          "JobCounty": "CLINTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "STILES DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$2,275.95",
          "BranchNumber": 11,
          "SubmitDate": "08-29-2019",
          "ClientonJob": "STILES DRYWALL LLC"
        },
        {
          "WorkOrder": 3961801,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Karrar-Simpson",
          "JobNumber": "502-858231-44",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1720 Elk St",
          "JobAddress2": "",
          "JobFullAddress": "1720 Elk St",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,857.23",
          "BranchNumber": 11,
          "SubmitDate": "08-29-2019",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 3961800,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Flex High School",
          "JobNumber": "502-856071-85",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1245 South Center Rd",
          "JobAddress2": "",
          "JobFullAddress": "1245 South Center Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$20,289.37",
          "BranchNumber": 8,
          "SubmitDate": "08-29-2019",
          "ClientonJob": "M & V CONSTRUCTION"
        },
        {
          "WorkOrder": 3961799,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WarrenTruck WTAP MLM",
          "JobNumber": "502-813200-129",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21500 Mound Rd",
          "JobAddress2": "",
          "JobFullAddress": "21500 Mound Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING CORP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$351.97",
          "BranchNumber": 8,
          "SubmitDate": "08-29-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING CORP"
        },
        {
          "WorkOrder": 3961798,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ELITE DENTAL",
          "JobNumber": "502-50550-67",
          "FirstdayontheJob": "08-28-2019",
          "LastdayontheJob": "08-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6101 KALAMAZOO AVE SE",
          "JobAddress2": "",
          "JobFullAddress": "6101 KALAMAZOO AVE SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "CENTRAL CEILING WEST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$371.53",
          "BranchNumber": 5,
          "SubmitDate": "08-29-2019",
          "ClientonJob": "CENTRAL CEILING WEST"
        },
        {
          "WorkOrder": 3961735,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Browning Masonic",
          "JobNumber": "502-1611204-27",
          "FirstdayontheJob": "08-27-2019",
          "LastdayontheJob": "08-27-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8883 Browning Drive",
          "JobAddress2": "",
          "JobFullAddress": "8883 Browning Drive",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "JR Interiors",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$8,729.57",
          "BranchNumber": 13,
          "SubmitDate": "08-28-2019",
          "ClientonJob": "JR Interiors"
        },
        {
          "WorkOrder": 3961734,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HAMMERMAN GRAF HUGHES & CO",
          "JobNumber": "65-2134688-13",
          "FirstdayontheJob": "08-27-2019",
          "LastdayontheJob": "08-27-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7677 PARAGON RD SUITE A",
          "JobAddress2": "",
          "JobFullAddress": "7677 PARAGON RD SUITE A",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$331.53",
          "BranchNumber": 12,
          "SubmitDate": "08-28-2019",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 3961733,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LIVINGSTON PROJECT",
          "JobNumber": "65-409839-10",
          "FirstdayontheJob": "08-27-2019",
          "LastdayontheJob": "08-27-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1567 E LIVINGSTON AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "1567 E LIVINGSTON AVENUE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "HENDERSON CONT.CORP.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$4,621.93",
          "BranchNumber": 4,
          "SubmitDate": "08-28-2019",
          "ClientonJob": "HENDERSON CONT.CORP."
        },
        {
          "WorkOrder": 3960049,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3262 BEAVER RD",
          "JobNumber": "502-1650969-54",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3262 BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "3262 BEAVER RD",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$17,686.10",
          "BranchNumber": 1,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 3960048,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Peterbuilt",
          "JobNumber": "502-967075-116",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17607 Commerce Dr",
          "JobAddress2": "",
          "JobFullAddress": "17607 Commerce Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$6,491.10",
          "BranchNumber": 7,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 3960047,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FAKAOURY - \"FGI",
          "JobNumber": "502-952599-19",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5250 CORPORATE DR STE 300",
          "JobAddress2": "",
          "JobFullAddress": "5250 CORPORATE DR STE 300",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$5,232.22",
          "BranchNumber": 9,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3960046,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ST OF MI DHHS 19064",
          "JobNumber": "502-933550-171",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "427 E ALCOTT ST",
          "JobAddress2": "",
          "JobFullAddress": "427 E ALCOTT ST",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$28,869.80",
          "BranchNumber": 9,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3960045,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BERKSHIRE SUPPLY",
          "JobNumber": "502-933550-160",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "40333 14 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "40333 14 MILE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$180,006.00",
          "BalanceRemaining": "$18,388.06",
          "BranchNumber": 9,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3960044,
          "ParentWorkOrder": "",
          "JobName": "(NOF) W.CHICAGO",
          "JobNumber": "502-910621-44",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1438 WEST CHICAGO BLVD",
          "JobAddress2": "",
          "JobFullAddress": "1438 WEST CHICAGO BLVD",
          "JobCounty": "Lenawee",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 3960043,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DOLLARTREEGLADWIN",
          "JobNumber": "502-876752-10",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "168 JAMES ROBERTSON DR.",
          "JobAddress2": "",
          "JobFullAddress": "168 JAMES ROBERTSON DR.",
          "JobCounty": "GLADWIN",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,456.10",
          "BranchNumber": 8,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 3960042,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Casper",
          "JobNumber": "502-856071-84",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27500 Novi Road",
          "JobAddress2": "Space C281",
          "JobFullAddress": "27500 Novi Road Space C281",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,690.98",
          "BranchNumber": 8,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "M & V CONSTRUCTION"
        },
        {
          "WorkOrder": 3960041,
          "ParentWorkOrder": "",
          "JobName": "(NOF)New Oakland",
          "JobNumber": "502-815630-219",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29550 5 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "29550 5 Mile Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3960040,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9053 AH SJPHS ICU",
          "JobNumber": "502-815250-347",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16001 W Nine Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "16001 W Nine Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$250,006.00",
          "BalanceRemaining": "$8,958.54",
          "BranchNumber": 8,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3960039,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9048 AH SJP Novi",
          "JobNumber": "502-815250-343",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "47601 Grand River Ave.",
          "JobAddress2": "",
          "JobFullAddress": "47601 Grand River Ave.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,391.58",
          "BranchNumber": 8,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3960038,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Faurecia HQ",
          "JobNumber": "502-813200-128",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 High Meadow Circle",
          "JobAddress2": "",
          "JobFullAddress": "2800 High Meadow Circle",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$472.13",
          "BranchNumber": 8,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3960037,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CHRYSLER MAC 1",
          "JobNumber": "502-195531-11",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4000 ST JEAN ST",
          "JobAddress2": "",
          "JobFullAddress": "4000 ST JEAN ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TravCo Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$3,424.81",
          "BranchNumber": 9,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "TravCo Interiors LLC"
        },
        {
          "WorkOrder": 3960036,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MADEWELL",
          "JobNumber": "502-52900-42",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1830 BRETON RD SE",
          "JobAddress2": "SUITE 400",
          "JobFullAddress": "1830 BRETON RD SE SUITE 400",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "MID MICHIGAN CEILING &",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "MID MICHIGAN CEILING &"
        },
        {
          "WorkOrder": 3960035,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Kroger Westland",
          "JobNumber": "502-20010-25",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "36430 Ford Rd",
          "JobAddress2": "",
          "JobFullAddress": "36430 Ford Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$21,354.65",
          "BranchNumber": 8,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 3960034,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Kroger Frankenmuth",
          "JobNumber": "502-20010-24",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "435 N Main Street",
          "JobAddress2": "",
          "JobFullAddress": "435 N Main Street",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$20,448.12",
          "BranchNumber": 8,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 3960033,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DAYTON MOTOR CAR CC",
          "JobNumber": "65-2134688-12",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15 MCDONOUGH STREET",
          "JobAddress2": "",
          "JobFullAddress": "15 MCDONOUGH STREET",
          "JobCounty": "Montgomery",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$13,484.11",
          "BranchNumber": 12,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 3960032,
          "ParentWorkOrder": "",
          "JobName": "NOF-DISTRICT AT DEERFIELD",
          "JobNumber": "65-506646-8",
          "FirstdayontheJob": "08-21-2019",
          "LastdayontheJob": "08-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9345 MASON MONTGOMERY RD",
          "JobAddress2": "BLDG # 1",
          "JobFullAddress": "9345 MASON MONTGOMERY RD BLDG # 1",
          "JobCounty": "Warren",
          "JobState": "Ohio",
          "CustomerCompanyName": "MESSER CONSTRUCTION CO.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$6,173.92",
          "BranchNumber": 2,
          "SubmitDate": "08-22-2019",
          "ClientonJob": "MESSER CONSTRUCTION CO."
        },
        {
          "WorkOrder": 3958960,
          "ParentWorkOrder": 3946014,
          "JobName": "(NOF)BLOCK A OFFICE",
          "JobNumber": "65-412100-42",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6515 LONGSHORE LOOP",
          "JobAddress2": "",
          "JobFullAddress": "6515 LONGSHORE LOOP",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "KNOLLMAN CONSTRUCTION",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$200,060.00",
          "BalanceRemaining": "$4,517.00",
          "BranchNumber": 4,
          "SubmitDate": "08-19-2019",
          "ClientonJob": "KNOLLMAN CONSTRUCTION"
        },
        {
          "WorkOrder": 3958539,
          "ParentWorkOrder": 3952854,
          "JobName": "(NOF) HOLIDAY INN EXPRESS & SUITES",
          "JobNumber": "65-2134688-9",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "60 Troy Town Dr",
          "JobAddress2": "",
          "JobFullAddress": "60 Troy Town Dr",
          "JobCounty": "MIAMI",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$25,855.19",
          "BranchNumber": 12,
          "SubmitDate": "08-16-2019",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 3956802,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CARLETON CROSSING 64",
          "JobNumber": "502-1668468-2",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "925 RABBIT RUN RD",
          "JobAddress2": "",
          "JobFullAddress": "925 RABBIT RUN RD",
          "JobCounty": "MONROE",
          "JobState": "Michigan",
          "CustomerCompanyName": "WADE JOURNEY HOMES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$8,800.71",
          "BranchNumber": 1,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "WADE JOURNEY HOMES"
        },
        {
          "WorkOrder": 3956801,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CHEESECAKE FACTORY",
          "JobNumber": "502-1624145-2",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3191 28TH ST SE",
          "JobAddress2": "SUITE B121",
          "JobFullAddress": "3191 28TH ST SE SUITE B121",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "BASLAND, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$47,135.56",
          "BranchNumber": 5,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "BASLAND, INC."
        },
        {
          "WorkOrder": 3956800,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TRU HOTEL",
          "JobNumber": "502-990401-51",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2683 W Marquette Woods Rd",
          "JobAddress2": "",
          "JobFullAddress": "2683 W Marquette Woods Rd",
          "JobCounty": "BERRIEN",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$7,000.43",
          "BranchNumber": 7,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3956799,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Owosso Schools",
          "JobNumber": "502-990401-50",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "645 Alger Ave",
          "JobAddress2": "",
          "JobFullAddress": "645 Alger Ave",
          "JobCounty": "SHIAWASSEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3956798,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Belle Tire Southport",
          "JobNumber": "502-967075-115",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2119 E. Southport",
          "JobAddress2": "",
          "JobFullAddress": "2119 E. Southport",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$436.55",
          "BranchNumber": 7,
          "SubmitDate": "",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 3956797,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CLINTON RIVER WATER",
          "JobNumber": "502-933550-163",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "155 NORTH OPDYKE ROAD",
          "JobAddress2": "",
          "JobFullAddress": "155 NORTH OPDYKE ROAD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,258.76",
          "BranchNumber": 9,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3956796,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WSU CHATSWORTH RENO",
          "JobNumber": "502-933550-162",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "630 MERRICK ST",
          "JobAddress2": "",
          "JobFullAddress": "630 MERRICK ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$450,006.00",
          "BalanceRemaining": "$1,224.71",
          "BranchNumber": 9,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3956795,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-5093 Pomeroy",
          "JobNumber": "502-920940-66",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3500 South Boulevard W",
          "JobAddress2": "",
          "JobFullAddress": "3500 South Boulevard W",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$452.33",
          "BranchNumber": 8,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3956794,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Nordstrom GrtLksCros",
          "JobNumber": "502-920940-60",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4000 Baldwin Road",
          "JobAddress2": "",
          "JobFullAddress": "4000 Baldwin Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$7,816.11",
          "BranchNumber": 8,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3956793,
          "ParentWorkOrder": "",
          "JobName": "(NOF)McDonalds Royal Oak",
          "JobNumber": "502-916315-129",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3300 Rochester Road",
          "JobAddress2": "",
          "JobFullAddress": "3300 Rochester Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,711.20",
          "BranchNumber": 9,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3956792,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 1561 KENDRA SCOTT",
          "JobNumber": "502-913820-522",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3100 WASHTENAW AVE",
          "JobAddress2": "",
          "JobFullAddress": "3100 WASHTENAW AVE",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$3,680.00",
          "BranchNumber": 9,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3956791,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1556 FIVE GUYS",
          "JobNumber": "502-913820-517",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23339 GREENFIELD RD",
          "JobAddress2": "",
          "JobFullAddress": "23339 GREENFIELD RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$3,619.57",
          "BranchNumber": 9,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3956790,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SHRED 4/15",
          "JobNumber": "502-827505-5",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "443 E. BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "443 E. BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEVIN VIAL CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "KEVIN VIAL CONSTRUCTION"
        },
        {
          "WorkOrder": 3956789,
          "ParentWorkOrder": "",
          "JobName": "(NOF)888 W. Big Beaver",
          "JobNumber": "502-815630-217",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "888 W Big Beaver Rd",
          "JobAddress2": "Suite 200",
          "JobFullAddress": "888 W Big Beaver Rd Suite 200",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$10,641.02",
          "BranchNumber": 8,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3956788,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Mill Creek Middle",
          "JobNumber": "502-813200-120",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7305 Dexter Ann Arbor Rd",
          "JobAddress2": "",
          "JobFullAddress": "7305 Dexter Ann Arbor Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$1,806.77",
          "BranchNumber": 8,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3956787,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Operators Union",
          "JobNumber": "502-812350-85",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "275 E Highland Rd",
          "JobAddress2": "",
          "JobFullAddress": "275 E Highland Rd",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 3956786,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Eagle Village",
          "JobNumber": "502-30420-12",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17235 ADVENTURE RD",
          "JobAddress2": "",
          "JobFullAddress": "17235 ADVENTURE RD",
          "JobCounty": "OSCEOLA",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENALI CONSTRUCTION, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$3,052.39",
          "BranchNumber": 1,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "DENALI CONSTRUCTION, INC."
        },
        {
          "WorkOrder": 3956780,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CVS STORE #06151",
          "JobNumber": "65-4077685-25",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "900 COSHOCTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "900 COSHOCTON AVE",
          "JobCounty": "KNOX",
          "JobState": "Ohio",
          "CustomerCompanyName": "FORTNEY & WEYGANDT,INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,436.81",
          "BranchNumber": 4,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "FORTNEY & WEYGANDT,INC"
        },
        {
          "WorkOrder": 3956779,
          "ParentWorkOrder": "",
          "JobName": "NOF-MONUMENT & WEBSTER OF",
          "JobNumber": "65-2134688-11",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "537 EAST MONUMENT AVE",
          "JobAddress2": "",
          "JobFullAddress": "537 EAST MONUMENT AVE",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVICE",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$43,898.03",
          "BranchNumber": 12,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "BENCO CONSTRUCTION SERVICE"
        },
        {
          "WorkOrder": 3956778,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WALMART 1750",
          "JobNumber": "65-711283-3",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1546 MARION-MT GILEAD RD",
          "JobAddress2": "",
          "JobFullAddress": "1546 MARION-MT GILEAD RD",
          "JobCounty": "MARION",
          "JobState": "Ohio",
          "CustomerCompanyName": "MARJAM SUPPLY COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,920.55",
          "BranchNumber": 1,
          "SubmitDate": "",
          "ClientonJob": "MARJAM SUPPLY COMPANY"
        },
        {
          "WorkOrder": 3956777,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CHASE BANK DUBLIN WE",
          "JobNumber": "65-400110-8",
          "FirstdayontheJob": "08-08-2019",
          "LastdayontheJob": "08-08-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7370 STATE ROUTE 161",
          "JobAddress2": "",
          "JobFullAddress": "7370 STATE ROUTE 161",
          "JobCounty": "UNION",
          "JobState": "Ohio",
          "CustomerCompanyName": "Acoustic Ceiling & Part.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,402.80",
          "BranchNumber": 4,
          "SubmitDate": "08-09-2019",
          "ClientonJob": "Acoustic Ceiling & Part."
        },
        {
          "WorkOrder": 3955653,
          "ParentWorkOrder": 3942800,
          "JobName": "(NOF) Precision Alloy New Facility - TCI Job No. 1005.01",
          "JobNumber": "502-1609034-38",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "726 Innovation Dr",
          "JobAddress2": "",
          "JobFullAddress": "726 Innovation Dr",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,085.57",
          "BranchNumber": 13,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 3955647,
          "ParentWorkOrder": 3945202,
          "JobName": "(NOF) Cass City Public Schools 2018 Bond Project",
          "JobNumber": "502-10550-30",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4868 Seeger St",
          "JobAddress2": "",
          "JobFullAddress": "4868 Seeger St",
          "JobCounty": "Tuscola",
          "JobState": "Michigan",
          "CustomerCompanyName": "Booms Construction Inc.",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$36,006.00",
          "BalanceRemaining": "$26,912.45",
          "BranchNumber": 1,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "Booms Construction Inc."
        },
        {
          "WorkOrder": 3955631,
          "ParentWorkOrder": 3942767,
          "JobName": "(NOF)STOCKBRIDGE APTS",
          "JobNumber": "502-51950-42",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "420 STOCKING AVE NW",
          "JobAddress2": "",
          "JobFullAddress": "420 STOCKING AVE NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "SOBIE COMPANY, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$4,240.00",
          "BranchNumber": 5,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "SOBIE COMPANY, INC."
        },
        {
          "WorkOrder": 3955587,
          "ParentWorkOrder": 3942769,
          "JobName": "(NOF)Body Control Systems",
          "JobNumber": "502-812250-22",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33737 W 12 Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "33737 W 12 Mile Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENNIS BAULT CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$1,154.85",
          "BranchNumber": 8,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "DENNIS BAULT CONSTRUCTION"
        },
        {
          "WorkOrder": 3955571,
          "ParentWorkOrder": 3942797,
          "JobName": "(NOF)BELFOR - 18-052C",
          "JobNumber": "502-1524600-9",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2830 S. MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "2830 S. MAIN ST",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Sterling Millwork Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,506.00",
          "BalanceRemaining": "$6,167.17",
          "BranchNumber": 1,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "Sterling Millwork Inc"
        },
        {
          "WorkOrder": 3955549,
          "ParentWorkOrder": 3938545,
          "JobName": "(NOF) WSU STEM INNOVATION PROJECT NO. 008-302306",
          "JobNumber": "502-910050-54",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5048 GULLEN MALL",
          "JobAddress2": "",
          "JobFullAddress": "5048 GULLEN MALL",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Preliminary Notice (Public work)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$33,655.06",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 3955302,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Cramer Jr High",
          "JobNumber": "502-1689064-3",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "313 Pine St",
          "JobAddress2": "",
          "JobFullAddress": "313 Pine St",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "WOODCOCK'S DRYWALL SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$4,262.44",
          "BranchNumber": 1,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "WOODCOCK'S DRYWALL SERVICES"
        },
        {
          "WorkOrder": 3955301,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ALDI'S #96",
          "JobNumber": "502-1507798-26",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1106 S. ROCHESTER RD",
          "JobAddress2": "",
          "JobFullAddress": "1106 S. ROCHESTER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$20,544.16",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3955300,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LABORATORY MODERNIZA",
          "JobNumber": "502-1060142-14",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1300 MARKET AVE SW",
          "JobAddress2": "",
          "JobFullAddress": "1300 MARKET AVE SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 3955299,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Christoff",
          "JobNumber": "502-967400-11",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "400 N. Mechanic St",
          "JobAddress2": "",
          "JobFullAddress": "400 N. Mechanic St",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "COLLINS DESIGN /BUILD INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$13,006.00",
          "BalanceRemaining": "$871.09",
          "BranchNumber": 7,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "COLLINS DESIGN /BUILD INC"
        },
        {
          "WorkOrder": 3955298,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HENRY FORD LIBRARY",
          "JobNumber": "502-949575-108",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16301 MICHIGAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "16301 MICHIGAN AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$85,006.00",
          "BalanceRemaining": "$95.37",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3955297,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDONALD'S JACKSON",
          "JobNumber": "502-944100-317",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3310 E MICHIGAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "3310 E MICHIGAN AVE",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,272.50",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3955296,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Target Woodhaven",
          "JobNumber": "502-934150-64",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23555 Allen Road",
          "JobAddress2": "",
          "JobFullAddress": "23555 Allen Road",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "WOODS CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$9,218.00",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "WOODS CONSTRUCTION INC"
        },
        {
          "WorkOrder": 3955295,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MOD Pizza GR",
          "JobNumber": "502-916315-131",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3563 28th Street SE",
          "JobAddress2": "",
          "JobFullAddress": "3563 28th Street SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,870.82",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3955294,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1555 CARDIO RM 1311",
          "JobNumber": "502-913820-515",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 E MEDICAL CENTER DR",
          "JobAddress2": "",
          "JobFullAddress": "1500 E MEDICAL CENTER DR",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$262.27",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3955293,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GALLOUP CENTER,INC",
          "JobNumber": "502-913400-26",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23350 15 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "23350 15 MILE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DANCO CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$11,385.86",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "DANCO CONTRACTING, INC."
        },
        {
          "WorkOrder": 3955292,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CHRYSLER MAC 1",
          "JobNumber": "502-912080-13",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4000 ST.JEAN ST",
          "JobAddress2": "",
          "JobFullAddress": "4000 ST.JEAN ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRIX CORPORATION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$637.72",
          "BranchNumber": 9,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "BRIX CORPORATION"
        },
        {
          "WorkOrder": 3955291,
          "ParentWorkOrder": "",
          "JobName": "NOF HAMILTON HOMES",
          "JobNumber": "502-849801-214",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11119 Pacton Dr",
          "JobAddress2": "",
          "JobFullAddress": "11119 Pacton Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,426.00",
          "BalanceRemaining": "$5,016.51",
          "BranchNumber": 11,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 3955290,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DaVita Livonia",
          "JobNumber": "502-823300-72",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "37290 5 Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "37290 5 Mile Road",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$279.84",
          "BranchNumber": 8,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 3955289,
          "ParentWorkOrder": "",
          "JobName": "(NOF)18-8051 HFHSM Phase8",
          "JobNumber": "502-815250-346",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15855 19 Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "15855 19 Mile Road",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3955288,
          "ParentWorkOrder": "",
          "JobName": "(NOF)17-7081 SJPM VertTow",
          "JobNumber": "502-815250-345",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11800 Twelve Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "11800 Twelve Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3955287,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Courtyard Detroit",
          "JobNumber": "502-12300-5",
          "FirstdayontheJob": "08-05-2019",
          "LastdayontheJob": "08-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27027 Northwestern Hwy",
          "JobAddress2": "",
          "JobFullAddress": "27027 Northwestern Hwy",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "UG CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,955.54",
          "BranchNumber": 1,
          "SubmitDate": "08-06-2019",
          "ClientonJob": "UG CONTRACTING"
        },
        {
          "WorkOrder": 3954853,
          "ParentWorkOrder": 3942770,
          "JobName": "(NOF)19-9015 SJPHS 7 East",
          "JobNumber": "502-815250-334",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16001 West Nine Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "16001 West Nine Mile Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$429.36",
          "BranchNumber": 8,
          "SubmitDate": "08-05-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3954813,
          "ParentWorkOrder": 3945211,
          "JobName": "(NOF)Sephora-Village RH",
          "JobNumber": "502-916315-124",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "220 N Adams Road",
          "JobAddress2": "",
          "JobFullAddress": "220 N Adams Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$6,156.72",
          "BranchNumber": 9,
          "SubmitDate": "08-05-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3954798,
          "ParentWorkOrder": 3946023,
          "JobName": "NOF-DAYTON VA PROSTHETICS",
          "JobNumber": "65-502280-55",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4100 W THIRD ST",
          "JobAddress2": "",
          "JobFullAddress": "4100 W THIRD ST",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALTIES INC",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$170,006.00",
          "BalanceRemaining": "$2,732.04",
          "BranchNumber": 13,
          "SubmitDate": "08-05-2019",
          "ClientonJob": "COMBS INTERIOR SPECIALTIES INC"
        },
        {
          "WorkOrder": 3954794,
          "ParentWorkOrder": 3946009,
          "JobName": "(NOF)SAVANNAH RIDGE APTS",
          "JobNumber": "65-27529-8",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4150 BACH BUXTON ROAD",
          "JobAddress2": "",
          "JobFullAddress": "4150 BACH BUXTON ROAD",
          "JobCounty": "CLERMONT",
          "JobState": "Ohio",
          "CustomerCompanyName": "NEWPHASE DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,000.00",
          "BalanceRemaining": "$106.47",
          "BranchNumber": 2,
          "SubmitDate": "08-05-2019",
          "ClientonJob": "NEWPHASE DRYWALL LLC"
        },
        {
          "WorkOrder": 3954773,
          "ParentWorkOrder": 3946926,
          "JobName": "(NOF)PREFERRED LIVING",
          "JobNumber": "65-412945-43",
          "FirstdayontheJob": "07-07-2019",
          "LastdayontheJob": "07-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3255 MCKINLEY AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "3255 MCKINLEY AVENUE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "MONARCA CORP. DBA MARIANO & BROS DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Stopped",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$207.43",
          "BranchNumber": 4,
          "SubmitDate": "",
          "ClientonJob": "MONARCA CORP. DBA MARIANO & BROS DRYWALL"
        },
        {
          "WorkOrder": 3954765,
          "ParentWorkOrder": 3942766,
          "JobName": "(NOF)Bureau Veritas",
          "JobNumber": "502-21350-49",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "815 N Opdyke Road",
          "JobAddress2": "",
          "JobFullAddress": "815 N Opdyke Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ERICKSON & LINDSTROM CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$12,585.41",
          "BranchNumber": 1,
          "SubmitDate": "08-05-2019",
          "ClientonJob": "ERICKSON & LINDSTROM CONST."
        },
        {
          "WorkOrder": 3954746,
          "ParentWorkOrder": 3942768,
          "JobName": "(NOF)GordonFoods  - 41910 GFS Clinton Twp",
          "JobNumber": "502-811817-9",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34300 S Gratiot Ave",
          "JobAddress2": "",
          "JobFullAddress": "34300 S Gratiot Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$1,187.24",
          "BranchNumber": 8,
          "SubmitDate": "08-05-2019",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 3954256,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GATEWAY LOFTS",
          "JobNumber": "65-2137179-12",
          "FirstdayontheJob": "07-31-2019",
          "LastdayontheJob": "07-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6919 CHARDONNAY DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "6919 CHARDONNAY DRIVE",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "DURANGO DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$2,276.42",
          "BranchNumber": 13,
          "SubmitDate": "08-01-2019",
          "ClientonJob": "DURANGO DRYWALL LLC"
        },
        {
          "WorkOrder": 3954255,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Madison & Whetsel",
          "JobNumber": "65-2134999-12",
          "FirstdayontheJob": "07-31-2019",
          "LastdayontheJob": "07-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5801 Madison Rd",
          "JobAddress2": "",
          "JobFullAddress": "5801 Madison Rd",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "REYES/TDR INTERIORS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$31,402.36",
          "BranchNumber": 12,
          "SubmitDate": "08-01-2019",
          "ClientonJob": "REYES/TDR INTERIORS"
        },
        {
          "WorkOrder": 3954254,
          "ParentWorkOrder": "",
          "JobName": "(NOF) VIEW ON PAVEY",
          "JobNumber": "65-464635-4",
          "FirstdayontheJob": "07-31-2019",
          "LastdayontheJob": "07-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10 W NORTHWOOD AVE",
          "JobAddress2": "",
          "JobFullAddress": "10 W NORTHWOOD AVE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "MARQUEE DEVELOPMENT GROUP",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$23,500.04",
          "BranchNumber": 4,
          "SubmitDate": "08-01-2019",
          "ClientonJob": "MARQUEE DEVELOPMENT GROUP"
        },
        {
          "WorkOrder": 3952856,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CVS STORE #6186",
          "JobNumber": "65-4077685-26",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2565 EAST MAIN STREET",
          "JobAddress2": "",
          "JobFullAddress": "2565 EAST MAIN STREET",
          "JobCounty": "Clark",
          "JobState": "Ohio",
          "CustomerCompanyName": "FORTNEY & WEYGANDT,INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,878.90",
          "BranchNumber": 4,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "FORTNEY & WEYGANDT,INC"
        },
        {
          "WorkOrder": 3952855,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GE ADDITIVES",
          "JobNumber": "65-2137099-10",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8556 TRADE CENTER DR,",
          "JobAddress2": "NORTH POINT BLDG #2",
          "JobFullAddress": "8556 TRADE CENTER DR, NORTH POINT BLDG #2",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & A INTERIOR SYSTEMS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$320,006.00",
          "BalanceRemaining": "$21,311.00",
          "BranchNumber": 12,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "J & A INTERIOR SYSTEMS"
        },
        {
          "WorkOrder": 3952854,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HOLIDAY INN EXPRESS",
          "JobNumber": "65-2134688-9",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "60 Troy Town Dr",
          "JobAddress2": "",
          "JobFullAddress": "60 Troy Town Dr",
          "JobCounty": "MIAMI",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$25,855.19",
          "BranchNumber": 12,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 3952853,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GOLD MEDAL",
          "JobNumber": "65-510300-13",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10700 MEDALLION DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "10700 MEDALLION DRIVE",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$66.00",
          "BalanceRemaining": "$131.48",
          "BranchNumber": 2,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 3952852,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RUTH CHRIS",
          "JobNumber": "65-456790-2",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "501 N HIGH STREET",
          "JobAddress2": "",
          "JobFullAddress": "501 N HIGH STREET",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "WALL PRO LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$857.29",
          "BranchNumber": 4,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "WALL PRO LLC"
        },
        {
          "WorkOrder": 3952851,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Sola Salon",
          "JobNumber": "65-419100-9",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2727 Fairfield Commons Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2727 Fairfield Commons Blvd",
          "JobCounty": "GREENE",
          "JobState": "Ohio",
          "CustomerCompanyName": "VEATCH MASON & COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,101.25",
          "BranchNumber": 4,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "VEATCH MASON & COMPANY"
        },
        {
          "WorkOrder": 3952850,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WESTBROOK APARTMENTS",
          "JobNumber": "65-139575-2",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5530 AUTUMN HILLS DR",
          "JobAddress2": "",
          "JobFullAddress": "5530 AUTUMN HILLS DR",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "SERVICE MASTER BY CORNERSTONE",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$1,073.28",
          "BranchNumber": 13,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "SERVICE MASTER BY CORNERSTONE"
        },
        {
          "WorkOrder": 3952849,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LULU LEMON",
          "JobNumber": "65-50903-3",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 POLARIS PKWY STE 1062",
          "JobAddress2": "",
          "JobFullAddress": "1500 POLARIS PKWY STE 1062",
          "JobCounty": "DELAWARE",
          "JobState": "Ohio",
          "CustomerCompanyName": "DONALD R BORG CONSTR CO",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,258.31",
          "BranchNumber": 2,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "DONALD R BORG CONSTR CO"
        },
        {
          "WorkOrder": 3952796,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OLD NAVY ANN ARBOR",
          "JobNumber": "502-1602246-2",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2857 OAK VALLEY DR",
          "JobAddress2": "",
          "JobFullAddress": "2857 OAK VALLEY DR",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "KAMEO INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$13,293.31",
          "BranchNumber": 9,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "KAMEO INTERIORS LLC"
        },
        {
          "WorkOrder": 3952795,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DOW RECORDS CENTER",
          "JobNumber": "502-1602000-22",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2100 James Savage Rd",
          "JobAddress2": "",
          "JobFullAddress": "2100 James Savage Rd",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Constr",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$6,973.56",
          "BranchNumber": 1,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "Great Lakes Bay Constr"
        },
        {
          "WorkOrder": 3952794,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Aldi",
          "JobNumber": "502-1304186-48",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2227 Tiffin Ave",
          "JobAddress2": "",
          "JobFullAddress": "2227 Tiffin Ave",
          "JobCounty": "Hancock",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$2,663.07",
          "BranchNumber": 13,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3952793,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Krispy Kreme",
          "JobNumber": "502-1304186-47",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3145 Secor Road",
          "JobAddress2": "",
          "JobFullAddress": "3145 Secor Road",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$16,970.80",
          "BranchNumber": 13,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3952792,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OUR LADY OF ALBANIAN",
          "JobNumber": "502-949575-111",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29350 Lahser Rd",
          "JobAddress2": "",
          "JobFullAddress": "29350 Lahser Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3952791,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WCCCD",
          "JobNumber": "502-949575-110",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1001 W. FORT ST",
          "JobAddress2": "",
          "JobFullAddress": "1001 W. FORT ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$38,006.00",
          "BalanceRemaining": "$9,007.92",
          "BranchNumber": 9,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3952790,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MEDICAL SUITE",
          "JobNumber": "502-949575-109",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22361 W. VILLAGE DR",
          "JobAddress2": "",
          "JobFullAddress": "22361 W. VILLAGE DR",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$4,070.44",
          "BranchNumber": 9,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3952789,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ATI Physical Lansing",
          "JobNumber": "502-948945-56",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3415 E Saginaw St",
          "JobAddress2": "",
          "JobFullAddress": "3415 E Saginaw St",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trinity Building Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$2,567.57",
          "BranchNumber": 9,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "Trinity Building Group"
        },
        {
          "WorkOrder": 3952788,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CITY OF DETROIT",
          "JobNumber": "502-939250-49",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2 WOODWARD AVE,",
          "JobAddress2": "OFFICE OF TREASURY - TAX",
          "JobFullAddress": "2 WOODWARD AVE, OFFICE OF TREASURY - TAX",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.C.P.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$1,068.80",
          "BranchNumber": 9,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "D.C.P."
        },
        {
          "WorkOrder": 3952787,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Quicken Loans",
          "JobNumber": "502-933550-168",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "660 WOODWARD AVE",
          "JobAddress2": "",
          "JobFullAddress": "660 WOODWARD AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$18,747.99",
          "BranchNumber": 9,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3952786,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CRESTWOOD HIGHSCHOOL",
          "JobNumber": "502-933550-150",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1501 N BEECH DALY RD",
          "JobAddress2": "",
          "JobFullAddress": "1501 N BEECH DALY RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$120,006.00",
          "BalanceRemaining": "$22,801.06",
          "BranchNumber": 9,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3952785,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TACO BELL LINCOL PK",
          "JobNumber": "502-920300-84",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2306 DIX HWY",
          "JobAddress2": "",
          "JobFullAddress": "2306 DIX HWY",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$6,191.80",
          "BranchNumber": 9,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 3952784,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1543 CONCENTRA SHELL",
          "JobNumber": "502-913820-504",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10912 WAYNE ROAD",
          "JobAddress2": "",
          "JobFullAddress": "10912 WAYNE ROAD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,515.23",
          "BranchNumber": 9,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3952783,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PROSPECT ST",
          "JobNumber": "502-911550-7",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12720 PROSPECT ST",
          "JobAddress2": "",
          "JobFullAddress": "12720 PROSPECT ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BEST FINISHERS INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "BEST FINISHERS INC."
        },
        {
          "WorkOrder": 3952782,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(259)Cleveland 117",
          "JobNumber": "502-816350-138",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2801 Vanness St",
          "JobAddress2": "",
          "JobFullAddress": "2801 Vanness St",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$30,878.84",
          "BranchNumber": 8,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 3952781,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9034 KCI CT Renov",
          "JobNumber": "502-815250-342",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4100 John R Street",
          "JobAddress2": "",
          "JobFullAddress": "4100 John R Street",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$1,002.53",
          "BranchNumber": 8,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3952780,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Pomeroy Living",
          "JobNumber": "502-814500-79",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "101 East Scripps Rd",
          "JobAddress2": "",
          "JobFullAddress": "101 East Scripps Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$2,817.90",
          "BranchNumber": 8,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "COMMERCIAL INT CONST"
        },
        {
          "WorkOrder": 3952779,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Cleveland Garfield",
          "JobNumber": "502-813200-118",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2801 Vanness St",
          "JobAddress2": "",
          "JobFullAddress": "2801 Vanness St",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$5,625.46",
          "BranchNumber": 8,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3952778,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Troy Beaumont",
          "JobNumber": "502-812350-84",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44201 Dequindre Rd",
          "JobAddress2": "",
          "JobFullAddress": "44201 Dequindre Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,265.08",
          "BranchNumber": 8,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 3952777,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19028 MEIJER 158",
          "JobNumber": "502-52900-40",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1999 E Beltline Ave NE",
          "JobAddress2": "",
          "JobFullAddress": "1999 E Beltline Ave NE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "MID MICHIGAN CEILING &",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,477.28",
          "BranchNumber": 5,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "MID MICHIGAN CEILING &"
        },
        {
          "WorkOrder": 3952776,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MEIJERS",
          "JobNumber": "502-23110-6",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8400 GRATIOT RD",
          "JobAddress2": "",
          "JobFullAddress": "8400 GRATIOT RD",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "SIWEK CONSTRUCTION CO.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,771.63",
          "BranchNumber": 1,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "SIWEK CONSTRUCTION CO."
        },
        {
          "WorkOrder": 3952775,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MCDONALDS #222",
          "JobNumber": "502-13380-131",
          "FirstdayontheJob": "07-25-2019",
          "LastdayontheJob": "07-25-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3212 CLIO RD",
          "JobAddress2": "",
          "JobFullAddress": "3212 CLIO RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "RAYMOND RENOVATIONS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$517.28",
          "BranchNumber": 1,
          "SubmitDate": "07-26-2019",
          "ClientonJob": "RAYMOND RENOVATIONS"
        },
        {
          "WorkOrder": 3951741,
          "ParentWorkOrder": 3935082,
          "JobName": "(NOF)Northridge",
          "JobNumber": "502-1639418-25",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "49555 North Territorial Rd",
          "JobAddress2": "",
          "JobFullAddress": "49555 North Territorial Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Draft",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$200.77",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "PATRIOT TEAM CONSTRUCTION"
        },
        {
          "WorkOrder": 3951740,
          "ParentWorkOrder": 3935082,
          "JobName": "(NOF)Northridge",
          "JobNumber": "502-1639418-25",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "49555 North Territorial Rd",
          "JobAddress2": "",
          "JobFullAddress": "49555 North Territorial Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Draft",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$200.77",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "PATRIOT TEAM CONSTRUCTION"
        },
        {
          "WorkOrder": 3951738,
          "ParentWorkOrder": 3935082,
          "JobName": "(NOF)Northridge",
          "JobNumber": "502-1639418-25",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "49555 North Territorial Rd",
          "JobAddress2": "",
          "JobFullAddress": "49555 North Territorial Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Draft",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$200.77",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "PATRIOT TEAM CONSTRUCTION"
        },
        {
          "WorkOrder": 3951737,
          "ParentWorkOrder": 3935082,
          "JobName": "(NOF)Northridge",
          "JobNumber": "502-1639418-25",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "49555 North Territorial Rd",
          "JobAddress2": "",
          "JobFullAddress": "49555 North Territorial Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Draft",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$200.77",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "PATRIOT TEAM CONSTRUCTION"
        },
        {
          "WorkOrder": 3951697,
          "ParentWorkOrder": 3935071,
          "JobName": "(NOF)19-9031 SJMO Main Pharmacy",
          "JobNumber": "502-815250-339",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44405 Woodward Ave.",
          "JobAddress2": "",
          "JobFullAddress": "44405 Woodward Ave.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,749.04",
          "BranchNumber": 8,
          "SubmitDate": "07-23-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3951668,
          "ParentWorkOrder": 3935070,
          "JobName": "(NOF) LINDENMEYER",
          "JobNumber": "502-52900-36",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2944 WALKENT DR NW",
          "JobAddress2": "",
          "JobFullAddress": "2944 WALKENT DR NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "MID MICHIGAN CEILINGS & DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,096.35",
          "BranchNumber": 5,
          "SubmitDate": "07-23-2019",
          "ClientonJob": "MID MICHIGAN CEILINGS & DRYWALL"
        },
        {
          "WorkOrder": 3951367,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CVS STORE #023181",
          "JobNumber": "65-4077685-24",
          "FirstdayontheJob": "07-21-2019",
          "LastdayontheJob": "07-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9151 S OLD STATE ROAD",
          "JobAddress2": "",
          "JobFullAddress": "9151 S OLD STATE ROAD",
          "JobCounty": "DELAWARE",
          "JobState": "Ohio",
          "CustomerCompanyName": "FORTNEY & WEYGANDT,INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,194.10",
          "BranchNumber": 4,
          "SubmitDate": "07-22-2019",
          "ClientonJob": "FORTNEY & WEYGANDT,INC"
        },
        {
          "WorkOrder": 3951366,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Fairhaven Church",
          "JobNumber": "65-2134688-8",
          "FirstdayontheJob": "07-21-2019",
          "LastdayontheJob": "07-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "875 West Central Ave",
          "JobAddress2": "",
          "JobFullAddress": "875 West Central Ave",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$2,916.67",
          "BranchNumber": 12,
          "SubmitDate": "07-22-2019",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 3951365,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FRISCH'S - CAREW",
          "JobNumber": "65-597042-2",
          "FirstdayontheJob": "07-21-2019",
          "LastdayontheJob": "07-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "441 VINE STREET",
          "JobAddress2": "",
          "JobFullAddress": "441 VINE STREET",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "JONAH DEVELOPMENT CORP",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$78.65",
          "BranchNumber": 2,
          "SubmitDate": "07-22-2019",
          "ClientonJob": "JONAH DEVELOPMENT CORP"
        },
        {
          "WorkOrder": 3951364,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Miller Animal League",
          "JobNumber": "65-510300-45",
          "FirstdayontheJob": "07-21-2019",
          "LastdayontheJob": "07-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4193 Taylor Rd",
          "JobAddress2": "",
          "JobFullAddress": "4193 Taylor Rd",
          "JobCounty": "CLERMONT",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$3,242.96",
          "BranchNumber": 2,
          "SubmitDate": "07-22-2019",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 3951363,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WAYFAIR/LOGISTICS 2",
          "JobNumber": "65-510300-36",
          "FirstdayontheJob": "07-21-2019",
          "LastdayontheJob": "07-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5101 RENEGADE WAY",
          "JobAddress2": "",
          "JobFullAddress": "5101 RENEGADE WAY",
          "JobCounty": "",
          "JobState": "Kentucky",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Intent to File a Lien (Commercial)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "On Hold",
          "ContractAmount": "$125,000.00",
          "BalanceRemaining": "$100.06",
          "BranchNumber": 2,
          "SubmitDate": "",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 3951362,
          "ParentWorkOrder": "",
          "JobName": "(NOF) City BBQ",
          "JobNumber": "65-419100-7",
          "FirstdayontheJob": "07-21-2019",
          "LastdayontheJob": "07-21-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6185 EMERALD PARKWAY",
          "JobAddress2": "",
          "JobFullAddress": "6185 EMERALD PARKWAY",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "VEATCH MASON & COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$344.57",
          "BranchNumber": 4,
          "SubmitDate": "07-22-2019",
          "ClientonJob": "VEATCH MASON & COMPANY"
        },
        {
          "WorkOrder": 3950666,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Hilton Garden Inn",
          "JobNumber": "502-1325000-15",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44700 Hayes Rd",
          "JobAddress2": "",
          "JobFullAddress": "44700 Hayes Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$7,820.17",
          "BranchNumber": 5,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 3950665,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GOEI CENTER",
          "JobNumber": "502-1060142-12",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "818 BUTTERWORTH ST SW",
          "JobAddress2": "",
          "JobFullAddress": "818 BUTTERWORTH ST SW",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 3950664,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Hilton Garden",
          "JobNumber": "502-990401-49",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44700 Hayes Rd",
          "JobAddress2": "",
          "JobFullAddress": "44700 Hayes Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3950663,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PLANET FITNESS",
          "JobNumber": "502-944100-110",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "37685 5 Mile Road",
          "JobAddress2": "(GC) K&M Buidling Inc.",
          "JobFullAddress": "37685 5 Mile Road (GC) K&M Buidling Inc.",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$12.79",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3950662,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Oak Street Flint",
          "JobNumber": "502-934634-9",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "102 W. Pierson Rd",
          "JobAddress2": "",
          "JobFullAddress": "102 W. Pierson Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONST. GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,235.02",
          "BranchNumber": 9,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "BLUESTONE CONST. GROUP"
        },
        {
          "WorkOrder": 3950661,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Parsons Elementary",
          "JobNumber": "502-920940-46",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14473 Middle Gibraltar Rd",
          "JobAddress2": "",
          "JobFullAddress": "14473 Middle Gibraltar Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,073.54",
          "BranchNumber": 8,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3950660,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Gibralter Chapman",
          "JobNumber": "502-920940-39",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31500 Olmstead Road",
          "JobAddress2": "",
          "JobFullAddress": "31500 Olmstead Road",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$2,968.07",
          "BranchNumber": 8,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3950659,
          "ParentWorkOrder": "",
          "JobName": "(NOF) REGENCY",
          "JobNumber": "502-920000-27",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25150 Regency Rd",
          "JobAddress2": "",
          "JobFullAddress": "25150 Regency Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NVR FINISHES, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$8,397.49",
          "BranchNumber": 9,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "NVR FINISHES, INC."
        },
        {
          "WorkOrder": 3950658,
          "ParentWorkOrder": "",
          "JobName": "(NOF)McDonalds Westland",
          "JobNumber": "502-916315-130",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1675 S. Wayne Road",
          "JobAddress2": "",
          "JobFullAddress": "1675 S. Wayne Road",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,603.48",
          "BranchNumber": 9,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3950657,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GENISYS CREDIT UNION",
          "JobNumber": "502-910868-61",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2343 E HILL RD",
          "JobAddress2": "",
          "JobFullAddress": "2343 E HILL RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construct",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,098.30",
          "BranchNumber": 9,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "American Family Construct"
        },
        {
          "WorkOrder": 3950656,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Pro's Services",
          "JobNumber": "502-851301-4",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16545 Eastland St",
          "JobAddress2": "",
          "JobFullAddress": "16545 Eastland St",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SCHAIBLE BUILDERS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$7,957.70",
          "BranchNumber": 8,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "SCHAIBLE BUILDERS"
        },
        {
          "WorkOrder": 3950655,
          "ParentWorkOrder": "",
          "JobName": "(NOF)256 Adams Rd",
          "JobNumber": "502-847401-143",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "256 N Adams Road",
          "JobAddress2": "",
          "JobFullAddress": "256 N Adams Road",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,888.53",
          "BranchNumber": 11,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 3950654,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DTMB VetHomeExterior",
          "JobNumber": "502-823200-79",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "48512 Hawk Dr",
          "JobAddress2": "",
          "JobFullAddress": "48512 Hawk Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PONTIAC CEILING & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$250,006.00",
          "BalanceRemaining": "$114.62",
          "BranchNumber": 8,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "PONTIAC CEILING & PART"
        },
        {
          "WorkOrder": 3950653,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GordonFoods Flint",
          "JobNumber": "502-811817-10",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2195 S Linden Road",
          "JobAddress2": "",
          "JobFullAddress": "2195 S Linden Road",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$723.00",
          "BranchNumber": 8,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 3950652,
          "ParentWorkOrder": "",
          "JobName": "NOF NEW HOPE BAPTIST CHUR",
          "JobNumber": "502-195531-10",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8900 PARDEE RD",
          "JobAddress2": "",
          "JobFullAddress": "8900 PARDEE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TravCo Interiors LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$881.75",
          "BranchNumber": 9,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "TravCo Interiors LLC"
        },
        {
          "WorkOrder": 3950651,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Ubly Schools",
          "JobNumber": "502-10550-31",
          "FirstdayontheJob": "07-17-2019",
          "LastdayontheJob": "07-17-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2020 Union St",
          "JobAddress2": "",
          "JobFullAddress": "2020 Union St",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Booms Construction Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$4,556.58",
          "BranchNumber": 1,
          "SubmitDate": "07-18-2019",
          "ClientonJob": "Booms Construction Inc."
        },
        {
          "WorkOrder": 3949445,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CVS STORE #5927",
          "JobNumber": "65-4077685-23",
          "FirstdayontheJob": "07-11-2019",
          "LastdayontheJob": "07-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1634 NEIL AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "1634 NEIL AVENUE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "FORTNEY & WEYGANDT,INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$231.80",
          "BranchNumber": 4,
          "SubmitDate": "07-12-2019",
          "ClientonJob": "FORTNEY & WEYGANDT,INC"
        },
        {
          "WorkOrder": 3949444,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Autozone",
          "JobNumber": "65-2136004-4",
          "FirstdayontheJob": "07-11-2019",
          "LastdayontheJob": "07-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2682 LONDON GROVEPORT RD",
          "JobAddress2": "",
          "JobFullAddress": "2682 LONDON GROVEPORT RD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "C&S DRYWALL INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$12,119.75",
          "BranchNumber": 9,
          "SubmitDate": "07-12-2019",
          "ClientonJob": "C&S DRYWALL INC."
        },
        {
          "WorkOrder": 3949443,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SITA",
          "JobNumber": "65-2135211-4",
          "FirstdayontheJob": "07-11-2019",
          "LastdayontheJob": "07-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3728 Benner Rd",
          "JobAddress2": "",
          "JobFullAddress": "3728 Benner Rd",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "GANE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$2,018.31",
          "BranchNumber": 13,
          "SubmitDate": "07-12-2019",
          "ClientonJob": "GANE CONSTRUCTION"
        },
        {
          "WorkOrder": 3949442,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GROW OHIO",
          "JobNumber": "65-433386-4",
          "FirstdayontheJob": "07-11-2019",
          "LastdayontheJob": "07-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6400 MAYSVILLE PIKE",
          "JobAddress2": "",
          "JobFullAddress": "6400 MAYSVILLE PIKE",
          "JobCounty": "Muskingum",
          "JobState": "Ohio",
          "CustomerCompanyName": "Paul Construction Co Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$7,563.94",
          "BranchNumber": 4,
          "SubmitDate": "07-12-2019",
          "ClientonJob": "Paul Construction Co Inc"
        },
        {
          "WorkOrder": 3949441,
          "ParentWorkOrder": "",
          "JobName": "(NOF)POST BB BUILD OUT",
          "JobNumber": "65-47359-3",
          "FirstdayontheJob": "07-11-2019",
          "LastdayontheJob": "07-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7470 STATE ROUTE 161",
          "JobAddress2": "",
          "JobFullAddress": "7470 STATE ROUTE 161",
          "JobCounty": "UNION",
          "JobState": "Ohio",
          "CustomerCompanyName": "MILLER CONTRACTING GROUP",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,797.09",
          "BranchNumber": 4,
          "SubmitDate": "07-12-2019",
          "ClientonJob": "MILLER CONTRACTING GROUP"
        },
        {
          "WorkOrder": 3949440,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Kawkwalin Wellness",
          "JobNumber": "502-1689064-2",
          "FirstdayontheJob": "07-11-2019",
          "LastdayontheJob": "07-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2245 S. HURON RD.",
          "JobAddress2": "",
          "JobFullAddress": "2245 S. HURON RD.",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "WOODCOCK'S DRYWALL SERVICES",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$1,113.21",
          "BranchNumber": 1,
          "SubmitDate": "07-12-2019",
          "ClientonJob": "WOODCOCK'S DRYWALL SERVICES"
        },
        {
          "WorkOrder": 3949439,
          "ParentWorkOrder": "",
          "JobName": "(NOF) COMMUNITY REF CHURC",
          "JobNumber": "502-1060142-11",
          "FirstdayontheJob": "07-11-2019",
          "LastdayontheJob": "07-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10376 FELCH ST",
          "JobAddress2": "",
          "JobFullAddress": "10376 FELCH ST",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,693.44",
          "BranchNumber": 5,
          "SubmitDate": "07-12-2019",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 3949438,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1557 AMAZON JOB",
          "JobNumber": "502-913820-518",
          "FirstdayontheJob": "07-11-2019",
          "LastdayontheJob": "07-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W. BIG BEAVER",
          "JobAddress2": "",
          "JobFullAddress": "2800 W. BIG BEAVER",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$38.21",
          "BranchNumber": 9,
          "SubmitDate": "07-12-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3949437,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Amber Properties",
          "JobNumber": "502-849801-209",
          "FirstdayontheJob": "07-11-2019",
          "LastdayontheJob": "07-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1800 Livernois Rd",
          "JobAddress2": "",
          "JobFullAddress": "1800 Livernois Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$1,667.76",
          "BranchNumber": 11,
          "SubmitDate": "07-12-2019",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 3949436,
          "ParentWorkOrder": "",
          "JobName": "(NOF)AnchorBayMiddleSchol",
          "JobNumber": "502-817800-42",
          "FirstdayontheJob": "07-11-2019",
          "LastdayontheJob": "07-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "48650 Sugar Bush Rd",
          "JobAddress2": "",
          "JobFullAddress": "48650 Sugar Bush Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "07-12-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3949435,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(260)Roosevelt Elem.",
          "JobNumber": "502-816350-140",
          "FirstdayontheJob": "07-11-2019",
          "LastdayontheJob": "07-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1112 20th Street",
          "JobAddress2": "BD 115",
          "JobFullAddress": "1112 20th Street BD 115",
          "JobCounty": "Saint Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,421.35",
          "BranchNumber": 8,
          "SubmitDate": "07-12-2019",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 3946931,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HAND & STONE",
          "JobNumber": "65-551119-7",
          "FirstdayontheJob": "07-07-2019",
          "LastdayontheJob": "07-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7685 DOC DR",
          "JobAddress2": "",
          "JobFullAddress": "7685 DOC DR",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "RELIANCE  CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,957.98",
          "BranchNumber": 2,
          "SubmitDate": "07-08-2019",
          "ClientonJob": "RELIANCE  CONSTRUCTION"
        },
        {
          "WorkOrder": 3946930,
          "ParentWorkOrder": "",
          "JobName": "(NOF) PRATT",
          "JobNumber": "65-534004-7",
          "FirstdayontheJob": "07-07-2019",
          "LastdayontheJob": "07-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10451 Dog Leg Rd",
          "JobAddress2": "",
          "JobFullAddress": "10451 Dog Leg Rd",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "TRUE CUT CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$9,247.05",
          "BranchNumber": 2,
          "SubmitDate": "07-08-2019",
          "ClientonJob": "TRUE CUT CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 3946929,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Home 2 Cincinnati",
          "JobNumber": "65-513200-2",
          "FirstdayontheJob": "07-07-2019",
          "LastdayontheJob": "07-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "856 Eastgate North Blvd",
          "JobAddress2": "",
          "JobFullAddress": "856 Eastgate North Blvd",
          "JobCounty": "Clermont",
          "JobState": "Ohio",
          "CustomerCompanyName": "STRUCTURAL VISIONS INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$8,619.33",
          "BranchNumber": 2,
          "SubmitDate": "07-08-2019",
          "ClientonJob": "STRUCTURAL VISIONS INC"
        },
        {
          "WorkOrder": 3946928,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CHARTER COMM",
          "JobNumber": "65-510300-32",
          "FirstdayontheJob": "07-07-2019",
          "LastdayontheJob": "07-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10900 Kenwood Rd",
          "JobAddress2": "",
          "JobFullAddress": "10900 Kenwood Rd",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,066.00",
          "BalanceRemaining": "$27.82",
          "BranchNumber": 2,
          "SubmitDate": "07-08-2019",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 3946927,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DEERASSIC",
          "JobNumber": "65-433386-6",
          "FirstdayontheJob": "07-07-2019",
          "LastdayontheJob": "07-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14250 CADIZ ROAD",
          "JobAddress2": "",
          "JobFullAddress": "14250 CADIZ ROAD",
          "JobCounty": "Guernsey",
          "JobState": "Ohio",
          "CustomerCompanyName": "Paul Construction Co Inc",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$120,006.00",
          "BalanceRemaining": "$50,226.75",
          "BranchNumber": 4,
          "SubmitDate": "07-08-2019",
          "ClientonJob": "Paul Construction Co Inc"
        },
        {
          "WorkOrder": 3946926,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PREFERRED LIVING",
          "JobNumber": "65-412945-43",
          "FirstdayontheJob": "07-07-2019",
          "LastdayontheJob": "07-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3255 MCKINLEY AVENUE",
          "JobAddress2": "",
          "JobFullAddress": "3255 MCKINLEY AVENUE",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "MONARCA CORP. DBA MARIANO & BROS DRYWALL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$207.43",
          "BranchNumber": 4,
          "SubmitDate": "07-08-2019",
          "ClientonJob": "MONARCA CORP. DBA MARIANO & BROS DRYWALL"
        },
        {
          "WorkOrder": 3946925,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GATEWAY LOFTS",
          "JobNumber": "65-131500-2",
          "FirstdayontheJob": "07-07-2019",
          "LastdayontheJob": "07-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "545 VERSAILLES DR",
          "JobAddress2": "",
          "JobFullAddress": "545 VERSAILLES DR",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "HCC CONSTRUCTION CO LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$2,425.13",
          "BranchNumber": 13,
          "SubmitDate": "07-08-2019",
          "ClientonJob": "HCC CONSTRUCTION CO LLC"
        },
        {
          "WorkOrder": 3946568,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HOTEL INDIGO",
          "JobNumber": "502-1687798-2",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1020 WASHINGTON BLVD",
          "JobAddress2": "",
          "JobFullAddress": "1020 WASHINGTON BLVD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "IN ACTION REMODELING INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "IN ACTION REMODELING INC"
        },
        {
          "WorkOrder": 3946567,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WOODCREST",
          "JobNumber": "502-1677740-27",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "135 WOODCREST DR",
          "JobAddress2": "",
          "JobFullAddress": "135 WOODCREST DR",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$9,218.32",
          "BranchNumber": 9,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3946566,
          "ParentWorkOrder": "",
          "JobName": "(NOF)9630SHOREDRIVE",
          "JobNumber": "502-1650969-49",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9630 SHORE DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "9630 SHORE DRIVE",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,806.00",
          "BalanceRemaining": "$1,364.64",
          "BranchNumber": 1,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 3946565,
          "ParentWorkOrder": "",
          "JobName": "(NOF)4498TOMTOMTRAIL",
          "JobNumber": "502-1650969-48",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4498 TOM-TOM TRAIL",
          "JobAddress2": "",
          "JobFullAddress": "4498 TOM-TOM TRAIL",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,506.00",
          "BalanceRemaining": "$4,166.45",
          "BranchNumber": 1,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 3946564,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Davita",
          "JobNumber": "502-1613422-2",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 Shaffer Park Drive",
          "JobAddress2": "",
          "JobFullAddress": "100 Shaffer Park Drive",
          "JobCounty": "Seneca",
          "JobState": "Ohio",
          "CustomerCompanyName": "DRIVEN CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$9,866.71",
          "BranchNumber": 13,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "DRIVEN CONSTRUCTION INC"
        },
        {
          "WorkOrder": 3946563,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Bath & Body Works",
          "JobNumber": "502-1611551-2",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50350/50753 Waterside Dr.",
          "JobAddress2": "",
          "JobFullAddress": "50350/50753 Waterside Dr.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SALVADOR YANEZ",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,232.63",
          "BranchNumber": 8,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "SALVADOR YANEZ"
        },
        {
          "WorkOrder": 3946562,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SAINT-GOBAIN PERFORM",
          "JobNumber": "502-1609034-40",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3910 TERRY-DIANE ST",
          "JobAddress2": "",
          "JobFullAddress": "3910 TERRY-DIANE ST",
          "JobCounty": "GLADWIN",
          "JobState": "Michigan",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$200,000.00",
          "BalanceRemaining": "$427.79",
          "BranchNumber": 13,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 3946561,
          "ParentWorkOrder": "",
          "JobName": "(NOF) AUTO ZONE CHEBOYGAN",
          "JobNumber": "502-1608791-3",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1119 S.MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "1119 S.MAIN ST",
          "JobCounty": "CHEBOYGAN",
          "JobState": "Michigan",
          "CustomerCompanyName": "C&S DRYWALL INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$8,114.67",
          "BranchNumber": 5,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "C&S DRYWALL INC."
        },
        {
          "WorkOrder": 3946560,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Scott Davis",
          "JobNumber": "502-1551974-3",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10511 E Oak Rd",
          "JobAddress2": "",
          "JobFullAddress": "10511 E Oak Rd",
          "JobCounty": "CLARE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFILES DESIGNS AND SHAPES INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 1,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "PROFILES DESIGNS AND SHAPES INC"
        },
        {
          "WorkOrder": 3946559,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BRIGHTON LIBRARY",
          "JobNumber": "502-1507798-25",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 LIBRARY DR",
          "JobAddress2": "",
          "JobFullAddress": "100 LIBRARY DR",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3946558,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Port Clinton High Sc",
          "JobNumber": "502-1304186-46",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "821 Jefferson St.",
          "JobAddress2": "",
          "JobFullAddress": "821 Jefferson St.",
          "JobCounty": "OTTAWA",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$6,742.33",
          "BranchNumber": 13,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3946557,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HAMPTON INN",
          "JobNumber": "502-990401-48",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1432 S 11TH ST",
          "JobAddress2": "",
          "JobFullAddress": "1432 S 11TH ST",
          "JobCounty": "BERRIEN",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$19,807.73",
          "BranchNumber": 7,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3946556,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SOUTH LAKE HIGH",
          "JobNumber": "502-981570-12",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21900 E 9 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "21900 E 9 MILE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DTS Contracting",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$5,927.04",
          "BranchNumber": 9,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "DTS Contracting"
        },
        {
          "WorkOrder": 3946555,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GOLDNER",
          "JobNumber": "502-950140-6",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3255 GOLDNER ST",
          "JobAddress2": "",
          "JobFullAddress": "3255 GOLDNER ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "S Vitale Contracting LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$82.68",
          "BranchNumber": 9,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "S Vitale Contracting LLC"
        },
        {
          "WorkOrder": 3946554,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ROSEVILLE SCHOOLS",
          "JobNumber": "502-944100-313",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17855 COMMON RD",
          "JobAddress2": "",
          "JobFullAddress": "17855 COMMON RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$4,579.81",
          "BranchNumber": 9,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3946553,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Edge Fitness",
          "JobNumber": "502-934634-10",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "36577 Warren Road",
          "JobAddress2": "",
          "JobFullAddress": "36577 Warren Road",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BLUESTONE CONST. GROUP",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$12,564.06",
          "BranchNumber": 9,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "BLUESTONE CONST. GROUP"
        },
        {
          "WorkOrder": 3946552,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Delta Kelly Elementa",
          "JobNumber": "502-920940-48",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3880 Adams Rd",
          "JobAddress2": "",
          "JobFullAddress": "3880 Adams Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,849.99",
          "BranchNumber": 8,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3946551,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Weiss Elementary",
          "JobNumber": "502-920940-47",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26631 Reaume Street",
          "JobAddress2": "",
          "JobFullAddress": "26631 Reaume Street",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,910.68",
          "BranchNumber": 8,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3946550,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Hunter Elementary",
          "JobNumber": "502-920940-45",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21320 Roche Rd",
          "JobAddress2": "",
          "JobFullAddress": "21320 Roche Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,648.86",
          "BranchNumber": 8,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3946549,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Waterford Beaumont",
          "JobNumber": "502-920940-43",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6532 Elizabeth Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "6532 Elizabeth Lake Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$17,755.88",
          "BranchNumber": 8,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3946548,
          "ParentWorkOrder": "",
          "JobName": "(NOF)H&M Detroit",
          "JobNumber": "502-916315-126",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1505 Woodward Ave",
          "JobAddress2": "",
          "JobFullAddress": "1505 Woodward Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$21,260.83",
          "BranchNumber": 9,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3946547,
          "ParentWorkOrder": "",
          "JobName": "(NOF)McDonalds SterlHeigh",
          "JobNumber": "502-916315-123",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2000 18 Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "2000 18 Mile Road",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$923.85",
          "BranchNumber": 9,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3946546,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Chick Fil A",
          "JobNumber": "502-913820-516",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W Big Beaver Rd Ste 230A",
          "JobAddress2": "",
          "JobFullAddress": "2800 W Big Beaver Rd Ste 230A",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3946545,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Aaron Quain",
          "JobNumber": "502-865651-5",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "340 Capitol Dr",
          "JobAddress2": "",
          "JobFullAddress": "340 Capitol Dr",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "GARY SWEENEY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$5,814.14",
          "BranchNumber": 11,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "GARY SWEENEY"
        },
        {
          "WorkOrder": 3946544,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Fallon Residence",
          "JobNumber": "502-861507-46",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8303 Anchor Bay Drive",
          "JobAddress2": "",
          "JobFullAddress": "8303 Anchor Bay Drive",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "LARRY CLEVELAND",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$5,796.69",
          "BranchNumber": 11,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "LARRY CLEVELAND"
        },
        {
          "WorkOrder": 3946543,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Mini Storage",
          "JobNumber": "502-843701-196",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1330 Coolidge Hwy",
          "JobAddress2": "",
          "JobFullAddress": "1330 Coolidge Hwy",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,251.64",
          "BranchNumber": 8,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 3946542,
          "ParentWorkOrder": "",
          "JobName": "(NOF) H P Pelzer Auto Sys",
          "JobNumber": "502-839040-141",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2415 Dove St",
          "JobAddress2": "",
          "JobFullAddress": "2415 Dove St",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "CHROME DOME CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$891.94",
          "BranchNumber": 11,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "CHROME DOME CONSTRUCTION"
        },
        {
          "WorkOrder": 3946541,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BURROUGHS BUILDING",
          "JobNumber": "502-811800-62",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41100 PLYMOUTH RD",
          "JobAddress2": "",
          "JobFullAddress": "41100 PLYMOUTH RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BHD BUILDERS, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,413.96",
          "BranchNumber": 8,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "BHD BUILDERS, INC"
        },
        {
          "WorkOrder": 3946540,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ELKTONPIGEONLAKERS",
          "JobNumber": "502-12150-11",
          "FirstdayontheJob": "07-04-2019",
          "LastdayontheJob": "07-04-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6136 PIGEON RD",
          "JobAddress2": "",
          "JobFullAddress": "6136 PIGEON RD",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "SERENUS JOHNSON",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$98,006.00",
          "BalanceRemaining": "$5,319.60",
          "BranchNumber": 1,
          "SubmitDate": "07-05-2019",
          "ClientonJob": "SERENUS JOHNSON"
        },
        {
          "WorkOrder": 3946035,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CENTERFIELD FLATS",
          "JobNumber": "65-2137179-8",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "204 SEARS ST/507 1ST ST E",
          "JobAddress2": "",
          "JobFullAddress": "204 SEARS ST/507 1ST ST E",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "DURANGO DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$300,006.00",
          "BalanceRemaining": "$211,195.82",
          "BranchNumber": 13,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "DURANGO DRYWALL LLC"
        },
        {
          "WorkOrder": 3946034,
          "ParentWorkOrder": "",
          "JobName": "(NOF)North Point-Rinck",
          "JobNumber": "65-2137099-9",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8556 Trade Center Dr",
          "JobAddress2": "Farms I & II",
          "JobFullAddress": "8556 Trade Center Dr Farms I & II",
          "JobCounty": "BUTLER",
          "JobState": "Ohio",
          "CustomerCompanyName": "J & A INTERIOR SYSTEMS",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$12.48",
          "BranchNumber": 12,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "J & A INTERIOR SYSTEMS"
        },
        {
          "WorkOrder": 3946033,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ECC BLDG #2",
          "JobNumber": "65-2137099-8",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "680 ERLANGER RD",
          "JobAddress2": "",
          "JobFullAddress": "680 ERLANGER RD",
          "JobCounty": "",
          "JobState": "Kentucky",
          "CustomerCompanyName": "J & A INTERIOR SYSTEMS",
          "RequestType": "Notice of Intent to File a Lien (Commercial)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "On Hold",
          "ContractAmount": "$200,000.00",
          "BalanceRemaining": "$327.53",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "J & A INTERIOR SYSTEMS"
        },
        {
          "WorkOrder": 3946032,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TOP GOLF RICHMOND",
          "JobNumber": "65-2134999-10",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2308 WESTWOOD AVE",
          "JobAddress2": "",
          "JobFullAddress": "2308 WESTWOOD AVE",
          "JobCounty": "",
          "JobState": "Virginia",
          "CustomerCompanyName": "REYES/TDR INTERIORS",
          "RequestType": "Memorandum for Mechanic's Lien Claimed by Sub Subcontractor",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "On Hold",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$3,620.98",
          "BranchNumber": 12,
          "SubmitDate": "",
          "ClientonJob": "REYES/TDR INTERIORS"
        },
        {
          "WorkOrder": 3946031,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TOP GOLF INDEPENDEN",
          "JobNumber": "65-2134999-9",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5280/5800 ROCKSIDE WOODS BLVD N",
          "JobAddress2": "",
          "JobFullAddress": "5280/5800 ROCKSIDE WOODS BLVD N",
          "JobCounty": "CUYAHOGA",
          "JobState": "Ohio",
          "CustomerCompanyName": "REYES/TDR INTERIORS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$13,200.77",
          "BranchNumber": 12,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "REYES/TDR INTERIORS"
        },
        {
          "WorkOrder": 3946030,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HOME 2 SUITES",
          "JobNumber": "65-2134999-7",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5460 FISHER ROAD",
          "JobAddress2": "",
          "JobFullAddress": "5460 FISHER ROAD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "REYES/TDR INTERIORS",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$6,559.30",
          "BranchNumber": 12,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "REYES/TDR INTERIORS"
        },
        {
          "WorkOrder": 3946029,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MIAMI TOWNSHIP",
          "JobNumber": "65-2134688-6",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1171 STATE ROUTE 28 STE 1012",
          "JobAddress2": "",
          "JobFullAddress": "1171 STATE ROUTE 28 STE 1012",
          "JobCounty": "CLERMONT",
          "JobState": "Ohio",
          "CustomerCompanyName": "BENCO CONSTRUCTION SERVIC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$1,499.35",
          "BranchNumber": 12,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "BENCO CONSTRUCTION SERVIC"
        },
        {
          "WorkOrder": 3946028,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Trans Ash",
          "JobNumber": "65-551119-6",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "617 Shepherd Dr",
          "JobAddress2": "",
          "JobFullAddress": "617 Shepherd Dr",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "RELIANCE  CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,962.51",
          "BranchNumber": 2,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "RELIANCE  CONSTRUCTION"
        },
        {
          "WorkOrder": 3946027,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CRUNCH FITNESS",
          "JobNumber": "65-510300-38",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5901 GALBRAITH RD #100",
          "JobAddress2": "",
          "JobFullAddress": "5901 GALBRAITH RD #100",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$3,525.65",
          "BranchNumber": 2,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 3946026,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SERVICE EXPERTS",
          "JobNumber": "65-510300-35",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11935 TRAMWAY DR",
          "JobAddress2": "BUILDING #3",
          "JobFullAddress": "11935 TRAMWAY DR BUILDING #3",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,000.00",
          "BalanceRemaining": "$98.49",
          "BranchNumber": 2,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 3946025,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CLOSET DESIGN",
          "JobNumber": "65-510300-28",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11275 DEERFIELD RD",
          "JobAddress2": "",
          "JobFullAddress": "11275 DEERFIELD RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$344.08",
          "BranchNumber": 2,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 3946024,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WATSON'S",
          "JobNumber": "65-510300-23",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2721/2795 EAST SHARON RD",
          "JobAddress2": "",
          "JobFullAddress": "2721/2795 EAST SHARON RD",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "T SQUARE WALL SYSTEMS LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$757.61",
          "BranchNumber": 2,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "T SQUARE WALL SYSTEMS LLC"
        },
        {
          "WorkOrder": 3946023,
          "ParentWorkOrder": "",
          "JobName": "NOF-DAYTON VA PROSTHETICS",
          "JobNumber": "65-502280-55",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4100 W THIRD ST",
          "JobAddress2": "",
          "JobFullAddress": "4100 W THIRD ST",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "COMBS INTERIOR SPECIALTIES INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$170,006.00",
          "BalanceRemaining": "$2,732.04",
          "BranchNumber": 13,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "COMBS INTERIOR SPECIALTIES INC"
        },
        {
          "WorkOrder": 3946022,
          "ParentWorkOrder": "",
          "JobName": "( NOF )NCH RADIOLOGY",
          "JobNumber": "65-484402-4",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "700 CHILDRENS DR.",
          "JobAddress2": "",
          "JobFullAddress": "700 CHILDRENS DR.",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "LCS LLC",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$5,627.98",
          "BranchNumber": 4,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "LCS LLC"
        },
        {
          "WorkOrder": 3946021,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Scioto Downs",
          "JobNumber": "65-484402-2",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6000 SOUTH HIGH STREET",
          "JobAddress2": "",
          "JobFullAddress": "6000 SOUTH HIGH STREET",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "LCS LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$30,589.64",
          "BranchNumber": 4,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "LCS LLC"
        },
        {
          "WorkOrder": 3946020,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ROSS DRESS FOR LESS",
          "JobNumber": "65-422006-34",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3695 EASTON MARKET",
          "JobAddress2": "",
          "JobFullAddress": "3695 EASTON MARKET",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "WAYNE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "-$90.30",
          "BranchNumber": 4,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "WAYNE CONSTRUCTION"
        },
        {
          "WorkOrder": 3946019,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HUFFMAN BUILDING",
          "JobNumber": "65-421030-2",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "111-129 E 3RD ST.",
          "JobAddress2": "",
          "JobFullAddress": "111-129 E 3RD ST.",
          "JobCounty": "MONTGOMERY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Windsor Construction Svc LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$1,137.67",
          "BranchNumber": 4,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "Windsor Construction Svc LLC"
        },
        {
          "WorkOrder": 3946018,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Shop At Lane",
          "JobNumber": "65-420000-33",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1609 W Lane Ave",
          "JobAddress2": "",
          "JobFullAddress": "1609 W Lane Ave",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "THIGS CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,991.27",
          "BranchNumber": 4,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "THIGS CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 3946017,
          "ParentWorkOrder": "",
          "JobName": "(NOF)NATIONWIDE CHILDRENS",
          "JobNumber": "65-413650-3",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "562 E. Main St",
          "JobAddress2": "",
          "JobFullAddress": "562 E. Main St",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "MILES-McCLELLAN CONST CO",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$8,433.22",
          "BranchNumber": 4,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "MILES-McCLELLAN CONST CO"
        },
        {
          "WorkOrder": 3946016,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CRESCENT RIDGE",
          "JobNumber": "65-412945-39",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3830 BIG RUN SOUTH RD",
          "JobAddress2": "",
          "JobFullAddress": "3830 BIG RUN SOUTH RD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "MONARCA CORP. DBA MARIANO & BROS DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$11,667.99",
          "BranchNumber": 4,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "MONARCA CORP. DBA MARIANO & BROS DRYWALL"
        },
        {
          "WorkOrder": 3946015,
          "ParentWorkOrder": "",
          "JobName": "(NOF)AMERICANA 380",
          "JobNumber": "65-412360-38",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "370/380 S. 5TH STREET",
          "JobAddress2": "",
          "JobFullAddress": "370/380 S. 5TH STREET",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "Liberty General Contracting",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,889.42",
          "BranchNumber": 4,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "Liberty General Contracting"
        },
        {
          "WorkOrder": 3946014,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BLOCK A OFFICE",
          "JobNumber": "65-412100-42",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6515 LONGSHORE LOOP",
          "JobAddress2": "",
          "JobFullAddress": "6515 LONGSHORE LOOP",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "KNOLLMAN CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$200,060.00",
          "BalanceRemaining": "$4,517.00",
          "BranchNumber": 4,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "KNOLLMAN CONSTRUCTION"
        },
        {
          "WorkOrder": 3946013,
          "ParentWorkOrder": "",
          "JobName": "(NOF)E. BROAD ASST LIVING",
          "JobNumber": "65-404400-107",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "79 HAIGHT WAY",
          "JobAddress2": "",
          "JobFullAddress": "79 HAIGHT WAY",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "COLUMBUS DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$150,060.00",
          "BalanceRemaining": "$503.05",
          "BranchNumber": 4,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "COLUMBUS DRYWALL"
        },
        {
          "WorkOrder": 3946012,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CROWNE PLAZA",
          "JobNumber": "65-93010-3",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33 EAST NATIONWIDE BLVD",
          "JobAddress2": "",
          "JobFullAddress": "33 EAST NATIONWIDE BLVD",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "FIRST FINISH INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$14,187.36",
          "BranchNumber": 9,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "FIRST FINISH INC"
        },
        {
          "WorkOrder": 3946011,
          "ParentWorkOrder": "",
          "JobName": "(NOF)XAVIER UNIVERSITY",
          "JobNumber": "65-71344-10",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3800 VICTORY PARKWAY",
          "JobAddress2": "",
          "JobFullAddress": "3800 VICTORY PARKWAY",
          "JobCounty": "HAMILTON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Northside Supply",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$250,006.00",
          "BalanceRemaining": "$113,319.47",
          "BranchNumber": 12,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "Northside Supply"
        },
        {
          "WorkOrder": 3946010,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OFFICE 4TH FLOOR",
          "JobNumber": "65-42942-2",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "226 N. 5TH STREET FL 4",
          "JobAddress2": "",
          "JobFullAddress": "226 N. 5TH STREET FL 4",
          "JobCounty": "FRANKLIN",
          "JobState": "Ohio",
          "CustomerCompanyName": "C.A. Orr Construction Co.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$7,000.00",
          "BalanceRemaining": "$200.54",
          "BranchNumber": 4,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "C.A. Orr Construction Co."
        },
        {
          "WorkOrder": 3946009,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SAVANNAH RIDGE APTS",
          "JobNumber": "65-27529-8",
          "FirstdayontheJob": "07-01-2019",
          "LastdayontheJob": "07-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4150 BACH BUXTON ROAD",
          "JobAddress2": "",
          "JobFullAddress": "4150 BACH BUXTON ROAD",
          "JobCounty": "CLERMONT",
          "JobState": "Ohio",
          "CustomerCompanyName": "NEWPHASE DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Nancy Schmidt",
          "Status": "Mailed",
          "ContractAmount": "$50,000.00",
          "BalanceRemaining": "$106.47",
          "BranchNumber": 2,
          "SubmitDate": "07-02-2019",
          "ClientonJob": "NEWPHASE DRYWALL LLC"
        },
        {
          "WorkOrder": 3945216,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Burlington Coats",
          "JobNumber": "502-1609034-41",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5025 Monroe Street",
          "JobAddress2": "Old Toys R Us",
          "JobFullAddress": "5025 Monroe Street Old Toys R Us",
          "JobCounty": "",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Draft",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$9,423.78",
          "BranchNumber": 13,
          "SubmitDate": "",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 3945215,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Mercy OB",
          "JobNumber": "502-1301172-44",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45 St. Lawrence Dr",
          "JobAddress2": "",
          "JobFullAddress": "45 St. Lawrence Dr",
          "JobCounty": "SENECA",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,310.51",
          "BranchNumber": 13,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 3945214,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FAMILY DOLLAR",
          "JobNumber": "502-949575-107",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27454 VAN BORN RD",
          "JobAddress2": "",
          "JobFullAddress": "27454 VAN BORN RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$7,709.21",
          "BranchNumber": 9,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3945213,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PALLEKONDA",
          "JobNumber": "502-917690-8",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "498 FARMERS ST",
          "JobAddress2": "",
          "JobFullAddress": "498 FARMERS ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Kingdom Construction, Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$276.30",
          "BranchNumber": 9,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "Kingdom Construction, Inc"
        },
        {
          "WorkOrder": 3945212,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Taco Bell Charlotte",
          "JobNumber": "502-916315-127",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "660 LANSING ST",
          "JobAddress2": "",
          "JobFullAddress": "660 LANSING ST",
          "JobCounty": "EATON",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$6,714.09",
          "BranchNumber": 9,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3945211,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Sephora-Village RH",
          "JobNumber": "502-916315-124",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "220 N Adams Road",
          "JobAddress2": "",
          "JobFullAddress": "220 N Adams Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$6,156.72",
          "BranchNumber": 9,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3945210,
          "ParentWorkOrder": "",
          "JobName": "(NOF)THE COLEMAN YOUNG",
          "JobNumber": "502-912080-12",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2 WOODWARD AVE",
          "JobAddress2": "",
          "JobFullAddress": "2 WOODWARD AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRIX CORPORATION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,311.38",
          "BranchNumber": 9,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "BRIX CORPORATION"
        },
        {
          "WorkOrder": 3945209,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CABRINI HIGH SCHOOL",
          "JobNumber": "502-910868-60",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15305 WICK RD",
          "JobAddress2": "",
          "JobFullAddress": "15305 WICK RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construct",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$935.70",
          "BranchNumber": 9,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "American Family Construct"
        },
        {
          "WorkOrder": 3945208,
          "ParentWorkOrder": "",
          "JobName": "(NOF)JSP International",
          "JobNumber": "502-820500-35",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4335 County Farm Rd",
          "JobAddress2": "",
          "JobFullAddress": "4335 County Farm Rd",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEE CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,272.75",
          "BranchNumber": 8,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "LEE CONTRACTING"
        },
        {
          "WorkOrder": 3945207,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WCCCD",
          "JobNumber": "502-812277-3",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "971 W. FORT ST",
          "JobAddress2": "",
          "JobFullAddress": "971 W. FORT ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Custom Architectural",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,500.96",
          "BranchNumber": 8,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "Custom Architectural"
        },
        {
          "WorkOrder": 3945206,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 7570 Hartwick-HMOR",
          "JobNumber": "502-112107-5",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7570 Hartwick Ct",
          "JobAddress2": "",
          "JobFullAddress": "7570 Hartwick Ct",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Barrett Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$6,121.40",
          "BranchNumber": 11,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "Barrett Drywall"
        },
        {
          "WorkOrder": 3945205,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MY LOCKER TEMP HALL",
          "JobNumber": "502-94091-17",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1300 ROSA PARKS BLVD",
          "JobAddress2": "",
          "JobFullAddress": "1300 ROSA PARKS BLVD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "EJ Kaneris Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "EJ Kaneris Inc"
        },
        {
          "WorkOrder": 3945204,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MEIJER - BELLEVILLE",
          "JobNumber": "502-52900-39",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41255 COCA COLA DR",
          "JobAddress2": "",
          "JobFullAddress": "41255 COCA COLA DR",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MID MICHIGAN CEILING &",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$2,231.82",
          "BranchNumber": 5,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "MID MICHIGAN CEILING &"
        },
        {
          "WorkOrder": 3945203,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDONALDS 15244",
          "JobNumber": "502-13380-130",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3180 N Genesee Rd",
          "JobAddress2": "",
          "JobFullAddress": "3180 N Genesee Rd",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "RAYMOND RENOVATIONS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,506.00",
          "BalanceRemaining": "$1,503.54",
          "BranchNumber": 1,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "RAYMOND RENOVATIONS"
        },
        {
          "WorkOrder": 3945202,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Cass City Schools",
          "JobNumber": "502-10550-30",
          "FirstdayontheJob": "06-26-2019",
          "LastdayontheJob": "06-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4868 Seeger St",
          "JobAddress2": "",
          "JobFullAddress": "4868 Seeger St",
          "JobCounty": "Tuscola",
          "JobState": "Michigan",
          "CustomerCompanyName": "Booms Construction Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$36,006.00",
          "BalanceRemaining": "$26,912.45",
          "BranchNumber": 1,
          "SubmitDate": "06-27-2019",
          "ClientonJob": "Booms Construction Inc."
        },
        {
          "WorkOrder": 3944999,
          "ParentWorkOrder": 3934135,
          "JobName": "(NOF)Holiday Inn Rosevill",
          "JobNumber": "502-990401-46",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30300 Gratiot Ave",
          "JobAddress2": "",
          "JobFullAddress": "30300 Gratiot Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$7,080.80",
          "BranchNumber": 7,
          "SubmitDate": "06-26-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3944995,
          "ParentWorkOrder": 3936278,
          "JobName": "(NOF)Hobby Lobby Defiance",
          "JobNumber": "502-1610113-2",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 N. Clinton Street  #8",
          "JobAddress2": "",
          "JobFullAddress": "1500 N. Clinton Street  #8",
          "JobCounty": "Defiance",
          "JobState": "Ohio",
          "CustomerCompanyName": "AJS CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$21,464.21",
          "BranchNumber": 13,
          "SubmitDate": "06-26-2019",
          "ClientonJob": "AJS CONSTRUCTION"
        },
        {
          "WorkOrder": 3944991,
          "ParentWorkOrder": 3934115,
          "JobName": "(NOF)Gordon Food Service",
          "JobNumber": "502-811817-8",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24475 Telegraph Rd",
          "JobAddress2": "",
          "JobFullAddress": "24475 Telegraph Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,258.51",
          "BranchNumber": 8,
          "SubmitDate": "06-26-2019",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 3944981,
          "ParentWorkOrder": 3934142,
          "JobName": "(NOF)Rochester Hills Apts",
          "JobNumber": "502-1639418-24",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1454 N. Rochester Rd",
          "JobAddress2": "",
          "JobFullAddress": "1454 N. Rochester Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,862.81",
          "BranchNumber": 8,
          "SubmitDate": "06-26-2019",
          "ClientonJob": "PATRIOT TEAM CONSTRUCTION"
        },
        {
          "WorkOrder": 3944978,
          "ParentWorkOrder": 3931208,
          "JobName": "(NOF)Ferndalehaus Phase 2",
          "JobNumber": "502-1639418-22",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "430 W. Nine Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "430 W. Nine Mile Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$10,160.31",
          "BranchNumber": 8,
          "SubmitDate": "06-26-2019",
          "ClientonJob": "PATRIOT TEAM CONSTRUCTION"
        },
        {
          "WorkOrder": 3944973,
          "ParentWorkOrder": 3934128,
          "JobName": "(NOF)Freestar Financial",
          "JobNumber": "502-917900-19",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "56350 Van Dyke Ave.",
          "JobAddress2": "",
          "JobFullAddress": "56350 Van Dyke Ave.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "R.E. LEGGETTE CO.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,309.12",
          "BranchNumber": 9,
          "SubmitDate": "06-26-2019",
          "ClientonJob": "R.E. LEGGETTE CO."
        },
        {
          "WorkOrder": 3944652,
          "ParentWorkOrder": 3938561,
          "JobName": "(NOF) HELEN ST",
          "JobNumber": "502-1677740-24",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4717 HELEN ST",
          "JobAddress2": "",
          "JobFullAddress": "4717 HELEN ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-25-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3944038,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Bluebird",
          "JobNumber": "502-1620200-2",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4466 Bluebird Dr",
          "JobAddress2": "",
          "JobFullAddress": "4466 Bluebird Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "4 CLOVER GROUP, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$2,828.58",
          "BranchNumber": 8,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "4 CLOVER GROUP, LLC"
        },
        {
          "WorkOrder": 3944037,
          "ParentWorkOrder": "",
          "JobName": "(NOF)COURTYARD MARRIOTT",
          "JobNumber": "502-1601069-8",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4741 28th ST SE",
          "JobAddress2": "",
          "JobFullAddress": "4741 28th ST SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "All-American Interior Con",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$694.99",
          "BranchNumber": 5,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "All-American Interior Con"
        },
        {
          "WorkOrder": 3944036,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ORCHARD LK CATARACT",
          "JobNumber": "502-1507798-24",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23133 ORCHARD LAKE RD",
          "JobAddress2": "",
          "JobFullAddress": "23133 ORCHARD LAKE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$22,006.00",
          "BalanceRemaining": "$837.86",
          "BranchNumber": 9,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3944035,
          "ParentWorkOrder": "",
          "JobName": "(NOF) THE JEFFREY / JOB NO. 17-0095",
          "JobNumber": "502-1507798-22",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "191 N. CHESTER ST",
          "JobAddress2": "",
          "JobFullAddress": "191 N. CHESTER ST",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$817.79",
          "BranchNumber": 9,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3944034,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BATH AND BODY",
          "JobNumber": "502-1395174-45",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3195 28th St SE # D102",
          "JobAddress2": "",
          "JobFullAddress": "3195 28th St SE # D102",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3944033,
          "ParentWorkOrder": "",
          "JobName": "(NOF) AWS Fallen Timbers",
          "JobNumber": "502-1305825-22",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6119 Finzel Rd.",
          "JobAddress2": "",
          "JobFullAddress": "6119 Finzel Rd.",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 3944032,
          "ParentWorkOrder": "",
          "JobName": "(NOF) AWS Jr. High School",
          "JobNumber": "502-1305825-21",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6035 Finzel Rd.",
          "JobAddress2": "",
          "JobFullAddress": "6035 Finzel Rd.",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 3944031,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Chase Bank Oregon",
          "JobNumber": "502-1304700-20",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2601 Navarre Ave.",
          "JobAddress2": "",
          "JobFullAddress": "2601 Navarre Ave.",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "LATHROP COMPANY",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$19,677.80",
          "BranchNumber": 13,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "LATHROP COMPANY"
        },
        {
          "WorkOrder": 3944030,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Perkins Room 604",
          "JobNumber": "502-1304200-6",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3714 Campbell Street",
          "JobAddress2": "Job# 2019-196",
          "JobFullAddress": "3714 Campbell Street Job# 2019-196",
          "JobCounty": "ERIE",
          "JobState": "Ohio",
          "CustomerCompanyName": "JANOTTA & HERNER, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement (Public Jobs)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "JANOTTA & HERNER, INC."
        },
        {
          "WorkOrder": 3944029,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HENRY FORD LIBRARY",
          "JobNumber": "502-967545-10",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16301 MICHIGAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "16301 MICHIGAN AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Carpentry Concepts & Ceilings",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,110.35",
          "BranchNumber": 9,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "Carpentry Concepts & Ceilings"
        },
        {
          "WorkOrder": 3944028,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Belle Tire Plainfld",
          "JobNumber": "502-967075-110",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2708 E. Main St.",
          "JobAddress2": "",
          "JobFullAddress": "2708 E. Main St.",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,136.68",
          "BranchNumber": 7,
          "SubmitDate": "",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 3944027,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ST FRANCIS CADRION",
          "JobNumber": "502-949575-106",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15305 WICK RD",
          "JobAddress2": "",
          "JobFullAddress": "15305 WICK RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$1,900.02",
          "BranchNumber": 9,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3944026,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ORCHESTRA LOBBY",
          "JobNumber": "502-933550-166",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3663 WOODWARD AVE",
          "JobAddress2": "",
          "JobFullAddress": "3663 WOODWARD AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$260.95",
          "BranchNumber": 9,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3944025,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TIMES NEWSTAND A61",
          "JobNumber": "502-933550-155",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "MCNAMARA TERMINAL/",
          "JobAddress2": "DETROIT METRO AIRPORT",
          "JobFullAddress": "MCNAMARA TERMINAL/ DETROIT METRO AIRPORT",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$591.43",
          "BranchNumber": 9,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3944024,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GrtLkCross A202Space/PO #185050A02",
          "JobNumber": "502-920940-33",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4300 BALDWIN ROAD #A202",
          "JobAddress2": "",
          "JobFullAddress": "4300 BALDWIN ROAD #A202",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$188.74",
          "BranchNumber": 8,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3944023,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HIGH ST.-NORTHVILLE",
          "JobNumber": "502-917690-9",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "440 HIGH ST",
          "JobAddress2": "",
          "JobFullAddress": "440 HIGH ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Kingdom Construction, Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "Kingdom Construction, Inc"
        },
        {
          "WorkOrder": 3944022,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Taco Bell Tecumseh",
          "JobNumber": "502-916315-125",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1006 West Chicago Blvd.",
          "JobAddress2": "",
          "JobFullAddress": "1006 West Chicago Blvd.",
          "JobCounty": "LENAWEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$6,011.43",
          "BranchNumber": 9,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3944021,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 1552 CHAMPS",
          "JobNumber": "502-913820-512",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17420 HALL RD",
          "JobAddress2": "",
          "JobFullAddress": "17420 HALL RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$5,352.77",
          "BranchNumber": 9,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3944020,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BJ'S WAREHOUSE CLUB",
          "JobNumber": "502-913400-25",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29101 JOHN R RD",
          "JobAddress2": "",
          "JobFullAddress": "29101 JOHN R RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DANCO CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$14,015.45",
          "BranchNumber": 9,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "DANCO CONTRACTING, INC."
        },
        {
          "WorkOrder": 3944018,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Starbucks",
          "JobNumber": "502-911900-4",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "465 NE Fuller Ave",
          "JobAddress2": "",
          "JobFullAddress": "465 NE Fuller Ave",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRANBETH CONSTR. INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "BRANBETH CONSTR. INC"
        },
        {
          "WorkOrder": 3944017,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HATTERAS PAINTING",
          "JobNumber": "502-910868-59",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13250 N HAGGERTY RD",
          "JobAddress2": "",
          "JobFullAddress": "13250 N HAGGERTY RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construct",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$7,098.73",
          "BranchNumber": 9,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "American Family Construct"
        },
        {
          "WorkOrder": 3944016,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ARBYS",
          "JobNumber": "502-876650-294",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4288 I75 BUSINESS SPUR",
          "JobAddress2": "",
          "JobFullAddress": "4288 I75 BUSINESS SPUR",
          "JobCounty": "CHIPPEWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,861.69",
          "BranchNumber": 8,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3944015,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Shred 415",
          "JobNumber": "502-856071-83",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "443 E. Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "443 E. Big Beaver Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$29,439.67",
          "BranchNumber": 8,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "M & V CONSTRUCTION"
        },
        {
          "WorkOrder": 3944014,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MONCARET",
          "JobNumber": "502-847401-142",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19074 FLORISSANT DR",
          "JobAddress2": "",
          "JobFullAddress": "19074 FLORISSANT DR",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MODERN DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$11,788.18",
          "BranchNumber": 11,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "MODERN DRYWALL INC"
        },
        {
          "WorkOrder": 3944013,
          "ParentWorkOrder": "",
          "JobName": "(NOF)N Oakland Plastic Su",
          "JobNumber": "502-817800-41",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "303 E Third Street",
          "JobAddress2": "Suite 220",
          "JobFullAddress": "303 E Third Street Suite 220",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,503.90",
          "BranchNumber": 8,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3944011,
          "ParentWorkOrder": "",
          "JobName": "(NOF)3068-00 Mclaren MRI",
          "JobNumber": "502-813200-125",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50 N Perry Street",
          "JobAddress2": "",
          "JobFullAddress": "50 N Perry Street",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$686.32",
          "BranchNumber": 8,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3944010,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Intrepid Cont. Syst.",
          "JobNumber": "502-812250-23",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1850 Research Drive",
          "JobAddress2": "",
          "JobFullAddress": "1850 Research Drive",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENNIS BAULT CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$90,006.00",
          "BalanceRemaining": "$9,855.52",
          "BranchNumber": 8,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "DENNIS BAULT CONSTRUCTION"
        },
        {
          "WorkOrder": 3944009,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RoushIndustries 600A",
          "JobNumber": "502-94091-16",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16640 Southfield Rd",
          "JobAddress2": "",
          "JobFullAddress": "16640 Southfield Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "EJ Kaneris Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$8,858.55",
          "BranchNumber": 8,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "EJ Kaneris Inc"
        },
        {
          "WorkOrder": 3944008,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GARDEN CITY SQUARE",
          "JobNumber": "502-11715-2",
          "FirstdayontheJob": "06-19-2019",
          "LastdayontheJob": "06-19-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29946-30120 FORD ROAD",
          "JobAddress2": "",
          "JobFullAddress": "29946-30120 FORD ROAD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PIERCE PLACE LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$324.32",
          "BranchNumber": 9,
          "SubmitDate": "06-20-2019",
          "ClientonJob": "PIERCE PLACE LLC"
        },
        {
          "WorkOrder": 3942803,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BYRD RD",
          "JobNumber": "502-1677740-26",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3505 BYRD RD",
          "JobAddress2": "",
          "JobFullAddress": "3505 BYRD RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$5,304.70",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3942802,
          "ParentWorkOrder": "",
          "JobName": "(NOF) COLSON ST",
          "JobNumber": "502-1677740-25",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15025 COLSON ST",
          "JobAddress2": "",
          "JobFullAddress": "15025 COLSON ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,806.00",
          "BalanceRemaining": "$3,024.68",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3942801,
          "ParentWorkOrder": "",
          "JobName": "(NOF) O'Reily's Jackson",
          "JobNumber": "502-1622590-3",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "300 W Michigan Ave.",
          "JobAddress2": "",
          "JobFullAddress": "300 W Michigan Ave.",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "The Dixon Construction Group LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$13,064.87",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "The Dixon Construction Group LLC"
        },
        {
          "WorkOrder": 3942800,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Precision Alloy",
          "JobNumber": "502-1609034-38",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "726 Innovation Dr",
          "JobAddress2": "",
          "JobFullAddress": "726 Innovation Dr",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,085.57",
          "BranchNumber": 13,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 3942799,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WTC AEC Temp Walls",
          "JobNumber": "502-1606296-7",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30400 Van Dyke Ave",
          "JobAddress2": "",
          "JobFullAddress": "30400 Van Dyke Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Regis Construction, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$10,684.68",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "Regis Construction, LLC"
        },
        {
          "WorkOrder": 3942798,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ST JOHN'S JOB# 1857",
          "JobNumber": "502-1590400-3",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22101 Moross Rd",
          "JobAddress2": "",
          "JobFullAddress": "22101 Moross Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BORTON CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "BORTON CONSTRUCTION INC"
        },
        {
          "WorkOrder": 3942797,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BELFOR - 18-052C",
          "JobNumber": "502-1524600-9",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2830 S. MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "2830 S. MAIN ST",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Sterling Millwork Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,506.00",
          "BalanceRemaining": "$6,167.17",
          "BranchNumber": 1,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "Sterling Millwork Inc"
        },
        {
          "WorkOrder": 3942796,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MOTT HIGH SCHOOL",
          "JobNumber": "502-1507798-21",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1151 SCOTT LAKE RD",
          "JobAddress2": "",
          "JobFullAddress": "1151 SCOTT LAKE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,681.72",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3942795,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PLANET FITNESS NOVI",
          "JobNumber": "502-1507798-20",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31124 BECK ROAD",
          "JobAddress2": "",
          "JobFullAddress": "31124 BECK ROAD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$7,531.38",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3942794,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BATH AND BODY",
          "JobNumber": "502-1395174-44",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3195 28TH ST SE",
          "JobAddress2": "",
          "JobFullAddress": "3195 28TH ST SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,074.14",
          "BranchNumber": 5,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3942793,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TANGLEWOOD TRAIL",
          "JobNumber": "502-1325000-14",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3220 TANGLEWOOD TRAIL",
          "JobAddress2": "",
          "JobFullAddress": "3220 TANGLEWOOD TRAIL",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,793.83",
          "BranchNumber": 5,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 3942792,
          "ParentWorkOrder": "",
          "JobName": "(NOF) STAYBRIDGE",
          "JobNumber": "502-1325000-13",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4488 POTOMAC AVE SW/3675 POTOMAC CIR SW",
          "JobAddress2": "",
          "JobFullAddress": "4488 POTOMAC AVE SW/3675 POTOMAC CIR SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$175,006.00",
          "BalanceRemaining": "$18,605.46",
          "BranchNumber": 5,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 3942791,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HI EXPRESS",
          "JobNumber": "502-1325000-12",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5830 Clyde Park Ave SW",
          "JobAddress2": "",
          "JobFullAddress": "5830 Clyde Park Ave SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$11,479.01",
          "BranchNumber": 5,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 3942790,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Belle Tire Camby",
          "JobNumber": "502-967075-109",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8116 Upland Bend",
          "JobAddress2": "",
          "JobFullAddress": "8116 Upland Bend",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,157.99",
          "BranchNumber": 7,
          "SubmitDate": "",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 3942789,
          "ParentWorkOrder": "",
          "JobName": "(NOF) RPT REALITY",
          "JobNumber": "502-952599-17",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20750 CIVIC CENTER DR",
          "JobAddress2": "",
          "JobFullAddress": "20750 CIVIC CENTER DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,558.56",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3942788,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDONALD'S #11557",
          "JobNumber": "502-944100-309",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2145 S. LINDEN RD",
          "JobAddress2": "",
          "JobFullAddress": "2145 S. LINDEN RD",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3942787,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Delux Nails",
          "JobNumber": "502-936150-54",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "ABSTRACT CEILINGS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "ABSTRACT CEILINGS, INC."
        },
        {
          "WorkOrder": 3942785,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dollar Tree",
          "JobNumber": "502-932775-64",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "871 Sumpter Road",
          "JobAddress2": "",
          "JobFullAddress": "871 Sumpter Road",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TLA INTERIORS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$7,366.33",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "TLA INTERIORS, INC."
        },
        {
          "WorkOrder": 3942784,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Redford Thirsten Hig",
          "JobNumber": "502-920940-64",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26255 Schoolcraft Rd",
          "JobAddress2": "#18-5041 Cafeteria",
          "JobFullAddress": "26255 Schoolcraft Rd #18-5041 Cafeteria",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,138.62",
          "BranchNumber": 8,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3942783,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GPF Memorial Church",
          "JobNumber": "502-916331-11",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16 Lake Shore Rd",
          "JobAddress2": "",
          "JobFullAddress": "16 Lake Shore Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "IDEAL CONTRACTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,889.24",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "IDEAL CONTRACTING LLC"
        },
        {
          "WorkOrder": 3942782,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SUMMIT POLYMERS",
          "JobNumber": "502-916315-121",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15101 N Commerce Dr",
          "JobAddress2": "",
          "JobFullAddress": "15101 N Commerce Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3942781,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1540 ARTHAUS FURN",
          "JobNumber": "502-913820-514",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER RD",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,223.27",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3942780,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1550 CANCER CENTER",
          "JobNumber": "502-913820-511",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22301 FOSTER WINTER DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "22301 FOSTER WINTER DRIVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3942779,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1549 THRIVE PROJECT",
          "JobNumber": "502-913820-510",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "28000 DEQUINDRE RD",
          "JobAddress2": "",
          "JobFullAddress": "28000 DEQUINDRE RD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$2,854.56",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3942778,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MACABES LOWER LEVEL",
          "JobNumber": "502-910868-58",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25800 NORTHWESTERN HWY",
          "JobAddress2": "LO1",
          "JobFullAddress": "25800 NORTHWESTERN HWY LO1",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$298.57",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "American Family Construction"
        },
        {
          "WorkOrder": 3942777,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SUDSY PUP GROOMING",
          "JobNumber": "502-910868-57",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1440 TORREY RD",
          "JobAddress2": "STE A",
          "JobFullAddress": "1440 TORREY RD STE A",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "AMERICAN  FAMILY CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$695.02",
          "BranchNumber": 9,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "AMERICAN  FAMILY CONSTRUCTION"
        },
        {
          "WorkOrder": 3942776,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Gerald Gomes",
          "JobNumber": "502-849801-202",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22039 E ANITA WAY",
          "JobAddress2": "",
          "JobFullAddress": "22039 E ANITA WAY",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$5,664.11",
          "BranchNumber": 11,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 3942775,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Masterworks",
          "JobNumber": "502-849801-201",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19304 Hudson River Dr",
          "JobAddress2": "",
          "JobFullAddress": "19304 Hudson River Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$5,095.72",
          "BranchNumber": 11,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 3942774,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DCC Dental Suite",
          "JobNumber": "502-823300-73",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "51817 Gratiot Ave",
          "JobAddress2": "",
          "JobFullAddress": "51817 Gratiot Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$18,006.00",
          "BalanceRemaining": "$2,495.53",
          "BranchNumber": 8,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 3942773,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Team Rehab",
          "JobNumber": "502-815630-212",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32270 Telegraph Rd",
          "JobAddress2": "2nd Floor",
          "JobFullAddress": "32270 Telegraph Rd 2nd Floor",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,224.62",
          "BranchNumber": 8,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3942772,
          "ParentWorkOrder": "",
          "JobName": "(NOF)23100 Michigan Ave",
          "JobNumber": "502-815630-210",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23100 Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "23100 Michigan Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$22,257.25",
          "BranchNumber": 8,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3942771,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9032 SJMO MCI pharmacy",
          "JobNumber": "502-815250-338",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44405 Woodward Ave.",
          "JobAddress2": "",
          "JobFullAddress": "44405 Woodward Ave.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,834.23",
          "BranchNumber": 8,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3942770,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9015 SJPHS 7 East",
          "JobNumber": "502-815250-334",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16001 West Nine Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "16001 West Nine Mile Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$429.36",
          "BranchNumber": 8,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3942769,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Body Control Systems",
          "JobNumber": "502-812250-22",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33737 W 12 Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "33737 W 12 Mile Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENNIS BAULT CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$1,154.85",
          "BranchNumber": 8,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "DENNIS BAULT CONSTRUCTION"
        },
        {
          "WorkOrder": 3942768,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GordonFoods ClntTwp",
          "JobNumber": "502-811817-9",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34300 S Gratiot Ave",
          "JobAddress2": "",
          "JobFullAddress": "34300 S Gratiot Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$1,187.24",
          "BranchNumber": 8,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 3942767,
          "ParentWorkOrder": "",
          "JobName": "(NOF)STOCKBRIDGE APTS",
          "JobNumber": "502-51950-42",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "420 STOCKING AVE NW",
          "JobAddress2": "",
          "JobFullAddress": "420 STOCKING AVE NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "SOBIE COMPANY, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$4,240.00",
          "BranchNumber": 5,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "SOBIE COMPANY, INC."
        },
        {
          "WorkOrder": 3942766,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Bureau Veritas",
          "JobNumber": "502-21350-49",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "815 N Opdyke Road",
          "JobAddress2": "",
          "JobFullAddress": "815 N Opdyke Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ERICKSON & LINDSTROM CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$12,585.41",
          "BranchNumber": 1,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "ERICKSON & LINDSTROM CONST."
        },
        {
          "WorkOrder": 3942765,
          "ParentWorkOrder": "",
          "JobName": "(NOF)McShane Mechanical",
          "JobNumber": "502-20010-23",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2055 Easy Street",
          "JobAddress2": "",
          "JobFullAddress": "2055 Easy Street",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 3942764,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MEIJER RENO SHIELDS",
          "JobNumber": "502-14600-38",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8400 GRATIOT RD",
          "JobAddress2": "",
          "JobFullAddress": "8400 GRATIOT RD",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Tri-City Acoustical",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$21,593.90",
          "BranchNumber": 1,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "Tri-City Acoustical"
        },
        {
          "WorkOrder": 3942763,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GRAYLING DPW",
          "JobNumber": "502-11790-14",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2920 INDUSTRIAL DR",
          "JobAddress2": "",
          "JobFullAddress": "2920 INDUSTRIAL DR",
          "JobCounty": "CRAWFORD",
          "JobState": "Michigan",
          "CustomerCompanyName": "GRAHAM CONSTRUCTION, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$4,484.01",
          "BranchNumber": 1,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "GRAHAM CONSTRUCTION, INC"
        },
        {
          "WorkOrder": 3942762,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DOWEASTEND2NDFLOOR",
          "JobNumber": "502-10950-56",
          "FirstdayontheJob": "06-13-2019",
          "LastdayontheJob": "06-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "715 E. MAIN ST. FL 2",
          "JobAddress2": "",
          "JobFullAddress": "715 E. MAIN ST. FL 2",
          "JobCounty": "MIDLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "CCP NORTH-MIDLAND",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$718.76",
          "BranchNumber": 1,
          "SubmitDate": "06-14-2019",
          "ClientonJob": "CCP NORTH-MIDLAND"
        },
        {
          "WorkOrder": 3941593,
          "ParentWorkOrder": 3931205,
          "JobName": "(NOF) Willard Bus Garage",
          "JobNumber": "502-1304186-43",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "515 E  Tiffin St",
          "JobAddress2": "",
          "JobFullAddress": "515 E  Tiffin St",
          "JobCounty": "HURON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,265.86",
          "BranchNumber": 13,
          "SubmitDate": "06-10-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3941586,
          "ParentWorkOrder": 3928161,
          "JobName": "(NOF)OSRAM CONTINENTAL",
          "JobNumber": "502-1507798-18",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1960 RESEARCH DR",
          "JobAddress2": "",
          "JobFullAddress": "1960 RESEARCH DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-10-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3941557,
          "ParentWorkOrder": 3923316,
          "JobName": "(NOF)MHAI THAI",
          "JobNumber": "502-920300-81",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6603 N CANTON CENTER RD STE 600",
          "JobAddress2": "",
          "JobFullAddress": "6603 N CANTON CENTER RD STE 600",
          "JobCounty": "CANTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$7,413.39",
          "BranchNumber": 9,
          "SubmitDate": "06-10-2019",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 3939595,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HERITAGE DR LOT# 11",
          "JobNumber": "502-910400-142",
          "FirstdayontheJob": "06-05-2019",
          "LastdayontheJob": "06-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "520 HERITAGE DR",
          "JobAddress2": "",
          "JobFullAddress": "520 HERITAGE DR",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACTION DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$4,505.53",
          "BranchNumber": 9,
          "SubmitDate": "06-06-2019",
          "ClientonJob": "ACTION DRYWALL"
        },
        {
          "WorkOrder": 3939594,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MSU MUSIC BUILDING",
          "JobNumber": "502-823200-69",
          "FirstdayontheJob": "06-05-2019",
          "LastdayontheJob": "06-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "333 W. Circle Drive",
          "JobAddress2": "",
          "JobFullAddress": "333 W. Circle Drive",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "PONTIAC CEILING & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$350,000.00",
          "BalanceRemaining": "$36,896.88",
          "BranchNumber": 8,
          "SubmitDate": "06-06-2019",
          "ClientonJob": "PONTIAC CEILING & PART"
        },
        {
          "WorkOrder": 3939593,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Fairlane",
          "JobNumber": "502-815630-211",
          "FirstdayontheJob": "06-05-2019",
          "LastdayontheJob": "06-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3153 Fairlane Dr",
          "JobAddress2": "",
          "JobFullAddress": "3153 Fairlane Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$14,710.58",
          "BranchNumber": 8,
          "SubmitDate": "06-06-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3939592,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9036 DTE ServBLD Renovation",
          "JobNumber": "502-815250-340",
          "FirstdayontheJob": "06-05-2019",
          "LastdayontheJob": "06-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "One Energy Plaza",
          "JobAddress2": "",
          "JobFullAddress": "One Energy Plaza",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$3,563.53",
          "BranchNumber": 8,
          "SubmitDate": "06-06-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3939591,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Crunch Fitness. E Lansing",
          "JobNumber": "502-111914-8",
          "FirstdayontheJob": "06-05-2019",
          "LastdayontheJob": "06-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2655 East Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "2655 East Grand River Ave",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Custom Build",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$12,245.95",
          "BranchNumber": 11,
          "SubmitDate": "06-06-2019",
          "ClientonJob": "Great Lakes Custom Build"
        },
        {
          "WorkOrder": 3939590,
          "ParentWorkOrder": "",
          "JobName": "(NOF)O'Reilly Auto Parts",
          "JobNumber": "502-10061-4",
          "FirstdayontheJob": "06-05-2019",
          "LastdayontheJob": "06-05-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "E CARO RD",
          "JobAddress2": "",
          "JobFullAddress": "E CARO RD",
          "JobCounty": "Tuscola",
          "JobState": "Michigan",
          "CustomerCompanyName": "A & M Contracting LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,669.76",
          "BranchNumber": 1,
          "SubmitDate": "06-06-2019",
          "ClientonJob": "A & M Contracting LLC"
        },
        {
          "WorkOrder": 3939442,
          "ParentWorkOrder": 3930176,
          "JobName": "(NOF)ALDI #43",
          "JobNumber": "502-1395174-40",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1323 W M 89 HWY",
          "JobAddress2": "",
          "JobFullAddress": "1323 W M 89 HWY",
          "JobCounty": "ALLEGAN",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$12,872.88",
          "BranchNumber": 5,
          "SubmitDate": "06-05-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3939379,
          "ParentWorkOrder": 3925265,
          "JobName": "(NOF)GLEANERS FOOD BANK",
          "JobNumber": "502-825063-49",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5924 STERLING DR",
          "JobAddress2": "",
          "JobFullAddress": "5924 STERLING DR",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHAFFER INCORPORATED",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$2,320.14",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "SHAFFER INCORPORATED"
        },
        {
          "WorkOrder": 3938562,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WoodSpring Suites",
          "JobNumber": "502-1691900-2",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1788 Indian Wood Cir",
          "JobAddress2": "",
          "JobFullAddress": "1788 Indian Wood Cir",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "INNOVATING DRYWALL SYSTEM",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,005.00",
          "BalanceRemaining": "$2,973.50",
          "BranchNumber": 13,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "INNOVATING DRYWALL SYSTEM"
        },
        {
          "WorkOrder": 3938561,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HELEN ST",
          "JobNumber": "502-1677740-24",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4717 HELEN ST",
          "JobAddress2": "",
          "JobFullAddress": "4717 HELEN ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3938560,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NORTHPOINT BLD 16",
          "JobNumber": "502-1645792-3",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3761 NORTH POINTE DR",
          "JobAddress2": "",
          "JobFullAddress": "3761 NORTH POINTE DR",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "PRD Construction, Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$19,294.96",
          "BranchNumber": 5,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "PRD Construction, Inc"
        },
        {
          "WorkOrder": 3938559,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Horseshoe Bend",
          "JobNumber": "502-1611204-25",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "112 Valley Hall Dr.",
          "JobAddress2": "LB 7015",
          "JobFullAddress": "112 Valley Hall Dr. LB 7015",
          "JobCounty": "Wood",
          "JobState": "Ohio",
          "CustomerCompanyName": "JR Interiors",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,706.00",
          "BalanceRemaining": "$4,112.18",
          "BranchNumber": 13,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "JR Interiors"
        },
        {
          "WorkOrder": 3938558,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NANTUCKET BAKERY",
          "JobNumber": "502-1395174-43",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1308 NE FULLER AVE",
          "JobAddress2": "",
          "JobFullAddress": "1308 NE FULLER AVE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3938557,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MCALISTER BUILDING",
          "JobNumber": "502-1344933-12",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6190 CLYDE PARK AVE SW",
          "JobAddress2": "",
          "JobFullAddress": "6190 CLYDE PARK AVE SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER 1 INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,867.29",
          "BranchNumber": 5,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "PREMIER 1 INTERIORS LLC"
        },
        {
          "WorkOrder": 3938556,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dollar General",
          "JobNumber": "502-1304186-45",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2844 State Route 66",
          "JobAddress2": "",
          "JobFullAddress": "2844 State Route 66",
          "JobCounty": "SHELBY",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$2,338.96",
          "BranchNumber": 13,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3938555,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GVSU STUDENT SERVICES BLDG",
          "JobNumber": "502-1060142-8",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 CAMPUS DR",
          "JobAddress2": "",
          "JobFullAddress": "1 CAMPUS DR",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,812.02",
          "BranchNumber": 5,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 3938554,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Eaton HR",
          "JobNumber": "502-967400-2",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "300 S. East Ave.",
          "JobAddress2": "",
          "JobFullAddress": "300 S. East Ave.",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "COLLINS DESIGN/BUILD",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,633.32",
          "BranchNumber": 7,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "COLLINS DESIGN/BUILD"
        },
        {
          "WorkOrder": 3938553,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Radiant Church",
          "JobNumber": "502-967075-113",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2700 Spring Arbor Road",
          "JobAddress2": "",
          "JobFullAddress": "2700 Spring Arbor Road",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$10,564.20",
          "BranchNumber": 7,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 3938552,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KEYSTONE UNIVERSAL",
          "JobNumber": "502-949575-105",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18400 RIALTO ST",
          "JobAddress2": "",
          "JobFullAddress": "18400 RIALTO ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$5,524.72",
          "BranchNumber": 9,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3938551,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HENRY FORD AH JACKSO",
          "JobNumber": "502-933550-165",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "205 N EAST AVE",
          "JobAddress2": "",
          "JobFullAddress": "205 N EAST AVE",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$245.36",
          "BranchNumber": 9,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3938550,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dickey's",
          "JobNumber": "502-920300-82",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10411 Fremont Pike",
          "JobAddress2": "",
          "JobFullAddress": "10411 Fremont Pike",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 3938549,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ZHONGDING",
          "JobNumber": "502-916800-29",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "48600 5 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "48600 5 MILE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "JASMAN CONSTRUCTION, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$12,208.21",
          "BranchNumber": 9,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "JASMAN CONSTRUCTION, INC"
        },
        {
          "WorkOrder": 3938548,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1551 BATH & BODY",
          "JobNumber": "502-913820-513",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2693 S. ROCHESTER RD",
          "JobAddress2": "",
          "JobFullAddress": "2693 S. ROCHESTER RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$16,006.00",
          "BalanceRemaining": "$669.79",
          "BranchNumber": 9,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3938547,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Ford-Rotunda",
          "JobNumber": "502-912080-11",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17101 Rotunda Dr",
          "JobAddress2": "",
          "JobFullAddress": "17101 Rotunda Dr",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRIX CORPORATION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,174.89",
          "BranchNumber": 9,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "BRIX CORPORATION"
        },
        {
          "WorkOrder": 3938546,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 640 TEMPLE",
          "JobNumber": "502-910900-9",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "640 TEMPLE ST",
          "JobAddress2": "",
          "JobFullAddress": "640 TEMPLE ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANN ARBOR CEILING & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$500,000.00",
          "BalanceRemaining": "$934.53",
          "BranchNumber": 9,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "ANN ARBOR CEILING & PART"
        },
        {
          "WorkOrder": 3938545,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WSU STEM INNOVATION",
          "JobNumber": "502-910050-54",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5048 GULLEN MALL",
          "JobAddress2": "",
          "JobFullAddress": "5048 GULLEN MALL",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$33,655.06",
          "BranchNumber": 9,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 3938544,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Dollar Tree",
          "JobNumber": "502-876752-8",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2329 S I-75 Business Loop",
          "JobAddress2": "",
          "JobFullAddress": "2329 S I-75 Business Loop",
          "JobCounty": "CRAWFORD",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$5,175.96",
          "BranchNumber": 8,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 3938543,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ARBYS PLAINWELL",
          "JobNumber": "502-876650-291",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1215 M-89 HWY",
          "JobAddress2": "",
          "JobFullAddress": "1215 M-89 HWY",
          "JobCounty": "ALLEGAN",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$11,695.29",
          "BranchNumber": 8,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3938542,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HMOR-6185 S Channel",
          "JobNumber": "502-859551-20",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6185 S Channel Dr",
          "JobAddress2": "",
          "JobFullAddress": "6185 S Channel Dr",
          "JobCounty": "St Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "PICKLEHAUPT DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$4,934.18",
          "BranchNumber": 11,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "PICKLEHAUPT DRYWALL"
        },
        {
          "WorkOrder": 3938541,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Public Service",
          "JobNumber": "502-823300-60",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4646 John R St",
          "JobAddress2": "",
          "JobFullAddress": "4646 John R St",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$40.60",
          "BranchNumber": 8,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 3938540,
          "ParentWorkOrder": "",
          "JobName": "(NOF)5725 Delphi Dr",
          "JobNumber": "502-822199-15",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5725 Delphi Dr/5725 Innovation Dr",
          "JobAddress2": "",
          "JobFullAddress": "5725 Delphi Dr/5725 Innovation Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATIONAL BUSINESS SUPPLY INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$10,477.21",
          "BranchNumber": 8,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "NATIONAL BUSINESS SUPPLY INC"
        },
        {
          "WorkOrder": 3938539,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Livingston Cnty 911",
          "JobNumber": "502-817800-40",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "300 South Highlander Way",
          "JobAddress2": "",
          "JobFullAddress": "300 South Highlander Way",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3938538,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Cintas Job",
          "JobNumber": "502-814500-78",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2408 S Canal Rd",
          "JobAddress2": "",
          "JobFullAddress": "2408 S Canal Rd",
          "JobCounty": "EATON",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONST",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$10,129.53",
          "BranchNumber": 8,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "COMMERCIAL INT CONST"
        },
        {
          "WorkOrder": 3938537,
          "ParentWorkOrder": "",
          "JobName": "(NOF)NORTH MIXED",
          "JobNumber": "502-51950-40",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "449 BRIDGE ST NW",
          "JobAddress2": "",
          "JobFullAddress": "449 BRIDGE ST NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "SOBIE COMPANY, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$15,016.04",
          "BranchNumber": 5,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "SOBIE COMPANY, INC."
        },
        {
          "WorkOrder": 3938536,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ALDI'S BAYRD",
          "JobNumber": "502-20010-22",
          "FirstdayontheJob": "05-31-2019",
          "LastdayontheJob": "05-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5275 BAY RD",
          "JobAddress2": "",
          "JobFullAddress": "5275 BAY RD",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "AC CONSTRUCTION CO INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,506.00",
          "BalanceRemaining": "$3,631.83",
          "BranchNumber": 8,
          "SubmitDate": "06-01-2019",
          "ClientonJob": "AC CONSTRUCTION CO INC"
        },
        {
          "WorkOrder": 3937394,
          "ParentWorkOrder": 3930175,
          "JobName": "(NOF)Promedica Hickman Job#186220",
          "JobNumber": "502-1301520-5",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5640 N. Adrian Highway",
          "JobAddress2": "",
          "JobFullAddress": "5640 N. Adrian Highway",
          "JobCounty": "LENAWEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "A.A. BOOS & SONS INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "05-25-2019",
          "ClientonJob": "A.A. BOOS & SONS INC."
        },
        {
          "WorkOrder": 3936281,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DOXTATOR HOUSE",
          "JobNumber": "502-1677740-23",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26615 DOXTATOR ST",
          "JobAddress2": "",
          "JobFullAddress": "26615 DOXTATOR ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3936280,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KENILLWORTH HOUSE",
          "JobNumber": "502-1677740-22",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6251 KENILWORTH ST",
          "JobAddress2": "",
          "JobFullAddress": "6251 KENILWORTH ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,606.00",
          "BalanceRemaining": "$3,687.27",
          "BranchNumber": 9,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3936279,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Fell Center",
          "JobNumber": "502-1651680-2",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "650 N. Saginaw St",
          "JobAddress2": "",
          "JobFullAddress": "650 N. Saginaw St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PONTIAC SCHOOL DISTRICT",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$19,269.20",
          "BranchNumber": 8,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "PONTIAC SCHOOL DISTRICT"
        },
        {
          "WorkOrder": 3936278,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hobby Lobby Defiance",
          "JobNumber": "502-1610113-2",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 N. Clinton Street #6 & #8",
          "JobAddress2": "",
          "JobFullAddress": "1500 N. Clinton Street #6 & #8",
          "JobCounty": "Defiance",
          "JobState": "Ohio",
          "CustomerCompanyName": "AJS CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$21,464.21",
          "BranchNumber": 13,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "AJS CONSTRUCTION"
        },
        {
          "WorkOrder": 3936277,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Tiffin Dialysis",
          "JobNumber": "502-1304186-44",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "10 ST LAWRENCE DR",
          "JobAddress2": "",
          "JobFullAddress": "10 ST LAWRENCE DR",
          "JobCounty": "Seneca",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$13,545.18",
          "BranchNumber": 13,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3936276,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Diversified",
          "JobNumber": "502-1301172-43",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7116 S STATE ROUTE 53",
          "JobAddress2": "",
          "JobFullAddress": "7116 S STATE ROUTE 53",
          "JobCounty": "Seneca",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,907.42",
          "BranchNumber": 13,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 3936275,
          "ParentWorkOrder": "",
          "JobName": "(NOF) COMMERCE",
          "JobNumber": "502-944650-4",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23333 Commerce Dr",
          "JobAddress2": "",
          "JobFullAddress": "23333 Commerce Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DEVELOPMENT SRV. LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$278.91",
          "BranchNumber": 9,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "DEVELOPMENT SRV. LLC"
        },
        {
          "WorkOrder": 3936274,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Taco Bell Blackman",
          "JobNumber": "502-916315-120",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2545 Airport Rd.",
          "JobAddress2": "",
          "JobFullAddress": "2545 Airport Rd.",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$2,194.71",
          "BranchNumber": 9,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3936273,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1547 THE WALKING CO",
          "JobNumber": "502-913820-508",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "2800 W Big Beaver Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$2,362.42",
          "BranchNumber": 9,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3936272,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1517 MRI REPLACEMENT",
          "JobNumber": "502-913820-503",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3601 W 13 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "3601 W 13 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$124.37",
          "BranchNumber": 9,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3936271,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HERITAGE LOT# 3",
          "JobNumber": "502-910400-141",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "509 HERITAGE RIDGE DR",
          "JobAddress2": "LOT #3",
          "JobFullAddress": "509 HERITAGE RIDGE DR LOT #3",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACTION DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,701.02",
          "BranchNumber": 9,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "ACTION DRYWALL"
        },
        {
          "WorkOrder": 3936270,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1200 Meisner Rd",
          "JobNumber": "502-877250-119",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1200 Meisner Rd",
          "JobAddress2": "",
          "JobFullAddress": "1200 Meisner Rd",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Consstruction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,300.00",
          "BalanceRemaining": "$5,225.82",
          "BranchNumber": 8,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "Blue Water Drywall & Consstruction"
        },
        {
          "WorkOrder": 3936269,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ARBYS #7050",
          "JobNumber": "502-876650-289",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2652 S STATE RD",
          "JobAddress2": "",
          "JobFullAddress": "2652 S STATE RD",
          "JobCounty": "IONIA",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3936268,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(260) Ft Gratiot MS",
          "JobNumber": "502-816350-139",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3985 Keewahdin Dr",
          "JobAddress2": "",
          "JobFullAddress": "3985 Keewahdin Dr",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,844.22",
          "BranchNumber": 8,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 3936267,
          "ParentWorkOrder": "",
          "JobName": "(NOF)16525 Fort St",
          "JobNumber": "502-815630-207",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16525 Fort St",
          "JobAddress2": "",
          "JobFullAddress": "16525 Fort St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$16,107.01",
          "BranchNumber": 8,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3936266,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9025 HFHybrid OR",
          "JobNumber": "502-815250-336",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2799 W. Grand Blvd",
          "JobAddress2": "",
          "JobFullAddress": "2799 W. Grand Blvd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$938.27",
          "BranchNumber": 8,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3936265,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Tru By Hilton",
          "JobNumber": "502-812692-17",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1731 N. Dixie Hwy",
          "JobAddress2": "",
          "JobFullAddress": "1731 N. Dixie Hwy",
          "JobCounty": "MONROE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Starbest Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$24,351.19",
          "BranchNumber": 8,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "Starbest Construction"
        },
        {
          "WorkOrder": 3936264,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HIE PROJECT 1705",
          "JobNumber": "502-812692-5",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5724 W. Main Street",
          "JobAddress2": "",
          "JobFullAddress": "5724 W. Main Street",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "Starbest Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$536.69",
          "BranchNumber": 8,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "Starbest Construction"
        },
        {
          "WorkOrder": 3936263,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WESCO #41",
          "JobNumber": "502-50435-54",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "211 N MAIN",
          "JobAddress2": "",
          "JobFullAddress": "211 N MAIN",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR BOUWKAMP & ASSOC. INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$413.40",
          "BranchNumber": 5,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "JR BOUWKAMP & ASSOC. INC"
        },
        {
          "WorkOrder": 3936262,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KEMBER FL CONNECTOR",
          "JobNumber": "502-30930-6",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4144 LYLE RD",
          "JobAddress2": "",
          "JobFullAddress": "4144 LYLE RD",
          "JobCounty": "GLADWIN",
          "JobState": "Michigan",
          "CustomerCompanyName": "JBS CONTRACTING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$8,691.29",
          "BranchNumber": 1,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "JBS CONTRACTING, INC"
        },
        {
          "WorkOrder": 3936261,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCLAREN FENTON",
          "JobNumber": "502-14600-39",
          "FirstdayontheJob": "05-22-2019",
          "LastdayontheJob": "05-22-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2420 OWEN RD.",
          "JobAddress2": "",
          "JobFullAddress": "2420 OWEN RD.",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Tri-City Acoustical",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$1,885.40",
          "BranchNumber": 1,
          "SubmitDate": "05-23-2019",
          "ClientonJob": "Tri-City Acoustical"
        },
        {
          "WorkOrder": 3935829,
          "ParentWorkOrder": 3930177,
          "JobName": "(NOF)GRAND VILLAGE PH3",
          "JobNumber": "502-1395174-41",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5050 PIERCE ST",
          "JobAddress2": "",
          "JobFullAddress": "5050 PIERCE ST",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$763.20",
          "BranchNumber": 5,
          "SubmitDate": "05-22-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3935082,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Northridge",
          "JobNumber": "502-1639418-25",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "49555 North Territorial Rd",
          "JobAddress2": "",
          "JobFullAddress": "49555 North Territorial Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$200.77",
          "BranchNumber": 8,
          "SubmitDate": "05-17-2019",
          "ClientonJob": "PATRIOT TEAM CONSTRUCTION"
        },
        {
          "WorkOrder": 3935081,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HYATTHOTEL FLINT PH2",
          "JobNumber": "502-1605376-5",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5481 HILL 23 DRIVE",
          "JobAddress2": "",
          "JobFullAddress": "5481 HILL 23 DRIVE",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Arland Draper Masonry",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$1,915.28",
          "BranchNumber": 1,
          "SubmitDate": "05-17-2019",
          "ClientonJob": "Arland Draper Masonry"
        },
        {
          "WorkOrder": 3935080,
          "ParentWorkOrder": "",
          "JobName": "(NOF) PROTRANS",
          "JobNumber": "502-1507798-14",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "37675 VAN BORN RD",
          "JobAddress2": "",
          "JobFullAddress": "37675 VAN BORN RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$142.15",
          "BranchNumber": 9,
          "SubmitDate": "05-17-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3935079,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ALDI STORE 43",
          "JobNumber": "502-1395174-38",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3935078,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WALL STREET JOUR A72",
          "JobNumber": "502-933550-158",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 DETROIT METRO AIRPORT, A72",
          "JobAddress2": "",
          "JobFullAddress": "1 DETROIT METRO AIRPORT, A72",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$344.73",
          "BranchNumber": 9,
          "SubmitDate": "05-17-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3935077,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Tradehome Shoes",
          "JobNumber": "502-916315-119",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 Briarwood Circle",
          "JobAddress2": "Space #E121",
          "JobFullAddress": "100 Briarwood Circle Space #E121",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,073.34",
          "BranchNumber": 9,
          "SubmitDate": "05-17-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3935076,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1548 ENDO EXPANSION",
          "JobNumber": "502-913820-509",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "47601 GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "47601 GRAND RIVER AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$395.22",
          "BranchNumber": 9,
          "SubmitDate": "05-17-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3935075,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1545 BATH & BODY",
          "JobNumber": "502-913820-507",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9670 VILLAGE PLACE BLVD E2",
          "JobAddress2": "",
          "JobFullAddress": "9670 VILLAGE PLACE BLVD E2",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$506.61",
          "BranchNumber": 9,
          "SubmitDate": "05-17-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3935074,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Bricks Pizzeria",
          "JobNumber": "502-864751-200",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15201 Kercheval Ave",
          "JobAddress2": "",
          "JobFullAddress": "15201 Kercheval Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "STILES DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$7,884.96",
          "BranchNumber": 11,
          "SubmitDate": "05-17-2019",
          "ClientonJob": "STILES DRYWALL LLC"
        },
        {
          "WorkOrder": 3935073,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RCS Transport Build",
          "JobNumber": "502-817800-38",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "380 S. Livernois Rd",
          "JobAddress2": "",
          "JobFullAddress": "380 S. Livernois Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$5,990.19",
          "BranchNumber": 8,
          "SubmitDate": "05-17-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3935072,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dr Berenholz",
          "JobNumber": "502-815630-208",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30445 Northwestern Hwy",
          "JobAddress2": "",
          "JobFullAddress": "30445 Northwestern Hwy",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,235.80",
          "BranchNumber": 8,
          "SubmitDate": "05-17-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3935071,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9031 SJMO Main Pharmacy",
          "JobNumber": "502-815250-339",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44405 Woodward Ave.",
          "JobAddress2": "",
          "JobFullAddress": "44405 Woodward Ave.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,749.04",
          "BranchNumber": 8,
          "SubmitDate": "05-17-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3935070,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LINDENMEYER",
          "JobNumber": "502-52900-36",
          "FirstdayontheJob": "05-16-2019",
          "LastdayontheJob": "05-16-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2944 WALKENT DR NW",
          "JobAddress2": "",
          "JobFullAddress": "2944 WALKENT DR NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "MID MICHIGAN CEILING &",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,096.35",
          "BranchNumber": 5,
          "SubmitDate": "05-17-2019",
          "ClientonJob": "MID MICHIGAN CEILING &"
        },
        {
          "WorkOrder": 3934373,
          "ParentWorkOrder": 3914380,
          "JobName": "(NOF)Holiday Inn Express",
          "JobNumber": "502-1325000-9",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3853 Research Park Dr",
          "JobAddress2": "",
          "JobFullAddress": "3853 Research Park Dr",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "05-15-2019",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 3934361,
          "ParentWorkOrder": 3925266,
          "JobName": "(NOF) NORTHVILLE HIGH",
          "JobNumber": "502-910050-53",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45700 6 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "45700 6 MILE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,598.65",
          "BranchNumber": 9,
          "SubmitDate": "05-15-2019",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 3934358,
          "ParentWorkOrder": 3925277,
          "JobName": "(NOF) Automated Logistics Job #19.0177.04",
          "JobNumber": "502-971850-31",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3517 Scheele Dr.",
          "JobAddress2": "",
          "JobFullAddress": "3517 Scheele Dr.",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "R.W. MERCER CO",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "05-15-2019",
          "ClientonJob": "R.W. MERCER CO"
        },
        {
          "WorkOrder": 3934356,
          "ParentWorkOrder": 3920991,
          "JobName": "(NOF)Village Market",
          "JobNumber": "502-876650-278",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18310 Mack Ave",
          "JobAddress2": "",
          "JobFullAddress": "18310 Mack Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-15-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3934354,
          "ParentWorkOrder": 3926631,
          "JobName": "(NOF) CHEMICAL BANK",
          "JobNumber": "502-1395174-39",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "155 W MICHIGAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "155 W MICHIGAN AVE",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$27,465.12",
          "BranchNumber": 5,
          "SubmitDate": "05-15-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3934351,
          "ParentWorkOrder": 3925262,
          "JobName": "(NOF)Royal Oak High School",
          "JobNumber": "502-813200-115",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 Lexington Blvd.",
          "JobAddress2": "",
          "JobFullAddress": "1500 Lexington Blvd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING CORP.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$1,515.80",
          "BranchNumber": 8,
          "SubmitDate": "05-15-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING CORP."
        },
        {
          "WorkOrder": 3934342,
          "ParentWorkOrder": 3926622,
          "JobName": "(NOF)1541 GAMMA KNIFE PROJECT ROK18-00018",
          "JobNumber": "502-913820-502",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3601 W 13 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "3601 W 13 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$43.19",
          "BranchNumber": 9,
          "SubmitDate": "05-15-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3934338,
          "ParentWorkOrder": 3930739,
          "JobName": "(NOF)Free Star Financial",
          "JobNumber": "502-817800-37",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "56350 Van Dyke Ave.",
          "JobAddress2": "",
          "JobFullAddress": "56350 Van Dyke Ave.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$10,193.34",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3934337,
          "ParentWorkOrder": 3926623,
          "JobName": "(NOF) Sharp Funeral Home",
          "JobNumber": "502-916315-113",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8138 Miller Road",
          "JobAddress2": "",
          "JobFullAddress": "8138 Miller Road",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$824.09",
          "BranchNumber": 9,
          "SubmitDate": "05-15-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3934144,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WINTERGREEN HOUSE",
          "JobNumber": "502-1677740-21",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "28988 WINTERGREEN DR",
          "JobAddress2": "",
          "JobFullAddress": "28988 WINTERGREEN DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$7,363.43",
          "BranchNumber": 9,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3934143,
          "ParentWorkOrder": "",
          "JobName": "(NOF) UofD",
          "JobNumber": "502-1650066-3",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4001 W Mcnichols Rd",
          "JobAddress2": "",
          "JobFullAddress": "4001 W Mcnichols Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PCC INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "PCC INTERIORS"
        },
        {
          "WorkOrder": 3934142,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Rochester Hills Apts",
          "JobNumber": "502-1639418-24",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1454 N. Rochester Rd",
          "JobAddress2": "",
          "JobFullAddress": "1454 N. Rochester Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$3,862.81",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "PATRIOT TEAM CONSTRUCTION"
        },
        {
          "WorkOrder": 3934141,
          "ParentWorkOrder": "",
          "JobName": "(NOF)All Seasons of AA",
          "JobNumber": "502-1639418-23",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4540 Geddes Rd",
          "JobAddress2": "",
          "JobFullAddress": "4540 Geddes Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$9,524.53",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "PATRIOT TEAM CONSTRUCTION"
        },
        {
          "WorkOrder": 3934140,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Driftwood Ypsilanti",
          "JobNumber": "502-1621700-5",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1821 Wexford Dr",
          "JobAddress2": "",
          "JobFullAddress": "1821 Wexford Dr",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHINGLETON CONTRACTORS, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$8,456.43",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "SHINGLETON CONTRACTORS, INC"
        },
        {
          "WorkOrder": 3934139,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GVSU",
          "JobNumber": "502-1395174-42",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5050 PIERCE ST",
          "JobAddress2": "",
          "JobFullAddress": "5050 PIERCE ST",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$2,401.80",
          "BranchNumber": 5,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3934138,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TOWNPLACE SUITES",
          "JobNumber": "502-1325000-11",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5830 CLYDE PARK SW",
          "JobAddress2": "",
          "JobFullAddress": "5830 CLYDE PARK SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$175,006.00",
          "BalanceRemaining": "$12,966.76",
          "BranchNumber": 5,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 3934137,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Montpelier McDonalds",
          "JobNumber": "502-1305825-19",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1100 East Main Street",
          "JobAddress2": "",
          "JobFullAddress": "1100 East Main Street",
          "JobCounty": "WILLIAMS",
          "JobState": "Ohio",
          "CustomerCompanyName": "MIDWEST CONTRACTING, INC.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "MIDWEST CONTRACTING, INC."
        },
        {
          "WorkOrder": 3934136,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Dollar General",
          "JobNumber": "502-1304186-42",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "961 N Dixie Hwy",
          "JobAddress2": "",
          "JobFullAddress": "961 N Dixie Hwy",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$130.81",
          "BranchNumber": 13,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3934135,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Holiday Inn Rosevill",
          "JobNumber": "502-990401-46",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30300 Gratiot Ave",
          "JobAddress2": "",
          "JobFullAddress": "30300 Gratiot Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$7,080.80",
          "BranchNumber": 7,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3934134,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MONUMENT PARK",
          "JobNumber": "502-944100-316",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8031 MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "8031 MAIN ST",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$1,658.69",
          "BranchNumber": 9,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3934133,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HEALEY",
          "JobNumber": "502-944100-315",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2820 BAKER RD",
          "JobAddress2": "",
          "JobFullAddress": "2820 BAKER RD",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$804.34",
          "BranchNumber": 9,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3934132,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDONALD'S  # 14349",
          "JobNumber": "502-944100-308",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 W Bristol Rd",
          "JobAddress2": "",
          "JobFullAddress": "1500 W Bristol Rd",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3934131,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BRASHEAR TOWERS METAL MBA",
          "JobNumber": "502-937250-154",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17841 N. LAUREL PARK DR",
          "JobAddress2": "",
          "JobFullAddress": "17841 N. LAUREL PARK DR",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINKER TEAM CONSTRUCTION, CO.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$3,271.54",
          "BranchNumber": 9,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "BRINKER TEAM CONSTRUCTION, CO."
        },
        {
          "WorkOrder": 3934130,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Chapman Elementary",
          "JobNumber": "502-920940-59",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31500 Olmstead Rd",
          "JobAddress2": "",
          "JobFullAddress": "31500 Olmstead Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3934129,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Great Lakes Crossin",
          "JobNumber": "502-920940-57",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4000 Baldwin Rd",
          "JobAddress2": "",
          "JobFullAddress": "4000 Baldwin Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3934128,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Freestar Financial",
          "JobNumber": "502-917900-19",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "56350 Van Dyke Ave.",
          "JobAddress2": "",
          "JobFullAddress": "56350 Van Dyke Ave.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "R.E. LEGGETTE CO.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,309.12",
          "BranchNumber": 9,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "R.E. LEGGETTE CO."
        },
        {
          "WorkOrder": 3934127,
          "ParentWorkOrder": "",
          "JobName": "(NOF)THORTON & GROOMS",
          "JobNumber": "502-916315-118",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24501 HALLWOOD CT",
          "JobAddress2": "",
          "JobFullAddress": "24501 HALLWOOD CT",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3934126,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Rainbow Apparel",
          "JobNumber": "502-916315-117",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9367 Telegraph Road",
          "JobAddress2": "",
          "JobFullAddress": "9367 Telegraph Road",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,952.86",
          "BranchNumber": 9,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3934125,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Ryan Residence",
          "JobNumber": "502-876650-287",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "54405 Queensborough Drive",
          "JobAddress2": "",
          "JobFullAddress": "54405 Queensborough Drive",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,253.04",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3934124,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CA NOVI PROJECT",
          "JobNumber": "502-849801-196",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "42400 12 MILE ROAD",
          "JobAddress2": "",
          "JobFullAddress": "42400 12 MILE ROAD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 3934123,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Red Olive Restaurant",
          "JobNumber": "502-827505-4",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "750 W 9 Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "750 W 9 Mile Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEVIN VIAL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,731.93",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "KEVIN VIAL"
        },
        {
          "WorkOrder": 3934122,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MATRIX QUALITY",
          "JobNumber": "502-825063-50",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "301 APPIAN WAY",
          "JobAddress2": "",
          "JobFullAddress": "301 APPIAN WAY",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHAFFER INCORPORATED",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$8,149.70",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "SHAFFER INCORPORATED"
        },
        {
          "WorkOrder": 3934121,
          "ParentWorkOrder": "",
          "JobName": "(NOF)EMU Student Athlete",
          "JobNumber": "502-823200-71",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "150 WESTVIEW DR",
          "JobAddress2": "",
          "JobFullAddress": "150 WESTVIEW DR",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "PONTIAC CEILING & PART",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$350,000.00",
          "BalanceRemaining": "$21,299.74",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "PONTIAC CEILING & PART"
        },
        {
          "WorkOrder": 3934120,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Samsung SDI",
          "JobNumber": "502-820500-34",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4121 N Atlantic Blvd",
          "JobAddress2": "",
          "JobFullAddress": "4121 N Atlantic Blvd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEE CONTRACTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "LEE CONTRACTING LLC"
        },
        {
          "WorkOrder": 3934119,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Livonia Retail",
          "JobNumber": "502-815630-206",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "29601 7 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "29601 7 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3934118,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9035 Karmanos Spe",
          "JobNumber": "502-815250-341",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "711 Stephenson Hwy",
          "JobAddress2": "",
          "JobFullAddress": "711 Stephenson Hwy",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$2,031.82",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3934117,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Panera Bread",
          "JobNumber": "502-814500-77",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2080 W. Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "2080 W. Grand River Ave",
          "JobCounty": "INGHAM",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONST",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$16,071.36",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "COMMERCIAL INT CONST"
        },
        {
          "WorkOrder": 3934116,
          "ParentWorkOrder": "",
          "JobName": "(NOF)U of M Murchie-Flint Job # 3060",
          "JobNumber": "502-813200-124",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "415 East Kearsley St",
          "JobAddress2": "",
          "JobFullAddress": "415 East Kearsley St",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,196.30",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3934115,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Gordon Food Service",
          "JobNumber": "502-811817-8",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24475 Telegraph Rd",
          "JobAddress2": "",
          "JobFullAddress": "24475 Telegraph Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$6,258.51",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 3934114,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Meijer 057 Rochester",
          "JobNumber": "502-811817-7",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3175 S. Rochester Road",
          "JobAddress2": "",
          "JobFullAddress": "3175 S. Rochester Road",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Big Sky Service Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$150,006.00",
          "BalanceRemaining": "$1,271.11",
          "BranchNumber": 8,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "Big Sky Service Company"
        },
        {
          "WorkOrder": 3934113,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BLAINS FARM & FLEET",
          "JobNumber": "502-50435-55",
          "FirstdayontheJob": "05-13-2019",
          "LastdayontheJob": "05-13-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3940 WEST SHORE DR",
          "JobAddress2": "",
          "JobFullAddress": "3940 WEST SHORE DR",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR BOUWKAMP & ASSOC. INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$11,596.81",
          "BranchNumber": 5,
          "SubmitDate": "05-14-2019",
          "ClientonJob": "JR BOUWKAMP & ASSOC. INC"
        },
        {
          "WorkOrder": 3931889,
          "ParentWorkOrder": 3926634,
          "JobName": "(NOF)155 W Spring St.",
          "JobNumber": "502-1650969-40",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "155 W Spring St.",
          "JobAddress2": "",
          "JobFullAddress": "155 W Spring St.",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$7,904.62",
          "BranchNumber": 1,
          "SubmitDate": "05-03-2019",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 3931210,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WHITENFIELD HOUSE",
          "JobNumber": "502-1677740-20",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1810 WHITEFIELD ST",
          "JobAddress2": "",
          "JobFullAddress": "1810 WHITEFIELD ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,506.00",
          "BalanceRemaining": "$9,783.39",
          "BranchNumber": 9,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3931209,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Wayne State",
          "JobNumber": "502-1650066-2",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "421 E Canfield ST",
          "JobAddress2": "",
          "JobFullAddress": "421 E Canfield ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "PCC INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "PCC INTERIORS"
        },
        {
          "WorkOrder": 3931208,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Ferndalehaus Phase 2",
          "JobNumber": "502-1639418-22",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "430 W. Nine Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "430 W. Nine Mile Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PATRIOT TEAM CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$10,160.31",
          "BranchNumber": 8,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "PATRIOT TEAM CONSTRUCTION"
        },
        {
          "WorkOrder": 3931207,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Sycamore Glen",
          "JobNumber": "502-1621700-4",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23241 Yarrow Ave",
          "JobAddress2": "",
          "JobFullAddress": "23241 Yarrow Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHINGLETON CONTRACTORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,279.87",
          "BranchNumber": 8,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "SHINGLETON CONTRACTORS"
        },
        {
          "WorkOrder": 3931205,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Willard Bus Garage",
          "JobNumber": "502-1304186-43",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "515 E  Tiffin St",
          "JobAddress2": "",
          "JobFullAddress": "515 E  Tiffin St",
          "JobCounty": "HURON",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,265.86",
          "BranchNumber": 13,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3931204,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Community Action Com",
          "JobNumber": "502-1304186-40",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1637 Tiffin Ave",
          "JobAddress2": "",
          "JobFullAddress": "1637 Tiffin Ave",
          "JobCounty": "Hancock",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,746.52",
          "BranchNumber": 13,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3931203,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Classic Turning",
          "JobNumber": "502-967075-112",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3000 E South Street",
          "JobAddress2": "",
          "JobFullAddress": "3000 E South Street",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$7,524.46",
          "BranchNumber": 7,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 3931202,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NANKIN MILLS",
          "JobNumber": "502-949575-99",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33175 ANN ARBOR TRAIL",
          "JobAddress2": "",
          "JobFullAddress": "33175 ANN ARBOR TRAIL",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$56.18",
          "BranchNumber": 9,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3931200,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NBS",
          "JobNumber": "502-944100-314",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2595 Bellingham Dr",
          "JobAddress2": "",
          "JobFullAddress": "2595 Bellingham Dr",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,073.05",
          "BranchNumber": 9,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3931199,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9051 LTU Cent.Acc",
          "JobNumber": "502-920940-58",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21411 Civic Center Drive",
          "JobAddress2": "",
          "JobFullAddress": "21411 Civic Center Drive",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$929.16",
          "BranchNumber": 8,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3931198,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Walgreens",
          "JobNumber": "502-916315-116",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32395 Northwestern Hwy",
          "JobAddress2": "",
          "JobFullAddress": "32395 Northwestern Hwy",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$3,161.71",
          "BranchNumber": 9,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3931197,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1544 MEDICAL CENTER",
          "JobNumber": "502-913820-506",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "210 NORTH LAFAYETTE ST",
          "JobAddress2": "",
          "JobFullAddress": "210 NORTH LAFAYETTE ST",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$745.11",
          "BranchNumber": 9,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3931196,
          "ParentWorkOrder": "",
          "JobName": "(NOF)City Hall-New Bldg",
          "JobNumber": "502-858231-43",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1355 Delaware Ave",
          "JobAddress2": "",
          "JobFullAddress": "1355 Delaware Ave",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,549.09",
          "BranchNumber": 11,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 3931195,
          "ParentWorkOrder": "",
          "JobName": "(NOF) White Castle LP",
          "JobNumber": "502-856071-82",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2115 Fort St",
          "JobAddress2": "",
          "JobFullAddress": "2115 Fort St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$811.06",
          "BranchNumber": 8,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "M & V CONSTRUCTION"
        },
        {
          "WorkOrder": 3931194,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Health Grades #4140",
          "JobNumber": "502-817800-39",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "303 E Third Street",
          "JobAddress2": "Suite 210",
          "JobFullAddress": "303 E Third Street Suite 210",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$1,410.16",
          "BranchNumber": 8,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3931193,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TRU HOTEL",
          "JobNumber": "502-812692-13",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1687 N DIXIE HWY",
          "JobAddress2": "",
          "JobFullAddress": "1687 N DIXIE HWY",
          "JobCounty": "MONROE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Starbest Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$6,141.40",
          "BranchNumber": 8,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "Starbest Construction"
        },
        {
          "WorkOrder": 3931192,
          "ParentWorkOrder": "",
          "JobName": "(NOF)190275 Bald Eagle",
          "JobNumber": "502-812350-83",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6480 Highland Road",
          "JobAddress2": "",
          "JobFullAddress": "6480 Highland Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$7,325.61",
          "BranchNumber": 8,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 3931191,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MEIJER 88 DC OFFICE",
          "JobNumber": "502-52900-34",
          "FirstdayontheJob": "05-01-2019",
          "LastdayontheJob": "05-01-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8857 SWAN CREEK RD",
          "JobAddress2": "",
          "JobFullAddress": "8857 SWAN CREEK RD",
          "JobCounty": "MONROE",
          "JobState": "Michigan",
          "CustomerCompanyName": "MID MICHIGAN CEILING &",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$5,243.85",
          "BranchNumber": 5,
          "SubmitDate": "05-02-2019",
          "ClientonJob": "MID MICHIGAN CEILING &"
        },
        {
          "WorkOrder": 3930751,
          "ParentWorkOrder": 3925274,
          "JobName": "(NOF)GSA NLRB OFFICE RENO JOB# 47PF0018C0135",
          "JobNumber": "502-939250-48",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "477 MICHIGAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "477 MICHIGAN AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.C.P.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$6,540.86",
          "BranchNumber": 9,
          "SubmitDate": "04-30-2019",
          "ClientonJob": "D.C.P."
        },
        {
          "WorkOrder": 3930740,
          "ParentWorkOrder": 3923311,
          "JobName": "(NOF) 1542 PHARMACY USP800 RENOVATION",
          "JobNumber": "502-913820-505",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "36475 Five Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "36475 Five Mile Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$360.28",
          "BranchNumber": 9,
          "SubmitDate": "04-30-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3930739,
          "ParentWorkOrder": 3925264,
          "JobName": "(NOF)Free Star Financial",
          "JobNumber": "502-817800-37",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "56350 Van Dyke Ave.",
          "JobAddress2": "",
          "JobFullAddress": "56350 Van Dyke Ave.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$10,193.34",
          "BranchNumber": 8,
          "SubmitDate": "04-30-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3930415,
          "ParentWorkOrder": 3923320,
          "JobName": "(NOF)RUMSEY BUILDING/WATER RESOURCE RECOVERY",
          "JobNumber": "502-1344933-5",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1300 MARKET AVE SW",
          "JobAddress2": "",
          "JobFullAddress": "1300 MARKET AVE SW",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER 1 INTERIORS LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$6,945.26",
          "BranchNumber": 5,
          "SubmitDate": "04-29-2019",
          "ClientonJob": "PREMIER 1 INTERIORS LLC"
        },
        {
          "WorkOrder": 3930413,
          "ParentWorkOrder": 3921004,
          "JobName": "(NOF)Hampton Manor of Dun",
          "JobNumber": "502-1609034-36",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "123 Waterstradt Commerce Dr",
          "JobAddress2": "",
          "JobFullAddress": "123 Waterstradt Commerce Dr",
          "JobCounty": "Monroe",
          "JobState": "Michigan",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$125,000.00",
          "BalanceRemaining": "$1,243.75",
          "BranchNumber": 13,
          "SubmitDate": "04-29-2019",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 3930411,
          "ParentWorkOrder": 3920380,
          "JobName": "(NOF)REGENCY AT WESTLAND",
          "JobNumber": "502-999108-11",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2209 NORTH NEWBURGH RD",
          "JobAddress2": "",
          "JobFullAddress": "2209 NORTH NEWBURGH RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Resurrection Carpentry",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$208.39",
          "BranchNumber": 9,
          "SubmitDate": "04-29-2019",
          "ClientonJob": "Resurrection Carpentry"
        },
        {
          "WorkOrder": 3930181,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ALEXANDRINE HOUSE",
          "JobNumber": "502-1677740-19",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22600 Alexandrine St",
          "JobAddress2": "",
          "JobFullAddress": "22600 Alexandrine St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,235.49",
          "BranchNumber": 9,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3930180,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Windmill Plaza",
          "JobNumber": "502-1623435-7",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2051 18 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "2051 18 Mile Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "HANKINS PLASTERING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "HANKINS PLASTERING"
        },
        {
          "WorkOrder": 3930179,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LAKE TRUST",
          "JobNumber": "502-1507798-19",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "110 W. ANN ARBOR RD / 190 W ANN ARBOR RD",
          "JobAddress2": "",
          "JobFullAddress": "110 W. ANN ARBOR RD / 190 W ANN ARBOR RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,322.45",
          "BranchNumber": 9,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3930178,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Ranch House",
          "JobNumber": "502-1445958-15",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5465 Waterville Monclova Rd",
          "JobAddress2": "Lockbox #1957",
          "JobFullAddress": "5465 Waterville Monclova Rd Lockbox #1957",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "PREMIER BUILDERS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$8,230.43",
          "BranchNumber": 13,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "PREMIER BUILDERS"
        },
        {
          "WorkOrder": 3930177,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GRAND VILLAGE PH3",
          "JobNumber": "502-1395174-41",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5050 PIERCE ST",
          "JobAddress2": "",
          "JobFullAddress": "5050 PIERCE ST",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$763.20",
          "BranchNumber": 5,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3930176,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ALDI #43",
          "JobNumber": "502-1395174-40",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1323 W M 89 HWY",
          "JobAddress2": "",
          "JobFullAddress": "1323 W M 89 HWY",
          "JobCounty": "ALLEGAN",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$12,872.88",
          "BranchNumber": 5,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3930175,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Promedica Hickman Job#186220",
          "JobNumber": "502-1301520-5",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5640 N. Adrian Highway",
          "JobAddress2": "",
          "JobFullAddress": "5640 N. Adrian Highway",
          "JobCounty": "LENAWEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "A.A. BOOS & SONS INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "A.A. BOOS & SONS INC."
        },
        {
          "WorkOrder": 3930174,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ALVAREZ",
          "JobNumber": "502-952599-16",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3930173,
          "ParentWorkOrder": "",
          "JobName": "(NOF) JLA",
          "JobNumber": "502-952599-15",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3930172,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WALL ST JOURNAL B8",
          "JobNumber": "502-933550-157",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Detroit Metro Airport B8",
          "JobAddress2": "",
          "JobFullAddress": "1 Detroit Metro Airport B8",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$104.58",
          "BranchNumber": 9,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3930171,
          "ParentWorkOrder": "",
          "JobName": "(NOF)North Hill Elementar",
          "JobNumber": "502-920940-56",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1385 Mahaffy Ave",
          "JobAddress2": "",
          "JobFullAddress": "1385 Mahaffy Ave",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3930170,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LYON CENTER",
          "JobNumber": "502-919700-131",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30786 LYON CENTER DR E",
          "JobAddress2": "",
          "JobFullAddress": "30786 LYON CENTER DR E",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$22,182.56",
          "BranchNumber": 9,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 3930169,
          "ParentWorkOrder": "",
          "JobName": "(NOF)U-Sew Service Center",
          "JobNumber": "502-916315-115",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31196 Five Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "31196 Five Mile Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,737.36",
          "BranchNumber": 9,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3930168,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Amber Landing",
          "JobNumber": "502-849801-193",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4071 Crooks Rd",
          "JobAddress2": "",
          "JobFullAddress": "4071 Crooks Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$14,949.55",
          "BranchNumber": 11,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 3930167,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Windmill Plaza",
          "JobNumber": "502-825063-48",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2325 18 Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "2325 18 Mile Road",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHAFFER INCORPORATED",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$130,000.00",
          "BalanceRemaining": "$2,435.18",
          "BranchNumber": 8,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "SHAFFER INCORPORATED"
        },
        {
          "WorkOrder": 3930166,
          "ParentWorkOrder": "",
          "JobName": "(NOF)48th District Court",
          "JobNumber": "502-823400-49",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4280 Telegraph Rd",
          "JobAddress2": "",
          "JobFullAddress": "4280 Telegraph Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PARALLEL CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$18,006.00",
          "BalanceRemaining": "$335.42",
          "BranchNumber": 8,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "PARALLEL CONSTRUCTION"
        },
        {
          "WorkOrder": 3930165,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Autoliv",
          "JobNumber": "502-820500-33",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1320 Pacific Drive",
          "JobAddress2": "",
          "JobFullAddress": "1320 Pacific Drive",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEE CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$9,097.58",
          "BranchNumber": 8,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "LEE CONTRACTING"
        },
        {
          "WorkOrder": 3930164,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Planet Fitness Clint",
          "JobNumber": "502-816100-17",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33890 S Gratiot Ave",
          "JobAddress2": "",
          "JobFullAddress": "33890 S Gratiot Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "EDIFICE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,609.82",
          "BranchNumber": 8,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "EDIFICE CONSTRUCTION"
        },
        {
          "WorkOrder": 3930163,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9014 SJPHS-ICU Renovation",
          "JobNumber": "502-815250-333",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "16001 West Nine Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "16001 West Nine Mile Road",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,000.00",
          "BalanceRemaining": "$524.90",
          "BranchNumber": 8,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3930162,
          "ParentWorkOrder": "",
          "JobName": "(NOF)23494 Amber Ave",
          "JobNumber": "502-96980-5",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23494 Amber Ave",
          "JobAddress2": "",
          "JobFullAddress": "23494 Amber Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "M.O.R.R. CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$5,808.40",
          "BranchNumber": 8,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "M.O.R.R. CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 3930161,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GREENSTONE BANK",
          "JobNumber": "502-30978-10",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "700 W Sanilac Rd",
          "JobAddress2": "",
          "JobFullAddress": "700 W Sanilac Rd",
          "JobCounty": "SANILAC",
          "JobState": "Michigan",
          "CustomerCompanyName": "Kolar Brothers Constr.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$5,121.04",
          "BranchNumber": 1,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "Kolar Brothers Constr."
        },
        {
          "WorkOrder": 3930160,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ALMACHRYSLER",
          "JobNumber": "502-30930-4",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2280 WEST MONROE RD.",
          "JobAddress2": "",
          "JobFullAddress": "2280 WEST MONROE RD.",
          "JobCounty": "GRATIOT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JBS CONTRACTING, INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$18,913.17",
          "BranchNumber": 1,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "JBS CONTRACTING, INC"
        },
        {
          "WorkOrder": 3930159,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MERSENBAYCITY JOB#19-233",
          "JobNumber": "502-12150-10",
          "FirstdayontheJob": "04-26-2019",
          "LastdayontheJob": "04-26-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "900 HARRISON ST.",
          "JobAddress2": "",
          "JobFullAddress": "900 HARRISON ST.",
          "JobCounty": "BAY",
          "JobState": "Michigan",
          "CustomerCompanyName": "SERENUS JOHNSON",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,506.00",
          "BalanceRemaining": "$2,909.95",
          "BranchNumber": 1,
          "SubmitDate": "04-27-2019",
          "ClientonJob": "SERENUS JOHNSON"
        },
        {
          "WorkOrder": 3928384,
          "ParentWorkOrder": 3920374,
          "JobName": "(NOF)Ashley Capital",
          "JobNumber": "502-916315-105",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20001 Brownstown Center Dr",
          "JobAddress2": "Building #10, Suite 1060",
          "JobFullAddress": "20001 Brownstown Center Dr Building #10, Suite 1060",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$947.82",
          "BranchNumber": 9,
          "SubmitDate": "04-22-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3928375,
          "ParentWorkOrder": 3923302,
          "JobName": "(NOF)(258)Garfield Elem.",
          "JobNumber": "502-816350-136",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1221 GARFIELD ST",
          "JobAddress2": "",
          "JobFullAddress": "1221 GARFIELD ST",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$432.52",
          "BranchNumber": 8,
          "SubmitDate": "04-22-2019",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 3928164,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Carlisle Commons",
          "JobNumber": "502-1621700-3",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8739 Central Ave",
          "JobAddress2": "",
          "JobFullAddress": "8739 Central Ave",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "SHINGLETON CONTRACTORS INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "SHINGLETON CONTRACTORS INC"
        },
        {
          "WorkOrder": 3928163,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Worthington Crossing",
          "JobNumber": "502-1621700-2",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5700 Highland Rd",
          "JobAddress2": "",
          "JobFullAddress": "5700 Highland Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHINGLETON CONTRACTORS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$3,660.02",
          "BranchNumber": 8,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "SHINGLETON CONTRACTORS INC"
        },
        {
          "WorkOrder": 3928162,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Elite Builders",
          "JobNumber": "502-1551310-3",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15201 Kercheval Ave",
          "JobAddress2": "",
          "JobFullAddress": "15201 Kercheval Ave",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELITE BUILDERS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,827.90",
          "BranchNumber": 11,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "ELITE BUILDERS LLC"
        },
        {
          "WorkOrder": 3928161,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OSRAM CONTINENTAL",
          "JobNumber": "502-1507798-18",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1960 RESEARCH DR",
          "JobAddress2": "",
          "JobFullAddress": "1960 RESEARCH DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3928160,
          "ParentWorkOrder": "",
          "JobName": "(NOF)NOMS Podiatry",
          "JobNumber": "502-1304170-17",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2500 West Strub Rd.",
          "JobAddress2": "Suite 100",
          "JobFullAddress": "2500 West Strub Rd. Suite 100",
          "JobCounty": "Erie",
          "JobState": "Ohio",
          "CustomerCompanyName": "IMMACULATE INTERIORS",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "IMMACULATE INTERIORS"
        },
        {
          "WorkOrder": 3928159,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Holiday Inn Southfie",
          "JobNumber": "502-990401-45",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "25100 Northwestern Hwy",
          "JobAddress2": "",
          "JobFullAddress": "25100 Northwestern Hwy",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$315.60",
          "BranchNumber": 7,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3928158,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Belle Tire Carmal",
          "JobNumber": "502-967075-111",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9704 N. Michigan Rd.",
          "JobAddress2": "",
          "JobFullAddress": "9704 N. Michigan Rd.",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$5,511.42",
          "BranchNumber": 7,
          "SubmitDate": "",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 3928157,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Samsung SDI",
          "JobNumber": "502-944100-312",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50 Continental Drive",
          "JobAddress2": "",
          "JobFullAddress": "50 Continental Drive",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,272.61",
          "BranchNumber": 9,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3928156,
          "ParentWorkOrder": "",
          "JobName": "(NOF)KRESGE",
          "JobNumber": "502-937250-153",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3939 WOODWARD AVE",
          "JobAddress2": "",
          "JobFullAddress": "3939 WOODWARD AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINKER TEAM CONSTRUCTION, CO.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$4,473.17",
          "BranchNumber": 9,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "BRINKER TEAM CONSTRUCTION, CO."
        },
        {
          "WorkOrder": 3928155,
          "ParentWorkOrder": "",
          "JobName": "(NOF)VILLAGE",
          "JobNumber": "502-936150-53",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22371 W.VILLAGE RD",
          "JobAddress2": "",
          "JobFullAddress": "22371 W.VILLAGE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ABSTRACT CEILINGS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$5,657.03",
          "BranchNumber": 9,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "ABSTRACT CEILINGS, INC."
        },
        {
          "WorkOrder": 3928154,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TIMES NEWSTAND A45",
          "JobNumber": "502-933550-154",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 METRO DETROIT AIRPORT,",
          "JobAddress2": "MCNAMARA TERMINAL",
          "JobFullAddress": "1 METRO DETROIT AIRPORT, MCNAMARA TERMINAL",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$490.50",
          "BranchNumber": 9,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3928153,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DETROIT! A29  19019",
          "JobNumber": "502-933550-153",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 DETROIT METRO AIRPORT,",
          "JobAddress2": "MCNAMARA TERMINAL",
          "JobFullAddress": "1 DETROIT METRO AIRPORT, MCNAMARA TERMINAL",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$803.59",
          "BranchNumber": 9,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3928152,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ADRIAN CIPLEU",
          "JobNumber": "502-919875-2",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8573 SLEEPY HOLLOW DR",
          "JobAddress2": "",
          "JobFullAddress": "8573 SLEEPY HOLLOW DR",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "New Tech Drywall Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,506.00",
          "BalanceRemaining": "$8,367.48",
          "BranchNumber": 9,
          "SubmitDate": "05-07-2019",
          "ClientonJob": "New Tech Drywall Inc"
        },
        {
          "WorkOrder": 3928151,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Smoothie King",
          "JobNumber": "502-916315-112",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50918 Gratiot Ave",
          "JobAddress2": "",
          "JobFullAddress": "50918 Gratiot Ave",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,000.00",
          "BalanceRemaining": "$2,205.65",
          "BranchNumber": 9,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3928150,
          "ParentWorkOrder": "",
          "JobName": "(NOF)600 ADAM BUILDING",
          "JobNumber": "502-910868-56",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "600 S ADAMS RD",
          "JobAddress2": "",
          "JobFullAddress": "600 S ADAMS RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construct",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$7,843.02",
          "BranchNumber": 9,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "American Family Construct"
        },
        {
          "WorkOrder": 3928149,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hampton Inn&Suites",
          "JobNumber": "502-877300-26",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1335 South Main Street",
          "JobAddress2": "",
          "JobFullAddress": "1335 South Main Street",
          "JobCounty": "LENAWEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SUPERIOR DESIGNS INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$12,469.60",
          "BranchNumber": 8,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "SUPERIOR DESIGNS INC."
        },
        {
          "WorkOrder": 3928148,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GS3 Growing Smiles",
          "JobNumber": "502-823300-71",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22524 Greater Mack Ave",
          "JobAddress2": "",
          "JobFullAddress": "22524 Greater Mack Ave",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,243.47",
          "BranchNumber": 8,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 3928147,
          "ParentWorkOrder": "",
          "JobName": "(NOF)H.I.E. CHESTERFIELD",
          "JobNumber": "502-812692-11",
          "FirstdayontheJob": "04-18-2019",
          "LastdayontheJob": "04-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45805 MARKETPLACE BLVD",
          "JobAddress2": "",
          "JobFullAddress": "45805 MARKETPLACE BLVD",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Starbest Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$8,350.02",
          "BranchNumber": 8,
          "SubmitDate": "04-19-2019",
          "ClientonJob": "Starbest Construction"
        },
        {
          "WorkOrder": 3927915,
          "ParentWorkOrder": 3923307,
          "JobName": "(NOF)PHASD BP11 FG School",
          "JobNumber": "502-861787-35",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3985 Keewahdin Rd",
          "JobAddress2": "",
          "JobFullAddress": "3985 Keewahdin Rd",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "04-18-2019",
          "ClientonJob": "JFS Company"
        },
        {
          "WorkOrder": 3927910,
          "ParentWorkOrder": 3923308,
          "JobName": "(NOF)PHASD BP11 Roosevelt",
          "JobNumber": "502-861787-36",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1112 20th Street",
          "JobAddress2": "",
          "JobFullAddress": "1112 20th Street",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "04-18-2019",
          "ClientonJob": "JFS Company"
        },
        {
          "WorkOrder": 3927354,
          "ParentWorkOrder": 3923303,
          "JobName": "(NOF)Local Credit Union",
          "JobNumber": "502-817800-36",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "39139 Mound Road",
          "JobAddress2": "",
          "JobFullAddress": "39139 Mound Road",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$4,454.40",
          "BranchNumber": 8,
          "SubmitDate": "04-16-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3926634,
          "ParentWorkOrder": "",
          "JobName": "(NOF)115 Spring St.",
          "JobNumber": "502-1650969-40",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "115 E Spring St.",
          "JobAddress2": "",
          "JobFullAddress": "115 E Spring St.",
          "JobCounty": "HURON",
          "JobState": "Michigan",
          "CustomerCompanyName": "NATE'S DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$7,904.62",
          "BranchNumber": 1,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "NATE'S DRYWALL"
        },
        {
          "WorkOrder": 3926633,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Kripke",
          "JobNumber": "502-1609034-37",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8201 W. Central Ave.",
          "JobAddress2": "",
          "JobFullAddress": "8201 W. Central Ave.",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$4,296.69",
          "BranchNumber": 13,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 3926632,
          "ParentWorkOrder": "",
          "JobName": "(NOF) RANCH HOUSE",
          "JobNumber": "502-1604855-4",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22077 INKSTER RD",
          "JobAddress2": "",
          "JobFullAddress": "22077 INKSTER RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "R&V DRYWALL INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,496.55",
          "BranchNumber": 9,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "R&V DRYWALL INC"
        },
        {
          "WorkOrder": 3926631,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CHEMICAL BANK",
          "JobNumber": "502-1395174-39",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "155 W MICHIGAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "155 W MICHIGAN AVE",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$27,465.12",
          "BranchNumber": 5,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3926630,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Findlay Home",
          "JobNumber": "502-1304186-41",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7527 Patriot Dr.",
          "JobAddress2": "",
          "JobFullAddress": "7527 Patriot Dr.",
          "JobCounty": "HANCOCK",
          "JobState": "Ohio",
          "CustomerCompanyName": "Interior Wall & Ceiling",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,603.51",
          "BranchNumber": 13,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "Interior Wall & Ceiling"
        },
        {
          "WorkOrder": 3926629,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ALTAR'D STATE BM",
          "JobNumber": "502-1060142-7",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3195 28TH ST SE",
          "JobAddress2": "SUITE E108B",
          "JobFullAddress": "3195 28TH ST SE SUITE E108B",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$13,278.20",
          "BranchNumber": 5,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 3926628,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GVSU 609 WATSON",
          "JobNumber": "502-1060142-5",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "609 WATSON ST SW",
          "JobAddress2": "",
          "JobFullAddress": "609 WATSON ST SW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,233.71",
          "BranchNumber": 5,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 3926627,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 6TH PRECINCT",
          "JobNumber": "502-949575-103",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11450 WARWICK ST",
          "JobAddress2": "",
          "JobFullAddress": "11450 WARWICK ST",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$6,310.75",
          "BranchNumber": 9,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3926626,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BRANDON HIGH SCHOOL",
          "JobNumber": "502-949575-102",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1025 S Ortonville Rd",
          "JobAddress2": "",
          "JobFullAddress": "1025 S Ortonville Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$105,000.00",
          "BalanceRemaining": "$13,917.54",
          "BranchNumber": 9,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3926625,
          "ParentWorkOrder": "",
          "JobName": "(NOF)University Hills",
          "JobNumber": "502-920940-54",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "600 Croydon Road,",
          "JobAddress2": "Elementary 19-5077",
          "JobFullAddress": "600 Croydon Road, Elementary 19-5077",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$80,006.00",
          "BalanceRemaining": "$1,135.47",
          "BranchNumber": 8,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3926624,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Guess",
          "JobNumber": "502-916315-114",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12240 S Beyer Rd",
          "JobAddress2": "",
          "JobFullAddress": "12240 S Beyer Rd",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,934.31",
          "BranchNumber": 9,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3926623,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Sharp Funeral Home",
          "JobNumber": "502-916315-113",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8138 Miller Road",
          "JobAddress2": "",
          "JobFullAddress": "8138 Miller Road",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$824.09",
          "BranchNumber": 9,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3926622,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1541 GAMMA KNIFE",
          "JobNumber": "502-913820-502",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3601 W 13 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "3601 W 13 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$43.19",
          "BranchNumber": 9,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3926621,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HEDKE ELEMENTARY",
          "JobNumber": "502-910050-52",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3201 MARIAN HEDKE",
          "JobAddress2": "",
          "JobFullAddress": "3201 MARIAN HEDKE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$254.29",
          "BranchNumber": 9,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 3926620,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Staybridge Suites",
          "JobNumber": "502-858231-42",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8333 15 Mie Rd",
          "JobAddress2": "",
          "JobFullAddress": "8333 15 Mie Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$10,969.28",
          "BranchNumber": 11,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 3926619,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HFHS REdico",
          "JobNumber": "502-815250-337",
          "FirstdayontheJob": "04-11-2019",
          "LastdayontheJob": "04-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1961 S Telegraph Rd",
          "JobAddress2": "",
          "JobFullAddress": "1961 S Telegraph Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$250,006.00",
          "BalanceRemaining": "$526.29",
          "BranchNumber": 8,
          "SubmitDate": "04-12-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3925333,
          "ParentWorkOrder": 3916587,
          "JobName": "(NOF)VIBE SALON PLYM SHIP",
          "JobNumber": "502-1507798-15",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "110 ANN ARBOR RD W",
          "JobAddress2": "",
          "JobFullAddress": "110 ANN ARBOR RD W",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,574.20",
          "BranchNumber": 9,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3925285,
          "ParentWorkOrder": "",
          "JobName": "(NOF) TURTLE CREEK",
          "JobNumber": "502-1652453-2",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7741 M72 E",
          "JobAddress2": "",
          "JobFullAddress": "7741 M72 E",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "KINGLSEY LUMBER & HARDWARE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "",
          "ClientonJob": "KINGLSEY LUMBER & HARDWARE"
        },
        {
          "WorkOrder": 3925284,
          "ParentWorkOrder": "",
          "JobName": "(NOF)EWC",
          "JobNumber": "502-1605646-3",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3579 28TH ST SE",
          "JobAddress2": "",
          "JobFullAddress": "3579 28TH ST SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFB CONSTRUCTION & DEVELOPMENT INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,298.04",
          "BranchNumber": 8,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "JFB CONSTRUCTION & DEVELOPMENT INC"
        },
        {
          "WorkOrder": 3925283,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TRACTOR SUPPLY",
          "JobNumber": "502-1507798-17",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "44005 MICHIGAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "44005 MICHIGAN AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$1,108.46",
          "BranchNumber": 9,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3925282,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CENTER FOR AUTOMOTIV",
          "JobNumber": "502-1507798-16",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "880 TECHNOLOGY DR",
          "JobAddress2": "",
          "JobFullAddress": "880 TECHNOLOGY DR",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$2,580.05",
          "BranchNumber": 9,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3925281,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCKAY TOWER",
          "JobNumber": "502-1395174-37",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "146 MONROE CENTER ST NW",
          "JobAddress2": "",
          "JobFullAddress": "146 MONROE CENTER ST NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$478.80",
          "BranchNumber": 5,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3925280,
          "ParentWorkOrder": "",
          "JobName": "(NOF) IDEXX LABRATORIES",
          "JobNumber": "502-1060142-6",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2112 E PARIS AVE SE",
          "JobAddress2": "",
          "JobFullAddress": "2112 E PARIS AVE SE",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEVEL 5 COMMERCIAL FINISH",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$2,183.57",
          "BranchNumber": 5,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "LEVEL 5 COMMERCIAL FINISH"
        },
        {
          "WorkOrder": 3925279,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HIE & SUITES",
          "JobNumber": "502-1014005-3",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5880 CLYDE PARK AVE SE",
          "JobAddress2": "/5830 CLYDE PARK AVE SE",
          "JobFullAddress": "5880 CLYDE PARK AVE SE /5830 CLYDE PARK AVE SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "RUDYS PLASTERING AND DRYWALL FINISH",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$16,358.45",
          "BranchNumber": 5,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "RUDYS PLASTERING AND DRYWALL FINISH"
        },
        {
          "WorkOrder": 3925278,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LK Dianne",
          "JobNumber": "502-990401-44",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14124 Diane Dr E",
          "JobAddress2": "",
          "JobFullAddress": "14124 Diane Dr E",
          "JobCounty": "HILLSDALE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3925277,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Automated Logistics Job #19.0177.04",
          "JobNumber": "502-971850-31",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3517 Scheele Dr.",
          "JobAddress2": "",
          "JobFullAddress": "3517 Scheele Dr.",
          "JobCounty": "JACKSON",
          "JobState": "Michigan",
          "CustomerCompanyName": "R.W. MERCER CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "R.W. MERCER CO"
        },
        {
          "WorkOrder": 3925276,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HUNTINGTON WOODS",
          "JobNumber": "502-950450-17",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26635 WOODWARD AVE",
          "JobAddress2": "",
          "JobFullAddress": "26635 WOODWARD AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "William West Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,366.65",
          "BranchNumber": 9,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "William West Inc."
        },
        {
          "WorkOrder": 3925275,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDONALD'S # 488",
          "JobNumber": "502-944100-306",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31255 HARPER AVE",
          "JobAddress2": "",
          "JobFullAddress": "31255 HARPER AVE",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3925274,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GSA NLRB OFFICE RENO JOB# 47PF0018C0135",
          "JobNumber": "502-939250-48",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "477 MICHIGAN AVE",
          "JobAddress2": "",
          "JobFullAddress": "477 MICHIGAN AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.C.P.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$6,540.86",
          "BranchNumber": 9,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "D.C.P."
        },
        {
          "WorkOrder": 3925273,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HFHS CTR FOR ATH MED",
          "JobNumber": "502-937250-148",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "690 AMSTERDAM ST",
          "JobAddress2": "#18-127",
          "JobFullAddress": "690 AMSTERDAM ST #18-127",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINKER TEAM CONSTRUCTION, CO.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$200,006.00",
          "BalanceRemaining": "$12,340.14",
          "BranchNumber": 9,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "BRINKER TEAM CONSTRUCTION, CO."
        },
        {
          "WorkOrder": 3925272,
          "ParentWorkOrder": "",
          "JobName": "(NOF) ATWATER BREWERY JOB # 19007",
          "JobNumber": "502-933550-159",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 DETROIT METRO AIRPORT, NORTH TERMINAL,",
          "JobAddress2": "",
          "JobFullAddress": "1 DETROIT METRO AIRPORT, NORTH TERMINAL,",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,079.71",
          "BranchNumber": 9,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3925271,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Z MARKET B4   19023",
          "JobNumber": "502-933550-156",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 DETROIT METRO AIRPORT, MCNAMARA TERMINAL",
          "JobAddress2": "",
          "JobFullAddress": "1 DETROIT METRO AIRPORT, MCNAMARA TERMINAL",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$685.77",
          "BranchNumber": 9,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3925270,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WALL STREET JOUR A20 - JOB 19017",
          "JobNumber": "502-933550-151",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "MCNAMARA TERMINAL,",
          "JobAddress2": "1 DETROIT METRO AIRPORT",
          "JobFullAddress": "MCNAMARA TERMINAL, 1 DETROIT METRO AIRPORT",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$473.57",
          "BranchNumber": 9,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3925269,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Garfield Elementary",
          "JobNumber": "502-920940-55",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1221 Garfield St",
          "JobAddress2": "",
          "JobFullAddress": "1221 Garfield St",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$1,167.43",
          "BranchNumber": 8,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3925268,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 1532 SHAKE SHACK",
          "JobNumber": "502-913820-493",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3030 WASHTENAW AVE",
          "JobAddress2": "",
          "JobFullAddress": "3030 WASHTENAW AVE",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$11,006.00",
          "BalanceRemaining": "$2,533.29",
          "BranchNumber": 9,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3925267,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BINGHAM MEDICAL",
          "JobNumber": "502-911550-6",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "13320 W WARREN AVE",
          "JobAddress2": "",
          "JobFullAddress": "13320 W WARREN AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BEST FINISHERS INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$13,006.00",
          "BalanceRemaining": "$1,283.56",
          "BranchNumber": 9,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "BEST FINISHERS INC."
        },
        {
          "WorkOrder": 3925266,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NORTHVILLE HIGH",
          "JobNumber": "502-910050-53",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45700 6 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "45700 6 MILE RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,598.65",
          "BranchNumber": 9,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 3925265,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GLEANERS FOOD BANK",
          "JobNumber": "502-825063-49",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5924 STERLING DR",
          "JobAddress2": "",
          "JobFullAddress": "5924 STERLING DR",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHAFFER INCORPORATED",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$2,320.14",
          "BranchNumber": 8,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "SHAFFER INCORPORATED"
        },
        {
          "WorkOrder": 3925264,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Free Star Financial",
          "JobNumber": "502-817800-37",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "56350 Van Dyke Ave.",
          "JobAddress2": "",
          "JobFullAddress": "56350 Van Dyke Ave.",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$10,193.34",
          "BranchNumber": 8,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3925263,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Domino's O&R 3041",
          "JobNumber": "502-813200-117",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30 Frank Llyod Wright Dr",
          "JobAddress2": "",
          "JobFullAddress": "30 Frank Llyod Wright Dr",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$175,000.00",
          "BalanceRemaining": "$22,550.70",
          "BranchNumber": 8,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3925262,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Royal Oak High School",
          "JobNumber": "502-813200-115",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1500 Lexington Blvd.",
          "JobAddress2": "",
          "JobFullAddress": "1500 Lexington Blvd.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$1,515.80",
          "BranchNumber": 8,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3925261,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Trinity Health",
          "JobNumber": "502-812250-21",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34375 W 12 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "34375 W 12 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENNIS BAULT CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$23,376.66",
          "BranchNumber": 8,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "DENNIS BAULT CONSTRUCTION"
        },
        {
          "WorkOrder": 3925260,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Xceleration Fitness",
          "JobNumber": "502-811800-61",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2675 Lapeer Rd",
          "JobAddress2": "",
          "JobFullAddress": "2675 Lapeer Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "BHD BUILDERS, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "BHD BUILDERS, INC"
        },
        {
          "WorkOrder": 3925259,
          "ParentWorkOrder": "",
          "JobName": "(NOF)41455 Production Dr",
          "JobNumber": "502-96980-4",
          "FirstdayontheJob": "04-07-2019",
          "LastdayontheJob": "04-07-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "41455 Production Dr",
          "JobAddress2": "",
          "JobFullAddress": "41455 Production Dr",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "M.O.R.R. CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$7,648.25",
          "BranchNumber": 8,
          "SubmitDate": "04-08-2019",
          "ClientonJob": "M.O.R.R. CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 3925202,
          "ParentWorkOrder": 3923562,
          "JobName": "(NOF)AUDIO ADVISOR",
          "JobNumber": "502-53500-31",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5500 EXECUTIVE PKWY SE",
          "JobAddress2": "",
          "JobFullAddress": "5500 EXECUTIVE PKWY SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "PYRAMID ACOUSTICS, INC.",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$4,001.31",
          "BranchNumber": 5,
          "SubmitDate": "04-05-2019",
          "ClientonJob": "PYRAMID ACOUSTICS, INC."
        },
        {
          "WorkOrder": 3925191,
          "ParentWorkOrder": 3918705,
          "JobName": "(NOF) NOVI DPS",
          "JobNumber": "502-944100-302",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26300 LEE BEGOLE DR.",
          "JobAddress2": "",
          "JobFullAddress": "26300 LEE BEGOLE DR.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,461.92",
          "BranchNumber": 9,
          "SubmitDate": "04-05-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3924619,
          "ParentWorkOrder": 3920366,
          "JobName": "(NOF)(255)Armada HS",
          "JobNumber": "502-816350-137",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23655 Armada Center Rd",
          "JobAddress2": "",
          "JobFullAddress": "23655 Armada Center Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$717.82",
          "BranchNumber": 8,
          "SubmitDate": "04-05-2019",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 3923562,
          "ParentWorkOrder": 3916563,
          "JobName": "(NOF)AUDIO ADVISOR",
          "JobNumber": "502-53500-31",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5500 EXECUTIVE PKWY SE",
          "JobAddress2": "",
          "JobFullAddress": "5500 EXECUTIVE PKWY SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "PYRAMID ACOUSTICS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$4,001.31",
          "BranchNumber": 5,
          "SubmitDate": "04-02-2019",
          "ClientonJob": "PYRAMID ACOUSTICS, INC."
        },
        {
          "WorkOrder": 3923323,
          "ParentWorkOrder": "",
          "JobName": "(NOF) VILLAGE DR HOUSE",
          "JobNumber": "502-1677740-17",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22371 W. VILLAGE DR",
          "JobAddress2": "",
          "JobFullAddress": "22371 W. VILLAGE DR",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$8,485.66",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3923322,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SCHUMACHER QUARRY",
          "JobNumber": "502-1626630-8",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8036 QUARRY ROAD",
          "JobAddress2": "",
          "JobFullAddress": "8036 QUARRY ROAD",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "MARKS PRECISION DRYWALL",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "MARKS PRECISION DRYWALL"
        },
        {
          "WorkOrder": 3923321,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CORNERSTONE CONST.",
          "JobNumber": "502-1604855-5",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "9870 MACEY RD",
          "JobAddress2": "",
          "JobFullAddress": "9870 MACEY RD",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "R&V DRYWALL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,346.62",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "R&V DRYWALL INC"
        },
        {
          "WorkOrder": 3923320,
          "ParentWorkOrder": "",
          "JobName": "(NOF)RUMSEY BUILDING/WATER RESOURCE RECOVERY",
          "JobNumber": "502-1344933-5",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1300 MARKET AVE SW",
          "JobAddress2": "",
          "JobFullAddress": "1300 MARKET AVE SW",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER 1 INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$6,945.26",
          "BranchNumber": 5,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "PREMIER 1 INTERIORS LLC"
        },
        {
          "WorkOrder": 3923319,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HILLEL DAY SCHOOL",
          "JobNumber": "502-967545-9",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32200 MIDDLEBELT RD",
          "JobAddress2": "",
          "JobFullAddress": "32200 MIDDLEBELT RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Carpentry Concepts & Ceiling",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$3,659.38",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "Carpentry Concepts & Ceiling"
        },
        {
          "WorkOrder": 3923318,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SANTCUARY AT SPRINGB",
          "JobNumber": "502-944100-310",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8627 CLEARCREEK FRANKLIN RD",
          "JobAddress2": "",
          "JobFullAddress": "8627 CLEARCREEK FRANKLIN RD",
          "JobCounty": "WARREN",
          "JobState": "Ohio",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$291.50",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3923317,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ZEAL CREDIT UNION",
          "JobNumber": "502-941950-8",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2640 E. JEFFERSON AVE",
          "JobAddress2": "",
          "JobFullAddress": "2640 E. JEFFERSON AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "GREAT LAKES CEILING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,720.53",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "GREAT LAKES CEILING"
        },
        {
          "WorkOrder": 3923316,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MHAI THAI",
          "JobNumber": "502-920300-81",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6603 N CANTON CENTER RD STE 600",
          "JobAddress2": "",
          "JobFullAddress": "6603 N CANTON CENTER RD STE 600",
          "JobCounty": "CANTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "George H. Pastor & Sons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$7,413.39",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "George H. Pastor & Sons"
        },
        {
          "WorkOrder": 3923315,
          "ParentWorkOrder": "",
          "JobName": "(NOF)THE JOURNEY CHURCH",
          "JobNumber": "502-920000-26",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11489 Rattalee Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "11489 Rattalee Lake Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NVR FINISHES, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$8,947.46",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "NVR FINISHES, INC."
        },
        {
          "WorkOrder": 3923314,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 2064 Yorkshore-HMOR",
          "JobNumber": "502-919700-130",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2064 Yorkshire Rd",
          "JobAddress2": "",
          "JobFullAddress": "2064 Yorkshire Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$7,701.66",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 3923313,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FEDERAL RESERVE BANK",
          "JobNumber": "502-916800-25",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1600 E WARREN AVE",
          "JobAddress2": "",
          "JobFullAddress": "1600 E WARREN AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "JASMAN CONSTRUCTION, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,838.41",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "JASMAN CONSTRUCTION, INC"
        },
        {
          "WorkOrder": 3923312,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Del Taco Allen Park",
          "JobNumber": "502-916315-111",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14570 Southfield Rd.",
          "JobAddress2": "",
          "JobFullAddress": "14570 Southfield Rd.",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,896.59",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3923311,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 1542 PHARMACY RENO",
          "JobNumber": "502-913820-505",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "36475 Five Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "36475 Five Mile Rd",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$45,006.00",
          "BalanceRemaining": "$360.28",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3923310,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Wendy Lokken",
          "JobNumber": "502-876650-280",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "523 Renshaw St",
          "JobAddress2": "",
          "JobFullAddress": "523 Renshaw St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3923309,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1-800 Self Storage",
          "JobNumber": "502-876650-279",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3846 Rochester Road",
          "JobAddress2": "",
          "JobFullAddress": "3846 Rochester Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$608.98",
          "BranchNumber": 8,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3923308,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PHASD BP11 Roosevelt",
          "JobNumber": "502-861787-36",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1112 20th Street",
          "JobAddress2": "",
          "JobFullAddress": "1112 20th Street",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "JFS Company"
        },
        {
          "WorkOrder": 3923307,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PHASD BP11 FG School",
          "JobNumber": "502-861787-35",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3985 Keewahdin Rd",
          "JobAddress2": "",
          "JobFullAddress": "3985 Keewahdin Rd",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFS Company",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 11,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "JFS Company"
        },
        {
          "WorkOrder": 3923306,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Holister GLC",
          "JobNumber": "502-856071-81",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4000 Baldwin Rd",
          "JobAddress2": "SPC 530",
          "JobFullAddress": "4000 Baldwin Rd SPC 530",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "M & V CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$13,738.81",
          "BranchNumber": 8,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "M & V CONSTRUCTION"
        },
        {
          "WorkOrder": 3923305,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Tim Rhatka",
          "JobNumber": "502-849801-182",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2703 Jewell Dr",
          "JobAddress2": "",
          "JobFullAddress": "2703 Jewell Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$2,991.45",
          "BranchNumber": 11,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 3923304,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Wohlfiehl",
          "JobNumber": "502-849801-180",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1420 Yosemite Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1420 Yosemite Blvd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$6,688.18",
          "BranchNumber": 11,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 3923303,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Local Credit Union",
          "JobNumber": "502-817800-36",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "39139 Mound Road",
          "JobAddress2": "",
          "JobFullAddress": "39139 Mound Road",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$4,454.40",
          "BranchNumber": 8,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3923302,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(258)Garfield Elem.",
          "JobNumber": "502-816350-136",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1221 GARFIELD ST",
          "JobAddress2": "",
          "JobFullAddress": "1221 GARFIELD ST",
          "JobCounty": "ST CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$432.52",
          "BranchNumber": 8,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 3923301,
          "ParentWorkOrder": "",
          "JobName": "(NOF) EDAG",
          "JobNumber": "502-815630-205",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1650 Research Drive",
          "JobAddress2": "3rd Floor",
          "JobFullAddress": "1650 Research Drive 3rd Floor",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,000.00",
          "BalanceRemaining": "$15,426.56",
          "BranchNumber": 8,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3923300,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Lake Trust Credit",
          "JobNumber": "502-815250-335",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "ONE ENERGY PLAZA",
          "JobAddress2": "/Union DTE 1909024",
          "JobFullAddress": "ONE ENERGY PLAZA /Union DTE 1909024",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,022.24",
          "BranchNumber": 8,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3923299,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HOLIDAY INN EXPRESS",
          "JobNumber": "502-812692-15",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1700 SHOOK DR",
          "JobAddress2": "",
          "JobFullAddress": "1700 SHOOK DR",
          "JobCounty": "",
          "JobState": "Indiana",
          "CustomerCompanyName": "Starbest Construction",
          "RequestType": "Sworn Statement and Notice of Intention to Hold Mechanic's Lien",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "On Hold",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$21,669.56",
          "BranchNumber": 8,
          "SubmitDate": "",
          "ClientonJob": "Starbest Construction"
        },
        {
          "WorkOrder": 3923298,
          "ParentWorkOrder": "",
          "JobName": "(NOF) PINETRACE",
          "JobNumber": "502-192258-10",
          "FirstdayontheJob": "03-31-2019",
          "LastdayontheJob": "03-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5860 Pine Trace Ct",
          "JobAddress2": "",
          "JobFullAddress": "5860 Pine Trace Ct",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "Next Generation Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "04-01-2019",
          "ClientonJob": "Next Generation Drywall"
        },
        {
          "WorkOrder": 3922229,
          "ParentWorkOrder": 3918710,
          "JobName": "(NOF) AW Primary",
          "JobNumber": "502-1304550-57",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6510 Texas Street",
          "JobAddress2": "Job# 25470",
          "JobFullAddress": "6510 Texas Street Job# 25470",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "LAKESIDE INTERIOR CONT",
          "RequestType": "Notice of Furnishing (Public Jobs)",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$659.40",
          "BranchNumber": 13,
          "SubmitDate": "03-29-2019",
          "ClientonJob": "LAKESIDE INTERIOR CONT"
        },
        {
          "WorkOrder": 3921517,
          "ParentWorkOrder": 3916564,
          "JobName": "(NOF)2997-12 McLaren/",
          "JobNumber": "502-813200-111",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50 N Perry St",
          "JobAddress2": "",
          "JobFullAddress": "50 N Perry St",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING CORP / CCC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-26-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING CORP / CCC"
        },
        {
          "WorkOrder": 3921445,
          "ParentWorkOrder": 3916572,
          "JobName": "(NOF)WARNER MIDDLE SCHOOL",
          "JobNumber": "502-910050-51",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30303 W. 14 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "30303 W. 14 MILE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$9,454.18",
          "BranchNumber": 9,
          "SubmitDate": "03-26-2019",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 3921341,
          "ParentWorkOrder": 3916588,
          "JobName": "(NOF)Verizon Wireless",
          "JobNumber": "502-1524600-2",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5775 State St.",
          "JobAddress2": "",
          "JobFullAddress": "5775 State St.",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Sterling Millwork Inc",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,000.00",
          "BalanceRemaining": "$50.88",
          "BranchNumber": 1,
          "SubmitDate": "03-25-2019",
          "ClientonJob": "Sterling Millwork Inc"
        },
        {
          "WorkOrder": 3921280,
          "ParentWorkOrder": 3916584,
          "JobName": "(NOF)OKEMOS MARRIOTT",
          "JobNumber": "502-990401-43",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "JOLLY OAK RD",
          "JobAddress2": "",
          "JobFullAddress": "JOLLY OAK RD",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$22,844.83",
          "BranchNumber": 7,
          "SubmitDate": "03-25-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3921016,
          "ParentWorkOrder": 3916574,
          "JobName": "(NOF)1531 BATH & BODY WKS",
          "JobNumber": "502-913820-492",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8570 26 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "8570 26 MILE RD",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$6,371.96",
          "BranchNumber": 9,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3921004,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hampton Manor of Dun",
          "JobNumber": "502-1609034-36",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "123 Waterstradt Commerce Dr",
          "JobAddress2": "",
          "JobFullAddress": "123 Waterstradt Commerce Dr",
          "JobCounty": "Monroe",
          "JobState": "Michigan",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$125,000.00",
          "BalanceRemaining": "$1,243.75",
          "BranchNumber": 13,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 3921003,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Frank Young Constr.",
          "JobNumber": "502-1509095-5",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5096 Lakeshore Rd",
          "JobAddress2": "",
          "JobFullAddress": "5096 Lakeshore Rd",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "KOREY EAGEN",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$6,361.55",
          "BranchNumber": 11,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "KOREY EAGEN"
        },
        {
          "WorkOrder": 3921002,
          "ParentWorkOrder": "",
          "JobName": "(NOF) RC-552/CAYMC",
          "JobNumber": "502-999108-12",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2 WOODWARD AVE",
          "JobAddress2": "",
          "JobFullAddress": "2 WOODWARD AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Resurrection Carpentry",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$2,500.00",
          "BalanceRemaining": "$518.11",
          "BranchNumber": 9,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "Resurrection Carpentry"
        },
        {
          "WorkOrder": 3921001,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HI WALKER",
          "JobNumber": "502-990401-42",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2280 NORTHRIDGE DR NW",
          "JobAddress2": "",
          "JobFullAddress": "2280 NORTHRIDGE DR NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$12,358.33",
          "BranchNumber": 7,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3921000,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SAINTS",
          "JobNumber": "502-969090-5",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2945 S WAYNE RD",
          "JobAddress2": "",
          "JobFullAddress": "2945 S WAYNE RD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Marra Building Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$2,760.74",
          "BranchNumber": 9,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "Marra Building Inc."
        },
        {
          "WorkOrder": 3920999,
          "ParentWorkOrder": "",
          "JobName": "(NOF) FRITA BATIDOS",
          "JobNumber": "502-949575-101",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2211 WOODWARD AVE",
          "JobAddress2": "STE C",
          "JobFullAddress": "2211 WOODWARD AVE STE C",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$885.46",
          "BranchNumber": 9,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3920998,
          "ParentWorkOrder": "",
          "JobName": "(NOF) RAMCO UM BEAUTY",
          "JobNumber": "502-943807-11",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33870 S. GRATIOT AVE",
          "JobAddress2": "",
          "JobFullAddress": "33870 S. GRATIOT AVE",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "JD Engle Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,000.00",
          "BalanceRemaining": "$900.65",
          "BranchNumber": 9,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "JD Engle Construction"
        },
        {
          "WorkOrder": 3920997,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ONE CAMPUS MARTIUS/19-208  ****METAL MBA****",
          "JobNumber": "502-937250-151",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 CAMPUS MARTIUS",
          "JobAddress2": "1000 WOODWARD AVE",
          "JobFullAddress": "1 CAMPUS MARTIUS 1000 WOODWARD AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINKER TEAM CONSTRUCTION, CO.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$400,000.00",
          "BalanceRemaining": "$16,406.24",
          "BranchNumber": 9,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "BRINKER TEAM CONSTRUCTION, CO."
        },
        {
          "WorkOrder": 3920996,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Frames Unlimited",
          "JobNumber": "502-916315-109",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31090 Five Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "31090 Five Mile Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3920995,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Five Below",
          "JobNumber": "502-916315-108",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18055 Silver Parkway",
          "JobAddress2": "",
          "JobFullAddress": "18055 Silver Parkway",
          "JobCounty": "GENESEE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$2,074.08",
          "BranchNumber": 9,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3920994,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MOD Pizza Gr Rapids",
          "JobNumber": "502-916315-107",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3300 NW Alpine Ave",
          "JobAddress2": "Suite C",
          "JobFullAddress": "3300 NW Alpine Ave Suite C",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$6,202.42",
          "BranchNumber": 9,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3920993,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1537 SURGICAL SCHED",
          "JobNumber": "502-913820-498",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "47601 GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "47601 GRAND RIVER AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$454.05",
          "BranchNumber": 9,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3920992,
          "ParentWorkOrder": "",
          "JobName": "(NOF)TILBURY HOUSE",
          "JobNumber": "502-910400-140",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8098 TILBURY CT",
          "JobAddress2": "",
          "JobFullAddress": "8098 TILBURY CT",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACTION DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$6,024.59",
          "BranchNumber": 9,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "ACTION DRYWALL"
        },
        {
          "WorkOrder": 3920991,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Village Market",
          "JobNumber": "502-876650-278",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "18310 Mack Ave",
          "JobAddress2": "",
          "JobFullAddress": "18310 Mack Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3920990,
          "ParentWorkOrder": "",
          "JobName": "(NOF) City Flats",
          "JobNumber": "502-857051-8",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "800 Military St",
          "JobAddress2": "",
          "JobFullAddress": "800 Military St",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "MARTIN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$873.76",
          "BranchNumber": 11,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "MARTIN CONSTRUCTION"
        },
        {
          "WorkOrder": 3920989,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Avon Gear",
          "JobNumber": "502-839040-140",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11968 Investment Drive",
          "JobAddress2": "",
          "JobFullAddress": "11968 Investment Drive",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "CHROME DOME CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$4,590.63",
          "BranchNumber": 11,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "CHROME DOME CONSTRUCTION"
        },
        {
          "WorkOrder": 3920988,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9018 DTE 6th Floor/P-199018TM4",
          "JobNumber": "502-815250-332",
          "FirstdayontheJob": "03-20-2019",
          "LastdayontheJob": "03-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Plaza",
          "JobAddress2": "",
          "JobFullAddress": "1 Energy Plaza",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$330.30",
          "BranchNumber": 8,
          "SubmitDate": "03-21-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3920496,
          "ParentWorkOrder": 3916582,
          "JobName": "(NOF)750 FOREST",
          "JobNumber": "502-944100-305",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "750 FOREST AVE",
          "JobAddress2": "",
          "JobFullAddress": "750 FOREST AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,593.24",
          "BranchNumber": 9,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3920383,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Timberstone Vet",
          "JobNumber": "502-1611204-24",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7901 Sylvania Ave",
          "JobAddress2": "",
          "JobFullAddress": "7901 Sylvania Ave",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "JR Interiors",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$7,482.91",
          "BranchNumber": 13,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "JR Interiors"
        },
        {
          "WorkOrder": 3920382,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FLEX FAB",
          "JobNumber": "502-1395174-33",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "102 COOK RD",
          "JobAddress2": "",
          "JobFullAddress": "102 COOK RD",
          "JobCounty": "Barry",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3920381,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Marco's Pizza",
          "JobNumber": "502-1301951-3",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6209 W. Saginaw Hwy",
          "JobAddress2": "",
          "JobFullAddress": "6209 W. Saginaw Hwy",
          "JobCounty": "Eaton",
          "JobState": "Michigan",
          "CustomerCompanyName": "CEDAR DOWNES CO.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "CEDAR DOWNES CO."
        },
        {
          "WorkOrder": 3920380,
          "ParentWorkOrder": "",
          "JobName": "(NOF)REGENCY AT WESTLAND",
          "JobNumber": "502-999108-11",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2209 NORTH NEWBURGH RD",
          "JobAddress2": "",
          "JobFullAddress": "2209 NORTH NEWBURGH RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "Resurrection Carpentry",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$208.39",
          "BranchNumber": 9,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "Resurrection Carpentry"
        },
        {
          "WorkOrder": 3920379,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DELTA DENTAL",
          "JobNumber": "502-952599-14",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27755 STANSBURY CT",
          "JobAddress2": "SUITE 200",
          "JobFullAddress": "27755 STANSBURY CT SUITE 200",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$1,143.19",
          "BranchNumber": 9,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3920378,
          "ParentWorkOrder": "",
          "JobName": "(NOF)GARDEN CITY HOSP.",
          "JobNumber": "502-944100-285",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6245 INKSTER RD",
          "JobAddress2": "",
          "JobFullAddress": "6245 INKSTER RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$121.90",
          "BranchNumber": 9,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3920377,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HIGHLAND RESTAURANT",
          "JobNumber": "502-933550-148",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "100 RENAISSANCE CENTER",
          "JobAddress2": "",
          "JobFullAddress": "100 RENAISSANCE CENTER",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$4,469.56",
          "BranchNumber": 9,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3920376,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Caniff Electric",
          "JobNumber": "502-916331-10",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2001 Caniff Street",
          "JobAddress2": "",
          "JobFullAddress": "2001 Caniff Street",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "IDEAL CONTRACTING LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$4,225.46",
          "BranchNumber": 9,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "IDEAL CONTRACTING LLC"
        },
        {
          "WorkOrder": 3920375,
          "ParentWorkOrder": "",
          "JobName": "(NOF) AT & T KALMAZOO",
          "JobNumber": "502-916315-106",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Stopped",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3920374,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Ashley Capital",
          "JobNumber": "502-916315-105",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20001 Brownstown Center Dr",
          "JobAddress2": "Building #10, Suite 1060",
          "JobFullAddress": "20001 Brownstown Center Dr Building #10, Suite 1060",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$947.82",
          "BranchNumber": 9,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3920373,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NEEHEE'S INDIAN",
          "JobNumber": "502-910621-43",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45656 FORD RD",
          "JobAddress2": "",
          "JobFullAddress": "45656 FORD RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 3920372,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BLUNK HOUSE",
          "JobNumber": "502-910400-139",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "662 Blunk St",
          "JobAddress2": "",
          "JobFullAddress": "662 Blunk St",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACTION DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$4,113.49",
          "BranchNumber": 9,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "ACTION DRYWALL"
        },
        {
          "WorkOrder": 3920371,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Midwest Commercial",
          "JobNumber": "502-869501-58",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8330 Petz Rd",
          "JobAddress2": "",
          "JobFullAddress": "8330 Petz Rd",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "Don Kerby Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$8,555.79",
          "BranchNumber": 11,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "Don Kerby Drywall"
        },
        {
          "WorkOrder": 3920370,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Cargill Salt",
          "JobNumber": "502-856251-2",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "916 S Riverside Ave",
          "JobAddress2": "",
          "JobFullAddress": "916 S Riverside Ave",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "M.D. CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$3,678.56",
          "BranchNumber": 11,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "M.D. CONSTRUCTION"
        },
        {
          "WorkOrder": 3920369,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Windmill Plaza",
          "JobNumber": "502-825063-47",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2325 18 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "2325 18 Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHAFFER INCORPORATED",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,011.03",
          "BranchNumber": 8,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "SHAFFER INCORPORATED"
        },
        {
          "WorkOrder": 3920368,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Allergy Institute",
          "JobNumber": "502-823300-70",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27655 Middlebelt Rd",
          "JobAddress2": "",
          "JobFullAddress": "27655 Middlebelt Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$386.90",
          "BranchNumber": 8,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 3920367,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Five Below",
          "JobNumber": "502-822500-2",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "45592 Michigan Ave",
          "JobAddress2": "",
          "JobFullAddress": "45592 Michigan Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "NETWORK CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$384.48",
          "BranchNumber": 8,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "NETWORK CONSTRUCTION"
        },
        {
          "WorkOrder": 3920366,
          "ParentWorkOrder": "",
          "JobName": "(NOF)(255)Armada HS",
          "JobNumber": "502-816350-137",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "23655 Armada Center Rd",
          "JobAddress2": "",
          "JobFullAddress": "23655 Armada Center Rd",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "ELKHORN CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$717.82",
          "BranchNumber": 8,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "ELKHORN CONSTRUCTION"
        },
        {
          "WorkOrder": 3920365,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DTE SB Basement PH1 P-199023TM3",
          "JobNumber": "502-815250-331",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Plz",
          "JobAddress2": "",
          "JobFullAddress": "1 Energy Plz",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$14,006.00",
          "BalanceRemaining": "$3,484.01",
          "BranchNumber": 8,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3920364,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Air Margaritaville",
          "JobNumber": "502-813200-114",
          "FirstdayontheJob": "03-18-2019",
          "LastdayontheJob": "03-18-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Detroit Metro Airport",
          "JobAddress2": "",
          "JobFullAddress": "Detroit Metro Airport",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,000.00",
          "BalanceRemaining": "$11,934.01",
          "BranchNumber": 8,
          "SubmitDate": "03-19-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3920232,
          "ParentWorkOrder": 3918863,
          "JobName": "(NOF)SKYLINE FALL PROT",
          "JobNumber": "502-53500-32",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8528 LAKE MICHIGAN DR",
          "JobAddress2": "",
          "JobFullAddress": "8528 LAKE MICHIGAN DR",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "PYRAMID ACOUSTICS, INC.",
          "RequestType": "Rescind",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,181.20",
          "BranchNumber": 5,
          "SubmitDate": "03-18-2019",
          "ClientonJob": "PYRAMID ACOUSTICS, INC."
        },
        {
          "WorkOrder": 3918877,
          "ParentWorkOrder": 3908355,
          "JobName": "(NOF)HOLIDAY INN WALKER",
          "JobNumber": "502-1325000-7",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2280 NORTHRIDGE DR NW",
          "JobAddress2": "",
          "JobFullAddress": "2280 NORTHRIDGE DR NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$75,006.00",
          "BranchNumber": 5,
          "SubmitDate": "03-12-2019",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 3918873,
          "ParentWorkOrder": 3914384,
          "JobName": "(NOF)LOVESTRAVELSTOP#743",
          "JobNumber": "502-1611300-2",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6560 DIXIE HWY",
          "JobAddress2": "",
          "JobFullAddress": "6560 DIXIE HWY",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "W H BASS INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$11,558.22",
          "BranchNumber": 1,
          "SubmitDate": "03-12-2019",
          "ClientonJob": "W H BASS INC"
        },
        {
          "WorkOrder": 3918865,
          "ParentWorkOrder": 3914789,
          "JobName": "(NOF)DETROIT OLYMPIA ARM",
          "JobNumber": "502-941500-45",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3030 MCGRAW/",
          "JobAddress2": "5920 W GRAND RIVER",
          "JobFullAddress": "3030 MCGRAW/ 5920 W GRAND RIVER",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "FREEMAN INTERIOR",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$5,169.27",
          "BranchNumber": 9,
          "SubmitDate": "03-12-2019",
          "ClientonJob": "FREEMAN INTERIOR"
        },
        {
          "WorkOrder": 3918863,
          "ParentWorkOrder": 3914786,
          "JobName": "(NOF)SKYLINE FALL PROT",
          "JobNumber": "502-53500-32",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8528 LAKE MICHIGAN DR",
          "JobAddress2": "",
          "JobFullAddress": "8528 LAKE MICHIGAN DR",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "PYRAMID ACOUSTICS, INC.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,181.20",
          "BranchNumber": 5,
          "SubmitDate": "03-12-2019",
          "ClientonJob": "PYRAMID ACOUSTICS, INC."
        },
        {
          "WorkOrder": 3918817,
          "ParentWorkOrder": 3914360,
          "JobName": "(NOF)PUREORTHOPEDICS",
          "JobNumber": "502-20630-41",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11620 S. Saginaw St",
          "JobAddress2": "",
          "JobFullAddress": "11620 S. Saginaw St",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "CASE CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$7,422.94",
          "BranchNumber": 1,
          "SubmitDate": "03-12-2019",
          "ClientonJob": "CASE CONSTRUCTION"
        },
        {
          "WorkOrder": 3918711,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Buchanan",
          "JobNumber": "502-1444189-4",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "215 Post Rd",
          "JobAddress2": "",
          "JobFullAddress": "215 Post Rd",
          "JobCounty": "BERRIEN",
          "JobState": "Michigan",
          "CustomerCompanyName": "GREEN INDUSTRY CONST SVCS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$30,952.88",
          "BranchNumber": 5,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "GREEN INDUSTRY CONST SVCS"
        },
        {
          "WorkOrder": 3918710,
          "ParentWorkOrder": "",
          "JobName": "(NOF) AW Primary",
          "JobNumber": "502-1304550-57",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6510 Texas Street",
          "JobAddress2": "Job# 25470",
          "JobFullAddress": "6510 Texas Street Job# 25470",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "LAKESIDE INTERIOR CONT",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$659.40",
          "BranchNumber": 13,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "LAKESIDE INTERIOR CONT"
        },
        {
          "WorkOrder": 3918709,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LIVONIA -YMCA",
          "JobNumber": "502-1130325-4",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14255 STARK RD",
          "JobAddress2": "",
          "JobFullAddress": "14255 STARK RD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "YOUNG INNOVATIONS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$1,777.97",
          "BranchNumber": 9,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "YOUNG INNOVATIONS INC"
        },
        {
          "WorkOrder": 3918708,
          "ParentWorkOrder": "",
          "JobName": "(NOF) RESURRECTION CARPEN",
          "JobNumber": "502-999108-10",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "34133 SCHOOLCRAFT RD",
          "JobAddress2": "",
          "JobFullAddress": "34133 SCHOOLCRAFT RD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Resurrection Carpentry",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,059.20",
          "BranchNumber": 9,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "Resurrection Carpentry"
        },
        {
          "WorkOrder": 3918707,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CENTRIA",
          "JobNumber": "502-952599-13",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27777 INKSTER RD",
          "JobAddress2": "",
          "JobFullAddress": "27777 INKSTER RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$8,435.57",
          "BranchNumber": 9,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3918706,
          "ParentWorkOrder": "",
          "JobName": "(NOF)New Shop",
          "JobNumber": "502-948945-51",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35020 Klix St",
          "JobAddress2": "",
          "JobFullAddress": "35020 Klix St",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "Trinity Building Group",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$693.94",
          "BranchNumber": 9,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "Trinity Building Group"
        },
        {
          "WorkOrder": 3918705,
          "ParentWorkOrder": "",
          "JobName": "(NOF) NOVI DPS",
          "JobNumber": "502-944100-302",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26300 LEE BEGOLE DR.",
          "JobAddress2": "",
          "JobFullAddress": "26300 LEE BEGOLE DR.",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$1,461.92",
          "BranchNumber": 9,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3918704,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ORCHESTRA PLACE CORR",
          "JobNumber": "502-933550-147",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3663 WOODWARD AVE",
          "JobAddress2": "",
          "JobFullAddress": "3663 WOODWARD AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$13,958.39",
          "BranchNumber": 9,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3918703,
          "ParentWorkOrder": "",
          "JobName": "(NOF)NCRC",
          "JobNumber": "502-920940-50",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 Plymouth Rd",
          "JobAddress2": "",
          "JobFullAddress": "2800 Plymouth Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3918702,
          "ParentWorkOrder": "",
          "JobName": "(NOF)#1539 TED BAKER",
          "JobNumber": "502-913820-500",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W BIG BEAVER RD STE 300",
          "JobAddress2": "",
          "JobFullAddress": "2800 W BIG BEAVER RD STE 300",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,006.00",
          "BalanceRemaining": "$310.39",
          "BranchNumber": 9,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3918701,
          "ParentWorkOrder": "",
          "JobName": "(NOF) New Bldg - Oxford",
          "JobNumber": "502-877250-116",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "499 N Oxford Rd",
          "JobAddress2": "",
          "JobFullAddress": "499 N Oxford Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Blue Water Drywall & Cons",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,806.00",
          "BalanceRemaining": "$2,014.59",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "Blue Water Drywall & Cons"
        },
        {
          "WorkOrder": 3918700,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Port Huron Town Cent",
          "JobNumber": "502-876650-277",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2834 Pine Grove Ave",
          "JobAddress2": "",
          "JobFullAddress": "2834 Pine Grove Ave",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$4,848.93",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3918699,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Glacier Club",
          "JobNumber": "502-849801-177",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7025 MIDDLECOFF DR",
          "JobAddress2": "",
          "JobFullAddress": "7025 MIDDLECOFF DR",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,054.74",
          "BranchNumber": 11,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 3918698,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Vernor",
          "JobNumber": "502-815630-203",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6171 W Vernor Hwy",
          "JobAddress2": "",
          "JobFullAddress": "6171 W Vernor Hwy",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.A. CONTRACTING, LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$21,504.44",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "D.A. CONTRACTING, LLC"
        },
        {
          "WorkOrder": 3918697,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9011 Sheet Metal",
          "JobNumber": "502-815250-330",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "17100 W 12 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "17100 W 12 Mile Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,616.78",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3918696,
          "ParentWorkOrder": "",
          "JobName": "(NOF)19-9007 DTE WCB 11th",
          "JobNumber": "502-815250-329",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Plz Fl 11",
          "JobAddress2": "",
          "JobFullAddress": "1 Energy Plz Fl 11",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$4,562.90",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3918695,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Consumer's Freedom",
          "JobNumber": "502-812350-82",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12457 E. Pleasant Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "12457 E. Pleasant Lake Rd",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "BARTON MALOW",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$10,837.64",
          "BranchNumber": 8,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "BARTON MALOW"
        },
        {
          "WorkOrder": 3918694,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LEGACY WOODS",
          "JobNumber": "502-192258-8",
          "FirstdayontheJob": "03-10-2019",
          "LastdayontheJob": "03-10-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6504 Legacy Woods Trl",
          "JobAddress2": "",
          "JobFullAddress": "6504 Legacy Woods Trl",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Next Generation Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$1,290.72",
          "BranchNumber": 9,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "Next Generation Drywall"
        },
        {
          "WorkOrder": 3918525,
          "ParentWorkOrder": 3914368,
          "JobName": "(NOF) Oasis Homes",
          "JobNumber": "502-869501-57",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7817 Hough Rd",
          "JobAddress2": "",
          "JobFullAddress": "7817 Hough Rd",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "Don Kerby Drywall",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$4,066.09",
          "BranchNumber": 11,
          "SubmitDate": "03-11-2019",
          "ClientonJob": "Don Kerby Drywall"
        },
        {
          "WorkOrder": 3917117,
          "ParentWorkOrder": 3914361,
          "JobName": "NOF 3037 PHHS BP#9",
          "JobNumber": "502-813200-107",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2215 Court Street",
          "JobAddress2": "",
          "JobFullAddress": "2215 Court Street",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-05-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3917109,
          "ParentWorkOrder": 3914381,
          "JobName": "(NOF)FISHER BUILDING/WATER RESOURCE RECOVERY",
          "JobNumber": "502-1344933-6",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1300 MARKET AVE SW",
          "JobAddress2": "",
          "JobFullAddress": "1300 MARKET AVE SW",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER 1 INTERIORS LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "03-05-2019",
          "ClientonJob": "PREMIER 1 INTERIORS LLC"
        },
        {
          "WorkOrder": 3916726,
          "ParentWorkOrder": 3912738,
          "JobName": "(NOF) Chatime Tea House",
          "JobNumber": "502-916315-100",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "340 Maynard Street Ste 4",
          "JobAddress2": "",
          "JobFullAddress": "340 Maynard Street Ste 4",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$1,409.85",
          "BranchNumber": 9,
          "SubmitDate": "03-04-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3916590,
          "ParentWorkOrder": "",
          "JobName": "(NOF) European Wax Center",
          "JobNumber": "502-1605646-2",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6862 N. Rochester Road",
          "JobAddress2": "",
          "JobFullAddress": "6862 N. Rochester Road",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "JFB CONSTRUCTION & DEVELOPMENT INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "JFB CONSTRUCTION & DEVELOPMENT INC"
        },
        {
          "WorkOrder": 3916589,
          "ParentWorkOrder": "",
          "JobName": "(NOF)New Beginings Baptis",
          "JobNumber": "502-1602000-20",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7325 Maceday Lake Rd",
          "JobAddress2": "",
          "JobFullAddress": "7325 Maceday Lake Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Great Lakes Bay Constr",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$18,622.54",
          "BranchNumber": 1,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "Great Lakes Bay Constr"
        },
        {
          "WorkOrder": 3916588,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Verizon Wireless",
          "JobNumber": "502-1524600-2",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5775 State St.",
          "JobAddress2": "",
          "JobFullAddress": "5775 State St.",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "Sterling Millwork Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,000.00",
          "BalanceRemaining": "$50.88",
          "BranchNumber": 1,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "Sterling Millwork Inc"
        },
        {
          "WorkOrder": 3916587,
          "ParentWorkOrder": "",
          "JobName": "(NOF)VIBE SALON PLYM SHIP",
          "JobNumber": "502-1507798-15",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "110 ANN ARBOR RD W",
          "JobAddress2": "",
          "JobFullAddress": "110 ANN ARBOR RD W",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$5,574.20",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3916586,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Grace Church",
          "JobNumber": "502-1301172-41",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1515 South County Road 1",
          "JobAddress2": "",
          "JobFullAddress": "1515 South County Road 1",
          "JobCounty": "Seneca",
          "JobState": "Ohio",
          "CustomerCompanyName": "BKA Drywall LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "BKA Drywall LLC"
        },
        {
          "WorkOrder": 3916585,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CADILLAC PLACE",
          "JobNumber": "502-999108-9",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3044 W GRAND BLVD",
          "JobAddress2": "",
          "JobFullAddress": "3044 W GRAND BLVD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "Resurrection Carpentry",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$2,672.37",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "Resurrection Carpentry"
        },
        {
          "WorkOrder": 3916584,
          "ParentWorkOrder": "",
          "JobName": "(NOF)OKEMOS MARRIOTT",
          "JobNumber": "502-990401-43",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2300 JOLLY OAK RD",
          "JobAddress2": "",
          "JobFullAddress": "2300 JOLLY OAK RD",
          "JobCounty": "Ingham",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$22,844.83",
          "BranchNumber": 7,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3916583,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BJ BREW HOUSE",
          "JobNumber": "502-949575-100",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14455 LAKESIDE CIRCLE",
          "JobAddress2": "",
          "JobFullAddress": "14455 LAKESIDE CIRCLE",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,006.00",
          "BalanceRemaining": "$1,647.54",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3916582,
          "ParentWorkOrder": "",
          "JobName": "(NOF)750 FOREST",
          "JobNumber": "502-944100-305",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "750 FOREST AVE",
          "JobAddress2": "",
          "JobFullAddress": "750 FOREST AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$2,593.24",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3916581,
          "ParentWorkOrder": "",
          "JobName": "(NOF)20001 Brownstown",
          "JobNumber": "502-939300-15",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20001 Center Dr Suite 1060",
          "JobAddress2": "",
          "JobFullAddress": "20001 Center Dr Suite 1060",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "D&S Contractors Inc",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$79,641.45",
          "BranchNumber": 8,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "D&S Contractors Inc"
        },
        {
          "WorkOrder": 3916580,
          "ParentWorkOrder": "",
          "JobName": "(NOF)JULIAN C MADISON",
          "JobNumber": "502-939250-45",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1420 WASHINGTON BLVD",
          "JobAddress2": "",
          "JobFullAddress": "1420 WASHINGTON BLVD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "D.C.P.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "D.C.P."
        },
        {
          "WorkOrder": 3916579,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FORD DCT CTL ENG PLT",
          "JobNumber": "502-937250-150",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1641 CAROL SHELBY WAY E",
          "JobAddress2": "19-203",
          "JobFullAddress": "1641 CAROL SHELBY WAY E 19-203",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINKER TEAM CONSTRUCTION, CO.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$12,694.93",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "BRINKER TEAM CONSTRUCTION, CO."
        },
        {
          "WorkOrder": 3916578,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Target Taylor",
          "JobNumber": "502-934150-63",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14099 Pardee Rd",
          "JobAddress2": "",
          "JobFullAddress": "14099 Pardee Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "WOODS CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$22,706.78",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "WOODS CONSTRUCTION INC"
        },
        {
          "WorkOrder": 3916577,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FIRST STATE BANK",
          "JobNumber": "502-919700-129",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1575 S ROCHESTER ROAD",
          "JobAddress2": "",
          "JobFullAddress": "1575 S ROCHESTER ROAD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$42,006.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 3916576,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Children's Orchard",
          "JobNumber": "502-916315-104",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20781 Gibraltar Rd.",
          "JobAddress2": "",
          "JobFullAddress": "20781 Gibraltar Rd.",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,942.19",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3916575,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1538 MICH EAR INSTIT",
          "JobNumber": "502-913820-499",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30055 NORTHWESTERN HWY",
          "JobAddress2": "",
          "JobFullAddress": "30055 NORTHWESTERN HWY",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$976.06",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3916574,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1531 BATH & BODY WKS",
          "JobNumber": "502-913820-492",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8570 26 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "8570 26 MILE RD",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$6,371.96",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3916573,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SOLA SALON",
          "JobNumber": "502-910621-42",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6425 N CANTON CENTER RD",
          "JobAddress2": "",
          "JobFullAddress": "6425 N CANTON CENTER RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "All Phases Ceiling & Carp",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,495.45",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "All Phases Ceiling & Carp"
        },
        {
          "WorkOrder": 3916572,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WARNER MIDDLE SCHOOL",
          "JobNumber": "502-910050-51",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30303 W. 14 MILE RD",
          "JobAddress2": "",
          "JobFullAddress": "30303 W. 14 MILE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$9,454.18",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 3916571,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Family Dollar",
          "JobNumber": "502-876752-7",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3518 E. Main Street",
          "JobAddress2": "",
          "JobFullAddress": "3518 E. Main Street",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "SBC Property Services",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$4,354.71",
          "BranchNumber": 8,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "SBC Property Services"
        },
        {
          "WorkOrder": 3916570,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BJ's Brewhouse",
          "JobNumber": "502-876650-276",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "14455 Lakeside Circle",
          "JobAddress2": "",
          "JobFullAddress": "14455 Lakeside Circle",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "SC CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "SC CONSTRUCTION"
        },
        {
          "WorkOrder": 3916569,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Glaier Club",
          "JobNumber": "502-849801-175",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7166 Venturi Dr",
          "JobAddress2": "",
          "JobFullAddress": "7166 Venturi Dr",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$2,633.15",
          "BranchNumber": 11,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 3916568,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Glacier Club",
          "JobNumber": "502-849801-174",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7172 VENTURI DR",
          "JobAddress2": "",
          "JobFullAddress": "7172 VENTURI DR",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$3,030.64",
          "BranchNumber": 11,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 3916567,
          "ParentWorkOrder": "",
          "JobName": "(NOF)CHEM TREND",
          "JobNumber": "502-825063-46",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3205 E. GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "3205 E. GRAND RIVER AVE",
          "JobCounty": "LIVINGSTON",
          "JobState": "Michigan",
          "CustomerCompanyName": "SHAFFER INCORPORATED",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$2,336.13",
          "BranchNumber": 8,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "SHAFFER INCORPORATED"
        },
        {
          "WorkOrder": 3916566,
          "ParentWorkOrder": "",
          "JobName": "(NOF) A.K.A Architects",
          "JobNumber": "502-817800-35",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "303 E. Third Street",
          "JobAddress2": "Suite 100",
          "JobFullAddress": "303 E. Third Street Suite 100",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$2,388.79",
          "BranchNumber": 8,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3916565,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WARNER MIDDLE SCHOOL",
          "JobNumber": "502-813200-112",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30303 W 14 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "30303 W 14 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING CORP.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$3,721.26",
          "BranchNumber": 8,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING CORP."
        },
        {
          "WorkOrder": 3916564,
          "ParentWorkOrder": "",
          "JobName": "(NOF)2997-12 McLaren/",
          "JobNumber": "502-813200-111",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "50 N Perry St",
          "JobAddress2": "",
          "JobFullAddress": "50 N Perry St",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING CORP / CCC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING CORP / CCC"
        },
        {
          "WorkOrder": 3916563,
          "ParentWorkOrder": "",
          "JobName": "(NOF)AUDIO ADVISOR",
          "JobNumber": "502-53500-31",
          "FirstdayontheJob": "02-28-2019",
          "LastdayontheJob": "02-28-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5500 EXECUTIVE PKWY SE",
          "JobAddress2": "",
          "JobFullAddress": "5500 EXECUTIVE PKWY SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "PYRAMID ACOUSTICS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$4,001.31",
          "BranchNumber": 5,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "PYRAMID ACOUSTICS, INC."
        },
        {
          "WorkOrder": 3916545,
          "ParentWorkOrder": 3914379,
          "JobName": "(NOF) GKN - PHASE 2",
          "JobNumber": "502-952599-12",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1670 N Opdyke Rd",
          "JobAddress2": "",
          "JobFullAddress": "1670 N Opdyke Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$12,271.69",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3916544,
          "ParentWorkOrder": 3912741,
          "JobName": "(NOF) GKN - PHASE 1",
          "JobNumber": "502-952599-11",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1670 N OPDYKE RD",
          "JobAddress2": "",
          "JobFullAddress": "1670 N OPDYKE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$11,942.04",
          "BranchNumber": 9,
          "SubmitDate": "03-01-2019",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3915745,
          "ParentWorkOrder": 3909908,
          "JobName": "(NOF)ALMA OPERA HOUSE",
          "JobNumber": "502-30900-63",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "101 EAST SUPERIOR STREET",
          "JobAddress2": "",
          "JobFullAddress": "101 EAST SUPERIOR STREET",
          "JobCounty": "Gratiot",
          "JobState": "Michigan",
          "CustomerCompanyName": "Integrity Interiors Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$3,998.27",
          "BranchNumber": 7,
          "SubmitDate": "02-26-2019",
          "ClientonJob": "Integrity Interiors Inc."
        },
        {
          "WorkOrder": 3914795,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DEARBORN-MORLEY",
          "JobNumber": "502-1677740-15",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22610 MORLEY AVE",
          "JobAddress2": "",
          "JobFullAddress": "22610 MORLEY AVE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$8,009.54",
          "BranchNumber": 9,
          "SubmitDate": "02-25-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3914794,
          "ParentWorkOrder": "",
          "JobName": "(NOF)THIRD NATURE BREW",
          "JobNumber": "502-1603909-6",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7733 CHILDSDALE AVE NE",
          "JobAddress2": "",
          "JobFullAddress": "7733 CHILDSDALE AVE NE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "PEL Construction",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,006.00",
          "BalanceRemaining": "$1,004.72",
          "BranchNumber": 5,
          "SubmitDate": "02-25-2019",
          "ClientonJob": "PEL Construction"
        },
        {
          "WorkOrder": 3914793,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Oxford Tap",
          "JobNumber": "502-1594988-3",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "36 S. Washington St",
          "JobAddress2": "",
          "JobFullAddress": "36 S. Washington St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "SOUTH EAST COMMERCIAL INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-25-2019",
          "ClientonJob": "SOUTH EAST COMMERCIAL INC"
        },
        {
          "WorkOrder": 3914792,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 310 MAIN 2ND FLOOR",
          "JobNumber": "502-1507798-13",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "310 N MAIN ST",
          "JobAddress2": "",
          "JobFullAddress": "310 N MAIN ST",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$1,319.79",
          "BranchNumber": 9,
          "SubmitDate": "02-25-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3914791,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hands of Hope",
          "JobNumber": "502-1268251-3",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "35 E Huron ST",
          "JobAddress2": "",
          "JobFullAddress": "35 E Huron ST",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "MCKENZIE CONSTRUCTION LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$2,610.46",
          "BranchNumber": 8,
          "SubmitDate": "02-25-2019",
          "ClientonJob": "MCKENZIE CONSTRUCTION LLC"
        },
        {
          "WorkOrder": 3914790,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Bob's Furniture",
          "JobNumber": "502-999108-8",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "43460 W Oaks Dr",
          "JobAddress2": "",
          "JobFullAddress": "43460 W Oaks Dr",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Resurrection Carpentry",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$21,883.01",
          "BranchNumber": 9,
          "SubmitDate": "02-25-2019",
          "ClientonJob": "Resurrection Carpentry"
        },
        {
          "WorkOrder": 3914789,
          "ParentWorkOrder": "",
          "JobName": "(NOF)DETROIT OLYMPIA ARM",
          "JobNumber": "502-941500-45",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3030 MCGRAW/",
          "JobAddress2": "5920 W GRAND RIVER",
          "JobFullAddress": "3030 MCGRAW/ 5920 W GRAND RIVER",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "FREEMAN INTERIOR",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$5,169.27",
          "BranchNumber": 9,
          "SubmitDate": "02-25-2019",
          "ClientonJob": "FREEMAN INTERIOR"
        },
        {
          "WorkOrder": 3914788,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MI Metal Coating",
          "JobNumber": "502-849601-2",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1720 Dove St",
          "JobAddress2": "",
          "JobFullAddress": "1720 Dove St",
          "JobCounty": "Saint Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "PHOENIX CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$10,006.00",
          "BalanceRemaining": "$6,332.25",
          "BranchNumber": 11,
          "SubmitDate": "02-25-2019",
          "ClientonJob": "PHOENIX CONTRACTING"
        },
        {
          "WorkOrder": 3914787,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Pontiac Schools",
          "JobNumber": "502-820500-30",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1700 Baldwin Ave",
          "JobAddress2": "",
          "JobFullAddress": "1700 Baldwin Ave",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "LEE CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$5,631.40",
          "BranchNumber": 8,
          "SubmitDate": "02-25-2019",
          "ClientonJob": "LEE CONTRACTING"
        },
        {
          "WorkOrder": 3914786,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SKYLINE FALL PROT",
          "JobNumber": "502-53500-32",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8528 LAKE MICHIGAN DR",
          "JobAddress2": "",
          "JobFullAddress": "8528 LAKE MICHIGAN DR",
          "JobCounty": "OTTAWA",
          "JobState": "Michigan",
          "CustomerCompanyName": "PYRAMID ACOUSTICS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,181.20",
          "BranchNumber": 5,
          "SubmitDate": "02-25-2019",
          "ClientonJob": "PYRAMID ACOUSTICS, INC."
        },
        {
          "WorkOrder": 3914785,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SOLOMON LAW",
          "JobNumber": "502-52900-32",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1925 BRETON RD SE",
          "JobAddress2": "",
          "JobFullAddress": "1925 BRETON RD SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "MID MICHIGAN CEILING &",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$982.83",
          "BranchNumber": 5,
          "SubmitDate": "02-25-2019",
          "ClientonJob": "MID MICHIGAN CEILING &"
        },
        {
          "WorkOrder": 3914784,
          "ParentWorkOrder": "",
          "JobName": "(NOF) LOGIC DATA",
          "JobNumber": "502-50700-10",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5300 BROADM0OR AVE SE",
          "JobAddress2": "",
          "JobFullAddress": "5300 BROADM0OR AVE SE",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "D9 SYSTEMS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$3,524.52",
          "BranchNumber": 5,
          "SubmitDate": "02-25-2019",
          "ClientonJob": "D9 SYSTEMS INC"
        },
        {
          "WorkOrder": 3914783,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WITNESS INSPECTIONS",
          "JobNumber": "502-50435-52",
          "FirstdayontheJob": "02-24-2019",
          "LastdayontheJob": "02-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "601 E ROOSVELT AVE",
          "JobAddress2": "",
          "JobFullAddress": "601 E ROOSVELT AVE",
          "JobCounty": "Ottawa",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR BOUWKAMP & ASSOC. INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "02-25-2019",
          "ClientonJob": "JR BOUWKAMP & ASSOC. INC"
        },
        {
          "WorkOrder": 3914387,
          "ParentWorkOrder": "",
          "JobName": "(NOF)NORTHVILLE HOUSE",
          "JobNumber": "502-1677740-14",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "21350 CURRIE RD",
          "JobAddress2": "",
          "JobFullAddress": "21350 CURRIE RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$4,911.25",
          "BranchNumber": 9,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3914386,
          "ParentWorkOrder": "",
          "JobName": "(NOF)SCHOOLCRAFT",
          "JobNumber": "502-1677740-13",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32985 SCHOOLCRAFT RD",
          "JobAddress2": "",
          "JobFullAddress": "32985 SCHOOLCRAFT RD",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$3,970.65",
          "BranchNumber": 9,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3914385,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MEDICAL BLDG",
          "JobNumber": "502-1677740-12",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2236 PACKARD RD",
          "JobAddress2": "",
          "JobFullAddress": "2236 PACKARD RD",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$10,419.36",
          "BranchNumber": 9,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3914384,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LOVESTRAVELSTOP#743",
          "JobNumber": "502-1611300-2",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6560 DIXIE HWY",
          "JobAddress2": "",
          "JobFullAddress": "6560 DIXIE HWY",
          "JobCounty": "Saginaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "W H BASS INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$11,558.22",
          "BranchNumber": 1,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "W H BASS INC"
        },
        {
          "WorkOrder": 3914383,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Holiday Inn Express",
          "JobNumber": "502-1609034-34",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1515 Cedar Point Dr",
          "JobAddress2": "",
          "JobFullAddress": "1515 Cedar Point Dr",
          "JobCounty": "Erie",
          "JobState": "Ohio",
          "CustomerCompanyName": "KCS Contracting LLC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$51,294.74",
          "BranchNumber": 13,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "KCS Contracting LLC"
        },
        {
          "WorkOrder": 3914382,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BIG LOTS PORTAGE",
          "JobNumber": "502-1395174-31",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6207 S. WESTNEDGE AVE",
          "JobAddress2": "",
          "JobFullAddress": "6207 S. WESTNEDGE AVE",
          "JobCounty": "KALAMAZOO",
          "JobState": "Michigan",
          "CustomerCompanyName": "KEYSTONE CONSTRUCTION CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$12,021.45",
          "BranchNumber": 5,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "KEYSTONE CONSTRUCTION CO"
        },
        {
          "WorkOrder": 3914381,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FISHER BUILDING/WATER RESOURCE RECOVERY",
          "JobNumber": "502-1344933-6",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1300 MARKET AVE SW",
          "JobAddress2": "",
          "JobFullAddress": "1300 MARKET AVE SW",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "PREMIER 1 INTERIORS LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "PREMIER 1 INTERIORS LLC"
        },
        {
          "WorkOrder": 3914380,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Holiday Inn Express",
          "JobNumber": "502-1325000-9",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3853 Research Park Dr",
          "JobAddress2": "",
          "JobFullAddress": "3853 Research Park Dr",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 5,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 3914379,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GKN - PHASE 2",
          "JobNumber": "502-952599-12",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1670 N Opdyke Rd",
          "JobAddress2": "",
          "JobFullAddress": "1670 N Opdyke Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$12,271.69",
          "BranchNumber": 9,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3914378,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DMC - 19-202",
          "JobNumber": "502-937250-149",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "Not specified",
          "JobAddress2": "",
          "JobFullAddress": "Not specified",
          "JobCounty": "",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINKER TEAM CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Canceled",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "",
          "ClientonJob": "BRINKER TEAM CONSTRUCTION"
        },
        {
          "WorkOrder": 3914377,
          "ParentWorkOrder": "",
          "JobName": "(NOF) 5275 KENILWORTH",
          "JobNumber": "502-936150-52",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "5275 KENILWORTH ST",
          "JobAddress2": "",
          "JobFullAddress": "5275 KENILWORTH ST",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "ABSTRACT CEILINGS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$2,096.46",
          "BranchNumber": 9,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "ABSTRACT CEILINGS, INC."
        },
        {
          "WorkOrder": 3914376,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MD VIP",
          "JobNumber": "502-919700-128",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1639 E Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "1639 E Big Beaver Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$688.82",
          "BranchNumber": 9,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "Multi Drywall & Partition"
        },
        {
          "WorkOrder": 3914375,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Club Pilates",
          "JobNumber": "502-916315-103",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26597 N Dixie Highway Ste 167",
          "JobAddress2": "",
          "JobFullAddress": "26597 N Dixie Highway Ste 167",
          "JobCounty": "WOOD",
          "JobState": "Ohio",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,959.03",
          "BranchNumber": 9,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3914374,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Royal Oak Barbershop",
          "JobNumber": "502-916315-102",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3208 Crooks Road",
          "JobAddress2": "",
          "JobFullAddress": "3208 Crooks Road",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$469.45",
          "BranchNumber": 9,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3914373,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Yoga Studio Birmingh",
          "JobNumber": "502-916315-101",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2227 Cole St",
          "JobAddress2": "",
          "JobFullAddress": "2227 Cole St",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$1,322.29",
          "BranchNumber": 9,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3914372,
          "ParentWorkOrder": "",
          "JobName": "(NOF)AT&T Woodland Mall",
          "JobNumber": "502-916315-99",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3195 28th Street SE",
          "JobAddress2": "Space E-109",
          "JobFullAddress": "3195 28th Street SE Space E-109",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$529.23",
          "BranchNumber": 9,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3914371,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1536 SHAKE SHACK",
          "JobNumber": "502-913820-497",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W Big Beaver Rd",
          "JobAddress2": "",
          "JobFullAddress": "2800 W Big Beaver Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,040.83",
          "BranchNumber": 9,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3914370,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1535 MORPHE",
          "JobNumber": "502-913820-496",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2800 W Big Beaver Rd Ste 410b",
          "JobAddress2": "",
          "JobFullAddress": "2800 W Big Beaver Rd Ste 410b",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$9,006.00",
          "BalanceRemaining": "$4,508.23",
          "BranchNumber": 9,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3914369,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LEVEL 1 HEAT/COOL",
          "JobNumber": "502-910868-55",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "54000 GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "54000 GRAND RIVER AVE",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construct",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,506.00",
          "BalanceRemaining": "$825.32",
          "BranchNumber": 9,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "American Family Construct"
        },
        {
          "WorkOrder": 3914368,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Oasis Homes",
          "JobNumber": "502-869501-57",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7817 Hough Rd",
          "JobAddress2": "",
          "JobFullAddress": "7817 Hough Rd",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "Don Kerby Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,506.00",
          "BalanceRemaining": "$4,066.09",
          "BranchNumber": 11,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "Don Kerby Drywall"
        },
        {
          "WorkOrder": 3914367,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Corrias Residence",
          "JobNumber": "502-867560-33",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7980 Linden Drive",
          "JobAddress2": "",
          "JobFullAddress": "7980 Linden Drive",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "ROBERT G WYLIN II",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$5,497.27",
          "BranchNumber": 11,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "ROBERT G WYLIN II"
        },
        {
          "WorkOrder": 3914366,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DJL",
          "JobNumber": "502-849801-173",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "540 Argyle St",
          "JobAddress2": "",
          "JobFullAddress": "540 Argyle St",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "PLATINUM DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,006.00",
          "BalanceRemaining": "$6,714.61",
          "BranchNumber": 11,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "PLATINUM DRYWALL"
        },
        {
          "WorkOrder": 3914365,
          "ParentWorkOrder": "",
          "JobName": "(NOF) PENSKE",
          "JobNumber": "502-843701-191",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2555 S. TELEGRAPH RD",
          "JobAddress2": "",
          "JobFullAddress": "2555 S. TELEGRAPH RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 3914364,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Senior Helpers",
          "JobNumber": "502-823300-69",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "30701 West 10 Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "30701 West 10 Mile Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "G.J. PERELLI CO",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$287.85",
          "BranchNumber": 8,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "G.J. PERELLI CO"
        },
        {
          "WorkOrder": 3914363,
          "ParentWorkOrder": "",
          "JobName": "(NOF)US Census Macomb",
          "JobNumber": "502-817800-34",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "19800 Hall Road",
          "JobAddress2": "",
          "JobFullAddress": "19800 Hall Road",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$2,701.08",
          "BranchNumber": 8,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3914362,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Planet Fitness Lpr",
          "JobNumber": "502-816100-16",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1935 W Genesee St",
          "JobAddress2": "",
          "JobFullAddress": "1935 W Genesee St",
          "JobCounty": "Lapeer",
          "JobState": "Michigan",
          "CustomerCompanyName": "EDIFICE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "EDIFICE CONSTRUCTION"
        },
        {
          "WorkOrder": 3914361,
          "ParentWorkOrder": "",
          "JobName": "NOF 3037 PHHS BP#9",
          "JobNumber": "502-813200-107",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2215 Court Street",
          "JobAddress2": "",
          "JobFullAddress": "2215 Court Street",
          "JobCounty": "SAINT CLAIR",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3914360,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PUREORTHOPEDICS",
          "JobNumber": "502-20630-41",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11620 S. Saginaw St",
          "JobAddress2": "",
          "JobFullAddress": "11620 S. Saginaw St",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "CASE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$7,422.94",
          "BranchNumber": 1,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "CASE CONSTRUCTION"
        },
        {
          "WorkOrder": 3914359,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ST. MARY'S CATHLAB#1 JOB#19-132 PC#09-01000",
          "JobNumber": "502-12150-8",
          "FirstdayontheJob": "02-20-2019",
          "LastdayontheJob": "02-20-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "800 S WASHINGTON AVE",
          "JobAddress2": "",
          "JobFullAddress": "800 S WASHINGTON AVE",
          "JobCounty": "SAGINAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "SERENUS JOHNSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,096.24",
          "BranchNumber": 1,
          "SubmitDate": "02-21-2019",
          "ClientonJob": "SERENUS JOHNSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3912751,
          "ParentWorkOrder": "",
          "JobName": "(NOF)WESTFIELD CHARTER ACA",
          "JobNumber": "502-910868-54",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27101 W Chicago",
          "JobAddress2": "",
          "JobFullAddress": "27101 W Chicago",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construct",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$5,566.51",
          "BranchNumber": 9,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "American Family Construct"
        },
        {
          "WorkOrder": 3912750,
          "ParentWorkOrder": "",
          "JobName": "(NOF)H&M Great Lakes Cros",
          "JobNumber": "502-916315-96",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "4000/4002 Baldwin Rd",
          "JobAddress2": "",
          "JobFullAddress": "4000/4002 Baldwin Rd",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$11.24",
          "BranchNumber": 9,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3912749,
          "ParentWorkOrder": "",
          "JobName": "(NOF)FAMILY DOLLAR",
          "JobNumber": "502-949575-98",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3401 GRAND RIVER AVE",
          "JobAddress2": "",
          "JobFullAddress": "3401 GRAND RIVER AVE",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "TOKEN ACOUSTICAL, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$12,006.00",
          "BalanceRemaining": "$1,715.61",
          "BranchNumber": 9,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "TOKEN ACOUSTICAL, INC."
        },
        {
          "WorkOrder": 3912748,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Brewster Elementary",
          "JobNumber": "502-920940-44",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1535 Brewster Road",
          "JobAddress2": "",
          "JobFullAddress": "1535 Brewster Road",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Clark Construction Co",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$350,000.00",
          "BalanceRemaining": "$1,187.34",
          "BranchNumber": 8,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "Clark Construction Co"
        },
        {
          "WorkOrder": 3912747,
          "ParentWorkOrder": "",
          "JobName": "(NOF)8404 swan creek-HMOR",
          "JobNumber": "502-859551-17",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "8404 Swan Creek Pt",
          "JobAddress2": "",
          "JobFullAddress": "8404 Swan Creek Pt",
          "JobCounty": "St Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "PICKLEHAUPT DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,506.00",
          "BalanceRemaining": "$4,828.77",
          "BranchNumber": 11,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "PICKLEHAUPT DRYWALL"
        },
        {
          "WorkOrder": 3912746,
          "ParentWorkOrder": "",
          "JobName": "(NOF)2997-010 Detroit Arsenal",
          "JobNumber": "502-813200-110",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "6501 Eleven Mile Rd",
          "JobAddress2": "",
          "JobFullAddress": "6501 Eleven Mile Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL CONTRACTING",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "COMMERCIAL CONTRACTING"
        },
        {
          "WorkOrder": 3912745,
          "ParentWorkOrder": "",
          "JobName": "(NOF)18-8066 DTE WCB 2nd Floor",
          "JobNumber": "502-815250-328",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1 Energy Plz 2nd Floor",
          "JobAddress2": "",
          "JobFullAddress": "1 Energy Plz 2nd Floor",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "DENN-CO CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$8,600.14",
          "BranchNumber": 8,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "DENN-CO CONSTRUCTION"
        },
        {
          "WorkOrder": 3912744,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Residence Inn North",
          "JobNumber": "502-1617542-3",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3536 Secor Rd.",
          "JobAddress2": "",
          "JobFullAddress": "3536 Secor Rd.",
          "JobCounty": "Lucas",
          "JobState": "Ohio",
          "CustomerCompanyName": "8 ACES CONSTRUCTION INC",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$4,102.24",
          "BranchNumber": 13,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "8 ACES CONSTRUCTION INC"
        },
        {
          "WorkOrder": 3912743,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Mortech",
          "JobNumber": "502-843701-187",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "37641 Mound Rd",
          "JobAddress2": "",
          "JobFullAddress": "37641 Mound Rd",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$7,006.00",
          "BalanceRemaining": "$5,692.65",
          "BranchNumber": 8,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 3912742,
          "ParentWorkOrder": "",
          "JobName": "(NOF) MONROE FIRE STATION",
          "JobNumber": "502-910050-50",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "911 E 3rd St",
          "JobAddress2": "",
          "JobFullAddress": "911 E 3rd St",
          "JobCounty": "Monroe",
          "JobState": "Michigan",
          "CustomerCompanyName": "ANM CONST. CO. INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$30,006.00",
          "BalanceRemaining": "$6,077.69",
          "BranchNumber": 9,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "ANM CONST. CO. INC."
        },
        {
          "WorkOrder": 3912741,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GKN - PHASE 1",
          "JobNumber": "502-952599-11",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1670 N OPDYKE RD",
          "JobAddress2": "",
          "JobFullAddress": "1670 N OPDYKE RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Dynamic Commercial Carp.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$60,006.00",
          "BalanceRemaining": "$11,942.04",
          "BranchNumber": 9,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "Dynamic Commercial Carp."
        },
        {
          "WorkOrder": 3912740,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DMC RIM",
          "JobNumber": "502-944100-303",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "261 MACK AVE",
          "JobAddress2": "",
          "JobFullAddress": "261 MACK AVE",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$3,284.77",
          "BranchNumber": 9,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3912739,
          "ParentWorkOrder": "",
          "JobName": "(NOF) CHEESECAKE FACTORY",
          "JobNumber": "502-933550-146",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27500 Novi Rd Spc E-121",
          "JobAddress2": "",
          "JobFullAddress": "27500 Novi Rd Spc E-121",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "TURNER-BROOKS, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$6,554.88",
          "BranchNumber": 9,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "TURNER-BROOKS, INC."
        },
        {
          "WorkOrder": 3912738,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Chatime Tea House",
          "JobNumber": "502-916315-100",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "340 Maynard Street",
          "JobAddress2": "",
          "JobFullAddress": "340 Maynard Street",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$1,409.85",
          "BranchNumber": 9,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3912737,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BEVERLY CREST",
          "JobNumber": "502-192258-7",
          "FirstdayontheJob": "02-11-2019",
          "LastdayontheJob": "02-11-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7085 BEVERLY CREST Dr",
          "JobAddress2": "",
          "JobFullAddress": "7085 BEVERLY CREST Dr",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Next Generation Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "-$182.83",
          "BranchNumber": 9,
          "SubmitDate": "02-12-2019",
          "ClientonJob": "Next Generation Drywall"
        },
        {
          "WorkOrder": 3909915,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ST CLAIR APTS",
          "JobNumber": "502-1084306-3",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1970 RIVER RD",
          "JobAddress2": "",
          "JobFullAddress": "1970 RIVER RD",
          "JobCounty": "Saint Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "T&B CONSTRUCTION",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$70,006.00",
          "BalanceRemaining": "$38,335.74",
          "BranchNumber": 13,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "T&B CONSTRUCTION"
        },
        {
          "WorkOrder": 3909914,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ROCKWOOD 2 HOUSES",
          "JobNumber": "502-1325000-8",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "31339/31315 Olmstead Rd",
          "JobAddress2": "",
          "JobFullAddress": "31339/31315 Olmstead Rd",
          "JobCounty": "Wayne",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$18,006.00",
          "BalanceRemaining": "$4,200.96",
          "BranchNumber": 5,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 3909913,
          "ParentWorkOrder": "",
          "JobName": "(NOF)PANDA EXPRESS",
          "JobNumber": "502-858231-41",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3170 28TH ST SE",
          "JobAddress2": "",
          "JobFullAddress": "3170 28TH ST SE",
          "JobCounty": "Kent",
          "JobState": "Michigan",
          "CustomerCompanyName": "MIDWEST METAL CONST., LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$1,208.44",
          "BranchNumber": 11,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "MIDWEST METAL CONST., LLC"
        },
        {
          "WorkOrder": 3909912,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Macomb Senior Center",
          "JobNumber": "502-814500-76",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "49880 Hayes Road",
          "JobAddress2": "",
          "JobFullAddress": "49880 Hayes Road",
          "JobCounty": "MACOMB",
          "JobState": "Michigan",
          "CustomerCompanyName": "COMMERCIAL INT CONST",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$170,000.00",
          "BalanceRemaining": "$24,683.70",
          "BranchNumber": 8,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "COMMERCIAL INT CONST"
        },
        {
          "WorkOrder": 3909911,
          "ParentWorkOrder": "",
          "JobName": "(NOF)LEGACY LIONS",
          "JobNumber": "502-192258-5",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "24338 PINNACLE CIRCLE",
          "JobAddress2": "",
          "JobFullAddress": "24338 PINNACLE CIRCLE",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "Next Generation Drywall",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "Next Generation Drywall"
        },
        {
          "WorkOrder": 3909910,
          "ParentWorkOrder": "",
          "JobName": "(NOF)IHS MARKIT 9TH FLOOR",
          "JobNumber": "502-920000-25",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "26533 EVERGREEN RD",
          "JobAddress2": "",
          "JobFullAddress": "26533 EVERGREEN RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "NVR FINISHES, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$503.02",
          "BranchNumber": 9,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "NVR FINISHES, INC."
        },
        {
          "WorkOrder": 3909909,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Auburn Crossing",
          "JobNumber": "502-916315-54",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2767 University Drive",
          "JobAddress2": "",
          "JobFullAddress": "2767 University Drive",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$171.90",
          "BranchNumber": 9,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3909908,
          "ParentWorkOrder": "",
          "JobName": "(NOF)ALMA OPERA HOUSE",
          "JobNumber": "502-30900-63",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "101 EAST SUPERIOR STREET",
          "JobAddress2": "",
          "JobFullAddress": "101 EAST SUPERIOR STREET",
          "JobCounty": "Gratiot",
          "JobState": "Michigan",
          "CustomerCompanyName": "Integrity Interiors Inc.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$100,006.00",
          "BalanceRemaining": "$3,998.27",
          "BranchNumber": 7,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "Integrity Interiors Inc."
        },
        {
          "WorkOrder": 3909907,
          "ParentWorkOrder": "",
          "JobName": "(NOF) UT Glendale Medical",
          "JobNumber": "502-1309100-15",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "3333 Glendale Ave",
          "JobAddress2": "",
          "JobFullAddress": "3333 Glendale Ave",
          "JobCounty": "LUCAS",
          "JobState": "Ohio",
          "CustomerCompanyName": "VAN TASSEL CONSTR. CORP.",
          "RequestType": "Notice of Furnishing and Request of Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 13,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "VAN TASSEL CONSTR. CORP."
        },
        {
          "WorkOrder": 3909906,
          "ParentWorkOrder": "",
          "JobName": "(NOF) SOLA SALON",
          "JobNumber": "502-137665-10",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "22713 ALLEN RD",
          "JobAddress2": "",
          "JobFullAddress": "22713 ALLEN RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BANYAN CONSTRUCTION SERVICES LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$606.02",
          "BranchNumber": 13,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "BANYAN CONSTRUCTION SERVICES LLC"
        },
        {
          "WorkOrder": 3909905,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Smoothie King",
          "JobNumber": "502-916315-98",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "27735 Harper Ave",
          "JobAddress2": "",
          "JobFullAddress": "27735 Harper Ave",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$2,213.83",
          "BranchNumber": 9,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3909904,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Oakland Medical",
          "JobNumber": "502-843701-186",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "633 South Blvd E",
          "JobAddress2": "",
          "JobFullAddress": "633 South Blvd E",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "HUDSON INTERIORS",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$120,000.00",
          "BalanceRemaining": "$18,692.09",
          "BranchNumber": 8,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "HUDSON INTERIORS"
        },
        {
          "WorkOrder": 3909903,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HR Pro",
          "JobNumber": "502-916315-95",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1025 N. Campbell Rd",
          "JobAddress2": "",
          "JobFullAddress": "1025 N. Campbell Rd",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$25,006.00",
          "BalanceRemaining": "$5,445.04",
          "BranchNumber": 9,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3909902,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Grow House Dettman",
          "JobNumber": "502-916315-94",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1183 S Dettman Road",
          "JobAddress2": "",
          "JobFullAddress": "1183 S Dettman Road",
          "JobCounty": "Jackson",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$330,000.00",
          "BalanceRemaining": "$18,255.70",
          "BranchNumber": 9,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3909901,
          "ParentWorkOrder": "",
          "JobName": "(NOF) GRANDVIEW",
          "JobNumber": "502-944100-301",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "7931 Grand St",
          "JobAddress2": "",
          "JobFullAddress": "7931 Grand St",
          "JobCounty": "Washtenaw",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "02-07-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3909900,
          "ParentWorkOrder": "",
          "JobName": "(NOF) DUNHILL ESTATES",
          "JobNumber": "502-919700-127",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20825 DUNHILL DR",
          "JobAddress2": "",
          "JobFullAddress": "20825 DUNHILL DR",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Multi Drywall & Partition LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "$8,434.00",
          "BranchNumber": 9,
          "SubmitDate": "02-07-2019",
          "ClientonJob": "Multi Drywall & Partition LLC"
        },
        {
          "WorkOrder": 3909899,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Del Taco Shelby",
          "JobNumber": "502-916315-86",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12387 23 Mile Road",
          "JobAddress2": "",
          "JobFullAddress": "12387 23 Mile Road",
          "JobCounty": "Macomb",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$50,006.00",
          "BalanceRemaining": "-$381.60",
          "BranchNumber": 9,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3909898,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Covenant Community",
          "JobNumber": "502-916315-97",
          "FirstdayontheJob": "01-31-2019",
          "LastdayontheJob": "01-31-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "32932 WARREN ROAD",
          "JobAddress2": "",
          "JobFullAddress": "32932 WARREN ROAD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$18,006.00",
          "BalanceRemaining": "$1,256.67",
          "BranchNumber": 9,
          "SubmitDate": "02-01-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3908357,
          "ParentWorkOrder": "",
          "JobName": "(NOF)THE GLENS AT CAMBRID",
          "JobNumber": "502-1677740-11",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1315 WATERSIDE",
          "JobAddress2": "",
          "JobFullAddress": "1315 WATERSIDE",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "TRADEMARK DRYWALL LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$20,006.00",
          "BranchNumber": 9,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "TRADEMARK DRYWALL LLC"
        },
        {
          "WorkOrder": 3908356,
          "ParentWorkOrder": "",
          "JobName": "(NOF) WARD DENTAL",
          "JobNumber": "502-1507798-a",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1080 CHARLES H ORNDORF DR.",
          "JobAddress2": "",
          "JobFullAddress": "1080 CHARLES H ORNDORF DR.",
          "JobCounty": "Livingston",
          "JobState": "Michigan",
          "CustomerCompanyName": "NEW HUDSON CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$15,006.00",
          "BalanceRemaining": "$15,006.00",
          "BranchNumber": 9,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "NEW HUDSON CONSTRUCTION"
        },
        {
          "WorkOrder": 3908355,
          "ParentWorkOrder": "",
          "JobName": "(NOF)HOLIDAY INN WALKER",
          "JobNumber": "502-1325000-7",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2280 NORTHRIDGE DR NW",
          "JobAddress2": "",
          "JobFullAddress": "2280 NORTHRIDGE DR NW",
          "JobCounty": "KENT",
          "JobState": "Michigan",
          "CustomerCompanyName": "PROFESSIONAL DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$75,006.00",
          "BalanceRemaining": "$75,006.00",
          "BranchNumber": 5,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "PROFESSIONAL DRYWALL"
        },
        {
          "WorkOrder": 3908354,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Hilton H2",
          "JobNumber": "502-990401-41",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "33098 Northwestern Hwy",
          "JobAddress2": "",
          "JobFullAddress": "33098 Northwestern Hwy",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "Prowse Plaster LLC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "Prowse Plaster LLC"
        },
        {
          "WorkOrder": 3908353,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BEECH DALY",
          "JobNumber": "502-967075-108",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "12655 BEECH DALY RD",
          "JobAddress2": "",
          "JobFullAddress": "12655 BEECH DALY RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "BRINDLE CONSTRUCTION",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 7,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "BRINDLE CONSTRUCTION"
        },
        {
          "WorkOrder": 3908352,
          "ParentWorkOrder": "",
          "JobName": "(NOF)MCDONALD'S BLOOMFIEL",
          "JobNumber": "502-944100-297",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "2209 S. TELEGRAPH RD",
          "JobAddress2": "",
          "JobFullAddress": "2209 S. TELEGRAPH RD",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACOUSTECH CEILING, INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$5,006.00",
          "BalanceRemaining": "$5,006.00",
          "BranchNumber": 9,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "ACOUSTECH CEILING, INC"
        },
        {
          "WorkOrder": 3908351,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Foot Locker Detroit",
          "JobNumber": "502-916315-93",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "15280 Grand River Ave",
          "JobAddress2": "",
          "JobFullAddress": "15280 Grand River Ave",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "INDEPENDENCE COMM.CONST.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$35,006.00",
          "BalanceRemaining": "$35,006.00",
          "BranchNumber": 9,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "INDEPENDENCE COMM.CONST."
        },
        {
          "WorkOrder": 3908350,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1534 DAN KILDEE OFF",
          "JobNumber": "502-913820-495",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "601 S SAGINAW ST",
          "JobAddress2": "",
          "JobFullAddress": "601 S SAGINAW ST",
          "JobCounty": "Genesee",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 9,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3908349,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1528 EE COMP SCIENCE",
          "JobNumber": "502-913820-489",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1301 BEAL AVE.",
          "JobAddress2": "",
          "JobFullAddress": "1301 BEAL AVE.",
          "JobCounty": "WASHTENAW",
          "JobState": "Michigan",
          "CustomerCompanyName": "DH CONSTRUCTION COMPANY",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$4,806.00",
          "BalanceRemaining": "$4,806.00",
          "BranchNumber": 9,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "DH CONSTRUCTION COMPANY"
        },
        {
          "WorkOrder": 3908348,
          "ParentWorkOrder": "",
          "JobName": "(NOF)INFO CENTER",
          "JobNumber": "502-910868-53",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "20400 SUPERIOR RD",
          "JobAddress2": "",
          "JobFullAddress": "20400 SUPERIOR RD",
          "JobCounty": "WAYNE",
          "JobState": "Michigan",
          "CustomerCompanyName": "American Family Construct",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$3,506.00",
          "BalanceRemaining": "$3,506.00",
          "BranchNumber": 9,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "American Family Construct"
        },
        {
          "WorkOrder": 3908347,
          "ParentWorkOrder": "",
          "JobName": "(NOF) OLD PLANK RD",
          "JobNumber": "502-910400-138",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1183 OLD PLANK RD",
          "JobAddress2": "",
          "JobFullAddress": "1183 OLD PLANK RD",
          "JobCounty": "Oakland",
          "JobState": "Michigan",
          "CustomerCompanyName": "ACTION DRYWALL",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$8,506.00",
          "BalanceRemaining": "$8,506.00",
          "BranchNumber": 9,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "ACTION DRYWALL"
        },
        {
          "WorkOrder": 3908346,
          "ParentWorkOrder": "",
          "JobName": "(NOF)1601 Gratiot Ave",
          "JobNumber": "502-873350-5",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "1601 Gratiot Blvd",
          "JobAddress2": "",
          "JobFullAddress": "1601 Gratiot Blvd",
          "JobCounty": "St Clair",
          "JobState": "Michigan",
          "CustomerCompanyName": "MASTER PLASTERER'S",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "MASTER PLASTERER'S"
        },
        {
          "WorkOrder": 3908345,
          "ParentWorkOrder": "",
          "JobName": "(NOF) Rodeo Homes",
          "JobNumber": "502-858171-93",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "303 Regional Way",
          "JobAddress2": "",
          "JobFullAddress": "303 Regional Way",
          "JobCounty": "LAPEER",
          "JobState": "Michigan",
          "CustomerCompanyName": "DGS DRYWALL LLC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$6,306.00",
          "BalanceRemaining": "$6,306.00",
          "BranchNumber": 11,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "DGS DRYWALL LLC"
        },
        {
          "WorkOrder": 3908344,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Service First Logistics",
          "JobNumber": "502-817800-33",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "303 E Second St",
          "JobAddress2": "",
          "JobFullAddress": "303 E Second St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3908343,
          "ParentWorkOrder": "",
          "JobName": "(NOF)Clarkston Capital",
          "JobNumber": "502-817800-32",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "303 E. Second St",
          "JobAddress2": "",
          "JobFullAddress": "303 E. Second St",
          "JobCounty": "OAKLAND",
          "JobState": "Michigan",
          "CustomerCompanyName": "HURON ACOUSTIC TILE",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$0.00",
          "BalanceRemaining": "$0.00",
          "BranchNumber": 8,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "HURON ACOUSTIC TILE"
        },
        {
          "WorkOrder": 3908342,
          "ParentWorkOrder": "",
          "JobName": "(NOF) BLAINS FARM FLEET",
          "JobNumber": "502-54390-17",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "210 US 31 SOUTH",
          "JobAddress2": "",
          "JobFullAddress": "210 US 31 SOUTH",
          "JobCounty": "Grand Traverse",
          "JobState": "Michigan",
          "CustomerCompanyName": "SURFACES, INC.",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$40,006.00",
          "BranchNumber": 5,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "SURFACES, INC."
        },
        {
          "WorkOrder": 3908341,
          "ParentWorkOrder": "",
          "JobName": "(NOF) HOEKSTRA ELECTRIC",
          "JobNumber": "502-50435-47",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "68 W 64TH ST",
          "JobAddress2": "",
          "JobFullAddress": "68 W 64TH ST",
          "JobCounty": "Allegan",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR BOUWKAMP & ASSOC. INC",
          "RequestType": "Notice of Furnishing and Request for Notice of Commencement",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$40,006.00",
          "BalanceRemaining": "$40,006.00",
          "BranchNumber": 5,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "JR BOUWKAMP & ASSOC. INC"
        },
        {
          "WorkOrder": 3908340,
          "ParentWorkOrder": "",
          "JobName": "(NOF)BAY POINTE WOODS",
          "JobNumber": "502-50435-42",
          "FirstdayontheJob": "01-24-2019",
          "LastdayontheJob": "01-24-2019",
          "EstimatedLastdayontheJob": "",
          "JobAddress1": "11456 MARSH RD",
          "JobAddress2": "",
          "JobFullAddress": "11456 MARSH RD",
          "JobCounty": "Barry",
          "JobState": "Michigan",
          "CustomerCompanyName": "JR BOUWKAMP & ASSOC. INC",
          "RequestType": "Notice of Furnishing",
          "CustomerRepresentative": "Natasha Dallas",
          "Status": "Mailed",
          "ContractAmount": "$20,006.00",
          "BalanceRemaining": "$20,006.00",
          "BranchNumber": 5,
          "SubmitDate": "01-25-2019",
          "ClientonJob": "JR BOUWKAMP & ASSOC. INC"
        }
      ]
}

Mock.onGet('/api/workorders/all').reply((config) => {
    return [200, workOrdersDB.WorkOrders]
})

Mock.onGet('/api/workorder').reply((config) => {
    const id = config.data
    const response = workOrdersDB.WorkOrders.find((wo) => wo.WorkOrder == id)
    return [200, response]
})

Mock.onPost('/api/workorder/delete').reply((config) => {
  let workorder = JSON.parse(config.data)
  let index = { i: 0 }
  workOrdersDB.WorkOrders.forEach((element) => {
      if (element.id === workorder.id) {
          return [200, workOrdersDB.WorkOrders.splice(index.i, 1)]
      }
      index.i++
  })
  return [200, workOrdersDB.WorkOrders]
})

Mock.onPost('/api/workorder/update').reply((config) => {
  let workorder = JSON.parse(config.data)
  let index = { i: 0 }
  workOrdersDB.WorkOrders.forEach((element) => {
      if (element.WorkOrder === workorder.WorkOrder) {
          workOrdersDB.WorkOrders[index.i] = workorder
          return [200, workOrdersDB.WorkOrders]
      }
      index.i++
  })
  return [200, workOrdersDB.WorkOrders]
})

Mock.onPost('/api/workorder/add').reply((config) => {
  let workorder = JSON.parse(config.data)
  workOrdersDB.WorkOrders.push(workorder)
  return [200, workOrdersDB.WorkOrders]
})
