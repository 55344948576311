import { createTheme } from '@mui/material/styles';

// Assets
import colors from 'assets/scss/_themes-vars.module.scss';

// Project Imports
import componentStyleOverrides from './CompStyleOverride'
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
*/

export const theme = () => {
    const color = colors;

    const themeOption = {
        colors: color,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: color.paper,
        sidebarBackground: color.primary,
        darkTextPrimary: color.grey700,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        menuSelected: color.paper,
        menuSelectedBack: color.primary900,
        divider: color.grey200,
        menuTextColor: color.paper,
        menuIconColor: color.paper,
    };

    const themeOptions = {
        direction: 'ltr',
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '45px',
                padding: '7px',
                '@media (min-width: 600px)': {
                    minHeight: '45px'
                }
            }
        },
        typography: themeTypography(themeOption),
        breakpoints: {
            values: {
              xs: 0,
              sm: 600,
              md: 900,
              lg: 1200,
              xl: 1536,
            },
        },
    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;
