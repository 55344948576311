import { GET_PROJECT_REQUEST } from "redux/actions/ProjectActions";
import { GET_PROJECT_SUCCESS } from "redux/actions/ProjectActions";
import { GET_PROJECT_FAIL } from "redux/actions/ProjectActions";

import { GET_PROJECTS_REQUEST } from "redux/actions/ProjectActions";
import { GET_PROJECTS_FAIL } from "redux/actions/ProjectActions";
import { GET_PROJECTS_SUCCESS } from "redux/actions/ProjectActions";

import { UPDATE_PROJECT_REQUEST } from "redux/actions/ProjectActions";
import { UPDATE_PROJECT_SUCCESS } from "redux/actions/ProjectActions";
import { UPDATE_PROJECT_FAIL } from "redux/actions/ProjectActions";

const initialStates = {
    projects: [],
    project: {},
    isLoading: true,
    error: ''
}

const ProjectReducer =  (state = initialStates, action) => {
    switch (action.type) {
        case GET_PROJECTS_REQUEST: 
            return {
                ...state,
                isLoading: true
            }
        case GET_PROJECTS_SUCCESS: 
            return {
                ...state,
                isLoading: false,
                error: '',
                projects: action.data,
            }
        case GET_PROJECTS_FAIL: 
            return {
                ...state,
                isLoading: false,
                error: action.data,
                projects: []
            }
        case GET_PROJECT_REQUEST: 
            return {
                ...state,
                isLoading: true
            }
        case GET_PROJECT_SUCCESS: 
            return {
                ...state,
                isLoading: false,
                error: '',
                project: action.data,
            }
        case GET_PROJECT_FAIL: 
            return {
                ...state,
                isLoading: false,
                error: action.data,
                project: []
            }
        case UPDATE_PROJECT_REQUEST: 
            return {
                ...state,
                isLoading: true
            }
        case UPDATE_PROJECT_SUCCESS:
            return {
                ...state,
                project: action.data,
                isLoading: false,
                error: ''
            }
        case UPDATE_PROJECT_FAIL: 
            return {
                ...state,
                isLoading: true,
                error: action.data,
            }
        default: 
            return state;
    }
}

export default ProjectReducer
