import { combineReducers } from 'redux';

// Importing Reducer
import CustomizationReducer from './CustomizationReducer';
import ParticipantsReducer from './ParticipantsReducer';
import ProjectsReducer from './ProjectsReducer';
import WorkOrdersReducer from './WorkOrdersReducer';
import StatesReducer from './StatesReducer';

// Combine Reducers //
const RootReducer = combineReducers({
    customization: CustomizationReducer,
    projects: ProjectsReducer,
    participants: ParticipantsReducer,
    workorders: WorkOrdersReducer,
    states: StatesReducer
});

export default RootReducer;