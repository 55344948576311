import { GET_PARTICIPANTS_REQUEST } from "redux/actions/ParticipantActions";
import { CREATE_PARTICIPANT_SUCCESS } from "redux/actions/ParticipantActions";
import { UPDATE_PARTICIPANT_REQUEST } from "redux/actions/ParticipantActions";
import { UPDATE_PARTICIPANT_FAIL } from "redux/actions/ParticipantActions";
import { UPDATE_PARTICIPANT_SUCCESS } from "redux/actions/ParticipantActions";
import { CREATE_PARTICIPANT_FAIL } from "redux/actions/ParticipantActions";
import { CREATE_PARTICIPANT_REQUEST } from "redux/actions/ParticipantActions";
import { GET_PARTICIPANTS_SUCCESS } from "redux/actions/ParticipantActions";
import { GET_PARTICIPANTS_FAIL } from "redux/actions/ParticipantActions";

const initialStates = {
    participants: [],
    participant: {},
    isLoading: false,
    error: '',
}

const ParticipantsReducer =  (state = initialStates, action) => {
    switch (action.type) {
        case GET_PARTICIPANTS_REQUEST: 
            return {
                ...state,
                isLoading: true,
            }
        case GET_PARTICIPANTS_SUCCESS: 
            return {
                ...state,
                isLoading: false,
                error: '',
                participants: action.data,
            }
        case GET_PARTICIPANTS_FAIL: 
            return {
                ...state,
                isLoading: true,
                error: action.data,
                participants: [],
            }
        case CREATE_PARTICIPANT_REQUEST: 
            return {
                ...state,
                isLoading: true,
            }
        case CREATE_PARTICIPANT_SUCCESS: 
            return {
                ...state,
                isLoading: false,
                error: '',
                participants: action.data,
            }
        case CREATE_PARTICIPANT_FAIL: 
            return {
                ...state,
                isLoading: true,
                error: action.data,
                participants: [],
            }
        case UPDATE_PARTICIPANT_REQUEST: 
            return {
                ...state,
                isLoading: true,
            }
        case UPDATE_PARTICIPANT_SUCCESS:
            return {
                ...state,
                participants: action.data,
                isLoading: false,
                error: '',
            }
        case UPDATE_PARTICIPANT_FAIL: 
            return {
                ...state,
                isLoading: true,
                error: action.data,
            }
        default: 
            return state;
    }
}

export default ParticipantsReducer
