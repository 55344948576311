import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import DefaultLayout from 'layout/DefaultLayout';

// login option 3 routing
const Login = Loadable(lazy(() => import('pages/auth/login/Login')));
const Register = Loadable(lazy(() => import('pages/auth/register/Register')));
const NotFound = Loadable(lazy(() => import('pages/auth/NotFound')));

// Public Routes

const PublicRoutes = {
    path: '/',
    element: <DefaultLayout />,
    children: [
        {
            path: '/auth/login',
            element: <Login />
        },
        {
            path: '/auth/register',
            element: <Register />
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]
};

export default PublicRoutes;
