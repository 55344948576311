// API Service Call
import { ParticipantsService } from 'services/ParticipantsService'

// Participants Action Types

export const GET_PARTICIPANTS_REQUEST = 'GET_PARTICIPANTS_REQUEST'
export const GET_PARTICIPANTS_SUCCESS = 'GET_PARTICIPANTS_SUCCESS'
export const GET_PARTICIPANTS_FAIL = 'GET_PARTICIPANTS_FAIL'

export const CREATE_PARTICIPANT_REQUEST = 'CREATE_PARTICIPANT_REQUEST'
export const CREATE_PARTICIPANT_SUCCESS = 'CREATE_PARTICIPANT_SUCCESS'
export const CREATE_PARTICIPANT_FAIL = 'CREATE_PARTICIPANT_FAIL'

export const UPDATE_PARTICIPANT_REQUEST = 'UPDATE_PARTICIPANT_REQUEST'
export const UPDATE_PARTICIPANT_SUCCESS = 'UPDATE_PARTICIPANT_SUCCESS'
export const UPDATE_PARTICIPANT_FAIL = 'UPDATE_PARTICIPANT_FAIL'

// Get Participants Details

export const getParticipantsRequest = () => {
    return {
        type: GET_PARTICIPANTS_REQUEST
    }
}

export const getParticipantsSuccess = (participants) => {
    return {
        type: GET_PARTICIPANTS_SUCCESS,
        data: participants
    }
}

export const getParticipantsFail = (error) => {
    return {
        type: GET_PARTICIPANTS_FAIL,
        data: error
    }
}

export const loadParticipants = () => {
    return function (dispatch) {
        dispatch(getParticipantsRequest())
        ParticipantsService.getAll().then((response) => {
            dispatch(getParticipantsSuccess(response))
        }).catch(err => {
            dispatch(getParticipantsFail(err))
        })
    }
}

// Add Participant

export const createParticipantRequest = () => {
    return {
        type: CREATE_PARTICIPANT_REQUEST
    }
}

export const createParticipantSuccess = (participant) => {
    return {
        type: CREATE_PARTICIPANT_SUCCESS,
        data: participant
    }
}

export const createParticipantFail = (error) => {
    return {
        type: CREATE_PARTICIPANT_FAIL,
        data: error
    }
}

export const createParticipant = (data) => {
    return function (dispatch) {
        dispatch(createParticipantRequest())
        ParticipantsService.create(data).then((response) => {
            console.log("pa1")
            dispatch(createParticipantSuccess(response))
        }).catch(err => {
            dispatch(createParticipantFail(err))
        })
    }
}

// Update Participant

export const updateParticipantRequest = (participant) => {
    return {
        type: UPDATE_PARTICIPANT_REQUEST,
        data: participant
    }
}

export const updateParticipantSuccess = (participant) => {
    return {
        type: UPDATE_PARTICIPANT_SUCCESS,
        data: participant
    }
}

export const updateParticipantFail = (error) => {
    return {
        type: UPDATE_PARTICIPANT_FAIL,
        data: error
    }
}

export const updateParticipant = (data) => {
    return function (dispatch) {
        dispatch(updateParticipantRequest(data))
        ParticipantsService.update(data).then((response) => {
            dispatch(updateParticipantSuccess(response))
        }).catch(err => {
            dispatch(updateParticipantFail(err))
        })
    }
}