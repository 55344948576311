import useAuth from 'hooks/useAuth';
import React from 'react'
import { Navigate } from 'react-router-dom'

const AuthGuard = ({ children }) => {
    const { isAuthenticated } = useAuth()
    return <>{isAuthenticated ? children : <Navigate to="/auth/login" />}</>
}

export default AuthGuard
