// API 
import { StatesService } from 'services/StatesService'

// State Action Types
export const GET_STATES_REQUEST = 'GET_STATES_REQUEST'
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS'
export const GET_STATES_FAIL = 'GET_STATES_FAIL'

export const GET_STATE_REQUEST = 'GET_STATE_REQUEST'
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS'
export const GET_STATE_FAIL = 'GET_STATE_FAIL'

// Get All States Details
export const getStatesRequest = () => {
    return {
        type: GET_STATES_REQUEST
    }
}

export const getStatesSuccess = (states) => {
    return {
        type: GET_STATES_SUCCESS,
        data: states
    }
}

export const getStatesFail = (error) => {
    return {
        type: GET_STATES_FAIL,
        data: error
    }
}

export const loadStates = ()  => (dispatch) => {
    dispatch(getStatesRequest())
    StatesService.getAll().then((response) => {
        dispatch(getStatesSuccess(response))
    }).catch(err => {
        dispatch(getStatesFail(err))
    })
}

// Get State Details
export const getStateRequest = () => {
    return {
        type: GET_STATE_REQUEST
    }
}

export const getStateSuccess = (states) => {
    return {
        type: GET_STATE_SUCCESS,
        data: states
    }
}

export const getStateFail = (error) => {
    return {
        type: GET_STATE_FAIL,
        data: error
    }
}

export const loadState = (id)  => (dispatch) => {
    dispatch(getStateRequest())
    StatesService.getById(`${id}`).then((response) => {
        dispatch(getStateSuccess(response))
    }).catch(err => {
        dispatch(getStateFail(err))
    })
}
