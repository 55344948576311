import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import useNotification from 'hooks/useNotification'
import { styled, useTheme } from '@mui/system'
import {
    Badge,
    Card,
    Button,
    IconButton,
    Drawer,
    Typography,
    Box,
} from '@mui/material';

// Tabler Icons
import { IconSettings } from '@tabler/icons';
import { Close } from '@mui/icons-material'

const Notification = styled('div')(() => ({
    padding: '16px',
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    height: 84,
    boxShadow: 'rgb(0 0 0 / 6%) 0px 3px 5px -1px, rgb(0 0 0 / 4%) 0px 6px 10px 0px, rgb(0 0 0 / 4%) 0px 1px 18px 0px',
    '& h5': {
        marginLeft: '8px',
        marginTop: 0,
        marginBottom: 0,
        fontWeight: '500',
    },
}))

const NotificationCard = styled(Box)(({ theme }) => ({
    position: 'relative',
    '&:hover': {
        '& .messageTime': {
            display: 'none',
        },
        '& .deleteButton': {
            opacity: '1',
        },
    },
    '& .messageTime': {
        color: theme.palette.text.secondary,
    },
    '& .icon': { fontSize: '1.25rem' }
}))

const DeleteButton = styled(IconButton)(({ theme }) => ({
    opacity: '0',
    position: 'absolute',
    right: 5,
    marginTop: 9,
    marginRight: '24px',
    background: 'rgba(0, 0, 0, 0.01)',
}))

const CardLeftContent = styled('div')(({ theme }) => ({
    padding: '12px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'rgba(0, 0, 0, 0.01)',
    '& small': {
        fontWeight: '500',
        marginLeft: '16px',
        color: theme.palette.text.secondary,
    },
}))

const Heading = styled('span')(({ theme }) => ({
    fontWeight: '500',
    marginLeft: '6px',
    color: theme.palette.text.secondary,
}))

const NotificationSection = ({container}) => {
    const theme = useTheme()
    const secondary = theme.palette.text.secondary
    const [panelOpen, setPanelOpen] = React.useState(false)
    const { deleteNotification, clearNotifications, notifications } = useNotification()

    const handleDrawerToggle = () => {
        setPanelOpen(!panelOpen)
    }

    return (
        <Fragment>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <IconButton disableRipple onClick={handleDrawerToggle}>
                    <Badge color="secondary" badgeContent={notifications?.length}>
                        <IconSettings stroke={1.5} size="1.8rem" />
                    </Badge>
                </IconButton>

            </Box>

            <Drawer
                width={'100px'}
                variant="temporary"
                container={container}
                anchor={'right'}
                open={panelOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <Box sx={{ width: 300 }}>
                    <Notification>
                        <IconSettings stroke={1.5} size="1.8rem" /><h3>Notifications</h3>
                    </Notification>

                    {notifications && notifications.map((notification) => (
                        <NotificationCard key={notification.id}>
                            <DeleteButton
                                size="small"
                                className="deleteButton"
                                onClick={() =>
                                    deleteNotification(notification.id)
                                }
                            >
                                <Close  size="1.2rem" sx={{ color:"red"}} />
                            </DeleteButton>
                            <Link
                                to={`/`}
                                onClick={handleDrawerToggle}
                                style={{ textDecoration: 'none' }}
                            >
                                <Card sx={{ mx: 2, mb: 3 }} elevation={3}>
                                    <CardLeftContent>
                                        <Box display="flex">
                                            <Heading>
                                                {notification.heading}
                                            </Heading>
                                        </Box>
                                    </CardLeftContent>
                                    <Box sx={{ px: 2, pt: 1, pb: 2 }}>
                                        <Typography sx={{ m: 0 }}>
                                            {notification.title}
                                        </Typography>
                                        <Typography sx={{ color: secondary }}>
                                            {notification.subtitle}
                                        </Typography>
                                    </Box>
                                </Card>
                            </Link>
                        </NotificationCard>
                    ))}
                    {/* {notifications && notifications.map(test => test.map(notification => notification.title))} */}
                    {!!notifications?.length && (
                        <Box sx={{ ml:2,color: secondary }}>
                            <Button onClick={clearNotifications}>
                                Clear Notifications
                            </Button>
                        </Box>
                    )}
                     {!notifications?.length && (
                        <Box sx={{ color: 'primary' }}>
                            <Typography align="center">Notifications not found</Typography>
                        </Box>
                    )}
                </Box>
            </Drawer>

        </Fragment>
    )
}

export default NotificationSection
