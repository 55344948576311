import BarChartIcon from '@mui/icons-material/BarChart';

// Charts Menu Items
const charts = {
    id: 'charts',
    type: 'group',
    children: [
        {
            id: 'charts',
            title: 'CHARTS',
            type: 'item',
            url: '/customer/charts',
            icon: BarChartIcon,
        }
    ]
};

export default charts;
