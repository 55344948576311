import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from './AuthGuard';

// Dashboard Routing
const CustomerDashboard = Loadable(lazy(() => import('pages/customer/dashboard')));

// Project Routing
const Project = Loadable(lazy(() => import('pages/customer/dashboard/project-components/project')));
const ProjectRequest = Loadable(lazy(() => import('pages/customer/project-request')));
const DocumentRequest = Loadable(lazy(() => import('pages/customer/document-request')));
const UploadRequests = Loadable(lazy(() => import('pages/customer/upload-requests')));
const QuickSearch = Loadable(lazy(() => import('pages/search-requests/quick-search')));
const AdvancedSearch = Loadable(lazy(() => import('pages/search-requests/advanced-search')));

// Charts Routing
const Charts = Loadable(lazy(() => import('pages/charts')));

// Profile Routing
const Profile = Loadable(lazy(() => import('pages/profile')));

// Private Routes

const PrivateRoutes = {
    path: '/',
    element: <AuthGuard><MainLayout /></AuthGuard>,
    children: [
        {
            path: '/',
            element: <CustomerDashboard />
        },
        {
            path: '/customer/dashboard',
            element: <CustomerDashboard />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/project-request',
            element: <ProjectRequest />
        },
        {
            path: '/document-request',
            element: <DocumentRequest />
        },
        {
            path: '/upload-requests',
            element: <UploadRequests />
        },
        {
            path: '/quick-search',
            element: <QuickSearch />
        },
        {
            path: '/advanced-search',
            element: <AdvancedSearch />
        },
        {
            path: '/customer/charts',
            element: <Charts />
        },
        {
            path: '/customer/project/:id',
            element: <Project />
        }
    ]
};

export default PrivateRoutes;
