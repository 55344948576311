import { Outlet } from 'react-router-dom';

// Default Layout

const DefaultLayout = () => (
    <>
        <Outlet />
    </>
);

export default DefaultLayout;
