import { IconAlignLeft } from '@tabler/icons';

const icons = { IconAlignLeft };

// Search Request Menu Items
const SearchRequests = {
    id: 'searchRequests',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Search Requests',
            type: 'collapse',
            icon: icons.IconAlignLeft,
            url: '#',
            target: false,
            children: [
                {
                    id: 'quickSearch',
                    title: 'Quick Search',
                    type: 'item',
                    url: '/quick-search',
                    target: false
                },
                {
                    id: 'advancedSearch',
                    title: 'Advanced Search',
                    type: 'item',
                    url: '/advanced-search',
                    target: false
                }
            ]
        }
    ]
};

export default SearchRequests;