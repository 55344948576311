import AxiosInstance from "./AxiosInstance";
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const getAll = async () => {
    const responseData = await AxiosInstance.get(`/api/workorders/all`).then(response => response);
    await delay(500);
    return responseData.data;
}

const create = async (WorkOrderData) => {
    const responseData = await AxiosInstance.post(`/api/workorders/add`, WorkOrderData).then(response => response);
    return responseData.data;
}

const update = async (WorkOrder) => {
    const responseData = await AxiosInstance.post(`/api/workorder/update`, WorkOrder).then(response => response);
    await delay(500); 
    return responseData.data;
}

const _delete = async (WorkOrder) => {
    const responseData = await AxiosInstance.delete(`/api/workorders/delete`, WorkOrder).then(response => response);
    await delay(500);
    return responseData.data;
}

const getById = async (WorkOrderId) => {
    const responseData = await AxiosInstance.get('/api/workorder', { data: WorkOrderId  }).then(response => response);
    await delay(500);
    return responseData.data;
}

export const WorkOrdersService = {
    getAll,
    getById,
    create,
    update,
    delete: _delete
};