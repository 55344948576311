// API 
import { WorkOrdersService } from 'services/WorkOrdersService'

// WorkOrder Action Types

export const GET_WORK_ORDERS_REQUEST = 'GET_WORK_ORDERS_REQUEST'
export const GET_WORK_ORDERS_SUCCESS = 'GET_WORK_ORDERS_SUCCESS'
export const GET_WORK_ORDERS_FAIL = 'GET_WORK_ORDERS_FAIL'

export const GET_WORK_ORDER_REQUEST = 'GET_WORK_ORDER_REQUEST'
export const GET_WORK_ORDER_SUCCESS = 'GET_WORK_ORDER_SUCCESS'
export const GET_WORK_ORDER_FAIL = 'GET_WORK_ORDER_FAIL'

export const UPDATE_WORK_ORDER_REQUEST = 'UPDATE_WORK_ORDER_REQUEST'
export const UPDATE_WORK_ORDER_SUCCESS = 'UPDATE_WORK_ORDER_SUCCESS'
export const UPDATE_WORK_ORDER_FAIL = 'UPDATE_WORK_ORDER_FAIL'

// Get All WorkOrders Details

export const getWorkOrdersRequest = () => {
    return {
        type: GET_WORK_ORDERS_REQUEST
    }
}

export const getWorkOrdersSuccess = (workorders) => {
    return {
        type: GET_WORK_ORDERS_SUCCESS,
        data: workorders
    }
}

export const getWorkOrdersFail = (error) => {
    return {
        type: GET_WORK_ORDERS_FAIL,
        data: error
    }
}

export const loadWorkOrders = ()  => (dispatch) => {
    dispatch(getWorkOrdersRequest())
    WorkOrdersService.getAll().then((response) => {
        dispatch(getWorkOrdersSuccess(response))
    }).catch(err => {
        dispatch(getWorkOrdersFail(err))
    })
}


// Get WorkOrder Details

export const getWorkOrderRequest = () => {
    return {
        type: GET_WORK_ORDER_REQUEST
    }
}

export const getWorkOrderSuccess = (workorders) => {
    return {
        type: GET_WORK_ORDER_SUCCESS,
        data: workorders
    }
}

export const getWorkOrderFail = (error) => {
    return {
        type: GET_WORK_ORDER_FAIL,
        data: error
    }
}

export const loadWorkOrder = (id)  => (dispatch) => {
    dispatch(getWorkOrderRequest())
    WorkOrdersService.getById(`${id}`).then((response) => {
        dispatch(getWorkOrderSuccess(response))
    }).catch(err => {
        dispatch(getWorkOrderFail(err))
    })
}

// Update WorkOrder Details

export const updateWorkOrderRequest = (workorder) => {
    return {
        type: UPDATE_WORK_ORDER_REQUEST,
        data: workorder
    }
}

export const updateWorkOrderSuccess = (data) => {
    return {
        type: UPDATE_WORK_ORDER_SUCCESS,
        data: data
    }
}

export const updateWorkOrderFail = (error) => {
    return {
        type: UPDATE_WORK_ORDER_FAIL,
        data: error
    }
}

export const updateWorkOrder = (data) => {
    return function (dispatch) {
        dispatch(updateWorkOrderRequest(data))
        WorkOrdersService.update(data).then((response) => {
            dispatch(updateWorkOrderSuccess(response))
        }).catch(err => {
            dispatch(updateWorkOrderFail(err))
        })
    }
}
