export default function ComponentStyleOverrides(theme) {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: '2px',
                    letterSpacing: '.3px',
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 8px',
                },
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.menuSelected,
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    '&.Mui-selected': {

                        '&:hover': {
                            color: theme.paper,
                            backgroundColor: theme.menuSelectedBack,

                            '& .MuiListItemIcon-root': {
                                color: "#fff"
                            },
                        },
                        '& .MuiListItemIcon-root': {
                            color: "#fff"
                        },
                        '& .MuiTypography-root': {
                            color: '#fff'
                        }
                    },
                    '&:hover': {
                        backgroundColor: "#fff",
                        color: "#005C83",
                        '& .MuiListItemIcon-root': {
                            color: "#005C83",
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.menuIconColor,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.paper
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: 'inherit',
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderWidth: 1,
                    paddingRight: '2px',
                    //boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                    borderColor: 'rgb(189, 189, 189)',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 1,
                        borderColor: 'rgb(189, 189, 189)',
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderWidth: 1,
                        borderColor: 'rgb(189, 189, 189)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 1,
                        borderColor: 'rgb(189, 189, 189)',
                        //boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                    }
                },
                input: {
                    padding: '13.5px 12px',
                    fontWeight: 600,
                    color: '#005c83',
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 0
                },
                notchedOutline: {
                    borderColor: 'rgb(189, 189, 189)',
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryDark,
                    background: 'none'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700
                }
            }
        },
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    cursor: 'pointer'
                }
            }
        },
        MuiYearPicker: {
            styleOverrides: {
                root: {
                    cursor: 'default'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: 'auto'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: '0px'
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    opacity: .5,
                    fontSize: '24px'
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    '@media screen and (max-width: 600px)': {
                        '& .MuiToolbar-root': {
                            '& .MuiTablePagination-selectLabel': {
                                display: 'none'
                            },
                            '& .MuiInputBase-root': {
                                display: 'none'
                            },
                            '& .MuiTablePagination-select': {
                                display: 'none'
                            },
                            '& .MuiTablePagination-selectIcon': {
                                display: 'none'
                            },
                            '& .MuiTablePagination-actions': {
                                marginLeft: '0px'
                            }
                        }                    }
                }
            }
        }
    };
}